import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";
import React, {useMemo, useState} from "react";

const SearchBadge = (props) => {

    const {value, param, label, id, subId, onRemove} = props;
    const [visible, setVisible] = useState(true);

    const isIgnored = useMemo(() => {
        if (param.ignoreValues) {
            return param.ignoreValues.split(",").includes(value);
        }
        return false;
    },[param.ignoreValues, value]);


    if (!visible || isIgnored || param.hidden) {
        return <></>;
    }
    return <Badge bg={"custom"}>
        <span className={"filter-label"}>{param.label}</span>
        <span className={"filter-value"}>{label}</span>
        <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
                setVisible(false)
                onRemove(id, subId);
            }}
            className={"filter-remove"}/>
    </Badge>;
}

export default SearchBadge;
