import React, {useCallback, useLayoutEffect} from "react";

export default function useElementSize(ref) {

    const [elementSize, setElementSize] = React.useState({
        width: ref?.current?.offsetWidth,
        height: ref?.current?.offsetHeight,
    });

    const changeElementSize = useCallback(() => {
        setElementSize({width: ref?.current?.offsetWidth, height: ref?.current?.offsetHeight});
    },
        [ref, setElementSize]
    );

    useLayoutEffect(() => {

        const resizeObserver = new ResizeObserver((entries) => {
            changeElementSize()
        });

        if (ref.current)
            resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };

    }, [ref, changeElementSize]);

    return elementSize;
}
