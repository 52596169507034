import React from 'react';
import LoginComponent from "./components/LoginComponent"
import LegalWarningContainer from "./containers/LegalWarningContainer";
import {Navigate, useLocation} from "react-router";
import {useApiState} from "system/API/APIStateContext";
import TwoFactorAuthContainer from "app/login/containers/TwoFactorAuthContainer";
import VeterinaryEntities from "app/login/VeterinaryEntities";
import {isAuthenticationExpired, shouldShowLogin} from "app/login/utils/sessionUtils";



const Login = (props) => {

    const {authentication} = useApiState();
    const location = useLocation();
    
    if (shouldShowLogin(authentication) || isAuthenticationExpired(authentication?.session?.validity)) {
        return <LoginComponent {...props}/>;
    }

    if (authentication.twoFactorAuthenticationRequired) {
        return <TwoFactorAuthContainer {...props}/>
    }

    if (!authentication.legalWarningReadTimestamp) {
        return <LegalWarningContainer {...props}/>
    }

    if (authentication.isPreAuthenticated && authentication?.veterinaryId) {
        return <VeterinaryEntities/>
    }

    return <Navigate
        to={
            (location?.state?.origin && location?.state?.origin !== "/logout")
                ? location?.state?.origin
                : '/'
        }
        state={{
            loginRedirected: true
        }}
    />
}

export default Login;
