import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {Form} from "react-bootstrap";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import EntityProfileDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityProfileDropdown";
import ReportCategoryDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ReportCategoryDropdown";

const BaseTemplateForm = props => {

    const {errors, t, template, setTemplate, readOnly} = props;

    return <Row>
        <Col sm={12} lg={12} xl={12}>
            <FormGroup error={errors['name']}>
                <Form.Label>{t('name')}</Form.Label>
                <FormControl
                    type="text"
                    placeholder={t('name_ph')}
                    disabled={readOnly}
                    value={getOrEmpty(template?.name)}
                    onChange={useCallback((e) => setTemplate(template => ({
                        ...template,
                        name: e.target.value,
                    })), [setTemplate])}
                />
                <Form.Text className="sub-error">
                    {t(errors['name'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['async']}>
                <Form.Label>{t('async')}</Form.Label>
                <BooleanDropdown
                    isDisabled={readOnly}
                    value={parseBoolean(template?.async, null)}
                    onChange={useCallback((value) => setTemplate(template => ({
                        ...template,
                        async: value,
                    })), [setTemplate])}
                />
                <Form.Text className="sub-error">
                    {t(errors['async'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['category']}>
                <Form.Label>{t('category')}</Form.Label>
                <ReportCategoryDropdown
                    t={t}
                    isCreatable={true}
                    isDisabled={readOnly}
                    value={getOrNull(template?.category)}
                    onChange={useCallback((value) => setTemplate(template => ({
                        ...template,
                        category: value,
                    })), [setTemplate])}
                />
                <Form.Text className="sub-error">
                    {t(errors['category'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['allowedEntityIds']}>
                <Form.Label>{t('entities')}</Form.Label>
                <EntityDropdown
                    isDisabled={readOnly}
                    isMulti={true}
                    value={getOrNull(template?.allowedEntityIds)}
                    onChange={useCallback((value) => setTemplate(template => ({
                        ...template,
                        allowedEntityIds: value,
                    })), [setTemplate])}
                />

                <Form.Text className="sub-error">
                    {t(errors['allowedEntityIds'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['allowedEntityProfileIds']}>
                <Form.Label>{t('entity_profiles')}</Form.Label>
                <EntityProfileDropdown
                    isDisabled={readOnly}
                    isMulti={true}
                    value={getOrNull(template?.allowedEntityProfileIds)}
                    onChange={useCallback((value) => setTemplate(template => ({
                        ...template,
                        allowedEntityProfileIds: value,
                    })), [setTemplate])}
                />

                <Form.Text className="sub-error">
                    {t(errors['allowedEntityProfileIds'])}
                </Form.Text>
            </FormGroup>
        </Col>
    </Row>

}

export default BaseTemplateForm;
