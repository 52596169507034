import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faCodeBranch, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import AddressComponent from "./AddressComponent";
import ContactsComponent from "./ContactsComponent";
import EntityResponsibleComponent from "./EntityResponsibleComponent";
import PersonalDataComponent from "./PersonalDataComponent";
import TypeComponent from "./TypeComponent";
import {OwnerOperationAllowed, OwnerOperations} from "./OwnerOperationAllowed";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import {defaultOwnerProps} from "app/owners/utils/form/defaultOwnerProps";
import Loading from "layout/modules/Loading/Loading";
import OwnerCheckContainer from "app/owners/containers/form/OwnerCheckContainer";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import Audit from "app/audit/Audit";
import {Actions, OwnerActions} from "app/audit/utils/AuditActions";
import {AuditObjectTypes} from "app/audit/utils/AuditObjectTypes";
import Observations from "app/observations/Observations";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import {OwnerType} from "types/Onwer";
import VersionsContainer from "app/owners/containers/view/VersionsContainer";
import "app/owners/assets/css/form.scss";
import LockOwnerComponent from "app/owners/components/view/toolbar/LockOwnerComponent";

const FormComponent = (props) => {

    const {
        owner: originalOwner,
        id, loading, create, errors = {}, viewMode, compareMode, changes = {},
        fullChanged, onSubmit, onEditMode,
    } = props;

    const {t} = useTranslation('owner');
    const [readOnly, setReadOnly] = useState(viewMode || compareMode);
    const [versionsVisible, setVersionsVisible] = useState(false);
    const [owner, setOwner] = useState(originalOwner ? originalOwner : defaultOwnerProps);

    //In order to support compare mode
    useEffect(() => {
        if (originalOwner) {
            setOwner(originalOwner);
        }
    }, [originalOwner, setOwner]);


    //Update EditMode
    useEffect(() => {
        if (onEditMode)
            onEditMode(!readOnly);
    }, [onEditMode, readOnly])

    return <div
        className={classnames("limited-width", {
            "view-mode": (viewMode && readOnly),
            "compare-mode": compareMode,
        })}>

        {!compareMode &&
            <>
                <GenericFormError t={t} errors={errors}/>
                <FormHandleNotSaved
                    remote={originalOwner ? originalOwner : defaultOwnerProps}
                    local={owner}
                    loading={loading}
                    readOnly={readOnly}
                    setLocal={setOwner}
                    setReadOnly={setReadOnly}
                    onSubmit={onSubmit}/>
            </>
        }

        <Loading visible={loading}/>

        {viewMode &&
            <>
                <VersionsContainer
                    id={id}
                    title={t('compare_owners')}
                    visible={versionsVisible}
                    onClose={() => setVersionsVisible(false)}
                    onRevert={(owner) => {
                        setReadOnly(false);
                        setVersionsVisible(false);
                        setOwner({
                            ...owner
                        });
                    }}
                />
            </>
        }

        {(!readOnly && !create) &&
            <OwnerCheckContainer
                owner={owner}
                setOwner={setOwner}
                readOnly={readOnly}
                create={create}
            />
        }

        <Form className={classnames({"full-changed": fullChanged})}>
            <Box>
                <BoxTitle>{t('owner_type')}</BoxTitle>

                <BoxOptions visible={!create && !compareMode}>

                    <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                        <Audit
                            objectId={owner.id}
                            filterActions={OwnerActions}
                            affectedObjectType={AuditObjectTypes.Owner}
                        />

                        {owner.hasAccount &&
                            <Audit
                                id={"owner-audit-log"}
                                icon={faAddressBook}
                                tooltip={t('user_audit_log')}
                                ownerVatNumber={owner.vatNumber}
                                filterActions={Actions}
                                filterObjectTypes={AuditObjectTypes}
                            />
                        }

                    </UserHasPermission>

                    <UserHasPermission permission={["READ_OBSERVATION"]}>
                        <Observations/>
                    </UserHasPermission>

                    <LockOwnerComponent {...props}/>

                    <UserHasPermission permission="READ_VERSIONS">
                        <ToolTip content={t('compare_owners')}>
                            <div className={classnames("btn-rounded-primary small", {"active": versionsVisible})}
                                 onClick={() => setVersionsVisible(true)}>
                                <FontAwesomeIcon icon={faCodeBranch}/>
                            </div>
                        </ToolTip>
                    </UserHasPermission>

                    <OwnerOperationAllowed owner={owner} operation={OwnerOperations.EDIT}>
                        <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                             onClick={() => setReadOnly(!readOnly)}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </div>
                    </OwnerOperationAllowed>

                </BoxOptions>

                <TypeComponent
                    create={create}
                    errors={errors}
                    owner={owner}
                    changes={changes}
                    setOwner={setOwner}
                    readOnly={readOnly}
                />


            </Box>

            {owner.country &&
                <>

                    <PersonalDataComponent
                        errors={errors}
                        owner={owner}
                        changes={changes}
                        setOwner={setOwner}
                        readOnly={readOnly}
                    />

                    {(owner.type === OwnerType.COLLECTIVE) &&
                        <EntityResponsibleComponent
                            errors={errors}
                            owner={owner}
                            changes={changes}
                            setOwner={setOwner}
                            readOnly={readOnly}
                        />
                    }

                    <AddressComponent
                        errors={errors}
                        owner={owner}
                        changes={changes}
                        setOwner={setOwner}
                        readOnly={readOnly}
                        compareMode={compareMode}
                    />

                    <ContactsComponent
                        compareMode={compareMode}
                        errors={errors}
                        owner={owner}
                        changes={changes}
                        setOwner={setOwner}
                        readOnly={readOnly}
                    />

                </>
            }

            {!readOnly &&
                <div>
                    <Row>
                        <Col className={"text-end"}>
                            {!create &&
                                <Button variant="secondary" className={"cancel"} onClick={() => setReadOnly(true)}>
                                    {t("cancel")}
                                </Button>
                            }
                            <UserHasPermission permission="WRITE_OWNER">
                                <Button variant="primary" className={"submit"} onClick={() => onSubmit(owner)}>
                                    {create ? t("create_form") : t("save_form")}
                                </Button>
                            </UserHasPermission>
                        </Col>
                    </Row>
                </div>
            }
        </Form>
    </div>
}

export default FormComponent;
