import React, {useMemo} from "react";
import {components as cs} from "react-select";
import CreatableSelect from "react-select/creatable";

const NewSingleValue = () => null;

const NewInput = (props) => {

    const {value: inputValue, isHidden, ...otherProps} = props;

    const {
        selectProps: {
            value,
            getOptionLabel
        }
    } = otherProps;

    const label = useMemo(() => {
        if (!value) {
            return "";
        }
        return getOptionLabel(value);
    }, [getOptionLabel, value]);

    const v = useMemo(() => {

        if (!inputValue) {
            return label;
        }
        return inputValue;
    }, [inputValue, label]);

    const hidden = useMemo(() => {
        if (v) {
            return false;
        }
        return isHidden;
    }, [isHidden, v]);


    return <cs.Input isHidden={hidden} value={v} {...otherProps} autoComplete="chrome-off" />;
};


const CreatableSingle = (props) => {

    const {isDisabled} = props

    const components = useMemo(() => ({
        ...cs,
        Input: NewInput,
        SingleValue: NewSingleValue
    }),[]);

    if (isDisabled) {
        return <CreatableSelect {...props}/>;
    }
    else {
        return <CreatableSelect {...props} components={components}/>
    }

};

export default CreatableSingle;
