export const pt = {

    register_type: "Tipo de registo",
    aggression_data: "Dados da agressão",
    general_data: "Dados gerais",

    date: "Data",
    registration_date: "Data de registo",
    type: "Tipo de agressão",
    fate: "Destino",
    no_aggression_results: "Sem agressões registadas",
    sub_type: "Nível de agressão",
    no_results: "Não foram encontrados resultados",


    //form
    view_aggression: "Visualizar registo",
    add_aggression: "Registar",
    clear_reason: "Motivo",
    add: "Registar",
    edit: "Guardar",
    cancel: "Cancelar",

    //place holders
    date_ph: "Data no formato (dd-mm-yyyy)",
    type_ph: "Selecione uma opção",
    sub_type_ph: "Selecione uma opção",
    fate_ph:  "Selecione uma opção",

    registry_cleared: "Limpeza de cadastro",
    reason: "Motivo",

    //Register Type
    REGISTER_AGGRESSION: "Registar agressão",
    REGISTER_CLEAR: "Limpeza de cadastro",


    //types:
    PERSON: "Agressão a pessoa",
    ANIMAL: "Agressão a animal fora de propriedade",
    OTHER: "Outro",


    //Subtypes
    PUBLIC_PLACE_INJURY: "Ferimento na via pública",
    PUBLIC_PLACE_DEATH: "Morte na via publica",
    RESIDENCE_INJURY: "Ferimento na residência",
    RESIDENCE_DEATH: "Morte na residência",

    SERIOUS_INJURY: "Ferimento grave",
    DEATH: "Morte",

    //Fate
    EUTHANASIA: "Eutanásia",
    DEVOLUTION: "Devolução ao titular",
    TRAINING: "Treino",


    aggression_created_by: "Agressão registada por",
    entity: "Entidade",
    user: "Utilizador",

    aggression_files: "Documentos associados",
    aggression_file_upload: "Opcionalmente poderá anexar 1 ou mais documentos de suporte à agressão",

    //Messages
    SUCCESS_ADD_AGGRESSION: "Agressão registada",
    SUCCESS_ADD_AGGRESSION_MSG: "Foi registada uma agressão para o animal com o transponder {{transponder}}",

    SUCCESS_EDIT_AGGRESSION: "Agressão guardada",
    SUCCESS_EDIT_AGGRESSION_MSG: "Foram guardadas alterações no registo agressão para o animal com o transponder {{transponder}}",

    ERROR_ADD_AGGRESSION: "Erro a registar agressão",
    ERROR_ADD_AGGRESSION_MSG: "Por favor verifique os campos assinalados",
    MISSING_DATE: "Indique uma data válida",
    MISSING_TYPE: "Indique o tipo de agressão",
    MISSING_SUBTYPE: "Indique o nível de agressão",
    MISSING_ANIMAL_FATE: "Indique o destino do animal",
    MISSING_REASON: "Indique o motivo",

    DELETE_AGGRESSION_CONFIRM: "Confirmação",
    DELETE_AGGRESSION_CONFIRM_MSG: "Deseja realmente apagar o registo do dia {{date}}?",

    SUCCESS_DELETE_AGGRESSION: "Agressão removida",
    SUCCESS_DELETE_AGGRESSION_MSG: "A agressão ocorrida no dia {{date}} foi removida do sistema com sucesso!",

    SUCCESS_DELETE_CLEAR_AGGRESSION: "Registo removido",
    SUCCESS_DELETE_CLEAR_AGGRESSION_MSG: "Foi removida a limpeza de cadastro do dia {{date}} com sucesso!",

    SUCCESS_CLEAR: "Cadastro limpo",
    SUCCESS_CLEAR_MSG: "Foi limpo o cadastro do animal {{transponder}} com sucesso!",

    FAIL_DELETE_AGGRESSION: "Erro",
    FAIL_DELETE_AGGRESSION_MSG: "Ocorreu um erro ao apagar a agressão. Por favor tente mais tarde",

    ERROR_CLEARING_REGISTRY: "Erro ao limpar",
    ERROR_CLEARING_REGISTRY_MSG: "Ocorreu um erro ao limpar cadastro. Por favor tente mais tarde",


};

export default pt;
