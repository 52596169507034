import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserHasPermission from "../../../../system/API/Authentication/UserHasPermission";
import React, {useMemo} from "react";
import classnames from "classnames";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import "../assets/css/entry-menu-options.scss"
import MenuDropdown from "../../MenuDropdown/MenuDropdown";

const TableEntryMenuOptions = (props) => {

    const {permission, placement, children, label, ...otherProps} = props;

    const totalItems = useMemo(() => {
        let total = 0;
        React.Children.forEach(children, content => {
            React.Children.forEach(content.props.children, children => {
                if (children && children.props?.visible !== false) {
                    total++
                }
            });
        });
        return total;
    }, [children]);

    const link = useMemo(() => <>
            <div {...otherProps}
                 className={classnames("table-line-options", props.className, {"disabled": totalItems === 0})}>
                <MenuDropdown placement={placement} disabled={totalItems === 0}>
                    {label
                        ? label
                        : <div className={"dropdown-button"}>
                            <FontAwesomeIcon icon={faChevronDown}/>
                        </div>
                    }

                    {children}
                </MenuDropdown>
            </div>
        </>,
        [placement, totalItems, children, props, label, otherProps]
    );

    if (permission) {
        return <UserHasPermission permission={permission}>
            {link}
        </UserHasPermission>
    }

    return link;

}

export default TableEntryMenuOptions;
