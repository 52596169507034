import {useGet} from "system/Rest/Rest"
import React from "react"
import locale from "app/personalArea/locales/locales"
import PersonalAreaComponent from "app/personalArea/components/PersonalAreaComponent";
import useTranslations from "system/Translations/UseTranslations";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";

const PersonalArea = (props) => {

    const {t} = useTranslations('personalArea', locale);

    const {data: user, loading, error, refetch: reload} = useGet({
        path: "/auth/personal"
    });

    return <Title
        title={user?.name ? user.name : "-"}
        breadcrumbs={[{href: "/personal", label: t('personal_title')},
        ]} {...props}>

        <PersonalAreaComponent
            {...props}
            user={user}
            loading={loading}
            error={error}
            reload={reload}/>
        
    </Title>

}


export default PersonalArea;
