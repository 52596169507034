import PopupFormComponent from "../components/PopupFormComponent";
import useTranslations from "../../../system/Translations/UseTranslations";
import locale from "../locales/locales";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useMutate} from "system/Rest/Rest";
import useErrorParser from "../../../system/API/Error/ErrorParser";
import {useCallback} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import isFunction from "system/Utils/isFunction";

const FormContainer = (props) => {

    const {t} = useTranslations("animal-license", locale);
    const showToast = useShowToast();

    const {transponder, reload, reloadBase, onClose} = props;

    const {mutate: submitLicensing, loading, error: errorSubmitting} = useMutate({
        verb: "POST",
        path: "/animal-licenses/",
    });

    const errors = useErrorParser(errorSubmitting);

    return <PopupFormComponent
        {...props}
        errors={errors}
        create={true}
        loading={loading}
        onSubmit={useCallback(
            license => {

                submitLicensing({
                    ...license,
                    transponder: transponder,
                })
                    .then(() => {

                        if (isFunction(reload))
                            reload();

                        if (isFunction(reloadBase))
                            reloadBase();

                        if (isFunction(onClose))
                            onClose();

                        showToast({
                            title: t('SUCCESS_ADD_LICENSING'),
                            message: t('SUCCESS_ADD_LICENSING_MSG', {transponder: transponder}),
                            icon: faCheck,
                        });

                    })
                    .catch(() => {
                        showToast({
                            title: t('ERROR_ADD_LICENSING'),
                            message: t('ERROR_ADD_LICENSING_MSG', {transponder: transponder}),
                            icon: faTimes,
                            className: 'error',
                        });
                    })

            },
            [t, reload, onClose, showToast, transponder, submitLicensing, reloadBase]
        )}

    />

}

export default FormContainer;
