const TicketProcessType = {
    ANIMAL_DEATH_REQUEST: "ANIMAL_DEATH_REQUEST",
    ANIMAL_PRE_REGISTER: "ANIMAL_PRE_REGISTER",
    ANIMAL_TRANSFER: "ANIMAL_TRANSFER",
    ANIMAL_TRANSFER_REQUEST: "ANIMAL_TRANSFER_REQUEST",
    LOST_ANIMAL: "LOST_ANIMAL",
    OWNER_DATA_UPDATE: "OWNER_DATA_UPDATE",
    OWNER_MERGE: "OWNER_MERGE",
    OWNER_REVIEW: "OWNER_REVIEW",
    ANIMAL_DIAC_REQUEST: "ANIMAL_DIAC_REQUEST"
}

export {TicketProcessType};
