import {useCallback, useEffect, useMemo, useState} from "react";
import {useGet} from "system/Rest/Rest";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import OwnerMergeComponent from "app/owners/components/form/OwnerMergeComponent";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import usePrevious from "system/Components/usePrevious";
import useDebounce from "system/System/useDebounce";
import ownerResolver from "app/owners/utils/OwnerResolver";
import TicketCategory from "app/support/utils/TicketCategory";
import {objectIsEqual} from "system/Objects/Objects";
import appConfig from "configs/config";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {UserType} from "types/Users";
import {useSupport} from "app/support/utils/SupportStateContext";


const isOriginalOwner = (originalOwner, owner) => originalOwner?.country === owner?.country &&
    owner?.vatNumber === originalOwner?.vatNumber &&
    owner?.type === originalOwner.type &&
    (owner?.hasLocalVatNumber === originalOwner?.hasLocalVatNumber || (!owner?.hasLocalVatNumber && !originalOwner?.hasLocalVatNumber));

const OwnerCheckContainer = (props) => {

    const {create, owner, setOwner, readOnly} = props;

    const {t} = useTranslation('owner');
    const {openTicket} = useSupport();

    const showToast = useShowToast();
    const userHasPermission = useUserHasPermission();

    const [showPopup, setShowPopup] = useState(false);
    const [mergeOwner, setMergeOwner] = useState(false);

    const [originalOwner] = useState(owner);
    const [remoteOwner, setRemoteOwner] = useState(null);

    const previousOwner = usePrevious(owner);

    const isAllowed = useMemo(() => userHasPermission({
        permission: "READ_OWNER_BY_VAT",
        orUserTypes: UserType.VETERINARY
    }), [userHasPermission]);

    const {data, refetch} = useGet({
        path: "/owners/get-by-vat/",
        lazy: true,
    });

    const searchWithParameters = useCallback(
        (queryParameters) => {
            refetch({queryParams: queryParameters})
        },
        [refetch]
    );

    const searchOwner = useDebounce(searchWithParameters, 750);

    const handleOwnerReject = useCallback(
        () => {
            openTicket({
                category: TicketCategory.ANIMAL_OWNER_ERRORS,
                subject: t('ticket_subject_merge', owner),
                description: t('ticket_subject_merge_msg', owner),
            });
            setShowPopup(false);
            setRemoteOwner(null);
            setMergeOwner(null);
            setOwner(() => ({
                ...originalOwner
            }));
        },
        [setShowPopup, originalOwner, setRemoteOwner, setMergeOwner, setOwner, openTicket, owner, t]
    )
    const handleOwnerAccept = useCallback(
        (useRemote) => {
            if (useRemote)
                setOwner(() => remoteOwner);
            setShowPopup(false);
            setRemoteOwner(null);
            setMergeOwner(remoteOwner);

        },
        [setShowPopup, remoteOwner, setRemoteOwner, setMergeOwner, setOwner]
    );

    const handleClose = useCallback(
        () => {
            setShowPopup(false);
            setRemoteOwner(null);
            setMergeOwner(null);
            setOwner(() => ({
                ...originalOwner
            }));
        },
        [setShowPopup, originalOwner, setRemoteOwner, setMergeOwner, setOwner]
    )

    useEffect(
        () => {
            if (data && Object.keys(data).length > 0) {
                if (!objectIsEqual(originalOwner, ownerResolver(data)) && !objectIsEqual(mergeOwner, ownerResolver(data)))
                    setRemoteOwner(ownerResolver(data));

            } else {
                setRemoteOwner(null);
            }
        },
        // eslint-disable-next-line
        [data, setRemoteOwner]
    );

    useEffect(
        () => {

            if (readOnly || !isAllowed)
                return;

            setMergeOwner(false);

            if (owner?.country && owner?.vatNumber && owner?.type !== undefined) {

                //Escape for the original owner
                if (!create && isOriginalOwner(originalOwner, owner)) {
                    return;
                }

                //Escape in case of usePortugueseVat and only country changed
                if (owner?.nationality === previousOwner?.nationality && owner?.nationality === appConfig.defaultCountry &&
                    owner?.vatNumber === previousOwner?.vatNumber && owner?.type === previousOwner.type) {

                    if (owner?.hasLocalVatNumber === previousOwner?.hasLocalVatNumber
                        && owner?.hasLocalVatNumber)
                        return;
                    else if (owner?.country === appConfig.defaultCountry && previousOwner?.hasLocalVatNumber)
                        return;
                    else if (previousOwner?.country === appConfig.defaultCountry && owner?.hasLocalVatNumber) {
                        return;
                    }
                }

                //Escape if nationality changed and user is portuguese resident
                if (owner?.country === appConfig.defaultCountry && owner?.country === previousOwner?.country
                    && owner?.vatNumber === previousOwner?.vatNumber && owner?.type === previousOwner.type) {
                    return;
                }

                searchOwner({
                    vatNumber: owner.vatNumber,
                    country: owner.country,
                    type: owner.type,
                    hasLocalVatNumber: owner?.hasLocalVatNumber,
                });
            }
        },
        // eslint-disable-next-line
        [owner?.country, owner?.vatNumber, owner?.type, owner?.hasLocalVatNumber, readOnly/**, isAllowed*/]
    );

    useEffect(
        () => {
            if (remoteOwner) {
                if (create) {
                    setOwner(() => remoteOwner);
                    setRemoteOwner(null);
                    setMergeOwner(remoteOwner);
                    showToast({
                        title: t('OWNER_DATA_LOADED'),
                        message: t('OWNER_DATA_LOADED_MSG', {name: remoteOwner.name}),
                        icon: faInfo,
                        className: 'primary',
                        duration: 8000,
                    });
                } else {
                    setShowPopup(true);
                }
            }
        },
        [remoteOwner, setRemoteOwner, create, setOwner, t, showToast]);

    if (readOnly || !isAllowed)
        return <></>
    else
        return <OwnerMergeComponent
            remoteOwner={remoteOwner}
            originalOwner={originalOwner}
            showPopup={showPopup}
            autoFilledOwner={mergeOwner}
            onAccept={handleOwnerAccept}
            onReject={handleOwnerReject}
            onClose={handleClose}
        />

}


export default OwnerCheckContainer;
