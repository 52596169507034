import React from "react"
import locale from "./locales/locales"
import Button from "react-bootstrap/Button";
import {ReactComponent as ErrorImage} from "./assets/images/error.svg";
import useTranslations from "system/Translations/UseTranslations";
import './assets/css/error.scss';

const ErrorPage = (props) => {

    const {t} = useTranslations('errors', locale);

    const {message = t("GENERIC_ERROR"), showGoDashboard} = props;

    return <div className="error-page">

        <div className="error-content">

            <div className="error-area">
                <div className="error-message-block">
                    <div className="title">{t('title')}</div>
                    <div className="message">{message}</div>
                    <div className="baseline">{t('baseline')}</div>
                    <div className="buttons">

                        {showGoDashboard
                            ? <Button variant="primary" onClick={() => {
                                window.location.href = "/"
                            }}>
                                {t('go_dashboard')}
                            </Button>
                            :
                            <a href={window.location.href}>
                                <Button variant="primary">
                                    {t('refresh')}
                                </Button>
                            </a>
                        }


                        <div>
                            <Button variant="primary" onClick={() => {
                                window.history.back()
                            }}>
                                {t('go_back')}
                            </Button>
                        </div>

                    </div>

                </div>

                <div className={"image"}>
                    {props.children
                        ? props.children
                        : <ErrorImage/>
                    }
                </div>
            </div>

        </div>
    </div>


}

export default ErrorPage;
