export const pt = {

    title: "Lista de modelos de operações",
    edit_title: "Editar {{name}}",
    create_title: "Criar operação",
    execute_title: "Executar operação",
    schedule_title: "Agendar operação",
    create_schedule_title: "Criar agendamento",
    edit_schedule_title: "Editar agendamento",

    //Form template
    edit_template: "Editar operação",
    template_base_config: "Configuração base",
    name_ph: "O nome do relatório",
    query: "Query",
    query_base: "Query base",
    enabled: "Ativo",
    true: "Sim",
    false: "Não",
    name: "Nome",
    category: "Categoria",
    async: "Assíncrono",
    roles: "Perfil de permissão",
    body: "Corpo da operação",
    preview_query: "Query de pré-visualização",
    create_form: "Criar operação",
    save_form: "Guardar operação",
    placeholder: "Selecione uma opção",
    type: "Tipo",
    UPDATE: "Query",
    STORED_PROCEDURE: "Stored Procedure",

    MISSING_TYPE: "Por favor indique o tipo",
    MISSING_NAME: "Por favor indique o nome do relatório",
    MISSING_ASYNC: "Por favor indique se o relatório é assincrono",
    MISSING_CATEGORY: "Por favor indique uma opção",
    MISSING_BODY: "Por favor indique o corpo da operação",

    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    CREATE_SUCCESS: "Operação criada",
    CREATE_SUCCESS_MSG: "A operação {{name}} foi criada com sucesso!",

    UPDATE_SUCCESS: "Operação atualizada",
    UPDATE_SUCCESS_MSG: "A operação {{name}} foi atualizada com sucesso!",

    SUCCESS_PREVIEW: "Pre-visualização",
    SUCCESS_PREVIEW_MSG: "Foi gerada uma pré-visualização da operação",

    SUCCESS_SUBMIT_REPORT: "Operação executada",
    SUCCESS_SUBMIT_ASYNC_REPORT: "Operação em execução",
    SUCCESS_SUBMIT_REPORT_MSG: "A operação foi executado com sucesso",
    SUCCESS_SUBMIT_ASYNC_REPORT_MSG: "A operação vai ser executado dentro de momentos.\r\nIrá receberá um email quando o ficheiro com o resultado estiver disponível.",

    DELETE_SUCCESS: "Operação apagada",
    DELETE_SUCCESS_MSG: "A operação {{name}} foi apagada com sucesso",

};

export default pt;
