import {useMemo} from "react";

const useOwnerErrorsAndChanges = (object) => useMemo(
    () => Object.keys(object)
        .filter(param => param.startsWith("owner"))
        .reduce((e, p) => ({
            ...e,
            [p.replace("owner.", "")]: object[p]
        }), {}),
    [object]
);

export default useOwnerErrorsAndChanges;
