import {useMutate} from "system/Rest/Rest";
import {useCallback} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import ReviewComponent from "app/owners/components/review/ReviewComponent";
import {useLocation} from "react-router";

const ReviewAlertContainer = props => {

    const {owner, reload} = props;
    const showToast = useShowToast();
    const {t} = useTranslation("owner");

    const location = useLocation();
    const reviewId = location?.state?.reviewId;

    const {mutate: setReviewed, loading: accepting} = useMutate({
        verb: "PUT",
        path: "/owner-review/" + owner?.id,
    });

    const {mutate: createTicket, loading: creatingTicket} = useMutate({
        verb: "PUT",
        path: "/owner-review/" + owner?.id + "/" +  reviewId + "/ticket"
    });


    return <ReviewComponent
        {...props}
        id={reviewId}
        loading={creatingTicket || accepting}
        onAccept={useCallback(
            () => {
                setReviewed({}).then(() => {
                    showToast({
                        title: t('SUCCESS_REVIEW'),
                        message: t('SUCCESS_REVIEW_MSG'),
                        icon: faCheck,
                    });
                    reload();
                });
            },
            [t, showToast, reload, setReviewed]
        )}
        onTicket={useCallback(
            (message) => {
                createTicket({
                    comment: message
                }).then(() => {
                    showToast({
                        title: t('SUCCESS_TICKET'),
                        message: t('SUCCESS_TICKET_MSG'),
                        icon: faCheck
                    });

                    reload();

                }).catch((e) => {
                    showToast({
                        title: t('ERROR_TICKET'),
                        message: t(e.data?.message ? e.data?.message : 'ERROR_TICKET_MSG'),
                        icon: faTimes,
                        className: 'error',
                    });
                });
            },
            [t, showToast, createTicket, reload]
        )}
    />

}

export default ReviewAlertContainer;
