import {AnimalStatus} from "types/Animal";

const isPrintDIACAllowed = (animals) => animals?.length > 0 && !animals.some(animal => !animal.isDataValid)
const isMultiSelectAllowed = (animal) => (!animal.hasPendingOwnerTransfer && animal.status === AnimalStatus.NORMAL);



export {
    isMultiSelectAllowed
}

export default isPrintDIACAllowed;
