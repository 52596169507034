import React, {useCallback, useRef, useState} from "react";
import {Overlay} from "react-bootstrap";
import classnames from "classnames";
import {useClickedOutsideEffect} from "system/Window/HandleClickOutside";

import './assets/css/menu-dropdown.scss'

const MenuDropdown = (props) => {

    const {children, disabled, placement = "bottom", className, onChange} = props;

    const [isVisible, setVisible] = useState(false);

    const target = useRef(null);
    const menuRef = useRef(null);

    useClickedOutsideEffect(target, useCallback((e) => {
            if (isVisible) {
                if (!menuRef.current.contains(e.target)) {
                    setVisible(false);
                    if (onChange) onChange(!isVisible);
                }

            }
        }, [setVisible, isVisible, onChange])
    );


    const handleClick = useCallback(
        () => {
            if (onChange) onChange(!isVisible);
            setVisible(!disabled && !isVisible);
        },
        [onChange, setVisible, disabled, isVisible]
    )

    return <div className={"menu-drop-down"} ref={target}>
        <div onClick={handleClick}>
            {React.cloneElement(children[0], {
                className: classnames(children[0].props.className, {'active': isVisible, 'disabled': disabled})
            })}
        </div>

        <Overlay
            ref={menuRef}
            target={target.current}
            show={isVisible}
            placement={placement}
            popperConfig={{
                modifiers: [{
                    name: 'preventOverflow',
                    options: {
                        mainAxis: true, // true by default
                        altAxis: true,
                    },
                },]
            }}>
            {({placement, arrowProps, show: _show, popper, hasDoneInitialMeasure, ...props}) => (
                <div {...props} onClick={handleClick} className={classnames("menu-drop-down-container", className)}>
                    {children[1]}
                </div>
            )}
        </Overlay>
    </div>

}

export default MenuDropdown;
