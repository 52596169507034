import React, {useCallback, useState} from "react";
import locale from "app/certificates/locales/locales";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useTranslations from "system/Translations/UseTranslations";
import SeparatorComponent from "app/animals/components/view/panel/SeparatorComponent";
import PopupFormContainer from "app/certificates/containers/generate/PopupFormContainer";
import "app/certificates/assets/certificate-animal-list.scss";
import "app/certificates/assets/certificate-modal.scss";

const AnimalListComponent = (props) => {

    const {t} = useTranslations('certificate', locale);
    const {certificates, onSubmit, downloading, errors} = props;
    const [selectedTemplate, setSelectedTemplate] = useState(false);

    const handleClosePopup = useCallback(() => {
        setSelectedTemplate(false);
    }, [setSelectedTemplate])

    return <>
        {(selectedTemplate !== false) &&
        <PopupFormContainer
            {...props}
            onClose={handleClosePopup}
            certificateTemplate={selectedTemplate}
            onSubmit={onSubmit}
            loading={downloading}
            errors={errors}
        />
        }

        <div className={"issue-documents"}>
            <div className={"title"}>
                {t('issue_documents')}
            </div>

            {certificates?.map((template, index) =>
                <div className={"certificate-link"} key={index} onClick={() => setSelectedTemplate(template)}>
                    {template.name}
                    <FontAwesomeIcon icon={faDownload}/>
                </div>
            )}
        </div>

        <SeparatorComponent/>
    </>
}

export default AnimalListComponent;
