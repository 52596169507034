import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import Col from "react-bootstrap/Col";
import {getOrNull} from "system/Objects/ObjectParameters";
import BreedGroupType from "types/BreedGroupType";
import BreedDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/BreedDropdown";

const BreedAdvancedDropdownComponent = props => {

    const {
        label,
        onChange,
        isDisabled,
        t: tt,
        mandatory,
        error,
        change,
        currentChoices,
        setCurrentChoices,
        allowOnlyEditFinalGroup
    } = props;

    const errorPosition = isDisabled ? -1 : currentChoices?.length;

    const handleItemChange = useCallback(
        (v, e, p) => {
            let choices = currentChoices.slice(0, p);

            if (e !== null) {
                choices[p] = e;
            }
            setCurrentChoices(choices);

            if (e?.groupType === BreedGroupType.BREED) {
                onChange(e.id, {
                    ...e,
                    parent: {
                        ...choices[p-1]
                    }
                });
            } else {
                onChange(null);
            }
        },
        [setCurrentChoices, currentChoices, onChange]
    );



    return <>

        <Col sm={12} lg={6}>
            <FormGroup error={getError(error, errorPosition, 0)} changes={change} mandatory={mandatory}>
                <Form.Label>{label}</Form.Label>

                <BreedDropdown
                    isMulti={false}
                    value={getOrNull(currentChoices[0]?.id)}
                    isDisabled={isDisabled || allowOnlyEditFinalGroup}
                    defaultSelectedOption={currentChoices[0]}
                    onChange={(e, v) => handleItemChange(e, v, 0)}
                    onlyRootBreeds={true}
                />

                <Form.Text className="sub-error">
                    {tt(getError(error, errorPosition, 0))}
                </Form.Text>
            </FormGroup>
        </Col>

        {currentChoices.map((entry, position) => {

            if (entry.groupType === BreedGroupType.BREED)
                return null;

            const isLocalDisabled = isDisabled || (allowOnlyEditFinalGroup && entry.groupType !== BreedGroupType.FINAL_GROUP);

            return <Col sm={12} lg={6} key={entry.id}>
                <FormGroup error={getError(error, errorPosition, position+1)} changes={change} mandatory={mandatory}>
                    <Form.Label>{entry.label}</Form.Label>

                    <BreedDropdown
                        isMulti={false}
                        value={getOrNull(currentChoices[position + 1]?.id)}
                        isDisabled={isLocalDisabled}
                        defaultSelectedOption={getOrNull(currentChoices[position + 1])}
                        onChange={(e, v) => handleItemChange(e, v, position + 1)}
                        parentId={entry.id}
                    />

                    <Form.Text className="sub-error">
                        {tt(getError(error, errorPosition, position+1))}
                    </Form.Text>
                </FormGroup>
            </Col>

        })}


    </>
}

const getError = (error, position, errorPosition) => (position === errorPosition || position === -1) ? error : undefined;

export default BreedAdvancedDropdownComponent;
