import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import GeneralSettingsContainer from "../containers/GeneralSettingsContainer";
import PasswordContainer from "../containers/PasswordContainer";
import VeterinaryEntitiesContainer from "../containers/VeterinaryEntitiesContainer";
import FileList from "app/files/List";
import {UserType} from "types/Users";
import {useApiState} from "system/API/APIStateContext";
import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import DocumentType from "types/Documents";
import AuditLogContainer from "app/personalArea/containers/AuditLogContainer";

const PersonalAreaComponent = (props) => {

    const {t} = useTranslation('personalArea');
    const {authentication} = useApiState();
    const [isEditMode, setEditMode] = useState();

    const {showTab, reload} = props;

    const isVeterinary = useMemo(
        () => UserType.VETERINARY === authentication.type,
        [authentication.type]
    );

    return <div className={"own-user"}>
        <Tabs defaultActiveKey={showTab ?? "settings"} id="tab-user" unmountOnExit={true} mountOnEnter={true}
              disabled={isEditMode}>
            <Tab eventKey="settings" title={t('settings_form')} disabled={isEditMode}>
                <GeneralSettingsContainer {...props} onEditModeChange={setEditMode} isVeterinary={isVeterinary}
                                          reload={reload}/>
            </Tab>

            {(!isVeterinary) &&
            <Tab eventKey="password" title={t('password_form')} unmountOnExit={true} mountOnEnter={true}
                 disabled={isEditMode}>
                <PasswordContainer {...props} reload={reload}/>
            </Tab>
            }

            {(isVeterinary) &&
            <Tab eventKey="entities" title={t('entities')} unmountOnExit={true} mountOnEnter={true}
                 disabled={isEditMode}>
                <VeterinaryEntitiesContainer {...props} />
            </Tab>
            }

            <Tab eventKey="files" title={t('files')} unmountOnExit={true} mountOnEnter={true} disabled={isEditMode}>
                <FileList
                    {...props}
                    type={isVeterinary ? DocumentType.VETERINARY : DocumentType.USER}
                    id={isVeterinary ? authentication?.veterinaryId : authentication?.id}
                />
            </Tab>

            <Tab eventKey="audit" title={t('audit')} unmountOnExit={true} mountOnEnter={true} disabled={isEditMode}>
                <AuditLogContainer {...props} />
            </Tab>

        </Tabs>
    </div>


}

export default PersonalAreaComponent;
