import {useLocation, useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {isPotentiallyDanger} from "app/animals/utils/AnimalResolver";
import {useTranslation} from "react-i18next";
import GenericPopupMessageComponent from "app/animals/components/generic/GenericPopupMessageComponent";
import {useGet} from "system/Rest/Rest";

const PotentiallyDangerousAlert = props => {

    const {animal} = props;
    const location = useLocation();
    const navigate = useNavigate();

    const {t} = useTranslation('animal');
    const [popupContent, setPopupContent] = useState(false);


    const {data, refetch} = useGet({
        path: "/animals/register-potentially-dangerous",
        lazy: true,
    });

    const isPossibleDangerous = isPotentiallyDanger(animal);

    useEffect(
        () => {
            setPopupContent(data)
        },
        [data]
    );

    useEffect(
        () => {
            if (isPossibleDangerous && location?.state?.create) {
                refetch();
                navigate(location?.pathname, {replace: true});
            }
        },
        [location?.state?.create, isPossibleDangerous, setPopupContent, refetch, location?.pathname, navigate]
    );

    if (popupContent) {
        return <GenericPopupMessageComponent
            popupContent={popupContent}
            setPopupContent={setPopupContent}
            confirm={t("confirm")}
            title={t("breed_popup_title")}
        />
    } else return <></>;


}

export default PotentiallyDangerousAlert;
