import React from 'react';
import {Outlet} from "react-router-dom";

const Login = (props) => {
    return <div className="login">
        <Outlet/>
    </div>;
}

export default Login;
