import React, {useCallback, useEffect, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import Loading from "layout/modules/Loading/Loading";
import Box from "layout/modules/Box/Box";
import "./legal-warning.scss";
import useWindowSize from "system/Window/WindowSize";
import classnames from "classnames";

const LegalWarningComponent = props => {

    const {t, loading, legalWarning, onAccept, onReject, labelAccept, labelReject, size} = props;

    const [isRead, setRead] = useState(false);
    const windowSize = useWindowSize();
    const ref = useRef(null);

    const handleScroll = useCallback(
        () => {
            if (ref.current) {
                const { scrollTop, scrollHeight, clientHeight } = ref.current;
                if ((scrollHeight - scrollTop) - clientHeight < 150) {
                    setRead(true);
                }
            }
        }, [setRead, ref]);

    useEffect(
        () => {

            if (ref.current) {
                const { scrollTop, scrollHeight, clientHeight } = ref.current;

                //Fix on some browsers
                if (!scrollHeight || scrollTop === undefined || !clientHeight) {
                    setRead(true);
                }
                else if (scrollHeight - scrollTop === clientHeight) {
                    setRead(true);
                }
                else {
                    setRead(false);
                }
            }
        },
        // eslint-disable-next-line
        [legalWarning, ref, setRead, windowSize]
    );

    return <div className={classnames("legal-warning", size)}>
        <Loading visible={loading}/>
        <Box className={size}>
            <div
                className={classnames("message", size)}
                ref={ref}
                dangerouslySetInnerHTML={{__html: legalWarning}}
                onScroll={handleScroll}
            />
            <div>
                {onReject &&
                <Button variant="danger" onClick={onReject} disabled={!isRead}>
                    {t(labelReject ?? "reject_legal_warning")}
                </Button>
                }

                <Button variant="primary" onClick={onAccept} disabled={!isRead}>
                    {t(labelAccept ?? "accept_legal_warning")}
                </Button>
            </div>

        </Box>

    </div>
}

export default LegalWarningComponent
