import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import DateTime from "system/DateTime/DateTime";
import React, {useState} from "react";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import LostContainer from "app/animals/containers/lost/LostContainer";
import LostStatusComponent from "app/animals/components/lost/list/LostStatusComponent";
import {useCountryTranslation} from "layout/modules/Forms/Dropdowns/CountryDropdown/useCountryList";

const AnimalLostListComponent = props => {

    const {
        data,
        loading,
        onPageSort,
        onPageChange,
    } = props;

    const {t} = useTranslation("animal");
    const getCountry = useCountryTranslation();

    const [selectedEntry, setSelectedEntry] = useState(false);

    return <Box className={"lost-list"}>

        {selectedEntry &&
        <LostContainer
            onClose={() => setSelectedEntry(null)}
            lostInfo={selectedEntry}
            transponder={selectedEntry?.transponder}
        />
        }

        <SearchDataPagination data={data} onPageChange={onPageChange}/>

        <TableAdvanced
            hover
            sort={data?.sortBy}
            order={data?.sortOrder?.toLowerCase()}
            onSortClick={onPageSort}
            loading={(loading)}>
            <thead>
            <tr>

                <th className={"d-none d-md-table-cell"}>
                    {t('lost_local')}
                </th>

                <th id={"creationTimestamp"} sorted>
                    {t('registration_date')}
                </th>

                <th id={"eventDate"} className={"d-none d-md-table-cell"} sorted>
                    {t('occurrence_date')}
                </th>

                <th>
                    {t('status')}
                </th>

                <th></th>

            </tr>
            </thead>
            <tbody>

            {data?.content?.map((lostInfo, index) => {
                return <tr key={index}>

                    <td className={"d-none d-md-table-cell col-location"}>
                        {lostInfo?.eventParish?.name
                            ? <>{lostInfo?.eventParish?.name}</>
                            : <>{getCountry(lostInfo?.eventCountry)}</>
                        }
                    </td>

                    <td className={"td-sorted"}>
                        <div className={"line-regular"}>
                            <DateTime format="DD-MM-YYYY" timezone={'UTC'}>
                                {lostInfo?.creationTimestamp}
                            </DateTime>
                        </div>
                    </td>

                    <td className={"d-none d-md-table-cell td-sorted"}>
                        <div className={"line-regular"}>
                            <DateTime format="DD-MM-YYYY" timezone={'UTC'}>
                                {lostInfo?.eventDate}
                            </DateTime>
                        </div>
                    </td>

                    <td>
                        <LostStatusComponent lostInfo={lostInfo}/>
                    </td>

                    <td className="table-buttons">
                        <div className="buttons">
                            <TableIconButton
                                onClick={() => setSelectedEntry(lostInfo)}
                                icon={faSearch}
                                className={"table-btn btn-rounded-primary small"}
                            />

                        </div>

                    </td>


                </tr>
            })}

            </tbody>
        </TableAdvanced>

    </Box>
}

export default AnimalLostListComponent;
