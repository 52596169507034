import useTranslations from "system/Translations/UseTranslations";
import locales from "app/animals/locales/locales";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const LostCloseByTransferAlertComponent = props => {

    const {t} = useTranslations("animal", locales);
    const {lostAnimalId,lostAnimalDeliveryStatus, pendingAnimalTransferId } = props;

    if (lostAnimalId) {
        return <AdvanceInfoMessage
            icon={faWarning}
            type={"warning"}
            iconType={"warning"}
            title={t("transfer_is_from_lost_close")}
            message={t("transfer_is_from_lost_close_msg",{status: t(lostAnimalDeliveryStatus?.toLowerCase())})}
        />
    }
    else if (pendingAnimalTransferId) {
        return <AdvanceInfoMessage
            icon={faWarning}
            type={"warning"}
            iconType={"warning"}
            title={t("lost_close_is_pending")}
            message={t("lost_close_is_pending_msg")}
        />
    }
    else
        return <></>


}

export default LostCloseByTransferAlertComponent;
