import {isCannotAntiRabies} from "app/interventions/utils/antiRabies/isAntiRabies";
import {isCannotSterilization} from "app/interventions/utils/sterilization/isSterilization";
import {isString} from "system/Utils/isString";

const interventionHasReason = (typeId) => isCannotAntiRabies(typeId) || isCannotSterilization(typeId);
const VALIDATE_REASON = (value, data) => {

    if (interventionHasReason(data?.typeId)) {
        if (!value || (isString(value) && value.trim().length < 1)) {
            return "INVALID_REASON"
        }
    }
    return null;
}

export {interventionHasReason, VALIDATE_REASON}
