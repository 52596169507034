import React from "react";
import List from "app/support/List";
import "app/home/assets/css/support.scss";

const SupportComponent = props => {

    return <List {...props} dashboard={true}/>
}

export default SupportComponent;
