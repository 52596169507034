import React from "react";
import locale from "locales/locales";
import {Link} from "react-router-dom";
import useTranslations from "system/Translations/UseTranslations";
import {useApiState} from "system/API/APIStateContext";
import useExitImpersonate from "app/personalArea/utils/useExitImpersonate";
import {useBillingBalanceState} from "modules/Billing/BillingStateContext";
import HeaderMessage from "layout/modules/InfoMessages/HeaderMessage";
import AnnouncementComponent from "layout/modules/BaseLayout/ContentArea/components/header/AnnouncementComponent";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const TopAlertMessages = (props) => {

    const {t} = useTranslations('global', locale);

    const {authentication} = useApiState();
    const exitImpersonate = useExitImpersonate();
    const billingState = useBillingBalanceState();

    //const generalAnnouncement = useGeneralAnnouncementState();
    return <div className={"header-messages"}>
        {authentication.impersonate &&
        <HeaderMessage
            type={"danger"}
            message={t('user_impersonate_msg', {name: authentication?.name})}
            action={exitImpersonate}
            actionLabel={t('exit_impersonate')}
        />
        }

        <AnnouncementComponent {...props}/>

        {(billingState.isAvailable && billingState.balance < 5) &&

        <>
            {billingState.balance === 0
                ? <HeaderMessage
                    type={"danger"}
                    message={t('no_balance_message')}
                    actionLabel={<ReferencesLink t={t}/>}
                />
                : <HeaderMessage
                    type={"warning"}
                    message={t('low_balance_message', {count: billingState.balance})}
                    actionLabel={<ReferencesLink t={t}/>}
                />
            }
        </>
        }
    </div>

}

const ReferencesLink = props => {
    const {t} = props;

    return <UserHasPermission permission={['READ_BILLING']}>
        <Link to={"/billing/charging/"}>
            {t('mb_references_list')}
        </Link>
    </UserHasPermission>
}

export default TopAlertMessages;
