import locale from "app/licensing/locales/locales";
import React, {useMemo} from "react";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import ListComponent from "app/licensing/components/ListComponent";


const List = (props) => {

    const {transponder} = props;

    return <GenericListContainer
        {...props}
        id={"animal-license"}
        module="animal-license"
        searchPath={"/animal-licenses/search"}
        locale={locale}
        hideTitle={!!transponder}
        queryParameters={useMemo(() => ({
            transponder: transponder,
            sortProperty: 'licenseDate',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 10,
        }),[transponder])}>

        <ListComponent {...props} />

    </GenericListContainer>


}
export default List;
