import Modal from "react-bootstrap/Modal";
import FormComponent from "./FormComponent";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import Loading from "layout/modules/Loading/Loading";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";

const PopupFormComponent = (props) => {

    const {onClose, certificateTemplate, onSubmit, loading, animal} = props;
    const {t} = useTranslation('certificate');

    const [request, setRequest] = useState({
        id: certificateTemplate.id,
        sendEmail: false,
        emailAddress: animal.owner?.email,
        properties: {},
    });

    return <Modal
        show={true}
        onHide={loading ? () => void 0 : onClose}
        size={"xl"}
        className={"certificate-modal"}
        backdrop="static"
        keyboard={false}
    >

        <Loading visible={loading} localOnly={true}/>
        <Modal.Header closeButton>
            <Modal.Title>{t('get_document', {name: certificateTemplate.name})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormComponent
                {...props}
                onClose={onClose}
                setRequest={setRequest}
                request={request}
            />
        </Modal.Body>

        <ModalButtons>
            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>

            <Button
                variant="primary"
                onClick={() => {
                    onSubmit(request).then(onClose);
                }}>
                {t("confirm")}
            </Button>
        </ModalButtons>

    </Modal>
}

export default PopupFormComponent;
