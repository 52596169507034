import React, {useCallback, useMemo} from "react";
import locale from "app/owners/locales/locales";
import {OwnerType} from "types/Onwer";
import useTranslations from "system/Translations/UseTranslations";
import AnimalFieldIsAllowed, {useAnimalFieldsAllowed} from "app/animals/utils/AnimalFieldsAllowed";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import useOwnerErrorsAndChanges from "app/animals/utils/useOwnerErrorsAndChanges";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import OwnerCheckContainer from "app/owners/containers/form/OwnerCheckContainer";
import TypeComponent from "app/owners/components/form/TypeComponent";
import PersonalDataComponent from "app/owners/components/form/PersonalDataComponent";
import EntityResponsibleComponent from "app/owners/components/form/EntityResponsibleComponent";
import AddressComponent from "app/owners/components/form/AddressComponent";
import ContactsComponent from "app/owners/components/form/ContactsComponent";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import OwnerAlertsComponent from "app/owners/components/view/OwnerAlertsComponent";

const OwnerComponent = (props) => {

    const {t} = useTranslations('owner', locale);

    const {errors: animalErrors, readOnly, changes: animalChanges, compareMode, create, showLink} = props;

    const isFieldAllowed = useAnimalFieldsAllowed(readOnly, create, animalErrors);

    const {
        animal: {
            owner = {}
        },
        setAnimal
    } = useAnimalState();

    const setOwner = useCallback(
        (fnc) => setAnimal(animal => ({
            ...animal,
            owner: {
                ...fnc(animal.owner)
            }
        })),
        [setAnimal]
    );

    const errors = useOwnerErrorsAndChanges(animalErrors);
    const changes = useOwnerErrorsAndChanges(animalChanges);

    const ownerLockFields = useMemo(
        () => {

            const vatAllowed = isFieldAllowed('owner.vatNumber');
            const idCardAllowed = isFieldAllowed('owner.idCard');

            return {
                type: !isFieldAllowed('owner.type'),
                name: !isFieldAllowed('owner.name'),
                passport: !isFieldAllowed('owner.passport'),
                idCard: !idCardAllowed,
                nationality: (!isFieldAllowed('owner.nationality') && !vatAllowed && !idCardAllowed),
                country: (!isFieldAllowed('owner.country') && !vatAllowed),
                vatNumber: !vatAllowed && !create,
                email: !isFieldAllowed('owner.email'),
                allowVatChange: create,
            }
        },
        [isFieldAllowed, create]);

    return <>

        <OwnerAlertsComponent {...props} owner={owner} t={t}/>

        <Box>
            <BoxTitle>{t('owner_type')}</BoxTitle>
            <BoxOptions>

                {showLink &&
                <UserHasPermission permission="READ_OWNER">
                    <ToolTip content={t('view_owner')}>
                        <Link to={"/owner/" + owner.id}>
                            <div className={classnames("btn-rounded-primary small")}>
                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                            </div>
                        </Link>
                    </ToolTip>
                </UserHasPermission>
                }

            </BoxOptions>

            {!readOnly &&
            <OwnerCheckContainer
                owner={owner}
                setOwner={setOwner}
                readOnly={readOnly}
                create={create}
            />
            }

            <TypeComponent
                errors={errors}
                owner={owner}
                changes={changes}
                setOwner={setOwner}
                readOnly={readOnly}
                lockFields={ownerLockFields}
                create={create}
            />
        </Box>

        {owner.country &&
        <>
            <PersonalDataComponent
                errors={errors}
                owner={owner}
                changes={changes}
                setOwner={setOwner}
                readOnly={readOnly}
                lockFields={ownerLockFields}
                create={create}
            />

            {(owner.type === OwnerType.COLLECTIVE) &&
            <AnimalFieldIsAllowed field={"owner.responsible"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <EntityResponsibleComponent
                    errors={errors}
                    owner={owner}
                    changes={changes}
                    setOwner={setOwner}
                    readOnly={readOnly}
                    create={create}
                />
            </AnimalFieldIsAllowed>
            }

            <AnimalFieldIsAllowed field={"owner.address"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <AddressComponent
                    errors={errors}
                    owner={owner}
                    changes={changes}
                    setOwner={setOwner}
                    readOnly={readOnly}
                    compareMode={compareMode}
                    lockFields={ownerLockFields}
                />
            </AnimalFieldIsAllowed>

            <AnimalFieldIsAllowed field={"owner.phone"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <ContactsComponent
                    compareMode={compareMode}
                    errors={errors}
                    owner={owner}
                    changes={changes}
                    setOwner={setOwner}
                    readOnly={readOnly}
                    lockFields={ownerLockFields}
                />
            </AnimalFieldIsAllowed>

        </>
        }


    </>;
}

export default OwnerComponent;
