export const en = {

    cancel: "No",
    continue: "Yes",

    writeConfirmationLabel: "Please type \"{{label}}\" ",

};

export default en;
