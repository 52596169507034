const handleEnterDownAndSpace = (e, fnc) => {

    if (e.key === 'Enter' || e.key === 'ArrowDown' || e.key === ' ') {
        fnc();
    }
};

const handleEscapePress = (e, fnc) => {

    if (e.key === 'Escape')  {
        fnc();
    }
};



const handleEnterPress = (e, fnc) => {

    if (e.key === 'Enter' || e.key === ' ') {
        fnc();
    }
};


const generateOnClickAndEnter = (fnc, tabIndex = 0) => ({
    onClick: () => {
        fnc()
    },
    onKeyDown: (e) => {
        handleEnterPress(e,fnc)
    },
    tabIndex: tabIndex,
});

export {generateOnClickAndEnter, handleEnterPress,handleEscapePress,handleEnterDownAndSpace}
