import Form from "react-bootstrap/Form";
import React, {useCallback, useRef, useState} from "react";
import {getFormData} from "../../../system/Forms/FormHandling";
import FormHandleNotSaved from "./FormHandleNotSaved";

const AdvancedForm = (props) => {

    const {loading, children, readOnly, setReadOnly, onSubmit, ...otherProps} = props;
    const formRef = useRef();

    const [initialFormData, setInitialFormData] = useState(null);
    const [currentFormData, setCurrentFormData] = useState(null);

    const [formVersion, setFormVersion] = useState(1);

    const handleSubmit = useCallback((data) => onSubmit(data), [onSubmit]);
    const handleReset = useCallback(() => {
        setFormVersion(formVersion => formVersion + 1);
        setInitialFormData(null);
        setCurrentFormData(null);
    }, [setFormVersion, setInitialFormData, setCurrentFormData]);

    const updateFormData = useCallback(
        () => {

            const formData = getFormData(formRef.current, false);
            if (!readOnly) {
                if (!initialFormData) {
                    setInitialFormData(getFormData(formRef.current, false));
                }
                setCurrentFormData(formData);
            }
        },
        [formRef, setCurrentFormData, readOnly, initialFormData, setInitialFormData]
    );

    return <>
        {(initialFormData && currentFormData) &&
        <FormHandleNotSaved
            remote={initialFormData}
            local={currentFormData}
            loading={loading}
            readOnly={readOnly}
            setLocal={handleReset}
            setReadOnly={setReadOnly}
            onSubmit={handleSubmit}/>
        }
        <Form {...otherProps}
              key={"form-" + formVersion}
              ref={formRef}
              onSubmit={onSubmit}
              onBlur={updateFormData}
              onClick={updateFormData}
              onInput={updateFormData}
              onKeyPress={updateFormData}
        >
            {children}
        </Form>
    </>

}

export default AdvancedForm;
