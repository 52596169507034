const getAsArray = (value) => {
    if (value === null || value === undefined)
        return [];
    else if (!Array.isArray(value))
        return [value];
    else
        return value;
}

export default getAsArray;
