import {useApiState} from "../APIStateContext";
import * as PropTypes from "prop-types"
import {useCallback, useMemo} from "react"
import getAsArray from "../../Arrays/getArray";
import {getOrDefault} from "system/Objects/ObjectParameters";

const UserHasNoPermission = (props) => {
    return <UserHasPermission {...props} inverted={true}/>
}

const useUserHasPermission = () => {

    //Authentication Context Usage
    //const {authentication: {role: {permissions}, type}} = useApiState();
    const {authentication} = useApiState();
    const permissions = getOrDefault(authentication?.role?.permissions,[]);
    const type = authentication?.type;

    return useCallback((props) => {

        const {permission, inverted = false, any = false, orUserTypes = []} = props
        let hasPermission;

        if (any)
            hasPermission = getAsArray(permission).some(v => permissions.includes(v))
        else
            hasPermission = getAsArray(permission).every(v => permissions.includes(v))

        if (inverted) {
            hasPermission = !hasPermission;
        }

        if (!hasPermission && orUserTypes.length > 0)
            hasPermission = getAsArray(orUserTypes).some(ut => ut === type);

        return hasPermission

    }, [permissions, type]);

}

const UserHasPermission = (props) => {

    const userHasPermission = useUserHasPermission();

    const hasPermission = useMemo(
        () => userHasPermission(props),
        [userHasPermission, props]
    );

    if (hasPermission) {
        return props.children;
    } else {
        return null;
    }
}

export default UserHasPermission;
export {UserHasNoPermission, useUserHasPermission};

UserHasPermission.propTypes = {
    permission: PropTypes.any,
    inverted: PropTypes.bool,
    any: PropTypes.bool
};
