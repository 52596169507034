import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import {getOrEmpty} from "system/Objects/ObjectParameters";

const FormComponent = (props) => {

    const {transponder, errors, license, setLicense, readOnly} = props;
    const {t} = useTranslation("animal-license");

    return <Box>
        <BoxTitle>
            {readOnly
                    ? t('licensing')
                    : t('add_licensing', {transponder: transponder})
            }
        </BoxTitle>
        <Row>
            <Col sm={12} xl={6}>
                <FormGroup error={errors['licenseDate']}>
                    <Form.Label>{t('license_date')}</Form.Label>
                    <DatePicker
                        placeholder={t("license_ph")}
                        onlyPastDates={true}
                        timezone={"UTC"}
                        disabled={readOnly}
                        value={getOrEmpty(license?.licenseDate)}
                        onChange={useCallback(
                            date => setLicense(license => ({
                                ...license,
                                licenseDate: date,
                            })), [setLicense]
                        )}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['licenseDate'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Row>
    </Box>
}

export default FormComponent;
