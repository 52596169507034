import {useGet, useMutate} from "system/Rest/Rest";
import {useCallback, useEffect, useState} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import animalLostResolver from "app/animals/utils/AnimalLostResolver";
import useErrorParser from "system/API/Error/ErrorParser";
import ModalComponent from "app/animals/components/lost/ModalComponent";
import Loading from "layout/modules/Loading/Loading";

const LostContainer = (props) => {

    const {transponder, reload: remoteReload, onClose, lostInfo: remoteLostInfo} = props;
    const showToast = useShowToast();
    const {t} = useTranslation("animal");

    const create = !remoteLostInfo;

    const [lostInfo, setLostInfo] = useState(create ? animalLostResolver(transponder) : null);
    const [data, setData] = useState(null);


    const {mutate: submit, loading: submitting, error} = useMutate({
        verb: remoteLostInfo ? "PUT" : "POST",
        path: "/lost-animals/" + (remoteLostInfo ? transponder : ""),
    });

    const {data: rData, loading, refetch} = useGet({
        path: "/lost-animals/id/" + remoteLostInfo?.id,
        resolve: useCallback((data) => animalLostResolver(transponder, data), [transponder]),
        lazy: create
    });

    const reload = useCallback(
        () => {
            refetch();
            remoteReload();
        },
        [remoteReload, refetch]
    );

    useEffect(() => {
            if (data)
                setLostInfo(data)
        }, [data]
    );

    useEffect(() => {
        if (rData)
            setData(rData);
    }, [rData]);

    const handleSubmit = useCallback(() => {
        submit(lostInfo).then(() => {
            showToast({
                title: t('SUCCESS_SET_LOST_' + lostInfo.status),
                message: t('SUCCESS_SET_LOST_' + lostInfo.status + "_MSG", {transponder: lostInfo.transponder}),
                icon: faCheck,
            });

            onClose();
            reload();
        }).catch(e => {
            if (e.data?.message) {
                showToast({
                    title: t('FORM_ERROR'),
                    message: t(e.data?.message ? e.data?.message : 'ERROR_SET_LOST'),
                    icon: faTimes,
                    className: 'error',
                });
            }

        });


    }, [lostInfo, showToast, t, submit, reload, onClose]);

    const errors = useErrorParser(error);

    if ((!data || !lostInfo) && !create)
        return <Loading visible={true}/>

    return <ModalComponent
        {...props}
        errors={errors}
        lostInfo={lostInfo}
        setLostInfo={setLostInfo}
        create={create}
        remoteLostInfo={data}
        loading={loading || submitting}
        onSubmit={handleSubmit}
        reloadLostInfo={refetch}

    />


}

export default LostContainer;
