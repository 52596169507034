import Select from "react-select";
import React, {useCallback, useMemo} from "react";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales";
import getVersionLabel from "modules/VersionsContainer/utils/label";
import {useDateTime} from "system/DateTime/DateTime";
import {getValuesArrayFromObjectsArray} from "layout/modules/Forms/Dropdowns/utils/utils";

const VersionDropdown = props => {

    const {
        id = "versionId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        versions,
        excludeVersion,
        onChange,
        value,
        onlyMainVersions,
        isMulti,
        t: tMain,
        ...otherProps
    } = props;

    const {t} = useTranslations('classDropdown', locale);
    const getDate = useDateTime();

    const versionOptions = useMemo(
        () => versions?.map((version, index) => {
            if (version?.isSecondary && onlyMainVersions) {
                return undefined;
            }
            return {
                label: getVersionLabel(index, version, getDate, tMain ?? t),
                value: version.revisionId + "_" + version.revisionTimestamp,
                revisionId: version.revisionId,
                isSecondary: version.isSecondary,
                revisionTimestamp: version.revisionTimestamp,
                isDisabled: (version.revisionId === excludeVersion?.revisionId
                    && version.revisionTimestamp === excludeVersion?.revisionTimestamp)
            }
        }).reverse().filter(e => e !== undefined),
        [versions, getDate, excludeVersion, t, tMain, onlyMainVersions]
    );

    return <Select
        {...otherProps}
        id={id}
        classNamePrefix="rs"
        className="rs"
        isMulti={isMulti}
        placeholder={usePlaceHolder(placeholder, t('version_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage, t('versions_no'))}
        loadingMessage={useLabelCallback(loadingMessage, t('version_loading'))}
        options={versionOptions}
        styles={versionStyle}
        value={useMemo(
            () => {
                if (value) {
                    const valueId = value.id + "_" + value.revisionTimestamp
                    let selectedVersion = versionOptions?.find(e => e.value === valueId)

                    if (!selectedVersion) {
                        return versionOptions?.find(e => e.revisionId === valueId)
                    }
                    return selectedVersion;
                } else if (value === undefined) {
                    return undefined;
                } else {
                    return null;
                }
            },
            [value, versionOptions]
        )}

        onChange={useCallback(
            (options) => onChange(getValuesArrayFromObjectsArray(options, isMulti, 'revisionId'), options),
            [onChange,isMulti]
        )}
    />
}

const versionStyle = {
    option: (styles, {data}) => {
        return {
            ...styles,
            "fontStyle": data?.isSecondary ? "italic" : undefined,
            "fontWeight": !data?.isSecondary ? "500" : "300",
        };
    },
}

export default VersionDropdown;
