import useWindowSize from "./WindowSize";
import {maxWidthForResponsive} from "layout/modules/BaseLayout/Menu/utils/Utils";



const useIsMobile = () => {

    const {width} = useWindowSize();
    return width <= maxWidthForResponsive;
}


const NotInMobile = props => {

    const isMobile = useIsMobile();

    if (!isMobile)
        return props.children;

    return <></>;

}

export {NotInMobile, useIsMobile};
