import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";

const EmailComponent = (props) => {

    const {t} = useTranslation('certificate');
    const {errors, request, setRequest} = props;

    return <Box>
        <BoxTitle>{t('email_box_title')}</BoxTitle>
        <Row>
            <Col sm={12} xl={12}>
                <FormGroup error={errors['emailAddress']}>
                    <Form.Label>{t('email')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('email_ph')}
                        value={getOrEmpty(request.emailAddress)}
                        onChange={(e) => setRequest(request => ({
                            ...request,
                            emailAddress: e.target.value,
                        }))}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['emailAddress'])}
                    </Form.Text>
                </FormGroup>
            </Col>

        </Row>
    </Box>
}

export default EmailComponent;
