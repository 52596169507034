import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import List from "app/control/modules/preRegister/List";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/control/modules/preRegister/locales/locales";

const TabList = props => {

    const {t} = useTranslations('preRegister', locale);

    return <Tabs id={"pre-registers"} unmountOnExit={true} mountOnEnter={true} defaultActiveKey={"open"}>

        <Tab eventKey="open" title={t('open_requests')}>
            <List {...props} isClosed={false} id={"open-pre-register"}/>
        </Tab>

        <Tab eventKey="close" title={t('closed_requests')}>
            <List {...props} isClosed={true} id={"closed-pre-register"}/>
        </Tab>

    </Tabs>


}

export default TabList;
