export const en = {
    entries_one: "Entry",
    entries_other: "Entries",
    of: "of",

    //Buttons
    show_table_as_list: "Show as List",
    show_table_as_grid: "Show as Grid",
    button_add: "Add New",
};

export default en;
