import ButtonComponent from "../../../layout/modules/BaseLayout/ContentArea/components/header/ButtonComponent";
import {faComment, faCommentSlash} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useState} from "react";
import PopupComponent from "./PopupComponent";
import ToolTip from "../../../layout/modules/ToolTip/ToolTip";
import useTranslations from "../../../system/Translations/UseTranslations";
import locale from "../locales/locales";

const ObservationButtonComponent = (props) => {

    const {t} = useTranslations("observation", locale)

    const {count, path, reload, btnRegular, allowFix} = props;
    const [showComments, setShowComments] = useState(false);

    const handleClose = useCallback(
        () => {
            setShowComments(false);
            reload();
        },
        [setShowComments, reload]
    );
    const handleOpen = useCallback(() => setShowComments(true), [setShowComments]);

    return <>

        <ToolTip content={t('observations')}>
            <ButtonComponent
                className={!btnRegular ? "small" : ""}
                icon={(count > 0) ? faComment : faCommentSlash}
                blink={(count > 0)}
                onClick={handleOpen}
            />
        </ToolTip>

        {showComments &&
        <PopupComponent onClose={handleClose} path={path} allowFix={allowFix}/>
        }

    </>


}

export default ObservationButtonComponent;
