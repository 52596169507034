import Container from "react-bootstrap/Container";
import {faCheck, faDownload, faSearch, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useMemo, useState} from "react";
import locale from "../locales/locales";
import PreviewContainer from "../containers/PreviewContainer";
import UploadContainer from "../containers/UploadContainer";
import DownloadContainer from "../containers/DownloadContainer";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useTranslations from "system/Translations/UseTranslations";
import {useRemoteEntity} from "modules/GenericRemoteClassFetcher/GenericSubClassFetcher";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import DateTime from "system/DateTime/DateTime";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import canPreviewFile from "app/files/utils/canPreviewFile";

const ListComponent = (props) => {

    const {t} = useTranslations("file", locale);
    const showToast = useShowToast();

    const {data, onSearch, onPageChange, onPageSort, loading, searchFilter, onDelete} = props;

    const [createdBy, loadingUsers] = useRemoteEntity(data, '/users/search', 'creationUserId', "UPLOAD_DOCUMENTS");

    const [deletedInfo, setDeletedInfo] = useState({});
    const [preview, setPreview] = useState(null);
    const [download, setDownload] = useState(null);

    const handlePreviewClose = useCallback(() => setPreview(null), [setPreview]);
    const handleDownloadComplete = useCallback(() => setDownload(null), [setDownload]);

    const isFileWithExpirationDatePresent = useMemo(
        () => data?.content?.find(v => v.expirationDate),
        [data?.content]
    );

    return <Container fluid>

        <PopupConfirm
            title={t('DELETE_FILE_CONFIRM')}
            message={t('DELETE_FILE_CONFIRM_MSG', {name: deletedInfo.name})}
            classConfirm={"danger"}
            visible={!!deletedInfo.id}
            onCancel={useCallback(
                () => setDeletedInfo({}),
                [setDeletedInfo]
            )}
            onConfirm={useCallback(
                () => {
                    setDeletedInfo({})
                    onDelete(
                        deletedInfo.id, {name: deletedInfo.name},
                        () => showToast({
                            title: t('SUCCESS_DELETE_FILE'),
                            message: t('SUCCESS_DELETE_FILE_MSG', {name: deletedInfo.name}),
                            icon: faCheck,
                        }),
                        () => showToast({
                            title: t('FAIL_DELETE_FILE'),
                            message: t('FAIL_DELETE_FILE_MSG', {name: deletedInfo.name}),
                            className: 'error',
                            icon: faTimes,
                        })
                    );
                },
                [onDelete, setDeletedInfo, deletedInfo.name, deletedInfo.id, showToast, t]
            )}
        />

        {preview &&
        <PreviewContainer file={preview} onClose={handlePreviewClose}/>
        }

        {download &&
        <DownloadContainer file={download} onDownloadComplete={handleDownloadComplete}/>
        }

        <UserHasPermission permission={['UPLOAD_DOCUMENTS']}>
            <UploadContainer {...props} />
        </UserHasPermission>

        <Box>

            <TableHeader>
                <SearchArea onSearch={onSearch} value={searchFilter.universalSearch}/>
            </TableHeader>

            <SearchDataPagination data={data} onPageChange={onPageChange}/>

            <TableAdvanced
                hover
                sort={data?.sortBy}
                order={data?.sortOrder?.toLowerCase()}
                onSortClick={onPageSort}
                loading={(loading || !data || loadingUsers)}>

                <thead>
                <tr>

                    <th id="filename" sorted>
                        {t('filename')}
                    </th>


                    <th id="type">
                        {t('type')}
                    </th>


                    <UserHasPermission permission={['UPLOAD_DOCUMENTS']}>
                        <th className={"d-none d-lg-table-cell"}>
                            {t('user')}
                        </th>
                    </UserHasPermission>

                    <th id="creationTimestamp" sorted className={"d-none d-lg-table-cell"}>
                        {t('date')}
                    </th>

                    {isFileWithExpirationDatePresent &&
                    <th id="expirationDate" className={"d-none d-lg-table-cell"}>
                        {t('expiration_date')}
                    </th>
                    }

                    <th/>

                </tr>
                </thead>
                <tbody>

                {data?.content?.map((file, index) => {
                    return <tr key={index}>

                        <td className="td-sorted">
                            {file.title ? file.title : file.filename}
                        </td>

                        <td>
                            {t(file.type)}
                        </td>

                        <UserHasPermission permission={['UPLOAD_DOCUMENTS']}>
                            <td className={"d-none d-lg-table-cell fill-empty"}>
                                {createdBy[file.creationUserId]?.name}
                            </td>
                        </UserHasPermission>

                        <td className={"td-sorted d-none d-lg-table-cell"}>
                            <DateTime format="DD-MM-YYYY | HH:mm:ss">
                                {file.creationTimestamp}
                            </DateTime>
                        </td>

                        {isFileWithExpirationDatePresent &&
                        <td className={"d-none d-lg-table-cell"}>
                            <DateTime format="DD-MM-YYYY">
                                {file.expirationDate}
                            </DateTime>
                        </td>
                        }


                        <td className="table-buttons">
                            <div className={"buttons"}>

                                {canPreviewFile(file) &&
                                <TableIconButton
                                    icon={faSearch}
                                    className={"table-btn btn-rounded-primary small"}
                                    onClick={() => setPreview(file)}
                                />
                                }

                                <TableIconButton
                                    icon={faDownload}
                                    className={"table-btn btn-rounded-primary small"}
                                    onClick={() => setDownload(file)}
                                />

                                <TableIconButton
                                    icon={faTrash}
                                    permission={"DELETE_DOCUMENTS"}
                                    onClick={() => {
                                        setDeletedInfo({
                                            id: file.id,
                                            name: file.filename
                                        })
                                    }}
                                    className={"table-btn btn-rounded-danger small"}
                                />
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
            <div className={"no-table-results"}>
                {t('no_results')}
            </div>
            }
        </Box>
    </Container>
}

export default ListComponent;
