import {useCallback, useMemo} from "react";
import {useAnimalFieldsAllowed} from "./AnimalFieldsAllowed";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {OwnerType} from "types/Onwer";
import {parseServerFormError} from "system/API/Error/ErrorParser";
import {AnimalStatus} from "types/Animal";
import {UserType} from "types/Users";


const AnimalOperations = {
    SET_LOST: "SET_LOST",
    SET_FOUND: "SET_FOUND",
    SET_DELIVERED: "SET_DELIVERED",
    SET_DEAD: "SET_DEAD",
    SET_STERILIZATION: "SET_STERILIZATION",
    REGISTER_INTERVENTION: "REGISTER_INTERVENTION",
    REGISTER_LICENSING: "REGISTER_LICENSING",
    SET_NOT_DEAD: "SET_NOT_DEAD",
    REGISTER_AGGRESSION: "REGISTER_AGGRESSION",
    CHANGE_OWNER: "CHANGE_OWNER",
    PRINT_CERTIFICATE: "PRINT_CERTIFICATE",
    SET_PHOTO: "SET_PHOTO",
    DELETE: "DELETE",
    EDIT: "EDIT",

};

const useAnimalOperationAllowed = (animal) => {

    //SET_LOST //SET_FOUND //SET_DELIVERED //EDIT
    const userHasPermission = useUserHasPermission();

    const animalStatus = animal?.status;
    const isLocked = animal?.isLocked;
    const isExternal = animal?.isExternal;
    const isTraces = animal?.owner?.type === OwnerType.TRACES;
    const isDataValid = animal?.isDataValid;
    const hasPendingOwnerTransfer = animal?.hasPendingOwnerTransfer;
    const isSterilized = animal?.isSterilized;

    const errors = useMemo(
        () => Array.isArray(animal.errors) ? parseServerFormError(animal.errors) : animal.errors,
        [animal.errors]
    );

    const isAnimalFieldAllowed = useAnimalFieldsAllowed(false, false, errors);

    return useCallback(
        (operation) => {

            switch (operation) {

                case AnimalOperations.SET_PHOTO:
                case AnimalOperations.EDIT:

                    if (isExternal || isLocked || hasPendingOwnerTransfer || AnimalStatus.DEAD === animalStatus ||
                        checkIfIsLostAndWithoutPermission(animalStatus, userHasPermission))
                        return false;

                    if (checkIfUserCannotEditErrorFields(errors, isAnimalFieldAllowed)) {
                        return false;
                    }

                    if (isTraces && !userHasPermission({permission: "REGISTER_OWNER_TRACES"}))
                        return false;

                    return (userHasPermission({
                            any: true,
                            permission: [
                                'WRITE_ANIMAL_CHARS_GROUP_2',
                                'WRITE_ANIMAL_CHARS_GROUP_2',
                                'WRITE_ANIMAL_CONTACTS',
                                'WRITE_ANIMAL_NAME',
                                'WRITE_ANIMAL_PROTECTED_FIELDS'
                            ],
                            orUserTypes: UserType.VETERINARY
                        }
                    ));

                case AnimalOperations.CHANGE_OWNER:
                    return (
                        !hasPendingOwnerTransfer && !isLocked && !isExternal &&
                        AnimalStatus.NORMAL === animalStatus &&
                        userHasPermission({
                            permission: "REQUEST_ANIMAL_TRANSFER",
                            orUserTypes: UserType.VETERINARY
                        })
                    );

                case AnimalOperations.REGISTER_LICENSING:
                    return (
                        AnimalStatus.NORMAL === animalStatus &&
                        userHasPermission({
                            permission: "WRITE_ANIMAL_LICENSE"
                        }));
                case AnimalOperations.REGISTER_INTERVENTION:
                    return (
                        (AnimalStatus.NORMAL === animalStatus || AnimalStatus.FOUND === animalStatus) &&
                        !isLocked && !isExternal &&
                        userHasPermission({
                            permission: "WRITE_HEALTH_INTERVENTION",
                            orUserTypes: UserType.VETERINARY
                        }));
                case AnimalOperations.SET_STERILIZATION:
                    return (AnimalStatus.NORMAL === animalStatus && !isSterilized &&
                        !isLocked && isDataValid && !hasPendingOwnerTransfer);
                case AnimalOperations.SET_DEAD:
                    return (
                        AnimalStatus.NORMAL === animalStatus && !isLocked &&
                        userHasPermission({
                            permission: "WRITE_ANIMAL_DEAD",
                            orUserTypes: UserType.VETERINARY
                        }));
                case AnimalOperations.SET_NOT_DEAD:
                    return (AnimalStatus.DEAD === animalStatus && userHasPermission({permission: "WRITE_ANIMAL_UNDEAD"}))
                case AnimalOperations.SET_FOUND:
                case AnimalOperations.SET_LOST:
                    return (AnimalStatus.NORMAL === animalStatus &&
                        userHasPermission({permission: "REGISTER_LOST_ANIMAL", orUserTypes: UserType.VETERINARY}));
                case AnimalOperations.SET_DELIVERED:
                    return ([AnimalStatus.FOUND, AnimalStatus.LOST].includes(animalStatus) && !isLocked &&
                        userHasPermission({permission: "UPDATE_LOST_ANIMAL_STATUS", orUserTypes: UserType.VETERINARY}));
                case AnimalOperations.REGISTER_AGGRESSION:
                    return (AnimalStatus.DEAD !== animalStatus && userHasPermission({permission: "CREATE_AGGRESSION"}));
                case AnimalOperations.DELETE:
                    return false;
                case AnimalOperations.PRINT_CERTIFICATE:
                    return !isLocked;
                default:
                    return true;

            }
        },
        [isAnimalFieldAllowed, userHasPermission, animalStatus, isTraces, isSterilized,
            isLocked, hasPendingOwnerTransfer, isDataValid, isExternal, errors]
    )
}


const checkIfUserCannotEditErrorFields = (errors = {}, isAnimalFieldAllowed) => {
    return Object.keys(errors).some(key => !isAnimalFieldAllowed(key));
}

const checkIfIsLostAndWithoutPermission = (animalStatus, userHasPermission) => {
    return ([AnimalStatus.LOST, AnimalStatus.FOUND].includes(animalStatus) &&
        !userHasPermission({permission: "EDIT_ANIMAL_LOST"}))
}

const AnimalOperationAllowed = (props) => {

    const {animal, operation} = props;
    const isOperationAllowed = useAnimalOperationAllowed(animal);

    if (isOperationAllowed(operation)) {
        return props.children;
    }

    return <></>;

}


export default AnimalOperationAllowed;
export {AnimalOperations, useAnimalOperationAllowed};
