import React from 'react';
import locale from "locales/locales"
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router";
import classnames from "classnames";
import logo from "layout/assets/images/logo.png";
import useTranslations from "system/Translations/UseTranslations";
import 'layout/modules/BaseLayout/ContentArea/css/header.scss'
import 'layout/modules/BaseLayout/ContentArea/css/header-responsive.scss'
import {useLocation} from "react-router-dom";

const Header = (props) => {

    const {title} = props;
    const {t} = useTranslations('global', locale);

    const navigate = useNavigate();
    const location = useLocation();

    const isDashboard = location.pathname.length <= 1;

    if (isDashboard) return <></>

    return <div className={classnames("content-header")}>

        <div className={"content-header-title"}>
            <div className={"page-name"}>
                {title}
            </div>

            <div className={"bar-logo"}>
                <img src={logo} className="logo" alt={"logo"}/>
            </div>

            {(!isDashboard && window.history.length > 1) ? <div className="go-back" onClick={() => navigate((-1))}>
                <div className={"go-back-icon"}>
                    <FontAwesomeIcon icon={faAngleLeft}/>
                </div>
                <span className="go-back-label">
                    {t('go_back')}
                    </span>
            </div> : <div className="go-back">&nbsp;</div>}

        </div>


        {/**<div className={"content-header-buttons"}>
         <UserComponent/>
         </div>*/}

    </div>

}


export default Header;
