export const pt = {

    help: "Ajuda",
    go_back: "< Voltar atrás",

    title: "Nome do Artigo",
    title_ph: "Por favor indique o nome do artigo",

    category: "Categoria",
    category_ph: "Indique o nome de uma categoria",

    contents: "Conteúdo",
    save_changes: "Guardar",

    SUCCESS_HELP: "Ajuda guardada",
    SUCCESS_HELP_MSG: "O conteúdo de ajuda foi atualizado com sucesso!",


    CONFIRM_DELETE_PAGE: "Confirmação",
    CONFIRM_DELETE_PAGE_MSG: "Esta página de ajuda vai ser apagada e não pode ser recuperada. Deseja realmente continuar?",

    ERROR_HELP_DELETE: "Erro",
    ERROR_HELP_DELETE_MSG: "Ocorreu um problema. Por favor tente mais tarde!",

    SUCCESS_HELP_DELETE: "Artigo removido",
    SUCCESS_HELP_DELETE_MSG: "O artigo foi removido com sucesso!",

    MISSING_TITLE: "Por favor indique um titulo para o artigo",
    MISSING_CATEGORY: "Por favor indique uma categoria",
    MISSING_CONTENTS: "Por favor preencha com o conteúdo",

    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

};

export default pt;
