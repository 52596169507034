import {Badge} from "react-bootstrap";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/adminLabels/locale/locales";

const LabelsReadOnlyComponent = (props) => {

    const {labels} = props;
    const {t} = useTranslations('adminLabel', locale);

    if (labels && labels.length === 0)
        return <div className={"add-label"}>
            <FontAwesomeIcon icon={faPlus}/>
            <div className={"text"}>
                {t('add_label')}
            </div>
        </div>
    else
        return <div className={"labels-read-only"}>
            {labels?.map((l, index) => <Badge bg="custom" key={index}>{l}</Badge>)}
        </div>
}

export default LabelsReadOnlyComponent;
