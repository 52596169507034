import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faPaw} from "@fortawesome/free-solid-svg-icons";
import React, {useMemo} from "react";
import {useGet} from "system/Rest/Rest";
import {Trans, useTranslation} from "react-i18next";
import BasicValidationStatus from "types/BasicValidationStatus";
import {Link} from "react-router-dom";

const PendingLostAlert = props => {

    const {animal} = props;
    const {t} = useTranslation("animal");

    const {data: result} = useGet({
        path: "/lost-animals/pending/search",
        queryParams: useMemo(() => ({
            validationStatus: BasicValidationStatus.PENDING,
            universalSearch: animal.transponder,
            itemsPerPage: 1,
        }), [animal.transponder]),
        lazy: !animal.transponder
    });


    if (result?.totalItems > 0) {
        return <AdvanceInfoMessage
            icon={faPaw}
            type={"warning"}
            iconType={"warning"}
            title={t("animal_lost_pending", {count: result?.totalItems})}
        >
            <Trans>
                {t('animal_lost_pending_msg', {
                    count: result?.totalItems
                })}

                {result?.totalItems === 1 &&
                    <Link to={"/pending-lost/" + result?.content[0].id}>
                        {t('animal_lost_pending_msg_link')}
                    </Link>
                }
            </Trans>
        </AdvanceInfoMessage>
    }

    return <></>

}

export default PendingLostAlert;
