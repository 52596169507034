import {useTranslation} from "react-i18next";
import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import {getOrDefault, getOrEmpty} from "system/Objects/ObjectParameters";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";

const PedigreeComponent = (props) => {

    const {t} = useTranslation('animal');
    const {errors, readOnly, changes, compareMode} = props;

    const {
        animal: {
            hasPedigree = false,
            lopNumber,
            lopName,
        },
        setAnimal
    } = useAnimalState();

    const collapsed = !hasPedigree && !compareMode;

    return <Box collapsed={collapsed} visible={!readOnly || hasPedigree}>
        <BoxTitle>{t('pedigree')}</BoxTitle>
        <BoxOptions>
            <CheckBox
                value={getOrDefault(hasPedigree, false)}
                label={t('has_pedigree')}
                disabled={readOnly}
                onChange={useCallback(
                    (val) => setAnimal(animal => ({
                        ...animal,
                        hasPedigree: val,
                        lopNumber: undefined,
                        lopName: undefined

                    })),
                    [setAnimal]
                )}
            />
        </BoxOptions>

        {!collapsed &&

        <Row>
            <Col sm={12} xl={6}>
                <FormGroup error={errors['lopNumber']} changes={changes['lopNumber']}>
                    <Form.Label>{t('lop_number')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('lop_number_ph')}
                        disabled={readOnly}
                        value={getOrEmpty(lopNumber)}
                        onChange={(e) => setAnimal(animal => ({
                            ...animal,
                            lopNumber: e.target.value,
                        }))}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['lopNumber'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} xl={6}>
                <FormGroup error={errors['lopName']} changes={changes['lopName']}>
                    <Form.Label>{t('lop_name')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('lop_name_ph')}
                        disabled={readOnly}
                        value={getOrEmpty(lopName)}
                        onChange={(e) => setAnimal(animal => ({
                            ...animal,
                            lopName: e.target.value,
                        }))}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['lopName'])}
                    </Form.Text>
                </FormGroup>
            </Col>

        </Row>

        }

    </Box>

}

export default PedigreeComponent;
