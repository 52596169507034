import React, {useCallback, useEffect, useState} from "react";
import PopupNotSavedComponent from "./components/PopupNotSavedComponent";
import PopupExitEditModeComponent from "./components/PopupExitEditModeComponent";
import {objectIsEqual} from "system/Objects/Objects";
import useBlockingRouter from "system/Routing/useBlockingRouter";

const FormHandleNotSaved = (props) => {

    const {remote, local, readOnly, setLocal, setReadOnly, onSubmit, loading, disabled = false} = props
    const [isModified, setModified] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    //Detect modifications
    useEffect(() => {
        if (!isModified && remote && local && !objectIsEqual(remote, local)) {
            setModified(true);
        } else if (isModified && remote && local && objectIsEqual(remote, local)) {
            setModified(false);
        }
    }, [local, remote, isModified])

    useBlockingRouter((isModified && !loading && !disabled), useCallback((accept, reject) => {
            setShowPopup({
                isVisible: true,
                accept: accept,
                reject: reject,
            });
        },
        [setShowPopup]
    ));


    if (disabled)
        return <></>;

    return <>
        {showPopup?.isVisible &&
        <PopupNotSavedComponent
            isVisible={true}
            onConfirm={showPopup?.accept}
            onCancel={() => {
                showPopup.reject();
                setShowPopup(false);
            }}

        />
        }

        {(readOnly && isModified) &&
        <PopupExitEditModeComponent
            isVisible={true}
            onCancel={() => {
                setLocal({
                    ...remote,
                });
                setModified(false);
            }}
            onConfirm={() => {
                setReadOnly(false);
                onSubmit(local);
            }}
        />
        }
    </>

}
export default FormHandleNotSaved;
