import {useMemo} from "react";
import {useLocation} from "react-router";

const maxWidthForResponsive = 992;

const useIsMenuSelected = (path) => {

    const location = useLocation();

    return useMemo(
        () =>
            Array.isArray(path)
                ? path.some(p => checkIfMenuIsSelected(location.pathname, p))
                : checkIfMenuIsSelected(location.pathname, path),
        [path, location.pathname]
    );
}

const checkIfMenuIsSelected = (currentPath, path) => {

    if (!path)
        return false;

    const cp = currentPath.split("/");
    const p = path.split("/");

    if (cp.length < 2) {
        return false;
    } else return cp[1] === p[1];

}

export {maxWidthForResponsive, useIsMenuSelected}
