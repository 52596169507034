export const pt = {

    title: "Titulares",
    list: "Titulares",
    view: "",

    create_title: "Criar novo titular",
    vat_or_passport: "Identificação",
    review_title: "Revisão de suspeitos",

    //Infos
    owner_is_locked: "Titular bloqueado",
    owner_is_locked_msg: "O registo deste titular está bloqueado pelos serviços do SIAC e apenas pode ser utilizado para um conjunto limitado de funcionalidades.<br/>" +
        "Para mais informações ou qualquer intervenção no registo contate os serviços do SIAC.",

    owner_is_locked_temporary_msg: "<br/><br/><u>O pedido de bloqueio está ativo até ao dia {{date}}</u>.<br/><br/><strong>{{observations}}</strong>",

    owner_edit_is_locked: "Edição de titular bloqueada",
    owner_edit_is_locked_msg: "A edição do registo deste titular está bloqueada pelos serviços do SIAC.<br/>" +
        "Para mais informações ou qualquer intervenção no registo contate os serviços do SIAC.",

    owner_is_verified: "Dados do titular validados",
    owner_is_verified_msg: "Nos titulares validados não é possível alterar o nome nem os dados dos documentos de identificação já que os  dados do titular foram verificados pelos Serviços SIAC ou através da chave móvel digital do titular",

    //tabs
    general: "Dados gerais",
    animals: "Animais",
    files: "Documentos",
    owners_tickets: "Suporte",

    //list

    name: "Nome completo",
    SINGULAR: "Pessoa singular",
    COLLECTIVE: "Pessoa coletiva",
    TRACES: "Traces",

    true: "Sim",
    false: "Não",

    no_owners_found: "Não foi encontrado nenhum titular",

    //Filter
    local_residence: "Residente em {{country}}",
    district: "Distrito",
    county: "Concelho",
    parish: "Freguesia",
    id_search: "Pesquisa BI/CC",
    id_search_ph: "Indique o termo que deseje pesquisar",

    //Supported Countries
    AO: "Angola",
    PT: "Portugal",

    //form
    owner_type: "Dados gerais do titular",
    type: "Tipo",

    residence_country: "País de residência",

    owner_data: "Dados pessoais titular",
    nationality: "Nacionalidade",
    has_local_vat: "Tem NIF em {{country}}",

    traces_number: "Nº de traces",
    traces_ph: "Insira Nº de traces",
    vat_ph: "Indique um número de identificação válido",


    name_ph: "Indique o nome completo do titular ",
    document_number: "Núm. BI/CC",
    document_number_complete: "Nº do bilhete de identidade/cartão do cidadão",
    id_card_ph: "Escreva apenas a componente numérica",

    passport: "Nº de Passaporte",
    passport_ph: "Por favor indique o número do passaporte",

    owner_address: "Morada do titular",


    contacts: "Contactos",
    email: "Email",
    email_ph: "Por favor indique um email",

    phone: "Telefone principal",
    phone2: "Telefone alternativo",
    phone3: "Telefone alternativo",

    entity_responsible: "Responsável da entidade titular",
    responsible: "Nome do responsável",
    responsible_ph: "Indique o nome do responsável da entidade",

    create_form: "Criar novo titular",
    save_form: "Atualizar titular",

    compare_owners: "Visualizar alterações ao titular",
    view_owner: "Ver titular",
    user_audit_log: "Audit log de acesso ao titulares",

    lock_options: "Opções de bloqueio",
    lock_owner: "Bloquear o titular",
    unlock_owner: "Desbloquear o titular",
    lock_edit_owner: "Bloquear edições do titular",
    unlock_edit_owner: "Desbloquear a edição do titular",
    lock_owner_temporary_msg: "Esta operação vai bloquear o registo e edição dos dados do titular. Opcionalmente pode indicar a data final de bloqueio bem como as observações associadas.<br/><br/>Deseja realmente bloquear o titular?",
    date_of_unlock: "Data de fim do bloqueio",
    observations: "Observações",
    date_ph: "Data no formato (dd-mm-yyyy)",


    item_selected_one: "{{count}} animal selecionado",
    item_selected_other: "{{count}} animais selecionados",

    ticket_subject_merge: "Fusão titular - Dados inválidos para o NIF {{vatNumber}}",
    ticket_subject_merge_msg: "Ao realizar a fusão do titular com o nome {{name}} com o titular registado com o NIF {{vatNumber}} foi verificado que não são a mesma pessoa",

    LOCK_OWNER_CONFIRM: "Confirmação",
    LOCK_OWNER_CONFIRM_MSG: "Esta operação vai <strong>{{desc}}</strong>. Deseja continuar?",

    SUCCESS_LOCKING_OWNER: "Bloqueio alterado",
    SUCCESS_LOCKING_OWNER_MSG: "O titular {{name}} foi bloqueado com sucesso",
    SUCCESS_UNLOCKING_OWNER_MSG: "O titular {{name}} foi desbloqueado com sucesso",

    //Errors
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    OWNER_IS_LOCKED: "Não é possível editar um utilizador bloqueado",

    MISSING_NAME: "O Nome do titular não é válido",
    MISSING_IS_COLLECTIVE: "Falta o Tipo de titular",
    MISSING_IS_LOCAL_RESIDENT: "Falta a informação sobre residência do titular",

    INVALID_VAT_NUMBER: "O documento de identificação do titular não é válido",
    MISSING_VAT_NUMBER:  "O documento de identificação do titular não é válido",
    VAT_NUMBER_ALREADY_EXISTS: "Já existe um titular registado com este número",


    ID_CARD_ALREADY_EXISTS: "BI/CC já está registado na base de dados",
    MISSING_ID_CARD: "Falta o BI/CC do titular. (Deve verificar se a nacionalidade está correta)",
    INVALID_ID_DOCUMENT_NUMBER: "Número do bilhete de identidade/cartão cidadão inválido",

    MISSING_PASSPORT: "Falta o número de passaporte do titular",
    PASSPORT_ALREADY_EXISTS: "Já existe um titular registado com este passaporte",

    MISSING_RESPONSIBLE: "Falta o nome da pessoa responsável da entidade",

    MISSING_COUNTRY: "Falta o país de residencia do titular",

    MISSING_EMAIL: "Por favor indique um email válido",
    INVALID_EMAIL: "Por favor indique um email válido",

    MISSING_PHONE: "O número de telefone não válido",
    INVALID_PHONE: "O número de telefone não válido",

    CREATE_SUCCESS: "Titular criado",
    CREATE_SUCCESS_MSG: "O titular \"{{name}}\" foi criado com sucesso!",

    UPDATE_SUCCESS: "Titular atualizado",
    UPDATE_SUCCESS_MSG: "O titular \"{{name}}\" foi atualizado com sucesso!",

    DELETE_OWNER_CONFIRM: "Confirmação",
    DELETE_OWNER_CONFIRM_MSG: "Deseja realmente apagar o titular {{name}}?",

    //Training
    training: "Formação",
    no_training: "Este titular não dispõe de nenhuma formação.",
    training_data: "Informação de formação",
    compare_training: "Visualizar alterações na formação",
    delete_training: "Remover informação de formação",

    certification_number: "Número de certificação",
    certification_number_ph: "Indique o número de certificação",
    certification_date: "Data de certificação",
    certification_date_ph: "Escreva ou escolha uma data (dd-mm-aaaa)",
    training_entity: "Entidade de formação",
    training_entity_ph: "Indique o nome da entidade",

    DELETE_FAILED: "Erro a apagar",
    OWNER_HAS_ANIMALS: "Este titular não pode ser apagado por ter animais registados.",

    save_training: "Guardar",

    MISSING_CERTIFICATION_NUMBER: "Por favor indique o número de certificação",
    MISSING_CERTIFICATION_DATE: "Por favor indique a data de certificação",
    INVALID_CERTIFICATION_DATE: "Por favor indique uma data válida",
    MISSING_TRAINING_ENTITY: "Por favor indique o nome da entidade",

    DELETE_TRAINING_CONFIRM: "Confirmação",
    DELETE_TRAINING_CONFIRM_MSG: "Deseja realmente apagar a informação de formação?",

    SUCCESS_DEL_TRAINING: "Informação de formação",
    SUCCESS_DEL_TRAINING_MSG: "A informação de formação foi removida com sucesso",

    SUCCESS_TRAINING: "Formação Guardado",
    SUCCESS_TRAINING_MSG: "A informação de formação foi guardada com sucesso",


    // External errors
    INVALID_POSTAL_CODE: "O código postal do titular não é válido",
    MISSING_POSTAL_CODE:"O código postal do titular não é válido",
    MISSING_ADDRESS: "A morada do titular não é válida",
    MISSING_PARISH: "A freguesia do titular não é válida",
    parishId: "Freguesia",
    INVALID_TYPE: "Tipo inválido",

    //Owner Check
    OWNER_DATA_LOADED: "Dados do titular",
    OWNER_DATA_LOADED_MSG: "Foram carregados automaticamente os dados do titular {{name}}. Caso exista algum dado que esteja incorreto ou desatualizado pode corrigir no formulário",
    OWNER_MERGE_TITLE: "Titular com NIF já registado",
    OWNER_MERGE_MSG: "Um titular com o NIF {{vatNumber}} e o nome {{name}} já se encontra registado.\n" +
        "Por favor verifique nos campos abaixo se se trata do mesmo titular. Em caso afirmativo as duas fichas serão fundidas. No caso de não se tratar do mesmo titular será aberto um pedido de suporte para os serviços",

    owner_merge_reject: "Não é o mesmo titular",
    owner_merge_accept: "Sim é o mesmo titular. Continuar a editar.",
    owner_merge_accept_remote: "Sim é o mesmo titular. Continuar com esta versão",
    cancel: "Cancelar",

    current_owner: "Titular da ficha",
    remote_owner: "Titular já registado",

    owner_in_review: "Necessária revisão",
    owner_in_review_msg: "Foram realizadas alterações que necessitam de revisão. Por favor consulte as alterações e caso sejam válidas carregue no botão \"Aceitar alterações\"",
    created_by_owner: "Via SIAC titulares",
    accept: "Aceitar",
    SUCCESS_REVIEW: "Sucesso",
    SUCCESS_REVIEW_MSG: "As alterações no documento foram dadas como revistas",

    DELETE_SUCCESS: "Titular apagado",
    DELETE_SUCCESS_MSG: "O titular \"{{name}}\" foi apagado com sucesso!",


    placeholder: "Escolha uma opção",

    //Owners account
    owners_account: "Acesso titulares",
    owners_no_email: "Este titular não tem email definido.<br/>Para ativar uma conta deverá primeiro definir na ficha de registo um email válido.",
    activate_info: "O utilizador <strong>{{name}}</strong> ainda não dispõe de acesso à plataforma SIAC titulares.<br/>Para criar um acesso de entidade coletiva com o nome de utilizador <strong>{{email}}</strong> por favor carregue no botão abaixo",
    activate_account: "Ativar conta",
    success_activate: "Conta ativada",
    success_activate_msg: "Foi ativada a conta para o titular <strong>{{name}}</strong> com sucesso",
    account_info: "O titular <strong>{{name}}</strong> já tem conta ativa para o email <strong>{{email}}</strong>",
    ERROR_ACTIVATING: "Erro ao ativar",
    OWNER_ALREADY_HAS_A_USER: "A conta deste titular já foi ativada",
    USER_HAS_NO_EMAIL: "Não existe email definido",
    DUPLICATE_EMAIL: "O email do titular já está associado a outra conta",

    //VATS
    VAT_COLLECTIVE: "VAT / NIF / Num. Empresa",
    VAT_PT_SINGULAR: "Nº Identificação Fiscal (NIF)",
    VAT_PT_COLLECTIVE: "Nº Individual Pessoa Coletiva (NIPC)",

    VAT_AL_SINGULAR: "Num. Pessoal ",
    VAT_AL_COLLECTIVE: "Num. Empresa",

    VAT_AD_SINGULAR: "NRT Número d’Identificació Administrativa",
    VAT_AD_COLLECTIVE: "NRT Número d’Identificació Administrativa",

    VAT_AO_SINGULAR: "NIF Angolano",
    VAT_AO_COLLECTIVE: "NIPC Angolano",

    VAT_GB_SINGULAR: "UTR / National Insurance Number / TRN",
    VAT_GB_COLLECTIVE: "UTR",

    VAT_BE_SINGULAR: "National Register Nº / Numéro National (NN)",
    VAT_BE_COLLECTIVE: "Número de Empresa / Numéro d’entreprise / Belgische ondernemingsnummer",

    VAT_BR_SINGULAR: "Cadastro de Pessoas Físicas (CPF)",
    VAT_BR_COLLECTIVE: "Cadastro Nacional da Pessoa Jurídica (CNPJ)",

    VAT_BG_SINGULAR: "UCN/Personal No",
    VAT_BG_COLLECTIVE: "Unified Identification Code (UIC) / Official number",

    VAT_HR_SINGULAR: "Personal Identification Number (OIB)",
    VAT_HR_COLLECTIVE: "Company Identification Number (OIB)",

    VAT_CH_SINGULAR: "AHV-Versichertennummer (N.º Seg. Social) / No d'assuré",
    VAT_CH_COLLECTIVE: "UID-number (Unternehmens-Identifikationsnummer)",

    VAT_DK_SINGULAR: "CPR Number",
    VAT_DK_COLLECTIVE: "CPR Number / SE Number",

    VAT_DE_SINGULAR: "Personalausweis / Steueridentifikationsnummer / Steuer-IdNr.",
    VAT_DE_COLLECTIVE: "Steuernummer",

    VAT_ES_SINGULAR: "DNI / Número de identificación fiscal",
    VAT_ES_COLLECTIVE: "Número de identificación fiscal",

    VAT_SE_SINGULAR: "Personnummer / Samordningsnummer",
    VAT_SE_COLLECTIVE: "VAT-nummer / Momsnummer",

    VAT_SI_SINGULAR: "ID za DDV ou EMŠO",
    VAT_SI_COLLECTIVE: "ID za DDV",

    VAT_SK_SINGULAR: "Rodné Cislo / Personal No",
    VAT_SK_COLLECTIVE: "IC DPH",

    VAT_CZ_SINGULAR: "Rodné Cislo / Personal No",
    VAT_CZ_COLLECTIVE: "Daňové identifikační číslo (DIC)",

    VAT_RU_SINGULAR: "INN / ИНН",
    VAT_RU_COLLECTIVE: "INN / ИНН",

    VAT_RO_SINGULAR: "Personal Identification Number (CNP)",
    VAT_RO_COLLECTIVE: "Codul de identificare fiscală (CIF)",

    VAT_PL_SINGULAR: "PESEL",
    VAT_PL_COLLECTIVE: "Numer Identyfikacji Podatkowej (NIP)",

    VAT_NO_SINGULAR: "National Identity Number (NIN) / D-Number",
    VAT_NO_COLLECTIVE: "Organization Number",

    VAT_NL_SINGULAR: "Personal No / BSN Nummer",
    VAT_NL_COLLECTIVE: "BSN Nummer",

    VAT_MT_SINGULAR: "Identity Card Number",
    VAT_MT_COLLECTIVE: "Vat Number",

    VAT_LU_SINGULAR: "Social security number (Num. Seg. Social)",
    VAT_LU_COLLECTIVE: "TVA (taxe sur la valeur ajoutée, Luxembourgian VAT number)",

    VAT_LT_SINGULAR: "Personal Number (Asmens kodas)",
    VAT_LT_COLLECTIVE: "Company Number (PVM Kodas)",

    VAT_LV_SINGULAR: "Personas Kods",
    VAT_LV_COLLECTIVE: "VAT Num. Empresa (PVN)",

    VAT_IT_SINGULAR: "Codice fiscale",
    VAT_IT_COLLECTIVE: "Partita IVA (Company Vat Number)",

    VAT_IE_SINGULAR: "Personal Public Service Number (PPS N°)",
    VAT_IE_COLLECTIVE: "Tax Reference Number (TRN) / CHY Number",

    VAT_HU_SINGULAR: "Adóazonosító jel / Szemelyi Szam Ellenorzese",
    VAT_HU_COLLECTIVE: "Adószám / Kozossegi Adoszam (ANUM)",

    VAT_GR_SINGULAR: "AMKA (Número Segurança Social) / FPA",
    VAT_GR_COLLECTIVE: "CPR Number (FPA)",

    VAT_EE_SINGULAR: "Personal Id - Isikukood",
    VAT_EE_COLLECTIVE: "Kaibemaksukohuslase (KMKR)",

    VAT_FR_SINGULAR: "INSEE (Seg. Social) /NIR/NIF (French personal identification number)",
    VAT_FR_COLLECTIVE: "TVA Number / SIREN",

    VAT_FI_SINGULAR: "Security Social Number",
    VAT_FI_COLLECTIVE: "Business ID / Vat Number",

    VAT_AT_SINGULAR: "Versicherungsnummer (VNR, SVNR, VSNR)",
    VAT_AT_COLLECTIVE: "UID (Umsatzsteuer-Identifikationsnummer)",

    VAT_CY_SINGULAR: "Id Card Number / Vat Number",
    VAT_CY_COLLECTIVE: "VAT Number (ΦΠΑ)",

    VAT_RS_SINGULAR: "Unique Master Citizen Number (JMBG)",
    VAT_RS_COLLECTIVE: "NIF / VAT number",

    VAT_ID_SINGULAR: "Nomor Pokok Wajib Pajak (NPWP)",
    VAT_ID_COLLECTIVE: "Nomor Pokok Wajib Pajak (NPWP)",

    //Review
    suspect_date: "Data de alteração",
    is_closed: "Validado",
    author: "Autor",
    user: "Utilizador",
    entity: "Entidade",
    VETERINARY_USER: "M. Veterinário",
    NORMAL_USER: "Utilizador Normal",
    OWNER_USER: "Titular",
    accept_in_bulk: "Validar selecionados",
    BULK_ACCEPT_CONFIRM: "Confirmação",
    BULK_ACCEPT_CONFIRM_MSG: "Os titulares na lista abaixo vão ser dados com revistos.\r\n\r\n{{names}}\r\n\r\nDeseja realmente avançar?",
    SUCCESS_BULK_ACCEPT: "Titulares revistos",
    SUCCESS_BULK_ACCEPT_MSG: "{{number}} titulares dados como revistos",
    ERROR_BULK_ACCEPT: "Erro a rever",
    ERROR_BULK_ACCEPT_MSG: "Ocorreu um erro. Por favor tente mais tarde.",
    bulk_item_selected_one: "{{count}} titular selecionado",
    bulk_item_selected_other: "{{count}} titulares selecionados",
    more_information: "Mais informação",

    request_more_information: "Pedido de informação",
    more_information_message_helper: "Escreva ou escolha a mensagem de pedido de mais informação",

    SUCCESS_TICKET: "Ticket Aberto",
    SUCCESS_TICKET_MSG: "Foi feito um pedido de mais informação",

    ERROR_TICKET: "Erro",
    ERROR_TICKET_MSG: "Ocorreu um erro ao abrir o ticket",

    bulk_owner_transfer_btn: "Trans. titular",
    labels: "Etiquetas",
    exclude_labels: "Excluir Etiquetas",
    no_labels: "Apresentar apenas sem labels",


    //Transfer owner labels
    new_owner_type: "Dados do novo titular",
    new_owner_data: "Dados pessoais do novo titular",
    new_owner_address: "Morada do novo titular",
    new_owner_contacts: "Contactos do novo titular",

    //vat_examples_help
    vat_examples_help: "Ver exemplos",

};

export default pt;
