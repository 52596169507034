import {useIsMobile} from "system/Window/Mobile";
import {Tabs as BootstrapTabs} from "react-bootstrap";
import MobileTabs from "layout/modules/Tabs/components/MobileTabs";
import useHistoryState from "system/Routing/useHistoryState";
import {useCallback} from "react";
import "layout/modules/Tabs/assets/css/pills-tabs.scss";

const Tabs = (props) => {

    const {id, defaultActiveKey, forceMobile, disableDefaultActiveKey, variant, ...otherProps} = props;
    const isMobile = useIsMobile();
    const [activeKey, setActiveKey] = useHistoryState("defaultActiveKey-" + id, defaultActiveKey);

    /**
     useEffect(() => {
     setActiveKey(defaultActiveKey)
     // eslint-disable-next-line
     }, [id]);
     */

    const handleOnSelect = useCallback(
        (id) => {
            if (!disableDefaultActiveKey)
                setActiveKey(id)
        },
        [setActiveKey, disableDefaultActiveKey]
    );

    if (isMobile || forceMobile) {
        return <MobileTabs {...otherProps} defaultActiveKey={activeKey} key={id}/>
    } else {

        if (variant === 'pills') {
            return <div className={"pills-tabs"}>
                <BootstrapTabs {...otherProps} variant={"pills"} activeKey={activeKey} onSelect={handleOnSelect}/>
            </div>
        } else {
            return <BootstrapTabs {...otherProps} activeKey={activeKey} onSelect={handleOnSelect}/>
        }
    }


}

export default Tabs;
