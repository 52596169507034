import ListComponent from "app/audit/components/ListComponent";
import React from "react";

const AuditLogComponent = props => {

    return <div className={"own-audit-log"}>
        <ListComponent
            {...props}
            filterActions={{}}
            filterObjectTypes={{}}
            userId={true}
            hideMoreInfo={true}
        />


    </div>
}

export default AuditLogComponent;
