import HelpComponent from "layout/modules/BaseLayout/Menu/components/fixed/HelpComponent";
import UserComponent from "layout/modules/BaseLayout/Menu/components/fixed/UserComponent";
import React, {useRef} from "react";
import NotificationArea from "app/notifications/NotificationArea";
import DarkModeComponent from "layout/modules/BaseLayout/Menu/components/fixed/DarkModeComponent";
import SupportButtons from "app/support/SupportButtons";
import classnames from "classnames";
import {useIsMobile} from "system/Window/Mobile";
import "layout/modules/BaseLayout/Menu/css/fixed-items.scss";
import {useApiState} from "system/API/APIStateContext";
import SingleMenuItem from "layout/modules/BaseLayout/Menu/components/SingleMenuItem";
import {faPersonWalkingArrowRight} from "@fortawesome/free-solid-svg-icons";
import useTranslations from "system/Translations/UseTranslations";
import locale from "locales/locales";

const FixedItemsComponent = props => {

    const {collapsed} = props;
    const ref = useRef(null);

    const isMobile = useIsMobile();
    const {authentication} = useApiState();

    const isSIAC = authentication?.entity?.id === 1;
    const {t} = useTranslations('global', locale);


    return <div className={classnames("menu-fixed-buttons", {"collapsed": collapsed})}>

        <div className={"separator"}/>
        <div className={classnames("main-menu-items", {"collapsed": collapsed})} tabIndex={0} ref={ref} role="menubar"
             aria-orientation="vertical">
            {(!isMobile && !isSIAC) &&
                <>
                    <SupportButtons {...props}/>
                    <NotificationArea {...props}/>
                </>
            }
            <HelpComponent {...props}/>

            <SingleMenuItem label={t('sign_out')} icon={faPersonWalkingArrowRight} path="/logout"/>

        </div>


        <div className={"separator"}/>
        <div className={"menu-user-button"}>
            <UserComponent collapsed={collapsed}/>
        </div>

        <div className={"separator"}/>
        <DarkModeComponent/>

    </div>
}

export default FixedItemsComponent;
