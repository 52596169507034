import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import classnames from "classnames";
import React, {useCallback, useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import TransponderControl from "modules/Forms/Transponder/TransponderControl";
import {getOrDefault, getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import FormNotRegisteredComponent from "app/animals/components/lost/create/form/FormNotRegisteredComponent";
import {AnimalStatus} from "types/Animal";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import AnimalFoundStatus from "app/animals/utils/AnimalFoundStatus";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import CountryDropdown from "layout/modules/Forms/Dropdowns/CountryDropdown/CountryDropdown";
import appConfig from "configs/config";
import GeofenceForm from "modules/Forms/Geofence/GeofenceForm";
import FormControl from "layout/modules/Forms/Control/FormControl";
import Box from "layout/modules/Box/Box";
import {useTranslation} from "react-i18next";
import Observations from "app/observations/Observations";

const GeneralDataComponent = props => {

    const {t} = useTranslation("animal");
    const {create, readOnly, setReadOnly, errors, lostInfo, setLostInfo, notRegistered} = props;

    const eventGeoArea = useMemo(
        () => ({
            districts: lostInfo?.eventDistrict,
            counties: lostInfo?.eventCounty,
            parishes: lostInfo?.eventParish,
        }),
        [lostInfo?.eventDistrict, lostInfo?.eventCounty, lostInfo?.eventParish]
    );

    const handleEditClick = useCallback(() => setReadOnly(readOnly => !readOnly), [setReadOnly]);

    return <Box>
        <BoxTitle>{t('general')}</BoxTitle>
        <BoxOptions visible={!create}>
            {!create &&
            <UserHasPermission permission={["READ_OBSERVATION"]}>
                <Observations location={"/lost-animals/process/" + lostInfo?.id}/>
            </UserHasPermission>
            }

            {!lostInfo?.isClosed &&
            <UserHasPermission permission="EDIT_LOST_ANIMAL">
                <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                     onClick={handleEditClick}>
                    <FontAwesomeIcon icon={faPencilAlt}/>
                </div>
            </UserHasPermission>
            }


        </BoxOptions>

        <Row>
            <Col sm={12} md={12} lg={12}>
                <FormGroup error={errors['transponder']} mandatory={true}>
                    <Form.Label>{t('transponder')}</Form.Label>
                    <TransponderControl
                        id="lostTransponder"
                        placeholder={t('transponder_ph')}
                        disabled={true}
                        value={getOrEmpty(lostInfo?.transponder)}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['transponder'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            {(notRegistered || lostInfo?.isExternal) &&
            <FormNotRegisteredComponent
                t={t}
                errors={errors}
                readOnly={readOnly}
                lostInfo={lostInfo}
                setLostInfo={setLostInfo}
            />
            }

            {lostInfo.status === AnimalStatus.FOUND &&
            <Col sm={12} md={12} lg={12}>
                <FormGroup error={errors['foundAnimalSituation']} mandatory={true}>
                    <Form.Label>{t('lost_animal_situation')}</Form.Label>
                    <EnumDropdown
                        t={t}
                        isMulti={false}
                        placeholder={t('lost_animal_situation_ph')}
                        options={AnimalFoundStatus}
                        isDisabled={readOnly}
                        value={getOrNull(lostInfo?.foundAnimalSituation)}
                        onChange={(status) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            foundAnimalSituation: status,
                        }))}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['foundAnimalSituation'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            }


            <Col sm={12} md={6} lg={6}>
                <FormGroup error={errors['eventDate']} mandatory={true}>
                    <Form.Label>{t('occurrence_date')}</Form.Label>
                    <DatePicker
                        name={"eventDate"}
                        placeholder={t("date_ph")}
                        timezone={"UTC"}
                        disabled={readOnly}
                        onlyPastDates={true}
                        value={getOrEmpty(lostInfo?.eventDate)}
                        onChange={useCallback(date => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            eventDate: date,
                        })), [setLostInfo])}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['eventDate'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} md={6} lg={6}>
                <FormGroup error={errors['eventCountry']} mandatory={true}>
                    <Form.Label>{t('event_country')}</Form.Label>
                    <CountryDropdown
                        id={"eventCountry"}
                        isDisabled={readOnly}
                        value={getOrDefault(lostInfo?.eventCountry, appConfig.defaultCountry)}
                        onChange={useCallback((country) => {
                            setLostInfo((info) => ({
                                ...info,
                                eventCountry: country,
                            }))
                        }, [setLostInfo])}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['eventCountry'])}
                    </Form.Text>
                </FormGroup>
            </Col>


            {(lostInfo?.eventCountry === "PT") &&
            <Col sm={12} md={12} lg={12}>
                <GeofenceForm
                    t={t}
                    readOnly={readOnly}
                    ignoreSubmit={true}
                    isMulti={false}
                    errors={{
                        districts: (errors['eventCounty'] && !eventGeoArea?.districts) ? "INVALID_DISTRICT" : undefined,
                        counties: errors['eventCounty'],
                    }}
                    mandatory={{
                        district: true,
                        county: true,
                    }}
                    entry={eventGeoArea}
                    onChange={(value) => {
                        setLostInfo((info) => ({
                            ...info,
                            eventDistrict: value?.districts,
                            eventCounty: value?.counties,
                            eventParish: value?.parishes,

                        }));
                    }}
                />
            </Col>
            }

            <Col sm={12} md={12} lg={12}>
                <FormGroup error={errors['eventLocal']} mandatory={true}>
                    <Form.Label>{t('lost_local')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('local_ph')}
                        disabled={readOnly}
                        value={getOrEmpty(lostInfo?.eventLocal)}
                        onChange={useCallback((e) => {
                            setLostInfo((info) => ({
                                ...info,
                                eventLocal: e.target.value,
                            }))
                        }, [setLostInfo])}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['eventLocal'])}
                    </Form.Text>
                </FormGroup>
            </Col>

        </Row>
    </Box>


}

export default GeneralDataComponent;
