import {AnimalOperations, useAnimalOperationAllowed} from "app/animals/utils/AnimalOperationAllowed";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import React, {useMemo} from "react";
import useTranslations from "system/Translations/UseTranslations";
import localeOwner from "app/owners/locales/locales";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import locale from "app/animals/locales/locales";

const InvalidDataAlert = props => {

    const {animal} = useAnimalState();
    const isOperationAllowed = useAnimalOperationAllowed(animal);

    const {t} = useTranslations("animal", locale);
    const {t: to} = useTranslations("owner", localeOwner);

    const errorMessage = useMemo(
        () => animal?.errors ? parseAlertErrors(animal, to, t) : "",
        [animal, t, to]
    );

    if (animal?.isDataValid) {
        return <></>
    } else if (isOperationAllowed(AnimalOperations.EDIT)) {
        return <AdvanceInfoMessage
            icon={faExclamation}
            type={"warning"}
            iconType={"danger"}
            title={t("animal_is_not_valid")}
            message={t('animal_is_not_valid_edit_msg', {errors: errorMessage})}
        />;
    } else {
        return <AdvanceInfoMessage
            icon={faExclamation}
            type={"warning"}
            iconType={"danger"}
            title={t("animal_is_not_valid")}
            message={t('animal_is_not_valid_msg', {errors: errorMessage})}
        />;
    }

}


const parseAlertErrors = (animal, to, t) => Object.keys(animal?.errors)
    .map(param =>
        param.includes(".")
            ? to(animal?.errors[param])
            : t(animal?.errors[param])
    ).join("<br/>");

export default InvalidDataAlert;
