import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import Help from "app/help/Help";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import SingleMenuItem from "layout/modules/BaseLayout/Menu/components/SingleMenuItem";

const HelpComponent = (props) => {

    const [isHelpVisible, setHelpVisible] = useState(false);
    const {t} = useTranslation('global');

    return <>

        <SingleMenuItem
            label={t('help')}
            {...generateOnClickAndEnter(() => setHelpVisible(!isHelpVisible))}
            aria-description={t('help')}
            icon={faQuestion}
            className={classnames({'selected': isHelpVisible})}
        />

        <Help isVisible={isHelpVisible} onClose={() => setHelpVisible(false)}/>

    </>
}

export default HelpComponent;
