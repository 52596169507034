import React, {useMemo} from "react";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const ResultStatsComponent = props => {

    const {t, result} = props;

    const totalUpdates = useMemo(
        () => result?.updateCounts?.reduce((partialSum, a) => partialSum + a, 0),
        [result]
    );

    return <AdvanceInfoMessage
        icon={faCheck}
        type={"success"}
        iconType={"success"}
        title={t("operation_result_message")}
        message={t("operation_result_message_msg", {
            totalUpdates: totalUpdates ? totalUpdates : 0,
            totalTables: result?.resultSets?.length
        })}
    />
}

export default ResultStatsComponent;
