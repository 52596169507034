import React, {useCallback} from "react";
import AnimalPanel from "./panel/AnimalPanel";
import locales from "../../locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import BallComponent from "app/animals/components/generic/BallComponent";
import Box from "layout/modules/Box/Box";

const OverviewPanelComponent = (props) => {

    const {isEditMode, photoUploadRef, reload} = props;
    const {t} = useTranslations("animal", locales);

    const {animal} = useAnimalState();
    const handleAnimalUploadClick = useCallback(() => photoUploadRef.current.showPhotoManagement(), [photoUploadRef]);

    return <>
        <BallComponent
            animal={animal}
            reload={reload}
            hideStateBorder={false}
            editMode={isEditMode}
            photoUploadRef={photoUploadRef}
        />
        <Box>
            {isEditMode &&
            <div
                className={"upload-label"}
                onClick={handleAnimalUploadClick}
            >
                {t('upload_photo')}
            </div>
            }

            {!isEditMode &&
            <AnimalPanel {...props}/>
            }

        </Box>
    </>
}

export default OverviewPanelComponent;
