import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import AnimalDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/AnimalDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import VersionDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/VersionDropdown";
import {useGet} from "system/Rest/Rest";
import OwnerDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/OwnerDropdown";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";

const VersionComponent = props => {

    const {value, setValue, parameter, error, readOnly, ownerVersions} = props;
    const {t} = useTranslation("reports");
    const [mainDocumentId, setMainDocumentId] = useState();

    const {data: versions, loading} = useGet({
        path: ownerVersions
            ? "/owners/" + mainDocumentId + "/versions"
            : "/animals/" + mainDocumentId + "/versions",

        lazy: !mainDocumentId,
    });

    const handleMainDocument = useCallback(
        (v) => {
            setMainDocumentId(v);
            setValue(undefined);
        },
        [setValue, setMainDocumentId]
    );

    if (readOnly) {
        return <Col sm={12} lg={6}>
            <LabelWithValue
                label={parameter?.label}
                value={Array.isArray(value) ? value.join(' ') : value}
                />
        </Col>
    }

    return <>
        <Col sm={12} lg={6}>
            <FormGroup error={error} mandatory={parameter?.mandatory}>
                <Form.Label>{parameter?.label}</Form.Label>
                {ownerVersions
                    ? <OwnerDropdown
                        isClearable={!parameter?.mandatory}
                        value={getOrNull(mainDocumentId)}
                        onChange={handleMainDocument}
                        isDisabled={readOnly}
                    />
                    : <AnimalDropdown
                        isClearable={!parameter?.mandatory}
                        value={getOrNull(mainDocumentId)}
                        onChange={handleMainDocument}
                        isDisabled={readOnly}
                    />
                }
            </FormGroup>
        </Col>
        <Col sm={12} lg={6}>
            <FormGroup error={error} mandatory={parameter?.mandatory}>
                <Form.Label>{t('version', {label: parameter?.label})}</Form.Label>
                <VersionDropdown
                    isDisabled={readOnly || !mainDocumentId}
                    isLoading={loading}
                    versions={versions}
                    isMulti={parameter?.multipleValues}
                    onlyMainVersions={true}
                    value={getOrNull(value)}
                    onChange={setValue}
                />

                <Form.Text className="sub-error">
                    {error}
                </Form.Text>
            </FormGroup>

        </Col>
    </>
}

export default VersionComponent;
