const ReportParamType = {
    TEXT: "TEXT",
    BOOLEAN: "BOOLEAN",
    NUMERIC: "NUMERIC",
    DATE: "DATE",
    ANIMAL: "ANIMAL",
    ANIMAL_VERSION: "ANIMAL_VERSION",
    OWNER: "OWNER",
    OWNER_VERSION: "OWNER_VERSION",
    ENTITY: "ENTITY",
    BREED: "BREED",
    BREED_TYPE: "BREED_TYPE",
    BREED_GROUP: "BREED_GROUP",
    VETERINARY: "VETERINARY",
    COUNTRY: "COUNTRY",
    STERILIZATION_TYPE: "STERILIZATION_TYPE",
    DISTRICT: "DISTRICT",
    COUNTY: "COUNTY",
    PARISH: "PARISH",
    ANIMAL_STATUS: "ANIMAL_STATUS",
    GENDER: "GENDER",
    USER: "USER",
    ENTITY_PROFILE: "ENTITY_PROFILE",
    ENTITY_TYPE: "ENTITY_TYPE",
    OPTIONAL: "OPTIONAL",
    LOG_PARAMETER: "LOG_PARAMETER",
    RABIES_VACCINE: "RABIES_VACCINE",
};

export {ReportParamType};
