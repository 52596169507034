import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import React, {useCallback, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import Select from 'react-select'
import useTimeZoneList, {useDefaultTimeZone} from "../utils/timezones"
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Audit from "../../audit/Audit";
import {useApiState} from "system/API/APIStateContext";
import useHandleFormSubmit from "system/Forms/FormHandling";
import useTranslationList from "app/users/utils/translations";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import AdvancedForm from "modules/Forms/NotSaved/AdvancedForm";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {Actions} from "app/audit/utils/AuditActions";
import {AuditObjectTypes} from "app/audit/utils/AuditObjectTypes";
import Observations from "app/observations/Observations";
import Loading from "layout/modules/Loading/Loading";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {UserType} from "types/Users";
import RoleDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RoleDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import StatusDropdown from "layout/modules/Forms/Dropdowns/StatusDropdown/StatusDropdown";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import {useLocation} from "react-router";
import EntityLinkDropdown from "app/entities/components/other/EntityLinkDropdown";
import AuditUserType from "app/audit/utils/AuditUserType";


const FormComponent = (props) => {

    const {user, loading = true, onSubmit, create = false, errors = {}, onEditMode, compare} = props;
    const {t} = useTranslation('user');
    const {authentication} = useApiState();

    const [userEntityRole, setUserEntityRole] = useState({});
    const [readOnly, setReadOnly] = useState(!create);

    const handleUserSubmit = useHandleFormSubmit(onSubmit);
    const translations = useTranslationList();
    const timeZones = useTimeZoneList();
    const defaultTimeZone = useDefaultTimeZone(timeZones, user?.timeZone);

    const location = useLocation();

    const setReadMode = useCallback(() => setReadOnly(readOnly => !readOnly), [setReadOnly]);

    useEffect(() => {
        setUserEntityRole({
            entityId: user?.entityId || location?.state?.entityId || authentication?.entity?.id,
            roleId: user?.roleId
        })
    }, [user, location?.state?.entityId, authentication?.entity?.id]);


    //Update EditMode
    useEffect(() => {
        if (onEditMode)
            onEditMode(!readOnly);
    }, [onEditMode, readOnly])


    return <div className={classnames("form-component", {
        "view-mode": readOnly,
        "compare-mode": compare
    })}>

        <Loading visible={loading}/>

        {!compare && <GenericFormError t={t} errors={errors}/>}
        <AdvancedForm readOnly={readOnly} setReadOnly={setReadOnly} loading={loading} onSubmit={handleUserSubmit}>
            <Box className={"mandatory"}>
                <BoxTitle>
                    {create ? t("create_user") : t('general')}
                </BoxTitle>

                <BoxOptions>
                    {(!create && !compare) &&
                    <>
                        <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                            <Audit
                                userId={user.id}
                                userType={AuditUserType.SIAC}
                                filterActions={Actions}
                                filterObjectTypes={AuditObjectTypes}

                            />
                        </UserHasPermission>

                        <UserHasPermission permission={["READ_OBSERVATION"]}>
                            <Observations/>
                        </UserHasPermission>

                        <UserHasPermission permission="WRITE_USER">
                            <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                                 onClick={setReadMode}>
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </div>
                        </UserHasPermission>
                    </>
                    }
                </BoxOptions>

                <Row>
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['name']}>
                            <Form.Label>{t('name')}</Form.Label>
                            <FormControl
                                type="text"
                                id="name"
                                placeholder={t('name_ph')}
                                defaultValue={user?.name}
                                disabled={readOnly}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['name'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['email']}>
                            <Form.Label>{t('email')}</Form.Label>
                            <FormControl
                                type="text"
                                id="email"
                                placeholder={t('email_ph')}
                                defaultValue={user?.email}
                                disabled={!create}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['email'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    {(user?.type !== UserType.VETERINARY) &&
                    <>
                        <UserHasPermission permission={"READ_ENTITY"}>
                            <Col sm={12} lg={6}>
                                <FormGroup error={errors['entityId']}>
                                    <Form.Label>{t('entity')}</Form.Label>
                                    <EntityLinkDropdown
                                        key={user?.entityId}
                                        defaultValue={user?.entityId || location?.state?.entityId || authentication?.entity?.id}
                                        onChange={(entityId) => {
                                            setUserEntityRole({
                                                entityId: entityId,
                                                roleId: null,
                                            });
                                        }}
                                        isDisabled={readOnly}
                                    />
                                    <Form.Text className="sub-error">
                                        {t(errors['entityId'])}
                                    </Form.Text>
                                </FormGroup>
                            </Col>
                        </UserHasPermission>

                        <Col sm={12} lg={6}>
                            <FormGroup error={errors['roleId']}>
                                <Form.Label>{t('role')}</Form.Label>
                                <RoleDropdown
                                    value={getOrNull(userEntityRole?.roleId)}
                                    userEntityId={userEntityRole?.entityId || authentication?.entity?.id}
                                    userTypes={[UserType.NORMAL]}
                                    onChange={(key) => setUserEntityRole((old) => {
                                        return {
                                            ...old,
                                            roleId: key,
                                        }
                                    })}
                                    isDisabled={readOnly}
                                    excludeUnavailableForUser={true}
                                />

                                <Form.Text className="sub-error">
                                    {t(errors['roleId'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>
                    </>
                    }

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['language']}>
                            <Form.Label>{t('language')}</Form.Label>
                            <Select
                                name="language"
                                id="language"
                                classNamePrefix="rs"
                                className="rs"
                                placeholder={t('language_ph')}
                                noOptionsMessage={useLabelCallback(null, t('language_no'))}
                                defaultValue={translations.find((el) => el.value === user?.language)}
                                key={user?.language}
                                options={translations}
                                isDisabled={readOnly}
                                isClearable={true}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['language'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['timeZone']}>
                            <Form.Label>{t('timezone')}</Form.Label>
                            <Select
                                name="timeZone"
                                id="timeZone"
                                classNamePrefix="rs"
                                className="rs"
                                placeholder={t('timezone_ph')}
                                noOptionsMessage={useLabelCallback(null, t('timezone_no'))}
                                options={timeZones}
                                defaultValue={defaultTimeZone}
                                key={user?.timeZone}
                                isDisabled={readOnly}
                                isClearable={true}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['timeZone'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['status']}>
                            <Form.Label>{t('status').label}</Form.Label>
                            <StatusDropdown
                                defaultValue={user?.status}
                                isDisabled={readOnly}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['status'])}
                            </Form.Text>
                        </FormGroup>

                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['receiveNotifications']}>
                            <Form.Label>{t('receive_notifications')}</Form.Label>
                            <BooleanDropdown
                                id={"receiveNotifications"}
                                defaultValue={parseBoolean(user?.receiveNotifications, false)}
                                isDisabled={readOnly}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['receiveNotifications'])}
                            </Form.Text>
                        </FormGroup>

                    </Col>

                </Row>
            </Box>


            {!readOnly &&
            <Row>
                <Col className={"text-end"}>
                    {!create &&
                    <Button variant="secondary" className={"cancel"} onClick={setReadMode}>
                        {t("cancel")}
                    </Button>
                    }
                    <UserHasPermission permission={"WRITE_USER"}>
                        <Button variant="primary" type="submit" disabled={readOnly}>
                            {create ? t("create_user") : t("save_changes")}
                        </Button>
                    </UserHasPermission>
                </Col>
            </Row>
            }
        </AdvancedForm>
    </div>

}

export default FormComponent;
