import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback, useMemo} from "react";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {AnimalStatus, GenderType, GenderTypeWithUndefined} from "types/Animal";
import FormControl from "layout/modules/Forms/Control/FormControl";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import BreedAdvancedDropdown from "app/breeds/containers/BreedAdvancedDropdown";
import BreedDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/BreedDropdown";

const FormNotRegisteredComponent = props => {

    const {t, errors, readOnly, lostInfo, setLostInfo} = props;

    const handleNameChange = useCallback(
        (e) => setLostInfo(lostInfo => ({
            ...lostInfo,
            name: e.target.value,
        })),
        [setLostInfo]
    );

    return <>

        {lostInfo.status === AnimalStatus.LOST &&
        <Col sm={12} md={6} lg={6}>
            <FormGroup error={errors['name']} mandatory={false}>
                <Form.Label>{t('name')}</Form.Label>

                <FormControl
                    type="text"
                    placeholder={t('name_ph')}
                    disabled={readOnly}
                    value={getOrEmpty(lostInfo?.name)}
                    onChange={handleNameChange}
                />
                <Form.Text className="sub-error">
                    {t(errors['name'])}
                </Form.Text>
            </FormGroup>
        </Col>
        }

        <BreedAdvancedDropdown
            t={t}
            value={getOrNull(lostInfo?.breedId)}
            error={errors['breedId']}
            label={t('specie')}
            defaultSelectedOption={lostInfo?.breed}
            isDisabled={readOnly}
            onChange={useCallback((breedId, breed) => setLostInfo(animal => ({
                ...animal,
                breedId: breedId,
                breed: breed,
                isCrossBreed: false,
                crossBreedId: undefined,
            })), [setLostInfo])}

        />


        <Col sm={12} lg={6}>
            <FormGroup error={errors['isCrossBreed']}>
                <Form.Label>{t('is_cross_breed')}</Form.Label>

                <BooleanDropdown
                    isClearable={false}
                    isDisabled={readOnly || !lostInfo?.breedId}
                    value={parseBoolean(lostInfo?.isCrossBreed, false)}
                    onChange={useCallback((val) => setLostInfo(lostInfo => ({
                        ...lostInfo,
                        isCrossBreed: val,
                        crossBreedId: null,
                    })), [setLostInfo])}
                />
            </FormGroup>
        </Col>

        {(lostInfo?.isCrossBreed) &&
        <Col sm={12} lg={6}>
            <FormGroup error={errors['crossBreedId']}  mandatory={true}>
                <Form.Label>{t('cross_breed')}</Form.Label>

                <BreedDropdown
                    isMulti={false}
                    value={getOrNull(lostInfo?.crossBreedId)}
                    parentId={lostInfo?.breed?.parent?.id}
                    defaultSelectedOption={lostInfo?.crossBreed}
                    isDisabled={readOnly || !lostInfo?.breedId}
                    excludeId={lostInfo?.breedId}
                    onChange={(breedId, crossBreed) => setLostInfo(lostInfo => ({
                        ...lostInfo,
                        crossBreedId: breedId,
                        crossBreed: crossBreed,
                    }))}
                />

                <Form.Text className="sub-error">
                    {t(errors['crossBreedId'])}
                </Form.Text>
            </FormGroup>
        </Col>
        }


        <Col sm={12} md={6} lg={6}>
            <FormGroup error={errors['gender']} mandatory={true}>
                <Form.Label>{t('gender')}</Form.Label>
                <EnumDropdown
                    t={t}
                    isDisabled={readOnly}
                    value={getOrNull(lostInfo?.gender)}
                    placeholder={t('gender_ph')}
                    options={useMemo(
                        () => lostInfo?.breed?.canHaveUndefinedGender ? GenderTypeWithUndefined : GenderType,
                        [lostInfo?.breed?.canHaveUndefinedGender]
                    )}
                    onChange={useCallback(
                        (val) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            gender: val,
                        })), [setLostInfo]
                    )}
                />

                <Form.Text className="sub-error">
                    {t(errors['gender'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} md={6} xl={6}>
            <FormGroup error={errors['color']} mandatory={true}>
                <Form.Label>{t('color')}</Form.Label>
                <FormControl
                    type="text"
                    placeholder={t('color_ph')}
                    disabled={readOnly}
                    value={getOrEmpty(lostInfo?.color)}
                    onChange={useCallback(
                        (e) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            color: e.target.value,
                        })),
                        [setLostInfo]
                    )}

                />
                <Form.Text className="sub-error">
                    {t(errors['color'])}
                </Form.Text>
            </FormGroup>
        </Col>


    </>
}

export default FormNotRegisteredComponent;
