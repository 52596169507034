import React, {useCallback, useMemo, useState} from "react";
import classnames from "classnames";
import {faCameraRetro} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AnimalStatus} from "types/Animal";
import SetPhotoContainer from "app/animals/containers/SetPhotoContainer";
import {AnimalOperations, useAnimalOperationAllowed} from "app/animals/utils/AnimalOperationAllowed";
import {ReactComponent as LogoLost} from "app/animals/assets/images/no-photo-lost.svg";
import {ReactComponent as LogoNoPhoto} from "app/animals/assets/images/no-photo.svg";
import {ReactComponent as LogoDead} from "app/animals/assets/images/no-photo-dead.svg";
import "app/animals/assets/css/ball.scss";


const BallComponent = (props) => {

    const {animal = {}, editMode, hideStateBorder, photoUploadRef, disableChange} = props;

    const isOperationAllowed = useAnimalOperationAllowed(animal);
    const canChangeDirectlyPhoto = useMemo(
        () => isOperationAllowed(AnimalOperations.SET_PHOTO) && !editMode && !disableChange,
        [isOperationAllowed, editMode, disableChange]
    );

    const handlePhotoSwitchClick = useCallback(
        () => photoUploadRef?.current?.showPhotoManagement(),
        [photoUploadRef]
    );

    const [showChangePhoto, setShowChangePhoto] = useState(false);
    const handleChangePhoto = useCallback(() => setShowChangePhoto(true), [setShowChangePhoto]);

    return <>

        {showChangePhoto &&
        <SetPhotoContainer
            {...props}
            animal={animal}
            onClose={() => setShowChangePhoto(false)}
        />
        }

        <div
             className={classnames("animal-ball", {
                 "lost": animal.status === AnimalStatus.LOST,
                 "found": animal.status === AnimalStatus.FOUND,
                 "dead": animal.status === AnimalStatus.DEAD,
                 "hide-border": hideStateBorder,
                 "edit-mode": editMode,
                 "edit-mode-available": canChangeDirectlyPhoto,
             })}>
            <div className={classnames("animal-ball-content", {
                "no-photo": !animal.photo,
                "no-status": !animal.status
            })}>


                <div className={"image"}>
                    {animal.photo
                        ? <img src={`data:image/jpeg;base64,${animal.photo}`} alt={"animal"}/>
                        : <>
                            {animal.status === AnimalStatus.LOST && <LogoLost/>}
                            {animal.status === AnimalStatus.FOUND && <LogoLost/>}
                            {animal.status === AnimalStatus.DEAD && <LogoDead/>}
                            {(animal.status === AnimalStatus.NORMAL || !animal.status) && <LogoNoPhoto/>}
                        </>
                    }
                </div>

                {editMode &&
                <div className={"edit-overlay"} onClick={handlePhotoSwitchClick}>
                    <FontAwesomeIcon icon={faCameraRetro}/>
                </div>
                }

                {canChangeDirectlyPhoto &&
                <div className={"edit-available-overlay"} onClick={handleChangePhoto}>
                    <FontAwesomeIcon icon={faCameraRetro}/>
                </div>
                }

            </div>
        </div>
    </>
}

export default BallComponent;
