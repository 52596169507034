import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useCallback} from "react";
import ValidationStatus from "types/ValidationStatus";

const ButtonsComponent = props => {

    const {t, onChangeState, setShowSetState, request, hideAccept, hideMoreInformation} = props;

    const handleAccept = useCallback(() => onChangeState(ValidationStatus.ACCEPTED), [onChangeState]);
    const handleReject = useCallback(() => setShowSetState(ValidationStatus.REJECTED), [setShowSetState]);
    const handleMoreInformation = useCallback(() => setShowSetState(ValidationStatus.MORE_INFORMATION_NEEDED), [setShowSetState]);

    if (request.closed) {
        return <></>
    }

    return <Row>
        <Col lg={12} sm={12} className={"accept-buttons text-end"}>

            {!hideAccept &&
            <Button variant="success" onClick={handleAccept}>
                {t("accept")}
            </Button>
            }

            {!hideMoreInformation &&
            <Button variant="warning" onClick={handleMoreInformation}>
                {t("request_more_information")}
            </Button>
            }

            <Button variant="danger" onClick={handleReject}>
                {t("reject")}
            </Button>

        </Col>

    </Row>
}

export default ButtonsComponent;
