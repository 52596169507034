import React from "react";

const BaseLoginLayout = (props) => {

    return <div className="login-area">
        {props.children}
    </div>;

}

export default BaseLoginLayout;
