import {useMutate} from "system/Rest/Rest";
import {useCallback} from "react";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useTranslation} from "react-i18next";
import SetPhotoComponent from "app/animals/components/generic/SetPhotoComponent";

const SetPhotoContainer = props => {

    const {animal, reload, onClose, onChange} = props;
    const showToast = useShowToast();

    const {t} = useTranslation('animal');

    const {mutate: submit, loading} = useMutate({
        verb: "PUT",
        path: "/animals/" + animal?.transponder + "/photo",
    });

    return <SetPhotoComponent
        {...props}
        photo={animal?.photo}
        loading={loading}
        onChange={useCallback(
            (photo) => {

                if (onChange)
                    return onChange(photo);

                submit({
                    photo: photo
                }).then(() => {
                    showToast({
                        title: t('SUCCESS_SET_PHOTO'),
                        message: t('SUCCESS_SET_PHOTO_MSG', animal),
                        icon: faCheck,
                    });

                    reload();
                    onClose();
                });
            },
            [t, showToast, animal, reload, onClose, submit, onChange]
        )}
    />

}

export default SetPhotoContainer;
