import GenericListContainer from "../../../modules/GenericTableContainer/GenericListContainer";
import React, {useMemo} from "react";
import ListComponent from "../components/ListComponent";
import locale from "../locales/locales";

const ListContainer = (props) => {

    const {
        id = "audit",
        objectId,
        userId,
        affectedObjectType,
        actions,
        objectType,
        hideTitle = true,
        toTimestamp,
        fromTimestamp,
        ownerVatNumber,
        userType,
    } = props;

    return <GenericListContainer
        id={id}
        module="audit"
        searchPath={"/audit/search"}
        hideTitle={hideTitle}
        locale={locale}
        queryParameters={useMemo(() => ({
            objectId: affectedObjectType ? undefined : objectId,
            affectedObjectType: affectedObjectType,
            affectedObjectId: affectedObjectType ? objectId : undefined,
            objectType: objectType,
            actions: actions,
            fromTimestamp: fromTimestamp,
            toTimestamp: toTimestamp,
            ownerVatNumber: ownerVatNumber,
            userIds: userId ? [userId] : undefined,
            userType: userType,
            sortProperty: 'timestamp',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 15,
        }), [objectId, userId,userType, actions, affectedObjectType, objectType, toTimestamp, fromTimestamp, ownerVatNumber])}>

        <ListComponent
            {...props}
        />

    </GenericListContainer>
}

export default ListContainer;
