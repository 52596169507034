import React, {useCallback, useState} from "react";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import FormComponent from "app/control/modules/transfers/components/create/FormComponent";
import {useTranslation} from "react-i18next";
import Loading from "layout/modules/Loading/Loading";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import StepBox from "layout/modules/StepBox/StepBox";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import {readFileContent} from "system/Files/ReadFileContent";
import Box from "layout/modules/Box/Box";
import {acceptTypeImages, acceptTypesPdf} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";
import "app/control/modules/transfers/assets/css/change-owner.scss";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {useNavigate} from "react-router";


const TransferAnimalComponent = (props) => {

    const {t} = useTranslation('transfer');

    const {animalOwner, setAnimalOwner, onSubmit, loading, animals, isBulk, errors = {}} = props;
    const [uploadingCounter, setUploadingCounter] = useState(0);
    const documentCounter = animalOwner.documents.length;

    const navigate = useNavigate();

    return <div className={"change-animal-owner"}>
        <Loading visible={loading}/>

        <GenericFormError t={t} errors={errors}/>

        <div className={"steps"}>
            <Row>
                <Col lg={4} sm={12}>
                    <StepBox step={1}
                             active={documentCounter === 0}
                             done={documentCounter > 0}
                    >
                        {t('change_owner_s1_desc')}<br/>
                        <a target='_blank' rel="noreferrer"
                           href={"https://www.siac.vet/wp-content/uploads/2022/08/Transmissao_titularidade.pdf"}>
                            {t('change_owner_s1_link_download')}
                        </a>
                    </StepBox>
                </Col>


                <Col lg={4} sm={12}>
                    <StepBox step={2} active={documentCounter > 0}>
                        {t("change_owner_s2_desc")}
                    </StepBox>
                </Col>

                <Col lg={4} sm={12}>
                    <StepBox step={3}>
                        {t("change_owner_s3_desc")}
                    </StepBox>
                </Col>

            </Row>
        </div>


        <Form>

            <FormGroup error={errors['documents']} mandatory={true}>
                <Box className={"declaration-upload-box"}>
                    <Row>
                        <Col lg={(documentCounter > 0) ? 6 : 12} sm={12} className={"col-upload"}>
                            <DragDropFile
                                maxFiles={10}
                                acceptTypes={{
                                    ...acceptTypesPdf,
                                    ...acceptTypeImages,
                                }}
                                text={t('change_owner_file_upload')}
                                onFileUploaded={useCallback((nf) => {
                                    nf.forEach((file) => {
                                        setUploadingCounter((uc) => uc + 1);
                                        readFileContent(file, (content) => {
                                            setAnimalOwner(ao => ({
                                                ...ao,
                                                documents: [
                                                    ...ao.documents,
                                                    {
                                                        filename: file.name,
                                                        type: file.type,
                                                        contents: btoa(content)
                                                    }
                                                ]
                                            }));
                                            setUploadingCounter((uc) => uc - 1);
                                        });
                                    })
                                }, [setUploadingCounter, setAnimalOwner])}
                            />
                        </Col>

                        <Col lg={6} sm={12} className={"file-list"}>
                            <Loading visible={uploadingCounter > 0}/>
                            {animalOwner.documents.map((file, id) =>
                                <div className={"file"} key={id}>
                                    <div className={"name"}>
                                        {file.filename}
                                    </div>
                                    <div className={"close"}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={"delete"}
                                            onClick={() => {
                                                setAnimalOwner(ao => {
                                                    const documents = [...ao.documents];
                                                    documents.splice(id, 1);
                                                    return {
                                                        ...ao,
                                                        documents: documents
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Box>
                <Form.Text className="sub-error documents-errors">
                    {t('error_in_documents')}
                </Form.Text>
            </FormGroup>

            {documentCounter > 0 &&
                <>
                    <FormComponent loading={loading} animalOwner={animalOwner} setAnimalOwner={setAnimalOwner}
                                   errors={errors} animals={animals} isBulk={isBulk}/>
                    <Row>
                        <Col>
                            <div className={"buttons"}>
                                {window.history.length > 1 &&
                                <Button variant="secondary" onClick={() => navigate((-1))}>
                                    {t("cancel")}
                                </Button>
                                }

                                <Button variant="primary"  onClick={() => onSubmit(animalOwner)}>
                                    {t("submit_co_request")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </Form>
    </div>

}

export default TransferAnimalComponent;
