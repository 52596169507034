import {useGet} from "system/Rest/Rest";
import ChangeLogComponent from "./components/ChangeLogComponent";
import useTranslations from "../../system/Translations/UseTranslations";
import locale from "./locales/locales";
import Title from "../../layout/modules/BaseLayout/ContentArea/components/Title";
import {useMemo} from "react";

const Changelog = (props) => {

    const {t} = useTranslations('dashboard', locale);

    const {data: information, loading} = useGet({
        path: "/informations/",
        queryParams: useMemo(() => ({
            type: "CHANGELOG"
        }),[])
    });

    return <Title
        title={t('changelog')}
        breadcrumbs={[
            {href: "/", label: t('title_dashboard')},
            {href: "/changelog", label: t('changelog')},
        ]} {...props}>

        <ChangeLogComponent information={information?.content} loading={loading}/>
    </Title>

}

export default Changelog;
