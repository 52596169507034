import pt from "./pt"

const locale = [
    {
        lang: 'pt-PT',
        name: "Português",
        translation: pt
    }

];

export default locale;

