import Container from "react-bootstrap/Container";
import {faCheck, faCodeBranch, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useState} from "react";
import locale from "app/licensing/locales/locales";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import classnames from "classnames";
import FormContainer from "app/licensing/containers/FormContainer";
import useTranslations from "system/Translations/UseTranslations";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import TableHeaderButtonGroup from "layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import Audit from "app/audit/Audit";
import {AnimalObjectTypes} from "app/audit/utils/AuditObjectTypes";
import {LicenseActions} from "app/audit/utils/AuditActions";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import DateTime from "system/DateTime/DateTime";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";


const ListComponent = (props) => {

    const {t} = useTranslations("animal-license", locale);
    const showToast = useShowToast();

    const {data, onPageChange, onPageSort, loading, onDelete, animal, reloadBase} = props;

    const [deletedInfo, setDeletedInfo] = useState({});
    const [showAdd, setShowAdd] = useState(false);


    return <Container fluid>

        {showAdd &&
        <FormContainer {...props} onClose={() => setShowAdd(false)}/>
        }

        <PopupConfirm
            title={t('DELETE_LICENSE_CONFIRM')}
            message={t('DELETE_LICENSE_CONFIRM_MSG', {name: deletedInfo.name})}
            classConfirm={"danger"}
            visible={!!deletedInfo.id}
            onCancel={useCallback(
                () => setDeletedInfo({}),
                [setDeletedInfo]
            )}
            onConfirm={useCallback(
                () => {
                    setDeletedInfo({})
                    onDelete(
                        deletedInfo.id, {name: deletedInfo.name},
                        () => {
                            showToast({
                                title: t('SUCCESS_DELETE_LICENSE'),
                                message: t('SUCCESS_DELETE_LICENSE_MSG', {name: deletedInfo.name}),
                                icon: faCheck,
                            });

                            reloadBase();
                        },
                        () => showToast({
                            title: t('FAIL_DELETE_LICENSE'),
                            message: t('FAIL_DELETE_LICENSE_MSG', {name: deletedInfo.name}),
                            className: 'error',
                            icon: faTimes,
                        })
                    );
                },
                [onDelete, setDeletedInfo, deletedInfo.name, deletedInfo.id, showToast, t, reloadBase]
            )}
        />

        <Box>
            <TableHeader>
                <TableHeaderButtonGroup>

                    <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                        <Audit
                            id={"licensing"}
                            lg={true}
                            icon={faCodeBranch}
                            objectId={animal.transponder}
                            affectedObjectType={AnimalObjectTypes.Animal}
                            objectType={[
                                AnimalObjectTypes.AnimalLicense
                            ]}
                            filterActions={LicenseActions}

                        />
                    </UserHasPermission>

                    <AnimalOperationAllowed animal={animal} operation={AnimalOperations.REGISTER_LICENSING}>
                        <TableIconAdd
                            className={classnames({"active": showAdd})}
                            onClick={useCallback(() => setShowAdd(true), [setShowAdd])}
                        />
                    </AnimalOperationAllowed>

                </TableHeaderButtonGroup>
            </TableHeader>

            <SearchDataPagination data={data} onPageChange={onPageChange}/>

            <TableAdvanced
                hover
                sort={data?.sortBy}
                order={data?.sortOrder?.toLowerCase()}
                onSortClick={onPageSort}
                loading={(loading || !data)}>

                <thead>
                <tr>

                    <th>
                        {t('year')}
                    </th>

                    <th id="licenseDate" sorted>
                        {t('license_date')}
                    </th>

                    <th className={"d-none d-lg-table-cell"}>
                        {t('entity')}
                    </th>

                    <th className={"d-none d-lg-table-cell"}>
                        {t('valid')}
                    </th>

                    <th/>

                </tr>
                </thead>
                <tbody>

                {data?.content?.map((license, index) => {
                    return <tr key={index}>

                        <td>
                            <DateTime format="YYYY">
                                {license.licenseDate}
                            </DateTime>
                        </td>


                        <td className={"td-sorted"}>
                            <DateTime format="DD-MM-YYYY" timezone={"UTC"}>
                                {license.licenseDate}
                            </DateTime>
                        </td>

                        <td className={"fill-empty d-none d-lg-table-cell"}>
                            {license.createdEntity?.name}
                        </td>

                        <td className={"fill-empty d-none d-lg-table-cell"}>
                            {t(license.isLicenseValid)}
                        </td>

                        <td className="table-buttons">
                            <div className={"buttons"}>

                                <TableIconButton
                                    icon={faTrash}
                                    permission={"DELETE_ANIMAL_LICENSE"}
                                    onClick={() => {
                                        setDeletedInfo({
                                            id: license.id,
                                            name: license.filename
                                        })
                                    }}
                                    className={"table-btn btn-rounded-danger small"}
                                />
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
                <div className={"no-table-results"}>
                {t('no_results')}
            </div>
            }
        </Box>
    </Container>
}

export default ListComponent;
