import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import {Link} from "react-router-dom"
import logo from "layout/assets/images/logo.png";
import BaseLoginLayout from "./BaseLoginLayout";
import 'app/login/assets/css/login.scss'
import 'app/login/assets/css/login-responsive.scss'
import {EmailValidator} from "app/login/utils/validators";
import InfoMessage from "layout/modules/InfoMessages/InfoMessage";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import Loading from "layout/modules/Loading/Loading";


const RecoveryFormComponent = (props) => {

    //Lang stuff
    const {t} = useTranslation('login', true);
    const {loading, onSubmit, errors = {}} = props;

    const [email, setEmail] = useState("");
    const [isValid, setValid] = useState(false);

    const handleRecovery = useCallback((e, email) => {
        e.preventDefault();
        onSubmit(email);
    }, [onSubmit]);


    //Validate Email
    useEffect(() => {
        setValid(EmailValidator.test(email))
    }, [email])


    return <BaseLoginLayout>
        <div className="login-bar">
            <img src={logo} className="logo" alt={"logo"}/>
        </div>

        <div className="login-content">
            <Loading visible={loading}/>

            <div className={"login-content-area"}>
                <Row className={["justify-content-md-center"]}>
                    <Col md="auto" className="login-title">
                        {t('password_recovery_title')}
                    </Col>
                </Row>

                <Row className={["justify-content-md-center", "login-side-bars"]}>
                    <Col className={["login-form-area"]}>

                        <InfoMessage type={"info"}>
                            {t('email_recovery_description')}
                        </InfoMessage>

                        <Form autoComplete="off" onSubmit={(e) => handleRecovery(e, email)}>

                            <FormGroup error={errors['email']}>
                                <Form.Label>{t('email')}</Form.Label>
                                <FormControl type="email" id="email"
                                             value={email}
                                             onChange={(e) => setEmail(e.target.value)}
                                />
                                <Form.Text className="sub-label">
                                    {t(errors['email'])}
                                </Form.Text>
                            </FormGroup>


                            <Row className="justify-content-md-center">
                                <Col className="text-center">
                                    <Button variant="primary" type="submit" disabled={!isValid}>
                                        {t('password_recovery_send')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <div className="bottom-link">
                    <Link to="/auth/" >
                        {t('go_to_login')}
                    </Link>
                </div>
            </div>
        </div>
    </BaseLoginLayout>;
}

export default RecoveryFormComponent;
