import React from "react";
import {useTranslation} from "react-i18next";
import ModalWithNoMargin from "../../../../layout/modules/Modal/ModalWithNoMargin/ModalWithNoMargin";
import GetOptionsComponent from "./form/GetOptionsComponent";
import EmailComponent from "./form/EmailComponent";
import PropertiesComponent from "./form/PropertiesComponent";

const FormComponent = (props) => {

    const {certificateTemplate, errors, request, setRequest} = props;
    const {t} = useTranslation('certificate');

    return <>

        <div className={"info-text"}>
            {t('issue_option_text')}
        </div>

        <GetOptionsComponent
            request={request}
            setRequest={setRequest}
        />


        {(request.sendEmail || certificateTemplate?.properties?.length > 0) &&
        <ModalWithNoMargin>
            <div className={"form-body"}>

                {request.sendEmail &&
                <EmailComponent
                    request={request}
                    setRequest={setRequest}
                    errors={errors}
                />
                }

                {certificateTemplate?.properties?.length > 0 &&
                <PropertiesComponent
                    certificateTemplate={certificateTemplate}
                    request={request}
                    setRequest={setRequest}
                    errors={errors?.properties}
                />
                }

            </div>
        </ModalWithNoMargin>
        }
    </>;

}

export default FormComponent;
