import {Trans, useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback, useMemo, useState} from "react";
import useAntiRabiesValidity from "app/interventions/utils/antiRabies/useAntiRabiesValidity";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import FormControl from "layout/modules/Forms/Control/FormControl";
import InfoMessage from "layout/modules/InfoMessages/InfoMessage";
import VaccineDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/VaccineDropdown";

const AntiRabiesComponent = (props) => {

    const {t} = useTranslation("health-intervention");
    const {errors, intervention, setIntervention, readOnly, compare} = props;

    const [vaccines, setVaccines] = useState([]);
    const getValidity = useAntiRabiesValidity(vaccines);

    const defaultImmunizationEnd = useMemo(
        () => getValidity(intervention?.vaccineId, intervention.executionDate),
        [intervention?.vaccineId, intervention.executionDate, getValidity]
    );

    return <>

        {!readOnly &&
        <InfoMessage type={"warning"}>
            <Trans>
                {t('rabies_date_info')}
            </Trans>
        </InfoMessage>
        }

        <Box>
            <BoxTitle>{t('antirabie')}</BoxTitle>

            <Row>
                <Col sm={12} xl={4}>

                    <FormGroup error={errors['vaccineId']} mandatory={true}>
                        <Form.Label>{t('vaccine')}</Form.Label>
                        <VaccineDropdown
                            t={t}
                            isClearable={false}
                            isSearchable={false}
                            isDisabled={readOnly}
                            value={getOrNull(intervention?.vaccineId)}
                            defaultSelectedVaccine={intervention?.vaccine}
                            forceLoadData={compare}
                            placeholder={t('select_vaccine_ph')}
                            onVaccineLoad={useCallback(vaccines => setVaccines(vaccines), [setVaccines])}
                            onChange={useCallback(vaccineId => setIntervention(intervention => ({
                                    ...intervention,
                                    vaccineId: vaccineId,
                                    immunizationDateEnd: getValidity(vaccineId, intervention.executionDate),
                                })),
                                [setIntervention, getValidity]
                            )}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['vaccineId'])}
                        </Form.Text>
                    </FormGroup>

                </Col>

                <Col sm={12} lg={4}>
                    <FormGroup error={errors['immunizationDateEnd']} mandatory={true}>
                        <Form.Label>{t('immunization_date_end')}</Form.Label>
                        <DatePicker
                            placeholder={t("immunization_date_end_ph")}
                            fromDate={intervention?.executionDate}
                            toDate={defaultImmunizationEnd}
                            timezone={"UTC"}
                            disabled={readOnly || !intervention.vaccineId}
                            value={getOrEmpty(intervention?.immunizationDateEnd)}
                            onChange={useCallback(
                                date => setIntervention(intervention => ({
                                    ...intervention,
                                    immunizationDateEnd: date,
                                })), [setIntervention]
                            )}
                        />
                        <Form.Text className="sub-error">
                            {t(errors['immunizationDateEnd'])}
                        </Form.Text>
                    </FormGroup>
                </Col>

                <Col sm={12} xl={4}>
                    <FormGroup error={errors['batch']} mandatory={true}>
                        <Form.Label>{t('batch')}</Form.Label>
                        <FormControl
                            type="text"
                            value={getOrEmpty(intervention?.batch)}
                            disabled={readOnly}
                            placeholder={t('batch_ph')}
                            onChange={useCallback(
                                e => setIntervention(intervention => ({
                                    ...intervention,
                                    batch: e.target.value,
                                })), [setIntervention]
                            )}
                        />
                        <Form.Text className="sub-error">
                            {t(errors['batch'])}
                        </Form.Text>
                    </FormGroup>
                </Col>

            </Row>
        </Box>
    </>


}

export default AntiRabiesComponent;
