import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import AnimalFieldIsAllowed, {useAnimalFieldsAllowed} from "app/animals/utils/AnimalFieldsAllowed";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {GenderType, GenderTypeWithUndefined} from "types/Animal";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import BreedAdvancedDropdown from "app/breeds/containers/BreedAdvancedDropdown";
import BreedDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/BreedDropdown";

const AnimalDataComponent = (props) => {

    const {t} = useTranslation('animal');
    const {errors = {}, readOnly, changes, create, compareMode} = props;

    const {
        animal: {
            name,
            passport,
            birthdate,
            gender,
            breedId,
            isCrossBreed,
            crossBreedId,
            color,
            breedType,
            healthReport,
            breed,
            crossBreed
        },
        setAnimal

    } = useAnimalState();

    const isFieldAllowed = useAnimalFieldsAllowed(readOnly, create, errors);


    return <Box>
        <BoxTitle>{t('animal_data')}</BoxTitle>
        <Row>

            <AnimalFieldIsAllowed field={"name"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Col sm={12} xl={6}>
                    <FormGroup error={errors['name']} changes={changes['name']} mandatory={true}>
                        <Form.Label>{t('name')}</Form.Label>
                        <FormControl
                            type="text"
                            placeholder={t('name_ph')}
                            disabled={readOnly}
                            value={getOrEmpty(name)}
                            onChange={useCallback((e) => setAnimal(animal => ({
                                ...animal,
                                name: e.target.value,
                            })), [setAnimal])}

                        />
                        <Form.Text className="sub-error">
                            {t(errors['name'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </AnimalFieldIsAllowed>

            <AnimalFieldIsAllowed field={"passport"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Col sm={12} xl={6}>
                    <FormGroup error={errors['passport']} changes={changes['passport']}>
                        <Form.Label>{t('passport')}</Form.Label>
                        <FormControl
                            type="text"
                            placeholder={t('passport_ph')}
                            disabled={readOnly}
                            value={getOrEmpty(passport)}
                            onChange={useCallback((e) => setAnimal(animal => ({
                                ...animal,
                                passport: e.target.value,
                            })), [setAnimal])}

                        />
                        <Form.Text className="sub-error">
                            {t(errors['passport'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </AnimalFieldIsAllowed>


            <AnimalFieldIsAllowed field={"birthdate"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Col sm={12} lg={6}>
                    <FormGroup error={errors['birthdate']} changes={changes['birthdate']} mandatory={true}>
                        <Form.Label>{t('birthdate')}</Form.Label>
                        <DatePicker
                            name={"birthdate"}
                            placeholder={t("date_ph")}
                            disabled={readOnly}
                            onlyPastDates={true}
                            timezone={"UTC"}
                            value={getOrEmpty(birthdate)}
                            onChange={useCallback((date) => setAnimal(animal => ({
                                ...animal,
                                birthdate: date,
                            })), [setAnimal])}
                        />
                        <Form.Text className="sub-error">
                            {t(errors['birthdate'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </AnimalFieldIsAllowed>

            <AnimalFieldIsAllowed field={"breedId"} isReadOnly={readOnly} errors={errors} isCreation={create}>

                <BreedAdvancedDropdown
                    t={t}
                    value={getOrNull(breedId)}
                    change={changes['breedId']}
                    mandatory={true}
                    error={errors['breedId']}
                    label={t('specie')}
                    isDisabled={readOnly}
                    allowOnlyEditFinalGroup={!isFieldAllowed("breedGroup")}
                    onChange={useCallback((breedId, breed) => setAnimal(animal => ({
                        ...animal,
                        breedId: breedId,
                        breed: breed,
                        breedCategoryId: undefined,
                        crossBreedId: undefined,
                        crossBreed: undefined,
                        isCrossBreed: false,
                    })), [setAnimal])}

                />


            </AnimalFieldIsAllowed>

            <AnimalFieldIsAllowed field={"isCrossBreed"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Col sm={12} lg={6}>
                    <FormGroup error={errors['isCrossBreed']} changes={changes['isCrossBreed']}>
                        <Form.Label>{t('is_cross_breed')}</Form.Label>
                        <BooleanDropdown
                            isClearable={false}
                            isDisabled={readOnly}
                            value={parseBoolean(isCrossBreed, false)}
                            onChange={useCallback((val) => setAnimal(animal => ({
                                ...animal,
                                isCrossBreed: val,
                                crossBreedId: val ? animal?.crossBreedId : null,
                            })), [setAnimal])}
                        />
                    </FormGroup>
                </Col>
            </AnimalFieldIsAllowed>

            {(isCrossBreed || compareMode) &&
            <AnimalFieldIsAllowed field={"crossBreedId"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Col sm={12} lg={6}>
                    <FormGroup error={errors['crossBreedId']} changes={changes['crossBreedId']} mandatory={true}>
                        <Form.Label>{t('cross_breed')}</Form.Label>

                        <BreedDropdown
                            isMulti={false}
                            value={getOrNull(crossBreedId)}
                            parentId={breed?.parent?.id}
                            defaultSelectedOption={crossBreed}
                            isDisabled={readOnly || !breedId}
                            excludeId={breed?.id}
                            onChange={(breedId, crossBreed) => setAnimal(animal => ({
                                ...animal,
                                crossBreedId: breedId,
                                crossBreed: crossBreed,
                            }))}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['crossBreedId'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </AnimalFieldIsAllowed>
            }


            <AnimalFieldIsAllowed field={"gender"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Col sm={12} lg={6}>
                    <FormGroup error={errors['gender']} changes={changes['gender']} mandatory={true}>
                        <Form.Label>{t('gender')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            options={useMemo(
                                () => breed?.canHaveUndefinedGender ? GenderTypeWithUndefined : GenderType,
                                [breed?.canHaveUndefinedGender]
                                )}
                            isDisabled={readOnly}
                            value={getOrNull(gender)}
                            placeholder={t('gender_ph')}
                            onChange={useCallback((val) => setAnimal(animal => ({
                                ...animal,
                                gender: val,
                            })), [setAnimal])}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['gender'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </AnimalFieldIsAllowed>


            <AnimalFieldIsAllowed field={"color"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Col sm={12} xl={6}>
                    <FormGroup error={errors['color']} changes={changes['color']} mandatory={true}>
                        <Form.Label>{t('color')}</Form.Label>
                        <FormControl
                            type="text"
                            placeholder={t('color_ph')}
                            disabled={readOnly}
                            value={getOrEmpty(color)}
                            onChange={useCallback((e) => setAnimal(animal => ({
                                ...animal,
                                color: e.target.value,
                            })), [setAnimal])}

                        />
                        <Form.Text className="sub-error">
                            {t(errors['color'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </AnimalFieldIsAllowed>

            <AnimalFieldIsAllowed field={"healthReport"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Col sm={12} xl={6}>
                    <FormGroup error={errors['healthReport']} changes={changes['healthReport']} mandatory={false}>
                        <Form.Label>{t('health_report')}</Form.Label>
                        <FormControl
                            type="text"
                            placeholder={t('health_report_ph')}
                            disabled={readOnly}
                            value={getOrEmpty(healthReport)}
                            onChange={useCallback((e) => setAnimal(animal => ({
                                ...animal,
                                healthReport: e.target.value,
                            })), [setAnimal])}

                        />
                        <Form.Text className="sub-error">
                            {t(errors['healthReport'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </AnimalFieldIsAllowed>

            {readOnly &&

            <Col sm={12} xl={true}>
                <FormGroup mandatory={false}>
                    <Form.Label>{t('category')}</Form.Label>
                    <FormControl
                        type="text"
                        disabled={true}
                        value={getOrEmpty(breedType?.description)}
                    />

                </FormGroup>
            </Col>
            }


        </Row>


    </Box>

};

export default AnimalDataComponent;
