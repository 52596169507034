export const pt = {

    //CONFIRM
    CHANGES_NOT_SUBMITTED: "Alterações não guardadas",
    CHANGES_NOT_SUBMITTED_CANCEL: "Não",
    CHANGES_NOT_SUBMITTED_CONFIRM: "Sim",
    CHANGES_NOT_SUBMITTED_MSG: "Existem alterações que não foram guardadas. Deseja guardar as alterações realizadas ?",
    CHANGES_NOT_SUBMITTED_POPUP: 'Existem alterações não guardadas, tem a certeza que quer sair?',

    CHANGES_NOT_SAVED: "Sair da Página",
    CHANGES_NOT_SAVED_MSG: "Existem alterações que não foram guardadas e que serão perdidas\r\n<strong>Pretende realmente sair desta página?</strong>",
    NO: "Não",
    YES: "Sim",

};

export default pt;
