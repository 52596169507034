import {useMemo, useState} from "react";

const useAnimalErrors = (animal, responseErrors, readOnly, compareMode) => {

    const [isSubmitted, setSubmitted] = useState(false);

    const errors = useMemo(
        () => {
            if ((isSubmitted && !readOnly) || compareMode)
                return responseErrors;
            else if (animal?.errors) {
                return animal.errors;
            } else return {};
        },
        [responseErrors, animal?.errors, isSubmitted, readOnly, compareMode]
    );

    return [errors, setSubmitted];
}


export default useAnimalErrors;
