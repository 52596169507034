import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import ReportTemplateDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ReportTemplateDropdown";
import {getOrDefault, getOrNull} from "system/Objects/ObjectParameters";
import ReportCategoryDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ReportCategoryDropdown";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {ReportOutputFormat} from "app/reporting/utils/ReportOutputFormat";

const TemplateComponent = props => {

    const {
        t,
        errors,
        setRequest,
        request,
        onTemplateChange,
        readOnly,
        compare,
        template,
        forceShowFormat,
        edit,
        isOperation
    } = props;

    const handleCategoryChange = useCallback(
        (category) => {
            setRequest(request => ({
                ...request,
                category: category,
                templateId: null,
                format: undefined,
            }));
            onTemplateChange(null);
        },
        [onTemplateChange, setRequest]
    );

    return <>
        {!compare &&
        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['category']}>
                <Form.Label>{t('category')}</Form.Label>
                <ReportCategoryDropdown
                    isOperation={isOperation}
                    value={getOrNull(request?.category)}
                    isDisabled={edit}
                    onChange={handleCategoryChange}
                />

                <Form.Text className="sub-error">
                    {t(errors['templateId'])}
                </Form.Text>
            </FormGroup>
        </Col>
        }

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['templateId']}>
                <Form.Label>{t('template')}</Form.Label>
                <ReportTemplateDropdown
                    id={"template_id"}
                    isOperation={isOperation}
                    value={getOrNull(request?.templateId)}
                    isDisabled={edit || !request?.category}
                    category={request?.category}
                    onChange={useCallback(
                        (templateId) => {
                            setRequest(request => ({
                                ...request,
                                templateId: templateId,
                                format: undefined,
                            }));

                            onTemplateChange(templateId);

                        },
                        [onTemplateChange, setRequest]
                    )}
                />

                <Form.Text className="sub-error">
                    {t(errors['templateId'])}
                </Form.Text>
            </FormGroup>
        </Col>

        {((forceShowFormat || template?.async) && !isOperation) &&
        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['format']}>
                <Form.Label>{t('file_format')}</Form.Label>
                <EnumDropdown
                    id={"format"}
                    t={t}
                    isClearable={false}
                    value={getOrDefault(request?.format, ReportOutputFormat.CSV)}
                    isDisabled={readOnly || !request?.category}
                    options={ReportOutputFormat}
                    onChange={(format) => {
                        setRequest(request => ({
                            ...request,
                            format: format
                        }));
                    }}
                />

                <Form.Text className="sub-error">
                    {t(errors['format'])}
                </Form.Text>
            </FormGroup>
        </Col>
        }
    </>

}

export default TemplateComponent;
