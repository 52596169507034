import React from "react"
import Pagination from "layout/modules/TableAdvanced/components/Pagination";

const SearchDataPagination = (props) => {

    if (!props.data) return <Pagination />;

    const {data: {totalPages, currentPage, itemsPerPage, totalItems}, onPageChange} = props;

    return <Pagination pages={totalPages}
                       currentPage={currentPage + 1}
                       itemsPerPage={itemsPerPage}
                       totalItems={totalItems}
                       onPageChange={onPageChange}>
        {props.children}
    </Pagination>

}


export default SearchDataPagination;
