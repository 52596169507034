import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import AdminLabelDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/AdminLabelDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import React from "react";
import {parseBoolean} from "system/Utils/parseBoolean";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";

const LabelFilterComponent = props => {

    const {filterState, setFilterState, t} = props;
    return  <Row>

        <Col sm={12} lg={4}>
            <FormGroup>
                <Form.Label>{t('no_labels')}</Form.Label>
                <BooleanDropdown
                    id={"noLabels"}
                    isClearable={true}
                    value={parseBoolean(filterState.noLabels)}
                    onChange={(val) => setFilterState({
                        ...filterState,
                        noLabels: val,
                    })
                    }
                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={4}>
            <FormGroup>
                <Form.Label>{t('labels')}</Form.Label>
                <AdminLabelDropdown
                    id={"labels"}
                    value={getOrNull(filterState?.labels)}
                    isMulti={true}
                    onChange={(value) => {
                        setFilterState({
                            ...filterState,
                            labels: value,
                        })
                    }}
                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={4}>
            <FormGroup>
                <Form.Label>{t('exclude_labels')}</Form.Label>
                <AdminLabelDropdown
                    id={"excludeLabels"}
                    value={getOrNull(filterState?.excludeLabels)}
                    isMulti={true}
                    onChange={(value) => {
                        setFilterState({
                            ...filterState,
                            excludeLabels: value,
                        })
                    }}
                />
            </FormGroup>
        </Col>

    </Row>


}

export default LabelFilterComponent;
