import React, {useMemo} from 'react'
import locale from "../locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";

const VeterinaryDropdown = (props) => {

    const {
        id = "veterinaryId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
    } = props;

    const {t} = useTranslations('classDropdown', locale);

    return <UserHasPermission permission={"READ_VETERINARY"}>
        <GenericRemoteClassDropdown
            {...props}
            id={id}
            placeholder={usePlaceHolder(placeholder, t('veterinary_ph'))}
            noOptionsMessage={useLabelCallback(noOptionsMessage, t('veterinary_no'))}
            loadingMessage={useLabelCallback(loadingMessage, t('veterinary_loading'))}
            remotePath="/veterinaries/search"
            labelHandler={getVeterinaryValueOrEmpty}
            searchFilter={useMemo(() => ({
                sortProperty: 'name',
                sortDirection: 'ASC',
                currentPage: 0,
                itemsPerPage: maxItems,
            }), [maxItems])}
        />
    </UserHasPermission>

}

const getVeterinaryLabel = (o) => o['ballotNumber'] ? o['ballotNumber'] + " - " + o["name"] : "-";
const getVeterinaryValueOrEmpty = (o) => o ? getVeterinaryLabel(o) : "";

export default VeterinaryDropdown;

export {getVeterinaryValueOrEmpty};
