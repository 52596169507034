import {processInputValue} from "system/Forms/FormHandling";
import getAsArray from "system/Arrays/getArray";

const labelFields = '.rs__multi-value__label,.rs__value-container:not(.rs__value-container--is-multi),.date-picker-input input';
const inputFields = 'input:not([ignoresubmit]),select:not([ignoresubmit])';

const getFormParameters = (form) => {
    const data = {};
    const inputs = form.querySelectorAll(inputFields);

    for (let input of inputs) {
        let key = input.id ? input.id : input.name;
        data[key] = processInputValue(input, data[key]);
    }

    return data;
}

const getInputLabels = (formGroup, value) => {

    let valueLabels = [];
    let inputLabels = formGroup.querySelectorAll(labelFields);

    if (inputLabels.length === 0) {
        return [value];
    }

    for (let inputLabel of inputLabels) {
        valueLabels.push(inputLabel.textContent ? inputLabel.textContent : inputLabel.value);
    }

    return valueLabels;
}


const getFormDataWithLabels = (form) => {

    const data = {};

    if (!form)
        return data;

    form.querySelectorAll('div.form-group').forEach((formGroup) => {
        formGroup.querySelectorAll(inputFields).forEach(input => {

            const key = input.id ? input.id : input.name;

            if (input.value) {
                const value = processInputValue(input, data[key]?.value);
                data[key] = {
                    value: value,
                    valueLabels: getInputLabels(formGroup,value),
                    label: formGroup.querySelector('label').textContent,
                    ignoreValues: formGroup.getAttribute("filter-ignore-values"),
                    hidden: formGroup.getAttribute("filter-hidden")
                };
            }
        });
    });

    return data;
}

const getFormDataWithoutParameter = (form, parameter, position) => {

    const formParameters = getFormParameters(form);

    let values = getAsArray(formParameters[parameter]);
    values.splice(position,1);

    return {
        ...formParameters,
        [parameter]:values.length > 0 ? values : null,
    }
};

export {getFormParameters, getFormDataWithLabels,getFormDataWithoutParameter}
