import React, {useEffect, useState} from "react";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import {useTranslation} from "react-i18next";
import GenericPopupMessageComponent from "app/animals/components/generic/GenericPopupMessageComponent";

const BreedDisclaimerPopupComponent = props => {

    const {create} = props;
    const {animal} = useAnimalState();

    const {t} = useTranslation('animal');
    const [popupContent, setPopupContent] = useState(false);

    const disclaimer = animal?.breed?.parent?.disclaimer;

    useEffect(
        () => {

            if (!create) {
                return;
            } else if (disclaimer?.length > 5) {
                setPopupContent(disclaimer);
            } else {
                setPopupContent(false);
            }
        },
        // eslint-disable-next-line
        [disclaimer, create]
    )


    return <GenericPopupMessageComponent
        popupContent={popupContent}
        setPopupContent={setPopupContent}
        confirm={t("confirm")}
        title={t("breed_popup_title")}
    />

}

export default BreedDisclaimerPopupComponent;
