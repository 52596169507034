import React, {useMemo} from 'react'
import GenericRemoteClassDropdown from "../GenericRemoteClassDropdown"

const BreedTypeDropdown = (props) => {

    const {parentId} = props;

    return <GenericRemoteClassDropdown
        {...props}
        remotePath={"/breeds/search-types"}
        classLabel={"description"}
        universalSearch={"description"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: 50,
            parentIds: parentId ? [parentId]: undefined
        }),[parentId])}
    />

}

export default BreedTypeDropdown;
