import {useGet} from "system/Rest/Rest";
import {useCallback, useEffect, useState} from "react";
import ValidateComponent from "app/certificates/components/validate/ValidateComponent";

const ValidateContainer = (props) => {

    const [result, setResult] = useState(false);

    const {data, refetch: check, loading} = useGet({
        path: useCallback(({code}) => `/certificates/validate/${code}`,[]),
        lazy: true
    });

    useEffect(() => {
        if (data)
            setResult(data);
    }, [data])


    return <ValidateComponent
        result={result}
        loading={loading}
        onSubmit={useCallback(
            (data) => {
                setResult(false);
                check({pathParams: data})
            },
            [setResult, check]
        )}
    />


}

export default ValidateContainer;
