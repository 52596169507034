import {useTranslation} from "react-i18next";
import React, {useCallback, useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import {emptyVaccine} from "app/vaccines/utils/VaccineResolver";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import Loading from "layout/modules/Loading/Loading";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrDefault, getOrEmpty} from "system/Objects/ObjectParameters";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {TimeUnit} from "types/TimeUnits";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const FormComponent = (props) => {

    const {t} = useTranslation('vaccine');
    const {vaccine: savedVaccine, loading = true, onSubmit, errors = {}, readOnly, compare} = props;

    const [vaccine, setVaccine] = useState(savedVaccine ? savedVaccine : emptyVaccine);

    return <div className={classnames("form-component", {
        "view-mode": readOnly,
        "compare-mode": compare,
    })}>

        {!compare &&
        <GenericFormError t={t} errors={errors}/>
        }
        
        <FormHandleNotSaved
            remote={savedVaccine}
            local={vaccine}
            loading={loading}
        />
        <Loading visible={loading}/>

        <Form>
            <Box className={"mandatory"}>
                <BoxTitle>
                    {t('form_title')}
                </BoxTitle>

                <Row>
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['name']}>
                            <Form.Label>{t('name')}</Form.Label>
                            <FormControl
                                type="text"
                                id="name"
                                placeholder={t('name_ph')}
                                value={getOrEmpty(vaccine?.name)}
                                onChange={useCallback((e) => setVaccine(vaccine => ({
                                    ...vaccine,
                                    name: e.target.value
                                })), [setVaccine])}
                                disabled={readOnly}

                            />
                            <Form.Text className="sub-error">
                                {t(errors['name'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>


                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['isActive']}>
                            <Form.Label>{t('active')}</Form.Label>
                            <BooleanDropdown
                                id="isActive"
                                isClearable={true}
                                isDisabled={readOnly}
                                value={parseBoolean(vaccine?.isActive, true)}
                                onChange={useCallback((val) => {
                                    setVaccine(vaccine => ({
                                        ...vaccine,
                                        isActive: val
                                    }))
                                }, [setVaccine])}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['isActive'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['requiresPermission']}>
                            <Form.Label>{t('requires_permission')}</Form.Label>
                            <BooleanDropdown
                                id="requiresPermission"
                                isClearable={true}
                                isDisabled={readOnly}
                                value={parseBoolean(vaccine?.requiresPermission, false)}
                                onChange={useCallback((val) => {
                                    setVaccine(vaccine => ({
                                        ...vaccine,
                                        requiresPermission: val
                                    }))
                                }, [setVaccine])}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['requiresPermission'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['immunizationTimePeriod']}>
                            <Form.Label>{t('immunization_time_period')}</Form.Label>
                            <FormControl
                                type="text"
                                id="immunizationTimePeriod"
                                disabled={readOnly}
                                placeholder={t('immunization_time_period_ph')}
                                value={getOrEmpty(vaccine?.immunizationTimePeriod)}
                                onChange={useCallback((e) => setVaccine(vaccine => ({
                                    ...vaccine,
                                    immunizationTimePeriod: e.target.value
                                })), [setVaccine])}

                            />

                            <Form.Text className="sub-error">
                                {t(errors['immunizationTimePeriod'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['immunizationTimeUnit']}>
                            <Form.Label>{t('immunization_time_unit')}</Form.Label>
                            <EnumDropdown
                                t={t}
                                value={getOrDefault(vaccine?.immunizationTimeUnit, TimeUnit.YEAR)}
                                options={TimeUnit}
                                isClearable={false}
                                isDisabled={readOnly}
                                placeholder={t('immunization_time_unit_ph')}
                                isSearchable={false}
                                onChange={useCallback((val) => {
                                    setVaccine(vaccine => ({
                                        ...vaccine,
                                        immunizationTimeUnit: val
                                    }))
                                }, [setVaccine])}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['immunizationTimeUnit'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                </Row>
            </Box>

            {!readOnly &&
            <Row>
                <Col className={"text-end"}>
                    <UserHasPermission permission="MANAGE_VACCINES">
                        <Button variant="primary" className="submit" disabled={loading} onClick={() => onSubmit(vaccine)}>
                            {t("save_form")}
                        </Button>
                    </UserHasPermission>
                </Col>
            </Row>
            }

        </Form>
    </div>

}

export default FormComponent;
