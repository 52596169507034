import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import React from "react";
import classnames from "classnames";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import LostComponent from "app/animals/components/lost/show/LostComponent";
import SetDeadContainer from "app/animals/containers/SetDeadContainer";
import LostCloseButtonComponent from "app/animals/components/lost/other/LostCloseButtonComponent";

const StatusOperationsComponent = (props) => {

    const {t} = useTranslation("animal");
    const {animal} = props;

    return <div className={"operation-buttons"}>
        <div className={"title"}>
            {t('set_status')}
        </div>
        <div className={"buttons"}>

            <LostCloseButtonComponent {...props} animal={animal}/>

            <AnimalOperationAllowed animal={animal} operation={AnimalOperations.SET_LOST}>
                <LostComponent {...props} transponder={animal?.transponder}>
                    {({setVisible, active}) =>
                        <Button variant="danger" onClick={setVisible} className={classnames({'active': active})}>
                            {t("as_lost")}
                        </Button>
                    }
                </LostComponent>
            </AnimalOperationAllowed>

            <SetDeadContainer {...props} animal={animal}/>

        </div>

    </div>
}

export default StatusOperationsComponent;
