import {useGet} from "system/Rest/Rest";
import i18next from "i18next";
import {useMemo} from "react";
import IndexComponent from "../components/IndexComponent";

const IndexContainer = (props) => {

    const {data: contents, loading} = useGet({
        path: "/help/list",
        queryParams: useMemo(() => ({
            language: i18next.language,
        }), [])
    });

    const helpTopics = useMemo(
        () => contents?.reduce((topics, content) => {
                return {
                    ...topics,
                    [content.category] : Array.isArray(topics[content.category])
                        ? [...topics[content.category], content]
                        : [content]
                }
            },{}),
        [contents]
    )

    return <IndexComponent {...props} topics={helpTopics} loading={loading}/>
}

export default IndexContainer;
