import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import Loading from "layout/modules/Loading/Loading";

const AssociateNewEntityComponent = (props) => {

    const {onSearch, vatNumber, entities, loading, onAssociate, login} = props;
    const {t} = useTranslation("login");

    const [addPopup, setAddPopup] = useState({});


    return <>
        <PopupConfirm
            title={t('CONFIRM_ASSOCIATE_ENTITY')}
            message={t('CONFIRM_ASSOCIATE_ENTITY_MSG', {name: addPopup.entityName})}
            classConfirm={"primary"}
            visible={!!addPopup.entityId}
            onCancel={() => {
                setAddPopup({});
            }}
            onConfirm={() => {
                setAddPopup({})
                onAssociate(addPopup.entityId, {name: addPopup.entityName});
            }}
            backdrop={login}
        />

        <div className={"associate-new-entity"}>

            <div className={"text-help"}>{t('entity_add_help')}</div>
            <SearchArea onSearch={onSearch} value={vatNumber} liveSearch={true}/>

            <Loading localOnly={true} visible={loading} />

            {!entities
                ? <div className="no-results">{t('add_entity_no_results')}</div>
                : <TableAdvanced hover loading={(loading)}>
                    <thead>
                    <tr>
                        <th style={{width: "100%"}}>{t('entity_name')}</th>
                    </tr>
                    </thead>
                    <tbody>

                    {entities?.map((value, index) => {
                        return <tr key={index}>
                            <td>
                                {value.name}

                                {!value.vatNumber &&
                                <span className={"sub-entity-label"}>
                                    {t('sub_entity')}
                                </span>
                                }

                            </td>

                            {!value.status
                                ? <td className={"table-text-option request-association"}
                                      onClick={() => setAddPopup({
                                          entityName: value.name,
                                          entityId: value.id
                                      })}>
                                    {t('request_association')}
                                </td>
                                : <td className={"table-text-option request-association-not-possible"}>
                                    {(value.status === "PENDING_ENTITY_RESPONSE") && t('pending')}
                                    {(value.status === "PENDING_VETERINARY_RESPONSE") && t('pending')}
                                    {(value.status === "ACCEPTED") && t('associated')}
                                </td>
                            }
                        </tr>
                    })}

                    </tbody>
                </TableAdvanced>
            }


        </div>
    </>
}

export default AssociateNewEntityComponent;
