const getOriginalRawObject = (object, changes) => {

    const original = {
        ...object
    }

    Object.keys(changes).forEach((key) => original[key] = changes[key].oldValue);

    return original;

}

const getDiffsInErrorFormat = (changes = {}) => {
    const errors = {}
    Object.keys(changes).forEach((key) => errors[key] = true);
    return errors;
}


export {getOriginalRawObject,getDiffsInErrorFormat};
