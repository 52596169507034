export const en = {

    country: "Country",


    address: "Address",
    address_name: "Street Address",
    address_name_ph: "Please Type the Street Name. (eg. Rua do Liceu)",
    no_address_suggestions: "No Sugestions. Type the Address",


    door: "Door number",
    door_ph: "Ex. 3A, 3",

    accommodation: "Accomodation Id",
    accommodation_ph: "Ex. 3E, 2D",

    zip_code: "Zip Code",
    zip_code_ph: "0000-000",
    pt_zip_help: "For more info visit click here",

    locality: "Locality",
    locality_ph: "Ex. Lisboa",
    no_locality_suggestions: "No Suggestions. Type the locality name",

    use: "{{value}}",

    parish: "Parish",
    parish_ph: "Please Select a Parish",
    no_parish_found: "No parish found",


    //Errors
    INVALID_POSTAL_CODE: "Invalid Postal Code.",
    MISSING_POSTAL_CODE: "Invalid Postal Code.",
    MISSING_ADDRESS: "Please fill the address and the Door Number",
    MISSING_LOCALITY: "Please fill the locality",
    MISSING_PARISH: "Please select one Parish",


    //INternal errors
    INVALID_DOOR_NUMBER: "Invalid Door Number",

    help_address: "Addresss Help",

};

export default en;
