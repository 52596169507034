import {useApiState} from "../API/APIStateContext"
import {useCallback} from "react";
import {convertTimezone} from "layout/modules/DayJS/functions";
import DayJS from "layout/modules/DayJS/DayJS";

const DateTime = (props) => {
    const {authentication} = useApiState();
    return <DayJS {...props} timeZone={props?.timezone ? props.timezone: authentication.timeZone}/>
}

const useDateTime = (format = "DD-MM-YYYY HH:mm:ss") => {

    const {authentication} = useApiState();

    return useCallback((timestamp, timezone) => {

        if (timestamp === 0) {
            return "";
        }

        return convertTimezone(timestamp, null, timezone ? timezone : authentication.timeZone).format(format);
    }, [authentication, format]);

}

const useConvertedDate = () => {
    const {authentication} = useApiState();

    return useCallback((timestamp, timeZone) => {
        return convertTimezone(timestamp, null, timeZone ? timeZone : authentication.timeZone);
    }, [authentication]);
}

const useTimezone = () => {
    const {authentication} = useApiState();
    return authentication?.timeZone;
}


export default DateTime;

export {
    useDateTime,
    useConvertedDate,
    useTimezone,
}
