import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Box from "layout/modules/Box/Box";
import {useTranslation} from "react-i18next";
import "app/animals/assets/css/process-observations.scss";
import DateTime from "system/DateTime/DateTime";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import React, {useCallback, useState} from "react";
import Form from "react-bootstrap/Form";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import Loading from "layout/modules/Loading/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import getArray from "system/Arrays/getArray";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const ProcessObservationsComponent = props => {

    const {t} = useTranslation("animal");

    const {lostInfo, errors, submitting, onSubmit, onDelete} = props;

    const [isFormVisible, setFormVisible] = useState(false);
    const [observation, setObservation] = useState("");
    const [deletedInfo, setDeletedInfo] = useState(false);

    const handleObservationSubmit = useCallback(
        (observation) => {
            onSubmit(observation);
            setFormVisible(false);
            setObservation("");
        },
        [onSubmit, setFormVisible, setObservation]
    );

    const switchForm = useCallback(
        () => setFormVisible(show => !show),
        [setFormVisible]
    );

    return <Box className={"process-observations"}>
        <BoxTitle>{t('process_observations')}</BoxTitle>
        <BoxOptions>
            <TableIconAdd onClick={switchForm} className={{active: isFormVisible}}/>
        </BoxOptions>

        <PopupConfirm
            title={t('DELETE_OBSERVATION_CONFIRM')}
            message={t('DELETE_OBSERVATION_CONFIRM_MSG')}
            classConfirm={"danger"}
            visible={!!deletedInfo}
            onCancel={useCallback(
                () => {
                    setDeletedInfo(false)
                }, [setDeletedInfo])
            }
            onConfirm={useCallback(
                () => {
                    setDeletedInfo(false);
                    onDelete(deletedInfo);
                },
                [onDelete, setDeletedInfo, deletedInfo]
            )}
        />

        {isFormVisible &&
            <div className={"process-observations-form"}>
                <Form>
                    <GenericFormError t={t} errors={errors}/>
                    <Loading visible={submitting}/>
                    <Row>
                        <Col md={12} sm={12} xl={12}>
                            <FormGroup error={errors['observation']} mandatory={true}>
                                <Form.Label>{t('observation')}</Form.Label>
                                <Form.Control
                                    name={"observation"}
                                    type="textarea"
                                    as="textarea"
                                    rows={3}
                                    value={getOrEmpty(observation)}
                                    onChange={(e) => setObservation(e.target.value)}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['observation'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>
                    </Row>

                    <div className={"text-end"}>
                        <Button variant="secondary" onClick={switchForm}>
                            {t('cancel')}
                        </Button>
                        <Button variant="primary" onClick={() => handleObservationSubmit(observation)}>
                            {t('create')}
                        </Button>
                    </div>
                </Form>
            </div>

        }

        {lostInfo?.processObservations?.map((observation) =>
            <div className={"observation-block"} key={observation.id}>

                <div className={"identification"}>
                    <div className={"author"}>
                        {observation.creationUser?.name}
                    </div>

                    <div className={"date"}>
                        <DateTime format="DD-MM-YYYY HH:mm">
                            {observation.creationTimestamp}
                        </DateTime>
                    </div>

                    <UserHasPermission permission={["DELETE_LOST_ANIMAL_OBSERVATION"]}>
                        <div className={"delete"}>
                            <div className={classnames("btn-rounded-danger small")}
                                 onClick={() => setDeletedInfo(observation.id)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </div>
                        </div>
                    </UserHasPermission>

                </div>
                <div className={"text"}>
                    {observation.observation}
                </div>

            </div>
        )}

        {(getArray(lostInfo?.processObservations).length === 0 && !isFormVisible) &&
            <div className={"no-observation-block"}>
                {t('no_process_observation')}
            </div>
        }


    </Box>
}

export default ProcessObservationsComponent;
