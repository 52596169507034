import {useMemo} from "react"
import tz from "../../../layout/modules/DayJS/timeZones"

const useTimeZoneList = () => useMemo(() => Object.keys(tz).map((key) => {
    return {
        value: tz[key],
        label: key
    }
}), []);

const DefaultTimeZone = {
    value: "Europe/Lisbon",
    label: "Portugal Continental e Madeira",
}

const useDefaultTimeZone = (timeZones, timeZone) => useMemo(() => {
    if (timeZone)
        return timeZones.find((el) => el.value === timeZone)
    else return DefaultTimeZone;
}, [timeZones, timeZone])

export default useTimeZoneList;

export {
    DefaultTimeZone,
    useDefaultTimeZone
};
