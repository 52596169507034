export const pt = {

    title: "Pré-registos",
    show_pre_register: "Pré-registo {{transponder}}",

    //Pre Register Message
    transponder_not_allowed: "Transponder não rastreado para a sua entidade",
    pre_register_message: "Introduziu um número de transponder que não se encontra rastreado para a sua entidade.<br/><br/>Se se trata de um transponder que adquiriu recentemente deve contactar o quanto antes o seu fornecedor de transponders.<br/><br/>" +
        "Se se trata de um animal proveniente do estrangeiro ou com transponder implantado e sem registo no SIAC, clique no botão abaixo \"Realizar pré-registo\"." +
        "<br/><br/><u>Se o animal era portador de algum tipo de documentação como o Boletim Sanitário, por favor, anexe também cópia das seguintes páginas:1,3,4, 5 e 8.</u><br/><br/>" +
        "Caso se trate de um erro, deve reportar o problema mediante um pedido de suporte (canto superior direito).<br/><br/>" +
        "<strong>ATENÇÃO: O pré-registo não é um registo imediato e por isso ficará pendente de aprovação dos Serviços do SIAC. Assim que o pré-registo for aceite receberá uma notificação e poderá consultar o registo do animal no SIAC.</strong>",
    pre_register_ticket: "Pedido de suporte",
    pre_register_ticket_title: "Transponder {{transponder}} não rastreado",
    pre_register: "Realizar pré-registo",
    pre_register_title: "Pré-registo de animal",

    //Forms
    request: "Pedido",
    register: "Registo",
    registered_owner: "Titular registado",
    owner: "Titular",

    pre_register_s1_desc: "Indique o motivo do pré-registo e anexe uma fotografia com a leitura do transponder",
    pre_register_s2_desc: "Preencha os dados do animal e do titular",
    pre_register_s3_desc: "Aguarde pela aprovação dos serviços do SIAC",

    pre_register_file_upload: "Deve carregar a fotografia do leitor a confirmar a leitura do transponder do animal. A imagem deve estar legível.<br/><br/>" +
        "Selecione ou arraste para aqui a fotografia da leitura do transponder ",
    pre_register_reason: "Motivo do registo",
    submit_pre_request: "Realizar pré registo",

    MISSING_REASON: "Por favor indique a razão do pré registo",
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    INVALID_TRANSPONDER: "O transponder indicado não é válido",

    CREATE_SUCCESS: "Pré-registo guardado",
    CREATE_SUCCESS_MSG: "O pré Registo foi criado com sucesso. Será notificado quando o pedido de registo for aprovado ou rejeitado.",

    NO_CREDITS: "A sua conta não dispõe de créditos suficientes para o pré-registo de animais.",
    CANNOT_REGISTER_FREE_TRANSPONDER: "Não está autorizado a pré-registar este transponder. Por favor contacte o suporte",

    //List
    transponder: "Transponder",
    name: "Nome do animal",
    veterinary: "M. veterinário",
    date: "Data",
    status: "Estado",
    entity: "Entidade",
    no_results: "Não existem resultados de pré registos para apresentar.",
    bulk_item_selected_one: "{{count}} pedido selecionado",
    bulk_item_selected_other: "{{count}} pedidos selecionados",
    accept_in_bulk: "Aprovar os pedidos selecionados",
    reject_in_bulk: "Rejeitar os pedidos selecionados",
    open_requests: "Pedidos pendentes",
    closed_requests: "Histórico",

    upload_photo: "Adicionar foto",
    documents: "Documentos ({{count}})",
    preview_documents: "Visualizar documentos",

    BULK_ACCEPT_CONFIRM: "Confirmação",
    BULK_ACCEPT_CONFIRM_MSG: "Os pedidos para registo apresentados na lista abaixo vão ser APROVADOS.\r\n\r\n{{transponders}}\r\n\r\nDeseja realmente APROVAR estes pedidos?",
    SUCCESS_BULK_ACCEPT: "Pedidos aprovados",
    SUCCESS_BULK_ACCEPT_MSG: "{{number}} pedidos de registo de titular foram aprovados",
    ERROR_BULK_ACCEPT: "Erro a aprovar",
    ERROR_BULK_ACCEPT_MSG: "Ocorreu um erro. Por favor tente mais tarde.",

    BULK_REJECT_CONFIRM_MSG: "Os pedidos para registo apresentados na lista abaixo vão ser REJEITADOS.\r\n\r\n{{transponders}}\r\n\r\n",
    SUCCESS_BULK_REJECT: "Pedidos rejeitados",
    SUCCESS_BULK_REJECT_MSG: "{{number}} pedidos de registo foram rejeitados",
    ERROR_BULK_REJECT: "Erro a rejeitar",
    ERROR_BULK_REJECT_MSG: "Ocorreu um erro. Por favor tente mais tarde.",

    open: "Aberto",

    ACCEPTED: "Aprovado",
    REJECTED: "Rejeitado",
    MORE_INFORMATION_NEEDED: "A aguardar informação",

    true: "Aceite",
    false: "Rejeitado",


    //Form
    accept: "Aceitar",
    reject: "Rejeitar",
    cancel: "Cancelar",
    request_more_information: "Pedir informação",

    select_file_one: "Documento - ({{count}} documento)",
    select_file_other: "Escolha o ficheiro - ({{count}} documentos)",
    reject_message_helper: "Por favor indique a razão da rejeição",
    more_information_message_helper: "Por favor indique que informação se encontra em falta",

    request_is_closed: "Pré-registo fechado",
    request_is_closed_msg: "Este pedido já se encontra no estado {{status}} e não pode ser alterado.",
    request_is_closed_msg_registered: "Pode consultar a ficha de registo deste animal carregando aqui",
    request_is_rejected_msg: "Este pedido foi fechado e rejeitado com o seguinte motivo: <strong>{{rejectReason}}</strong>.",

    transponder_already_registered: "Transponder já registado",
    transponder_already_registered_msg_1: "O transponder ",
    transponder_already_registered_msg_2:" já se encontra registado no SIAC pelo que este pré registo não poderá ser dado como aceite!",

    transponder_pending_entity: "Transponder pendente no sistema de rastreabilidade",
    transponder_pending_entity_msg: "O transponder <strong>{{transponder}}</strong> está neste momento registado numa transação <strong>Pendente</strong> no sistema de rastreabilidade para a entidade  <0><strong>{{entity}} ({{vatNumber}})</strong></0>. Para conseguir aceitar este pré registo deverá aceitar ou rejeitar a transação.",

    transponder_in_entity: "Transponder com rastreabilidade",
    transponder_in_entity_msg: "O transponder <strong>{{transponder}}</strong> está neste momento registado no sistema de rastreabilidade na entidade <strong><0>{{entity}} ({{vatNumber}})</0></strong>. Com a aceitação deste registo será criada uma transação com o transponder para a entidade <strong>{{registerEntity}} ({{registerVatNumber}})</strong>.",

    //Status
    created_by: "Autor",
    review_by: "Revisão",
    created_by_entity: "Entidade",
    creation_date: "Data",
    approved_by: "Aprovado por",
    reject_reason: "Motivo da rejeição",
    
    //Filter
    closed_process: "Processo fechado",
    accepted_process: "Registo aceite",

    SUCCESS_CHANGED: "Pré-registo de animal",
    SUCCESS_CHANGED_MSG: "O pré-registo do animal com o transponder {{transponder}} foi {{status}}.",
    SUCCESS_REQUEST_MORE_INFORMATION: "Foi realizado um pedido de mais informação para o pré-registo do animal com o transponder {{transponder}}.",

    ERROR_CHANGE_STATE: "Pedido de registo de animal",
    ERROR_CHANGE_STATE_MSG: "Não foi possível alterar o estado do pedido. Por favor tente mais tarde",


    //File Upload
    add_new_document: "Carregar novo documento",
    add_new_document_description: "Por favor arraste para esta área ou utilize o botão escolher para anexar novos documentos",
    add_files: "Adicionar ficheiros",
    close: "Fechar",
    SUCCESS_UPLOAD: "Documentos carregados",
    SUCCESS_UPLOAD_MSG: "Documentos carregados com sucesso no pedido de transmissão de titularidade",

    ERROR_UPLOADING: "Erro a carregar",
    ERROR_UPLOADING_MSG: "Não foi possível carregar os documentos anexados. Por favor tente mais tarde ou entre em contacto com os serviços SIAC",

    //more information
    more_information_request: "Pedido de mais informação:",
    more_information_provided: "A aguardar validação",
    more_information_msg: "Foram carregados novos documentos em resposta ao pedido \"<i>{{message}}</i>\"",

    //validation status
    validation_status: "Estado da validação",
    WAITING_VALIDATION: "Validação pendente",
    WAITING_DOCUMENTATION: "Falta documentação",
    select_ph: "Selecione uma opção",
    DONE: "Fechado",

    VETERINARY_USER: "M. Veterinário",
    NORMAL_USER: "Utilizador Normal",

    next_request: "Pedido seguinte >",
    previous_request: "< Pedido anterior",

    labels: "Etiquetas",
    exclude_labels: "Excluir Etiquetas",
    no_labels: "Apresentar apenas sem labels",

    animal_is_danger: "Atenção",
    animal_is_danger_msg: "Animal Potencialmente Perigoso.",

    error_loading_preview: "Erro a visualizar os ficheiros",
    error_loading_preview_msg: "Ocorreu um erro a abrir o visualizador de ficheiros. Por favor faça refresh ao browser para resolver o problema",

    error_in_documents: "Foram detetados ficheiros inválidos. Por favor verifique novamente ou tente com um documento diferente",


    external_transponder_validation: "Validação externa do transponder",
    external_validation_pending: "A validação do transponder está pendente. Por favor aguarde mais uns minutos enquanto o SIAC valida externamente o transponder",

    external_validation_found: "O transponder foi dado como registado na(s) base(s) de dado(s) <strong>{{dbNames}}</strong>!",
    external_validation_not_found: "O transponder não foi encontrado em nenhuma base de dados internacional.",

    duplicated_entry: "Pré-registo repetido",
    duplicated_entry_msg: "Parecem existir mais {{total}} registo(s) para o mesmo transponder. Por favor verifique na listagem de pedidos.",


    SUCCESS_OWNER_CHANGE: "Pedido de pré-registo",
    SUCCESS_OWNER_CHANGE_MSG: "O pedido de pré-registo foi submetido com sucesso. Deve aguardar agora pela validação dos serviços.",


};

export default pt;
