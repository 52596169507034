import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import classnames from "classnames";
import {Form} from "react-bootstrap";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ParamsComponent from "app/reporting/components/generate/ParamsComponent";
import RecurrenceFormComponent from "app/reporting/components/schedule/RecurrenceFormComponent";
import TemplateComponent from "app/reporting/components/generate/TemplateComponent";
import TemplateAgreementComponent from "app/reporting/components/generate/TemplateAgreementComponent";
import Loading from "layout/modules/Loading/Loading";

const FormComponent = props => {

    const {t} = useTranslation("reports");
    const {create, loading, schedule: remoteSchedule = {}, errors = {}, onSubmit, template, compare, isOperations} = props;

    const [readOnly, setReadOnly] = useState(!create);
    const [schedule, setSchedule] = useState(remoteSchedule);

    return <>
        <Loading visible={loading}/>
        <div className={classnames("report-template form-component", {
            "view-mode": readOnly,
            "compare-mode": compare,
        })}>
            <Form>
                {(create && !isOperations) &&
                <TemplateAgreementComponent
                    {...props}
                    t={t}
                    template={template}
                    request={schedule}
                    setRequest={setSchedule}
                />
                }

                {!compare && <GenericFormError t={t} errors={errors}/>}

                <FormHandleNotSaved
                    remote={remoteSchedule}
                    local={schedule}
                    loading={loading}
                    readOnly={readOnly}
                    setLocal={setSchedule}
                    setReadOnly={setReadOnly}
                    onSubmit={onSubmit}/>

                <Box>
                    <BoxTitle>{t('schedule_base_config')}</BoxTitle>
                    <BoxOptions>
                        {(!create && !compare) &&
                        <ToolTip content={t('edit_schedule')}>
                            <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                                 onClick={() => setReadOnly(readOnly => !readOnly)}>
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </div>
                        </ToolTip>
                        }
                    </BoxOptions>

                    <Row>
                        <TemplateComponent
                            {...props}
                            t={t}
                            forceShowFormat={true}
                            readOnly={readOnly}
                            edit={!create}
                            request={schedule}
                            setRequest={setSchedule}
                            isOperation={isOperations}

                        />

                        {schedule?.templateId &&
                        <RecurrenceFormComponent
                            {...props}
                            t={t}
                            schedule={schedule}
                            setSchedule={setSchedule}
                            readOnly={readOnly}
                        />
                        }

                    </Row>
                </Box>

                {(template && template?.parameters?.length > 0) &&
                <ParamsComponent
                    {...props}
                    t={t}
                    request={schedule}
                    setRequest={setSchedule}
                    readOnly={readOnly}
                />
                }


                {(!readOnly && schedule?.templateId) &&
                <Row>
                    <Col className={"text-end"}>
                        <Button variant="primary" className={"submit"} onClick={() => onSubmit(schedule)}>
                            {create ? t("create_schedule") : t("save_schedule")}
                        </Button>
                    </Col>
                </Row>
                }

            </Form>
        </div>
    </>
}
export default FormComponent;
