import {useGet} from "system/Rest/Rest";
import {useCallback} from "react";
import LostAlertComponent from "app/animals/components/lost/show/LostAlertComponent";

const LostAlertContainer = (props) => {

    const {transponder, reload} = props;

    const {data, loading, refetch} = useGet({
        path: "/lost-animals/" + transponder,
        localErrorOnly: true,
    });


    return <LostAlertComponent
        {...props}
        lostInfo={data}
        loading={loading}
        reload={useCallback(
            () => {
                reload();
                refetch();
            },
            [reload, refetch]
        )}
    />

}

export default LostAlertContainer;
