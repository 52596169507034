import {useGet} from "system/Rest/Rest";
import React, {useEffect, useMemo} from "react";
import {getOrNull} from "system/Objects/ObjectParameters";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";

const VaccineDropdown = props => {

    const {isDisabled = false, value, defaultSelectedVaccine, onVaccineLoad, forceLoadData} = props;

    const {data: vaccines, loading} = useGet({
        path: "/rabies-vaccines/list",
        lazy: isDisabled && !forceLoadData,
    });

    const vaccinesOptions = useMemo(
        () => {
            if (vaccines) {
                return vaccines?.reduce((data, item) => ({
                    ...data,
                    [item.name]: item.id
                }), {})
            } else if (isDisabled && defaultSelectedVaccine) {
                return {
                    [defaultSelectedVaccine?.name]: defaultSelectedVaccine?.id
                }
            }
        },
        [vaccines, isDisabled, defaultSelectedVaccine]
    );

    useEffect(
        () => {
            if (onVaccineLoad)
                onVaccineLoad(vaccines);
        },
        [onVaccineLoad, vaccines]
    );

    return <EnumDropdown
        {...props}
        isLoading={loading}
        value={getOrNull(value)}
        options={vaccinesOptions ? vaccinesOptions : {}}
        isDisabled={isDisabled}
    />


}

export default VaccineDropdown;
