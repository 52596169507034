import React, {useMemo} from 'react'
import GenericRemoteClassDropdown from "../GenericRemoteClassDropdown"
import locale from "../locales/locales"
import useTranslations from "../../../../../system/Translations/UseTranslations";
import usePlaceHolder from "../utils/usePlaceHolder";
import useLabelCallback from "../utils/useLabelCallback";

const UserDropdown = (props) => {

    const {t} = useTranslations('classDropdown', locale);
    const {
        id = "userId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
        userType,
    } = props;

    return <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder,t('generic_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage,t('generic_no'))}
        loadingMessage={useLabelCallback(loadingMessage,t('generic_loading'))}
        remotePath={"/users/search"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: maxItems,
            type: userType ? userType : "ANY",
        }), [maxItems, userType])}
    />

}

export default UserDropdown;
