import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React, {useEffect, useState} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import UserDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/UserDropdown";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import {parseNumber} from "system/Utils/parseNumber";
import AuditUserType from "app/audit/utils/AuditUserType";

const ListFilterComponent = (props) => {

    const {searchFilter, t, filterActions = {}, userId, filterObjectTypes = {}, objectType, ownerVatNumber} = props;
    const [filterState, setFilterState] = useState();

    useEffect(() => {
        setFilterState({...searchFilter});
    }, [searchFilter]);

    if (!filterState) {
        return <></>;
    }

    return <>
        <Row>
            {(Object.keys(filterActions).length > 0) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('action')}</Form.Label>
                    <EnumDropdown
                        t={t}
                        placeholder={t('select_ph')}
                        id={"actions"}
                        isMulti={true}
                        options={filterActions}
                        value={getOrNull(filterState.actions)}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState, actions: val,
                            })
                        }}
                    />
                </FormGroup>
            </Col>}

            {(!objectType && Object.keys(filterObjectTypes).length > 0) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('object_type')}</Form.Label>
                    <EnumDropdown
                        t={t}
                        placeholder={t('select_ph')}
                        id={"objectType"}
                        isMulti={false}
                        options={filterObjectTypes}
                        value={getOrNull(filterState.objectType)}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState, objectType: val,
                            })
                        }}
                    />
                </FormGroup>
            </Col>}


            {(!userId && !ownerVatNumber) && <>
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('user_type')}</Form.Label>
                        <EnumDropdown
                            id={"userType"}
                            t={t}
                            isMulti={false}
                            options={AuditUserType}
                            isClearable={true}
                            value={getOrNull(filterState.userType)}
                            onChange={(val) => setFilterState({
                                ...filterState, userType: val,
                            })}
                        />
                    </FormGroup>
                </Col>
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('user')}</Form.Label>
                        <UserDropdown
                            id={"userIds"}
                            isMulti={true}
                            value={getOrNull(filterState.userIds)}
                            onChange={(val) => setFilterState({
                                ...filterState, userIds: val,
                            })}
                        />
                    </FormGroup>
                </Col>
            </>
            }

            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('from_date')}</Form.Label>
                    <DatePicker
                        name={"fromTimestamp"}
                        placeholder={t("date_ph")}
                        value={parseNumber(filterState.fromTimestamp, undefined)}
                        toDate={parseNumber(filterState.toTimestamp, undefined)}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState, fromTimestamp: val,
                            })
                        }}
                    />
                </FormGroup>
            </Col>

            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('to_date')}</Form.Label>
                    <DatePicker
                        name={"toTimestamp"}
                        placeholder={t("date_ph")}
                        value={parseNumber(filterState.toTimestamp, undefined)}
                        fromDate={parseNumber(filterState.fromTimestamp, undefined)}
                        outputEndOfDayTimestamp={true}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState, toTimestamp: val,
                            })
                        }}
                    />
                </FormGroup>
            </Col>


        </Row>
    </>

}

export default ListFilterComponent
