import PhoneInput from "react-phone-input-2";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import './assets/css/PhoneInput.scss';
import 'layout/modules/Forms/Dropdowns/CountryDropdown/assets/flags.scss';
import {getPhoneCountry} from "layout/modules/Forms/PhoneControl/utils";
import countryList from "system/Countries/countries";

const PhoneControl = (props) => {

    const {onChange, defaultValue, value: valueProp, disabled, country = 'pt'} = props;
    const [value, setValue] = useState(defaultValue ? defaultValue : valueProp);
    const ref = useRef(null);

    const selectedCountry = ref?.current?.state?.selectedCountry;
    const [isSupportedCountry, setSupportedCountry] = useState(true);

    useEffect(
        () => setSupportedCountry(ref?.current?.state?.selectedCountry !== 0),
        [ref?.current?.state?.selectedCountry]
    );

    const handleClear = useCallback(() => {
        if (selectedCountry?.countryCode) {
            const cc = "+" + selectedCountry.countryCode;
            setValue(cc)
            if (onChange)
                onChange("");
        }
    }, [setValue, onChange, selectedCountry?.countryCode]);

    useEffect(
        () => {
            if (valueProp) {
                setValue(valueProp)
            } else if (!value && selectedCountry?.countryCode) {
                setValue("+" + selectedCountry.countryCode)
            }
        },
        [setValue, valueProp, value, selectedCountry?.countryCode]
    );

    const countryLocalization = useMemo(
        () => countryList.reduce(
            (cl, country) => ({
                ...cl,
                [country.code.toLowerCase()]: country.name['pt_PT']
            }), {}
        ),
        []
    );

    const sortedCountries = useMemo(
        () => Object.keys(countryLocalization).sort(
            (a, b) => Intl.Collator().compare(countryLocalization[a], countryLocalization[b])),
        [countryLocalization]
    );

    return <div className={classnames("phone-control", "input-wrapper", {"disabled": disabled})}>
        <PhoneInput
            {...props}
            ref={ref}
            key={country}
            value={value}
            preferredCountries={['pt', country]}
            country={getPhoneCountry(value, country, isSupportedCountry)}
            countryCodeEditable={true}
            onlyCountries={sortedCountries}
            placeholder={"+351 911 111 111"}
            masks={{pt: '... ... ...'}}
            preserveOrder={['onlyCountries']}
            localization={countryLocalization}
            onChange={useCallback((number, country) => {
                setValue(number);
                if (onChange) {
                    onChange((number === country?.dialCode) ? "" : "+" + number);
                }
            }, [setValue, onChange])}
        />
        <div className="form-control-extra-buttons">

            {(!disabled && value) &&
                <i>
                    <FontAwesomeIcon
                        icon={faTimes}
                        style={{visibility: value ? 'visible' : 'hidden'}}
                        onClick={handleClear}
                    />

                </i>}
        </div>
    </div>

}

export default PhoneControl;
