import classnames from "classnames";
import ListMenuOptionsComponent from "app/animals/components/list/ListMenuOptionsComponent";
import React from "react";
import {useTranslation} from "react-i18next";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import BallComponent from "app/animals/components/generic/BallComponent";
import DateTime from "system/DateTime/DateTime";
import getAnimalStatusLabel from "app/animals/utils/getAnimalStatusLabel";
import {AnimalStatus} from "types/Animal";
import {getOrDefault} from "system/Objects/ObjectParameters";
import useAnimalListClick from "app/animals/utils/handleAnimalListClick";

const RunningListEntryComponent = (props) => {

    const {t} = useTranslation("animal");
    const {
        animal, onCheckBoxChange, checkboxSelectedItems, checkEnableCheckBox,
        isNonNormalAnimalPresent, hideOptions = false, reload
    } = props;


    const handleClick = useAnimalListClick(animal);


    return <tr className={classnames({'animal-line': !animal?.isExternal})}>

        {onCheckBoxChange &&
        <td className={"table-checkbox"}>
            <CheckBox
                value={getOrDefault(checkboxSelectedItems.find(e => e.id === animal.id), false)}
                onChange={(value) => onCheckBoxChange(animal, value)}
                disabled={checkEnableCheckBox ? !checkEnableCheckBox(animal) : false}
            />
        </td>
        }

        <td className={"d-none d-sm-table-cell"}>
            <div>
                <BallComponent animal={animal} reload={reload}/>
            </div>
        </td>

        <td className={"d-none d-sm-table-cell"} onClick={handleClick}>
            <div className={"line-title strong fill-empty"}>
                {!animal.isExternal
                    ? animal.name
                    : <div className={"external-animal"}>
                        {t('external_animal')}
                    </div>
                }
            </div>
            <div className={"line-regular"}>
                {animal.breed?.name ? animal.breed?.name + " | " : ""} {t(animal.gender)}
            </div>
        </td>

        <td onClick={handleClick}>
            <div className={classnames("line-transponder", {"merged-transponder": animal?.hasMergedTransponders})}>
                {animal?.mergedTransponder ?? animal.transponder}
            </div>
            <div className={"line-regular d-none d-sm-table-cell"}>
                <DateTime format="DD-MM-YYYY" timezone={'UTC'}>
                    {animal.registrationDate}
                </DateTime>
            </div>
        </td>

        <td className={"d-none d-lg-table-cell"} onClick={handleClick}>
            <div className={"line-title"}>
                {animal.breed?.name}
                {animal.crossBreed &&
                <>&nbsp;X&nbsp;{animal.crossBreed.name}</>
                }
            </div>
            <div className={"line-regular"}>
                <DateTime format="DD-MM-YYYY" timezone={"UTC"}>
                    {animal.birthdate}
                </DateTime>
            </div>

        </td>

        <td className={"col-location d-none d-lg-table-cell"} onClick={handleClick}>
            <div className={"line-title fill-empty"}>
                {animal.parish?.name}
            </div>

            {animal.county &&
            <div className={"line-regular"}>
                {animal.county} | {animal.district}
            </div>
            }
        </td>


        {isNonNormalAnimalPresent &&
        <td className={"d-none d-lg-table-cell fill-empty"} onClick={handleClick}>
            <div className={classnames("line-title", "status", {
                "lost": animal.status === AnimalStatus.LOST,
                "found": animal.status === AnimalStatus.FOUND,
                "dead": animal.status === AnimalStatus.DEAD,
                "normal": animal.status === AnimalStatus.NORMAL,
                "locked": animal.isLocked,
                "pending": animal.hasPendingOwnerTransfer,
                "invalid": (!animal.isDataValid && !animal.isPreRegister),
                "pre-register": animal.isPreRegister,
            })}>
                {getAnimalStatusLabel(t, animal)}
            </div>
        </td>
        }

        {!hideOptions &&
        <td className={"animal-menu-dropdown"}>
            <ListMenuOptionsComponent {...props} animal={animal}/>
        </td>
        }

    </tr>

}

export default RunningListEntryComponent;
