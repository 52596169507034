import classnames from "classnames";

const ValueLabelComponent = (props) => {

    const {label, value, className, children} = props;

    return <div className={classnames("value-label",className)}>
        <div className={"label"}>
            {label}
        </div>
        <div className={"value"}>
            {value ? value : children}
        </div>
    </div>
}

export default ValueLabelComponent;
