import {useTranslation} from "react-i18next";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import PhoneControl from "layout/modules/Forms/PhoneControl/PhoneControl";
import Box from "layout/modules/Box/Box";

const ParticipantDataComponent = props => {

    const {t} = useTranslation("animal");
    const {readOnly, errors, lostInfo, setLostInfo} = props;

    return <Box>
        <BoxTitle>{t('participant_data')}</BoxTitle>

        <Row>
            <Col sm={12} md={12} lg={12}>
                <FormGroup error={errors['participantName']} mandatory={true}>
                    <Form.Label>{t('participant_name')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('participant_name_ph')}
                        disabled={readOnly}
                        value={getOrEmpty(lostInfo?.participantName)}
                        onChange={useCallback((e) => {
                            setLostInfo((info) => ({
                                ...info,
                                participantName: e.target.value,
                            }))
                        }, [setLostInfo])}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['participantName'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} md={6} lg={6}>
                <FormGroup error={errors['participantContact']} mandatory={true}>
                    <Form.Label>{t('participant_contact')}</Form.Label>
                    <PhoneControl
                        value={getOrEmpty(lostInfo?.participantContact)}
                        disabled={readOnly}
                        onChange={useCallback(phone => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            participantContact: phone,
                        })), [setLostInfo])}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['participantContact'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} md={6} lg={6}>
                <FormGroup error={errors['participantEmail']}>
                    <Form.Label>{t('participant_email')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('email_ph')}
                        disabled={readOnly}
                        value={getOrEmpty(lostInfo?.participantEmail)}
                        onChange={useCallback(e => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            participantEmail: e.target.value?.toLowerCase()?.trim(),
                        })), [setLostInfo])}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['participantEmail'])}
                    </Form.Text>
                </FormGroup>
            </Col>


            {(!readOnly || lostInfo?.participantContact2) &&
            <Col sm={12} md={6} lg={6}>
                <FormGroup error={errors['participantContact2']} mandatory={false}>
                    <Form.Label>{t('participant_contact_alternative')}</Form.Label>
                    <PhoneControl
                        value={getOrEmpty(lostInfo?.participantContact2)}
                        disabled={readOnly}
                        onChange={(phone) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            participantContact2: phone,
                        }))}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['participantContact2'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            }

            {(!readOnly || lostInfo?.participantContact3) &&
            <Col sm={12} md={6} lg={6}>
                <FormGroup error={errors['participantContact3']} mandatory={false}>
                    <Form.Label>{t('participant_contact_alternative')}</Form.Label>
                    <PhoneControl
                        value={getOrEmpty(lostInfo?.participantContact3)}
                        disabled={readOnly}
                        onChange={(phone) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            participantContact3: phone,
                        }))}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['participantContact3'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            }

        </Row>

    </Box>

}

export default ParticipantDataComponent;
