import React from 'react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import UserHasPermission from "../../../../../system/API/Authentication/UserHasPermission"
import {Link} from "react-router-dom"
import * as PropTypes from "prop-types"
import classnames from "classnames";

import 'layout/modules/TableAdvanced/assets/css/table-buttons.scss'
import {getOrDefault} from "system/Objects/ObjectParameters";
import {useLocation} from "react-router";

const TableIconButton = React.forwardRef((props, ref) => {

    const {permission, orUserTypes, path, icon, className, ...otherProps} = props;
    const {visible = true} = props;

    const {pathname} = useLocation();

    if (!visible) {
        return <></>;
    }
    
    const link = <>
        {path && <Link to={getOrDefault(path?.pathname, path)} state={getOrDefault(path?.state, {origin: pathname})}
                       className={classnames("btn-rounded-primary", className)} ref={ref} {...otherProps}>
            <FontAwesomeIcon icon={icon}/>
        </Link>}

        {!path && <div {...otherProps} className={classnames("btn-rounded-primary", className)} ref={ref}>
            <FontAwesomeIcon icon={icon}/>
        </div>}

    </>;

    if (permission) {
        return <UserHasPermission permission={permission} orUserTypes={orUserTypes}>
            {link}
        </UserHasPermission>
    }

    return link;

});

export default TableIconButton;

TableIconButton.propTypes = {
    path: PropTypes.any,
    permission: PropTypes.any,
    icon: PropTypes.object.isRequired,
    className: PropTypes.string
};
