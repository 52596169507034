import Modal from "react-bootstrap/Modal";
import Loading from "../../../layout/modules/Loading/Loading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ModalButtons from "../../../layout/modules/Modal/Buttons/ModalButtons";
import Button from "react-bootstrap/Button";
import FormComponent from "./FormComponent";

const PopupFormComponent = (props) => {

    const {transponder, onSubmit, onClose, loading, errors} = props;
    const {t} = useTranslation("animal-license");

    const [license, setLicense] = useState({});

    return <Modal
        show={true}
        onHide={loading ? () => void 0 : onClose}
        size={"xl dark"}
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{t('licensing')}</Modal.Title>
        </Modal.Header>
        <Loading visible={loading}/>
        <Modal.Body>

            {t('licensing_tip')}
            <FormComponent
                transponder={transponder}
                errors={errors}
                license={license}
                setLicense={setLicense}
            />

        </Modal.Body>

        <ModalButtons>

            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>

            <Button variant="primary" onClick={() => onSubmit(license)}>
                {t("add")}
            </Button>

        </ModalButtons>

    </Modal>

}

export default PopupFormComponent;
