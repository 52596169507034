import React from 'react';

import Login from "./modules/BaseLayout/Login/Login"
import '@fortawesome/fontawesome-svg-core/styles.css';
import './assets/css/fonts.scss'
import './assets/css/dark-mode.scss'

const LoginLayout = (props) => {
    return <>
        <Login {...props}/>
    </>
}

export default LoginLayout;
