const scheduleResolver = (data) => ({
   ...data,
   templateId: data?.template?.id,
   category: data?.template?.category,
});


const scheduleAuditResolver = (data) => ({
   ...data,
   templateId: data?.template,
   template: {
      id: data?.template,
   }
});


export default scheduleResolver;
export {scheduleAuditResolver}
