export const en = {

    name: "Name",
    email: "Email",
    entity: "Entity",

    //FORM
    name_ph: "Type the User First and Last Name",
    save_changes: "Save Changes",
    cancel: "Cancel",
    language: "Language",
    language_ph: "Select a language",
    language_no: "No languages found!",

    timezone: "Time Zone",
    timezone_ph: "Select a time zone",
    timezone_no: "No time zones found!",

    receive_notifications: "Receive Notifications",

    //ERRORS
    MISSING_NAME: "Please fill the with the first and last name",
    INVALID_NAME: "Invalid Name, Please fill the with the first and last name",

    INVALID_LANGUAGE: "Please select a valid language",
    MISSING_LANGUAGE: "Please select a language",

    MISSING_TIMEZONE: "Please select a timezone",
    INVALID_TIMEZONE: "Please select a timezone",

    MISSING_RECEIVE_NOTIFICATIONS: "Please select an option",

    //List Success Creation and Update
    UPDATE_SUCCESS: "User Updated",
    UPDATE_SUCCESS_MSG: "User {{name}} has been successfully updated!",


    FORM_ERROR: "Some Errors found",
    FORM_ERROR_MSG: "Please check the fields marked with errors",


    //Own User
    personal_title: "Personal Information",

    settings_form: "General Settings",
    password_form: "Change Password",
    entities: "Entities",

    new_password_confirm: "New Password Confirmation",
    new_password_confirm_tip: "The same password above",

    password: "Current Password",
    password_tip: "Enter the Current Password",

    new_password: "New Password",
    new_password_tip: "Enter a new Password",


    set_new_password: "Set Password",

    invalid_password: "The password should have at least:",
    //Password Recovery tips
    uppercase: "1 Upper Case Char",
    lowercase: "1 Lower Case Char",
    digits: "1 Digit",
    symbols: "1 Non alphanumeric",
    min: "6 Characters",
    max: "25 Characters",

    invalid_confirm_password: "The password confirmation should be the same as introduced in password field",

    USER_PASSWORD_MISMATCH: "The current password in not correct. Please check it and try again",
    MISSING_OLD_PASSWORD: "The current password is blank. Please check it and try again",

    FORM_PASSWORD_ERROR: "Error while changing the password",
    FORM_PASSWORD_ERROR_MSG: "Please check fields bellow and try again",

    SUCCESS_PASSWORD: "Password Changed",
    SUCCESS_PASSWORD_MSG: "Your password was successfully changed!",

    access_request: "Entity Access Request",

    select_impersonate: "Select an user to impersonate",

    SUCCESS_IMPERSONATE: "User impersonated",
    SUCCESS_IMPERSONATE_MSG: "You are now logged as {{name}}",
    SUCCESS_EXIT_IMPERSONATE_MSG: "You are now logged with your user account",

    ERROR_IMPERSONATE: "Error changing user",
    ERROR_IMPERSONATE_MSG: "Please try again later."

};

export default en;
