import React, {useCallback, useEffect, useState} from "react";
import FormControl from "../../Forms/Control/FormControl";
import {useTranslation} from "react-i18next";

const ConfirmationComponent = (props) => {

    const {confirmationString, onConfirm} = props;

    const {t} = useTranslation('_popup');
    const [confirmationValue, setConfirmationValue] = useState("");

    const handleConfirmationString = useCallback(
        (e) => setConfirmationValue(e.target.value),
        [setConfirmationValue]
    );

    useEffect(
        () => onConfirm(confirmationString === confirmationValue),
        [confirmationString, confirmationValue, onConfirm]
    );

    return <FormControl
        type="text"
        placeholder={t('writeConfirmationLabel', {label: confirmationString})}
        value={confirmationValue}
        onChange={handleConfirmationString}
        className="input-popup-confirm"

    />

}

export default ConfirmationComponent;
