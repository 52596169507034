import React, {createContext, useMemo, useState} from "react"

const MenuStateContext = createContext({});

const useMenuState = () => {
    const context = React.useContext(MenuStateContext)
    if (!context) {
        throw new Error(`useMenuState must be used within a MenuStateContext`)
    }
    return context
}


const MenuStateProvider = (props) => {

    const [menuState, setMenuState] = useState({});

    const value = useMemo(
        () => [menuState, setMenuState],
        [menuState, setMenuState]
    );

    return <MenuStateContext.Provider value={value}>
        {props.children}
    </MenuStateContext.Provider>;

}

export {MenuStateProvider, useMenuState}
