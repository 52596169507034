import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faCapsules} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/animals/locales/locales";
import {useDateTime} from "system/DateTime/DateTime";

const MergedTranspondersAlert = props => {

    const {animal} = useAnimalState();
    const {t} = useTranslations("animal", locale);
    const getDateTime = useDateTime("DD-MM-YYYY");

    if (!animal?.hasMergedTransponders)
        return <></>;

    return <AdvanceInfoMessage
        type={"info"}
        icon={faCapsules}
        iconType={"info"}
        title={t("animal_has_merged_transponders")}
        message={t('animal_has_merged_transponders_msg', {
            transponder: animal.transponder,
            entries: animal?.transponderMerges?.map(
                m => t('merge_entry', {
                    ...m,
                    registrationDate: getDateTime(m?.oldTransponderRegDate, "UTC"),
                    veterinaryName: m?.oldVeterinary?.name,
                    veterinaryBallot: m?.oldVeterinary?.ballotNumber,
                })).join("<br/>")
        })}
    />

}

export default MergedTranspondersAlert;
