import {Link} from "react-router-dom";
import React from "react";
import "app/control/assets/css/next-previous.scss";

const NextAndPreviousComponent = props => {

    const {document, path, t} = props;

    if (!document?.next && !document?.previous)
        return <></>

    return <>
        <div className={"next-and-previous"}>
            <div className={"previous-request"}>
                {document?.previous &&
                <Link to={path + document?.previous}>
                    {t("previous_request")}
                </Link>
                }
            </div>

            <div className={"next-request"}>
                {document?.next &&
                <Link to={path + document?.next}>
                    {t("next_request")}
                </Link>
                }
            </div>
        </div>
    </>
}

export default NextAndPreviousComponent;
