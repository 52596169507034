import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import i18next from "i18next"
import LanguageDetector from 'i18next-browser-languagedetector';
import appConfig from "configs/config";

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: {
            order: ['querystring', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        },
        fallbackLng: appConfig.defaultLng.replace("_","-"),
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: false,
            bindI18n: "languageChanged loaded",
            bindI18nStore: "added",
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i','p','a', 'div', 'u', '&bull'],
        },
        returnObjects: true
    });


const setLangConfig = (namespace, translations) => {
    translations.forEach((translation) => {
        i18next.addResourceBundle(translation.lang, namespace, translation.translation, true, true);
    });
}
const hasLangConfig = (namespace, translations) => !translations.some((translation) => !i18next.hasResourceBundle(translation.lang, namespace));

const setMainLanguage = (lng) => {
    const language = lng.replace("_","-");
    if (language !== i18n.language) {
        i18n.changeLanguage(language);
    }
}
export {
    setLangConfig,
    setMainLanguage,
    hasLangConfig

};

