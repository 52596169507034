import SupportMenuButtonsComponent from "app/support/components/menu/SupportMenuButtonsComponent";
import React, {useMemo} from "react";
import Poll from "system/Rest/Poll";

const SupportButtons = props  => {

    return <Poll
        path="/ticketing/search"
        localErrorOnly={true}
        interval={30 * 1000}
        queryParams={useMemo(() => ({
            isRead: false,
            itemsPerPage: 1,
        }),[])}>
        {(data) => <SupportMenuButtonsComponent {...props} data={data}/>}
    </Poll>
}

export default SupportButtons;
