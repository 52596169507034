import Tracker from "@openreplay/tracker";
import appConfig from "configs/config";

const OpenReplayTracker = new Tracker({
    projectKey: appConfig.openReplay.projectKey,
    ingestPoint: appConfig.openReplay.url,
    defaultInputMode: 0,
    obscureTextEmails: false,
    obscureTextNumbers: false,
    obscureInputEmails: false,
    obscureInputNumbers: false,
    __DISABLE_SECURE_MODE: appConfig.openReplay.allowInsecure,
});

export default OpenReplayTracker;
