import {Trans, useTranslation} from "react-i18next";

const AccountInfoComponent = props => {

    const {t} = useTranslation('owner');
    const {owner} = props;

    return <div className={"owners-app-info"}>
        <div>
            <Trans>{t('account_info', owner)}</Trans>
        </div>
    </div>
}

export default AccountInfoComponent;
