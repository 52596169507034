import dayjs from "dayjs"
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc)
dayjs.extend(timezone);
dayjs.extend(duration);

export function generateInitialDate(date, children) {
    if (children) {
        return dayjs(children)
    } else if (date) {
        return dayjs(date)
    }
    return dayjs(0);
}

export function convertTimezone(date, children, timeZone) {

    if (children) {
        return dayjs(children).tz(timeZone, true);
    } else if (date) {
        return dayjs(date).tz(timeZone, true);
    }
    return dayjs();
}


export function addToDate(date, add) {
    return Object.keys(add).reduce((updatedDate, key) => updatedDate.add(dayjs.duration(add[key], key)), date)
}

export function fixUTCBug(unixTimestamp) {
    return unixTimestamp - (unixTimestamp % (60*60*24*1000));
}

export function subtractFromDate(date, subtract) {
    return Object.keys(subtract).reduce((updatedDate, key) => updatedDate.subtract(subtract[key], key), date)
}

export function convertSecondsToTime(seconds) {

    if (!isNaN(seconds)) {
        return new Date(seconds * 1000).toISOString().substr(11, 8);
    } else {
        return "";
    }
}
