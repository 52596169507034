import BoxTitle from "layout/modules/Box/components/BoxTitle";
import React from "react";
import Box from "layout/modules/Box/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";

const OwnerRequestComponent = (props) => {


    const {t, animalOwner} = props;

    return <Box>
        <BoxTitle>
            {t('owner_request')}
        </BoxTitle>

        <Row>
            <Col md={12}>
                <LabelWithValue label={t('new_owner_nif')}>
                    {animalOwner?.vatNumber}
                </LabelWithValue>
            </Col>

            <Col md={12}>
                <LabelWithValue label={t('new_owner_name')}>
                    {animalOwner?.name}
                </LabelWithValue>

            </Col>

        </Row>
    </Box>

}

export default OwnerRequestComponent;
