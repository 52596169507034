import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import React from "react";
import {EntityType} from "types/Entity";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/entities/locales/locales";

const EntityTypeComponent = props => {

    const {value, setValue, parameter, error, readOnly} = props;

    const {t} = useTranslations('entity', locale);

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <EnumDropdown
                t={t}
                options={EntityType}
                isDisabled={readOnly}
                isClearable={!parameter?.mandatory}
                isMulti={parameter?.multipleValues}
                value={getOrNull(value)}
                placeholder={t('type_ph')}
                onChange={setValue}
            />
            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default EntityTypeComponent;
