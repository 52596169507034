import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React from "react";
import DateTime from "system/DateTime/DateTime";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import EntityLinkComponent from "app/entities/components/other/EntityLinkComponent";
import {Link} from "react-router-dom";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import TransferType from "app/control/modules/transfers/utils/TransferType";

const RequestAuthorAndReviewerComponent = props => {

    const {t, request} = props;

    return <>

        <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER","MANAGE_ANIMAL_PRE_REGISTER"]} any={true}>
        {request.closed &&
        <Box>
            <BoxTitle>
                {t('review_by')}
            </BoxTitle>

            <Row>
                <Col sm={12} lg={12}>
                    <FormGroup>
                        <Form.Label>{t('review_by')}</Form.Label>
                        <FormControl
                            type="text"
                            disabled={true}
                            value={getOrEmpty(request?.lastUpdateUser?.name)}
                        />
                    </FormGroup>
                </Col>

                {request?.lastUpdateUser?.entity &&
                <Col sm={12} lg={12}>
                    <FormGroup>
                        <Form.Label>{t('created_by_entity')}</Form.Label>
                        <FormControl
                            type="text"
                            disabled={true}
                            value={getOrEmpty(request?.lastUpdateUser?.entity)}
                        />
                    </FormGroup>
                </Col>
                }

                <Col sm={12} lg={12}>
                    <LabelWithValue label={t('creation_date')}>
                        <DateTime format="DD-MM-YYYY HH:mm:ss">
                            {request?.lastUpdateTimestamp}
                        </DateTime>
                    </LabelWithValue>
                </Col>

                {request.rejectReason &&
                <Col sm={12} lg={12}>
                    <FormGroup>
                        <Form.Label>{t('reject_reason')}</Form.Label>
                        <FormControl
                            type="text"
                            disabled={true}
                            value={request?.rejectReason}
                        />
                    </FormGroup>
                </Col>
                }

            </Row>

        </Box>
        }
        </UserHasPermission>

        <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER","MANAGE_ANIMAL_PRE_REGISTER"]} any={true}>
            <Box>
                <BoxTitle>
                    {t('created_by')}
                </BoxTitle>

                <Row>
                    <Col sm={12} lg={12}>
                        <LabelWithValue label={t('created_by')}>
                            {request?.creationUser?.veterinaryId &&
                            <Link to={"/veterinary/" + request?.creationUser?.veterinaryId}>
                                {request?.creationUser?.name}
                            </Link>
                            }

                            {(request?.creationUser?.id && !request?.creationUser?.veterinaryId && !(request?.origin === TransferType.OWNERS)) &&
                            <Link to={"/user/" + request?.creationUser?.id}>
                                {request?.creationUser?.name}
                            </Link>
                            }

                            {request?.origin === TransferType.OWNERS &&
                            <>{request?.creationUser?.name}</>
                            }

                            {request?.publicRequest?.requesterName &&
                                <>{request?.publicRequest?.requesterName} - {request?.publicRequest?.requesterEmail}</>
                            }

                        </LabelWithValue>
                    </Col>

                    {request?.creationEntity?.name &&
                    <Col sm={12} lg={12}>
                        <LabelWithValue label={t('created_by_entity')}>
                            <EntityLinkComponent
                                id={request?.creationEntity?.id}
                                name={request?.creationEntity?.name}
                            />
                        </LabelWithValue>
                    </Col>
                    }

                    <Col sm={12} lg={12}>
                        <LabelWithValue label={t('creation_date')}>
                            <DateTime format="DD-MM-YYYY HH:mm:ss">
                                {request?.creationTimestamp
                                    ? request?.creationTimestamp
                                    : request?.registrationDate}
                            </DateTime>
                        </LabelWithValue>

                    </Col>

                </Row>
            </Box>
        </UserHasPermission>

    </>

}

export default RequestAuthorAndReviewerComponent
