import {useCallback, useState} from "react";
import getNested from "system/Objects/getNested";

const useGenericFormValidator = (props) => {

    const [errors, setErrors] = useState(null);

    const validate = useCallback(
        (toValidate) => {

            const eList = Object.entries(props).reduce((data, [field, validator]) => {

                const result = validator(getNested(toValidate, field), toValidate, field);

                if (result) {
                    return {
                        ...data,
                        [field]: result
                    }
                }
                else
                    return data;
            },{});

            if (Object.entries(eList).length === 0) {
                setErrors(null);
                return true;
            }
            else {
                setErrors(eList);
                return false;
            }

        },
        // eslint-disable-next-line
        [setErrors]
    );

    return {
        errors: errors,
        validate: validate,
    }


}


const VALIDATE_NOT_NULL = (value, object, key) => {
    if (!value) {
        return "INVALID_" + key.split(/(?=[A-Z])/).join('_').toUpperCase();
    }
};

export default useGenericFormValidator;
export {
    VALIDATE_NOT_NULL
}
