import UserHasPermission from "system/API/Authentication/UserHasPermission";
import Audit from "app/audit/Audit";
import {AnimalActions} from "app/audit/utils/AuditActions";
import {AnimalObjectTypes} from "app/audit/utils/AuditObjectTypes";
import Observations from "app/observations/Observations";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodeBranch, faExternalLinkAlt, faLock, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import {Link} from "react-router-dom";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import VersionsContainer from "app/animals/containers/form/VersionsContainer";

const AnimalBoxOptionsButtons = props => {

    const {
        create, compareMode, animal, remoteAnimal, onLock, setReadOnly,
        readOnly, setAnimal, viewMode, showLink, showObservations
    } = props;

    const {t} = useTranslation('animal');

    const [lockPopupVisible, setLockPopupVisible] = useState(false);
    const [versionsVisible, setVersionsVisible] = useState(false);

    const handleOnLockPopupCancel = useCallback(
        () => setLockPopupVisible(false),
        [setLockPopupVisible]
    );

    const handleOnLockConfirm = useCallback(
        () => {
            onLock(!animal?.isLocked);
            setLockPopupVisible(false);
        },
        [setLockPopupVisible, animal?.isLocked, onLock]
    );

    const handleVersionRevert = useCallback((animal) => {
        setReadOnly(false);
        setVersionsVisible(false);
        setAnimal({
            ...animal
        });
    }, [setReadOnly, setVersionsVisible, setAnimal])

    return <>

        {viewMode &&
        <>
            <PopupConfirm
                title={t('LOCK_ANIMAL_CONFIRM')}
                message={animal?.isLocked ? t('UNLOCK_ANIMAL_CONFIRM_MSG') : t('LOCK_ANIMAL_CONFIRM_MSG')}
                classConfirm={"danger"}
                visible={lockPopupVisible}
                onCancel={handleOnLockPopupCancel}
                onConfirm={handleOnLockConfirm}
            />

            {versionsVisible &&
            <VersionsContainer
                remoteAnimal={remoteAnimal}
                id={animal?.id}
                title={t('compare_animals')}
                visible={versionsVisible}
                onClose={() => setVersionsVisible(false)}
                onRevert={handleVersionRevert}
            />
            }
        </>
        }


        {(!create && !compareMode) &&
        <>
            <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                <Audit
                    objectId={animal.transponder}
                    filterActions={AnimalActions}
                    filterObjectTypes={AnimalObjectTypes}
                    affectedObjectType={[AnimalObjectTypes.Animal, AnimalObjectTypes.AnimalPreRegister]}
                />
            </UserHasPermission>

            <UserHasPermission permission={["READ_OBSERVATION"]}>
                <Observations allowFix={true}/>
            </UserHasPermission>

            <UserHasPermission permission="LOCK_ANIMAL">
                <ToolTip content={animal?.isLocked ? t('unlock_animal') : t('lock_animal')}>
                    <div
                        className={classnames("btn-rounded-danger small", {"active": animal?.isLocked})}
                        onClick={() => setLockPopupVisible(true)}>
                        <FontAwesomeIcon icon={faLock}/>
                    </div>
                </ToolTip>
            </UserHasPermission>

            <UserHasPermission permission="READ_VERSIONS">
                <ToolTip content={t('compare_animals')}>
                    <div className={classnames("btn-rounded-primary small", {"active": versionsVisible})}
                         onClick={() => setVersionsVisible(true)}>
                        <FontAwesomeIcon icon={faCodeBranch}/>
                    </div>
                </ToolTip>
            </UserHasPermission>

            <AnimalOperationAllowed animal={animal} operation={AnimalOperations.EDIT}>
                <ToolTip content={t('edit_animal')}>
                    <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                         onClick={() => setReadOnly(readOnly => !readOnly)}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </div>
                </ToolTip>
            </AnimalOperationAllowed>
        </>
        }

        {showLink &&
        <UserHasPermission permission="READ_ANIMAL">
            <ToolTip content={t('view_animal')}>
                <Link to={"/animal/view/" + animal.transponder}>
                    <div className={classnames("btn-rounded-primary small")}>
                        <FontAwesomeIcon icon={faExternalLinkAlt}/>
                    </div>
                </Link>
            </ToolTip>
        </UserHasPermission>
        }


        {showObservations &&
        <UserHasPermission permission={["READ_OBSERVATION"]}>
            <Observations location={"/animal/view/" + animal.transponder}/>
        </UserHasPermission>
        }
    </>

}

export default AnimalBoxOptionsButtons;
