import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React, {useEffect, useState} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import TransferValidationStatus from "app/control/modules/transfers/utils/TransferValidationStatus";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import UserDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/UserDropdown";
import LabelFilterComponent from "app/adminLabels/components/filter/LabelFilterComponent";
import {isBoolean} from "system/Utils/isBoolean";
import TransferType from "app/control/modules/transfers/utils/TransferType";

const ListFilterComponent = (props) => {

    const {searchFilter, t, isClosed} = props;

    const [filterState, setFilterState] = useState();

    useEffect(() => {
        setFilterState({...searchFilter});
    }, [searchFilter]);

    if (!filterState) {
        return <></>;
    }

    return <>
        <Row>
            {!isBoolean(isClosed) &&
                <Col sm={12} lg={true}>
                    <FormGroup>
                        <Form.Label>{t('closed_process')}</Form.Label>
                        <BooleanDropdown
                            id={"isClosed"}
                            isClearable={true}
                            value={parseBoolean(filterState.isClosed)}
                            onChange={(val) => setFilterState({
                                ...filterState,
                                isClosed: val,
                            })
                            }
                        />
                    </FormGroup>
                </Col>
            }

            {(isClosed !== false) &&
                <Col sm={12} lg={true}>
                    <FormGroup>
                        <Form.Label>{t('accepted_process')}</Form.Label>
                        <BooleanDropdown
                            id={"isAccepted"}
                            isClearable={true}
                            value={parseBoolean(filterState.isAccepted)}
                            onChange={(val) => setFilterState({
                                ...filterState,
                                isAccepted: val,
                            })}
                        />
                    </FormGroup>
                </Col>
            }

            {(isClosed !== true) &&
                <Col sm={12} lg={true}>
                    <FormGroup>
                        <Form.Label>{t('validation_status')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            placeholder={t('select_ph')}
                            id={"validationStatus"}
                            isMulti={false}
                            options={TransferValidationStatus}
                            value={getOrNull(filterState.validationStatus)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    validationStatus: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>
            }

            <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER"]}>
                <Col sm={12} lg={true}>
                    <FormGroup>
                        <Form.Label>{t('origin')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            placeholder={t('select_ph')}
                            id={"origin"}
                            isMulti={true}
                            options={TransferType}
                            value={getOrNull(filterState.origin)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    origin: val,
                                })
                            }}
                        />

                    </FormGroup>
                </Col>
            </UserHasPermission>
        </Row>
        <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER"]}>
            <Row>
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('created_by')}</Form.Label>
                        <UserDropdown
                            id={"userIds"}
                            value={getOrNull(filterState?.userIds)}
                            isMulti={true}
                            onChange={(value) => {
                                setFilterState({
                                    ...filterState,
                                    userIds: value,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('created_by_entity')}</Form.Label>
                        <EntityDropdown
                            id={"entityIds"}
                            value={getOrNull(filterState?.entityIds)}
                            isMulti={true}
                            onChange={(value) => {
                                setFilterState({
                                    ...filterState,
                                    entityIds: value,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <LabelFilterComponent setFilterState={setFilterState} filterState={filterState} t={t}/>

        </UserHasPermission>

    </>

}

export default ListFilterComponent
