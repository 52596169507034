import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Box from "layout/modules/Box/Box";
import {useTranslation} from "react-i18next";
import React, {useCallback, useState} from "react";
import classnames from "classnames";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Form} from "react-bootstrap";
import Audit from "app/audit/Audit";
import {ExecuteOperationActions} from "app/audit/utils/AuditActions";
import {AuditObjectTypes, OperationObjectTypes} from "app/audit/utils/AuditObjectTypes";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import ParamsComponent from "app/reporting/components/templates/form/ParamsComponent";
import BaseTemplateFormComponent from "app/operations/components/template/BaseTemplateFormComponent";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import CodeEditor from "layout/modules/Forms/CodeEditor/CodeEditor";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import "app/reporting/assets/css/form.scss";
import Loading from "layout/modules/Loading/Loading";

const TemplateFormComponent = props => {

    const {t} = useTranslation("operations");
    const {t: tr} = useTranslation("reports");

    const {create, loading, template: remoteTemplate = {}, errors = {}, onSubmit, compare} = props;

    const [readOnly, setReadOnly] = useState(!create);
    const [template, setTemplate] = useState(remoteTemplate);

    const handlePreviewQuery = useCallback(
        (val) => {
            setTemplate(template => ({
                ...template,
                previewQuery: val,
            }))
        },
        [setTemplate]
    );


    return <div className={classnames("report-template form-component", {
        "view-mode": readOnly,
        "compare-mode": compare,
    })}>
        <Form>
            <Loading visible={loading}/>
            {!compare && <GenericFormError t={t} errors={errors}/>}
            <FormHandleNotSaved
                remote={remoteTemplate}
                local={template}
                loading={loading}
                readOnly={readOnly}
                setLocal={setTemplate}
                setReadOnly={setReadOnly}
                onSubmit={onSubmit}/>

            <Box>
                <BoxTitle>{t('template_base_config')}</BoxTitle>
                <BoxOptions>

                    {(!create && !compare) &&
                    <>
                        <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                            <Audit
                                objectId={template?.id}
                                filterActions={{
                                    ...ExecuteOperationActions,
                                }}
                                filterObjectTypes={OperationObjectTypes}
                                affectedObjectType={[
                                    AuditObjectTypes.Operation,
                                    AuditObjectTypes.OperationSchedule,
                                ]}
                            />
                        </UserHasPermission>

                        <ToolTip content={t('edit_template')}>
                            <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                                 onClick={() => setReadOnly(readOnly => !readOnly)}>
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </div>
                        </ToolTip>

                    </>
                    }

                </BoxOptions>

                <BaseTemplateFormComponent
                    t={t}
                    template={template}
                    setTemplate={setTemplate}
                    readOnly={readOnly}
                    errors={errors}
                />

            </Box>

            {(!readOnly || template?.previewQuery) &&
            <Box>
                <BoxTitle>{t('preview_query')}</BoxTitle>
                <Row>
                    <Col sm={12} lg={12} xl={12}>
                        <FormGroup error={errors['previewQuery']}>
                            <Form.Label>{t('preview_query')}</Form.Label>
                            <CodeEditor
                                name={"previewQuery"}
                                onChange={handlePreviewQuery}
                                value={getOrEmpty(template?.previewQuery)}
                                readOnly={readOnly}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['previewQuery'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                </Row>
            </Box>
            }

            <Box>
                <BoxTitle>{t('body')}</BoxTitle>
                <Row>
                    <Col sm={12} lg={12} xl={12}>
                        <FormGroup error={errors['body']}>
                            <Form.Label>{t('body')}</Form.Label>
                            <CodeEditor
                                name={"body"}
                                onChange={useCallback(
                                    (val) => {
                                        setTemplate(template => ({
                                            ...template,
                                            body: val,
                                        }))
                                    },
                                    [setTemplate]
                                )}
                                value={getOrEmpty(template?.body)}
                                readOnly={readOnly}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['body'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                </Row>
            </Box>


            {(!readOnly || template?.parameters?.length > 0) &&
            <ParamsComponent
                t={tr}
                isOperation={true}
                template={template}
                setTemplate={setTemplate}
                readOnly={readOnly}
                errors={errors}/>
            }


            {!readOnly &&
            <Row>
                <Col className={"text-end"}>
                    <Button className={"submit"}  variant="primary" onClick={() => onSubmit(template)}>
                        {create ? t("create_form") : t("save_form")}
                    </Button>
                </Col>
            </Row>
            }

        </Form>
    </div>

}

export default TemplateFormComponent;
