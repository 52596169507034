import React, {useMemo} from "react"
import GenericTableContainer from "./GenericTableContainer"
import useTranslations from "system/Translations/UseTranslations";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";

const GenericListContainer = (props) => {

    const {location, children, module, locale, queryParameters, searchPath, deletePath, hideTitle = false} = props;
    const {t, ready} = useTranslations(module, locale);

    const pathBase = useMemo(() => {
        let modulePartUrl = module;
        if (modulePartUrl.endsWith("y")) {
            modulePartUrl = modulePartUrl.substring(0, modulePartUrl.length - 1);
            modulePartUrl += 'ie';
        }
        return modulePartUrl;

    }, [module]);

    if (!ready) {
        return <></>;
    }

    return <Title {...props} title={t('title')} breadcrumbs={[{href: "/" + module, label: t('list')}]}
                  disable={hideTitle}>
        <GenericTableContainer
            {...props}
            t={t}
            location={location}
            path={searchPath ? searchPath : "/" + pathBase + "s/search"}
            pathDelete={deletePath ? deletePath : "/" + pathBase + "s/"}
            queryParameters={queryParameters}
        >
            {children}
        </GenericTableContainer>
    </Title>
}

export default GenericListContainer;
