const acceptTypeImages = {
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpeg', '.jpg']
}

const acceptTypesPdf = {
    'application/pdf': ['.pdf']
}

const acceptTypeDocx = {
    'application/*': ['.docx']
}

export {
    acceptTypeImages,
    acceptTypesPdf,
    acceptTypeDocx
}
