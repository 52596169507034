import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import ModalButtons from "../../../layout/modules/Modal/Buttons/ModalButtons";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import FormComponent from "./FormComponent";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";

const PopupFormComponent = (props) => {

    const {onSubmit, onClose, loading, errors, create, aggression: remoteAggression} = props;

    const {t} = useTranslation("aggression");

    const [aggression, setAggression] = useState(create ? { documents: [] } : remoteAggression);
    const [readOnly, setReadOnly] = useState(!create);

    return <Modal
        show={true}
        onHide={loading ? () => void 0 : onClose}
        size={"xl"}
        className={"dark"}
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{create ? t('add_aggression') : t('view_aggression')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <FormHandleNotSaved
                remote={remoteAggression}
                local={aggression}
                loading={loading}
                readOnly={readOnly}
                setLocal={setAggression}
                setReadOnly={setReadOnly}
                onSubmit={onSubmit}/>

            <FormComponent
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                loading={loading}
                create={create}
                aggression={aggression}
                setAggression={setAggression}
                errors={errors}
            />

        </Modal.Body>

        <ModalButtons>

            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>

            {!readOnly &&
            <Button variant="primary" onClick={() => onSubmit(aggression)}>
                {create ? t("add") : t("edit")}
            </Button>
            }

        </ModalButtons>

    </Modal>
}

export default PopupFormComponent;
