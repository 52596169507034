import React from "react";
import {useTranslation} from "react-i18next";
import ValueLabelComponent from "./ValueLabelComponent";
import classnames from "classnames";
import StatusOperationsComponent from "./StatusOperationsComponent";
import SeparatorComponent from "./SeparatorComponent";
import OperationsComponent from "./OperationsComponent";
import {AnimalStatus} from "types/Animal";
import AnimalCertificateList from "app/certificates/AnimalCertificateList";
import AnimalOperationAllowed, {
    AnimalOperations,
    useAnimalOperationAllowed
} from "app/animals/utils/AnimalOperationAllowed";
import StatusInfoComponent from "app/animals/components/view/panel/StatusInfoComponent";

import "app/animals/assets/css/panel.scss";
import "app/animals/assets/css/generic.scss";
import BreedFilesList from "app/animals/components/view/panel/BreedFilesList";

const AnimalPanel = (props) => {

    const {animal} = props;
    const {t} = useTranslation("animal");

    const isOperationAllowed = useAnimalOperationAllowed(animal);

    return <div className={"animal-panel"}>
        <div className={"name"}>
            {animal?.name}
        </div>


        {animal?.status !== AnimalStatus.NORMAL &&
        <>
            <ValueLabelComponent label={t('status')}>
                <div className={classnames("panel-status", {
                    "normal": animal?.status === AnimalStatus.NORMAL,
                    "lost": animal?.status === AnimalStatus.LOST,
                    "found": animal?.status === AnimalStatus.FOUND,
                    "dead": animal?.status === AnimalStatus.DEAD
                })}>
                    {t(animal?.status)}
                </div>
            </ValueLabelComponent>
            <SeparatorComponent/>
        </>
        }

        <StatusInfoComponent animal={animal}/>

        {(animal?.status !== AnimalStatus.DEAD || isOperationAllowed(AnimalOperations.SET_NOT_DEAD)) &&
        <>
            <StatusOperationsComponent {...props} animal={animal}/>
            <SeparatorComponent/>
        </>
        }


        <AnimalOperationAllowed animal={animal} operation={AnimalOperations.PRINT_CERTIFICATE}>
            <AnimalCertificateList {...props} animal={animal}/>
        </AnimalOperationAllowed>

        <BreedFilesList animal={animal}/>

        {(animal?.status !== AnimalStatus.DEAD || isOperationAllowed(AnimalOperations.SET_NOT_DEAD)) &&
        <OperationsComponent {...props} animal={animal}/>
        }

    </div>

}

export default AnimalPanel;
