import React, {useEffect, useState} from "react";
import classnames from "classnames";
import TableIconButton from "../../../layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {faPencilAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import FormContainer from "../containers/FormContainer";
import Loading from "../../../layout/modules/Loading/Loading";
import {useTranslation} from "react-i18next";
import PopupConfirm from "../../../layout/modules/Popups/PopupConfirm";

const ArticleComponent = (props) => {

    const {t} = useTranslation('help');

    const {article: originalArticle, loading, onGoBack, onDelete} = props;

    const [article, setArticle] = useState(originalArticle);
    const [editMode, setEditMode] = useState(false);
    const [isConfirmVisible, setConfirmVisible] = useState(false);

    useEffect(() => {
        setArticle(originalArticle)
        // eslint-disable-next-line
    }, [originalArticle.title]);

    return <div className={classnames({"view": !editMode, "edit": editMode})}>

        <Loading visible={loading}/>

        <PopupConfirm
            title={t('CONFIRM_DELETE_PAGE')}
            message={t('CONFIRM_DELETE_PAGE_MSG')}
            classConfirm={"danger"}
            visible={isConfirmVisible}
            onCancel={() => {
                setConfirmVisible(false)
            }}
            onConfirm={() => onDelete()}
            backdrop={true}
        />

        <div className={"buttons"}>

            {editMode &&
            <TableIconButton
                className={classnames("btn-rounded-danger", {"active": isConfirmVisible})}
                icon={faTrash}
                permission={["WRITE_HELP"]}
                onClick={() => setConfirmVisible(true)}
            />
            }

            <TableIconButton
                className={classnames("btn-rounded-primary", {"active": editMode})}
                icon={faPencilAlt}
                onClick={() => setEditMode(!editMode)}
                permission={["WRITE_HELP"]}
            />
        </div>

        {!editMode &&
        <div className={"go-back"} onClick={onGoBack}>
            {t('go_back')}
        </div>
        }

        {editMode
            ? <FormContainer
                {...props}
                article={article}
                onClose={(content) => {
                    setArticle(content);
                    setEditMode(false);
                }}/>
            : <div className={"article"}>
                <div className={"title"}>
                    {article?.title}
                </div>
                <div className={"category"}>
                    {article?.category}
                </div>
                <div className={"contents"} dangerouslySetInnerHTML={{__html: article?.contents}}>
                </div>
            </div>
        }

    </div>

}

export default ArticleComponent;
