import React, {useEffect, useState} from "react";
import {useGet} from "system/Rest/Rest";
import GeofenceFormComponent from "./GeofenceFormComponent";

const FormGeofence = React.forwardRef((props, ref) => {

    const {visible = true, errors} = props;

    const [loaded, setLoaded] = useState(false);

    const {data: districts, loading} = useGet({
        path: "/address/search-district",
        lazy: !loaded,
        resolve: (data) => data.reduce((data, item) => ({
            ...data,
            [item.name]: item.id
        }), {})
    });

    useEffect(() => {
            if (visible)
                setLoaded(true)
        },
        [visible]
    );

    return <GeofenceFormComponent
        {...props}
        districts={districts ? districts : {}}
        loading={loading}
        errors={errors ? errors : {}}
        ref={ref}
    />


});

export default FormGeofence;
