import { config } from '@fortawesome/fontawesome-svg-core';
import getRandomString from "../system/Randoom/Random";

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false

const appConfig = (function () {

    const defaultConfig = {
        defaultCountry: "PT",
        defaultLng: "pt_PT",
        name: "SIAC",
        nameComplete: "Sistema de Informação de Animais de Companhia",
        omv: {
            enabled: true,
            url: "https://www.omv.pt/auth/oauth/authorize?response_type=code&client_id=" + process.env.REACT_APP_OMV_CLIENT_ID + "&state=" + getRandomString(200) + "&scope=identity%3Aread&redirect_uri=" + window.location.origin + "/auth/omv",
            logoutUrl: "https://www.omv.pt/auth/logout?redirect_uri=" + window.location.origin + "/logout",
        },
        supportEmail: "geral@siac.vet",
        api: {
            base: window.location.origin + "/api/",
        },
        openReplay: {
            url: "https://logs.siac.vet/ingest",
            allowInsecure: false,
            projectKey: process.env.REACT_APP_OPEN_REPLAY_PK

        }
    };

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return {
            ...defaultConfig,
            api: {
                ...defaultConfig.api,
                base: "https://staging.siac.vet/api/",
                //base: "https://portal.siac.vet/api/",
                //base: "https://siac.bleen.tech/api/",
                //base: "http://localhost:8080/api/",
            },
            openReplay: {
                ...defaultConfig.openReplay,
                disabled: true,
                allowInsecure: true,
                projectKey: "FyIaKERuIm9DjXMSAogP",
            }
        }
    }
    return defaultConfig;
})
();


export default appConfig;
