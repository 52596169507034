import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";

const EntityResponsibleComponent = (props) => {

    const {errors = {}, changes = {}, owner, setOwner, readOnly} = props;
    const {t} = useTranslation('owner');


    return <Box visible={owner.type}>
        <BoxTitle>{t('entity_responsible')}</BoxTitle>

        <Row className={"mandatory"}>
            <Col sm={12} lg={true}>
                <FormGroup error={errors['responsible']} changes={changes['responsible']}>
                    <Form.Label>{t('responsible')}</Form.Label>
                    <FormControl
                        type="text"
                        id="responsible"
                        placeholder={t('responsible_ph')}
                        disabled={readOnly}
                        value={getOrEmpty(owner?.responsible)}
                        onChange={useCallback(e => setOwner(owner => ({
                            ...owner,
                            responsible: e.target.value,
                        })),[setOwner])}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['responsible'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Row>
    </Box>
}

export default EntityResponsibleComponent;
