import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import React, {useCallback, useState} from "react";
import FormContainer from "app/licensing/containers/FormContainer";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import classnames from "classnames";
import Button from "react-bootstrap/Button";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/licensing/locales/locales";

const AddLicenseButtonComponent = props => {

    const {animal, isTableButton} = props;
    const [showAdd, setShowAdd] = useState(false);

    const handlePopupClose = useCallback(() => setShowAdd(false), [setShowAdd]);
    const handleClick = useCallback(() => setShowAdd(true), [setShowAdd]);

    const {t} = useTranslations("animal-license", locale);

    return <AnimalOperationAllowed animal={animal} operation={AnimalOperations.REGISTER_LICENSING}>
        {showAdd && <FormContainer {...props} transponder={animal?.transponder} onClose={handlePopupClose}/>}


        {isTableButton
            ? <TableIconAdd
                className={classnames({"active": showAdd})}
                onClick={handleClick}
            />
            : <Button variant="primary" onClick={handleClick}>
                {t('register')}
            </Button>
        }
    </AnimalOperationAllowed>
}

export default AddLicenseButtonComponent;
