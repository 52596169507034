import UploadComponent from "../components/UploadComponent";
import {useMutate} from "system/Rest/Rest";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";

const UploadContainer = (props) => {

    const {type, id, reload} = props;

    const {t} = useTranslation("file");
    const showToast = useShowToast();

    const {mutate: submit, loading} = useMutate({
        verb: "POST",
        path: "/documents/" + type + (id ? ("/" + id) : ""),
    });

    return <UploadComponent
        {...props}
        loading={loading}
        onUpload={useCallback(
            (file) => {
                submit({
                    documents: [file]
                }).then(() => {
                    showToast({
                        title: t('SUCCESS_UPLOAD_FILE'),
                        message: t('SUCCESS_UPLOAD_FILE_MSG', {name: file.filename}),
                        icon: faCheck,
                    });
                    reload();
                }).catch(() => {
                    showToast({
                        title: t('FAIL_UPLOAD_FILE'),
                        message: t('FAIL_UPLOAD_FILE_MSG', {name: file.filename}),
                        className: 'error',
                        icon: faTimes,
                    });
                });
            },

            [t, showToast, submit, reload]
        )}
    />
}

export default UploadContainer;
