import {UserType} from "types/Users";

const generateSession = (response, type, extraProps) => {

    if (type === UserType.VETERINARY) {
        return{
            ...extraProps,
            isAuthenticated: false,
            type: UserType.VETERINARY,
            isPreAuthenticated: true,
            veterinaryId: response.veterinaryId,
            id: response.id,
            timeZone: response.timeZone,
            language: response.language,
            user: response.email,
            name: response.name,
            legalWarningReadTimestamp: response.legalWarningReadTimestamp,
            entityProfile: response?.entity?.profile,
            session: {
                validity: response.sessionValidity,
                token: response.sessionToken
            },
            veterinarySession: {
                validity: response.sessionValidity,
                token: response.sessionToken
            }
        }
    } else {

        return{
            ...extraProps,
            isAuthenticated: true,
            type: UserType.NORMAL,
            id: response.id,
            entity: response.entity,
            legalWarningReadTimestamp: response.legalWarningReadTimestamp,
            entitiesAccessList: response.entitiesAccessList,
            role: response.role,
            entityProfile: response?.entity?.profile,
            timeZone: response.timeZone,
            language: response.language,
            user: response.email,
            name: response.name,
            session: {
                validity: response.sessionValidity,
                token: response.sessionToken
            }
        };
    }
}

const generateTwoFactorSession = (props, type) => {
    return {
        ...props,
        type: type,
        twoFactorAuthenticationRequired: true,
        twoFactorValidity: Date.now() + 5 * 60 * 1000,
    }
}

export {generateTwoFactorSession};
export default generateSession;
