import {useMutate} from "system/Rest/Rest";
import React from "react";
import PasswordComponent from "app/personalArea/components/PasswordComponent";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import Loading from "layout/modules/Loading/Loading";

const PasswordContainer = (props) => {

    const {user, reload} = props;
    const {t} = useTranslation('personalArea');

    const showToast = useShowToast();

    const {mutate: changePassword, loading, error} = useMutate({
        verb: "PUT",
        path: "/auth/password",
    });

    const errors = useErrorParser(error);

    if (!user) {
        return <Loading visible={true}/>;
    }

    return <PasswordComponent
        user={user}
        errors={errors}
        loading={loading}

        onPasswordSubmit={(setPasswordObj) => {
            changePassword(setPasswordObj)
                .then(() => {
                    showToast({
                        title: t('SUCCESS_PASSWORD'),
                        message: t('SUCCESS_PASSWORD_MSG'),
                        icon: faCheck,
                    });

                    reload();

                })
                .catch(e => {
                }); //only to prevent warning
        }}

    />

}

export default PasswordContainer;
