import React, {createContext, useEffect} from 'react';
import createPersistedState from "system/System/PersistedState/PersistenState";

const useThemePersistedState = createPersistedState('theme');
const isDarkModeEnabled = () => window.matchMedia('(prefers-color-scheme: dark)').matches || false;

const themes = {
    dark: "dark-mode",
    light: "",
};

const ThemeContext = createContext({
    theme: themes.light,
    changeTheme: () => {},
});

function useThemeState() {
    const context = React.useContext(ThemeContext)
    if (!context) {
        throw new Error(`useApiState must be used within a ApiStateContext`)
    }
    return context
}

const ThemeContextWrapper = (props) => {

    const [theme, setTheme] = useThemePersistedState(isDarkModeEnabled() ? themes.dark : themes.light);

    useEffect(() => {
        configTheme(theme)
    }, [theme]);


    return <ThemeContext.Provider value={{theme: theme, changeTheme: setTheme}}>
        {props.children}
    </ThemeContext.Provider>
}

const configTheme = (theme) => {

    switch (theme) {
        case themes.light:
            document.body.classList.remove('dark-mode');
            document.querySelector("meta[name='theme-color']").setAttribute("content",  "#f1f1f1");
            document.getElementById('manifest').href = '/manifest.json'
            break;
        case themes.dark:
        default:
            document.body.classList.add('dark-mode');
            document.querySelector("meta[name='theme-color']").setAttribute("content",  "#202124");
            document.getElementById('manifest').href = '/manifest-dark.json'
            break;
    }
}

export {
    ThemeContextWrapper,
    useThemeState,
    themes
}
