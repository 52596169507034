import {useGet} from "system/Rest/Rest";
import processResolver from "app/support/utils/ProcessResolver";
import ControlSupportComponent from "app/support/components/ControlSupportComponent";

const ControlSupportContainer = props => {

    const {processType, id} = props;

    const {data: result} = useGet({
        path: "/ticketing/process/" + processType + "/" + id,
        lazy: !processType || !id,
        resolve: processResolver
    });


    if (!result || !result?.id)
        return <></>

    return <ControlSupportComponent
        {...props}
        summary={result}
    />

}

export default ControlSupportContainer;
