import {useTranslation} from "react-i18next";
import React, {useMemo, useState} from "react";
import {faDownload, faSearch} from "@fortawesome/free-solid-svg-icons";
import ModalComponent from "app/audit/components/Operation/ModalComponent";
import useLogDocument from "app/audit/utils/useLogDocument";
import DescriptionComponent from "./DescriptionComponent";
import {useRemoteEntity} from "modules/GenericRemoteClassFetcher/GenericSubClassFetcher";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import ListFilterComponent from "app/audit/components/ListFilterComponent";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import DateTime from "system/DateTime/DateTime";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";

import "app/audit/assets/css/list.scss";
import MenuDropdown from "layout/modules/MenuDropdown/MenuDropdown";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import AuditExportFormat from "app/audit/utils/AuditExportFormat";
import useAuditExporter from "app/audit/utils/AuditExporter";
import Loading from "layout/modules/Loading/Loading";
import AuditUserType from "app/audit/utils/AuditUserType";

const ListComponent = (props) => {

    const {t} = useTranslation('audit');

    const {
        data,
        searchFilter,
        onPageChange,
        onPageSort,
        onFilterSubmit,
        loading,
        filterActions,
        filterObjectTypes,
        userId,
        affectedObjectType,
        objectType,
        error,
        hideMoreInfo,
        hideFilter,
        ownerVatNumber
    } = props;


    const dataWithoutOwners = useMemo(
        () => ({
            content: data?.content?.filter(e => e.userType !== AuditUserType.OWNERS)
        }),
        [data]
    );

    const [extendedData, loadingExtraData] = useLogDocument(data);
    const [users, loadingUsers] = useRemoteEntity(dataWithoutOwners, '/users/search', 'userId', "READ_USER");
    const [impersonatedUsers, loadingImpersonated] = useRemoteEntity(data, '/users/search', 'impersonatorUserId', "READ_USER");

    const [operation, showOperation] = useState();

    const isImpersonatedUserPresent = useMemo(
        () => data?.content?.find(v => v.impersonatorUserId),
        [data?.content]
    );

    const {onDownload, downloading} = useAuditExporter(searchFilter)

    return <Box className={"audit-log-list"}>

        <BoxOptions>
            <MenuDropdown>
                <ToolTip content={t('download_menu')}>
                    <div className={classnames("btn-rounded-primary small")}>
                        <FontAwesomeIcon icon={faDownload}/>
                    </div>
                </ToolTip>
                <MenuDropdownContent>
                    {Object.keys(AuditExportFormat).map(param =>
                        <MenuDropdownOption key={param}>
                            <div onClick={() => onDownload(param)}>
                                {t('export_to', {type: t(param)})}
                            </div>
                        </MenuDropdownOption>
                    )}
                </MenuDropdownContent>
            </MenuDropdown>
        </BoxOptions>

        {operation &&
            <ModalComponent
                {...props}
                operation={operation}
                onClose={() => showOperation(false)}
            />
        }

        <Loading visible={downloading}/>

        <TableHeader>
            {!hideFilter &&
                <SearchArea hideInput={true}>
                    <SearchFilter onSubmit={(data) => onFilterSubmit(data)}>
                        <ListFilterComponent searchFilter={searchFilter} t={t} filterActions={filterActions}
                                             filterObjectTypes={filterObjectTypes}
                                             userId={userId} objectType={objectType} ownerVatNumber={ownerVatNumber}/>
                    </SearchFilter>
                </SearchArea>
            }

        </TableHeader>
        <SearchDataPagination data={data} onPageChange={onPageChange}/>

        <TableAdvanced
            hover
            sort={data?.sortBy}
            order={data?.sortOrder?.toLowerCase()}
            onSortClick={onPageSort}
            loading={(loading || (!data && !error?.data?.hasErrors) || loadingUsers || loadingImpersonated || loadingExtraData)}>
            <thead>
            <tr>
                <th id="timestamp" sorted>
                    {t('date')}
                </th>

                <th id="objectType" className="d-none d-md-table-cell">
                    {t('object_type')}
                </th>

                <th id={"action"}>
                    {t('action')}
                </th>

                {!affectedObjectType &&
                    <th id="objectType" className="d-none d-md-table-cell">
                        {t('affected_id')}
                    </th>
                }

                <th id={"ip"}>
                    {t('ip')}
                </th>

                {(!userId && !ownerVatNumber) &&
                    <th id="user">
                        {t('user')}
                    </th>
                }

                {isImpersonatedUserPresent &&
                    <th id="user">
                        {t('impersonator_user')}
                    </th>
                }

                {!hideMoreInfo &&
                    <th/>
                }

            </tr>
            </thead>
            <tbody>

            {extendedData?.map((value, index) => {

                return <tr key={index}>
                    <td className="td-sorted">
                        <DateTime format="DD-MM-YYYY HH:mm:ss">
                            {value.timestamp}
                        </DateTime>
                    </td>

                    <td className="d-none d-md-table-cell">
                        {t(value.objectType)}
                    </td>

                    <td>
                        {t(value.action)}
                    </td>

                    {!affectedObjectType &&
                        <td className={"fill-empty"}>
                            <DescriptionComponent value={value}/>
                        </td>
                    }

                    <td className={"fill-empty"}>
                        {value?.userIpAddress}
                    </td>

                    {(!userId && !ownerVatNumber) &&
                        <td className={"fill-empty"}>
                            {value.userType !== AuditUserType.OWNERS
                                ? users?.[value.userId]?.name
                                : <i>{t('owner')}</i>
                            }
                        </td>
                    }

                    {isImpersonatedUserPresent &&
                        <td className={"fill-empty"}>
                            {impersonatedUsers?.[value.impersonatorUserId]?.name}
                        </td>
                    }

                    {!hideMoreInfo &&
                        <td className="table-buttons">
                            <div className={"buttons"}>

                                <TableIconButton
                                    icon={faSearch}
                                    className={"table-btn btn-rounded-primary small"}
                                    onClick={() => showOperation({
                                        ...value,
                                        author: users?.[value.userId],

                                    })}
                                />

                            </div>
                        </td>
                    }
                </tr>
            })}
            </tbody>
        </TableAdvanced>
        {data?.content?.length === 0 &&
            <div className={"no-table-results"}>
                {t('no_results')}
            </div>
        }

        {error?.data?.hasErrors &&
            <div className={"no-table-results"}>
                {t('error_platform')}
            </div>
        }
    </Box>


}

export default ListComponent;
