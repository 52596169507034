
import GenerateContainer from "app/reporting/containers/GenerateContainer";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/reporting/locales/locales";

const ReportGenerateComponent = props => {

    const {operation, isOperation} = props;
    const {t} = useTranslations("reports", locale);

    return <>
        <GenerateContainer
            t={t}
            isOperation={isOperation}
            defaultTemplateId={operation?.objectRaw?.templateId}
            defaultRequest={operation?.objectRaw}
            readOnly={true}
        />
    </>
}

export default ReportGenerateComponent;
