import React from "react";
import classnames from "classnames";
import cloneWithoutKeys from "system/Objects/Objects";

const InputText = (props, ref) => {

    const {type, onChange, value, defaultValue, disabled} = props;

    return <>
        {(!value && !defaultValue && disabled) &&
        <div className={classnames({
            "empty-value": (!value && !defaultValue && disabled)
        })}/>
        }
        <input
            {...cloneWithoutKeys(props, ["onClear", "currentValue"])}
            type={type}
            ref={ref}
            onChange={onChange}
            className={classnames("form-control", {
                "empty-value": (!value && !defaultValue && disabled)
            })}
        />
    </>;
}

const InputTextComponent = React.forwardRef(InputText);
export default InputTextComponent;
