import {useTranslation} from "react-i18next";
import React from "react";
import Container from "react-bootstrap/Container";
import ListFilterComponent from "./ListFilterComponent";
import ListEntryComponent from "./ListEntryComponent";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import TableReloadWatcher from "modules/GenericTableContainer/utils/TableReloadWatcher";
import TableHeaderButtonGroup from "layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import "app/support/assets/css/list.scss";
import "app/support/assets/css/status.scss";
import {useSupport} from "app/support/utils/SupportStateContext";

const ListComponent = (props) => {

    const {t} = useTranslation('support');
    const {openTicket, isOpenTicketAvailable} = useSupport();

    const {
        data, searchFilter, onSearch, onPageChange, onPageSort, loading, onFilterSubmit, simpleMode

    } = props;

    return <Container fluid>

        <TableReloadWatcher {...props} />
        <Box className={"ticket-list"}>

            {!simpleMode && <TableHeader>
                <SearchArea onSearch={onSearch} value={searchFilter.universalSearch}>
                    <SearchFilter onSubmit={(data) => onFilterSubmit(data)}>
                        <ListFilterComponent t={t} searchFilter={searchFilter}/>
                    </SearchFilter>
                </SearchArea>

                {isOpenTicketAvailable &&
                <TableHeaderButtonGroup>
                    <TableIconAdd onClick={() => openTicket({})}/>
                </TableHeaderButtonGroup>
                }

            </TableHeader>}
            <SearchDataPagination data={data} onPageChange={onPageChange}/>


            <TableAdvanced hover
                           sort={data?.sortBy}
                           order={data?.sortOrder?.toLowerCase()}
                           onSortClick={onPageSort}
                           loading={(loading || !data)}>

                <thead>
                <tr>

                    <th>
                        {t('id')}
                    </th>

                    <th>
                        {t('subject')}
                    </th>

                    {simpleMode ? <th id="lastUpdate" className="d-none d-md-table-cell">
                        {t('last_update')}
                    </th> : <th id="lastUpdate" className="d-none d-md-table-cell" sorted>
                        {t('last_update')}
                    </th>}

                    <th>
                        {t('status')}
                    </th>


                </tr>
                </thead>
                <tbody>

                <ListEntryComponent isDashboard={false} t={t} data={data} simpleMode={simpleMode}/>
                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 && <div className={"no-table-results"}>
                {t('no_tickets_found')}
            </div>}
        </Box>
    </Container>

}

export default ListComponent;
