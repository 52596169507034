import ModalWithNoMargin from "layout/modules/Modal/ModalWithNoMargin/ModalWithNoMargin";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import {Trans} from "react-i18next";

const LockTemporaryPopupComponent = props => {

    const {t, setStatus, status} = props;

    return <>

        <div className={"lock-popup-extra-label"}>
            <Trans>
                {t('lock_owner_temporary_msg')}
            </Trans>
        </div>
        <ModalWithNoMargin>
            <div className={"lost-close-popup-extra"}>
                <Form>
                    <Col sm={12} lg={12}>
                        <FormGroup mandatory={false}>
                            <Form.Label>{t('date_of_unlock')}</Form.Label>
                            <DatePicker
                                placeholder={t("date_ph")}
                                timezone={"UTC"}
                                onlyFutureDates={true}
                                value={getOrEmpty(status?.lockEndDate)}
                                onChange={useCallback(
                                    (date) => {
                                        setStatus(status => ({
                                            ...status,
                                            lockEndDate: date
                                        }))
                                    },
                                    [setStatus]
                                )}
                            />
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={12}>
                        <FormGroup>
                            <Form.Label>{t('observations')}</Form.Label>
                            <Form.Control
                                name={"observation"}
                                type="textarea"
                                as="textarea"
                                rows={3}
                                value={getOrEmpty(status?.observations)}
                                onChange={(e) => setStatus(s => ({
                                    ...s,
                                    observations: e.target.value,
                                }))}
                            />
                        </FormGroup>
                    </Col>

                </Form>
            </div>
        </ModalWithNoMargin>
    </>

}

export default LockTemporaryPopupComponent;