import {useEffect} from "react";
import {useApiState} from "system/API/APIStateContext";
import trackerAssist from '@openreplay/tracker-assist';
import useTranslations from "system/Translations/UseTranslations";
import locale from "locales/locales";
import "modules/OpenReplay/assets/css/open-replay.scss";
import {themes, useThemeState} from "system/Themes/ThemeContextWrapper";
import tracker from "modules/OpenReplay/tracker";
import appConfig from "configs/config";


const OpenReplay = (props) => {

    const {t} = useTranslations('global', locale);
    const {authentication} = useApiState();
    const {theme} = useThemeState();

    useEffect(() => { // use componentDidMount in case of React Class Component

            tracker.use(trackerAssist({
                callUITemplate:
                    (theme === themes.dark)
                        ? process.env.PUBLIC_URL + "/call-assist/index-dm.html"
                        : process.env.PUBLIC_URL + "/call-assist/index.html",

                callConfirm: {
                    text: t('assist_call_confirm_message'),
                    style: {
                        background: null,
                        color: null,
                        "border-radius": null,
                        "font": null,
                        "box-shadow": null,
                    },
                    confirmBtn: {
                        innerHTML: t('assist_call_accept'),
                        style: {
                            backgroundColor: null
                        },
                    },
                    declineBtn: t('assist_call_cancel'),
                },
                controlConfirm: {
                    text: t('assist_control_confirm_message'),
                    style: {
                        background: null,
                        color: null,
                        "border-radius": null,
                        "font": null,
                        "box-shadow": null,
                    },
                    confirmBtn: {
                        innerHTML: t('assist_control_accept'),
                        style: {
                            backgroundColor: null
                        },
                    },
                    declineBtn: t('assist_control_cancel'),
                }
            }));

            if (!appConfig.openReplay.disabled)
                tracker.start();

        },
        // eslint-disable-next-line
        []
    )


    useEffect(() => {

            if (authentication) {
                tracker.setUserID(authentication?.user);
                tracker.setMetadata("entityId", authentication?.entity?.id?.toString());
                tracker.setMetadata("entityName", authentication?.entity?.name);
                tracker.setMetadata("type", authentication?.type);
                tracker.setMetadata("name", authentication?.name);
            }
        },
        [authentication]
    );


    return props.children;


}

export default OpenReplay;
