import React from "react";
import "../../assets/css/animal.scss"
import "../../assets/css/animal-responsive.scss"
import FormContainer from "../../containers/form/FormContainer";


const GeneralComponent = (props) => {

    return <div className={"animal-data"}>
        <FormContainer {...props} viewMode={true}/>
    </div>

}

export default GeneralComponent;
