import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import UserHasPermission, {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import Loading from "layout/modules/Loading/Loading";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Box from "layout/modules/Box/Box";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrDefault, getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import {AggressionAnimalFate, AggressionMap, AggressionRegisterType, AggressionType} from "types/Aggression";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import DocumentsComponent from "app/aggressions/components/form/DocumentsComponent";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import DateTime from "system/DateTime/DateTime";

const FormComponent = (props) => {

    const {readOnly, setReadOnly, loading, create, aggression, setAggression, errors, compare} = props;

    const {t} = useTranslation("aggression");
    const userHasPermission = useUserHasPermission();
    const handleEditClick = useCallback(() => setReadOnly(readOnly => !readOnly), [setReadOnly]);

    return <div className={classnames("aggression-form", {
        "view-mode": readOnly,
        "compare-mode": compare
    })}>
        <Loading visible={loading}/>

        <Box>
            <BoxTitle>
                {t('general_data')}
            </BoxTitle>

            <BoxOptions>
                {(!create && !compare && !aggression.clearRegistry) &&
                <UserHasPermission permission="EDIT_AGGRESSION">
                    <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                         onClick={handleEditClick}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </div>
                </UserHasPermission>
                }
            </BoxOptions>

            <Row>

                {create && userHasPermission({permission: "WRITE_AGGRESSION_CLEAR"}) &&
                <Col sm={12} xl={6}>
                    <FormGroup mandatory={true}>
                        <Form.Label>{t('register_type')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            value={getOrDefault(aggression?.registerType, AggressionRegisterType.REGISTER_AGGRESSION)}
                            options={AggressionRegisterType}
                            isClearable={false}
                            isSearchable={true}
                            isDisabled={!create}
                            onChange={type => setAggression(aggression => ({
                                ...aggression,
                                registerType: type,
                            }))}
                        />
                    </FormGroup>
                </Col>
                }

                <Col sm={12} xl={true}>
                    <FormGroup error={errors['aggressionDate']} mandatory={true}>
                        <Form.Label>{t('date')}</Form.Label>
                        <DatePicker
                            placeholder={t("date_ph")}
                            timezone={"UTC"}
                            onlyPastDates={true}
                            disabled={readOnly}
                            value={getOrEmpty(aggression?.aggressionDate)}
                            onChange={useCallback(
                                date => setAggression(aggression => ({
                                    ...aggression,
                                    aggressionDate: date,
                                })), [setAggression]
                            )}
                        />
                        <Form.Text className="sub-error">
                            {t(errors['aggressionDate'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </Row>
        </Box>


        {(!aggression.registerType || AggressionRegisterType.REGISTER_AGGRESSION === aggression.registerType) &&
        <Box>
            <BoxTitle>
                {t('aggression_data')}
            </BoxTitle>

            <Row>
                <Col sm={12} xl={true}>
                    <FormGroup error={errors['aggressionType']} mandatory={true}>
                        <Form.Label>{t('type')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            value={getOrNull(aggression?.aggressionType)}
                            options={AggressionType}
                            isClearable={false}
                            isSearchable={true}
                            isDisabled={readOnly}
                            placeholder={t('type_ph')}
                            onChange={type => setAggression(aggression => ({
                                ...aggression,
                                aggressionType: type,
                                aggressionSubType: null,
                            }))}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['aggressionType'])}
                        </Form.Text>
                    </FormGroup>
                </Col>

                {(aggression?.aggressionType && AggressionMap[aggression?.aggressionType]) &&
                <>
                    <Col sm={12} xl={6}>
                        <FormGroup error={errors['aggressionSubType']} mandatory={true}>
                            <Form.Label>{t('sub_type')}</Form.Label>
                            <EnumDropdown
                                t={t}
                                key={aggression?.aggressionType}
                                value={getOrNull(aggression?.aggressionSubType)}
                                options={AggressionMap[aggression?.aggressionType]}
                                isClearable={false}
                                isSearchable={true}
                                isDisabled={readOnly}
                                placeholder={t('type_ph')}
                                onChange={type => setAggression(aggression => ({
                                    ...aggression,
                                    aggressionSubType: type,
                                }))}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['aggressionSubType'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} xl={6}>
                        <FormGroup error={errors['animalFate']} mandatory={true}>
                            <Form.Label>{t('fate')}</Form.Label>
                            <EnumDropdown
                                t={t}
                                value={getOrNull(aggression?.animalFate)}
                                options={AggressionAnimalFate}
                                isClearable={false}
                                isSearchable={true}
                                isDisabled={readOnly}
                                placeholder={t('fate_ph')}
                                onChange={type => setAggression(aggression => ({
                                    ...aggression,
                                    animalFate: type,
                                }))}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['animalFate'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                </>
                }
            </Row>
        </Box>
        }

        {(AggressionRegisterType.REGISTER_CLEAR === aggression.registerType) &&
        <Box>
            <BoxTitle>{t('REGISTER_CLEAR')}</BoxTitle>
            <Row>
                <Col sm={12} xl={12}>
                    <FormGroup error={errors['clearRegistryReason']} mandatory={true}>
                        <Form.Label>{t('clear_reason')}</Form.Label>
                        <Form.Control
                            type="textarea"
                            as="textarea"
                            rows={3}
                            disabled={readOnly}
                            value={getOrEmpty(aggression?.clearRegistryReason)}
                            onChange={(e) => setAggression(intervention => ({
                                ...intervention,
                                clearRegistryReason: e.target.value,
                            }))}

                        />
                        <Form.Text className="sub-error">
                            {t(errors['clearRegistryReason'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </Row>

        </Box>
        }

        <DocumentsComponent
            readOnly={readOnly}
            aggression={aggression}
            setAggression={setAggression}
        />

        {(readOnly && !compare) &&
        <Box>
            <BoxTitle>
                {t('aggression_created_by')}
            </BoxTitle>

            <Row>
                <Col sm={12} xl={6}>
                    <LabelWithValue
                        label={t('entity')}
                        value={getOrEmpty(aggression?.createdByEntity?.name)}
                    />
                </Col>

                <Col sm={12} xl={6}>
                    <LabelWithValue
                        label={t('user')}
                        value={getOrEmpty(aggression?.createdByUser?.name)}
                    />
                </Col>

                <Col sm={12} xl={6}>
                    <LabelWithValue label={t('registration_date')}>
                        <DateTime format="DD-MM-YYYY HH:mm">
                            {getOrEmpty(aggression?.creationTimestamp)}
                        </DateTime>
                    </LabelWithValue>
                </Col>

            </Row>
        </Box>


        }

    </div>

}

export default FormComponent;
