import React, {forwardRef, useCallback, useEffect, useMemo, useState} from "react";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/control/locale/locales";
import {useMutate} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import {useLocation} from "react-router";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";

const FileDeleteComponent = forwardRef((props, ref) => {

    const {t} = useTranslations("control", locale);
    const {selectedFile, request, reload} = props;

    const [isConfirmVisible, setConfirmVisible] = useState(false);

    const location = useLocation();
    const showToast = useShowToast();

    const {mutate: del, loading} = useMutate({
        verb: "DELETE",
        path: useMemo(() => "/documents/" + selectedFile?.id + "/observation", [selectedFile]),
        queryParams: useMemo(() => ({
            url: selectedFile?.category === "ANIMAL_PRE_REGISTER"
                ? location?.pathname
                : "/animal/view/" + request.transponder,
        }), [selectedFile?.category, location?.pathname, request.transponder])
    });

    useEffect(() => {
        ref.current = {
            handleDelete: () => setConfirmVisible(true)
        }
    }, [ref, setConfirmVisible]);

    const handleConfirm = useCallback(
        () => {

            setConfirmVisible(false);

            del().then(
                () => {
                    showToast({
                        title: t('DELETE_SUCCESS'),
                        message: t('DELETE_SUCCESS_MSG', selectedFile),
                        icon: faCheck,
                    })
                    reload();
                }
            ).catch(e => handleUnknownFormError(e, showToast, t));

        },
        [del, showToast, reload, t, selectedFile]
    );

    return <>
        {loading &&
            <Loading visible={loading}/>
        }

        {isConfirmVisible &&
            <PopupConfirm
                title={t('CONFIRM_DELETE_FILE', selectedFile)}
                message={t('CONFIRM_DELETE_FILE_MSG', selectedFile)}
                classConfirm={"primary"}
                backdrop={true}
                visible={true}
                onCancel={() => {
                    setConfirmVisible(false);
                }}
                onConfirm={handleConfirm}
            />
        }
    </>


});

export default FileDeleteComponent;