import React, {createContext, useMemo, useRef} from 'react'

const SupportContext = createContext({});

function useSupport() {
    const context = React.useContext(SupportContext)
    if (!context) {
        throw new Error(`useSupport must be used within a SupportContext`)
    }
    return context
}


const SupportContextProvider = (props) => {

    const ref = useRef(null);

    const value = useMemo(
        () => {
            return {
                ref: ref,
                openTicket: (props) => ref?.current?.openTicket(props),
                isOpenTicketAvailable: !!ref?.current?.openTicket,
            }
        },
        [ref]
    );

    return <SupportContext.Provider value={value}>
        {props.children}
    </SupportContext.Provider>

}

export default SupportContextProvider;
export {useSupport}
