import {useGet} from "system/Rest/Rest";
import React, {useMemo} from "react";
import {useLocation} from "react-router";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import "app/observations/assets/fixed.scss";

const ShowFixedObservations = props => {

    const location = useLocation();
    const {location: locationProps} = props;

    const pathName = locationProps ?? location?.pathname;

    const {data} = useGet({
        path: "/observations/search-public/",
        queryParams: useMemo(() => ({
            url: pathName,
        }), [pathName])
    });

    if (data?.totalItems >= 1) {
        return <div className={"fixed-observations"}>
            {data?.content?.map(
                (observation) =>
                    <AdvanceInfoMessage
                        key={observation.id}
                        icon={faBell}
                        type={"info"}
                        iconType={"info"}
                    >
                        <div className={"fixed-observation-content"}>
                            {observation.observation}
                        </div>
                    </AdvanceInfoMessage>

            )}
        </div>

    } else return <></>


}

export default ShowFixedObservations;
