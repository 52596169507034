import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import React, {useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import {useAnimalState} from "../../utils/AnimalContext";
import AnimalFieldIsAllowed, {useAnimalFieldsAllowed} from "../../utils/AnimalFieldsAllowed";
import {useLocation} from "react-router";
import Loading from "layout/modules/Loading/Loading";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import TransponderControl from "modules/Forms/Transponder/TransponderControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import FormControl from "layout/modules/Forms/Control/FormControl";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const IDComponent = (props) => {

    const {
        errors = {}, readOnly, changes = {}, onValidateTransponder,
        create, onInvalidateTransponder, validating, validationStatus
    } = props;

    const {
        animal: {
            transponder, orderNumber, registrationDate, transponderMerges
        }, setAnimal
    } = useAnimalState();

    const location = useLocation();
    const {t} = useTranslation('animal');

    const canEditField = useAnimalFieldsAllowed(readOnly, create, errors);

    const mergedTransponders = useMemo(
        () => transponderMerges?.map(t => t.oldTransponder),
        [transponderMerges]
    );

    const handleValid = useCallback(
        (transponder) => {
            onValidateTransponder(transponder);
            setAnimal(a => ({
                ...a,
                transponder: transponder
            }));
        },
        [onValidateTransponder, setAnimal]
    );


    const handleInvalid = useCallback(
        (transponder) => {
            onInvalidateTransponder(transponder);
            setAnimal(a => ({
                ...a,
                transponder: transponder
            }));
        },
        [onInvalidateTransponder, setAnimal]
    )

    useEffect(() => {
        if (location?.state?.transponder_auto_fill) {
            handleValid(location?.state?.transponder_auto_fill)
        }

    }, [location?.state?.transponder_auto_fill, handleValid]);



    return <>
        <Loading visible={validating}/>
        <Row>
            <Col sm={12} xl={true}>
                <FormGroup error={validationStatus?.errorMessage || errors['transponder']}
                           changes={changes['transponder']} mandatory={true}>
                    <Form.Label>{t('transponder')}</Form.Label>
                    <TransponderControl
                        placeholder={t('transponder_ph')}
                        disabled={readOnly || !canEditField('transponder')}
                        onlyNumeric={true}
                        value={getOrEmpty(transponder)}
                        onValid={handleValid}
                        onInvalid={handleInvalid}

                    />

                    <Form.Text className="sub-error">
                        {validationStatus?.errorMessage
                            ? t(validationStatus.errorMessage)
                            : t(errors['transponder'])
                        }
                    </Form.Text>
                </FormGroup>
            </Col>

            {mergedTransponders?.map((altTransponder, idx) =>
                <Col sm={12} lg={6} key={idx}>
                    <FormGroup>
                        <Form.Label>{t('transponder_n', {n: (idx + 2)})}</Form.Label>
                        <TransponderControl
                            disabled={true}
                            value={getOrEmpty(altTransponder)}
                        />
                    </FormGroup>
                </Col>
            )}

            <UserHasPermission permission="SEE_ORDER_NUMBER">
                <AnimalFieldIsAllowed field={"orderNumber"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['orderNumber']} changes={changes['orderNumber']}>
                            <Form.Label>{t('order_number')}</Form.Label>
                            <FormControl
                                type="text"
                                placeholder={t('order_number_ph')}
                                disabled={readOnly}
                                value={getOrEmpty(orderNumber)}
                                onChange={useCallback(e => setAnimal(animal => ({
                                    ...animal,
                                    orderNumber: e.target.value,
                                })), [setAnimal])}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['orderNumber'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                </AnimalFieldIsAllowed>
            </UserHasPermission>

            <AnimalFieldIsAllowed field={"registrationDate"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Col sm={12} lg={6}>
                    <FormGroup error={errors['registrationDate']} changes={changes['registrationDate']}>
                        <Form.Label>{t('registration_date')}</Form.Label>
                        <DatePicker
                            name={"registrationDate"}
                            placeholder={t("date_ph")}
                            disabled={readOnly}
                            timezone={"UTC"}
                            onlyPastDates={true}
                            value={getOrEmpty(registrationDate)}
                            onChange={useCallback(date => setAnimal(animal => ({
                                ...animal,
                                registrationDate: date,
                            })), [setAnimal])}
                        />
                        <Form.Text className="sub-error">
                            {t(errors['registrationDate'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </AnimalFieldIsAllowed>
        </Row>
    </>

}

export default IDComponent;
