import Col from "react-bootstrap/Col";
import FormGroup from "../../../../../layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import React from "react";
import {getOrNull} from "../../../../../system/Objects/ObjectParameters";
import GenericRemoteClassDropdown
    from "../../../../../modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";

const CountyOrParishComponent = props => {

    const {value, setValue, parameter, error, readOnly, isParish} = props;

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <GenericRemoteClassDropdown
                remotePath={isParish ? "/address/search-parish" : "/address/search-county"}
                universalSearch={"name"}
                isDisabled={readOnly}
                isClearable={!parameter?.mandatory}
                isMulti={parameter?.multipleValues}
                value={getOrNull(value)}
                onChange={setValue}
                searchFilter={{
                    sortProperty: 'name',
                    sortDirection: 'ASC',
                    currentPage: 0,
                    itemsPerPage: 100,
                }}
            />
            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default CountyOrParishComponent;
