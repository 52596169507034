import React, {useEffect, useRef, useState} from "react";
import useOnScreen from "system/Components/useOnScreen";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import {AnimalLostStatus} from "types/Animal";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import BreedDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/BreedDropdown";
import appConfig from "configs/config";
import FormGeofence from "modules/Forms/Geofence/GeofenceForm";
import AnimalLostOrigin from "types/AnimalLostOrigin";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import {parseNumber} from "system/Utils/parseNumber";
import BreedGroupType from "types/BreedGroupType";

const LostListFilterComponent = props => {

    const {searchFilter, t} = props;
    const [filterState, setFilterState] = useState();

    const filterRef = useRef(null);
    const isVisible = useOnScreen(filterRef);

    useEffect(() => {
        setFilterState({
            ...searchFilter
        });
    }, [searchFilter]);

    return <div ref={filterRef}>
        {filterState &&
        <>
            <Row>
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('status')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            placeholder={t('status_ph')}
                            id={"status"}
                            isMulti={true}
                            options={AnimalLostStatus}
                            value={getOrNull(filterState.status)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    status: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('origin')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            placeholder={t('status_ph')}
                            id={"creationOrigin"}
                            isMulti={true}
                            options={AnimalLostOrigin}
                            value={getOrNull(filterState.creationOrigin)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    creationOrigin: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('closed_occurrence')}</Form.Label>
                        <BooleanDropdown
                            id="isClosed"
                            isClearable={true}
                            value={parseBoolean(filterState.isClosed, null)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    isClosed: val
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('registered')}</Form.Label>
                        <BooleanDropdown
                            id="isRegistered"
                            isClearable={true}
                            value={parseBoolean(filterState.isRegistered, null)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    isRegistered: val
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('allow_advertising')}</Form.Label>
                        <BooleanDropdown
                            id="allowsAdvertising"
                            isClearable={true}
                            value={parseBoolean(filterState.allowsAdvertising, null)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    allowsAdvertising: val
                                })
                            }}
                        />
                    </FormGroup>
                </Col>


                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('breed')}</Form.Label>
                        <BreedDropdown
                            id={"breedIds"}
                            groupType={BreedGroupType.BREED}
                            includeParentOnLabel={true}
                            isMulti={true}
                            value={getOrNull(filterState?.breedIds)}
                            onChange={(breedIds) => setFilterState(filterState => ({
                                ...filterState,
                                breedIds: breedIds,
                            }))}

                        />
                    </FormGroup>
                </Col>

                {appConfig.defaultCountry === "PT" &&
                <Col sm={12} lg={12}>
                    <FormGeofence
                        t={t}
                        visible={isVisible}
                        ignoreSubmit={false}
                        readOnly={parseBoolean(filterState.externalLostAnimals, false)}
                        entry={{
                            districts: getOrNull(filterState.districts),
                            counties: getOrNull(filterState.counties),
                            parishes: getOrNull(filterState.parishes),
                        }}
                        onChange={(val) => setFilterState(filterState => ({
                            ...filterState,
                            ...val,
                        }))}
                    />
                </Col>
                }


                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('from_creation_date')}</Form.Label>
                        <DatePicker
                            name={"fromCreationTimestamp"}
                            placeholder={t("date_ph")}
                            value={parseNumber(filterState.fromCreationTimestamp, undefined)}
                            toDate={parseNumber(filterState.toCreationTimestamp, undefined)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    fromCreationTimestamp: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('to_creation_date')}</Form.Label>
                        <DatePicker
                            name={"toCreationTimestamp"}
                            placeholder={t("date_ph")}
                            value={parseNumber(filterState.toCreationTimestamp, undefined)}
                            fromDate={parseNumber(filterState.fromCreationTimestamp, undefined)}
                            outputEndOfDayTimestamp={true}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    toCreationTimestamp: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>


                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('from_occurrence_date')}</Form.Label>
                        <DatePicker
                            name={"fromEventTimestamp"}
                            placeholder={t("date_ph")}
                            value={parseNumber(filterState.fromEventTimestamp, undefined)}
                            toDate={parseNumber(filterState.toEventTimestamp, undefined)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    fromEventTimestamp: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('to_occurrence_date')}</Form.Label>
                        <DatePicker
                            name={"toEventTimestamp"}
                            placeholder={t("date_ph")}
                            value={parseNumber(filterState.toEventTimestamp, undefined)}
                            fromDate={parseNumber(filterState.fromEventTimestamp, undefined)}
                            outputEndOfDayTimestamp={true}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    toEventTimestamp: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

            </Row>
        </>
        }

    </div>
}

export default LostListFilterComponent;
