const fileNameGenerator = (result) => {

    if (result.customFilename?.length > 0) {

        return result.hasTransponderSuffix
            ? result.customFilename + "-" + result.transponder + ".pdf"
            : result.customFilename + ".pdf";
    } else {
        return result.transponder + ".pdf";
    }
}

export default fileNameGenerator;
