import React from "react";
import {useTranslation} from "react-i18next";
import RequestAuthorAndReviewerComponent from "app/control/components/show/RequestAuthorAndReviewerComponent";
import FormAnimalComponent from "app/control/modules/transfers/components/create/AnimalComponent";
import OwnerRequestComponent from "app/control/modules/transfers/components/show/request/OwnerRequestComponent";
import ControlSupportContainer from "app/support/containers/ControlSupportContainer";
import {TicketProcessType} from "types/TicketProcessType";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import ShowAdminLabels from "app/adminLabels/ShowAdminLabels";
import ObjectType from "app/adminLabels/utils/ObjectType";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import TransferType from "app/control/modules/transfers/utils/TransferType";

const RequestComponent = (props) => {

    const {request} = props;
    const {t} = useTranslation('transfer');

    return <>

        <ControlSupportContainer
            processType={TicketProcessType.ANIMAL_TRANSFER}
            id={request.id}/>

        <RequestAuthorAndReviewerComponent t={t} request={request}/>

        {request.origin !== TransferType.OWNERS
            ? <FormAnimalComponent
                animalOwner={request}
                readOnly={true}
            />
            : <OwnerRequestComponent
                t={t}
                animalOwner={request}
            />
        }

        <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER", "MANAGE_LABELS"]}>
            <Box>
                <BoxTitle>{t('labels')}</BoxTitle>
                <ShowAdminLabels objectType={ObjectType.ANIMAL_TRANSFER} objectId={request?.id}
                                 labels={request?.labels}/>
            </Box>
        </UserHasPermission>


    </>

}

export default RequestComponent;
