import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import "app/animals/assets/css/animal-lost-close-popup.scss";
import ModalWithNoMargin from "layout/modules/Modal/ModalWithNoMargin/ModalWithNoMargin";

const CloseAsDeadComponent = props => {

    const {t} = useTranslation("animal");
    const {status, setStatus} = props;

    return <>

        <div className={"lost-close-popup-extra-label"}>
            {t('set_dead_alert_msg')}
        </div>
        <div className={"lost-close-popup-extra-label"}>
            {t('please_set_dead_date')}
        </div>
        <ModalWithNoMargin>
            <div className={"lost-close-popup-extra"}>
                <Form>
                    <Col sm={12} lg={12}>
                        <FormGroup>
                            <Form.Label>{t('date_of_death')}</Form.Label>
                            <DatePicker
                                placeholder={t("date_ph")}
                                timezone={"UTC"}
                                onlyPastDates={true}
                                value={getOrEmpty(status?.deathDate)}
                                onChange={useCallback(
                                    (date) => {
                                        setStatus(status => ({
                                            ...status,
                                            deathDate: date
                                        }))
                                    },
                                    [setStatus]
                                )}
                            />
                        </FormGroup>
                    </Col>
                </Form>
            </div>
        </ModalWithNoMargin>
    </>
}

export default CloseAsDeadComponent;
