import AnimalListComponent from "./components/generate/AnimalListComponent";
import {useGet} from "system/Rest/Rest";
import React, {useEffect} from "react";
import {useApiState} from "system/API/APIStateContext";
import generateHashCode from "system/Utils/generateHashCode";
import useHistoryState from "system/Routing/useHistoryState";
import Loading from "layout/modules/Loading/Loading";

const AnimalCertificateList = (props) => {

    const {animal} = props;


    const {authentication} = useApiState();

    const currentHashCode = generateHashCode(animal);
    const [certificates, setCertificates] = useHistoryState("certificates", null);
    const [animalHash, setAnimalHash] = useHistoryState("hash", currentHashCode);

    const {data, refetch: reloadDocuments} = useGet({
        path: "/certificates/list/" + animal?.transponder,
        debounce: 100,
        lazy: certificates
    });

    //Update certificates on data update
    useEffect(
        () => {
            if (data) {
                setCertificates(data);
            }
        },
        [data, setCertificates]);

    //Update on animal change
    useEffect(
        () => {
            if (currentHashCode !== animalHash) {
                setAnimalHash(currentHashCode);
                reloadDocuments();
            }
        },
        [currentHashCode, setAnimalHash, animalHash, reloadDocuments]
    );

    //update documents on impersonate
    useEffect(
        () => {
            if (authentication?.id && certificates) {
                reloadDocuments();
            }
        },
        // eslint-disable-next-line
        [authentication?.id]
    );

    if (!certificates) {
        return <Loading visible={true}/>
    } else if (certificates.length === 0) {
        return <></>;
    }

    return <AnimalListComponent
        {...props}
        certificates={certificates ? certificates : []}
    />

}

export default AnimalCertificateList
