const AnimalLostPublicClosedState = {
    DELIVERED_TO_OWNER: "DELIVERED_TO_OWNER",
    DELIVERED_TO_CRO: "DELIVERED_TO_CRO",
    TRANSFERRED: "TRANSFERRED",
    DEAD: "DEAD",
};

const AnimalLostClosedState = {
    DELIVERED_TO_OWNER: "DELIVERED_TO_OWNER",
    DELIVERED_TO_CRO: "DELIVERED_TO_CRO",
    DEAD: "DEAD",
    ARRESTED: "ARRESTED",
    TRANSFERRED: "TRANSFERRED",
    REGISTERED_BY_ERROR: "REGISTERED_BY_ERROR",
};

export default AnimalLostClosedState;
export {AnimalLostPublicClosedState};
