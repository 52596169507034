import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";
import React from "react";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/animals/locales/locales";

const TransferAlert = props => {

    const {hideTransfer} = props;
    const {animal} = useAnimalState();
    const {t} = useTranslations("animal", locale);

    if (hideTransfer || !animal?.hasPendingOwnerTransfer) {
        return <></>
    }

    return <AdvanceInfoMessage
        type={"warning"}
        icon={faExchangeAlt}
        iconType={"warning"}
        title={t("animal_is_pending_owner")}>

        <Trans>
            {t('animal_is_pending_owner_msg')}
            {animal?.pendingOwnerTransferId &&
                <Link to={"/transfers/" + animal?.pendingOwnerTransferId}>
                    {t('animal_is_pending_owner_msg_link')}
                </Link>
            }
        </Trans>

    </AdvanceInfoMessage>

}

export default TransferAlert;
