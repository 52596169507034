import Loading from "layout/modules/Loading/Loading";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import StepBox from "layout/modules/StepBox/StepBox";
import React from "react";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import {getEmptyAnimal} from "app/animals/utils/AnimalResolver";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/control/modules/preRegister/locales/locales";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import AnimalFormComponent from "app/control/modules/preRegister/components/form/AnimalFormComponent";
import classnames from "classnames";
import PreRegisterFormComponent from "app/control/modules/preRegister/components/form/PreRegisterFormComponent";
import "app/control/modules/preRegister/assets/css/form.scss";
import BreedDisclaimerPopupComponent from "app/animals/components/form/BreedDisclaimerPopupComponent";

const FormComponent = props => {

    const {t} = useTranslations('preRegister', locale);

    const {loading, errors = {}, create, readOnly, onSubmit} = props;

    const {animal, setAnimal} = useAnimalState();

    const documentCounter = animal?.documents?.length;


    return <div className={classnames("pre-register-form", {"view-mode": readOnly})}>
        <Loading visible={loading}/>

        <GenericFormError t={t} errors={errors}/>

        {create &&
        <FormHandleNotSaved
            remote={getEmptyAnimal()}
            local={animal}
            loading={loading}
            readOnly={readOnly}
            setLocal={setAnimal}
            setReadOnly={() => void 0}
            onSubmit={onSubmit}/>
        }

        <BreedDisclaimerPopupComponent animal={animal} create={create}/>

        <Loading visible={loading}/>

        <div className={"steps"}>
            <Row>
                <Col lg={4} sm={12}>
                    <StepBox step={1}
                             active={documentCounter === 0}
                             done={documentCounter > 0}>
                        {t('pre_register_s1_desc')}<br/>
                    </StepBox>
                </Col>


                <Col lg={4} sm={12}>
                    <StepBox step={2} active={documentCounter > 0}>
                        {t("pre_register_s2_desc")}
                    </StepBox>
                </Col>

                <Col lg={4} sm={12}>
                    <StepBox step={3}>
                        {t("pre_register_s3_desc")}
                    </StepBox>
                </Col>

            </Row>

        </div>

        <PreRegisterFormComponent errors={errors} readOnly={readOnly}/>

        {documentCounter > 0 &&
        <>
            <AnimalFormComponent
                {...props}
                readOnly={readOnly}
                disableTransponder={true}
            />
        </>
        }

    </div>
}

export default FormComponent;
