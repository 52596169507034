import {Trans} from "react-i18next";
import React, {useCallback, useState} from "react";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/control/modules/preRegister/locales/locales";
import {Modal} from "react-bootstrap";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";

const AlertComponent = props => {

    const {animal} = props;

    const {t} = useTranslations('preRegister', locale);
    const [isVisible, setVisible] = useState(true);


    const closePopup = useCallback(
        () => {
            document.activeElement.blur();
            setVisible(false)
        },
        [setVisible]
    )

    return <Modal
        show={isVisible}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
    >

        <Modal.Header closeButton>
            <Modal.Title>{t("transponder_not_allowed")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Trans>
                {t('pre_register_message')}
            </Trans>
        </Modal.Body>

        <ModalButtons>

            <Button variant="secondary" onClick={closePopup}>
                {t("cancel")}
            </Button>

            <Link to={"/animal/pre-register"} state={{transponder: animal?.transponder}}>
                <Button variant="primary">
                    {t('pre_register')}
                </Button>
            </Link>


        </ModalButtons>

    </Modal>


}

export default AlertComponent;
