import React, {useCallback, useMemo} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import classnames from "classnames";

import {generateSelectOption} from "modules/Forms/Address/utils/utils";
import useFirstElementIfUnique from "system/Arrays/useFirstElementIfUnique";
import {getOrDefault, getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import CreatableSingle from "layout/modules/Forms/Dropdowns/CreatableSingle/CreatableEditableSelect";
import FormControl from "layout/modules/Forms/Control/FormControl";

import 'modules/Forms/Address/assets/css/compare.scss';
import 'modules/Forms/Address/assets/css/address.scss';

const PortugueseStreetComponent = (props) => {

    const {t, errors = {}, changes = {}, data, addresses, onChange, readOnly} = props;


    const streetOptions = useMemo(
        () =>
            addresses?.filter(address => address.parish.id === data.parish)
                .map(address => address.street)
                .filter(val => val.length > 0)
                .map(val => generateSelectOption(val)),
        [addresses, data.parish]
    );

    //Adjust data if only one address was recived from server
    useFirstElementIfUnique(
        addresses,
        firstAddress => {
            onChange(address => ({
                ...address,
                street: getOrDefault(address.street, firstAddress.street),
            }));
        });

    return <Row className={classnames("address-block", {
        'error': errors['address'],
        'has-changed': changes['address']
    })}>

        <Col sm={12} lg={12} xl={6}>
            <FormGroup error={(errors['address'] && !data.street)} mandatory={true}>
                <Form.Label>{t('address_name')}</Form.Label>
                <CreatableSingle
                    placeholder={useMemo(() => t('address_name_ph'), [t])}
                    isClearable={true}
                    classNamePrefix="rs"
                    className="rs"
                    isDisabled={readOnly || !data.parish}
                    options={streetOptions}
                    noOptionsMessage={useCallback(() => t('no_address_suggestions'), [t])}
                    formatCreateLabel={useCallback(
                        (s) => {
                            return <>
                                {s}
                                <small><i>{t('prees_info')}</i></small>
                            </>
                        }, [t])}
                    value={useMemo(() => generateSelectOption(data.street), [data.street])}
                    onChange={useCallback((street) => onChange((data) => ({
                        ...data,
                        street: getOrNull(street?.label),
                        accommodation: street ? data.accommodation : "",
                        door: street ? data.door : "",
                    })), [onChange])}
                />
                <Form.Text className="sub-error">
                    {(errors['address'] && !data.street) && t(errors['address'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={6} lg={6} xl={3}>
            <FormGroup error={(errors['address'] && data.street)} mandatory={true}>
                <Form.Label>{t('door')}</Form.Label>
                <FormControl
                    type="text"
                    placeholder={t('door_ph')}
                    disabled={readOnly || !data.street}
                    value={getOrEmpty(data?.door)}
                    onChange={useCallback(e => onChange((data) => ({
                        ...data,
                        door: e.target.value,
                        accommodation: e.target.value ? data.accommodation : ""
                    })), [onChange])}
                />
                <Form.Text className="sub-error">
                    {(errors['address'] && data.street) && t('INVALID_DOOR_NUMBER')}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={6} lg={6} xl={3}>
            <FormGroup>
                <Form.Label>{t('accommodation')}</Form.Label>
                <FormControl
                    type="text"
                    placeholder={t('accommodation_ph')}
                    disabled={readOnly || !data.door}
                    value={getOrEmpty(data?.accommodation)}
                    onChange={useCallback(e => onChange((data) => ({
                        ...data,
                        accommodation: e.target.value,
                    })), [onChange])}
                />
            </FormGroup>
        </Col>
    </Row>;
}


export default PortugueseStreetComponent;
