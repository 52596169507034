import ProcessObservationsComponent from "app/animals/components/lost/observations/ProcessObservationsComponent";
import {useMutate} from "system/Rest/Rest";
import {useCallback, useMemo} from "react";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useTranslation} from "react-i18next";
import useErrorParser from "system/API/Error/ErrorParser";

const ProcessObservationsContainer = props => {

    const {lostInfo, reloadLostInfo} = props;
    const showToast = useShowToast();
    const {t} = useTranslation("animal");

    const {mutate: submit, loading: submitting, error: errorSubmitting} = useMutate({
        verb: "POST",
        path: useMemo(() => "/lost-animals/" + lostInfo?.transponder + "/observation", [lostInfo?.transponder])
    });

    const {mutate: del, loading: deleting} = useMutate({
        verb: "DELETE",
    });

    const errors = useErrorParser(errorSubmitting);

    return <ProcessObservationsComponent
        {...props}
        errors={errors}
        submitting={submitting || deleting}
        onDelete={useCallback(
            (id) => {
                del({}, {
                        path: "/lost-animals/" + lostInfo?.transponder + "/observation/" + id,
                    })
                    .then(() => {
                        showToast({
                            title: t('SUCCESS_DEL_OBSERVATION'),
                            message: t('SUCCESS_DEL_OBSERVATION_MSG'),
                            icon: faCheck,
                        });
                        reloadLostInfo({});
                    })
                    .catch(e => handleUnknownFormError(e, showToast, t));
            },
            [lostInfo?.transponder, reloadLostInfo, del, showToast, t]
        )}
        onSubmit={useCallback(
            (observation) => new Promise(resolve => {
                submit({
                    observation: observation
                }).then(() => {
                    showToast({
                        title: t('SUCCESS_CREATED_OBSERVATION'),
                        message: t('SUCCESS_CREATED_OBSERVATION_MSG'),
                        icon: faCheck,
                    });
                    reloadLostInfo();
                    resolve();
                }).catch(e => handleUnknownFormError(e, showToast, t))

            }),
            [showToast, t, submit, reloadLostInfo]
        )}

    />
}

export default ProcessObservationsContainer;
