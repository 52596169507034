import List from "app/control/modules/transfers/List";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/control/modules/transfers/locales/locales";
import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";

const TabList = props => {

    const {t} = useTranslations('transfer', locale);

    return <Tabs id={"transfers"} unmountOnExit={true} mountOnEnter={true} defaultActiveKey={"open"}>

        <Tab eventKey="open" title={t('open_transfers')}>
            <List {...props} isClosed={false} id={"open-transfers"}/>
        </Tab>

        <Tab eventKey="close" title={t('closed_transfers')}>
            <List {...props} isClosed={true} id={"closed-transfers"}/>
        </Tab>

    </Tabs>

}

export default TabList;
