import {STERILIZATION_ID} from "./sterilization/isSterilization";
import {ANTI_RABIES_ID} from "./antiRabies/isAntiRabies";

const getInterventionPath = (props) => {

    const {intervention, isCreate} = props;

    const idPart = isCreate ? "" : intervention.transponder + "/" + intervention.id;

    switch (intervention.typeId) {
        case STERILIZATION_ID:
            return "/health-interventions/sterilization/" + idPart;
        case ANTI_RABIES_ID:
            return "/health-interventions/rabies/" + idPart;
        default:
            return "/health-interventions/" + idPart;
    }
};

export default getInterventionPath;
