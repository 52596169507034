import React, {useCallback, useMemo} from "react";
import ListComponent from "app/control/modules/preRegister/components/list/ListComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import useBulkUpdate from "app/control/modules/transfers/utils/useBulkUpdate";
import locale from "app/control/modules/preRegister/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import ValidationStatus from "types/ValidationStatus";

const List = (props) => {

    const {reloadBase, ids, isClosed} = props;
    const {t} = useTranslations('preRegister', locale);


    const {loading: accepting, handleBulkOperation} = useBulkUpdate({
        path: "/animals/pre-register/bulk-validate",
        t: t,
        reloadBase: reloadBase,
        prop: "ids",
    });

    return <GenericListContainer
        id={"pre-register-table"}
        {...props}
        module="preRegister"
        hideTitle={ids}
        locale={locale}
        usePost={true}
        searchPath={"/animals/pre-register/search"}
        queryParameters={useMemo(() => ({
            currentPage: 0,
            itemsPerPage: 10,
            sortProperty: "creationTimestamp",
            sortDirection: isClosed === false ? "ASC" : "DESC",
            isClosed: isClosed,
            ids: ids,
        }), [isClosed, ids])}>

        <ListComponent
            accepting={accepting}
            isClosed={isClosed}
            onAccept={useCallback((ids, reload) => handleBulkOperation(ids, reload, ValidationStatus.ACCEPTED), [handleBulkOperation])}
            onReject={useCallback((ids, reload, reason) => handleBulkOperation(ids, reload, ValidationStatus.REJECTED, reason), [handleBulkOperation])}
        />
    </GenericListContainer>

}

export default List;
