import {useCallback, useMemo} from "react";
import {useApiState} from "system/API/APIStateContext";
import countryList from "system/Countries/countries";
import appConfig from "configs/config";

const useCountryListOptions = () => {

    const {authentication} = useApiState();
    const language = authentication?.language ? authentication.language : appConfig.defaultLng;

    return useMemo(() => countryList.map(
            (country) => ({
                value: country.code,
                label: country.name[language],
            })),
        [language]
    );
}


const useCountryTranslation = () => {

    const {authentication} = useApiState();
    const language = authentication?.language ? authentication.language : appConfig.defaultLng;

    const countries = useMemo(() => countryList.reduce(
            (acc, country) => ({
                ...acc,
                [country.code]: country.name[language]
            }), {}),
        [language]
    );

    return useCallback((countryCode) => countries[countryCode], [countries]);


}

export default useCountryListOptions;
export {useCountryTranslation}
