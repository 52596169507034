export const pt = {

    generate_title: "Relatórios",
    title: "Lista de modelos de relatório",
    schedule_title: "Agendar relatório",
    generate_report: "Gerar relatório",
    edit_title: "Relatório {{name}}",
    create_title: "Criar modelo de relatório",
    template: "Relatório",
    edit_schedule_title: "Agendamento de relatorio {{name}}",
    report_params: "Parâmetros",


    create_schedule_title: "Criar agendamento de relatório",
    request_report: "Executar",
    preview_report: "Pré-Visualizar",
    report_result_one: "{{count}} resultado",
    report_result_other: "{{count}} resultados",

    placeholder: "Selecione uma opção",
    no_results: "Sem resultados",

    MALE: "Masculino",
    FEMALE: "Feminino",

    NORMAL: "Normal",
    DEAD: "Morto",
    LOST: "Animal desaparecido",
    FOUND: "Animal encontrado",

    FALSE: "Não",
    TRUE: "Sim",

    name: "Nome",
    category: "Categoria",
    entity_profiles: "Perfil de entidade",
    roles: "Perfil de permissão",
    entities: "Entidades",
    async: "Assíncrono",

    SUCCESS_SUBMIT_REPORT: "Relatório executado",
    SUCCESS_SUBMIT_ASYNC_REPORT: "Relatório em execução",
    SUCCESS_SUBMIT_REPORT_MSG: "O relatório foi executado com sucesso",
    SUCCESS_SUBMIT_ASYNC_REPORT_MSG: "O relatório vai ser executado dentro de momentos.\r\nIrá receberá um email quando o ficheiro com o resultado estiver disponível.",

    MISSING_PARAMETER: "Por favor indique um valor válido",
    INVALID_PARAMETER: "Por favor indique um valor",
    INVALID_PARAMETER_VALUE:  "Por favor indique um valor",

    DELETE_REPORT_CONFIRM: "Confirmação",
    DELETE_REPORT_CONFIRM_MSG: "Deseja realmente apagar o modelo de relatório {{name}}?",

    //form
    template_base_config: "Configuração base relatório",
    template_params_config: "Parâmetros",
    name_ph: "O nome do relatório",
    query: "Query",
    query_base: "Query base",
    query_restrictions: "Restrições de visibilidade",
    reference: "Referência (opcional)",
    reference_ph: "Referência na query principal",
    agreement: "Termos de aceitação",
    has_agreement: "Obriga aceitação",
    reject_legal_warning: "Rejeitar",
    accept_legal_warning: "Aceitar",
    enabled: "Ativo",
    true: "Sim",
    false: "Não",


    //Place holders
    date_ph: "Data no formato (dd-mm-yyyy)",

    //Schedule
    edit_schedule: "Editar agendamento",
    schedule_base_config: "Dados do agendamento",
    report_name: "Nome do relatório",
    operation_name: "Operação",
    recurrence: "Recorrência",
    next_generation: "Prox. execução",
    recurrence_monthly: "Dia do mês",
    recurrence_weekly: "Dia da semana",
    recurrence_daily: "Hora do dia",
    create_schedule: "Criar agendamento",
    save_schedule: "Guardar agendamento",

    DELETE_SCHEDULE_REPORT_CONFIRM: "Confirmação",
    DELETE_SCHEDULE_REPORT_CONFIRM_MSG: "Deseja realmente apagar o agendamento <strong>{{name}}?</strong>",

    //Recurrence
    DAILY: "Diária",
    WEEKLY: "Semanal",
    MONTHLY: "Mensal",

    //categories
    ANIMAL: "Animais",
    VETERINARY: "M. veterinários",
    OTHERS: "Outros relatórios",

    //parameters form
    param_type: "Tipo",
    label: "Nome / Descrição",
    mandatory: "Obrigatório",
    multiple_values: "Multiplos valores",
    query_condition: "Query parcial",
    label_ph: "Ex: entidades",
    condition_ph: "Introduza a query",
    generic_select_ph: "Escolha uma opção",
    save_form: "Guardar alterações",
    create_form: "Criar modelo relatório",
    edit_template: "Editar modelo",
    variable_name: "Nome da variável",
    variable_name_ph: "O nome da variável no stored procedure",
    version: "Versão ({{label}})",

    operation_result_message: "Sucesso a executar",
    operation_result_message_msg: "A operação foi executada com sucesso e produziu <strong>{{totalUpdates}} alterações</strong> na base de dados e gerou {{totalTables}} tabela(s) de resultado que pode(m) ser consultadas abaixo.",

    //Parameter types
    ANIMAL_VERSION: "Versão do animal",
    COUNTRY: "Lista de países",
    BOOLEAN: "Booleano",
    TEXT: "Texto",
    NUMERIC: "Número",
    DATE: "Data / Calendário",
    OWNER: "Titular",
    OWNER_VERSION: "Versão do titular",
    BREED: "Raça",
    BREED_GROUP: "Espécie / Grupo de Raça",
    BREED_TYPE: "Tipo de animal",
    ENTITY: "Entidade",
    GENDER: "Sexo",
    STERILIZATION_TYPE: "Tipo de esterilização",
    DISTRICT: "Distrito",
    COUNTY: "Concelho",
    PARISH: "Freguesia",
    ENTITY_PROFILE: "Perfil de entidade",
    ENTITY_TYPE: "Tipo de entidade",
    ANIMAL_STATUS: "Estado do animal",
    USER: "Utilizador",
    OPTIONAL: "Query parcial opcional",
    LOG_PARAMETER: "Param. para log",
    RABIES_VACCINE: "Vacina antirrábica",

    //Query Restrictions
    ENTITY_VISIBILITY: "Limitar a entidades com acesso",
    VETERINARY_VISIBILITY: "Limitar a  m. veterinários com acesso",
    ANIMAL_GEOFENCE: "Limitar por animal geofence",
    OWNER_GEOFENCE: "Limitar por geofence do titular",

    DELETE_FAILED: "Erro ao apagar",
    DELETE_SUCCESS: "Relatório apagado",
    DELETE_SUCCESS_MSG: "O relatório {{name}} foi apagado com sucesso",


    //template form errors
    MISSING_TYPE: "Por favor indique o tipo",
    MISSING_TEMPLATE_ID: "O template não está válido",
    INVALID_TEMPLATE_ID: "O template não está válido",
    MISSING_RECURRING_TIME: "A recorrência indicada não é válida",
    MISSING_NAME: "Por favor indique o nome do relatório",
    MISSING_ASYNC: "Por favor indique se o relatório é assincrono",
    MISSING_CATEGORY: "Por favor indique uma opção",
    MISSING_QUERY: "Por favor indique a query base a executar",
    MISSING_LABEL: "Por favor indique um nome / descrição",
    MISSING_CONDITION: "Por favor indique a condição",
    REPORT_QUERY_ERROR: "Foi detetado um problema no relatório. Por favor tente mais tarde ou contacte o suporte",
    MISSING_VARIABLE_NAME: "Por favor indique o nome da variável",

    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    CREATE_SUCCESS: "Criado com sucesso",
    CREATE_SUCCESS_MSG: "{{name}} criado com sucesso!",

    UPDATE_SUCCESS: "Atualizado com sucesso",
    UPDATE_SUCCESS_MSG: "{{name}} atualizado com sucesso!",
    PREVIEW_QUERY_ERROR: "Erro na query de pré-visualização",


    //Week days
    "WEEK_DAY_1": "Segunda-Feira",
    "WEEK_DAY_2": "Terça-Feira",
    "WEEK_DAY_3": "Quarta-Feira",
    "WEEK_DAY_4": "Quinta-Feira",
    "WEEK_DAY_5": "Sexta-Feira",
    "WEEK_DAY_6": "Sábado",
    "WEEK_DAY_7": "Domingo",

    file_format: "Formato do relatório",
    export_to: "Descarregar {{type}}",
    download_menu: "Opções de download",
    CSV: "Ficheiro CSV",
    XLSX: "Documento Excel (xlsx)",
    PDF: "Documento PDF",


};

export default pt;
