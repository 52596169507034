const TicketCategory = {
    STERILIZATION: "STERILIZATION",
    RABIES_PROPHYLAXIS: "RABIES_PROPHYLAXIS",
    BILLING: "BILLING",
    TRANSPONDER_ERROR: "TRANSPONDER_ERROR",
    CHANGE_OWNER: "CHANGE_OWNER",
    ANIMAL_OWNER_ERRORS: "ANIMAL_OWNER_ERRORS",
    TRACEABILITY: "TRACEABILITY",
    OTHER: "OTHER"
}

export default TicketCategory;
