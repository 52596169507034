import ManageOwnerAccessComponent from "app/owners/components/ownersApp/ManageOwnerAccessComponent";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useTranslation} from "react-i18next";
import {useCallback} from "react";
import {useMutate} from "system/Rest/Rest";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

const ManageOwnerAccessContainer = props => {

    const {owner, reload} = props;
    const showToast = useShowToast();
    const {t} = useTranslation('owner');

    const {mutate: activate, loading} = useMutate({
        verb: "POST",
        path: "/owners/" + owner?.id + "/create-account"
    });


    return <ManageOwnerAccessComponent
        {...props}
        loading={loading}
        onActivate={useCallback(
            () => {
                activate({}).then(result => {
                    showToast({
                        title: t('success_activate'),
                        message: t('success_activate_msg', result),
                        icon: faCheck,
                    });

                    reload();

                }).catch(error => {

                    showToast({
                        title: t('ERROR_ACTIVATING'),
                        message: t(error?.data?.message),
                        icon: faTimes,
                        className: 'error',
                    })

                });

            },
            [reload, showToast, t, activate]
        )}
    />


}

export default ManageOwnerAccessContainer;
