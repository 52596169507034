import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import Loading from "layout/modules/Loading/Loading";
import React, {useCallback, useMemo, useState} from "react";
import {readFileContent} from "system/Files/ReadFileContent";
import "layout/modules/Forms/MultipleFileUpload/assets/css/multiple-upload.scss";
import classnames from "classnames";

const MultipleFileUpload = props => {

    const {maxFiles = 10, acceptTypes, onFileUploaded, uploadBoxText, files, children, readOnly} = props;
    const [uploadingCounter, setUploadingCounter] = useState(0);

    const totalFiles = useMemo(() => files?.length ?? 0, [files]);

    const handlePhotoUpload = useCallback((nf) => {
        nf.forEach((file) => {
            setUploadingCounter((uc) => uc + 1);
            readFileContent(file, (content) => {
                onFileUploaded({
                    name: file.name,
                    type: file.type,
                    content: btoa(content)
                });
                setUploadingCounter((uc) => uc - 1);
            });
        })
    }, [setUploadingCounter, onFileUploaded])

    return <div className={"multiple-file-upload"}>
        <Loading visible={uploadingCounter > 0}/>

        {(totalFiles < maxFiles && !readOnly) &&
        <div className={"upload-area"}>
            <DragDropFile
                maxFiles={maxFiles - totalFiles}
                acceptTypes={acceptTypes}
                text={uploadBoxText}
                onFileUploaded={handlePhotoUpload}
            />
        </div>
        }

        {totalFiles > 0 &&
        <div className={classnames("file-list", { "view-mode": readOnly})}>

            {files.map((file, id) =>
                React.cloneElement(children, {
                    key: id,
                    id: id,
                    file: file,
                    readOnly: readOnly,
                })
            )}
        </div>
        }
    </div>
}

export default MultipleFileUpload;
