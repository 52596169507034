import classnames from "classnames";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodeBranch, faPencilAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loading from "layout/modules/Loading/Loading";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import "app/owners/assets/css/training.scss"
import TrainingVersionsContainer from "app/owners/containers/training/TrainingVersionContainer";

const TrainingComponent = (props) => {

    const {t} = useTranslation('owner');
    const {
        id,
        owner,
        training: originalTraining,
        errors = {},
        changes = {},
        fullChanged,
        loading,
        compareMode,
        onSubmit,
        onDelete,
    } = props;

    const [readOnly, setReadOnly] = useState(true);
    const [versionsVisible, setVersionsVisible] = useState(false);
    const [training, setTraining] = useState(null);
    const [isDeleteVisible, setDeleteVisible] = useState(false);

    //Update training
    useEffect(() => {
        if (originalTraining) {
            setTraining(originalTraining);
            setReadOnly(true);
        }
    }, [originalTraining]);


    if (!training || !originalTraining) {
        return <Loading visible={true}/>
    }

    return <div className={classnames("limited-width", {
        "view-mode": readOnly,
        "compare-mode": compareMode,
    })}>

        <GenericFormError t={t} errors={compareMode ? {} : errors}/>
        <Loading visible={loading}/>

        <PopupConfirm
            title={t('DELETE_TRAINING_CONFIRM')}
            message={t('DELETE_TRAINING_CONFIRM_MSG')}
            classConfirm={"danger"}
            visible={isDeleteVisible}
            onCancel={() => {
                setDeleteVisible(false);

            }}
            onConfirm={() => {
                onDelete();
                setDeleteVisible(false);
            }}
        />

        <FormHandleNotSaved
            remote={originalTraining}
            local={training}
            loading={loading}
            readOnly={readOnly}
            setLocal={setTraining}
            setReadOnly={setReadOnly}
            onSubmit={onSubmit}/>

        <TrainingVersionsContainer
            id={id}
            title={t('compare_training')}
            visible={versionsVisible}
            onClose={() => setVersionsVisible(false)}
            onRevert={(training) => {
                setReadOnly(false);
                setVersionsVisible(false);
                setTraining({
                    ...training
                });
            }}
        />

        <Form className={classnames({"full-changed": fullChanged})}>
            <Box>
                <BoxTitle>{t('training_data')}</BoxTitle>

                <BoxOptions visible={!compareMode}>

                    {Object.keys(originalTraining).length > 0 &&
                    <>
                        {!owner?.isLocked &&
                        <UserHasPermission permission="DELETE_OWNER_TRAINING">
                            <ToolTip content={t('delete_training')}>
                                <div className={classnames("btn-rounded-danger small", {"active": isDeleteVisible})}
                                     onClick={() => setDeleteVisible(true)}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </div>
                            </ToolTip>
                        </UserHasPermission>
                        }

                        <UserHasPermission permission="READ_VERSIONS">
                            <ToolTip content={t('compare_training')}>
                                <div className={classnames("btn-rounded-primary small", {"active": versionsVisible})}
                                     onClick={() => setVersionsVisible(true)}>
                                    <FontAwesomeIcon icon={faCodeBranch}/>
                                </div>
                            </ToolTip>
                        </UserHasPermission>
                    </>
                    }

                    {!owner?.isLocked &&
                    <UserHasPermission permission="WRITE_OWNER_TRAINING">
                        <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                             onClick={() => setReadOnly(!readOnly)}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </div>
                    </UserHasPermission>
                    }

                </BoxOptions>

                <Loading visible={loading}/>

                {(Object.keys(training).length === 0 && readOnly)
                    ? <div className={"no-training"}>{t('no_training')}</div>
                    : <Row className={"mandatory"}>
                        <Col sm={12} lg={6}>
                            <FormGroup error={errors['certificationNumber']} changes={changes['certificationNumber']}>
                                <Form.Label>{t('certification_number')}</Form.Label>
                                <FormControl
                                    type="text"
                                    id="certificationNumber"
                                    placeholder={t('certification_number_ph')}
                                    disabled={readOnly}
                                    value={getOrEmpty(training?.certificationNumber)}
                                    onChange={(e) => setTraining(training => ({
                                        ...training,
                                        certificationNumber: e.target.value,
                                    }))}

                                />
                                <Form.Text className="sub-error">
                                    {t(errors['certificationNumber'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>

                        <Col sm={12} lg={6}>
                            <FormGroup error={errors['certificationDate']} changes={changes['certificationDate']}>
                                <Form.Label>{t('certification_date')}</Form.Label>
                                <DatePicker
                                    name={"certificationDate"}
                                    placeholder={t("certification_date_ph")}
                                    disabled={readOnly}
                                    onlyPastDates={true}
                                    timezone={"UTC"}
                                    value={getOrEmpty(training?.certificationDate)}
                                    onChange={(date) => setTraining(training => ({
                                        ...training,
                                        certificationDate: date,
                                    }))}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['certificationDate'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>

                        <Col sm={12} lg={true}>
                            <FormGroup error={errors['trainingEntity']} changes={changes['trainingEntity']}>
                                <Form.Label>{t('training_entity')}</Form.Label>
                                <FormControl
                                    type="text"
                                    id="trainingEntity"
                                    placeholder={t('training_entity_ph')}
                                    value={getOrEmpty(training?.trainingEntity)}
                                    disabled={readOnly}
                                    onChange={(e) => setTraining(training => ({
                                        ...training,
                                        trainingEntity: e.target.value,
                                    }))}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['trainingEntity'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>
                    </Row>
                }
            </Box>

            {!readOnly &&
            <div>
                <Row>
                    <Col className={"text-end"}>
                        <UserHasPermission permission="WRITE_OWNER">
                            <Button variant="primary" className={"submit"} onClick={() => onSubmit(training)}>
                                {t("save_training")}
                            </Button>
                        </UserHasPermission>
                    </Col>
                </Row>
            </div>
            }

        </Form>
    </div>

}

export default TrainingComponent;
