import classnames from "classnames";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";

const GetOptionsComponent = (props) => {

    const {t} = useTranslation('certificate');
    const {request, setRequest} = props;

    return  <div className={"certificate-generate-options"}>

        <div
            className={classnames("option", {"active": !request.sendEmail})}
            onClick={useCallback(
                () => setRequest(request => ({
                    ...request,
                    sendEmail: false
                })),
                [setRequest]
            )}
        >
            {t('download')}
        </div>

        <div
            className={classnames("option", {"active": request.sendEmail})}
            onClick={useCallback(
                () => setRequest(request => ({
                    ...request,
                    sendEmail: true
                })),
                [setRequest]
            )}
        >
            {t('send_by_email')}
        </div>

    </div>

}

export default GetOptionsComponent;
