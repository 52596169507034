import {useCallback, useEffect, useRef, useState} from 'react';
import useEventListener from '@use-it/event-listener';
import createGlobalState from './createGlobalState';

const usePersistedState = (initialState, key, {get, set}) => {
    const globalState = useRef(null);
    const [state, setState] = useState(() => get(key, initialState));

    // subscribe to `storage` change events
    useEventListener('storage', ({key: k, newValue}) => {
        if (k === key) {
            const newState = JSON.parse(newValue);
            if (state !== newState) {
                setState(newState);
            }
        }
    });

    // only called on mount
    useEffect(() => {
        // register a listener that calls `setState` when another instance emits
        globalState.current = createGlobalState(key, setState, initialState);

        return () => {
            globalState.current.deregister();
        };
    }, [initialState, key]);

    const persistentSetState = useCallback(
        (newState) => {

            setState(state => {
                const newStateValue =
                    typeof newState === 'function' ? newState(state) : newState;
                asyncSaveStorage(globalState, set, key, newStateValue);
                return newStateValue;
            });
        },
        [set, key]
    );

    return [state, persistentSetState];
};


async function asyncSaveStorage(globalState, set, key, newValue) {
    set(key, newValue);
    globalState.current.emit(newValue);
}

export default usePersistedState;
