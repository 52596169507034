import {useLocation} from "react-router-dom";
import usePrevious from "system/Components/usePrevious";
import {useEffect} from "react";

const TableReloadWatcher = props => {

    const {reload} = props;
    const location = useLocation();
    const oldLocation = usePrevious(location);

    useEffect(
        () => {
            if (location?.pathname === oldLocation?.pathname
                && location?.key !== oldLocation?.key) {
                reload();
            }
        },
        [location, oldLocation, reload]
    )

    return <></>;

}

export default TableReloadWatcher;
