import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import ClosedLostContainer from "app/animals/containers/lost/ClosedLostContainer";
import classnames from "classnames";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locales from "app/animals/locales/locales";
import {Dropdown} from "react-bootstrap";
import UserHasPermission, {
    UserHasNoPermission,
    useUserHasPermission
} from "system/API/Authentication/UserHasPermission";
import AnimalLostClosedState, {AnimalLostPublicClosedState} from "types/AnimalLostClosedState";

const LostCloseButtonComponent = props => {

    const {animal} = props;
    const {t} = useTranslations("animal", locales);
    const userHasPermission = useUserHasPermission();


    return <AnimalOperationAllowed animal={animal} operation={AnimalOperations.SET_DELIVERED}>
        <ClosedLostContainer {...props} transponder={animal.transponder}>
            {({setVisible, active}) =>
                <>
                    <UserHasPermission permission="SET_CUSTOM_LOST_ANIMAL_STATUS">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" className={classnames({'active': active})}>
                                {t('close_process')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {Object.keys(AnimalLostClosedState).map(key =>
                                    <Dropdown.Item onClick={() => setVisible(key)} key={key}>
                                        {t(key.toLowerCase())}
                                    </Dropdown.Item>
                                )}

                            </Dropdown.Menu>
                        </Dropdown>
                    </UserHasPermission>
                    <UserHasNoPermission permission="SET_CUSTOM_LOST_ANIMAL_STATUS">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" className={classnames({'active': active})}>
                                {t('close_process')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {Object.keys(AnimalLostPublicClosedState)
                                    .filter(key => key !== AnimalLostPublicClosedState.DEAD || userHasPermission({permission: "WRITE_ANIMAL_DEAD"}))
                                    .map(key =>
                                    <Dropdown.Item onClick={() => setVisible(key)} key={key}>
                                        {t(key.toLowerCase())}
                                    </Dropdown.Item>
                                )}

                            </Dropdown.Menu>
                        </Dropdown>
                    </UserHasNoPermission>
                </>
            }

        </ClosedLostContainer>
    </AnimalOperationAllowed>

}

export default LostCloseButtonComponent;
