import React, {useCallback} from "react";
import {useMutate} from "system/Rest/Rest"
import FormComponent from "app/owners/components/form/FormComponent"
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import Loading from "layout/modules/Loading/Loading";
import useGenericFormSubmit, {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";

const FormContainer = (props) => {

    const {owner, loading, id, reload} = props;
    const showToast = useShowToast();

    const {t} = useTranslation('owner');

    const {onSubmit, loading: submitting, error: errorSubmitting} = useGenericFormSubmit({
        id: id,
        apiBasePath: "/owners/",
        redirectBasePath: useCallback((owner) => "/owner/" + owner?.id,[]),
        translation: "owner",
        reload: reload,
    })

    const {mutate: lockOwner, loading: locking, error: errorLocking} = useMutate({
        verb: "PUT",
        path: "/owners/" + id + "/lock",
    });

    const errors = useErrorParser(errorSubmitting ? errorSubmitting : errorLocking);

    const handleOwnerLock = useCallback(
        (props) => {
            lockOwner({
                ...props

            }).then(() => {
                reload();
                showToast({
                    title: t('SUCCESS_LOCKING_OWNER'),
                    message: t(props.isLocked || props.editLocked
                        ? 'SUCCESS_LOCKING_OWNER_MSG'
                        : 'SUCCESS_UNLOCKING_OWNER_MSG', {name: owner?.name}),
                    icon: faCheck,
                });
            }).catch(error => handleUnknownFormError(error, showToast, t));
        },
        [lockOwner, showToast, t, owner?.name, reload]
    );

    if (!owner && id) {
        return <Loading visible={true}/>
    }

    return <FormComponent
        {...props}
        errors={errors}
        create={!id}
        loading={loading || submitting || locking}
        onLock={handleOwnerLock}
        onSubmit={onSubmit}
    />
}

export default FormContainer;
