import ListMenuOptionsComponent from "app/animals/components/list/ListMenuOptionsComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const CardOptionsComponent = (props) => {

    const {animal, t} = props;

    return <div className={"animal-card-box-buttons"}>
        <ListMenuOptionsComponent {...props} animal={animal} placement={"top"}>
            <div className={"button"}>
                <div>{t('table_menu_options')}</div>
                <FontAwesomeIcon icon={faChevronUp}/>
            </div>
        </ListMenuOptionsComponent>
    </div>
}

export default CardOptionsComponent;
