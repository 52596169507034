import Box from "../Box/Box";
import React from "react";
import classnames from "classnames";
import "./step-box.scss";

const StepBox = (props) => {

    const {step, children, active = false, done = false} = props;

    return <Box className={classnames("step-box",{
        'active': active,
        'done': done
    })}>
        <div className={"step-box-number"}>
            {step}
        </div>
        <div className={"step-box-description"}>
            {children}
        </div>

    </Box>

}

export default StepBox;
