import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React from "react";
import {useTranslation} from "react-i18next";
import useHandleFormSubmit from "system/Forms/FormHandling";
import usePasswordWithRepetitionValidator from "app/login/utils/validators";
import Loading from "layout/modules/Loading/Loading";
import Box from "layout/modules/Box/Box";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";

const PasswordComponent = (props) => {

    const {
        loading,
        onPasswordSubmit,
        errors,
    } = props

    const {t} = useTranslation('personalArea');
    const handlePasswordSubmit = useHandleFormSubmit(onPasswordSubmit)
    const [passwords, setPasswords] = usePasswordWithRepetitionValidator();

    return <Form autoComplete="off" onSubmit={handlePasswordSubmit}>
        <Box className={"mandatory"}>

            <Loading visible={loading}/>

            <GenericFormError t={t} errors={errors}/>


            <Row>
                <Col sm={12} lg={6}>
                    <FormGroup error={errors['oldPassword']}>
                        <Form.Label>{t('password')}</Form.Label>
                        <FormControl
                            type="password"
                            id="oldPassword"
                            placeholder={t('password_tip')}
                        />
                        <Form.Text className="sub-label">
                            {t(errors['oldPassword'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </Row>
            <Row>

                <Col sm={12} lg={6}>
                    <FormGroup error={passwords.errors.password}>
                        <Form.Label>{t('new_password')}</Form.Label>
                        <FormControl
                            type="password"
                            id="newPassword"
                            placeholder={t('new_password_tip')}
                            onChange={(e) => setPasswords(e.target.value, passwords.repetition)}
                            value={passwords.password}
                        />
                        <Form.Text
                            className="sub-label password-tip">
                            {passwords?.errors.password
                                ? <>
                                    {t('invalid_password')}
                                    {passwords?.errors.list.map(error =>
                                        <div key={error} className={"error-detail"}>{t(error)}</div>
                                    )}
                                </>
                                : ''}

                        </Form.Text>
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>

                    <FormGroup error={passwords.errors.repetition}>
                        <Form.Label>{t('new_password_confirm')}</Form.Label>
                        <FormControl
                            type="password"
                            id="newPasswordConfirm"
                            placeholder={t('new_password_confirm_tip')}
                            value={passwords.repetition}
                            onChange={(e) => setPasswords(passwords.password, e.target.value)}
                        />

                        <Form.Text className="sub-label">
                            {passwords.errors.repetition ? t('invalid_confirm_password') : ''}
                        </Form.Text>
                    </FormGroup>
                </Col></Row>
        </Box>

        <Row>
            <Col className="text-end">

                <Button variant="primary" type="submit" disabled={!passwords.areValid}>
                    {t('set_new_password')}
                </Button>
            </Col>
        </Row>

    </Form>
}

export default PasswordComponent;
