import locale from "../locales/locales";
import {useMutate} from "system/Rest/Rest";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback} from "react";
import {Navigate} from "react-router-dom";
import useTranslations from "system/Translations/UseTranslations";
import {useApiState} from "system/API/APIStateContext";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import AssociatedEntitiesComponent from "app/login/components/veterinaries/AssociatedEntitiesComponent";

const AssociatedEntitiesContainer = (props) => {

    const {loadingEntities: loading, entities, onReFetchEntities} = props;

    const {t} = useTranslations('login', locale);

    const {authentication} = useApiState();
    const showToast = useShowToast();

    const {mutate: associate, loading: associating} = useMutate({
        verb: "PUT",
        path: "/veterinaries/associate-entities"
    });

    const handleAccept = useCallback(
        (entityId, data) => {
            associate({
                accepted: true,
                entityId: entityId,

            }).then(() => {
                onReFetchEntities();
                showToast({
                    title: t('SUCCESS_ASSOCIATION'),
                    message: t('SUCCESS_ASSOCIATION_MSG', {name: data.name}),
                    icon: faCheck,
                })

            });
        },
        [associate, showToast, onReFetchEntities, t]
    );

    const handleDisassociate = useCallback(
        (entityId, status, data) => {

            associate({
                accepted: false,
                entityId: entityId,
            }, {
                path: (status === "ACCEPTED")
                    ? "/veterinaries/remove-associated-entity"
                    : "/veterinaries/associate-entities"
            }).then(() => {
                onReFetchEntities();
                showToast({
                    title: t('SUCCESS_DELETE_ASSOCIATION'),
                    message: t('SUCCESS_DELETE_ASSOCIATION_MSG', {name: data.name}),
                    icon: faCheck,
                })

            }).catch((error) => {
                showToast({
                    title: t('ERROR_DELETE_ASSOCIATION'),
                    message: t(error?.data?.message),
                    icon: faTimes,
                    className: 'error',
                })
            });
        },
        [associate, onReFetchEntities, showToast, t]
    );

    if (authentication?.sessionExpired) {
        return <Navigate to={'/auth/'}/>
    }

    return <AssociatedEntitiesComponent
        {...props}
        data={entities}
        loading={loading || associating}
        onDisassociate={handleDisassociate}
        onAccept={handleAccept}

    />

}

export default AssociatedEntitiesContainer;
