import React, {Suspense, useCallback, useMemo, useRef, useState} from "react";
import Select from "react-select";
import Loading from "layout/modules/Loading/Loading";
import Col from "react-bootstrap/Col";
import DownloadContainer from "app/files/containers/DownloadContainer";

import "app/control/assets/css/file-preview.scss";
import lazyWithRetry from "system/System/lazyWithRetry";
import ErrorBoundary from "system/Error/ErrorBoundary";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {useDateTime} from "system/DateTime/DateTime";
import FileDeleteComponent from "app/control/components/files/FileDeleteComponent";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const FilePreview = lazyWithRetry(() => import("layout/modules/FilePreview/FilePreview"));

const FilePreviewComponent = (props) => {

    const {request, t} = props;
    const getDateAndTime = useDateTime("DD-MM-YYYY HH:mm:ss");

    const [selectedFile, setSelectedFile] = useState(0);
    const refDelete = useRef();

    const options = useMemo(
        () => request?.documents?.map((document, id) => ({
            value: id,
            label: document?.title
                ? document.title + " (" + getDateAndTime(document.creationTimestamp) + ")"
                : document.filename + " (" + getDateAndTime(document.creationTimestamp) + ")"
        })),
        [request?.documents, getDateAndTime]
    );

    const handleNoOptions = useCallback(() => t('no_file_found'), [t])
    const handleOnChange = useCallback(
        (option) => setSelectedFile(option.value),
        [setSelectedFile]
    );

    const [download, setDownload] = useState(null);
    const handleDownloadComplete = useCallback(() => setDownload(null), [setDownload]);

    return <Col lg={6} md={0} sm={0}>

        {download &&
            <DownloadContainer file={download} onDownloadComplete={handleDownloadComplete}/>
        }

        <UserHasPermission permission="DELETE_DOCUMENTS_WITH_OBS">
            <FileDeleteComponent {...props} ref={refDelete} request={request}
                                 selectedFile={request?.documents ? request?.documents[selectedFile] : undefined } />
        </UserHasPermission>


        <ErrorBoundary fallback={<AdvanceInfoMessage
            icon={faBan}
            type={"danger"}
            iconType={"danger"}
            title={t("error_loading_preview")}
            message={t('error_loading_preview_msg')}
        />}>


            <div className={"control-file-preview"}>
                <div className={"file-selection"}>
                    <div className={"file-selection-label"}>
                        {t('select_file', {count: options?.length})}
                    </div>
                    <div className={"file-selection-dropdown"}>
                        <Select
                            classNamePrefix="rs"
                            className="rs"
                            placeholder={t('select_file_ph')}
                            noOptionsMessage={handleNoOptions}
                            defaultValue={options?.length > 0 ? options[0] : null}
                            options={options}
                            onChange={handleOnChange}
                            isClearable={false}
                        />
                    </div>

                </div>

                <div className={"documents"}>

                    {options?.length > 0 &&
                        <Suspense fallback={<Loading visible={true}/>}>
                            <FilePreview
                                type={request?.documents[selectedFile].type}
                                content={request?.documents[selectedFile].contents}
                                onDownload={() => setDownload(request?.documents[selectedFile])}
                                onDelete={refDelete.current?.handleDelete}
                            />
                        </Suspense>
                    }
                </div>

            </div>
        </ErrorBoundary>
    </Col>
}

export default FilePreviewComponent;
