import {useCallback} from "react";
import {useNavigate} from "react-router";

const useAnimalListClick = (animal) => {

    const navigate = useNavigate();

    return useCallback(
        () => {
            if (!animal?.isExternal) {
                if (animal.isPreRegister)
                    navigate('/pre-registers/' + animal.id)
                else
                    navigate('/animal/view/' + animal.transponder)
            }
        },
        [animal?.isExternal, animal?.transponder, animal.isPreRegister, animal.id, navigate]
    );
}

export default useAnimalListClick;
