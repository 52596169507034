import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import classnames from "classnames";
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import Loading from "layout/modules/Loading/Loading";
import AssociateNewEntityContainer from "app/login/containers/AssociateNewEntityContainer";
import Box from "layout/modules/Box/Box";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import AssociatedEntitiesContainer from "app/login/containers/AssociatedEntitiesContainer";
import 'app/login/assets/css/entity-management.scss';
import 'app/login/assets/css/entity-management-responsive.scss';

const VeterinaryEntitiesComponent = (props) => {

    const {t} = useTranslation('personalArea');
    const {loadingEntities, entities} = props;

    const [isPopupVisible, setPopupVisible] = useState(false);
    if (loadingEntities && !entities) {
        return <Loading visible={true}/>
    }

    return <>
        <Modal show={isPopupVisible}
               onHide={() => setPopupVisible(false)}
               size={"xl"}
               backdrop="static"
               keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('access_request')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AssociateNewEntityContainer
                    {...props}
                    login={false}
                    onAssociate={() => {
                        setPopupVisible(false);
                    }}
                />
            </Modal.Body>
        </Modal>

        <Box className={"personal-entities"}>
            <BoxOptions>
                <div className={classnames("btn-rounded-primary small", {"active": isPopupVisible})}
                     onClick={() => setPopupVisible(!isPopupVisible)}>
                    <FontAwesomeIcon icon={faPlus}/>
                </div>
            </BoxOptions>
            <AssociatedEntitiesContainer {...props} login={false}/>
        </Box>
    </>

}

export default VeterinaryEntitiesComponent;
