import React, {useCallback} from "react";
import useLockAnimal from "app/animals/utils/useLockAnimal";
import useValidateTransponder from "app/animals/utils/useValidateTransponder";
import {useBillingBalanceState} from "modules/Billing/BillingStateContext";
import useGenericFormSubmit from "modules/Forms/Utils/useGenericFormSubmit";
import useErrorParser from "system/API/Error/ErrorParser";
import Loading from "layout/modules/Loading/Loading";
import FormComponent from "app/animals/components/form/FormComponent";

const FormContainer = (props) => {

    const {animal, loading, id, reload} = props;

    const {loading: locking, error: errorLocking, onLock} = useLockAnimal(animal?.id, id, reload);

    const {validationStatus, onValidateTransponder, onInvalidateTransponder, loading: validating} = useValidateTransponder();
    const billingState = useBillingBalanceState();

    const {onSubmit, loading: submitting, error: errorSubmitting} = useGenericFormSubmit({
        id: id,
        apiBasePath: "/animals/",
        redirectBasePath: useCallback((d) => "/animal/view/" + d?.transponder, []),
        redirectEditPath: "/animal/view/" + id,
        translation: 'animal',
        onSuccess: billingState.reload,
        reload: reload
    });

    const errors = useErrorParser(errorSubmitting ? errorSubmitting : errorLocking);

    if (!animal && id) {
        return <Loading visible={true}/>
    }

    return <FormComponent
        {...props}
        errors={errors}
        create={!id}
        loading={loading || submitting || locking}
        onValidateTransponder={onValidateTransponder}
        onInvalidateTransponder={onInvalidateTransponder}
        validating={validating}
        validationStatus={validationStatus}
        onLock={onLock}
        onSubmit={onSubmit}
    />


}

export default FormContainer;
