const generateHashCode = (val) => {

    if (!val) {
        return "";
    }

    let hash = 0, i,chr;
    const value = JSON.stringify(val);

    if (!value) return hash;
    for (i = 0; i < value.length; i++) {
        chr   = value.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export default generateHashCode;
