const entityResolver = (data) => ({
    ...data,
    parentId: data.parent?.id,
    parishId: data.parish?.id,
    priceTableId: data.priceTable?.id,
    geofences: data.geofences?.map((entry) => ({
        districts: entry.districts.map(district => district.id),
        counties: entry.counties.map(county => county.id),
        parishes: entry.parishes.map(parish => parish.id),
    }))
});

export default entityResolver;
