import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import LegalWarningComponent from "layout/modules/LegalWarning/LegalWarningComponent";
import {useGet} from "system/Rest/Rest";

const ProofOfLifeContainer = (props) => {

    const {t} = useTranslation("health-intervention");
    const {intervention, setIntervention} = props;
    const [isLegalOpen, setLegalOpen] = useState(true);

    useEffect(
        () => {
            setIntervention(intervention => ({
                ...intervention,
                lockSubmit: true,
            }))
            return () => {
                setIntervention(intervention => ({
                    ...intervention,
                    lockSubmit: undefined,
                }))
            }
        },
        [setIntervention]
    );


    useEffect(
        () => {
            if (!intervention?.executionDate) {
                setIntervention(intervention => ({
                    ...intervention,
                    executionDate: new Date().getTime()
                }))
            }
        },
        [intervention?.executionDate, setIntervention]
    )

    const {data: legalWarningData} = useGet({
        path: "/health-interventions/proof-of-life-disclaimer",
    });


    if (isLegalOpen && legalWarningData) {
        return <LegalWarningComponent
            {...props}
            size={"xs"}
            t={t}
            legalWarning={legalWarningData}
            labelAccept={"accept_proof_of_life"}
            labelReject={"cancel_proof_of_life"}
            loading={false}
            onAccept={() => {
                setLegalOpen(false);
                setIntervention(intervention => ({
                    ...intervention,
                    lockSubmit: undefined
                }));
            }}
            onReject={() => {
                setLegalOpen(false);
                setIntervention(intervention => ({
                    ...intervention,
                    lockSubmit: true
                }));
            }}
        />;
    }

}

export default ProofOfLifeContainer;
