import {useBlocker} from 'react-router-dom';
import {useEffect} from "react";

export function useBlockingRouter(active = true, callback) {

    const blocker = useBlocker(active);

    useEffect(
        () => {
            if (blocker.state === "blocked" && active) {
                callback(blocker.proceed, blocker.reset);
            }
        },
        [blocker, callback, active]
    );

}

export default useBlockingRouter;
