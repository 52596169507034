import React, {useMemo} from 'react'
import GenericRemoteClassDropdown from "../GenericRemoteClassDropdown"

const FinancingEntitiesDropdown = (props) => {

    return <GenericRemoteClassDropdown
        {...props}
        remotePath={"/health-interventions/financing-entities"}
        classLabel={"name"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: 50,
        }),[])}
    />

}

export default FinancingEntitiesDropdown;
