import React from "react";
import UserPropertiesComponent from "app/home/components/user/UserPropertiesComponent";
import "app/home/assets/css/user-details.scss";

const UserDetailsComponent = props => {

    return <div className={"dashboard-user-details"}>
        <UserPropertiesComponent {...props} />
    </div>
}

export default UserDetailsComponent;
