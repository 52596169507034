import React from "react";
import "app/notifications/assets/css/list.scss";
import List from "app/notifications/List";

const NotificationComponent = props => {

    return <List dashboard={true}/>

}

export default NotificationComponent;
