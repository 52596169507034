import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";

const GeofenceFormComponent = React.forwardRef((props, ref) => {

    const {
        t,
        entry = {},
        globalError,
        districts,
        readOnly,
        loading,
        onChange,
        onDelete,
        errors,
        isMulti = true,
        mandatory = [],
        ignoreSubmit
    } = props


    return <Row>
        <Col sm={12} lg={3} xl={3}>
            <FormGroup
                error={errors['districts'] || globalError}
                filter-hidden={entry.counties}
                mandatory={mandatory['district']}
            >

                <Form.Label>{t('district')}</Form.Label>
                <EnumDropdown
                    ref={ref}
                    isLoading={loading}
                    id={ignoreSubmit ? undefined : 'districts'}
                    isMulti={isMulti}
                    isDisabled={readOnly}
                    options={districts}
                    value={getOrNull(entry?.districts)}
                    onChange={(val) => onChange({
                        districts: val,
                        counties: null,
                        parishes: null,
                    })}
                />

                <Form.Text className="sub-error">
                    {t(errors['districts'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={3} xl={3}>
            <FormGroup
                error={errors['counties'] || globalError}
                filter-hidden={entry.parishes}
                mandatory={mandatory['county']}
            >

                <Form.Label>{t('county')}</Form.Label>
                <GenericRemoteClassDropdown
                    remotePath={"/address/search-county"}
                    universalSearch={"name"}
                    id={ignoreSubmit ? undefined : 'counties'}
                    isMulti={isMulti}
                    value={getOrNull(entry?.counties)}
                    isDisabled={readOnly || !(entry?.districts?.length > 0)}
                    searchFilter={{
                        sortProperty: 'name',
                        sortDirection: 'ASC',
                        currentPage: 0,
                        itemsPerPage: 20,
                        districtIds: entry?.districts,
                    }}
                    onChange={(value) => onChange({
                        districts: entry?.districts,
                        counties: value,
                        parishes: null,
                    })}
                />

                <Form.Text className="sub-error">
                    {t(errors['counties'])}
                </Form.Text>
            </FormGroup>



        </Col>

        <Col xs={12} sm={true} lg={onDelete ? 5 : 6}>

            <FormGroup error={errors['parishes'] || globalError} mandatory={mandatory['parish']}>
                <Form.Label>{t('parish')}</Form.Label>
                <GenericRemoteClassDropdown
                    isMulti={isMulti}
                    remotePath={"/address/search-parish"}
                    universalSearch={"name"}
                    id={ignoreSubmit ? undefined : 'parishes'}
                    isDisabled={readOnly || !entry?.counties?.length > 0}
                    value={getOrNull(entry?.parishes)}
                    searchFilter={{
                        sortProperty: 'name',
                        sortDirection: 'ASC',
                        currentPage: 0,
                        itemsPerPage: 20,
                        countyIds: entry?.counties,
                    }}
                    onChange={(value) => onChange({
                        districts: entry?.districts,
                        counties: entry?.counties,
                        parishes: value
                    })}
                />
                <Form.Text className="sub-error">
                    {t(errors['parishes'])}
                    {t(globalError)}
                </Form.Text>
            </FormGroup>

        </Col>

        {(onDelete && !readOnly) &&
        <Col xs={7} sm={7} lg={1} className="align-self-center">
            <div className={"btn-rounded-danger ms-auto"} onClick={onDelete}>
                <FontAwesomeIcon icon={faTrash}/>
            </div>
        </Col>
        }
    </Row>
});

export default GeofenceFormComponent;
