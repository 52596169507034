import {useMenuState} from "../MenuStateContext";
import React, {useMemo} from "react";
import getAsArray from "../../../../../system/Arrays/getArray";
import {useIsMenuSelected} from "../utils/Utils";
import UserHasPermission from "../../../../../system/API/Authentication/UserHasPermission";
import MultipleMenuItemComponent from "../components/MultipleMenuItemComponent";

const MultipleMenuItem = (props) => {

    const {label, children} = props;
    const [menuState, setMenuState] = useMenuState();
    const forceClose = (menuState.active !== label && menuState.active);
    const isActive = (menuState.active === label);

    const setActive = (state) => {
        setMenuState(menuState => ({
            ...menuState,
            active: state ? label : null,
        }))
    }

    //Extract all permissions
    const permissions = useMemo(() =>
            getAsArray(children).reduce((permissions, subMenu) => {
                return getAsArray(subMenu?.props?.children).reduce((p, subMenuItem) => {
                    return p.concat(subMenuItem?.props?.permissions);
                }, permissions)
            }, []).filter(item => item !== undefined),

        [children]);

    //Extract all permissions
    const orUserTypes = useMemo(() =>
            getAsArray(children).reduce((userTypes, subMenu) => {
                return getAsArray(subMenu?.props?.children).reduce((p, subMenuItem) => {
                    return p.concat(subMenuItem?.props?.orUserTypes);
                }, userTypes)
            }, []).filter(item => item !== undefined),

        [children]);

    //Extract all paths
    const paths = useMemo(() =>
            getAsArray(children).reduce((paths, subMenu) => {
                return getAsArray(subMenu?.props?.children).reduce((p, subMenuItem) => {
                    return p.concat(subMenuItem?.props?.path);
                }, paths)
            }, []),

        [children]);

    const isSelected = useIsMenuSelected(paths);

    return <UserHasPermission permission={permissions} orUserTypes={orUserTypes} any={true}>
        <MultipleMenuItemComponent
            {...props}
            forceClose={forceClose}
            setActive={setActive}
            isActive={isActive}
            currentActive={menuState.active}
            isSelected={isSelected}
        />
    </UserHasPermission>

}


export default MultipleMenuItem;
