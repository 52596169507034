import {Tab as BootstrapTab} from "react-bootstrap";
import {useIsMobile} from "system/Window/Mobile";

const Tab = (props) => {

    const isMobile = useIsMobile();

    if (!isMobile) {
        return <BootstrapTab {...props}/>
    } else return <></>


}

export default Tab;
