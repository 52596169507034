import Modal from "react-bootstrap/Modal";
import React from "react";
import {useTranslation} from "react-i18next";
import PersonalDataComponent from "./PersonalDataComponent";
import AddressComponent from "./AddressComponent";
import ContactsComponent from "./ContactsComponent";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import Collapse from "layout/modules/Collapse/Collapse";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import TypeComponent from "app/owners/components/form/TypeComponent";
import {useGetDiffs} from "modules/VersionsContainer/utils/compare";
import "app/owners/assets/css/owner-merge.scss";

const OwnerMergeComponent = (props) => {

    const {onAccept, onReject, onClose, originalOwner, remoteOwner, autoFilledOwner, showPopup} = props
    const {t} = useTranslation('owner');

    const diffs = useGetDiffs(originalOwner, remoteOwner);

    if (showPopup) {

        return <Modal show={true} onHide={onClose}
                      size={"xl"} className={"merge-window dark"} backdrop="static"
                      keyboard={false}>

            <Modal.Header closeButton>
                <Modal.Title>{t('OWNER_MERGE_TITLE')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div>
                    <AdvanceInfoMessage
                        icon={faInfo}
                        type={"success"}
                        iconType={"success"}
                        title={t("OWNER_MERGE_TITLE")}
                        message={t('OWNER_MERGE_MSG', {vatNumber: remoteOwner?.vatNumber, name: remoteOwner?.name})}
                    />

                    <Row>
                        <Col md={6} sm={12} className={"owner-merge-block versions current-owner"}>
                            <OwnerBlockComponent t={t} owner={originalOwner} title={t('current_owner')} diffs={diffs}/>
                        </Col>

                        <Col md={6} sm={12} className={"owner-merge-block versions"}>
                            <OwnerBlockComponent t={t} owner={remoteOwner} title={t('remote_owner')} diffs={diffs}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} sm={12} className={"onwer-buttons"}>
                            <div className={"block-button"}>
                                <Button variant="success" className={"merge-accept-button"}
                                        onClick={() => onAccept(false)}>
                                    {t('owner_merge_accept')}
                                </Button>
                            </div>
                        </Col>

                        <Col md={6} sm={12} className={"onwer-buttons"}>
                            <div className={"block-button"}>
                                <Button variant="success" className={"merge-accept-button"}
                                        onClick={() => onAccept(true)}>
                                    {t('owner_merge_accept_remote')}
                                </Button>
                            </div>

                        </Col>

                    </Row>

                </div>


            </Modal.Body>

            <ModalButtons>
                <div className={"owner-merge-buttons buttons"}>
                    <Button variant="danger" onClick={onReject}>
                        {t('owner_merge_reject')}
                    </Button>

                    <div>
                        <Button variant="secondary" onClick={onClose}>
                            {t('cancel')}
                        </Button>

                    </div>

                </div>

            </ModalButtons>


        </Modal>
    } else if (autoFilledOwner) {
        return <AdvanceInfoMessage
            icon={faInfo}
            type={"success"}
            iconType={"success"}
            title={t("OWNER_DATA_LOADED")}
            message={t('OWNER_DATA_LOADED_MSG', {name: autoFilledOwner.name})}
        />
    }

    return <></>

}


const OwnerBlockComponent = (props) => {

    const {t, owner, title, diffs} = props;

    return <div className={"view-mode owner-block compare-mode"}>
        <Collapse title={title} open={true}>

            <Box>
                <BoxTitle>{t('owner_type')}</BoxTitle>
                <TypeComponent
                    owner={owner}
                    readOnly={true}
                    changes={diffs}
                    create={true}
                />
            </Box>

            <PersonalDataComponent
                owner={owner}
                readOnly={true}
                errors={diffs}
            />

            <AddressComponent
                owner={owner}
                readOnly={true}
                errors={diffs}
            />

            <ContactsComponent
                owner={owner}
                readOnly={true}
                errors={diffs}
            />

        </Collapse>
    </div>

}


export default OwnerMergeComponent;
