import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import AnimalLostClosedState from "types/AnimalLostClosedState";
import CloseAsDeadComponent from "app/animals/components/lost/show/closeComponents/CloseAsDeadComponent";
import Loading from "layout/modules/Loading/Loading";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import ClosedAsTransferredComponent from "app/animals/components/lost/show/closeComponents/CloseAsTransferredComponent";

const ClosedLostComponent = React.forwardRef((props, ref) => {

    const {t} = useTranslation("animal");
    const {transponder, onSubmit, loading} = props;

    const userHasPermission = useUserHasPermission();
    const [status, setStatus] = useState(null);
    const [isVisible, setLostVisible] = useState(false);

    const handleClose = useCallback(() => {
        setStatus(null);
        setLostVisible(false);
    }, [setLostVisible, setStatus]);

    const handleVisible = useCallback((status) => {
        setStatus({status: status});
        setLostVisible(true);
    }, [setStatus, setLostVisible]);

    //Add setVisible to the ref
    useEffect(() => {
        if (ref) {
            ref.current = {
                setVisible: handleVisible
            }
        }
    }, [ref, handleVisible]);

    return <>

        {props.children && props.children({
            setVisible: handleVisible,
            active: isVisible,
        })}


        <Loading visible={loading}/>

        {(status?.status && isVisible) &&

            <>
                {(userHasPermission({permission: 'SET_CUSTOM_LOST_ANIMAL_STATUS'})
                    || [AnimalLostClosedState.DELIVERED_TO_OWNER, AnimalLostClosedState.DEAD].includes(status?.status))
                    ? <PopupConfirm
                        title={t('LOST_ANIMAL_CLOSE')}
                        message={t('LOST_ANIMAL_CLOSE_MSG', {
                            transponder: transponder,
                            status: t(status.status?.toLowerCase()).toLowerCase()
                        })}
                        classConfirm={"success"}
                        visible={isVisible}
                        onCancel={handleClose}
                        onConfirm={() => {
                            onSubmit(status);
                            handleClose();
                        }}
                    >

                        {status?.status === AnimalLostClosedState.DEAD &&
                            <CloseAsDeadComponent transponder={transponder} status={status} setStatus={setStatus}/>
                        }
                    </PopupConfirm>
                    : <ClosedAsTransferredComponent
                        {...props}
                        handleClose={handleClose}
                        type={status?.status}
                    />
                }


            </>

        }
    </>
});

export default ClosedLostComponent;
