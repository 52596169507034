import MainListComponent from "./components/list/MainListComponent";
import React from "react";
import locale from "./locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";

const AnimalList = (props) => {

    const {t} = useTranslations('animal', locale);

    return <Title title={t('list')} breadcrumbs={[{href: "/animal", label: t('list')}]} {...props}>
        <MainListComponent {...props}/>
    </Title>
}

export default AnimalList;
