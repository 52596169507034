import React, {createContext, useEffect, useMemo} from "react"
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {useApiState} from "system/API/APIStateContext";
import Poll from "system/Rest/Poll";


const BillingBalanceStateContext = createContext({});

function useBillingBalanceState() {
    const context = React.useContext(BillingBalanceStateContext)
    if (!context) {
        throw new Error(`useAnimalState must be used within a AnimalStateContext`)
    }
    return context
}


const BillingStateProvider = (props) => {

    const userHasPermission = useUserHasPermission();
    const {authentication} = useApiState();

    const canReadBalance = useMemo(
        () => userHasPermission({permission: "READ_BALANCE"})
            && authentication?.entityProfile?.isBillable,
        [userHasPermission, authentication?.entityProfile?.isBillable]
    );

    const canRegisterNotBillableAnimal = useMemo(
        () => userHasPermission({permission: "REGISTER_FREE_TRANSPONDER"})
            && authentication?.entityProfile?.isBillable,
        [userHasPermission, authentication?.entityProfile?.isBillable]
    );

    return <Poll
        path={"/billing/balance"}
        localErrorOnly={true}
        lazy={!canReadBalance}
        interval={120 * 1000}>
        {(data, refetch) =>
            <ContextProvider
                data={data}
                refetch={refetch}
                entityId={authentication?.entity?.id}
                canRegisterNotBillableAnimal={canRegisterNotBillableAnimal}
                canReadBalance={canReadBalance}>
                {props.children}
            </ContextProvider>
        }
    </Poll>
}

const ContextProvider = (props) => {

    const {data, refetch, canReadBalance, canRegisterNotBillableAnimal, entityId} = props;

    //Update on entityId change
    useEffect(
        () => {
            if (entityId !== data?.entityId && entityId) {
                refetch();
            }
        },
        [entityId, data?.entityId, refetch]
    )

    return <BillingBalanceStateContext.Provider
        value={useMemo(
            () => ({
                isAvailable: canReadBalance,
                balance: data?.value,
                canRegisterAnimal: parseInt(data?.value) === 0 ? !canReadBalance : true,
                canRegisterNotBillableAnimal: canRegisterNotBillableAnimal,
                reload: () => canReadBalance ? refetch() : void 0
            }),
            [canRegisterNotBillableAnimal, canReadBalance, data?.value, refetch]
        )}>
        {props.children}
    </BillingBalanceStateContext.Provider>
}

export {BillingStateProvider, useBillingBalanceState}
