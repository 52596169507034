import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import Box from "layout/modules/Box/Box";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {useTranslation} from "react-i18next";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {isBoolean} from "system/Utils/isBoolean";

const NotificationComponent = props => {

    const {t} = useTranslation('transfer');
    const {errors, owner, setOwner, readOnly} = props;

    return <UserHasPermission permission="SKIP_ANIMAL_TRANSFER_NOTIFICATIONS">
        <Box>
            <BoxTitle>{t('notification')}</BoxTitle>

            <FormGroup error={errors['skipNotifications']} mandatory={false}>
                <Form.Label>{t('send_notification')}</Form.Label>
                <BooleanDropdown
                    isDisabled={readOnly}
                    value={isBoolean(owner.skipNotifications) ? !owner.skipNotifications : null}
                    onChange={useCallback((value) => {
                            setOwner((owner) => ({
                                ...owner,
                                skipNotifications: !value
                            }));
                        },
                        [setOwner]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['skipNotifications'])}
                </Form.Text>
            </FormGroup>
        </Box>
    </UserHasPermission>


}

export default NotificationComponent;
