import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import React from "react";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import Loading from "layout/modules/Loading/Loading";
import AssociatedEntitiesContainer from "app/login/containers/AssociatedEntitiesContainer";
import AssociateNewEntityContainer from "app/login/containers/AssociateNewEntityContainer";
import 'app/login/assets/css/entity-management.scss';
import 'app/login/assets/css/entity-management-responsive.scss';


const VeterinaryEntitiesComponent = (props) => {

    const {t} = useTranslation('login');
    const {onLogin, onLoginWithoutEntity, selectedEntity, loading} = props;

    if (loading) {
        return <div className={"entity-management"}>
            <Loading visible={true}/>
        </div>
    }

    return <div className={"entity-management"}>

        <div className={"entity-management-box"}>
            <Tabs defaultActiveKey="entities"
                  className={"login-entity-tab"}
                  transition={false}
                  mountOnEnter={true}
                  unmountOnExit={true}>

                <Tab eventKey="entities" title={t('associated_entities')}>
                    <AssociatedEntitiesContainer {...props}/>
                </Tab>

                <Tab eventKey="associate" title={t('associate_entity')}>
                    <AssociateNewEntityContainer {...props}/>
                </Tab>
            </Tabs>

            <div className={"buttons"}>
                <Button variant="link" type="submit" onClick={() => onLoginWithoutEntity()}>
                    {t('login_without_entity')}
                </Button>

                <Button variant="primary" type="submit" disabled={!selectedEntity}
                        onClick={() => onLogin(selectedEntity)}>
                    {t('login_with_entity')}
                </Button>
            </div>

        </div>
    </div>
}

export default VeterinaryEntitiesComponent;
