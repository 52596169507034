import React, {useCallback, useMemo} from 'react'
import GenericRemoteClassDropdown from "../GenericRemoteClassDropdown"
import locale from "../locales/locales"
import UserHasPermission from "../../../../../system/API/Authentication/UserHasPermission"
import useTranslations from "../../../../../system/Translations/UseTranslations";
import usePlaceHolder from "../utils/usePlaceHolder";
import useLabelCallback from "../utils/useLabelCallback";

const OwnerDropdown = (props) => {

    const {t} = useTranslations('classDropdown', locale);

    const {
        id = "ownerId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 20,
    } = props;

    return <UserHasPermission permission={"READ_OWNER"}>
        <GenericRemoteClassDropdown
            {...props}
            id={id}
            placeholder={usePlaceHolder(placeholder, t('owner_ph'))}
            noOptionsMessage={useLabelCallback(noOptionsMessage, t('owner_no'))}
            loadingMessage={useLabelCallback(loadingMessage, t('owner_loading'))}
            remotePath="/owners/search"
            labelHandler={useCallback(o => {
                if (o['vatNumber'])
                    return o['vatNumber'] + ' - ' + o['name'];
                else
                    return o['name'];
            }, [])}
            searchFilter={useMemo(() => ({
                currentPage: 0,
                itemsPerPage: maxItems,
            }), [maxItems])}
        />
    </UserHasPermission>

}

export default OwnerDropdown;
