import React, {createContext, useMemo, useState} from "react"
import useDebounce from "system/System/useDebounce";
import {LoadingComponent} from "layout/modules/Loading/Loading";

const LoadingStateContext = createContext({});

function useLoadingContext() {
    const context = React.useContext(LoadingStateContext)
    if (!context) {
        return null;
    }
    return context
}


const LoadingStateProvider = (props) => {

    //const [counter, setCounter] = useState(0);
    const [loading, setLoading] = useState(false);
    const setLoadingDebounced = useDebounce(setLoading, 200);



    const value = useMemo(
        () => ({
            loading: loading,
            setLoading: (v) => {
                if (v)
                    setLoading(true)

                setLoadingDebounced(v);
            }
        }),
        [loading, setLoadingDebounced,setLoading]
    );

    return <LoadingStateContext.Provider value={value}>
        <LoadingComponent visible={loading}/>
        {props.children}
    </LoadingStateContext.Provider>;

}

export {LoadingStateProvider, useLoadingContext}
