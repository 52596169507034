import Box from "../../../layout/modules/Box/Box";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import React, {useCallback, useState} from "react";
import Container from "react-bootstrap/Container";
import PopupConfirm from "../../../layout/modules/Popups/PopupConfirm";
import {faCheck, faCodeBranch, faSearch, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import TableHeader from "../../../layout/modules/TableAdvanced/components/header/TableHeader";
import TableHeaderButtonGroup from "../../../layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import AnimalOperationAllowed, {AnimalOperations} from "../../animals/utils/AnimalOperationAllowed";
import TableIconAdd from "../../../layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import classnames from "classnames";
import SearchDataPagination from "../../../modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "../../../layout/modules/TableAdvanced/TableAdvanced";
import DateTime, {useDateTime} from "../../../system/DateTime/DateTime";
import TableIconButton from "../../../layout/modules/TableAdvanced/components/buttons/TableIconButton";
import FormContainer from "../containers/FormContainer";
import {useTranslation} from "react-i18next";

import "../assets/css/aggressions.scss";
import UserHasPermission from "../../../system/API/Authentication/UserHasPermission";
import Audit from "../../audit/Audit";
import {AnimalObjectTypes} from "../../audit/utils/AuditObjectTypes";
import {AggressionActions} from "../../audit/utils/AuditActions";

const ListComponent = (props) => {

    const {t} = useTranslation("aggression");

    const showToast = useShowToast();
    const getDate = useDateTime("DD-MM-YYYY");

    const {data, onPageChange, onPageSort, loading, onDelete, animal, reloadBase} = props;

    const [deletedInfo, setDeletedInfo] = useState({});

    const [showAdd, setShowAdd] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    return <Container fluid>

        {(showAdd || showDetail) &&
        <FormContainer
            {...props}
            onClose={() => {
                setShowAdd(false);
                setShowDetail(false)
            }}
            create={showAdd}
            aggression={showDetail}
        />
        }

        <PopupConfirm
            title={t('DELETE_AGGRESSION_CONFIRM')}
            message={t('DELETE_AGGRESSION_CONFIRM_MSG', {date: deletedInfo.date})}
            classConfirm={"danger"}
            visible={!!deletedInfo.id}
            onCancel={useCallback(
                () => setDeletedInfo({}),
                [setDeletedInfo]
            )}
            onConfirm={useCallback(
                () => {
                    setDeletedInfo({})
                    onDelete(
                        deletedInfo.id, {date: deletedInfo.date},
                        () => {
                            if (reloadBase)
                                reloadBase();

                            if (deletedInfo?.isClear) {
                                showToast({
                                    title: t('SUCCESS_DELETE_AGGRESSION'),
                                    message: t('SUCCESS_DELETE_AGGRESSION_MSG', {date: deletedInfo.date}),
                                    icon: faCheck,
                                });
                            } else {
                                showToast({
                                    title: t('SUCCESS_DELETE_CLEAR_AGGRESSION'),
                                    message: t('SUCCESS_DELETE_CLEAR_AGGRESSION_MSG', {date: deletedInfo.date}),
                                    icon: faCheck,
                                });
                            }

                        },
                        () => showToast({
                            title: t('FAIL_DELETE_AGGRESSION'),
                            message: t('FAIL_DELETE_AGGRESSION_MSG', {date: deletedInfo.date}),
                            className: 'error',
                            icon: faTimes,
                        })
                    );
                },
                [onDelete, setDeletedInfo, deletedInfo.date, deletedInfo.id, deletedInfo.isClear, showToast, t, reloadBase]
            )}
        />

        <Box className={"aggressions"}>
            <TableHeader>
                <TableHeaderButtonGroup>

                    <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                        <Audit
                            id={"aggressions"}
                            lg={true}
                            icon={faCodeBranch}
                            objectId={animal.transponder}
                            affectedObjectType={AnimalObjectTypes.Animal}
                            objectType={[
                                AnimalObjectTypes.Aggression,
                            ]}
                            filterActions={AggressionActions}

                        />
                    </UserHasPermission>

                    <AnimalOperationAllowed animal={animal} operation={AnimalOperations.REGISTER_AGGRESSION}>
                        <TableIconAdd
                            className={classnames({"active": showAdd})}
                            onClick={useCallback(() => setShowAdd(true), [setShowAdd])}
                        />
                    </AnimalOperationAllowed>
                </TableHeaderButtonGroup>

            </TableHeader>

            <SearchDataPagination data={data} onPageChange={onPageChange}/>

            <TableAdvanced
                hover
                sort={data?.sortBy}
                order={data?.sortOrder?.toLowerCase()}
                onSortClick={onPageSort}
                loading={(loading || !data)}>

                <thead>
                <tr>

                    <th id="aggressionDate" sorted>
                        {t('date')}
                    </th>

                    <th>
                        {t('type')}
                    </th>

                    <th className={"d-none d-lg-table-cell"}>
                        {t('fate')}
                    </th>

                    <th></th>

                </tr>
                </thead>
                <tbody>

                {data?.content?.map((aggression, index) => {
                    return <tr key={index}
                               className={classnames({"aggression-clear-entry": aggression?.clearRegistry})}>


                        <td className={"td-sorted"}>
                            <DateTime format="DD-MM-YYYY" timezone={"UTC"}>
                                {aggression.aggressionDate}
                            </DateTime>
                        </td>

                        {aggression?.clearRegistry
                            ? <td>
                                <div className={"clear-message"}>
                                    {t('registry_cleared')}
                                </div>
                                <div className={"reason"}>
                                    <span className={"reason-label"}>
                                        {t('reason')}:
                                    </span>
                                    {aggression?.clearRegistryReason}
                                </div>

                            </td>
                            : <>
                                <td>
                                    {t(aggression.aggressionType)}
                                </td>

                                <td className={"fill-empty d-none d-lg-table-cell"}>
                                    {t(aggression.animalFate)}
                                </td>
                            </>

                        }


                        <td className="table-buttons">
                            <div className={"buttons"}>

                                {!aggression?.clearRegistry &&
                                <TableIconButton
                                    icon={faSearch}
                                    className={"table-btn btn-rounded-primary small"}
                                    onClick={() => setShowDetail(aggression)}
                                />
                                }

                                <TableIconButton
                                    icon={faTrash}
                                    permission={"DELETE_AGGRESSION"}
                                    onClick={() => {
                                        setDeletedInfo({
                                            id: aggression.id,
                                            date: getDate(aggression.aggressionDate),
                                            isClear: aggression?.clearRegistry,
                                        })
                                    }}
                                    className={"table-btn btn-rounded-danger small"}
                                />
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
            <div className={"no-table-results"}>
                {t('no_aggression_results')}
            </div>
            }
        </Box>
    </Container>
}

export default ListComponent;
