const supportedFilesTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/gif"
];

const canPreviewFile = (file) => supportedFilesTypes.includes(file.type) && !file.isDirectDownload;


export default canPreviewFile;
