import {useTranslation} from "react-i18next";
import {useGet, useMutate} from "system/Rest/Rest";
import React, {useCallback, useMemo, useState} from "react";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import MergeFormComponent from "../components/view/merge/MergeFormComponent";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";

const MergeFormContainer = props => {

    const {transponder: mainTransponder, reload, onClose} = props;
    const [transponder, setTransponder] = useState("");

    const showToast = useShowToast();
    const {t} = useTranslation("animal");

    const {data: result, loading: searching} = useGet({
        path: "/animals/search",
        queryParams: useMemo(() => ({
            universalSearch: transponder,
            itemsPerPage: 1,
        }),[transponder]),
        lazy: !transponder,
    });

    const {mutate: submit, loading, error} = useMutate({
        verb: "POST",
        path: "/animal-merges/",
    });

    const errors = useErrorParser(error);

    return <MergeFormComponent
        {...props}
        errors={errors}
        loading={loading}
        transponder={transponder}
        setTransponder={setTransponder}
        searchResult={result}
        searching={searching}
        onSubmit={useCallback((oldTransponder, reason) => {
            submit({
                transponder: mainTransponder,
                oldTransponder: oldTransponder,
                reason: reason
            }).then(() => {
                showToast({
                    title: t('SUCCESS_MERGE'),
                    message: t('SUCCESS_MERGE_MSG', {t1: mainTransponder, t2: oldTransponder}),
                    icon: faCheck,
                });
                reload();
                onClose();
            });

        }, [showToast, t, mainTransponder, reload, submit, onClose])}

    />


}

export default MergeFormContainer;
