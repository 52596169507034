import locale from "./locales/locales";
import React, {useMemo} from "react";
import ListComponent from "./components/ListComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";

const List = (props) => {

    const {transponder} = props;

    return <GenericListContainer
        {...props}
        id={"health-intervention"}
        module="health-intervention"
        searchPath={"/health-interventions/search"}
        deletePath={"/health-interventions/" + transponder + "/"}
        locale={locale}
        hideTitle={transponder ? true : false}
        queryParameters={useMemo(() => ({
            transponder: transponder,
            sortProperty: 'executionDate',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 10,
        }),[transponder])}>

        <ListComponent {...props} />

    </GenericListContainer>


}
export default List;
