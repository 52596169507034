import {useTranslation} from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import React, {useMemo} from "react";
import {getOrNull} from "system/Objects/ObjectParameters";
import VeterinaryDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/VeterinaryDropdown";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";

const EntityVeterinaryAssociationComponent = (props) => {

    const {operation} = props;
    const {t} = useTranslation('audit');

    const ballotNumber = useMemo(
        () => {
            const ids = operation?.objectId?.split("::");
            if (ids?.length > 1) {
                return ids[0];
            }

            return null;
        },
        [operation?.objectId]
    );

    return <Box className={"view-mode"}>
        <BoxTitle>
            {t(operation.action)}
        </BoxTitle>

        <Row>
            <Col md={6}>
                <FormGroup>
                    <Form.Label>{t('request_status')}</Form.Label>
                    <div className={"form-control disabled"}>
                        {(operation.objectRaw?.status === "PENDING_ENTITY_RESPONSE") &&
                            t('PENDING_ENTITY_RESPONSE')
                        }

                        {(operation.objectRaw?.status === "PENDING_VETERINARY_RESPONSE") &&
                            t('PENDING_VETERINARY_RESPONSE')
                        }

                        {(operation.action === "VETERINARY_RESPONDED_ENTITY_ASSOCIATION" && operation?.changes) &&
                            <>
                                {t(operation?.changes?.status?.newValue + "_BY_VET")}
                            </>
                        }
                        {(operation.action === "VETERINARY_ASSOCIATION" && operation?.changes) &&
                            <>
                                {t(operation?.changes?.status?.newValue + "_BY_ENTITY")}
                            </>
                        }
                        {(operation.action === "VETERINARY_REMOVED_ENTITY_ASSOCIATION") &&
                            <>
                                {t('VETERINARY_REMOVED_ENTITY_ASSOCIATION')}
                            </>
                        }

                    </div>
                </FormGroup>
            </Col>

            <Col md={6}>
                <FormGroup>
                    <Form.Label>{t('entity')}</Form.Label>
                    <EntityDropdown
                        isDisabled={true}
                        value={getOrNull(operation.objectRaw?.entity ?? operation?.entity?.id)}
                        defaultValue={operation?.entity}
                    />
                </FormGroup>
            </Col>

            <Col md={6}>
                <FormGroup>
                    <Form.Label>{t('veterinary')}</Form.Label>
                    <VeterinaryDropdown
                        isDisabled={true}
                        value={getOrNull(operation.objectRaw?.veterinary)}
                    />
                </FormGroup>
            </Col>

            {ballotNumber &&
                <Col md={6}>
                    <LabelWithValue label={t('veterinary_ballot')}>
                        {ballotNumber}
                    </LabelWithValue>
                </Col>
            }

        </Row>


    </Box>

}

export default EntityVeterinaryAssociationComponent;
