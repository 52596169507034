import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans} from "react-i18next";
import React from "react";
import 'layout/modules/NotificationArea/assets/css/messages.scss'

const ShowToastComponent = (props) => {

    const {
        id,
        title = "",
        message = "",
        icon,
        duration = 0,
        showNotification,
        closeNotification,
        className,
        view,
        viewNotification,
        close = "Close"
    } = props;

    showNotification({
        id: id,
        duration: duration,
        popup: <div className={"notification-popup " + className}>
            <div className={"notification-popup-icon"}>
                <div className={"icon " + className}>
                    <FontAwesomeIcon icon={icon}/>
                </div>
            </div>

            <div className={"notification-popup-message"}>
                <p className={"title"}>
                    {title}
                </p>
                <p className={"message"}>
                    <Trans>{message}</Trans>
                </p>
            </div>
            <div className={"notification-popup-options"}>

                {viewNotification &&
                <>
                    <div className="view" onClick={() => viewNotification(id)}>
                        {view}
                    </div>

                    <div className={"divider"}></div>
                </>
                }

                <div className="close" onClick={() => closeNotification(id)}>
                    {close}
                </div>
            </div>
        </div>
    });

    return null;
}

export default ShowToastComponent;
