import React from 'react';

import 'layout/assets/css/fonts.scss'
import 'layout/assets/css/main.scss'
import 'layout/assets/css/generic.scss'
import 'layout/assets/css/bootstrap/bootstrap.scss';
import 'layout/assets/css/plugins/react-dropdown/react-dropdown.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'layout/assets/css/dark-mode.scss'

import ContentArea from "./modules/BaseLayout/ContentArea/ContentArea"
import Menu from "./modules/BaseLayout/Menu/Menu";
import {MenuStateProvider} from "./modules/BaseLayout/Menu/MenuStateContext";
import AuthenticationInterceptor from "system/API/Authentication/AuthenticationInterceptor";
import {BillingStateProvider} from "modules/Billing/BillingStateContext";
import {Outlet} from "react-router-dom";
import SupportContextProvider from "app/support/utils/SupportStateContext";

const MainLayout = (props) => {

    return <div className="main-layout">
        <AuthenticationInterceptor {...props}>
            <BillingStateProvider {...props}>
                <SupportContextProvider>
                    <MenuStateProvider>
                        <Menu {...props} />
                    </MenuStateProvider>
                    <ContentArea {...props}>
                        <Outlet/>
                    </ContentArea>
                </SupportContextProvider>
            </BillingStateProvider>
        </AuthenticationInterceptor>
    </div>;

}

export default MainLayout;
