import {useEffect} from "react";


export const useClickedOutsideMobile = (ref, fnc) => useClickedOutsideEffect(ref, fnc, true);

export const useClickedOutsideEffect = (ref, fnc, mobile = false) => {

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                fnc(e)
            }
        }
        document.addEventListener(mobile ? "mousedown" : "mouseup", handleClickOutside);

        return () => {
            document.removeEventListener(mobile ? "mousedown" : "mouseup", handleClickOutside);
        };
    },[ref,fnc, mobile]);

}
