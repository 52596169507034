import appConfig from "configs/config";

const handleResidenceCountryChange = (value, owner, create) => {

    const nationality = create ? value : owner?.nationality;

    return {
        country: value,
        nationality: owner?.hasLocalVatNumber ? appConfig.defaultCountry : nationality,
        hasLocalVatNumber: owner?.hasLocalVatNumber ? true : undefined,
    };
}


const handleIsLocalResident = (value, owner, create) => {

    const defaultNationality = value ? appConfig.defaultCountry : null;

    return {
        isLocalResident: value,
        country: value ? appConfig.defaultCountry : null,
        nationality: create ? defaultNationality : owner?.nationality,
        hasLocalVatNumber: create ? undefined : owner?.hasLocalVatNumber,
    }
}

export {
    handleIsLocalResident,
    handleResidenceCountryChange
}
