import {useTranslation} from "react-i18next";
import BoxTitle from "../../../../../layout/modules/Box/components/BoxTitle";
import React, {useCallback} from "react";
import Box from "../../../../../layout/modules/Box/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "../../../../../layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "../../../../../system/Objects/ObjectParameters";
import FormGroup from "../../../../../layout/modules/Forms/Group/FormGroup";
import ParameterType from "../../../utils/ParameterType";
import DatePicker from "../../../../../layout/modules/Forms/DatePicker/DatePicker";

const PropertiesComponent = (props) => {

    const {t} = useTranslation('certificate');
    const {certificateTemplate, request, setRequest, errors = {}} = props;

    const handleChangeInput = useCallback((value, prop) => setRequest(request => ({
            ...request,
            properties: {
                ...request.properties,
                [prop.code]: value,
            }

        })),
        [setRequest]
    );

    return <>
        {certificateTemplate?.properties?.map((prop, index) => {
            return <Box key={index}>
                <BoxTitle>{prop.description}</BoxTitle>
                <Row>
                    <Col sm={12} xl={(prop.type === ParameterType.Text) ? 12 : 6}>
                        <FormGroup error={errors[prop.code]}>
                            <Form.Label>{prop.name}</Form.Label>

                            {prop.type === ParameterType.Text &&
                            <FormControl
                                type="textarea"
                                as="textarea"
                                rows={3}
                                value={getOrEmpty(request.properties[prop.code])}
                                onChange={(e) => handleChangeInput(e.target.value, prop)}
                            />
                            }
                            {prop.type === ParameterType.Date &&
                            <DatePicker
                                placeholder={t("date_ph")}
                                timezone={"UTC"}
                                onlyPastDates={prop.properties?.preventFutureDates}
                                onlyFutureDates={prop.properties?.preventPastDates}
                                value={getOrEmpty(request.properties[prop.code])}
                                onChange={(timestamp) => handleChangeInput(timestamp, prop)}
                            />
                            }

                            <Form.Text className="sub-error">
                                {t(errors[prop.code])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                </Row>
            </Box>
        })}

    </>

}

export default PropertiesComponent;
