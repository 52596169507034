import {useMemo} from "react";
import isSterilization from "./sterilization/isSterilization";
import {useGet} from "system/Rest/Rest";
import {AnimalOperations, useAnimalOperationAllowed} from "app/animals/utils/AnimalOperationAllowed";


const useInterventionTypes = (animal, intervention, readOnly) => {

    const {data: interventions, loading: loadingTypes} = useGet({
        path: "/health-interventions/types",
        lazy: readOnly,
    });

    const animalOperationAllowed = useAnimalOperationAllowed(animal)
    const isSterilizationAllowed = useMemo(
        () => (animalOperationAllowed(AnimalOperations.SET_STERILIZATION) || isSterilization(intervention?.type?.id)),
        [animalOperationAllowed, intervention?.type?.id]
    );

    const interventionTypes = useMemo(() => {
        if (Array.isArray(interventions)) {
            if (!isSterilizationAllowed) {
                return parseTypesAsEnum(
                    interventions.filter(e => !isSterilization(e.id))
                );
            } else {
                return parseTypesAsEnum(interventions);
            }
        } else if (readOnly)
            return parseTypesAsEnum([intervention?.type]);
        else return {};

    }, [intervention, interventions, isSterilizationAllowed, readOnly])

    return {interventionTypes, loadingTypes}
}

const useAllInterventionTypes = () => {

    const {data: interventions, loading: loadingTypes} = useGet({
        path: "/health-interventions/types",
    });

    const types = useMemo(() => {
        if (Array.isArray(interventions)) {
            return parseTypesAsEnum(interventions);
        } else return {}
    }, [interventions])

    return [types, loadingTypes]

}

const parseTypesAsEnum = (types) => types.reduce((data, item) => ({
    ...data,
    [item.name]: item.id
}), {});


export default useInterventionTypes;

export {useAllInterventionTypes} ;
