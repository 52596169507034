import {useGet} from "system/Rest/Rest";
import React from "react";
import locale from "app/control/modules/transfers/locales/locales";
import ShowTransferContainer from "app/control/modules/transfers/containers/ShowTransferContainer";
import useTranslations from "system/Translations/UseTranslations";
import {cloneWithoutKey} from "system/Objects/Objects";
import Loading from "layout/modules/Loading/Loading";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";
import {useParams} from "react-router-dom";

const Show = (props) => {

    const {id} = useParams();
    const {embedded, id : internalId} = props;
    const {t} = useTranslations('transfer', locale);

    const {data: transferRequest, loading, refetch} = useGet({
        path: "/animal-transfers/" + (embedded ? internalId : id),
        resolve: (data) => ({
            ...cloneWithoutKey(data, ['parish','animalParish']),
            parishId: data.parish?.id,
            animalParishId: data.animalParish?.id,
        })
    });

    if (embedded) {
        return transferRequest
            ? <ShowTransferContainer {...props} id={id} transferRequest={transferRequest} loading={loading}/>
            : <Loading visible={true}/>
    }

    return <Title title={t('owner_transfer', {id: id})}
                  breadcrumbs={[{href: "/animal/transfers", label: t('list')}, {
                      href: "/transfers/" + id,
                      label: id
                  }]}
                  {...props}>

        {transferRequest
            ? <ShowTransferContainer {...props}  id={id} transferRequest={transferRequest} loading={loading} reload={refetch}/>
            : <Loading visible={true}/>
        }
    </Title>
}

export default Show;
