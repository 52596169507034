import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faCheck, faUserLock} from "@fortawesome/free-solid-svg-icons";
import ReviewAlertComponent from "app/owners/containers/view/ReviewAlertContainer";
import React from "react";
import {Trans} from "react-i18next";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {useDateTime} from "system/DateTime/DateTime";

const OwnerAlertsComponent = props => {

    const {owner, t} = props;
    const getDate = useDateTime("DD-MM-YYYY");

    return <>

        {owner?.isLocked &&
            <AdvanceInfoMessage
                icon={faUserLock}
                type={"danger"}
                iconType={"danger"}
                title={t("owner_is_locked")}
            >
                <Trans>
                    {t('owner_is_locked_msg')}
                </Trans>

                {owner?.timeLock?.endDate &&
                    <UserHasPermission permission="LOCK_OWNER">
                        <Trans>
                        {t('owner_is_locked_temporary_msg',{
                            date: getDate(owner?.timeLock?.endDate),
                            observations: owner?.timeLock?.observations ?? "",

                        })}
                        </Trans>
                    </UserHasPermission>
                }

            </AdvanceInfoMessage>

        }

        {owner?.editLocked &&
            <AdvanceInfoMessage
                icon={faUserLock}
                type={"danger"}
                iconType={"danger"}
                title={t("owner_edit_is_locked")}
                message={t('owner_edit_is_locked_msg')}
            />
        }


        {owner?.verifiedData &&
            <AdvanceInfoMessage
                icon={faCheck}
                type={"success"}
                iconType={"success"}
                title={t("owner_is_verified")}
                message={t('owner_is_verified_msg')}
            />
        }

        {owner?.needsReview &&
            <ReviewAlertComponent {...props} t={t} owner={owner}/>
        }

    </>
}

export default OwnerAlertsComponent;
