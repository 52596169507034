import {useTranslation} from "react-i18next";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import {getOrDefault} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import Form from "react-bootstrap/Form";
import Box from "layout/modules/Box/Box";
import Row from "react-bootstrap/Row";

const AllowLegalPermissions = props => {

    const {t} = useTranslation("animal");
    const {readOnly, errors, lostInfo, setLostInfo} = props;

    return <Box>
        <BoxTitle>{t('legal_allow_title')}</BoxTitle>

        <Row>

            <Col sm={12} xl={12}>
                <FormGroup error={errors['useAnimalPhotoInProcess']} mandatory={false}>
                    <CheckBox
                        value={getOrDefault(lostInfo?.useAnimalPhotoInProcess, false)}
                        label={t('use_animal_photo_in_process')}
                        disabled={readOnly}
                        onChange={useCallback((val) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            useAnimalPhotoInProcess: val,
                        })), [setLostInfo])}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['allowsAdvertising'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} xl={12}>
                <FormGroup error={errors['useAsAnimalPhoto']} mandatory={false}>
                    <CheckBox
                        value={getOrDefault(lostInfo?.useAsAnimalPhoto, false)}
                        label={t('use_as_animal_photo')}
                        disabled={readOnly}
                        onChange={useCallback((val) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            useAsAnimalPhoto: val,
                        })), [setLostInfo])}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['allowsAdvertising'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} xl={12}>
                <FormGroup error={errors['allowsAdvertising']} mandatory={false}>
                    <CheckBox
                        value={getOrDefault(lostInfo?.allowsAdvertising, false)}
                        label={t('allow_advertising_msg')}
                        disabled={readOnly}
                        onChange={useCallback((val) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            allowsAdvertising: val,
                        })), [setLostInfo])}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['allowsAdvertising'])}
                    </Form.Text>
                </FormGroup>
            </Col>

        </Row>
    </Box>
}

export default AllowLegalPermissions;
