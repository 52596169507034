import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import isAntiRabies from "app/interventions/utils/antiRabies/isAntiRabies";
import isSterilization, {isProofOfLife} from "app/interventions/utils/sterilization/isSterilization";
import SterilizationComponent from "app/interventions/components/form/SterilizationComponent";
import {useTranslation} from "react-i18next";
import {useApiState} from "system/API/APIStateContext";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {UserType} from "types/Users";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import {interventionHasReason} from "app/interventions/utils/reasons";
import ProofOfLifeContainer from "app/interventions/containers/ProofOfLifeContainer";
import AntiRabiesComponent from "app/interventions/components/form/AntiRabiesComponent";
import VeterinaryFormComponent from "app/interventions/components/form/VeterinaryFormComponent";

const FormComponent = (props) => {

    const {create, compare, animal, onEditClick, readOnly, errors, intervention, setIntervention, types} = props;
    const {t} = useTranslation("health-intervention");
    const {authentication} = useApiState();

    const handleSetExecutionDate = useCallback(
        date => setIntervention(intervention => ({
            ...intervention,
            executionDate: date,
        })), [setIntervention]
    );

    return <div className={classnames({
        "view-mode": readOnly,
        "compare-mode": compare,
    })}>
        <Box>
            <BoxTitle>
                {t('general_data')}
            </BoxTitle>

            <BoxOptions>

                {(!create && !compare && authentication?.type !== UserType.VETERINARY) &&
                <AnimalOperationAllowed animal={animal} operation={AnimalOperations.REGISTER_INTERVENTION}>
                    <UserHasPermission permission="EDIT_HEALTH_INTERVENTION">
                        <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                             onClick={onEditClick}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </div>
                    </UserHasPermission>
                </AnimalOperationAllowed>
                }

                {(authentication?.type === UserType.VETERINARY && intervention?.veterinaryId === authentication?.veterinaryId) &&
                <div className={classnames("btn-rounded-danger small", {"active": !readOnly})}
                     onClick={onEditClick}>
                    <FontAwesomeIcon icon={faPencilAlt}/>
                </div>
                }

            </BoxOptions>

            <Row>
                <Col sm={12} xl={12}>
                    <FormGroup error={errors['typeId']} mandatory={true}>
                        <Form.Label>{t('type')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            value={getOrNull(intervention?.typeId)}
                            options={types ? types : {}}
                            isClearable={false}
                            isSearchable={true}
                            isDisabled={!create}
                            placeholder={t('generic_select_ph')}
                            onChange={useCallback(
                                typeId => setIntervention(intervention => ({
                                    ...intervention,
                                    typeId: typeId,
                                })), [setIntervention]
                            )}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['typeId'])}
                        </Form.Text>
                    </FormGroup>
                </Col>

                {intervention?.typeId &&
                <>
                    <Col sm={12} xl={true}>
                        <FormGroup error={errors['executionDate']} mandatory={true}>
                            <Form.Label>{t('execution_date')}</Form.Label>
                            <DatePicker
                                placeholder={t("execution_date_ph")}
                                onlyPastDates={true}
                                disabled={readOnly}
                                timezone={"UTC"}
                                value={getOrEmpty(intervention?.executionDate)}
                                onChange={handleSetExecutionDate}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['executionDate'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>


                    {readOnly &&
                    <Col sm={12} xl={6}>
                        <FormGroup>
                            <Form.Label>{t('register_date')}</Form.Label>
                            <DatePicker
                                disabled={true}
                                value={getOrEmpty(intervention?.creationTimestamp)}
                            />
                        </FormGroup>
                    </Col>
                    }

                    <VeterinaryFormComponent
                        t={t}
                        setIntervention={setIntervention}
                        intervention={intervention}
                        readOnly={readOnly}
                        errors={errors}
                        compare={compare}
                    />
                </>
                }
            </Row>
        </Box>

        {(isAntiRabies(intervention?.typeId) && intervention?.executionDate) &&
        <AntiRabiesComponent
            readOnly={readOnly}
            errors={errors}
            intervention={intervention}
            setIntervention={setIntervention}
            compare={compare}
        />
        }

        {isSterilization(intervention?.typeId) &&
        <SterilizationComponent
            readOnly={readOnly}
            errors={errors}
            intervention={intervention}
            setIntervention={setIntervention}
            compare={compare}
        />
        }

        {(isProofOfLife(intervention?.typeId) && !readOnly) &&
        <ProofOfLifeContainer
            readOnly={readOnly}
            errors={errors}
            intervention={intervention}
            setIntervention={setIntervention}
            compare={compare}
        />
        }

        {(intervention?.typeId && (intervention?.description || !readOnly)) &&
        <Box>
            <BoxTitle>
                {interventionHasReason(intervention?.typeId) ? t('reason') : t('observations')}
            </BoxTitle>

            <Col sm={12} lg={12}>
                <FormGroup error={errors['description']} mandatory={interventionHasReason(intervention?.typeId)}>
                    <Form.Label>{interventionHasReason(intervention?.typeId) ? t('reason') : t('observations')}</Form.Label>
                    <Form.Control
                        type="textarea"
                        as="textarea"
                        rows={5}
                        disabled={readOnly}
                        value={getOrEmpty(intervention?.description)}
                        onChange={(e) => setIntervention(i => ({
                            ...i,
                            description: e.target.value,
                        }))}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['description'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Box>
        }

    </div>
}

export default FormComponent;
