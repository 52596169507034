import {useGet, useMutate} from "system/Rest/Rest";
import {useCallback, useMemo} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useTranslation} from "react-i18next";
import ShowComponent from "app/control/modules/preRegister/components/show/ShowComponent";
import useErrorParser from "system/API/Error/ErrorParser";
import ValidationStatus from "types/ValidationStatus";
import {parseBoolean} from "system/Utils/parseBoolean";
import ownerResolver from "app/owners/utils/OwnerResolver";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";

const ShowContainer = props => {

    const {preRegister, loading, reload} = props;

    const showToast = useShowToast();
    const userHasPermission = useUserHasPermission();
    const canManage = useMemo(() => userHasPermission({permission: "MANAGE_ANIMAL_PRE_REGISTER"}), [userHasPermission]);

    const {t} = useTranslation('preRegister');

    const {data: registeredOwner} = useGet({
        path: "/owners/get-by-vat",
        lazy: (parseBoolean(preRegister?.closed, true) || !canManage),
        queryParams: useMemo(
            () => ({
                onlyRegisteredOwner: true,
                vatNumber: preRegister?.owner?.vatNumber,
                type: preRegister?.owner?.type,
                country: preRegister?.owner?.country,
                hasLocalVatNumber: preRegister?.owner?.hasLocalVatNumber,
            }),
            [preRegister?.owner?.vatNumber,
                preRegister?.owner?.type, preRegister?.owner?.country,
                preRegister?.owner?.hasLocalVatNumber]
        ),
        resolve: (data) => data?.vatNumber ? ownerResolver(data) : undefined,
    });

    const {mutate: changeState, loading: changingState} = useMutate({
        verb: "PUT",
        path: "/animals/pre-register/" + preRegister.id
    });

    const {mutate: addFiles, loading: uploading, errors: errorUploading} = useMutate({
        verb: "PUT",
        path: "/animals/pre-register/" + preRegister.id + "/documents",
    });


    const errorsUploading = useErrorParser(errorUploading);

    return <ShowComponent
        {...props}
        loading={loading || changingState}
        preRegister={preRegister}
        uploading={uploading}
        errorsUploading={errorsUploading}
        registeredOwner={registeredOwner}
        onAddFiles={useCallback(
            (files, successCallback) => {
                addFiles({
                    documents: files
                }).then(() => {
                    showToast({
                        title: t('SUCCESS_UPLOAD'),
                        message: t('SUCCESS_UPLOAD_MSG', {
                            transponder: preRegister.transponder
                        }),
                        icon: faCheck,
                    });

                    reload();
                    successCallback();

                }).catch(() => showToast({
                    title: t('ERROR_UPLOADING'),
                    message: t('ERROR_UPLOADING_MSG'),
                    icon: faTimes,
                    className: 'error',
                }))
            },
            [showToast, t, preRegister, addFiles, reload]
        )}
        onChangeState={useCallback(
            (state, comments) => {
                changeState({
                    validation: state,
                    comments: comments,
                }).then((result) => {
                    showToast({
                        title: t('SUCCESS_CHANGED'),
                        message: (state === ValidationStatus.MORE_INFORMATION_NEEDED)
                            ? t('SUCCESS_REQUEST_MORE_INFORMATION', {transponder: preRegister.transponder})
                            : t('SUCCESS_CHANGED_MSG', {
                                transponder: preRegister.transponder,
                                status: t(state).toLowerCase()
                            }),
                        icon: faCheck,
                    });

                    reload();

                }).catch(() => showToast({
                    title: t('ERROR_CHANGE_STATE'),
                    message: t('ERROR_CHANGE_STATE_MSG'),
                    icon: faTimes,
                    className: 'error',
                }))
            },
            [preRegister.transponder, t, changeState, reload, showToast]
        )}
    />;


}

export default ShowContainer;
