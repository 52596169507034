import appConfig from "configs/config";

export const pt = {

    help: "Manuais de ajuda",
    dark_mode: "Alternar Dark Mode",
    account: "Utilizador",

    controls: "Pedidos",

    animals: "Animais",
    owners: "Titulares",
    list_owners: "Listagem de titulares",
    create_owner: "Criar novo titular",
    owner_review: "Revisão de titulares suspeitos",
    controls_by_labels: "Controlos por labels",

    list_animals: "Listagem de animais",
    register_animal: "Registar novo animal",
    lost_animals: "Animais perdidos",
    transfers: "Transmissões de titularidade",
    public_transfers: "Trans. de titul. do site",
    validate_document: "Validar documento",
    pending_lost_animals: "Validar perdidos",
    deaths: "Validar falecimentos",
    diac_request: "Pedidos de DIAC",

    my_entity: "A minha entidade",
    users: "Utilizadores",
    list_users: "Listagem de utilizadores",
    create_users: "Criar novo utilizador",

    entities: "Entidades",
    list_entities: "Listagem de entidades",
    create_entity: "Criar entidade e sub-entidade",

    veterinaries: "Médicos veterinários",
    list_my_veterinaries: "Gerir acessos à entidade",
    list_veterinaries: "Listagem de médicos veterinários",

    home: "Entrada",

    admin: "Definições",

    roles: "Permissões",
    list_roles: "Listagem de permissões",
    create_roles: "Criar perfil de permissões",

    breeds: "Raças",
    list_breeds: "Listagem de raças",

    entityProfiles: "Perfis de entidade",
    list_entityProfiles: "Listagem de perfis de entidade",
    create_entityProfiles: "Criar perfil de entidade",

    pricing: "Pagamentos",
    list_pricing_tables: "Listagem de tabelas de preços",

    //Menu
    sign_out: "Terminar sessão",
    personal: "Área pessoal",
    impersonate: "Impersonate",
    exit_impersonate: "Sair do modo impersonate",
    change_entity: "Mudar de entidade",
    user_historic: "Histórico de operações",

    //Alerts
    user_impersonate: "Está em modo impersonate",
    user_impersonate_msg: "<strong>Impersonate</strong> - Neste momento está a utilizar a conta do utilizador {{name}}.",

    //Vaccines
    vaccines: "Vacinas",
    manage_vaccines: "Gerir vacinas",

    //FILES
    files: "Documentos",
    manage_files: "Gerir documentos gerais",
    entity_files: "Documentos gerais e de faturação",
    general_files: "Doc. gerais",

    //certificates:
    certificates: "Documentos de Animal",
    manage_certificates: "Gerir documentos",

    //BIlling
    billing: "Faturação",
    transaction_list: "Transações",
    my_transaction_list: "Consumos",
    credits_available_one: "Crédito disponível",
    credits_available_other: "Créditos disponíveis",
    low_balance_message_one: "<strong>Atenção</strong> - O seu saldo atual é de {{count}} crédito.",
    low_balance_message_other: "<strong>Atenção</strong> - O seu saldo atual é de {{count}} créditos.",
    no_balance_message: "<strong>Sem Saldo</strong> - Atualmente não dispõe de saldo para realizar registos de animais",
    mb_references_list: "Referências multibanco",


    //Audit
    configs: "Sistema",
    configuration: "Configuração",
    read_audit: "Ver logs",

    //Traceability
    traceability: "Rastreabilidade",
    transactions: "Ver transações",
    transponders: "Transponders",

    reports_and_operations: "Relatórios e operações",
    reports: "Relatórios",
    list_report_templates: "Listagem de relatórios",
    list_operation_templates: "Listagem de operações",
    create_report_templates: "Criar relatório",
    create_operation_templates: "Criar Operação",
    schedule_report: "Agendamento de relatórios",
    operations: "Operações",
    schedule_operation: "Agendamento de operações",
    
    //Pre Registers
    pre_registers: "Pré-registos",
    owner_merges: "Fusão de titulares",
    owner_data: "Atualização de dados",

    //Generic Error
    error: "Erro",
    error_msg: "Ocorreu um erro a carregar o componente. Por favor atualize a versão do seu browser para uma mais recente",


    //herding dog - and ICNF
    ICNF: "ICNF - Pedidos IFAP",
    herding_dog: "Cão de proteção de gado",

    go_back: "Voltar",

    bottom_message: "2024 - " + appConfig.name + " - " + appConfig.nameComplete,


    //Assist
    assist_control_confirm_message: "Os serviços do SIAC desejam inciar uma sessão de suporte remota. Deseja continuar? ",
    assist_control_accept: "Aceitar",
    assist_control_cancel: "Cancelar",

    assist_call_confirm_message: "Os serviços do SIAC desejam inciar uma chamada de suporte. Deseja continuar? ",
    assist_call_accept: "Aceitar",
    assist_call_cancel: "Cancelar",


    //Menu
    dark: "Escuro",
    light: "Claro",


};

export default pt;
