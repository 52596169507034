import FormGroup from "../../../../layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrEmpty} from "../../../../system/Objects/ObjectParameters";
import Col from "react-bootstrap/Col";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import BoxTitle from "../../../../layout/modules/Box/components/BoxTitle";
import Box from "../../../../layout/modules/Box/Box";
import Row from "react-bootstrap/Row";
import {useAnimalState} from "../../utils/AnimalContext";

const ObservationsComponent = (props) => {

    const {errors, readOnly, changes} = props;
    const {t} = useTranslation('animal');

    const {
        animal: {
            observations
        },
        setAnimal
    } = useAnimalState();


    return <Box visible={!readOnly || observations}>
        <BoxTitle>{t('observations')}</BoxTitle>
        <Row>
            <Col sm={12} xl={12}>
                <FormGroup error={errors['observations']} changes={changes['observations']} mandatory={false}>
                    <Form.Label>{t('observations')}</Form.Label>
                    <Form.Control
                        type="textarea"
                        as="textarea"
                        rows={3}
                        disabled={readOnly}
                        value={getOrEmpty(observations)}
                        onChange={useCallback((e) => setAnimal(animal => ({
                            ...animal,
                            observations: e.target.value,
                        })),[setAnimal])}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['observations'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Row>
    </Box>

}

export default ObservationsComponent;
