import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import ZipCodeComponent from "./FormComponents/pt/ZipCodeComponent";
import {useGet} from "system/Rest/Rest";
import LocalityComponent from "./FormComponents/pt/LocalityComponent";
import ParishComponent from "./FormComponents/pt/ParishComponent";
import PortugueseStreetComponent from "./FormComponents/pt/PortugueseStreetComponent";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Loading from "layout/modules/Loading/Loading";
import {
    buildAddress,
    buildInternalAddressObject,
    hasAddressChanged,
    parseAsOutput
} from "modules/Forms/Address/components/FormComponents/pt/utils/address";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";

const FormComponent = (props) => {

    const {value, t, errors, readOnly, onChange, ignoreSubmit = false, changes = {}} = props;

    const [data, setInternalData] = useState({});
    const [changeData, setChangedData] = useState(null);
    const {postalCode, address, parishId, parish, locality, district, county} = value;

    const setData = useCallback((fnc) => {
        setInternalData((data) => {
            const updateData = fnc(data);
            if (hasAddressChanged(data, updateData)) {
                setChangedData(parseAsOutput(updateData));
            }
            return updateData;
        });
    }, [setChangedData]);

    const {data: addresses, loading: isLoading} = useGet({
        path: "/address/search-address",
        debounce: 300,
        lazy: !data.zipCodeValid || readOnly,
        queryParams: useMemo(() => ({
            cp3: data.cp3,
            cp4: data.cp4,
            itemsPerPage: 100,
        }), [data?.cp3, data?.cp4]),
        resolve: (data) => data.content
    });

    //Update on value change
    useEffect(() => {
        setInternalData((currentData) =>
            buildInternalAddressObject(address, postalCode, locality, parishId, currentData))
    }, [postalCode, address, parishId, locality]);


    //Validate Zip Code
    useEffect(() => {
        if (addresses) {
            setData(data => ({
                ...data,
                zipCodeConfirmed: (addresses.length > 0),
            }));
        }
    }, [addresses, setData]);


    useEffect(
        () => {
            if (changeData && onChange) {
                onChange(changeData);
            }
        },
        [changeData, onChange]
    );


    return <>
        <Loading visible={isLoading} localOnly={true}/>

        <Row className={"mandatory address-form"}>

            <Col sm={12} md={5} lg={3} xl={3}>
                <ZipCodeComponent
                    {...props}
                    data={data}
                    changes={changes}
                    errors={errors}
                    onChange={setData}
                    t={t}/>
            </Col>

            <Col sm={12} md={7} lg={9} xl={9}>
                <LocalityComponent
                    {...props}
                    t={t}
                    isLoading={isLoading}
                    data={data}
                    changes={changes}
                    addresses={addresses}
                    errors={errors}
                    onChange={setData}
                />
            </Col>

            {(readOnly && district && county) &&
                <>
                <Col sm={12} lg={4} xl={3}>
                    <LabelWithValue label={t('district')} value={district} inForm={true}/>
                </Col>

                <Col sm={12} lg={4} xl={3}>
                    <LabelWithValue label={t('county')} value={county} inForm={true}/>

                </Col>
            </>
            }

            <Col sm={12} lg={true} xl={true}>
                <ParishComponent
                    {...props}
                    t={t}
                    defaultParish={parish}
                    isLoading={isLoading}
                    changes={changes}
                    data={data}
                    addresses={addresses}
                    errors={errors}
                    onChange={setData}/>
            </Col>

        </Row>
        {(data.zipCodeConfirmed || data.edit) &&
        <PortugueseStreetComponent
            {...props}
            t={t}
            isLoading={isLoading}
            changes={changes}
            data={data}
            addresses={addresses}
            errors={errors}
            onChange={setData}/>
        }


        {!ignoreSubmit &&
        <>
            <input type="hidden" name="postalCode" value={getOrEmpty(data.zipCode)}/>
            <input type="hidden" name="locality" value={getOrEmpty(data.locality)}/>
            <input type="hidden" name="parishId" value={getOrEmpty(data.parish)}/>
            <input type="hidden"
                   name="address"
                   value={buildAddress(data.street, data.door, data.accommodation)}/>
        </>
        }

    </>;
}

export default FormComponent;
