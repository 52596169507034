export const pt = {

   observations: "Observações de backoffice",
   create_new_title: "Adicionar observação",
   create_new: "Observação",

   fixed: "Fixar como banner",
   public: "Definir como publico",

   create: "Adicionar",
   cancel: "Fechar",

   SUCCESS_CREATED: "Observação guardada",
   SUCCESS_CREATED_MSG: "A observação foi guardada com sucesso para este documento",

   MISSING_OBSERVATION: "Deve indicar uma observação",
   FORM_ERROR: "Erro no formulário",
   FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

   DELETE_OBSERVATION_CONFIRM: "Confirmação",
   DELETE_OBSERVATION_CONFIRM_MSG: "Deseja realmente apagar a observação criada por {{name}}?",

   SUCCESS_DELETED: "Comentário removido",
   SUCCESS_DELETED_MSG: "O comentário criado por {{name}} foi removido com sucesso.",

   no_observations: "Não existe atualmente nenhuma observação para este documento ",

   fixed_info: "(Banner fixo. Publico: {{public}})",
   true: "Sim",
   false: "Não",

};

export default pt;
