import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import React from "react";
import EntityLinkComponent from "app/entities/components/other/EntityLinkComponent";
import {useGet} from "system/Rest/Rest";
import entityResolver from "app/entities/utils/EntityResolver";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";

const EntityLinkDropdown = props => {

    const {isDisabled, value, defaultValue} = props;

    const {data: entity} = useGet({
        path: "/entities/" + (value ?? defaultValue),
        resolve: entityResolver,
        lazy: !isDisabled,
        localErrorOnly: true
    });

    if (isDisabled) {
        return <LabelWithValue>
            <EntityLinkComponent
                name={entity?.name}
                id={entity?.id}
            />
        </LabelWithValue>
    } else {
        return <EntityDropdown {...props}/>
    }


}

export default EntityLinkDropdown;
