const TicketStatus = {
    OPEN: "OPEN",
    PENDING: "PENDING",
    RESOLVED : "RESOLVED",
    CLOSED: "CLOSED",
    PENDING_USER: "PENDING_USER",
    PENDING_OTHERS: "PENDING_OTHERS",
    SIAC_RESPONDED: "SIAC_RESPONDED",

};

export default TicketStatus;
