import React, {useMemo, useRef, useState} from "react";
import locales from "app/animals/locales/locales";
import GeneralComponent from "./view/GeneralComponent";
import OverviewPanelComponent from "./view/OverviewPanelComponent";
import Alerts from "app/animals/components/view/Alerts";
import FileList from "app/files/List";
import InterventionList from "app/interventions/List";
import LicenseList from "app/licensing/List";
import AggressionList from "app/aggressions/List";
import OwnerTransferList from "app/control/modules/transfers/List";
import {useIsMobile} from "system/Window/Mobile";
import {UserType} from "types/Users";
import DocumentType from "types/Documents";
import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import useTranslations from "system/Translations/UseTranslations";
import Loading from "layout/modules/Loading/Loading";
import LostList from "app/animals/LostList";
import canLicenseAnimal from "app/licensing/utils/canLicenseAnimal";
import {useLocation} from "react-router";
import {generateRandomId} from "@openreplay/tracker/lib/utils";

const ViewComponent = (props) => {

    const isMobile = useIsMobile();

    const userHasPermission = useUserHasPermission();
    const {t} = useTranslations("animal", locales);

    const location = useLocation();

    //Refresh tab id on condition change
    const tabId = useMemo(
        () => generateRandomId(10),
        // eslint-disable-next-line
        [location?.state]
    );

    const {loading, animal: remoteAnimal, reload} = props;
    const [isEditMode, setEditMode] = useState();

    const photoUploadRef = useRef(null);

    if (!remoteAnimal) {
        return <Loading visible={true}/>
    }


    return <div>

        <Loading visible={loading}/>
        <Alerts {...props} />

        <div className={"show-animal"}>

            <div className={"main-panel"}>
                <Tabs id={tabId} unmountOnExit={true} mountOnEnter={true} defaultActiveKey={"main"}>
                    <Tab eventKey="main" title={t('general')}>
                        {isMobile &&
                        <div className={"right-panel"}>
                            <OverviewPanelComponent
                                {...props}
                                photoUploadRef={photoUploadRef}
                                isEditMode={isEditMode}/>
                        </div>
                        }

                        <GeneralComponent
                            {...props}
                            photoUploadRef={photoUploadRef}
                            onEditModeChange={setEditMode}
                        />
                    </Tab>

                    {userHasPermission({permission: 'READ_HEALTH_INTERVENTION', orUserTypes: UserType.VETERINARY}) &&
                    <Tab eventKey="interventions" title={t('health_interventions')} disabled={isEditMode}>
                        <InterventionList animal={remoteAnimal} transponder={remoteAnimal.transponder}
                                          reloadBase={reload}/>
                    </Tab>
                    }

                    {(userHasPermission({permission: 'READ_ANIMAL_LICENSE'}) && canLicenseAnimal(remoteAnimal)) &&
                    <Tab eventKey="licensing" title={t('licensing')} disabled={isEditMode}>
                        <LicenseList animal={remoteAnimal} transponder={remoteAnimal.transponder} reloadBase={reload}/>
                    </Tab>
                    }

                    {userHasPermission({permission: 'READ_AGGRESSIONS'}) &&
                    <Tab eventKey="aggressions" title={t('aggressions')} disabled={isEditMode}>
                        <AggressionList
                            transponder={remoteAnimal.transponder}
                            animal={remoteAnimal}
                            reloadBase={reload}
                        />
                    </Tab>
                    }

                    {userHasPermission({permission: 'MANAGE_ANIMAL_TRANSFER'}) &&
                    <Tab eventKey="ownerTransfers" title={t('change_owner_btn')} disabled={isEditMode}>
                        <OwnerTransferList transponder={remoteAnimal.transponder} reloadBase={reload}/>
                    </Tab>
                    }

                    <Tab eventKey="losts" title={t('lost_registers')} disabled={isEditMode}>
                        <LostList transponder={remoteAnimal.transponder} reloadBase={reload}/>
                    </Tab>

                    <Tab eventKey="files" title={t('files')} disabled={isEditMode}>
                        <FileList id={remoteAnimal.transponder} type={DocumentType.ANIMAL}/>
                    </Tab>

                </Tabs>

            </div>

            {!isMobile &&
            <div className={"right-panel"}>
                <OverviewPanelComponent
                    {...props}
                    photoUploadRef={photoUploadRef}
                    isEditMode={isEditMode}
                />
            </div>
            }
        </div>
    </div>

}

export default ViewComponent;
