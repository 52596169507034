import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import MainAddressComponent from "modules/Forms/Address/AddressComponent";
import {getOrDefault} from "system/Objects/ObjectParameters";
import appConfig from "configs/config";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import useLockFields from "app/owners/utils/useLockFields";

const AddressComponent = (props) => {

    const {errors = {}, changes = {}, owner, setOwner, readOnly, lockFields: remoteLockFields = {}} = props;
    const {t} = useTranslation('owner');

    const lockFields = useLockFields({owner, remoteLockFields, create: !owner?.id});

    return <Box>
        <BoxTitle>{props.title ?? t('owner_address')}</BoxTitle>
        <MainAddressComponent
            country={getOrDefault(owner?.country, appConfig.defaultCountry)}
            errors={errors}
            readOnly={readOnly || lockFields['address']}
            changes={changes}
            value={owner}
            onChange={useCallback(
                (address) => setOwner(owner => ({...owner, ...address})),
                [setOwner])
            }
        />
    </Box>
}

export default AddressComponent;
