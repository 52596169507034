const SterilizationType = {
    PRIVATE: "PRIVATE",
    FINANCED_BY_COUNTY: "FINANCED_BY_COUNTY",
    FINANCED_BY_MINISTRY_OF_AGRICULTURE: "FINANCED_BY_MINISTRY_OF_AGRICULTURE",
};


const SterilizationAllowedType = {
    PRIVATE: "PRIVATE",
    FINANCED_BY_COUNTY: "FINANCED_BY_COUNTY",
};


export default SterilizationType;
export {SterilizationAllowedType};
