import {useTranslation} from "react-i18next";
import React, {useCallback, useMemo} from "react";
import appConfig from "configs/config";
import {getOrDefault} from "system/Objects/ObjectParameters";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import MainAddressComponent from "modules/Forms/Address/AddressComponent";

const AnimalAddressComponent = (props) => {

    const {t} = useTranslation('animal');
    const {errors, readOnly, changes = {}, compareMode} = props;

    const {
        animal: {
            useOwnerAddress,
            postalCode,
            address,
            parishId,
            parish,
            locality
        },
        setAnimal
    } = props;

    const handleChangeAddress = useCallback(
        (address) => setAnimal((animal) => ({...animal, ...address})),
        [setAnimal]
    );

    const animalAddress = useMemo(() => ({
            postalCode: postalCode,
            address: address,
            parish: parish,
            parishId: parishId,
            locality: locality,
        }),

        [postalCode, address, parishId, parish, locality]
    );

    const collapsed = useOwnerAddress && !compareMode;

    return <Box collapsed={collapsed} visible={(!readOnly || !collapsed)}>
        <BoxTitle>{t('animal_address')}</BoxTitle>
        <BoxOptions>
            <CheckBox
                value={getOrDefault(useOwnerAddress, false)}
                label={t('use_owner_address')}
                disabled={readOnly}
                onChange={useCallback(
                    (val) => setAnimal(animal => ({
                        ...animal,
                        useOwnerAddress: val,
                        postalCode: undefined,
                        address: undefined,
                        parish: undefined,
                        parishId: undefined,
                        locality: undefined,
                    })),
                    [setAnimal]
                )}
            />
        </BoxOptions>

        {!collapsed &&

        <MainAddressComponent
            country={appConfig.defaultCountry}
            errors={errors}
            readOnly={readOnly}
            changes={changes}
            onChange={handleChangeAddress}
            value={animalAddress}
        />
        }

    </Box>

}

export default AnimalAddressComponent;
