import React from "react";
import EnumDropdown from "../EnumDropdown/EnumDropdown";
import {Status} from "types/Status";


const StatusDropdown = (props) => {

    return <EnumDropdown
        {...props}
        name={props.id ? props.id : "status"}
        id={props.id ? props.id : "status"}
        options={Status}
    >
    </EnumDropdown>
}


export default StatusDropdown;
