import React, {useCallback} from "react";
import MainVersionsContainer from "modules/VersionsContainer/VersionsContainer";
import FormComponent from "app/animals/components/form/FormComponent";
import animalResolver from "app/animals/utils/AnimalResolver";
import {AnimalStateProvider} from "app/animals/utils/AnimalContext";

const VersionsContainer = (props) => {

    const {remoteAnimal, ...otherProps} = props;

    const checkIfVersionAvailableForEdit = useCallback(
        (version) => remoteAnimal?.owner?.id === version?.owner?.id,
        [remoteAnimal?.owner?.id]
    );

    return <MainVersionsContainer
        {...otherProps}
        path={"/animals/" + props.id + "/versions"}
        isVersionAvailableForEdit={checkIfVersionAvailableForEdit}
        onResolve={(data) => ({
            ...data,
            entity: {
                ...animalResolver(data.entity)
            }
        })}>
        <CompareComponent/>

    </MainVersionsContainer>
}


const CompareComponent = (props) => {

    const {version, changes, diffs, fullChanged} = props;

    if (!version)
        return <></>;

    return <AnimalStateProvider animal={version.entity}>
        <FormComponent
            compareMode={true}
            showOwnerLink={true}
            fullChanged={fullChanged}
            changes={changes}
            errors={diffs}
        />
    </AnimalStateProvider>
}


export default VersionsContainer;
