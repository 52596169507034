import {useMutate} from "system/Rest/Rest";
import {useCallback} from "react";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";

const useLockAnimal = (id, transponder, reload) => {

    const showToast = useShowToast();

    const {t} = useTranslation('animal');

    const {mutate: lockAnimal, loading, error} = useMutate({
        verb: "PUT",
        path: "/animals/" + id + "/lock",
    });

    const onLock = useCallback(
        (lock) => {
            lockAnimal({
                isLocked: lock
            }).then(() => {

                showToast({
                    title: t('SUCCESS_LOCKING_ANIMAL'),
                    message: t(lock ? 'SUCCESS_LOCKING_ANIMAL_MSG' : 'SUCCESS_UNLOCKING_ANIMAL_MSG', {transponder: transponder}),
                    icon: faCheck,
                });

                reload();

            })
        },

        [showToast, transponder, t, lockAnimal, reload]
    );

    return {onLock, loading, error};
}

export default useLockAnimal;
