import React, {createContext, useEffect, useMemo, useState} from "react"
import {getEmptyAnimal} from "./AnimalResolver";

const AnimalStateContext = createContext({});

function useAnimalState() {
    const context = React.useContext(AnimalStateContext)
    if (!context) {
        throw new Error(`useAnimalState must be used within a AnimalStateContext`)
    }
    return context
}


const AnimalStateProvider = (props) => {

    const {animal: remoteAnimal} = props;
    const [animal, setAnimal] = useState(remoteAnimal ? remoteAnimal : getEmptyAnimal());

    const value = useMemo(
        () => ({
            animal,
            setAnimal,
        }),
        [animal, setAnimal]
    );

    //Update Remote Animal
    useEffect(() => {
        if (remoteAnimal)
            setAnimal(remoteAnimal);
    }, [remoteAnimal])

    return <AnimalStateContext.Provider value={value}>
        {props.children}
    </AnimalStateContext.Provider>;

}

export {AnimalStateProvider, useAnimalState}
