import Modal from "react-bootstrap/Modal";
import {Col, Row} from "react-bootstrap";
import RemoteListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RemoteListDropdown";
import Button from "react-bootstrap/Button";
import React, {useCallback, useState} from "react";
import {Trans} from "react-i18next";
import ValidationStatus from "types/ValidationStatus";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import "app/control/assets/css/set-state-modal.scss";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/control/locale/locales";

const SetStateModalComponent = props => {

    const {t, onHide, reasonsUrl, onChangeState, message, state} = props;
    const [comment, setComment] = useState("");

    const {t: genericTranslation} = useTranslations("control", locale);

    const isMoreInformation = (state === ValidationStatus.MORE_INFORMATION_NEEDED);

    const handleReject = useCallback(
        () => onChangeState(state, comment),
        [comment, onChangeState, state]
    )


    return <Modal show={true} onHide={onHide} className={"set-state-modal modal-pre-register"} backdrop="static" keyboard={false} size={"lg"}>
        <Modal.Header closeButton>
            <Modal.Title>{isMoreInformation ? t('request_more_information') : t('reject')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            {message &&
            <Row>
                <Col lg={12} sm={12}>
                    <Trans>{message}</Trans>
                </Col>
            </Row>
            }

            <Row>
                <Col lg={12} sm={12}>
                    {isMoreInformation
                        ? t('more_information_message_helper')
                        : t('reject_message_helper')
                    }

                </Col>
                {reasonsUrl &&
                <Col lg={12} sm={12}>
                    {reasonsUrl
                        ? <Row className={"set-state-modal-form"}>
                            <Form>
                                <Col lg={12} sm={12}>

                                    <FormGroup>
                                        <Form.Label>{genericTranslation('response_model')}</Form.Label>
                                        <RemoteListDropdown
                                            url={reasonsUrl}
                                            value={comment}
                                            isCreatable={true}
                                            onChange={setComment}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg={12} sm={12}>
                                    <FormGroup>
                                        <Form.Label>{genericTranslation('response')}</Form.Label>
                                        <Form.Control
                                            type="textarea"
                                            as="textarea"
                                            rows={8}
                                            value={getOrEmpty(comment)}
                                            onChange={(e) => setComment(e.target.value)}

                                        />
                                    </FormGroup>
                                </Col>
                            </Form>
                        </Row>
                        : <></>
                    }
                </Col>
                }
            </Row>

            <Row className={"popup-buttons"}>
                <Col lg={6} sm={6} xs={6}>
                    <Button variant="danger" onClick={onHide}>
                        {t("cancel")}
                    </Button>
                </Col>

                <Col lg={6} sm={6} xs={6} className={"text-end"}>
                    <Button variant="primary" onClick={handleReject}>
                        {isMoreInformation ? t("request_more_information") : t("reject")}
                    </Button>
                </Col>

            </Row>
        </Modal.Body>
    </Modal>

}

export default SetStateModalComponent;
