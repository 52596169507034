import React, {useCallback, useEffect, useState} from "react";
import FormContainer from "../containers/FormContainer";

const RegisterInterventionComponent = React.forwardRef((props, ref) => {

    const {animal} = props;
    const [isVisible, setVisible] = useState(false);
    const [type, setType] = useState(undefined);

    const handleVisible = useCallback(() => {
        setType(undefined);
        setVisible(true);
    }, [setVisible]);

    const openWithType  = useCallback((type) => {
        setVisible(true);
        setType(type);
    }, [setVisible]);

    //Add setVisible to the ref
    useEffect(() => {
        if (ref) {
            ref.current = {
                setVisible: handleVisible,
                openWithType: openWithType
            }
        }
    }, [ref, handleVisible,openWithType]);


    return <>

        {props.children &&  props.children({
            setVisible: handleVisible,
            openWithType: openWithType,
            active: isVisible,
        })}

        {isVisible &&
        <FormContainer
            {...props}
            type={type}
            transponder={animal?.transponder}
            onClose={() => setVisible(false)}
            create={true}
        />
        }
    </>

});

export default RegisterInterventionComponent;
