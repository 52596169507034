import React from "react";
import {Route} from 'react-router-dom';
import Home from "app/home/Home"
import Login from "app/login/Login"
import Recovery from "app/login/Recovery"
import PersonalArea from "app/personalArea/PersonalArea"
import Logout from "app/login/Logout"
import Owner from "app/owners/Owner";
import AnimalList from "app/animals/List";
import Animal from "app/animals/Animal";
import AnimalCreate from "app/animals/Create";
import PreRegister from "app/control/modules/preRegister/Create";
import PreRegisterList from "app/control/modules/preRegister/TabList";
import PreRegisterShow from "app/control/modules/preRegister/Show";
import TransferAnimal from "app/control/modules/transfers/TransferAnimal";
import TransferList from "app/control/modules/transfers/TabList";
import TransferShow from "app/control/modules/transfers/Show";
import Validate from "app/certificates/Validate";
import NotificationList from "app/notifications/List";
import SupportList from "app/support/List";
import SupportTicket from "app/support/Ticket";
import Changelog from "app/home/Changelog";
import OMVLoginRedirectContainer from "app/login/containers/OMVLoginRedirectContainer";
import ErrorPage from "layout/modules/ErrorPages/ErrorPage";
import NotFound from "layout/modules/ErrorPages/NotFound";
import LoginLayout from "layout/LoginLayout";
import MainLayout from "layout/MainLayout";
import PublicFormLayout from "layout/PublicFormLayout";
import ErrorInterceptor from "modules/ErrorBoundary/ErrorInterceptor";
import TwoFactorAuthContainer from "app/login/containers/TwoFactorAuthContainer";
import lazyWithRetry from "system/System/lazyWithRetry";

//Lazy Loads
const PriceTable = lazyWithRetry(() => import("app/pricingTables/PriceTable"));
const PriceTableCreate = lazyWithRetry(() => import("app/pricingTables/Create"));
const EntityVeterinaryList = lazyWithRetry(() => import("app/veterinaries/EntityVeterinaryList"));
const VeterinaryList = lazyWithRetry(() => import("app/veterinaries/List"));
const Veterinary = lazyWithRetry(() => import("app/veterinaries/Veterinary"));
const VeterinaryByBallotNumber = lazyWithRetry(() => import("app/veterinaries/VeterinaryByBallotNumber"));
const OwnerList = lazyWithRetry(() => import("app/owners/List"));
const OwnerReview = lazyWithRetry(() => import("app/owners/Review"));
const OwnerCreate = lazyWithRetry(() => import("app/owners/Create"));
const LostList = lazyWithRetry(() => import("app/animals/LostList"));
const BillingList = lazyWithRetry(() => import("app/billing/List"));
const BillingCreate = lazyWithRetry(() => import("app/billing/Create"));
const BillingReferences = lazyWithRetry(() => import("app/billing/References"));
const TemporaryFile = lazyWithRetry(() => import("app/files/TemporaryFile"));
const FileManagement = lazyWithRetry(() => import("app/files/FileManagement"));
const GeneralFiles = lazyWithRetry(() => import("app/files/GeneralFiles"));

const CertificateList = lazyWithRetry(() => import("app/certificates/List"));
const Certificate = lazyWithRetry(() => import("app/certificates/Certificate"));
const CertificateCreate = lazyWithRetry(() => import( "app/certificates/Create"));
const VeterinaryEntities = lazyWithRetry(() => import("app/login/VeterinaryEntities"));
const PriceTableList = lazyWithRetry(() => import("app/pricingTables/List"));

const EntityList = lazyWithRetry(() => import("app/entities/List"));
const Entity = lazyWithRetry(() => import("app/entities/Entity"));
const EntityByVat = lazyWithRetry(() => import("app/entities/EntityByVatNumber"));
const MyEntity = lazyWithRetry(() => import("app/entities/MyEntity"));
const EntityFiles = lazyWithRetry(() => import( "app/entities/Files"));
const EntityCreate = lazyWithRetry(() => import( "app/entities/Create"));

const UserList = lazyWithRetry(() => import("app/users/List"));
const User = lazyWithRetry(() => import("app/users/User"));
const UserCreate = lazyWithRetry(() => import("app/users/Create"));
const SetPassword = lazyWithRetry(() => import("app/login/SetPassword"));

const RoleList = lazyWithRetry(() => import("app/roles/List"));
const Role = lazyWithRetry(() => import("app/roles/Role"));
const RoleCreate = lazyWithRetry(() => import("app/roles/Create"));
const RoleBulkEdit = lazyWithRetry(() => import("app/roles/BulkEdit"));

const EntityProfileList = lazyWithRetry(() => import("app/entityProfiles/List"));
const EntityProfileCreate = lazyWithRetry(() => import("app/entityProfiles/Create"));
const EntityProfile = lazyWithRetry(() => import("app/entityProfiles/EntityProfile"));

const VaccineList = lazyWithRetry(() => import("app/vaccines/List"));
const VaccineCreate = lazyWithRetry(() => import("app/vaccines/Create"));
const Vaccine = lazyWithRetry(() => import("app/vaccines/Vaccine"));

const BreedList = lazyWithRetry(() => import("app/breeds/List"));
const BreedCreate = lazyWithRetry(() => import("app/breeds/Create"));
const Breed = lazyWithRetry(() => import("app/breeds/Breed"));

const Audit = lazyWithRetry(() => import("app/audit/List"));

const Configuration = lazyWithRetry(() => import("app/configuration/Configuration"));

const TraceabilityList = lazyWithRetry(() => import("app/traceability/ListTransactions"));
const Transaction = lazyWithRetry(() => import("app/traceability/View"));
const TransponderList = lazyWithRetry(() => import("app/traceability/ListTransponders"));

const LostAnimalPendingList = lazyWithRetry(() => import("app/control/modules/pendingLost/List"));
const LostAnimalPending = lazyWithRetry(() => import("app/control/modules/pendingLost/PendingLost"));

const GenerateReport = lazyWithRetry(() => import("app/reporting/Generate"));
const ReportTemplateList = lazyWithRetry(() => import("app/reporting/TemplateList"));
const ReportTemplateCreate = lazyWithRetry(() => import("app/reporting/CreateTemplate"));
const ReportTemplate = lazyWithRetry(() => import("app/reporting/Template"));

const ReportScheduleList = lazyWithRetry(() => import("app/reporting/ScheduleList"));
const ReportSchedule = lazyWithRetry(() => import("app/reporting/Schedule"));
const ReportScheduleCreate = lazyWithRetry(() => import("app/reporting/ScheduleCreate"));

const OperationTemplateList = lazyWithRetry(() => import("app/operations/TemplateList"));
const OperationTemplate = lazyWithRetry(() => import("app/operations/OperationTemplate"));
const OperationTemplateCreate = lazyWithRetry(() => import("app/operations/CreateTemplate"));
const ExecuteOperation = lazyWithRetry(() => import("app/operations/ExecuteOperation"));

const OperationSchedule = lazyWithRetry(() => import("app/operations/Schedule"));
const OperationScheduleList = lazyWithRetry(() => import("app/operations/ScheduleList"));
const OperationScheduleCreate = lazyWithRetry(() => import("app/operations/ScheduleCreate"));

const MergedOwnerList = lazyWithRetry(() => import("app/control/modules/mergedOwners/List"));
const MergedOwner = lazyWithRetry(() => import("app/control/modules/mergedOwners/Show"));


const OwnerDataList = lazyWithRetry(() => import("app/control/modules/ownerData/List"));
const OwnerData = lazyWithRetry(() => import("app/control/modules/ownerData/Show"));


const PublicAnimalTransfer = lazyWithRetry(() => import("app/publicForms/AnimalTransfer"));
const PublicFormPersonalData = lazyWithRetry(() => import("app/publicForms/PersonalData"));
const PublicFormAnimalDeath = lazyWithRetry(() => import("app/publicForms/AnimalDeath"));
const PublicFormHerdingDog = lazyWithRetry(() => import("app/publicForms/HerdingDog"));
const PublicFormDiac = lazyWithRetry(() => import("app/publicForms/Diac"));

const RequestDeathList = lazyWithRetry(() => import("app/control/modules/animalDeath/List"));
const RequestDeath = lazyWithRetry(() => import("app/control/modules/animalDeath/Show"));

const RequestDiacList = lazyWithRetry(() => import("app/control/modules/diac/List"));
const RequestDiac = lazyWithRetry(() => import("app/control/modules/diac/Show"));

const HerdingDogList = lazyWithRetry(() => import("app/control/modules/herdingDog/List"));
const HerdingDog = lazyWithRetry(() => import("app/control/modules/herdingDog/Show"));

const BulkTransferAnimal = lazyWithRetry(() => import("app/control/modules/transfers/BulkTransferAnimal"));

const ControlByLabels = lazyWithRetry(() => import("app/adminLabels/containers/ListByLabelContainer"));


const routes = <Route error errorElement={<ErrorInterceptor/>}>

    <Route path={"/forms"} element={<PublicFormLayout/>}>
        <Route path={"animal-transfer"} element={<PublicAnimalTransfer/>}/>
        <Route path={"personal-data"} element={<PublicFormPersonalData/>}/>
        <Route path={"request-death"} element={<PublicFormAnimalDeath/>}/>
        <Route path={"validate-herding-dog"} element={<PublicFormHerdingDog/>}/>
        <Route path={"diac"} element={<PublicFormDiac/>}/>
    </Route>

    <Route path="/temp-docs/" element={<LoginLayout/>}>
        <Route path=":id" element={<TemporaryFile/>}/>
    </Route>


    <Route path="/auth" element={<LoginLayout/>}>
        <Route index element={<Login/>}/>
        <Route path="recovery" element={<Recovery/>}/>
        <Route path="reset/:id/:token" element={<SetPassword/>}/>
        <Route path="2fa/:token/:code" element={<TwoFactorAuthContainer/>}/>
        <Route path="activate/:id/:token" element={<SetPassword activate={true}/>}/>
        <Route path="entities" element={<VeterinaryEntities/>}/>
        <Route path="omv" element={<OMVLoginRedirectContainer/>}/>
    </Route>

    <Route path="/" element={<MainLayout/>}>
        <Route path="/logout" element={<Logout/>}/>
        <Route index element={<Home/>}/>
        <Route path={"/personal"} element={<PersonalArea/>}/>
        <Route path={"/personal/files/:fileUniversalSearch"} element={<PersonalArea showTab={"files"}/>}/>
        <Route path={"/personal/audit"} element={<PersonalArea showTab={"audit"}/>}/>
        <Route path={"/personal/entities"} element={<PersonalArea showTab={"entities"}/>}/>
        <Route path={"/myEntity"} element={<MyEntity/>}/>
        <Route path={"/entity"} element={<EntityList/>}/>
        <Route path={"/entity/:id"} element={<Entity/>}/>
        <Route path={"/entity/by-vat/:vatNumber"} element={<EntityByVat/>}/>
        <Route path={"/entity/create"} element={<EntityCreate/>}/>
        <Route path={"/entity/files"} element={<EntityFiles/>}/>
        <Route path={"/entity-profile"} element={<EntityProfileList/>}/>
        <Route path={"/entity-profile/create"} element={<EntityProfileCreate/>}/>
        <Route path={"/entity-profile/:id"} element={<EntityProfile/>}/>

        <Route path={"/user"}>
            <Route index element={<UserList/>}/>
            <Route path={"create"} element={<UserCreate/>}/>
            <Route path={":id"} element={<User/>}/>
        </Route>

        <Route path={"/role"} element={<RoleList/>}/>
        <Route path={"/role/create"} element={<RoleCreate/>}/>
        <Route path={"/role/bulk"} element={<RoleBulkEdit/>}/>
        <Route path={"/role/:id"} element={<Role/>}/>
        <Route path={"/breed"} element={<BreedList/>}/>
        <Route path={"/breed/create"} element={<BreedCreate/>}/>
        <Route path={"/breed/:id/create"} element={<BreedCreate/>}/>
        <Route path={"/breed/:id"} element={<Breed/>}/>
        <Route path={"/veterinary-management"} element={<EntityVeterinaryList/>}/>
        <Route path={"/veterinary"} element={<VeterinaryList/>}/>
        <Route path={"/veterinary/:id"} element={<Veterinary/>}/>
        <Route path={"/veterinary/byBallot/:ballot"} element={<VeterinaryByBallotNumber/>}/>
        <Route path={"/pricing-tables"} element={<PriceTableList/>}/>
        <Route path={"/pricing-tables/:id"} element={<PriceTable/>}/>
        <Route path={"/pricing-tables/create"} element={<PriceTableCreate/>}/>
        <Route path={"/owner/:id"} element={<Owner/>}/>
        <Route path={"/owner"} element={<OwnerList/>}/>
        <Route path={"/owner-review"} element={<OwnerReview/>}/>
        <Route path={"/owner/create"} element={<OwnerCreate/>}/>
        <Route path={"/animal/pre-register"} element={<PreRegister/>}/>
        <Route path={"/pre-registers/:id"} element={<PreRegisterShow/>}/>
        <Route path={"/pre-registers"} element={<PreRegisterList/>}/>
        <Route path={"/animal"} element={<AnimalList/>}/>
        <Route path={"/animal/lost"} element={<LostList/>}/>
        <Route path={"/animal/create"} element={<AnimalCreate/>}/>
        <Route path={"/animal/view/:id"} element={<Animal/>}/>
        <Route path={"/transfers"} element={<TransferList/>}/>
        <Route path={"/transfers/:id"} element={<TransferShow/>}/>
        <Route path={"/animal/owner/change/:id"} element={<TransferAnimal/>}/>
        <Route path={"/animal/owner/change"} element={<BulkTransferAnimal/>}/>
        <Route path={"/vaccine"} element={<VaccineList/>}/>
        <Route path={"/vaccine/create"} element={<VaccineCreate/>}/>
        <Route path={"/vaccine/:id"} element={<Vaccine/>}/>
        <Route path={"/file-management"} element={<FileManagement/>}/>
        <Route path={"/general-files"} element={<GeneralFiles/>}/>
        <Route path={"/certificate"} element={<CertificateList/>}/>
        <Route path={"/certificate/:id"} element={<Certificate/>}/>
        <Route path={"/certificate/create"} element={<CertificateCreate/>}/>
        <Route path={"/validate"} element={<Validate/>}/>
        <Route path={"/billing/charging"} element={<BillingReferences/>}/>
        <Route path={"/billing/transactions"} element={<BillingList useUserEntity={true}/>}/>
        <Route path={"/pricing-tables/transactions"} element={<BillingList/>}/>
        <Route path={"/pricing-tables/transactions/create"} element={<BillingCreate/>}/>
        <Route path={"/configuration"} element={<Configuration/>}/>
        <Route path={"/audit"} element={<Audit/>}/>
        <Route path={"/traceability/show/:id"} element={<Transaction/>}/>
        <Route path={"/traceability/transponders"} element={<TransponderList/>}/>
        <Route path={"/traceability/"} element={<TraceabilityList/>}/>
        <Route path={"/notifications/"} element={<NotificationList/>}/>

        <Route path={"/support"}>
            <Route index element={<SupportList/>}/>
            <Route path={":id"} element={<SupportTicket/>}/>
        </Route>
        <Route path={"/changelog"} element={<Changelog/>}/>
        <Route path={"/pending-lost"} element={<LostAnimalPendingList/>}/>
        <Route path={"/pending-lost/:id"} element={<LostAnimalPending/>}/>
        <Route path={"/reports/schedule/:id"} element={<ReportSchedule/>}/>
        <Route path={"/reports/schedule"} element={<ReportScheduleList/>}/>
        <Route path={"/reports/schedule/create"} element={<ReportScheduleCreate/>}/>
        <Route path={"/reports"} element={<GenerateReport/>}/>
        <Route path={"/report-template"} element={<ReportTemplateList/>}/>
        <Route path={"/report-template/:id"} element={<ReportTemplate/>}/>
        <Route path={"/report-template/create"} element={<ReportTemplateCreate/>}/>
        <Route path={"/owner-merges"} element={<MergedOwnerList/>}/>
        <Route path={"/owner-merges/:id"} element={<MergedOwner/>}/>
        <Route path={"/request-death"} element={<RequestDeathList/>}/>
        <Route path={"/request-death/:id"} element={<RequestDeath/>}/>

        <Route path={"/request-diac"} element={<RequestDiacList/>}/>
        <Route path={"/request-diac/:id"} element={<RequestDiac/>}/>

        <Route path={"/herding-dog"} element={<HerdingDogList/>}/>
        <Route path={"/herding-dog/:id"} element={<HerdingDog/>}/>
        <Route path={"/owner-data"} element={<OwnerDataList/>}/>
        <Route path={"/owner-data/:id"} element={<OwnerData/>}/>
        <Route path={"/operation-template"} element={<OperationTemplateList/>}/>
        <Route path={"/operation-template/:id"} element={<OperationTemplate/>}/>
        <Route path={"/operation-template/create"} element={<OperationTemplateCreate/>}/>
        <Route path={"/operations"} element={<ExecuteOperation/>}/>
        <Route path={"/operations/schedule/:id"} element={<OperationSchedule/>}/>
        <Route path={"/operations/schedule"} element={<OperationScheduleList/>}/>
        <Route path={"/operations/schedule/create"} element={<OperationScheduleCreate/>}/>
        <Route path={"/labels/list-by-label"} element={<ControlByLabels/>}/>

        <Route path="error" element={<ErrorPage/>}/>
        <Route path={"notFound"} element={<NotFound/>}/>
        <Route path={"*"} element={<NotFound/>}/>

    </Route>
</Route>

export {routes};
