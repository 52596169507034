import React from "react";
import Container from "react-bootstrap/Container";
import ListEntryComponent from "./ListEntryComponent";
import {Link} from "react-router-dom";
import Box from "layout/modules/Box/Box";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import Loading from "layout/modules/Loading/Loading";
import BoxScrollComponent from "layout/modules/Box/components/BoxScrollContent";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/notifications/locales/locales";

const ListComponent = (props) => {

    const {t} = useTranslations('remoteNotifications', locale);

    const {
        data,
        onPageChange,
        loading,
        dashboard,
    } = props;


    return <Container fluid>

        <Box className={"notification-box"}>

            <div className={"header"}>
                {dashboard ? t('dashboard_title') : t('notifications')}

                {dashboard &&
                <div className={"view-all"}>
                    <Link to={"/notifications/"}>
                        {t('view_all')}
                    </Link>
                </div>
                }

            </div>

            {!dashboard &&
            <SearchDataPagination data={data} onPageChange={onPageChange}/>
            }
            <Loading visible={loading || !data}/>

            <BoxScrollComponent disabled={!dashboard}>
                <div className={"notification-list"}>
                    {data?.content?.map(
                        (notification, id) => <ListEntryComponent key={id} notification={notification}/>
                    )}
                </div>


                {data?.content?.length === 0 &&
                <div className={"no-table-results"}>
                    {t('no_results')}
                </div>
                }
            </BoxScrollComponent>
        </Box>
    </Container>

}


export default ListComponent;
