import React from "react";
import Loading from "layout/modules/Loading/Loading";
import AnimalCardComponent from "app/animals/components/list/implementations/cardList/AnimalCardComponent";

import "app/animals/assets/css/card-list.scss";
import NoResultsComponent from "app/animals/components/list/implementations/NoResultsComponent";
import ExternalAnimalCardComponent from "app/animals/components/list/implementations/cardList/ExternalAnimalCardComponent";

const CardListComponent = (props) => {

    const {data, loading, searchFilter} = props;

    if (!data) {
        return <Loading visible={loading}/>
    }

    return <>
        <Loading visible={loading} localOnly={true}/>
        <div className={"card-list-container"}>
            <div className={"card-list"}>
                {data?.content?.map((value, index) => {
                    return <div className={"card-container"} key={index}>
                        {!value.isExternal
                            ? <AnimalCardComponent {...props} animal={value}/>
                            : <ExternalAnimalCardComponent {...props} animal={value}/>
                        }
                    </div>
                })}

            </div>

            <NoResultsComponent
                {...props}
                data={data?.content}
                searchQuery={searchFilter?.universalSearch}
                loading={loading}/>

        </div>
    </>;
}

export default CardListComponent;
