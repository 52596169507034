import {defaultOwnerProps} from "app/owners/utils/form/defaultOwnerProps";
import appConfig from "configs/config";

const ownerResolver = (data) => ({
    ...defaultOwnerProps,
    ...data,
    parishId: data.parish?.id,
    hasLocalVatNumber: (data?.hasLocalVatNumber || data?.country === appConfig.defaultCountry)
});

const historyOwnerResolver = (data) => ({
    ...data,
    entity: {
        ...data.entity,
        parishId: data.entity.parish?.id,
    }
});

export default ownerResolver;
export {historyOwnerResolver};
