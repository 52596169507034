import {faExclamation, faInfo, faTimes} from "@fortawesome/free-solid-svg-icons";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import ExternalValidationMessages from "app/control/modules/preRegister/components/show/ExternalValidationMessages";
import React from "react";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";
import DuplicatedEntryMessage from "app/control/modules/preRegister/components/show/DuplicatedEntryMessage";


const ValidationHelperMessages = props => {

    const {t, request} = props;

    return <>

        <DuplicatedEntryMessage {...props}/>

        <ExternalValidationMessages {...props}/>

        {request?.isPotentiallyDanger &&
        <AdvanceInfoMessage
            icon={faExclamation}
            type={"danger"}
            iconType={"danger"}
            title={t("animal_is_danger")}
            message={t('animal_is_danger_msg')}
        />
        }

        <BlockingMessages {...props} />

    </>

}



const BlockingMessages = props => {

    const {t, request} = props;
    const validation = request?.transponderValidation;

    if (request?.transponderValidation?.alreadyRegistered) {
        return <AdvanceInfoMessage
            icon={faTimes}
            type={"danger"}
            iconType={"danger"}
            title={t("transponder_already_registered")}
        >
            {t('transponder_already_registered_msg_1')}
            <Link to={"/animal/view/" + request?.transponder}>
                {request?.transponder}
            </Link>
            {t('transponder_already_registered_msg_2')}
        </AdvanceInfoMessage>

    } else if (validation?.cannotBeAccepted && validation?.currentEntityInTraceability) {
        return <AdvanceInfoMessage
            icon={faExclamation}
            type={"danger"}
            iconType={"danger"}
            title={t("transponder_pending_entity")}
        >
            <Trans
                i18nKey={'transponder_pending_entity_msg'}
                t={t}
                values={{
                transponder: request?.transponder,
                entity: validation?.currentlyInTransactionTo?.name,
                vatNumber: validation?.currentlyInTransactionTo?.vatNumber,
            }}
            components={
                [
                    <Link to={'/entity/by-vat/' + validation?.currentlyInTransactionTo?.vatNumber}>
                        {validation?.currentlyInTransactionTo?.vatNumber}
                    </Link>
                ]
            }
            >
            </Trans>
        </AdvanceInfoMessage>
    } else if (!validation?.cannotBeAccepted && validation?.currentEntityInTraceability) {
        return <AdvanceInfoMessage
            icon={faInfo}
            type={"info"}
            iconType={"info"}
            title={t("transponder_in_entity")}>
            <Trans
                i18nKey={'transponder_in_entity_msg'}
                t={t}
                values={{
                    transponder: request?.transponder,
                    entity: validation?.currentEntityInTraceability?.name,
                    vatNumber: validation?.currentEntityInTraceability?.vatNumber,
                    registerEntity: request?.entity?.name,
                    registerVatNumber: request?.entity?.vatNumber,
                }}
                components={
                    [
                        <Link to={'/entity/by-vat/' + validation?.currentEntityInTraceability?.vatNumber}>
                            {validation?.currentEntityInTraceability?.vatNumber}
                        </Link>
                    ]
                }
            >
            </Trans>
        </AdvanceInfoMessage>
    } else return <></>
}

export default ValidationHelperMessages;
