import {useLocation} from "react-router";
import {useGet} from "system/Rest/Rest";
import {useEffect, useMemo, useState} from "react";
import ButtonComponent from "./components/ButtonComponent";

const Observations = (props) => {

    const location = useLocation();
    const [count, setCount] = useState(0);
    const {location: locationProps} = props;

    const pathName = locationProps ?? location?.pathname;

    const {data: countResponse, refetch} = useGet({
        path: "/observations/count",
        queryParams: useMemo(() => ({
            url: pathName
        }), [pathName]),
    });

    //Reset Count on Page Change
    useEffect(() => {
        setCount(countResponse?.totalComments);
    }, [countResponse])

    return <ButtonComponent {...props} count={count} path={pathName} reload={refetch}/>;

}

export default Observations;
