import {useEffect, useState} from "react"
import {useGet} from "system/Rest/Rest"
import {useApiState} from "system/API/APIStateContext";

//Function to obain a set of entities based on a entity array. (Entities are Roles, USers, Entities, etc)
function useRemoteEntity(data, path, key, permission, multiplePerEntry = false) {

    const [ids, setIds] = useState(null);

    const [entities, setEntities] = useState({});
    const [newData, loading] = useGetRemoteEntities(path, ids, permission)

    useEffect(
        () => newData?.forEach((entity) => setEntities((e) => ({
            ...e,
            [entity.id]: entity
        }))),
        [newData]
    );

    //Get entity List
    useEffect(
        () => {

            let entityIds = [];
            if (!multiplePerEntry)
                entityIds = [...new Set(data?.content?.map(entry => entry[key]))].filter(i => i !== undefined);
            else {
                entityIds = [...new Set(data?.content?.map(entry => entry[key])
                    .filter(i => (i !== undefined && i.length > 0))
                    .reduce((arr, item) => arr.concat(item), []))];
            }

            if (entityIds.every(id => entities.hasOwnProperty(id))) {
                return;
            }
            setIds(entityIds);
        },
        [entities, data, setIds, key, multiplePerEntry]
    );

    return [entities, loading];
}


const useGetRemoteEntities = (path, entityIds, permission) => {

    const {authentication} = useApiState();
    const {permissions = []} = authentication.role;

    const {data, loading, refetch: getEntities} = useGet({
        path: path,
        lazy: true,
        localErrorOnly: true,
        resolve: (data) => data.content,
    });

    useEffect(
        () => {

            if (!entityIds || entityIds.length === 0) {
                return;
            }

            if (!permission || permissions.includes(permission)) {
                const ids = [...new Set(entityIds)].filter(i => i !== undefined);
                getEntities({
                    queryParams: {
                        ids: ids.join(),
                        itemsPerPage: entityIds.length
                    }
                });
            }
        },
        // eslint-disable-next-line
        [entityIds, permission, permissions]
    );


    return [data, loading];


}

export {useRemoteEntity, useGetRemoteEntities};
