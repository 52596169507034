import {useTranslation} from "react-i18next";
import React, {useCallback} from "react";
import {useMutate} from "system/Rest/Rest";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import useErrorParser from "system/API/Error/ErrorParser";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import ClosedLostComponent from "app/animals/components/lost/show/ClosedLostComponent";

const ClosedLostContainer = React.forwardRef((props, ref) => {

    const {transponder, reload} = props;
    const showToast = useShowToast();
    const {t} = useTranslation("animal");

    const {mutate: submit, loading, error} = useMutate({
        verb: "PUT",
        path: "/lost-animals/" + transponder + "/update-status",
    });

    const errors = useErrorParser(error);

    return <ClosedLostComponent
        {...props}
        ref={ref}
        errors={errors}
        loading={loading}
        onSubmit={useCallback((props) => {
            submit({
                ...props,
            }).then(() => {
                showToast({
                    title: t('SUCCESS_SET_CLOSED'),
                    message: t('SUCCESS_SET_CLOSED_MSG', {transponder: transponder}),
                    icon: faCheck,
                });
                reload();
            }).catch(e => {
                showToast({
                    title: t('ERROR_SET_CLOSED'),
                    message: t(e.data?.message ? e.data?.message : 'ERROR_SET_CLOSED'),
                    icon: faTimes,
                    className: 'error',
                });
            });

        }, [showToast, t, transponder, submit, reload])}

    />


});

export default ClosedLostContainer;
