import Form from "react-bootstrap/Form";
import React, {useCallback, useMemo} from "react";
import useFirstElementIfUnique from "system/Arrays/useFirstElementIfUnique";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import CreatableSingle from "layout/modules/Forms/Dropdowns/CreatableSingle/CreatableEditableSelect";

const LocalityComponent = (props) => {

    const {t, errors = {}, data, addresses, changes = {}, onChange, readOnly, isLoading} = props;

    const localityList = useMemo(
        () => addresses?.map(address => address.locality)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((locality) => ({
                value: locality,
                label: locality,
            })),
        [addresses]
    );

    //Adjust data if only one address was recived from server
    useFirstElementIfUnique(localityList,
        locality => {
            onChange(address => ({
                ...address,
                locality: address?.locality ? address.locality : locality.value,
            }));
        });

    return <FormGroup error={errors['locality']} changes={changes['locality']}>
        <Form.Label>{t('locality')}</Form.Label>
        <CreatableSingle
            id="locality"
            placeholder={useMemo(() => t('locality_ph'),[t])}
            noOptionsMessage={useCallback(() => t('no_locality_suggestions'),[t])}
            formatCreateLabel={useCallback(s => t('use', {value: s}),[t])}
            classNamePrefix="rs"
            className="rs"
            isClearable={true}
            isLoading={isLoading}
            value={useMemo( () => data?.locality ? {value: data.locality, label: data.locality} : null, [data?.locality])}
            isDisabled={readOnly || !data.zipCodeConfirmed}
            options={localityList}
            onChange={useCallback(value => onChange(address => ({
                ...address,
                locality: value?.value,
                parish: undefined,
            })),[onChange])}

        />
        <Form.Text className="sub-error">
            {t(errors['locality'])}
        </Form.Text>
    </FormGroup>
}

export default LocalityComponent;
