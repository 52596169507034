import BaseLoginLayout from "app/login/components/BaseLoginLayout";
import logo from "layout/assets/images/logo.png";
import Loading from "layout/modules/Loading/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InfoMessage from "layout/modules/InfoMessages/InfoMessage";
import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import locale from "app/login/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import AuthCode from "react-auth-code-input";
import {Trans} from "react-i18next";
import "app/login/assets/css/two-factor.scss"

const TwoFactorAuthComponent = props => {

    const {loading, error, onAuthenticate, codeValidity, onCodeExpired} = props;
    const {t} = useTranslations('login', locale);
    const [code, setCode] = useState("");

    const isInvalid = code?.length < 6 || !code;

    const [remainValidity, setRemainValidity] = useState({minutes: 5, seconds: 59});

    useEffect(() => {
        const interval = setInterval(() => {
            const remainingTime = (codeValidity - Date.now()) / 1000 ;

            if (remainingTime > 0) {
                setRemainValidity({
                    minutes: (remainingTime - (remainingTime % 60)) / 60,
                    seconds: Math.round(remainingTime % 60),
                });
            }
            else {
             onCodeExpired();
            }

        }, 1000);

        return () => clearInterval(interval);
    }, [setRemainValidity, codeValidity, onCodeExpired]);


    return <BaseLoginLayout>

        <div className="login-bar">
            <img src={logo} className="logo" alt={"logo"}/>
        </div>

        <div className="login-content">
            <Loading visible={loading}/>

            <div className={"login-content-area"}>
                <Row className={["justify-content-md-center"]}>
                    <Col md="auto" className="login-title">
                        {t('title_2fa')}
                    </Col>
                </Row>

                <Row className={["justify-content-md-center", "login-side-bars"]}>
                    <Col className={["login-form-area"]}>

                        <InfoMessage type={"info"}>
                            <Trans>{t('description_2fa',remainValidity)}</Trans>
                        </InfoMessage>

                        <Form autoComplete="off">

                            <FormGroup error={error}>
                                <div className={"code-area"}>
                                    <AuthCode
                                        allowedCharacters='numeric'
                                        length={6}
                                        inputClassName='form-control'
                                        onChange={setCode}
                                    />
                                </div>
                                <Form.Text className="sub-label">

                                </Form.Text>
                            </FormGroup>


                            <Row className="justify-content-md-center">
                                <Col className="text-center">
                                    <Button variant="primary"
                                            onClick={() => onAuthenticate(code)}
                                            disabled={isInvalid}>
                                        {t('submit_2fa')}
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="exit-2fa justify-content-md-center">
                                <div  onClick={onCodeExpired}>
                                    {t('go_to_login')}
                                </div>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>

    </BaseLoginLayout>
}

export default TwoFactorAuthComponent;
