import AlertsComponent from "../components/AlertsComponent";
import {useGet} from "system/Rest/Rest";
import {useMemo} from "react";

const AlertsContainer = props => {

    const {data: information} = useGet({
        path: "/informations/",
        queryParams: useMemo(() => ({
            type: "DASHBOARD_HIGHLIGHTS"
        }), [])
    });

    if (!information?.content || information?.content?.length < 15) {
        return <></>
    }
    
    return <AlertsComponent
        {...props}
        information={information?.content}
    />
}

export default AlertsContainer;
