import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import {getOrDefault} from "system/Objects/ObjectParameters";
import {useLocation, useNavigate} from "react-router";
import BulkGenerateContainer from "app/certificates/containers/generate/BulkGenerateContainer";
import Box from "layout/modules/Box/Box";
import ListContainer from "app/animals/containers/ListContainer";
import {ListType} from "layout/modules/TableAdvanced/components/buttons/TableTypeButton";
import isPrintDIACAllowed, {isMultiSelectAllowed} from "app/owners/utils/isPrintDIACAllowed";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const AnimalListComponent = (props) => {

    const {id, owner} = props;
    const {t} = useTranslation('owner');
    const {state} = useLocation();

    const [selectedItems, setSelectedItems] = useState([]);
    const [isDiacPopupVisible, setDiacPopupVisible] = useState(false);

    const navigate = useNavigate();
    const onOwnerTransferClick = useCallback(
        () => navigate("/animal/owner/change", {
            state: {
                animals: selectedItems.map(i => ({
                    id: i.id,
                    transponder: i.transponder
                })),
            }
        }),
        [selectedItems, navigate]
    );

    return <Box>

        {isDiacPopupVisible &&
        <BulkGenerateContainer
            owner={owner}
            transponders={selectedItems.map(animal => animal.transponder)}
            onClose={() => setDiacPopupVisible(false)}
        />
        }

        <ListContainer
            ownerId={id}
            ownerAnimalTransponder={state?.animal}
            showAdd={false}
            type={ListType.LIST}
            checkboxSelectedItems={selectedItems}
            onCheckBoxChange={useCallback((animal, value) => setSelectedItems((items) => {
                if (value) {
                    return [...items, animal];
                } else {
                    return [...items].filter(item => item.id !== animal.id)
                }
            }), [setSelectedItems])}
            checkEnableCheckBox={isMultiSelectAllowed}
            paginationContent={useMemo(() => (
                    <PaginationContent
                        t={t}
                        onDiacBtnClick={() => setDiacPopupVisible(true)}
                        onOwnerTransferClick={onOwnerTransferClick}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}/>
                ),
                [t, selectedItems, setSelectedItems, onOwnerTransferClick])
            }
        />
    </Box>
}

const PaginationContent = (props) => {

    const {t, data, selectedItems, setSelectedItems, onDiacBtnClick, onOwnerTransferClick} = props;

    return <>
        <CheckBox
            label={t('item_selected', {count: selectedItems.length})}
            value={getOrDefault(selectedItems.length > 0, false)}
            square={data?.content?.length > selectedItems.length}
            disabled={useMemo(() => (!isPrintDIACAllowed(data?.content) && selectedItems.length === 0), [data, selectedItems])}
            onChange={(value) => {
                setSelectedItems(value ? data?.content?.filter(isMultiSelectAllowed) : [])
            }}

        />
        <Button variant="primary"
                disabled={!isPrintDIACAllowed(selectedItems)}
                className={"submit"}
                onClick={onDiacBtnClick}>
            {t('DIAC')}
        </Button>

        <UserHasPermission permission={"REQUEST_BULK_ANIMAL_TRANSFER"}>
            <Button variant="primary"
                    disabled={selectedItems.length === 0}
                    className={"submit"}
                    onClick={onOwnerTransferClick}>
                {t('bulk_owner_transfer_btn')}
            </Button>
        </UserHasPermission>
    </>
}


export default AnimalListComponent;
