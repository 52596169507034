import React from "react";
import DateTime from "system/DateTime/DateTime";
import classnames from "classnames";
import removeStylesFromHTML from "app/support/utils/removeStylesFromHTML";
import AttachmentComponent from "./AttachmentComponent";
import {ReactComponent as LogoSiac} from 'layout/assets/images/logo-small.svg'
import "app/support/assets/css/message.scss";
import {getInitials} from "app/support/utils/utils";

const MessageComponent = props => {

    const {attachments, description, timestamp, leftSide, user} = props;

    return <>
        <div className={classnames("message-block", {
            "left": leftSide,
            "right": !leftSide,
        })}>

            <div className={"main-block"}>

                <div className={"logo-user"}>
                    <div className={"image-spacing"}>
                        {leftSide && <LogoSiac/>}
                        {!leftSide && <div className={"letters"}>
                            {getInitials(user)}
                            </div>
                        }
                    </div>
                </div>

                <div className={"message-content"}>
                    <div className={"message"} dangerouslySetInnerHTML={{__html: removeStylesFromHTML(description)}}/>

                    <div className={"attachments"}>
                        {attachments?.map((at, aid) =>
                            <AttachmentComponent key={aid} attachment={at}/>
                        )}
                    </div>
                </div>
            </div>
            <div className={"date"}>
                <DateTime format={"DD-MM-YYYY | HH:mm"}>
                    {timestamp}
                </DateTime>
            </div>
        </div>


    </>
}

export default MessageComponent;
