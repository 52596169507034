import {useEffect} from "react";

const useClearUnusedFields = (readOnly, owner, model, setOwner) => {

    //Clear id card if not used
    useEffect(() => {

        if (readOnly) {
            return;
        }

        if (owner?.idCard && !model.cc) {
            setOwner(owner => ({
                ...owner,
                idCard: undefined,
            }));
        }
    }, [model.cc, owner?.idCard, readOnly, setOwner]);


    //Remove passport
    useEffect(() => {

        if (readOnly) {
            return;
        }

        if (owner?.passport && !model.passport) {
            setOwner(owner => ({
                ...owner,
                passport: undefined,
            }));
        }
    }, [model.passport, owner?.passport, readOnly, setOwner]);

    //Remove passport
    useEffect(() => {

        if (readOnly) {
            return;
        }

        if (owner?.vatNumber && !model.vat) {
            setOwner(owner => ({
                ...owner,
                vatNumber: undefined,
            }));
        }
    }, [model.vat, owner?.vatNumber, readOnly, setOwner]);

    //Remove address sub components
    useEffect(() => {

            if (readOnly) {
                return;
            }

            if (!owner?.isLocalResident) {
                if (owner?.postalCode || owner?.parish || owner?.locality) {
                    setOwner(owner => ({
                        ...owner,
                        address: undefined,
                        postalCode: undefined,
                        parish: undefined,
                        locality: undefined,
                        parishId: undefined,
                        county: undefined,
                        district: undefined,
                    }));
                }
            }
        },
        [owner?.isLocalResident, owner?.parish, owner?.locality, owner?.postalCode, readOnly, setOwner]
    );

}


export default useClearUnusedFields;
