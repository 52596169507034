import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faInfo, faUser, faUserSecret} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import TransferType from "app/control/modules/transfers/utils/TransferType";

const ManagementInfoMessages = props => {

    const {request, remoteNewOwner, t} = props;

    return <>
        {(!request.closed && remoteNewOwner && request.origin !== TransferType.OWNERS) &&
        <AdvanceInfoMessage
            icon={faInfo}
            type={"info"}
            iconType={"info"}
            title={t("new_owner_already_registered")}
            message={t('new_owner_already_registered_msg')}
        />
        }

        {request.origin === TransferType.OWNERS &&
        <AdvanceInfoMessage
            icon={faUser}
            type={"danger"}
            iconType={"danger"}
            title={t("owner_initiated_request")}
            message={t('owner_initiated_request_msg')}
        />
        }

        {request.origin === TransferType.PUBLIC &&
            <AdvanceInfoMessage
                icon={faUserSecret}
                type={"danger"}
                iconType={"danger"}
                title={t("site_initiated_request")}
                message={t('site_initiated_request_msg')}
            />
        }
    </>

}

export default ManagementInfoMessages;
