import {useNotifications} from "layout/modules/NotificationArea/components/NotificationAreaProvider";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/NotificationArea/locale/locales";
import {useCallback} from "react";
import getRandomString from "system/Randoom/Random";
import ShowToastComponent from "layout/modules/NotificationArea/components/toast/ShowToastComponent";

const useShowToast = (props) => {

    const {showNotification, closeNotification} = useNotifications();
    const {t} = useTranslations('notifications', locale);

    const showToast = useCallback(
        (props) => {

            const {id = getRandomString(10), duration = 5000, close, view, onClose, onView} = props;

            return ShowToastComponent({
                ...props,
                id: id,
                duration: duration,
                close: close ? close : t('close'),
                view: view ? view : t('view'),
                showNotification: showNotification,
                closeNotification: onClose ? () => onClose(id, closeNotification) : closeNotification,
                viewNotification: onView ? () => onView(id, closeNotification) : undefined,
            });
        },
        [t, closeNotification, showNotification]
    );

    return showToast;
}

export default useShowToast;
