import ViewComponent from "app/support/components/view/ViewComponent";
import {useGet, useMutate} from "system/Rest/Rest";
import {useCallback} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import Loading from "layout/modules/Loading/Loading";

const ViewContainer = props => {

    const {id} = props;
    const {t} = useTranslation("support");
    const showToast = useShowToast();

    const {data: ticket, loading: loadingTicket, refetch: reload} = useGet({
        path: "/ticketing/" + id,
    });

    const {mutate: submitComment, loading, error} = useMutate({
        verb: "POST",
        path: "/ticketing/" + id + "/conversation",
    });

    const handleSubmit = useCallback(
        (comment) => {
            submitComment(comment).then(() => {
                showToast({
                    title: t('SUCCESS_COMMENT'),
                    message: t('SUCCESS_COMMENT_MSG'),
                    icon: faCheck,
                });

                reload();

            }).catch(() => showToast({
                title: t('ERROR_COMMENT'),
                message: t('ERROR_COMMENT_MSG'),
                icon: faTimes,
                className: 'error',
            }));
        },
        [submitComment, showToast, t, reload]
    );

    const errors = useErrorParser(error);

    if (!ticket) {
        return <Loading visible={true}/>
    }

    return <ViewComponent
        {...props}
        ticket={ticket}
        errors={errors}
        loading={loadingTicket || loading}
        onSubmit={handleSubmit}
    />
}

export default ViewContainer;
