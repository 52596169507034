export const pt = {

    country: "País",

    address: "Morada",
    address_name: "Morada (tipo de artéria + nome da artéria)",
    address_name_ph: "Selecione ou escreva uma morada",
    no_address_suggestions: "Sem sugestões. Escreva o endereço",

    door: "Identificação de porta",
    door_ph: "Ex. 13 ou Lote A",

    accommodation: "Identificação alojamento",
    accommodation_ph: "Ex. andar (1.ºA, 2 Esq, etc.)",

    zip_code: "Código postal",
    zip_code_ph: "0000-000",
    pt_zip_help: "Para mais info clique aqui",

    locality: "Localidade",
    locality_ph: "Selecione ou escreva uma localidade",
    no_locality_suggestions: "Sem sugestões. Escreva o nome da localidade",


    //Angola
    region: "Região",
    county: "Município",
    district: "Distrito",
    comuna: "Comuna",


    use: "{{value}}",
    prees_info: " (Clique aqui ou prima enter para assumir esta morada)",

    parish: "Freguesia",
    parish_ph: "Por favor selecione uma freguesia",
    no_parish_found: "Nenhuma freguesia encontrada",

    //Errors
    INVALID_POSTAL_CODE: "Código postal inválido",
    MISSING_POSTAL_CODE: "Código postal inválido",
    MISSING_ADDRESS: "Por favor preencha a morada e a identificação de porta\n",
    MISSING_LOCALITY: "Por favor preencha a Localidade",
    MISSING_PARISH: "Por favor selecione uma freguesia",

    INVALID_REGION: "Região inválida",
    INVALID_COUNTY: "Munícipio inválido",
    INVALID_COMUNA: "Comuna inválida",

    //Internal errors
    INVALID_DOOR_NUMBER: "Preencha o nº da porta",

};

export default pt;
