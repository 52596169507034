import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import Modal from "react-bootstrap/Modal";
import {Table} from "react-bootstrap";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import "app/animals/assets/css/dead.scss"
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import Loading from "layout/modules/Loading/Loading";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import ModalWithNoMargin from "layout/modules/Modal/ModalWithNoMargin/ModalWithNoMargin";
import RunningListEntryComponent from "app/animals/components/list/implementations/runningList/RunningListEntryComponent";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";

const SetDeadComponent = (props) => {

    const {animal, errors = {}, setDead, setAlive, loading, isVisible, setVisible} = props;

    const {t} = useTranslation("animal");
    const [deathDate, setDeathDate] = useState(null);

    const [isAliveVisible, setAliveVisible] = useState(false);

    return <>

        <PopupConfirm
            title={t('SET_ALIVE_CONFIRM')}
            message={t('SET_ALIVE_CONFIRM_MSG')}
            classConfirm={"primary"}
            visible={isAliveVisible}
            onCancel={() => {
                setAliveVisible(false);

            }}
            onConfirm={() => {
                //onDelete();
                setAlive()
                setAliveVisible(false);
            }}
        />

        <Modal
            show={isVisible}
            onHide={loading ? () => void 0 : () => setVisible(false)}
            size={"xl"}
            className={"dead-modal"}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('as_dead')}</Modal.Title>
            </Modal.Header>
            <Loading visible={loading}/>
            <Modal.Body>
                <AdvanceInfoMessage
                    icon={faExclamation}
                    type={"danger"}
                    iconType={"danger"}
                    title={t("set_dead_alert")}
                    message={t('set_dead_alert_msg')}
                />

                <div className={"animal-data"}>
                    {t('animal_data')}
                </div>

                <ModalWithNoMargin>
                    <Table className={"running-animal-list"}>
                        <tbody>
                        <RunningListEntryComponent animal={animal} hideOptions={true}/>
                        </tbody>
                    </Table>
                </ModalWithNoMargin>

                <Form>
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['deathTimestamp']}>
                            <Form.Label>{t('date_of_death')}</Form.Label>
                            <DatePicker
                                placeholder={t("date_ph")}
                                timezone={"UTC"}
                                onlyPastDates={true}
                                value={getOrEmpty(deathDate)}
                                onChange={setDeathDate}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['deathTimestamp'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                </Form>
            </Modal.Body>

            <ModalButtons>

                <Button variant="secondary" onClick={() => setVisible(false)}>
                    {t("cancel")}
                </Button>

                <Button
                    disabled={!deathDate}
                    variant="primary"
                    onClick={() => setDead(deathDate)}>
                    {t("confirm")}
                </Button>

            </ModalButtons>

        </Modal>

        <AnimalOperationAllowed animal={animal} operation={AnimalOperations.SET_NOT_DEAD}>
            <Button variant="dark" onClick={() => setAliveVisible(true)}>
                {t("as_alive")}
            </Button>
        </AnimalOperationAllowed>

        <AnimalOperationAllowed animal={animal} operation={AnimalOperations.SET_DEAD}>
            <Button
                variant={classNames("danger", {'active': isVisible})}
                onClick={() => setVisible(true)}>
                {t("as_dead")}
            </Button>
        </AnimalOperationAllowed>


    </>

}

export default SetDeadComponent;
