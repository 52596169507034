import ModalComponent from "./components/ModalComponent";
import {useLocation} from "react-router";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useGet, useMutate} from "system/Rest/Rest";
import i18next from "i18next";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import locale from "./locales/locales";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useTranslations from "system/Translations/UseTranslations";

const Help = (props) => {

    const showToast = useShowToast();
    const {t} = useTranslations('help', locale);

    const location = useLocation();
    const {isVisible, forceArticle} = props;

    const [currentPageArticleId, setCurrentPageArticle] = useState(null);
    const [currentPageArticleExists, setCurrentPageArticleExists] = useState(false);

    const [articleId, setArticleId] = useState(null);
    const [article, setArticle] = useState(null);

    const queryParams = useMemo(() => ({
        language: i18next.language,
        url: articleId,
    }),[articleId])

    const {data, loading} = useGet({
        path: "/help/",
        lazy: !(isVisible && articleId),
        queryParams: queryParams
    });

    const {mutate: del, loading: deleting} = useMutate({
        verb: "DELETE",
        path: "/help/",
        queryParams: queryParams
    });

    const onChangeArticle = useCallback(
        (path) => {
            setArticleId(path);
            setArticle(null);
        },
        [setArticleId]
    );

    const onGoBack = useCallback(
        () => {
            setArticle({});
            setArticleId(null);
        },
        [setArticle]
    );

    useEffect(
        () => {

            if (forceArticle)
                return;

            const articleId = location.pathname.split("/").slice(0, 3).join("/").replace(/[0-9]/g, "");
            setArticleId(articleId);
            setCurrentPageArticle(articleId);
            setCurrentPageArticleExists(false);
            setArticle(null);
        },
        [location.pathname, forceArticle]
    );


    useEffect(
        () => {
            if (forceArticle) {
                setArticleId(forceArticle);
                setCurrentPageArticle(forceArticle);
                setCurrentPageArticleExists(false);
                setArticle(null);
            }
        },
        [forceArticle]
    )

    useEffect(() => {
        if (data) {
            setArticle(data);
            if (data?.url === currentPageArticleId)
                setCurrentPageArticleExists(true)
        }

    }, [data, currentPageArticleId]);

    return <ModalComponent
        {...props}
        article={article}
        currentPageArticleExists={currentPageArticleExists}
        currentPageArticleId={currentPageArticleId}
        loading={loading || deleting}
        id={articleId}
        onChangeArticle={onChangeArticle}
        onGoBack={onGoBack}
        onDelete={() => {
            del().then(() => {
                onGoBack();
                showToast({
                    title: t('SUCCESS_HELP_DELETE'),
                    message: t('SUCCESS_HELP_DELETE_MSG'),
                    icon: faCheck,
                });
            }).catch(() => {
                showToast({
                    id: "EV",
                    title: t('ERROR_HELP_DELETE'),
                    message: t('ERROR_HELP_DELETE_MSG'),
                    icon: faTimes,
                    className: 'error',
                });
            });

        }}
    />

}

export default Help;
