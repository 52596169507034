import "./assets/lwv.scss";
import classnames from "classnames";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import React from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";

const LabelWithValue = props => {

    const {label, value, children, className, inForm = false} = props;


    if (inForm) {
        return <FormGroup>
            <Form.Label>{label}</Form.Label>
            <FormControl
                type="text"
                disabled={true}
                defaultValue={value}

            />
        </FormGroup>
    }

    return <div className={classnames("label-with-value", className)}>

        <div className={"label"}>
            {label}
        </div>

        <div className={"value fill-empty"}>
            {value ? value : children}
        </div>

    </div>
}

export default LabelWithValue
