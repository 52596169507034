const AggressionActions = {
    AGGRESSION_CREATED: "AGGRESSION_CREATED",
    AGGRESSION_EDITED: "AGGRESSION_EDITED",
    AGGRESSION_CLEAR_CREATED: "AGGRESSION_CLEAR_CREATED",
    AGGRESSION_CLEAR_EDITED: "AGGRESSION_CLEAR_EDITED",
    AGGRESSION_DELETED: "AGGRESSION_DELETED",
};


const AnimalBaseActions = {
    ANIMAL_READ: "ANIMAL_READ",
    ANIMAL_CREATED: "ANIMAL_CREATED",
    ANIMAL_EDITED: "ANIMAL_EDITED",
    ANIMAL_DEAD: "ANIMAL_DEAD",
    ANIMAL_UNDEAD: "ANIMAL_UNDEAD",
    ANIMAL_LOCKED: "ANIMAL_LOCKED",
    ANIMAL_SET_PHOTO: "ANIMAL_SET_PHOTO",
    ANIMAL_DEATH_REQUEST_VALIDATED: "ANIMAL_DEATH_REQUEST_VALIDATED",
};


const LicenseActions = {
    ANIMAL_LICENSE_CREATED: "ANIMAL_LICENSE_CREATED",
    ANIMAL_LICENSE_EDITED: "ANIMAL_LICENSE_EDITED",
    ANIMAL_LICENSE_DELETED: "ANIMAL_LICENSE_DELETED",
};

const MergeActions = {
    ANIMAL_MERGED: "ANIMAL_MERGED",
}

const TransferActions = {
    ANIMAL_TRANSFERED: "ANIMAL_TRANSFERED",
    ANIMAL_TRANSFER_VALIDATED: "ANIMAL_TRANSFER_VALIDATED",
    ANIMAL_TRANSFER_VALIDATED_BULK: "ANIMAL_TRANSFER_VALIDATED_BULK",
    ANIMAL_TRANSFER_RESPOND: "ANIMAL_TRANSFER_RESPOND",
    ANIMAL_TRANSFER_CANCELED: "ANIMAL_TRANSFER_CANCELED"
};


const LostActions = {
    ANIMAL_LOST_CREATED: "ANIMAL_LOST_CREATED",
    ANIMAL_LOST_EDITED: "ANIMAL_LOST_EDITED",
    ANIMAL_LOST_VALIDATED: "ANIMAL_LOST_VALIDATED",
    ANIMAL_LOST_STATUS_UPDATED: "ANIMAL_LOST_STATUS_UPDATED",
    ANIMAL_LOST_CLOSED: "ANIMAL_LOST_CLOSED",
};


const DocumentActions = {
    DOCUMENT_DELETED: "DOCUMENT_DELETED",
};

const AuthenticationActions = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    LOGIN_2FA: "LOGIN_2FA",
    IMPERSONATE_USER: "IMPERSONATE_USER",
    SELF_USER_UPDATE: "SELF_USER_UPDATE",
    SELF_PASSWORD_CHANGE: "SELF_PASSWORD_CHANGE",
    ACTIVATION_CODE_VALIDATED: "ACTIVATION_CODE_VALIDATED",
    USER_SELECT_ENTITY: "USER_SELECT_ENTITY",
};

const RoleActions = {
    SYSTEM_ROLE_CREATED: "SYSTEM_ROLE_CREATED",
    SYSTEM_ROLE_EDITED: "SYSTEM_ROLE_EDITED",
    SYSTEM_ROLE_DELETED: "SYSTEM_ROLE_DELETED",
};


const UserActions = {
    USER_READ: "USER_READ",
    USER_CREATED: "USER_CREATED",
    USER_EDITED: "USER_EDITED",
    USER_DELETED: "USER_DELETED",
};

const EntityActions = {
    ENTITY_CREATED: "ENTITY_CREATED",
    ENTITY_EDITED: "ENTITY_EDITED",
    ENTITY_DELETED: "ENTITY_DELETED",
    OWN_ENTITY_EDITED: "OWN_ENTITY_EDITED",
};

const EntityProfileActions = {
    ENTITY_PROFILE_CREATED: "ENTITY_PROFILE_CREATED",
    ENTITY_PROFILE_EDITED: "ENTITY_PROFILE_EDITED",
    ENTITY_PROFILE_DELETED: "ENTITY_PROFILE_DELETED",
};

const VeterinaryAssociationActions = {
    VETERINARY_ASSOCIATION: "VETERINARY_ASSOCIATION",
}

const BillingActions = {
    TRANSACTION_CREATED: "TRANSACTION_CREATED",
    PRICE_TABLE_CREATED: "PRICE_TABLE_CREATED",
    PRICE_TABLE_EDITED: "PRICE_TABLE_EDITED",
    PRICE_TABLE_DELETED: "PRICE_TABLE_DELETED"
};


const BreedActions = {
    BREED_CREATED: "BREED_CREATED",
    BREED_EDITED: "BREED_EDITED",
    BREED_DELETED: "BREED_DELETED",
};


const CertificateActions = {
    CERTIFICATE_TEMPLATE_CREATED: "CERTIFICATE_TEMPLATE_CREATED",
    CERTIFICATION_TEMPLATE_EDITED: "CERTIFICATION_TEMPLATE_EDITED",
    CERTIFICATION_TEMPLATE_DELETED: "CERTIFICATION_TEMPLATE_DELETED",
    CERTIFICATION_GENERATED: "CERTIFICATION_GENERATED",
    CERTIFICATION_VALIDATED: "CERTIFICATION_VALIDATED",
    BULK_CERTIFICATION_REQUEST: "BULK_CERTIFICATION_REQUEST",
    ANIMAL_DIAC_REQUEST_VALIDATED: "ANIMAL_DIAC_REQUEST_VALIDATED",
};

const ConfigurationActions = {
    CONFIGURATION_EDITED: "CONFIGURATION_EDITED"
}

const HealthInterventionActions = {
    HEALTH_INTERVENTION_CREATED: "HEALTH_INTERVENTION_CREATED",
    RABIES_INTERVENTION_CREATED: "RABIES_INTERVENTION_CREATED",
    STERILIZATION_CREATED: "STERILIZATION_CREATED",
    HEALTH_INTERVENTION_EDITED: "HEALTH_INTERVENTION_EDITED",
    RABIES_INTERVENTION_EDITED: "RABIES_INTERVENTION_EDITED",
    STERILIZATION_EDITED: "STERILIZATION_EDITED",
    HEALTH_INTERVENTION_DELETED: "HEALTH_INTERVENTION_DELETED",
}

const VaccineManagementActions = {
    RABIES_VACCINE_CREATED: "RABIES_VACCINE_CREATED",
    RABIES_VACCINE_EDITED: "RABIES_VACCINE_EDITED",
    RABIES_VACCINE_DELETED: "RABIES_VACCINE_DELETED",
}

const HelpActions = {
    HELP_TOPIC_DELETED: "HELP_TOPIC_DELETED",
    HELP_TOPIC_EDITED: "HELP_TOPIC_EDITED",
}

const ObservationActions = {
    OBSERVATION_CREATED: "OBSERVATION_CREATED",
    OBSERVATION_DELETED: "OBSERVATION_DELETED",
}

const OwnerActions = {
    OWNER_READ: "OWNER_READ",
    OWNER_CREATED: "OWNER_CREATED",
    OWNER_EDITED: "OWNER_EDITED",
    OWNER_LOCKED: "OWNER_LOCKED",
    OWNER_DELETED: "OWNER_DELETED",
    OWNER_TRAINING_EDITED: "OWNER_TRAINING_EDITED",
    OWNER_TRAINING_DELETED: "OWNER_TRAINING_DELETED",
    OWNER_READ_BY_VAT: "OWNER_READ_BY_VAT",
    OWNER_MERGE_REVERTED: "OWNER_MERGE_REVERTED",
    OWNER_MERGE_VALIDATED: "OWNER_MERGE_VALIDATED",
    OWNER_DATA_VALIDATED: "OWNER_DATA_VALIDATED",
    OWNER_USER_VERIFIED: "OWNER_USER_VERIFIED",
    OWNER_USER_DATA_UPDATED: "OWNER_USER_DATA_UPDATED",
    OWNER_USER_REGISTERED: "OWNER_USER_REGISTERED",
}

const VeterinaryActions = {
    VETERINARY_EDITED: "VETERINARY_EDITED",
    VETERINARY_REQUESTED_ENTITY_ASSOCIATION: "VETERINARY_REQUESTED_ENTITY_ASSOCIATION",
    VETERINARY_RESPONDED_ENTITY_ASSOCIATION: "VETERINARY_RESPONDED_ENTITY_ASSOCIATION",
    VETERINARY_REMOVED_ENTITY_ASSOCIATION: "VETERINARY_REMOVED_ENTITY_ASSOCIATION",
}

const GeneratedReportActions = {
    REPORT_GENERATED: "REPORT_GENERATED",
    REPORT_SCHEDULE_CREATED: "REPORT_SCHEDULE_CREATED",
    REPORT_SCHEDULE_EDITED: "REPORT_SCHEDULE_EDITED",
    REPORT_SCHEDULE_DELETED: "REPORT_SCHEDULE_DELETED",
}

const ExecuteOperationActions = {
    OPERATION_RUN: "OPERATION_RUN",
    REPORT_SCHEDULE_CREATED: "OPERATION_SCHEDULE_CREATED",
    REPORT_SCHEDULE_EDITED: "OPERATION_SCHEDULE_EDITED",
    REPORT_SCHEDULE_DELETED: "OPERATION_SCHEDULE_DELETED",
}

const OperationTemplateActions = {
    OPERATION_TEMPLATE_CREATED: "OPERATION_TEMPLATE_CREATED",
    OPERATION_TEMPLATE_EDITED: "OPERATION_TEMPLATE_EDITED",
    OPERATION_TEMPLATE_DELETED: "OPERATION_TEMPLATE_DELETED",
}


const PreRegisterActions = {
    ANIMAL_PRE_REGISTERED: "ANIMAL_PRE_REGISTERED",
    ANIMAL_PRE_REGISTER_VALIDATED: "ANIMAL_PRE_REGISTER_VALIDATED",
    ANIMAL_PRE_REGISTER_EDITED: "ANIMAL_PRE_REGISTER_EDITED",

}

const ReportTemplateActions = {
    REPORT_TEMPLATE_CREATED: "REPORT_TEMPLATE_CREATED",
    REPORT_TEMPLATE_EDITED: "REPORT_TEMPLATE_EDITED",
    REPORT_TEMPLATE_DELETED: "REPORT_TEMPLATE_DELETED",
}

const LabelActions = {
    LABEL_ADDED: "LABEL_ADDED"
}

const HerdingDogActions = {
    HERDING_DOG_VALIDATED: "HERDING_DOG_VALIDATED",
}


const Actions = {
    ...AggressionActions,
    ...AnimalBaseActions,
    ...LicenseActions,
    ...TransferActions,
    ...MergeActions,
    ...LostActions,
    ...AuthenticationActions,
    ...RoleActions,
    ...UserActions,
    ...EntityActions,
    ...EntityProfileActions,
    ...VeterinaryAssociationActions,
    ...BillingActions,
    ...BreedActions,
    ...CertificateActions,
    ...ConfigurationActions,
    ...HealthInterventionActions,
    ...VaccineManagementActions,
    ...HelpActions,
    ...ObservationActions,
    ...OwnerActions,
    ...VeterinaryActions,
    ...ReportTemplateActions,
    ...GeneratedReportActions,
    ...OperationTemplateActions,
    ...ExecuteOperationActions,
    ...PreRegisterActions,
    ...HerdingDogActions,
    ...LabelActions,
    ...DocumentActions,
}

const AnimalActions = {
    ...AggressionActions,
    ...AnimalBaseActions,
    ...LicenseActions,
    ...TransferActions,
    ...MergeActions,
    ...LostActions,
    ...HealthInterventionActions,
    ...VaccineManagementActions,
    ...PreRegisterActions,
    ...CertificateActions,
    ...DocumentActions,
}

export {
    AggressionActions,
    AnimalBaseActions,
    LicenseActions,
    TransferActions,
    MergeActions,
    LostActions,
    AuthenticationActions,
    RoleActions,
    UserActions,
    EntityActions,
    EntityProfileActions,
    VeterinaryAssociationActions,
    BillingActions,
    BreedActions,
    CertificateActions,
    ConfigurationActions,
    HealthInterventionActions,
    VaccineManagementActions,
    HelpActions,
    ObservationActions,
    OwnerActions,
    VeterinaryActions,
    Actions,
    AnimalActions,
    ReportTemplateActions,
    GeneratedReportActions,
    ExecuteOperationActions,
    OperationTemplateActions,
    PreRegisterActions,
    HerdingDogActions,
    LabelActions,
    DocumentActions,
}
