import React from 'react';

import {Tab, Tabs} from "react-bootstrap";
import BaseLoginLayout from "./BaseLoginLayout";
import logo from "../../../layout/assets/images/logo.png";
import SIACLoginContainer from "../containers/SIACLoginContainer";
import OMVLoginContainer from "../containers/OMVLoginContainer";
import locale from "../locales/locales";
import appConfig from "configs/config";
import '../assets/css/login.scss'
import '../assets/css/login-responsive.scss'
import useTranslations from "system/Translations/UseTranslations";

const LoginComponent = (props) => {

    const {t} = useTranslations('login', locale);

    return <BaseLoginLayout>
        <div className="login-bar">
            <img src={logo} className="logo" alt={"logo"}/>
        </div>

        <div className="login-content">
            <Tabs defaultActiveKey="siac" id="tab-siac" className={"login-tab"}>
                <Tab eventKey="siac" title={appConfig.name}>
                    <SIACLoginContainer {...props} />
                </Tab>

                {appConfig.omv.enabled &&
                <Tab eventKey="omv" title={t('OMV')}>
                    <OMVLoginContainer {...props} />
                </Tab>
                }

            </Tabs>
        </div>
    </BaseLoginLayout>;
}

export default LoginComponent;
