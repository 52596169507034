import ShowComponent from "app/control/modules/transfers/components/show/ShowComponent";
import {useGet, useMutate} from "system/Rest/Rest";
import {useCallback, useMemo} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useErrorParser from "system/API/Error/ErrorParser";
import animalResolver from "app/animals/utils/AnimalResolver";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import ownerResolver from "app/owners/utils/OwnerResolver";
import appConfig from "configs/config";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import ValidationStatus from "types/ValidationStatus";
import TransferType from "app/control/modules/transfers/utils/TransferType";

const ShowTransferContainer = (props) => {

    const {transferRequest, reload} = props;

    const showToast = useShowToast();
    const userHasPermission = useUserHasPermission();
    const {t} = useTranslation('transfer');

    const canManage = useMemo(() => userHasPermission({permission: "MANAGE_ANIMAL_TRANSFER"}), [userHasPermission]);

    const {mutate: changeState, loading: changingState} = useMutate({
        verb: "PUT",
        path: "/animal-transfers/" + transferRequest.id
    });

    const {data: animal, loading: loadingAnimal} = useGet({
        path: "/animals/" + transferRequest.transponder,
        resolve: animalResolver
    });

    const {mutate: addFiles, loading: uploading, errors: errorUploading} = useMutate({
        verb: "PUT",
        path: "/animal-transfers/" + transferRequest.id + "/documents",
    });
    const errorsUploading = useErrorParser(errorUploading);

    const {data: newRemoteOwner} = useGet({
        path: "/owners/get-by-vat",
        lazy: (!transferRequest?.vatNumber || transferRequest.closed || !canManage),
        queryParams: useMemo(
            () => ({
                onlyRegisteredOwner: true,
                vatNumber: transferRequest?.vatNumber,
                type: transferRequest?.type,
                country: transferRequest.origin === TransferType.OWNERS ? appConfig.defaultCountry : transferRequest?.country,
                hasLocalVatNumber: transferRequest?.hasLocalVatNumber,
            }),
            [transferRequest?.vatNumber, transferRequest?.type, transferRequest?.origin,
                transferRequest?.country, transferRequest?.hasLocalVatNumber]
        ),
        resolve: (data) => data?.vatNumber ? ownerResolver(data) : undefined,
    });


    const {data: oldRemoteOwner} = useGet({
        path: "/owners/" + transferRequest?.currentOwnerId,
        lazy: (!transferRequest?.currentOwnerId || !canManage),
        resolve: ownerResolver,
    });

    return <ShowComponent
        {...props}
        loading={loadingAnimal || changingState || !animal}
        request={transferRequest}
        remoteNewOwner={newRemoteOwner}
        remoteOldOwner={oldRemoteOwner}
        showOwners={canManage}
        animal={animal}
        uploading={uploading}
        errorsUploading={errorsUploading}
        onAddFiles={useCallback(
            (files, successCallback) => {
                addFiles({
                    documents: files
                }).then(() => {
                    showToast({
                        title: t('SUCCESS_UPLOAD'),
                        message: t('SUCCESS_UPLOAD_MSG', {
                            transponder: transferRequest.transponder
                        }),
                        icon: faCheck,
                    });

                    reload();
                    successCallback();

                }).catch(() => showToast({
                    title: t('ERROR_UPLOADING'),
                    message: t('ERROR_UPLOADING_MSG'),
                    icon: faTimes,
                    className: 'error',
                }))
            },
            [showToast, t, transferRequest, addFiles, reload]
        )}
        onChangeState={useCallback(
            (state, comments) => {
                changeState({
                    validation: state,
                    comments: comments,
                }).then(() => {
                    showToast({
                        title: t('SUCCESS_CHANGED'),
                        message: (state === ValidationStatus.MORE_INFORMATION_NEEDED)
                            ? t('SUCCESS_REQUEST_MORE_INFORMATION', {transponder: transferRequest.transponder})
                            : t('SUCCESS_CHANGED_MSG', {
                                transponder: transferRequest.transponder,
                                status: t(state).toLowerCase()
                            }),
                        icon: faCheck,
                    });

                    reload();

                }).catch(() => showToast({
                    title: t('ERROR_CHANGE_STATE'),
                    message: t('ERROR_CHANGE_STATE_MSG'),
                    icon: faTimes,
                    className: 'error',
                }))
            },
            [transferRequest.transponder, t, changeState, showToast, reload]
        )}
    />;


}

export default ShowTransferContainer;
