import NotificationAreaContainer from "./containers/NotificationAreaContainer";
import Poll from "system/Rest/Poll";
import {useMemo} from "react";

const NotificationArea = props => {


    return <Poll
        path="/notifications/search"
        localErrorOnly={true}
        interval={120 * 1000}
        queryParams={useMemo(() => ({
            //maxAge: 60 * 1000,
            unread: true,
            itemsPerPage: 5,
        }),[])}>
        {(data, refetch) => {
            return <NotificationAreaContainer
                {...props}
                data={data}
                refetch={refetch}/>

        }}
    </Poll>

}

export default NotificationArea;
