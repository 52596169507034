import React, {useState} from "react";
import {faList, faTh} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import "../../assets/css/table-type-button.scss";
import useTranslations from "../../../../../system/Translations/UseTranslations";
import locale from "../../locales/locales";
import ToolTip from "../../../ToolTip/ToolTip";

const ListType = {
    LIST: "LIST",
    GRID: "GRID",
};


const TableTypeButton = (props) => {

    const {onChange, defaultListType = ListType.GRID} = props;
    const [listType, setListType] = useState(defaultListType);
    const {t} = useTranslations('tableAdvanced', locale);

    return <div className={"table-advanced-type-button"}>
        <ToolTip content={t('show_table_as_list')}>
            <div
                onClick={() => {
                    setListType(ListType.LIST)
                    onChange(ListType.LIST);
                }}
                className={classnames("table-advanced-type-button-icon", {
                    "active": listType === ListType.LIST
                })}>
                <FontAwesomeIcon icon={faList}/>
            </div>
        </ToolTip>

        <ToolTip content={t('show_table_as_grid')}>
            <div
                onClick={() => {
                    setListType(ListType.GRID)
                    onChange(ListType.GRID);
                }}
                className={classnames("table-advanced-type-button-icon", {
                    "active": listType === ListType.GRID
                })}>
                <FontAwesomeIcon icon={faTh}/>
            </div>
        </ToolTip>

    </div>

}

export default TableTypeButton;
export {ListType};
