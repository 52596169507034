import {useGet, useMutate} from "system/Rest/Rest";
import {useApiState} from "system/API/APIStateContext";
import LegalWarningComponent from "layout/modules/LegalWarning/LegalWarningComponent";
import locale from "../locales/locales";
import useTranslations from "system/Translations/UseTranslations";

const LegalWarningContainer = props => {

    const { setAuthentication} = useApiState();
    const {t} = useTranslations('login', locale);

    const {data, loading} = useGet({
        path: "/auth/legalWarning",
    });

    const {mutate: accept, loading: accepting} = useMutate({
        verb: "PUT",
        path: "/auth/legalWarning/accept"
    });

    return <LegalWarningComponent
        {...props}
        t={t}
        legalWarning={data?.legalWarning}
        loading={loading || accepting}
        onAccept={() =>
            accept().then(() => {
                setAuthentication((a) => ({
                    ...a,
                    legalWarningReadTimestamp: Date.now(),
                }))
            })

        }
    />

}

export default LegalWarningContainer
