import Box from "../../../../layout/modules/Box/Box";
import classnames from "classnames";
import DateTime from "../../../../system/DateTime/DateTime";
import React from "react";
import {useTranslation} from "react-i18next";

const PanelComponent = (props) => {

    const {t} = useTranslation("support");
    const {ticket} = props;

    return <div className={"right-menu-panel"}>
        <Box>
            <div className={"main-title"}>
                {t('details')}
            </div>

            <div className={"title"}>{t('status')}</div>
            <div className={"value"}>
                <div className={classnames("ticket-status", ticket.status?.toLowerCase().replace(/_/g, "-"))}>
                    {t(ticket.status)}
                </div>
            </div>


            <div className={"title"}>{t('category')}</div>
            <div className={"value"}>
                {t(ticket.category)}
            </div>


            <div className={"title"}>{t('creation_date')}</div>
            <div className={"value"}>
                <DateTime format={"DD-MM-YYYY HH:mm:ss"}>
                    {ticket.creationTimestamp}
                </DateTime>
            </div>


            <div className={"title"}>{t('last_update')}</div>
            <div className={"value"}>
                <DateTime format={"DD-MM-YYYY HH:mm:ss"}>
                    {ticket.lastUpdateTimestamp}
                </DateTime>
            </div>

            <div className={"title"}>{t('user')}</div>
            <div className={"value fill-empty"}>
                {ticket.creationUser?.name}
            </div>

            <div className={"title"}>{t('entity')}</div>
            <div className={"value fill-empty"}>
                {ticket.creationUser?.entity}
            </div>

            {ticket.currentUrl &&
            <>
                <div className={"title"}>{t('url')}</div>
                <div className={"value fill-empty"}>
                    <a href={ticket.currentUrl} target="_blank" rel="noopener noreferrer">
                        {ticket.currentUrl}
                    </a>
                </div>
            </>
            }

        </Box>
    </div>
}

export default PanelComponent;
