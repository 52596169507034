import {useTranslation} from "react-i18next";
import GenericComponent from "./GenericComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useMemo} from "react";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import {useGetRemoteEntities} from "modules/GenericRemoteClassFetcher/GenericSubClassFetcher";
import {CertificateActions} from "app/audit/utils/AuditActions";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Loading from "layout/modules/Loading/Loading";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";

const CertificateComponent = (props) => {

    const {t} = useTranslation('audit');
    const {operation} = props;

    const templateId = useMemo(
        () => [operation?.objectRaw?.certificateTemplateId],
        [operation?.objectRaw?.certificateTemplateId]
    );

    const [certificate, loadingCertificate] = useGetRemoteEntities(
        "/certificates/search", templateId);


    if (operation?.action !== CertificateActions.CERTIFICATION_GENERATED &&
        operation?.action !== CertificateActions.CERTIFICATION_VALIDATED) {
        return <GenericComponent {...props} />
    }

    return <div className={"view-mode"}>
        <Box>
            <BoxTitle>{t('certificate')}</BoxTitle>

            <Loading visible={loadingCertificate}/>
            <Row>
                <Col md={6}>
                    <LabelWithValue
                        label={t('certificate_template')}
                        value={certificate?.length > 0 ? certificate[0]?.name : ""}
                    />
                </Col>

                {(operation?.action === CertificateActions.CERTIFICATION_VALIDATED
                    || operation?.action === CertificateActions.CERTIFICATION_GENERATED) &&
                <Col md={6}>
                    <FormGroup>
                        <Form.Label>{t('validation_code')}</Form.Label>
                        <div className={"form-control disabled"}>
                            {operation?.objectRaw?.validationCode}
                        </div>
                    </FormGroup>
                </Col>
                }

                {operation?.action === CertificateActions.CERTIFICATION_GENERATED &&
                <>
                    <Col md={6}>
                        <FormGroup>
                            <Form.Label>{t('send_by_email')}</Form.Label>
                            <BooleanDropdown
                                isDisabled={true}
                                value={parseBoolean(operation?.objectRaw?.sendEmail, false)}
                            />
                        </FormGroup>
                    </Col>

                    {parseBoolean(operation?.objectRaw?.sendEmail, false) &&
                    <Col md={6}>
                        <LabelWithValue
                            label={t('email_destination')}
                            value={operation?.objectRaw?.emailAddress ?? ""}
                        />
                    </Col>
                    }

                </>
                }


            </Row>
        </Box>
    </div>
}

export default CertificateComponent;
