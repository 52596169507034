import BoxTitle from "../../../../layout/modules/Box/components/BoxTitle";
import Box from "../../../../layout/modules/Box/Box";
import Form from "react-bootstrap/Form";
import FormControl from "../../../../layout/modules/Forms/Control/FormControl";
import FormGroup from "../../../../layout/modules/Forms/Group/FormGroup";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import {Trans, useTranslation} from "react-i18next";
import Col from "react-bootstrap/Col";
import useHandleFormSubmit from "../../../../system/Forms/FormHandling";
import Button from "react-bootstrap/Button";
import AdvanceInfoMessage from "../../../../layout/modules/InfoMessages/AdvanceInfoMessage";
import Loading from "../../../../layout/modules/Loading/Loading";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import UserHasPermission from "../../../../system/API/Authentication/UserHasPermission";
import {Link} from "react-router-dom";
import "../../assets/certificate-validate.scss";
import {useDateTime} from "system/DateTime/DateTime";

const ValidateComponent = (props) => {

    const {t} = useTranslation('certificate');
    const {onSubmit, loading, result} = props;

    const [code, setCode] = useState("");

    const handleFormSubmit = useHandleFormSubmit(onSubmit)

    const getDateAndTime = useDateTime("DD-MM-YYYY HH:mm");

    return <>


        <Loading visible={loading}/>
        <Form onSubmit={handleFormSubmit} className={"limited-width validate-form"}>

            {code === result?.code &&
            <>
                {result?.validated === true && result?.outdated === false &&
                <AlertValidateMessage
                    type={"success"}
                    iconType={"success"}
                    title={t("document_is_valid")}
                    transponder={result?.transponder}
                    message={t('document_is_valid_msg', {
                        ...result,
                        date: getDateAndTime(result.date)
                    })}
                />
                }

                {result?.validated === true && result?.outdated === true &&
                <AlertValidateMessage
                    iconType={"warning"}
                    type={"warning"}
                    transponder={result?.transponder}
                    title={t("document_is_valid_and_outdated")}
                    message={t('document_is_valid_and_outdated_msg', {
                        ...result,
                        date: getDateAndTime(result.date)
                    })}
                />
                }

                {result?.validated === false &&
                <AlertValidateMessage
                    icon={faExclamation}
                    type={"danger"}
                    iconType={"danger"}
                    transponder={result?.transponder}
                    title={t("document_is_invalid")}
                    message={t('document_is_invalid_msg', result)}
                />
                }
            </>
            }

            <Box>
                <BoxTitle>{t('validate_box_title')}</BoxTitle>
                <Row>
                    <Col xs={12} md={12} lg={6} xl={6}>
                        <FormGroup>
                            <Form.Label>{t('code')}</Form.Label>
                            <FormControl
                                type="text"
                                name={"code"}
                                id={"code"}
                                onChange={e => setCode(e.target.value)}
                                placeholder={t('code_ph')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Box>

            <Row>
                <Col className={"text-end"}>
                    <Button variant="primary" type="submit">
                        {t('validate')}
                    </Button>
                </Col>
            </Row>

        </Form>
    </>

}

const AlertValidateMessage = (props) => {

    const {t} = useTranslation('certificate');
    const {type, icon, iconType, title, message, transponder} = props;

    return <AdvanceInfoMessage
        type={type}
        transponder={transponder}
        iconType={iconType}
        title={title}
        icon={icon}>

        <div className={"validate-alert"}>
            <div className={"message"}>
                <Trans>
                    {message}
                </Trans>
            </div>
            <div className={"button"}>
                {transponder &&
                <UserHasPermission permission="READ_ANIMAL">
                    <Link to={"/animal/view/" + transponder}>
                        <Button variant={type}>
                            {t('view_animal')}
                        </Button>
                    </Link>
                </UserHasPermission>
                }
            </div>
        </div>
    </AdvanceInfoMessage>

}

export default ValidateComponent;
