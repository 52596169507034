import {getZipCode, validateZipCode} from "./zipCode";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import {objectIsEqual} from "system/Objects/Objects";

const buildAddress = (street, door, accommodation) => {
    const address = [street, door, accommodation].filter(v => v);

    if (address.length > 1)
        return address.join(",");
    else return "";
}

const unBuildAddress = (address) => {

    if (!address) {
        return null;
    } else {
        const addr = address.split(",");

        if (addr.length > 3)
            return {
                street: addr.slice(0, addr.length - 2).join(","),
                door: addr[addr.length - 2],
                accommodation: addr[addr.length - 1],
            }
        else
            return {
                street: addr[0],
                door: addr[1],
                accommodation: addr[2],
            }
    }
}

const hasAddressChanged = (newData, oldData) => !objectIsEqual(parseAsOutput(newData), parseAsOutput(oldData))
    || newData?.street !== oldData.street;

const parseAsOutput = (data) => {

    return {
        postalCode: getOrEmpty(data?.zipCode),
        locality: getOrEmpty(data?.locality),
        parishId: getOrEmpty(data?.parish),
        address: buildAddress(data?.street, data?.door, data?.accommodation),
    }
}

const buildInternalAddressObject = (address, postalCode, locality, parishId, currentData) => {

    const value = {
        postalCode: postalCode,
        locality: locality,
        parishId: parishId,
        address: address,
    }

    const dataChanged = !objectIsEqual(value, parseAsOutput(currentData));

    if (dataChanged) {
        return {
            ...unBuildAddress(address),
            ...getZipCode(postalCode),
            zipCode: postalCode,
            locality: locality,
            parish: parishId,
            zipCodeValid: validateZipCode(postalCode),
            zipCodeConfirmed: (currentData?.zipCode === postalCode && currentData?.zipCodeConfirmed) ? true : undefined,
            edit: postalCode
        }
    } else {
        return currentData;
    }

}

export {buildAddress, unBuildAddress, buildInternalAddressObject, parseAsOutput, hasAddressChanged};


