import React from 'react';
import App from './App';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import * as serviceWorker from './system/System/serviceWorker';
import {createRoot} from 'react-dom/client';
import {ThemeContextWrapper} from "system/Themes/ThemeContextWrapper";
import './configs/i18n/i18n';
import './system/Window/HandleScroll';
import 'url-search-params-polyfill';


const emotionalNonce = process.env.EMOTIONAL_NONCE;

const styleCache = createCache({
    key: 'siac-css',
    nonce: emotionalNonce,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <ThemeContextWrapper>
        <CacheProvider value={styleCache}>
            <App/>
        </CacheProvider>
    </ThemeContextWrapper>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
