import React from 'react'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import TableIconButton from "./TableIconButton"
import ToolTip from "../../../ToolTip/ToolTip";
import useTranslations from "../../../../../system/Translations/UseTranslations";
import locale from "../../locales/locales";
import classnames from "classnames";

const TableIconAdd = (props) => {

    const {visible = true, className, ...otherProps} = props;
    const {t} = useTranslations('tableAdvanced', locale);

    if (!visible) {
        return <></>;
    }

    return <ToolTip content={t('button_add')}>
        <TableIconButton
            {...otherProps}
            className={classnames("btn-rounded-primary table-icon-add", className)}
            icon={faPlus}
        />
    </ToolTip>

}

export default TableIconAdd;

