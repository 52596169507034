import {useMutate} from "system/Rest/Rest";
import {useCallback} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import locale from "../locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import RequestSupportComponent from "app/support/components/RequestSupportComponent";
import {useLocation} from "react-router-dom";
import {shouldRefresh} from "app/support/utils/utils";
import {useNavigate} from "react-router";

const RequestSupportContainer = props => {

    const {onClose} = props;
    const {t} = useTranslations('support', locale);
    const showToast = useShowToast();

    const {mutate: submit, loading, error} = useMutate({
        verb: "POST",
        path: "/ticketing/",
    });

    const location = useLocation();
    const navigate = useNavigate();
    const errors = useErrorParser(error);

    return <RequestSupportComponent
        {...props}
        loading={loading}
        errors={errors}
        onSubmit={useCallback(
            (ticket) => {
                submit(ticket).then(() => {
                    showToast({
                        title: t('SUCCESS_REQUEST'),
                        message: t('SUCCESS_REQUEST_MSG'),
                        icon: faCheck,
                    });

                    onClose();

                    //Refresh if current page is support or dashboard
                    if (shouldRefresh(location)) {
                        navigate(location.pathname);
                    }


                }).catch((e) => {
                    showToast({
                        title: t('ERROR_COMMENT'),
                        message: t(e.data?.message ? e.data?.message : 'ERROR_COMMENT_MSG'),
                        icon: faTimes,
                        className: 'error',
                    });
                })
            },
            [showToast, submit, onClose, t, location, navigate]
        )}

    />
}

export default RequestSupportContainer;
