import React from "react";
import locale from "app/owners/locales/locales";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import useTranslations from "system/Translations/UseTranslations";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import TypeComponent from "app/owners/components/form/TypeComponent";
import PersonalDataComponent from "app/owners/components/form/PersonalDataComponent";
import EntityResponsibleComponent from "app/owners/components/form/EntityResponsibleComponent";
import AddressComponent from "app/owners/components/form/AddressComponent";
import ContactsComponent from "app/owners/components/form/ContactsComponent";
import {OwnerType} from "types/Onwer";
import OwnerAlertsComponent from "app/owners/components/view/OwnerAlertsComponent";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import Observations from "app/observations/Observations";

const RemoteOwnerComponent = (props) => {

    const {owner} = props;
    const {t} = useTranslations('owner', locale);
    const errors = {};

    return <>

        <OwnerAlertsComponent {...props}  owner={owner} t={t}/>

        <Box>
            <BoxTitle>{t('owner_type')}</BoxTitle>
            <BoxOptions>
                <ToolTip content={t('view_owner')}>
                    <Link to={"/owner/" + owner.id}>
                        <div className={classnames("btn-rounded-primary small")}>
                            <FontAwesomeIcon icon={faExternalLinkAlt}/>
                        </div>
                    </Link>
                </ToolTip>

                <UserHasPermission permission={["READ_OBSERVATION"]}>
                    <Observations location={"/owner/" + owner.id}/>
                </UserHasPermission>

            </BoxOptions>

            <TypeComponent
                errors={errors}
                owner={owner}
                readOnly={true}
            />
        </Box>

        {owner.country &&
        <>
            <PersonalDataComponent
                errors={errors}
                owner={owner}
                readOnly={true}
            />

            {(owner.type === OwnerType.COLLECTIVE) &&
            <EntityResponsibleComponent
                errors={errors}
                owner={owner}
                readOnly={true}
            />
            }

            <AddressComponent
                errors={errors}
                owner={owner}
                readOnly={true}
            />

            <ContactsComponent
                errors={errors}
                owner={owner}
                readOnly={true}
            />

        </>
        }
    </>
}

export default RemoteOwnerComponent;
