const generateResponseObject = (response, data) => ({
    status: response?.status,
    ok: response?.ok,
    data: data
});

const isJsonContent = (contentType) => contentType?.toLowerCase()?.includes("json");

export {
    generateResponseObject,
    isJsonContent
}
