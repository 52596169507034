import {useEffect, useMemo} from "react";
import {useGet} from "system/Rest/Rest";
import createPersistedState from "system/System/PersistedState/PersistenState";
import dayjs from "dayjs";

const Poll = (props) => {

    const {path, localErrorOnly, interval: timeout, queryParams, lazy} = props;
    const usePollState = useMemo(() => createPersistedState('pollState-' + btoa(path)), [path]);
    const [pollState, setPollState] = usePollState({});
    const lastUpdate = pollState?.lastUpdate;

    const {data, loading, error, refetch} = useGet({
        path: path,
        localErrorOnly: localErrorOnly,
        lazy: true,
        queryParams: queryParams,
    });

    //Configure cron
    useEffect(() => {

        //Refetch at start
        if (!lazy && shouldUpdate(lastUpdate, timeout))
            refetch();

        const interval = setInterval(
            () => {
                if (!lazy && shouldUpdate(lastUpdate, timeout)) {
                    refetch();
                }
            }, timeout
        );
        return () => clearInterval(interval);
    }, [timeout, lastUpdate, refetch, lazy]);


    useEffect(
        () => {
            if (data) {
                setPollState({
                    data: data,
                    lastUpdate: dayjs().valueOf(),
                });
            }
        },
        [data, setPollState]
    );

    return props.children(pollState?.data, refetch, loading, error);
}

const shouldUpdate = (lastUpdate, timeout) => (!lastUpdate || lastUpdate + timeout <= dayjs().valueOf())

export default Poll;
