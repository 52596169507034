import React, {useCallback} from "react"
import ImpersonateComponent from "./components/ImpersonateComponent";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import locale from "./locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useApiState} from "system/API/APIStateContext";
import generateSession from "app/login/utils/generateSession";
import {UserType} from "types/Users";
import {useMutate} from "system/Rest/Rest";
import {useNavigate} from "react-router";

const Impersonate = (props) => {

    const {t} = useTranslations('personalArea', locale);

    const showToast = useShowToast();
    const {onClose} = props;
    const {setAuthentication, authentication} = useApiState();
    const navigate = useNavigate();

    const {mutate: login, loading} = useMutate({
        verb: "POST",
        path: "/auth/impersonate",
    });

    const handleImpersonate = useCallback((user) => {
        login({
            userId: user.id
        }).then((response) => {

            showToast({
                title: t('SUCCESS_IMPERSONATE'),
                message: t('SUCCESS_IMPERSONATE_MSG', {name: response.name}),
                icon: faCheck,
            });

            if (user.type === UserType.VETERINARY) {
                setAuthentication(generateSession(response, UserType.VETERINARY, {
                    impersonate: authentication
                }));

                navigate('/auth/entities');

            } else {
                setAuthentication(generateSession(response, UserType.NORMAL, {
                    impersonate: authentication
                }));
                onClose();
                navigate('/');

            }

        }).catch((e) => {
            showToast({
                title: t('ERROR_IMPERSONATE'),
                message: t('ERROR_IMPERSONATE_MSG'),
                icon: faTimes,
                className: 'error',
            });
        });
    }, [navigate, onClose, t, showToast, setAuthentication, login, authentication])

    return <ImpersonateComponent
        {...props}
        loading={loading}
        onImpersonate={handleImpersonate}
    />;
}

export default Impersonate;
