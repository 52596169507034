import locale from "app/observations/locales/locales";
import React, {useCallback, useMemo} from "react";
import ListComponent from "../components/ListComponent";
import {useMutate} from "system/Rest/Rest";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import useTranslations from "system/Translations/UseTranslations";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";


const ObservationsContainer = (props) => {

    const {path} = props;

    const {t} = useTranslations('observation', locale);
    const showToast = useShowToast();

    const {mutate: submit, loading: submitting, error: errorSubmitting} = useMutate({
        verb: "POST",
        path: "/observations/"
    });

    const errors = useErrorParser(errorSubmitting);

    return <GenericListContainer
        id={"observation-" + path}
        module="observation"
        hideTitle={true}
        locale={locale}
        queryParameters={useMemo(() => ({
            url: path,
            sortProperty: 'creationTimestamp',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 5,
        }), [path])}>

        <ListComponent
            {...props}
            errors={errors}
            submitting={submitting}
            onSubmit={useCallback(
                (data) => new Promise(resolve => {
                    submit({
                        ...data,
                        url: path
                    }).then(() => {
                        showToast({
                            title: t('SUCCESS_CREATED'),
                            message: t('SUCCESS_CREATED_MSG'),
                            icon: faCheck,
                        });
                        resolve();
                    }).catch(e => handleUnknownFormError(e, showToast, t))

                }),
                [path, showToast, t, submit]
            )}
        />

    </GenericListContainer>

}

export default ObservationsContainer;
