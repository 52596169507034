import {Trans, useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import React from "react";

const ActivateAccountComponent = props => {

    const {t} = useTranslation('owner');
    const {owner, onActivate} = props;

    return <div className={"owners-app-activate"}>
        <div>
            <Trans>{t('activate_info', owner)}</Trans>
        </div>

        <div className={"activate-button"}>
            <Button variant="primary" onClick={onActivate}>
                {t("activate_account")}
            </Button>
        </div>

    </div>
}

export default ActivateAccountComponent;
