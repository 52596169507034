import classnames from "classnames";
import {AnimalStatus} from "types/Animal";
import React from "react";
import {useTranslation} from "react-i18next";

const LostStatusComponent = props => {

    const {lostInfo} = props;
    const {t} = useTranslation("animal");

    if (lostInfo?.isClosed) {
        return <div className={"line-title status normal"}>
            {t(lostInfo?.deliveryStatus?.toLowerCase())}
        </div>
    } else {
        return <div className={classnames("line-title", "status", {
            "lost": lostInfo.status === AnimalStatus.LOST,
            "found": lostInfo.status === AnimalStatus.FOUND
        })}>
            {t("LOST_STATUS_" + lostInfo.status)}
        </div>
    }


}

export default LostStatusComponent;
