import React from "react";
import PopupConfirm from "../../../../layout/modules/Popups/PopupConfirm";
import useTranslations from "../../../../system/Translations/UseTranslations";
import locale from "../locale/locales";

const PopupExitEditModeComponent = (props) => {

    const {isVisible, onCancel, onConfirm} = props;
    const {t} = useTranslations("notSaved", locale)

    return <PopupConfirm
        title={t('CHANGES_NOT_SUBMITTED')}
        message={t('CHANGES_NOT_SUBMITTED_MSG')}
        labelCancel={t('CHANGES_NOT_SUBMITTED_CANCEL')}
        labelConfirm={t('CHANGES_NOT_SUBMITTED_CONFIRM')}
        classConfirm={"primary"}
        classCancel={"danger"}
        visible={isVisible}
        onCancel={onCancel}
        onConfirm={onConfirm}
    />;

}

export default PopupExitEditModeComponent;
