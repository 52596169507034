import {Table} from "react-bootstrap";
import React, {useMemo, useState} from "react";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import MenuDropdown from "layout/modules/MenuDropdown/MenuDropdown";
import {ReportOutputFormat} from "app/reporting/utils/ReportOutputFormat";
import Loading from "layout/modules/Loading/Loading";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Pagination from "layout/modules/TableAdvanced/components/Pagination";

const ResultComponent = props => {

    const {t, result, downloading, onDownload, disableExport} = props;
    const itemsPerPage = 20;
    const totalItems = result?.rows?.length;

    const [currentPage, setCurrentPage] = useState(0);

    const rows = useMemo(
        () => result?.rows?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage),
        [result, currentPage]
    )

    return <>
        <Loading visible={downloading}/>
        <Box>
            <BoxTitle>
                {t('report_result', {count: totalItems})}
            </BoxTitle>

            {(!disableExport && totalItems > 0) &&
            <BoxOptions>
                <MenuDropdown>
                    <ToolTip content={t('download_menu')}>
                        <div className={classnames("btn-rounded-primary small")}>
                            <FontAwesomeIcon icon={faDownload}/>
                        </div>
                    </ToolTip>
                    <MenuDropdownContent>
                        {Object.keys(ReportOutputFormat).map(param =>
                            <MenuDropdownOption key={param}>
                                <div onClick={() => onDownload(param)}>
                                    {t('export_to', {type: t(param)})}
                                </div>
                            </MenuDropdownOption>
                        )}
                    </MenuDropdownContent>
                </MenuDropdown>
            </BoxOptions>
            }

            <Pagination pages={Math.ceil(totalItems / itemsPerPage)}
                        currentPage={currentPage + 1}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        onPageChange={(item) => setCurrentPage(item - 1)}/>

            <Table responsive>
                <thead>
                <tr>
                    {result?.headers?.map((header, id) => <th key={id}>{header}</th>)}
                </tr>

                </thead>

                <tbody>
                {rows?.map((row, id) =>
                    <tr key={id}>
                        {row?.map((cell, id) => <td key={id}>{cell}</td>)}
                    </tr>
                )}

                <tr>

                </tr>
                </tbody>

            </Table>

        </Box>
        </>


        }


export default ResultComponent;
