import {Link} from "react-router-dom";
import {useApiState} from "system/API/APIStateContext";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {useMemo} from "react";

const EntityLinkComponent = props => {

    const {name, id} = props;

    const {authentication} = useApiState();
    const userHasPermission = useUserHasPermission();

    const canAccessToEntity = useMemo(
        () => id && userHasPermission({permission: "READ_ENTITY"}) && authentication?.entity?.id !== id &&
            (!Array.isArray(authentication?.entitiesAccessList) || authentication?.entitiesAccessList.length === 0 ||
                authentication?.entitiesAccessList.includes(id)),
        [userHasPermission, authentication?.entitiesAccessList, authentication?.entity?.id, id]
    );

    if (!id) {
        return <div className={"fill-empty"}/>
    } else if (!canAccessToEntity) {
        return <>{name}</>
    } else if (authentication?.entity?.id === id) {
        return <>{name}</>
    } else {
        return <Link to={"/entity/" + id}>
            {name}
        </Link>
    }

}

export default EntityLinkComponent;
