

const AnimalObjectTypes = {
    Aggression: "Aggression",
    Animal: "Animal",
    AnimalLicense: "AnimalLicense",
    AnimalTransfer: "AnimalTransfer",
    AnimalMerge: "AnimalMerge",
    LostAnimal: "LostAnimal",
    HealthIntervention: "HealthIntervention",
    RabiesProphylaxis: "RabiesProphylaxis",
    Sterilization: "Sterilization",
    AnimalPreRegister: "AnimalPreRegister",
    PendingLostAnimal: "PendingLostAnimal",
    Certificate: "Certificate",
}

const ReportObjectTypes = {
    ReportSchedule: "ReportSchedule",
    Report: "Report",
}

const OperationObjectTypes = {
    OperationSchedule: "OperationSchedule",
    Operation: "Operation",
}


const AuditObjectTypes = {
    ...AnimalObjectTypes,
    ...ReportObjectTypes,
    ...OperationObjectTypes,
    User: "User",
    UserRole: "UserRole",
    AnimalDeathRequest: "AnimalDeathRequest",
    Entity: "Entity",
    EntityProfile: "EntityProfile",
    EntityVeterinaryAssociation: "EntityVeterinaryAssociation",
    BillingTransaction: "BillingTransaction",
    PriceTable: "PriceTable",
    Breed: "Breed",
    Certificate: "Certificate",
    Template: "Template",
    Configuration: "Configuration",
    HelpTopic: "HelpTopic",
    Observation: "Observation",
    Owner: "Owner",
    OwnerMergeReport: "OwnerMergeReport",
    OwnerDataUpdate: "OwnerDataUpdate",
    OwnerUser: "OwnerUser",
    Veterinary: "Veterinary",
    RabiesVaccine: "RabiesVaccine",
    ReportTemplate: "ReportTemplate",
    OperationTemplate: "OperationTemplate",
    HerdingDog: "HerdingDog",
    LabelValue: "LabelValue",
    Document: "Document",
}


export {
    AnimalObjectTypes,
    AuditObjectTypes,
    ReportObjectTypes,
    OperationObjectTypes
}
