import {useCallback} from "react";
import dayjs from "dayjs";
import {addToDate, fixUTCBug} from "layout/modules/DayJS/functions";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const useAntiRabiesValidity = (vaccines) => {

    dayjs.extend(utc)
    dayjs.extend(timezone);

    return useCallback(
        (vaccineId, executionTimestamp) => {
            const vaccine = vaccines?.find(v => v.id === vaccineId);

            return fixUTCBug(addToDate(
                dayjs(executionTimestamp).tz("UTC", true),
                {
                    [getTimeUnit(vaccine)]: vaccine?.immunizationTimePeriod
                }).valueOf());
        },
        [vaccines]
    );
}

const getTimeUnit = (vaccine) => vaccine?.immunizationTimeUnit === "YEAR" ? 'year' : 'month';

export default useAntiRabiesValidity;
