import useTranslations from "system/Translations/UseTranslations";
import locales from "app/animals/locales/locales";
import UserHasPermission, {
    UserHasNoPermission,
    useUserHasPermission
} from "system/API/Authentication/UserHasPermission";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import React from "react";
import AnimalLostClosedState, {AnimalLostPublicClosedState} from "types/AnimalLostClosedState";
import MenuDropDownGroup from "layout/modules/MenuDropdown/components/MenuDropDownGroup";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";

const LostCloseMenuOptionsComponent = props => {

    const {setDeliveredRef, animal} = props;
    const {t} = useTranslations("animal", locales);

    const userHasPermission = useUserHasPermission();


    return <AnimalOperationAllowed animal={animal} operation={AnimalOperations.SET_DELIVERED}>
        <UserHasPermission permission="SET_CUSTOM_LOST_ANIMAL_STATUS">
            <MenuDropDownGroup title={t('close_process')}>
                {Object.keys(AnimalLostClosedState).map(key =>
                    <MenuDropdownOption key={key}>
                        <div onClick={() => setDeliveredRef.current?.setVisible(key)}>
                            {t(key.toLowerCase())}
                        </div>
                    </MenuDropdownOption>
                )}
            </MenuDropDownGroup>

        </UserHasPermission>

        <UserHasNoPermission permission="SET_CUSTOM_LOST_ANIMAL_STATUS">

            <MenuDropDownGroup title={t('close_process')}>
                {Object.keys(AnimalLostPublicClosedState)
                    .filter(key => key !== AnimalLostPublicClosedState.DEAD || userHasPermission({permission: "WRITE_ANIMAL_DEAD"}))
                    .map(key =>
                        <MenuDropdownOption key={key}>
                            <div onClick={() => setDeliveredRef.current?.setVisible(key)}>
                                {t(key.toLowerCase())}
                            </div>
                        </MenuDropdownOption>
                    )}
            </MenuDropDownGroup>

        </UserHasNoPermission>

    </AnimalOperationAllowed>
}

export default LostCloseMenuOptionsComponent;
