import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {themes, useThemeState} from "system/Themes/ThemeContextWrapper";
import useUpdateIframeSize from "app/publicForms/utils/updateIframe";
import './assets/css/public-forms.scss'

const PublicFormLayout = props => {

    const {changeTheme} = useThemeState();

    const ref = useUpdateIframeSize();

    useEffect(
        () => changeTheme(themes.light),
        [changeTheme]
    );


    return <div className={"public-forms"} ref={ref}>
        <Outlet/>
    </div>
}

export default PublicFormLayout;
