import cloneWithoutKeys from "system/Objects/Objects";
import ownerResolver from "app/owners/utils/OwnerResolver";
import {parseServerFormError} from "system/API/Error/ErrorParser";
import {defaultOwnerProps} from "app/owners/utils/form/defaultOwnerProps";

const animalResolver = (data) => ({
    ...cloneWithoutKeys(data, ['errors']),
    parishId: data?.useOwnerAddress ? undefined: data.parish?.id,
    breedId: data.breed?.id,
    crossBreedId: data.crossBreed?.id,
    isCrossBreed: !!data.crossBreed?.id,
    hasPedigree: (data?.lopNumber || data?.lopName),
    entityId: data.entity?.id,
    isPotentiallyDanger: isPotentiallyDanger(data),
    veterinaryId: data.veterinary?.id,
    owner: {
        ...ownerResolver(data.owner)
    },
    errors: parseServerFormError(data.errors)

});

const getEmptyAnimal = (transponder) => ({
    transponder: transponder,
    useOwnerAddress: true,
    owner: defaultOwnerProps
});

const isPotentiallyDanger = (animal) => (animal?.breed?.type?.code === "G" || animal?.crossBreed?.type?.code === "G");

export {getEmptyAnimal, isPotentiallyDanger};

export default animalResolver;

