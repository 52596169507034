import React, {useEffect} from 'react';
import {useApiState} from "../APIStateContext";
import {Navigate, useLocation} from "react-router";
import {isAuthenticationExpired} from "app/login/utils/sessionUtils";

const AuthenticationInterceptor = (props) => {

    //Authentication Context Usage
    const {authentication, setAuthentication} = useApiState();
    const location = useLocation();

    //function to get timeouted session
    useEffect(() => {
        const interval = setInterval(() => {
            if (isAuthenticationExpired(authentication?.session?.validity)) {
                setAuthentication({
                    sessionExpired: true,
                });
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [authentication?.session?.validity, setAuthentication]);


    if (authentication?.isAuthenticated && authentication?.legalWarningReadTimestamp) {
        if (isAuthenticationExpired(authentication?.session?.validity)) {
            return <></>
        }
        return props.children;

    } else {

        return <Navigate to={'/auth/'} state={{
            ...props?.location?.state,
            origin: location?.pathname,
        }}
        />
    }
}


export default AuthenticationInterceptor;
