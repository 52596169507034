import Modal from "react-bootstrap/Modal";
import Loading from "layout/modules/Loading/Loading";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import Button from "react-bootstrap/Button";
import React from "react";
import {useTranslation} from "react-i18next";

import "app/login/assets/css/help.scss";

const HelpComponent = (props) => {

    const {isVisible, loading, setVisible, content} = props;

    const {t} = useTranslation('login');

    return <>

        <div className={"help-btn"} onClick={() => setVisible(true)}>
            {t('help')}
        </div>

        <Modal
            show={isVisible}
            onHide={loading ? () => void 0 : () => setVisible(false)}
            size={"xl"}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('help')}</Modal.Title>
            </Modal.Header>
            <Loading visible={loading}/>
            <Modal.Body>
                <div className={"login-help-content"} dangerouslySetInnerHTML={{__html: content}}/>
            </Modal.Body>

            <ModalButtons>

                <Button variant="secondary" onClick={() => setVisible(false)}>
                    {t("close")}
                </Button>
            </ModalButtons>

        </Modal>
    </>

}

export default HelpComponent;
