import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useCallback, useState} from "react";
import NewMessageComponent from "app/support/components/form/NewMessageComponent";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Loading from "layout/modules/Loading/Loading";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrNull} from "system/Objects/ObjectParameters";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import TicketCategory from "app/support/utils/TicketCategory";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import OpenReplayTracker from "modules/OpenReplay/tracker";

const RequestSupportComponent = props => {

    const {isOpen, loading, onSubmit, onClose, errors = {}, screenshot, request: baseRequest} = props;
    const {t} = useTranslation('support');

    const [request, setRequest] = useState({
        ...baseRequest,
        attachments: screenshot ? [{
            title: "screenshot.png",
            filename: "screenshot.png",
            type: "image/png",
            contents: screenshot
        }] : [],
        currentUrl: window.location.href,
        logSessionId: OpenReplayTracker?.getSessionID(),
    });


    return <Modal
        show={isOpen}
        size={"xl"}
        onHide={onClose}
        className={"dark-background"}
        backdrop="static"
        keyboard={false}
    >

        <Loading visible={loading}/>

        <Modal.Header closeButton>
            <Modal.Title>{t('request_support')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Box>

                <BoxTitle>
                    {t('new_request')}
                </BoxTitle>

                <Row>
                    {/**
                     <Col sm={12} md={6}>
                     <FormGroup mandatory={true} error={errors['subject']}>
                     <Form.Label>{t('subject')}</Form.Label>
                     <FormControl
                     type="text"
                     placeholder={t('subject_ph')}
                     value={getOrEmpty(request.subject)}
                     onChange={useCallback((e) => setRequest(r => ({
                     ...r,
                     subject: e.target.value,
                     })), [setRequest])}

                     />
                     <Form.Text className="sub-error">
                     {t(errors['subject'])}
                     </Form.Text>
                     </FormGroup>
                     </Col>
                     */}

                    <Col sm={true} md={true}>
                        <FormGroup mandatory={true} error={errors['category']}>
                            <Form.Label>{t('category')}</Form.Label>
                            <EnumDropdown
                                t={t}
                                value={getOrNull(request?.category)}
                                options={TicketCategory}
                                isClearable={false}
                                placeholder={t('select_category')}
                                isSearchable={true}
                                onChange={useCallback(
                                    (type) => setRequest(r => ({
                                        ...r,
                                        category: type,
                                        subject: type
                                    })),
                                    [setRequest]
                                )}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['category'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                </Row>

                <NewMessageComponent request={request} setRequest={setRequest} id={"description"} errors={errors}/>

            </Box>
        </Modal.Body>

        <ModalButtons>
            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>

            <Button variant="primary" onClick={() => onSubmit(request)}>
                {t("create_ticket")}
            </Button>
        </ModalButtons>
    </Modal>


}

export default RequestSupportComponent;
