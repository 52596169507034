import React, {useCallback, useEffect, useRef, useState} from 'react'
import {ReactComponent as Logo} from 'layout/assets/images/logo-small.svg'

import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {useMenuState} from "./MenuStateContext";
import {useLocation} from "react-router";
import {useIsMobile} from "system/Window/Mobile";
import {useClickedOutsideMobile} from "system/Window/HandleClickOutside";
import {MainMenu, MenuBottomMobile} from "app/menu";
import FixedItemsComponent from "layout/modules/BaseLayout/Menu/components/fixed/FixedItemsComponent";

import './css/menu-responsive.scss'
import './css/menu-responsive-effects.scss'
import './css/menu.scss'


//const useMenuCollapsed = createPersistedState('menuCollapsed');

const Menu = (props) => {

    const ref = useRef(null);
    const isMobile = useIsMobile();
    const location = useLocation();

    const [menuState, setMenuState] = useMenuState();

    //const [menuCollapsed, setMenuCollapsed] = useMenuCollapsed(false);
    const [menuCollapsed, setMenuCollapsed] = useState(false);

    const setMenuVisible = useCallback((value) => {
        setMenuState((state) => ({
            ...state, visible: value
        }));
    }, [setMenuState]);


    useEffect(() => setMenuVisible(!isMobile), [setMenuVisible, isMobile]);

    useEffect(() => {
        setMenuVisible(!isMobile);
    }, [location.pathname, setMenuVisible, isMobile]);


    //Close on outsiide click
    useClickedOutsideMobile(ref, useCallback(() => {
        if (menuState?.visible && isMobile) {
            setMenuVisible(false)
        } else if (menuState?.active) {
            setMenuState(menuState => ({...menuState, active: undefined}))
        }
    }, [setMenuVisible, setMenuState, menuState?.visible, menuState?.active, isMobile]));

    const switchMenu = useCallback((e) => {
        e.stopPropagation();
        setMenuVisible(!menuState?.visible);
    }, [menuState?.visible, setMenuVisible]);

    return <>
        <div ref={ref}
             className={classnames({'menu': true, 'is-visible': menuState?.visible, 'collapsed': menuCollapsed})}>
            <div>
                <div className={"menu-header"}>

                    <div className={"logo-menu"}>
                        <Logo/>
                    </div>

                </div>
                <div className={classnames({
                    "main-menu-items": true, "collapsed": menuCollapsed, "sub-menu-active": menuState.active
                })}>

                    <MainMenu {...props}/>

                </div>

                <FixedItemsComponent {...props} collapsed={menuCollapsed}/>

                <div className="collapse-icon" onClick={() => {
                    setMenuCollapsed(!menuCollapsed);
                    setMenuState(menuState => ({...menuState, active: null}));
                }}>
                    {menuCollapsed ? <FontAwesomeIcon icon={faChevronRight}/> : <FontAwesomeIcon icon={faChevronLeft}/>}
                </div>

            </div>
        </div>

        <div className={menuState?.visible ? 'menu-bottom menu-is-visible' : 'menu-bottom'}>
            <MenuBottomMobile onOpenMenu={switchMenu} {...props} />
        </div>
    </>


}

export default Menu;
