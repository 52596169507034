import React, {useEffect, useMemo} from "react"
import {useMutate} from "system/Rest/Rest"
import {useApiState} from "system/API/APIStateContext";
import Loading from "layout/modules/Loading/Loading";
import {UserType} from "types/Users";
import {useLocation} from "react-router";
import appConfig from "configs/config";

const Logout = (props) => {

    const {setAuthentication, authentication} = useApiState();
    const location = useLocation();
    const urlParameters = useMemo(() => new URLSearchParams(location.search), [location]);
    const force = urlParameters?.get('force');

    const {mutate: logout} = useMutate({
        verb: "DELETE",
        path: "/auth/logout",
        localErrorOnly: true,
    });

    const isVeterinary = useMemo(
        () => UserType.VETERINARY === authentication.type,
        [authentication.type]
    );


    useEffect(() => {

        if (!isVeterinary || force) {
            logout({})
                .then(() => {
                    setAuthentication({});
                })
                .catch(() => {
                    setAuthentication({})
                });
        } else {
            window.location.href = appConfig.omv.logoutUrl + "?force=1";
        }

    }, [setAuthentication, isVeterinary, force, logout]);

    return <Loading visible={true}/>;
}

export default Logout;
