import Form from "react-bootstrap/Form";
import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Box from "layout/modules/Box/Box";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import AnimalAddressComponent from "app/animals/components/form/AnimalAddressComponent";
import TagInput from "layout/modules/Forms/Tags/TagInput";

const AnimalComponent = (props) => {

    const {errors = {}, animalOwner, setAnimalOwner, readOnly, isBulk, animals} = props;
    const {t} = useTranslation('transfer');

    const [animalAddress, setAnimalAddress] = useState({
        postalCode: animalOwner?.animalPostalCode,
        address: animalOwner?.animalAddress,
        parishId: animalOwner?.animalParishId,
        locality: animalOwner?.animalLocality,
        useOwnerAddress: animalOwner.useOwnerAddress
    });


    const animalErrors = useMemo(
        () => ({
            postalCode: errors?.animalPostalCode,
            locality: errors?.animalLocality,
            parishId: errors?.animalParishId,
            address: errors?.animalAddress,

        }),
        [errors]
    );

    const handleAnimalAddress = useCallback((animalAddressFnc) => {
        const aad = animalAddressFnc({
            useOwnerAddress: false
        });

        setAnimalAddress(aad);
        setAnimalOwner(animalOwner => ({
            ...animalOwner,
            useOwnerAddress: aad.useOwnerAddress,
            animalParishId: aad.parishId,
            animalAddress: aad.address,
            animalPostalCode: aad.postalCode,
            animalLocality: aad.locality,
        }))
    }, [setAnimalAddress, setAnimalOwner])

    return <>
        <Box>
            <BoxTitle>{t('animal_data')}</BoxTitle>


            {isBulk
                ? <div className={"view-mode"}>
                    <FormGroup error={errors['animalName']} mandatory={false}>
                        <Form.Label>{t('transponders')}</Form.Label>
                        <TagInput
                            disabled={true}
                            value={animals.map(a => a.transponder)}
                        />
                    </FormGroup>
                </div>
                : <FormGroup error={errors['animalName']} mandatory={false}>
                    <Form.Label>{t('animal_name')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('animal_name_ph')}
                        value={getOrEmpty(animalOwner?.newAnimalName)}
                        onChange={(e) => setAnimalOwner(animalOwner => ({
                            ...animalOwner,
                            newAnimalName: e.target.value,
                        }))}
                        disabled={readOnly}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['name'])}
                    </Form.Text>
                </FormGroup>
            }

        </Box>

        <AnimalAddressComponent
            animal={animalAddress}
            setAnimal={handleAnimalAddress}
            errors={animalErrors}
            readOnly={readOnly}/>
    </>

}

export default AnimalComponent;
