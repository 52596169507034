import React, {useCallback, useState} from "react";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import {readFileContent} from "system/Files/ReadFileContent";
import Loading from "layout/modules/Loading/Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import Box from "layout/modules/Box/Box";
import {useTranslation} from "react-i18next";
import canPreviewFile from "app/files/utils/canPreviewFile";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import PreviewContainer from "app/files/containers/PreviewContainer";
import DownloadContainer from "app/files/containers/DownloadContainer";
import {acceptTypeImages, acceptTypesPdf} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";

const DocumentsComponent = props => {

    const {aggression, setAggression, readOnly} = props;
    const {t} = useTranslation("aggression");

    const [uploadingCounter, setUploadingCounter] = useState(0);
    const documentCounter = aggression?.documents?.length;

    const [preview, setPreview] = useState(null);
    const [download, setDownload] = useState(null);

    const handlePreviewClose = useCallback(() => setPreview(null), [setPreview]);
    const handleDownloadComplete = useCallback(() => setDownload(null), [setDownload]);

    const handleFileUpload = useCallback((nf) => {
        nf.forEach((file) => {
            setUploadingCounter((uc) => uc + 1);
            readFileContent(file, (content) => {
                setAggression(agg => ({
                    ...agg,
                    documents: [
                        ...agg.documents,
                        {
                            filename: file.name,
                            type: file.type,
                            contents: btoa(content)
                        }
                    ]
                }));
                setUploadingCounter((uc) => uc - 1);
            });
        })
    }, [setUploadingCounter, setAggression]);


    if (readOnly && documentCounter === 0)
        return <></>


    return <Box>
        <BoxTitle>
            {t('aggression_files')}
        </BoxTitle>

        {preview &&
        <PreviewContainer file={preview} onClose={handlePreviewClose}/>
        }

        {download &&
        <DownloadContainer file={download} onDownloadComplete={handleDownloadComplete}/>
        }

        <Row>
            {!readOnly &&
            <Col lg={(documentCounter > 0) ? 6 : 12} sm={12} className={"col-upload"}>
                <DragDropFile
                    maxFiles={10}
                    acceptTypes={{
                        ...acceptTypesPdf,
                        ...acceptTypeImages,
                    }}
                    text={t('aggression_file_upload')}
                    onFileUploaded={handleFileUpload}
                />
            </Col>
            }

            <Col lg={true} sm={12} className={"file-list"}>
                <Loading visible={uploadingCounter > 0}/>
                {aggression?.documents?.map((file, id) =>
                    <div className={"file"} key={id}>
                        <div className={"name"}>
                            {file.filename}
                        </div>
                        <div className={"close"}>

                            {!readOnly &&
                            <FontAwesomeIcon
                                icon={faTimes}
                                className={"delete"}
                                onClick={() => {
                                    setAggression(ao => {
                                        const documents = [...ao.documents];
                                        documents.splice(id, 1);
                                        return {
                                            ...ao,
                                            documents: documents
                                        }
                                    })
                                }}
                            />
                            }

                            {readOnly &&
                                <div className={"buttons"}>
                                    {canPreviewFile(file) &&
                                    <TableIconButton
                                        icon={faSearch}
                                        className={"table-btn btn-rounded-primary small"}
                                        onClick={() => setPreview(file)}
                                    />
                                    }

                                    <TableIconButton
                                        icon={faDownload}
                                        className={"table-btn btn-rounded-primary small"}
                                        onClick={() => setDownload(file)}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                )}
            </Col>
        </Row>


    </Box>
}

export default DocumentsComponent;
