import React, {useMemo} from "react";
import HeaderMessage from "layout/modules/InfoMessages/HeaderMessage";
import Poll from "system/Rest/Poll";

const AnnouncementComponent = (props) => {

    return <Poll
        path="/informations/"
        localErrorOnly={true}
        interval={300 * 1000}
        queryParams={useMemo(() => ({
            type: "GENERAL_ANNOUNCEMENT"
        }), [])}
    >
        {(data) => data?.content
            ? <HeaderMessage type={"warning"} message={data.content}/>
            : <></>
        }
    </Poll>


}

export default AnnouncementComponent;
