import React, {useCallback, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

import "./collapse.scss";

const Collapse = (props) => {

    const {isOpen: open = true, title, children, className} = props;

    const [isOpen, setOpen] = useState(open);

    return <div className={classnames("collapse-box", className)}>
        <div
            className={"collapse-title"}
            onClick={useCallback(
                () => setOpen(open => !open),
                [setOpen]
            )}>

            {title}

            <div className={"icon"}>
                {isOpen
                    ? <FontAwesomeIcon icon={faAngleUp}/>
                    : <FontAwesomeIcon icon={faAngleDown}/>
                }
            </div>


        </div>

        <div className={classnames("collapse-content", {open: isOpen})}>
            {children}
        </div>


    </div>
}

export default Collapse;
