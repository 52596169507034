import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {useTranslation} from "react-i18next";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import React from "react";
import {Col, Row} from "react-bootstrap";
import DateTime from "system/DateTime/DateTime";

const ClosedComponent = props => {

    const {t} = useTranslation("animal");
    const {lostInfo} = props;

    return <Box>
        <BoxTitle>{t('closed_title')}</BoxTitle>

        <Row>
            <Col lg={6} md={12}>
                {lostInfo?.closedByOwner
                    ? <LabelWithValue label={t('closed_by')} value={t('closed_by_owner')}/>
                    : <LabelWithValue label={t('closed_by')} value={lostInfo?.closedBy?.name}/>
                }
            </Col>


            <Col lg={6} md={12}>
                <LabelWithValue label={t('closed_date')}>
                    <DateTime format="DD-MM-YYYY HH:mm">
                        {lostInfo?.closedDate}
                    </DateTime>
                </LabelWithValue>
            </Col>

            <Col lg={6} md={12}>
                <LabelWithValue label={t('close_situation')}>
                    {t(lostInfo?.deliveryStatus?.toLowerCase())}
                </LabelWithValue>
            </Col>

        </Row>

    </Box>
}

export default ClosedComponent;
