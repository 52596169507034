import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";

const ObservationComponent = props => {

    const {t} = useTranslation("animal");
    const {readOnly, errors, lostInfo, setLostInfo} = props;

    return  <Box>
        <BoxTitle>{t('observations')}</BoxTitle>

        <Row>
            <Col sm={12} xl={12}>
                <FormGroup error={errors['observations']} mandatory={false}>
                    <Form.Label>{t('observations')}</Form.Label>
                    <Form.Control
                        type="textarea"
                        as="textarea"
                        rows={3}
                        disabled={readOnly}
                        value={getOrEmpty(lostInfo?.observations)}
                        onChange={useCallback((e) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            observations: e.target.value,
                        })), [setLostInfo])}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['observations'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Row>
    </Box>
}

export default ObservationComponent;
