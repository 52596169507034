import {Trans} from "react-i18next";
import classnames from "classnames";

import './assets/css/header-message.scss';

const HeaderMessage = (props) => {

    const {message, type, action,actionLabel} = props;

    return <div className={classnames({
        ["alert-" + type]: type,
        "header-message": true,
    })}>
        <div className={"info"}>
            <Trans>{message}</Trans>
        </div>

        {actionLabel &&
        <div className={"action"} onClick={action ? action : undefined}>
            {actionLabel}
        </div>
        }

    </div>
}

export default HeaderMessage;
