import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {Form} from "react-bootstrap";
import React, {useCallback} from "react";
import CodeEditor from "layout/modules/Forms/CodeEditor/CodeEditor";
import {getOrEmpty} from "system/Objects/ObjectParameters";


const QueryComponent = props => {

    const {errors, t, template, setTemplate, readOnly} = props;

    const handleChange = useCallback(
        (val) => {
            setTemplate(template => ({
                ...template,
                query: val,
            }))
        },
        [setTemplate]
    )

    return <Row>
        <Col sm={12} lg={12} xl={12}>
            <FormGroup error={errors['query']}>
                <Form.Label>{t('query_base')}</Form.Label>
                <CodeEditor
                    onChange={handleChange}
                    value={getOrEmpty(template?.query)}
                    readOnly={readOnly}
                    />

                <Form.Text className="sub-error">
                    {t(errors['query'])}
                </Form.Text>
            </FormGroup>
        </Col>

    </Row>;

}

export default QueryComponent;
