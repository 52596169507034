import BoxTitle from "layout/modules/Box/components/BoxTitle";
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {acceptTypeImages} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";
import Form from "react-bootstrap/Form";
import MultipleFileUpload from "layout/modules/Forms/MultipleFileUpload/MultipleFileUpload";
import getAsArray from "system/Arrays/getArray";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "app/animals/assets/css/lost-additional-photos.scss";

const AdditionalAnimalPhotosComponent = props => {


    const {t} = useTranslation("animal");
    const {readOnly, lostInfo, setLostInfo, errors} = props;

    const totalPhotos = useMemo(
        () => lostInfo?.additionalPhotos?.length ?? 0,
        [lostInfo?.additionalPhotos]
    );

    const handlePhotoUpload = useCallback((photo) => {
            setLostInfo(lostInfo => ({
                ...lostInfo,
                additionalPhotos: [
                    ...getAsArray(lostInfo.additionalPhotos),
                    {
                        filename: photo.name,
                        type: photo.type,
                        contents: photo.content,
                    }
                ]
            }));
        }, [setLostInfo]
    );

    const handleDeletePhoto = useCallback((id) => {
            setLostInfo(lostInfo => {
                const additionalPhotos = [...lostInfo.additionalPhotos];
                additionalPhotos.splice(id, 1);

                return {
                    ...lostInfo,
                    additionalPhotos: additionalPhotos
                };
            });

        }, [setLostInfo]
    );

    if (totalPhotos === 0 && readOnly) {
        return <></>
    }

    return <Box className={"lost-additional-animal-photos"}>
        <BoxTitle>{t('lost_additional_animal_photo')}</BoxTitle>

        <FormGroup error={errors['additionalPhotos']} mandatory={false}>

            <MultipleFileUpload
                maxFiles={4}
                readOnly={readOnly}
                files={lostInfo?.additionalPhotos}
                uploadBoxText={t('additional_photos_file_upload')}
                onFileUploaded={handlePhotoUpload}
                acceptTypes={{
                    ...acceptTypeImages,
                }}>

                <AdditionalAnimalPhotoComponent t={t} readOnly={readOnly} handleDeletePhoto={handleDeletePhoto}/>

            </MultipleFileUpload>

            <Form.Text className="sub-error">
                {t('error_in_additional_photos')}
            </Form.Text>
        </FormGroup>

    </Box>
}


const AdditionalAnimalPhotoComponent = props => {

    const {t, file, id, readOnly, handleDeletePhoto} = props;

    return <div className={"additional-photo-entry"}>

        {!readOnly && <div className={"delete-additional-photo"} onClick={() => handleDeletePhoto(id)}>
            <FontAwesomeIcon
                icon={faTimes}
                className={"delete-icon"}
            />
            <div className={"text-delete-label"}>
                {t('click_to_delete')}
            </div>
        </div>
        }

        <img
            src={`data:image/jpeg;base64,${file.contents}`}
            alt={t('alternative_photo')}/>
    </div>

}

export default AdditionalAnimalPhotosComponent;
