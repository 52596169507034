import React, {useCallback, useMemo} from "react";

const MultiOptionEntryComponent = React.forwardRef((props, ref) => {

    const {children, onChange, entry, index, readOnly, errors} = props;

    const handleChange = useCallback(
        (entry) => onChange((data) =>
            Object.assign([], data, {
                [index]: entry
            })),
        [index, onChange]
    );

    const handleDelete = useCallback(
        () => onChange((data) => {
            return data.filter((value, idx) => (index !== idx))
        }),
        [onChange, index]
    );

    const element = useMemo(
        () => {
            return React.cloneElement(children, {
                entry: entry,
                onChange: handleChange,
                onDelete: handleDelete,
                readOnly: readOnly,
                errors: errors?.[index],
                ref: ref
            })
        },

        // eslint-disable-next-line
        [entry, handleChange, handleDelete, readOnly, errors, index, ref]
    );

    return <div className={"form-multi-option-item"}>
        {element}
    </div>


});

export default MultiOptionEntryComponent;
