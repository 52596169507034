import {useMemo} from "react";
import rdiff from 'recursive-diff';
import {getOrDefault} from "system/Objects/ObjectParameters";

const useGetDiffs = (va, vb) => {

    return useMemo(
        () => (va && vb)
            ? compareVersions(
                getOrDefault(va?.entity, va),
                getOrDefault(vb?.entity, vb))
            : {},
        [va, vb]
    );
}

const compareVersions = (va, vb) => rdiff.getDiff(va, vb).reduce((acc, diff) => {
        if (diff.path) {
            return {
                ...acc,
                [diff.path.join('.')]: true,
            }
        } else
            return acc;
    }, {}
);

export {useGetDiffs, compareVersions};
