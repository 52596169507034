import TicketListComponent from "app/owners/components/view/TicketListComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import locale from "app/support/locales/locales";
import React, {useMemo} from "react";

const OwnerTicketsContainer = props => {

    const {id} = props;

    return <GenericListContainer
        {...props}
        searchPath={"/owners/" + id + "/tickets"}
        module="support"
        hideTitle={true}
        locale={locale}
        queryParameters={useMemo(() => ({
            sortProperty: 'lastUpdate',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 10
        }), [])}>
        <TicketListComponent/>
    </GenericListContainer>

}

export default OwnerTicketsContainer;
