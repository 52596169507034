import {useTranslation} from "react-i18next";
import BallComponent from "app/animals/components/generic/BallComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DateTime from "system/DateTime/DateTime";
import React from "react";
import CardOptionsComponent from "app/animals/components/list/implementations/cardList/CardOptionsComponent";

const ExternalAnimalCardComponent = (props) => {

    const {t} = useTranslation("animal");
    const {animal} = props;

    return <div className={"animal-card"}>
        <BallComponent
            animal={animal}
            hideStateBorder={false}
        />
        <div className={"animal-card-box"}>

            <div className={"animal-card-box-header external"}>

                <div className={"triangle"}></div>
                <div className={"name name-external"}>
                    {animal?.name ? animal.name : animal?.transponder}
                </div>
                <div className={"line-regular"}>
                    {t('external_animal')}
                </div>


                <div className={"animal-status"}>
                    {t(animal.status)}
                </div>
            </div>

            <div className={"animal-card-box-content"}>
                <Row>
                    <Col sm={7} md={7} xl={7}>
                        <div className={"title"}>
                            {t('transponder')}
                        </div>
                        <div className={"value"}>
                            {animal.transponder}
                        </div>

                    </Col>

                    <Col sm={5} md={5} xl={5}>
                        <div className={"title"}>
                            {t('occurrence_date')}
                        </div>
                        <div className={"value"}>
                            <DateTime format="DD-MM-YYYY" timezone={"UTC"}>
                                {animal.eventDate}
                            </DateTime>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} md={12} xl={12}>
                        <div className={"title"}>
                            {t('lost_local')}
                        </div>
                        <div className={"value"}>
                            {animal.eventLocal}
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col sm={7} md={7} xl={7}>
                        <div className={"title"}>
                            {t('participant_name_alt')}
                        </div>
                        <div className={"value fill-empty"}>
                            {animal.participantName}
                        </div>

                    </Col>

                    <Col sm={5} md={5} xl={5}>
                        <div className={"title"}>
                            {t('participant_contact')}
                        </div>
                        <div className={"value"}>
                            {animal.participantContact}
                        </div>
                    </Col>

                </Row>
            </div>

            <CardOptionsComponent {...props} animal={animal} t={t}/>

        </div>
    </div>

}

export default ExternalAnimalCardComponent;
