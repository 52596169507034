import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import {GenderTypeWithUndefined} from "types/Animal";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";

const GenderComponent = props => {

    const {t, value, setValue, parameter, error, readOnly} = props;

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <EnumDropdown
                t={t}
                isDisabled={readOnly}
                isClearable={!parameter?.mandatory}
                options={GenderTypeWithUndefined}
                value={getOrNull(value)}
                isMulti={parameter?.multipleValues}
                onChange={setValue}
            />

            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default GenderComponent;
