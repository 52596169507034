import React, {useEffect} from "react"
import ErrorPage from "./ErrorPage"
import locale from "./locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import {ReactComponent as ErrorImage} from "./assets/images/not-authorized.svg";

const Unauthorized = (props) => {

    const {t} = useTranslations('errors', locale);
    const isLoginRedirect = window.history.state?.usr?.loginRedirected;

    useEffect(
        () => {
            if (isLoginRedirect)
                window.location.href = "/"
        },
        [isLoginRedirect]
    );


    if (!isLoginRedirect) {
        return <ErrorPage {...props} message={t("403_UNAUTHORIZED")} showGoDashboard={true}>
            <ErrorImage/>
        </ErrorPage>
    } else {
        return <></>
    }
}

export default Unauthorized;
