import {useTranslation} from "react-i18next";
import React, {useCallback, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import {readFileContent} from "system/Files/ReadFileContent";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import Loading from "layout/modules/Loading/Loading";
import {useIsMobile} from "system/Window/Mobile";
import PhotoCrop from "layout/modules/Forms/PhotoCrop/PhotoCrop";
import "app/animals/assets/css/photo-modal.scss";
import {acceptTypeImages} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";

const SetPhotoComponent = props => {

    const {photo, onClose, onChange, loading} = props;
    const {t} = useTranslation('animal');

    const uploadRef = useRef(null);
    const isMobile = useIsMobile();

    const [photoContent, setPhoto] = useState(photo);
    const [photoCropped, setPhotoCropped] = useState(photo);

    const handleFileUpload = useCallback((nf) => {
        nf.forEach((file) => {
            readFileContent(file, (content) => {
                setPhotoCropped(btoa(content));
                setPhoto(btoa(content))
            });
        })
    }, [setPhoto]);

    const handleClear = useCallback(
        () => {
            setPhoto(null);
            setPhotoCropped(null);
        },
        [setPhoto, setPhotoCropped]
    );

    return <Modal show={true} className={"dark"} backdrop="static" keyboard={false} onHide={onClose}>

        <Loading visible={loading}/>
        <Modal.Header closeButton>
            <Modal.Title>{t('set_photo')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className={"photo-modal-content"}>
                <div className={"image"}>
                    <DragDropFile
                        ref={uploadRef}
                        maxFiles={1}
                        maxSize={5242880*3}
                        acceptTypes={{
                            ...acceptTypeImages
                        }}
                        onFileUploaded={handleFileUpload}
                        disabledClick={true}>
                        {photoContent
                            ? <PhotoCrop photo={photoContent} onChange={setPhotoCropped}/>
                            : <div className={"select-photo"}>
                                {t(isMobile ? 'select_photo_mobile' : 'select_photo')}
                            </div>
                        }
                    </DragDropFile>

                </div>

                <div className={"buttons"}>
                    <Button variant="primary" disabled={photoContent} onClick={() => uploadRef.current.click()}>
                        {t('upload_photo')}
                    </Button>

                    <Button variant="danger" disabled={!photoContent} onClick={handleClear}>
                        {t('clear_photo')}
                    </Button>

                </div>

            </div>


        </Modal.Body>
        <ModalButtons>

            <Button variant="secondary" onClick={onClose}>
                {t("close")}
            </Button>

            <Button variant="primary" onClick={() => onChange(photoCropped)}>
                {t("set_photo")}
            </Button>

        </ModalButtons>

    </Modal>
}

export default SetPhotoComponent;
