import classnames from "classnames";
import React from "react";
import {useNavigate} from "react-router";
import DateTime from "system/DateTime/DateTime";
import {parseToCss} from "system/Utils/css";

const ListEntryComponent = props => {

    const {data, t, isDashboard, simpleMode} = props;
    const navigate = useNavigate();

    return <>
        {data?.content?.map((value, index) => {
            return <tr
                key={index}
                className={classnames("ticket-item", {"new": !value.readTimestamp})}
                onClick={() => {
                    navigate("/support/" + value.id);
                }}
            >

                <td className={"id"}>
                    {value.id}
                </td>

                <td className={"subject"}>
                    <div className={"sub-line"}>
                        {t(value.category)}
                    </div>
                    <div className={"subject fill-empty"}>
                    {value.subject}
                    </div>

                </td>

                {!isDashboard &&
                <td className={classnames("d-none d-md-table-cell", { "td-sorted" : !simpleMode })}>
                    <DateTime format="DD-MM-YYYY">
                        {value.lastUpdateTimestamp}
                    </DateTime>

                    <div className={"sub-line"}>
                        <DateTime format="HH:mm">
                            {value.lastUpdateTimestamp}
                        </DateTime>
                    </div>
                </td>
                }

                <td>
                    <div
                        className={classnames("ticket-status", parseToCss(value.status))}>
                        {t(value.status)}
                    </div>
                </td>

            </tr>
        })}
    </>

}

export default ListEntryComponent;
