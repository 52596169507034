import React, {createContext, useEffect, useMemo, useState} from "react"
import createPersistedState, {
    getLocalStorageEntries,
    removeLocalStorageEntry
} from 'system/System/PersistedState/PersistenState'
import {setMainLanguage} from "configs/i18n/i18n";
import usePrevious from "system/Components/usePrevious";

const ApiStateContext = createContext({});
const useAuthenticationState = createPersistedState('authentication');

function useApiState() {
    const context = React.useContext(ApiStateContext)
    if (!context) {
        throw new Error(`useApiState must be used within a ApiStateContext`)
    }
    return context
}


const ApiStateProvider = (props) => {

    const [apiState, setApiState] = useState({});
    const [authentication, setAuthentication] = useAuthenticationState({});
    const oldAuthenticationId = usePrevious(authentication?.id);

    const value = useMemo(() => {
        return {
            apiState,
            setApiState,
            authentication,
            setAuthentication
        }
    }, [apiState, authentication, setAuthentication, setApiState]);


    //Remove poll state on logout
    useEffect(
        () => {
            if (oldAuthenticationId && oldAuthenticationId !== authentication?.id) {
                getLocalStorageEntries().forEach(e => {
                    if (e.startsWith("pollState-")) {
                        removeLocalStorageEntry(e);
                    }
                })

            }
        },
        [authentication?.id, oldAuthenticationId]
    )

    const children = useMemo(
        () => React.cloneElement(props.children, {
            ...props.children.props,
            requestOptions: {headers: {Authorization: (authentication?.session?.token) ? "Bearer " + authentication?.session.token : ""}},
            onError: (error) => {
                setApiState((apiState) => {
                    return {
                        ...apiState,
                        error: {
                            ...error,
                            errorURL: window.location.href,
                        },
                    }
                });

                if (error.status === 401 && authentication.isAuthenticated) {
                    setAuthentication({
                        sessionExpired: true,
                    });
                }
            },
        }),
        [authentication?.session?.token, authentication.isAuthenticated, setAuthentication, props.children]
    );

    useEffect(() => {
        if (authentication?.language)
            setMainLanguage(authentication.language);
    }, [authentication]);

    return <ApiStateContext.Provider value={value}>
        {children}
    </ApiStateContext.Provider>;

}

export {ApiStateProvider, useApiState}
