import React, {useCallback} from "react";
import classnames from "classnames";
import {ReactComponent as ImgLost} from "../../../../assets/images/lost/lost.svg";
import {ReactComponent as ImgFound} from "../../../../assets/images/lost/found.svg";
import {AnimalStatus} from "types/Animal";

const TypeSelectorComponent = props => {

    const {setLostInfo, lostInfo, readOnly, t} = props;

    const setStatus = useCallback(
        (status) => {
            if (!readOnly)
                setLostInfo(lostInfo => ({
                    ...lostInfo,
                    status: status,
                }))
        },
        [setLostInfo, readOnly]
    )


    return <div className={"lost-type-area"}>

        <div
            onClick={() => setStatus(AnimalStatus.LOST)}
            className={classnames("lost-type-box lost", {
                "active": lostInfo.status === AnimalStatus.LOST,
                "read-only": readOnly,
            })}>

            <div className={"label-block"}>
                <div className={"label lost"}>
                    {t('lost_set_lost')}
                </div>

                <div className={"sub-label"}>
                    {t('lost_set_lost_msg')}
                </div>
            </div>

            <div className={"img-block"}>
                <ImgLost/>
            </div>

        </div>

        <div
            onClick={() => setStatus(AnimalStatus.FOUND)}
            className={classnames("lost-type-box found", {
                "active": lostInfo.status === AnimalStatus.FOUND,
                "read-only": readOnly,
            })}>

            <div className={"label-block"}>
                <div className={"label found"}>
                    {t('lost_set_found')}
                </div>
                <div className={"sub-label"}>
                    {t('lost_set_found_msg')}
                </div>
            </div>

            <div className={"img-block"}>
                <ImgFound/>
            </div>


        </div>


    </div>
}

export default TypeSelectorComponent;
