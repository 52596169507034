import React, {useCallback, useEffect, useState} from "react";
import classnames from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import IDComponent from "./IDComponent";
import AnimalDataComponent from "./AnimalDataComponent";
import AnimalAddressComponent from "./AnimalAddressComponent";
import PedigreeComponent from "./PedigreeComponent";
import OwnerComponent from "./OwnerComponent";
import PhotoComponent from "./PhotoComponent";
import VeterinaryAndEntityComponent from "./VeterinaryAndEntityComponent";
import DeathDateComponent from "./DeathDateComponent";
import ObservationsComponent from "./ObservationsComponent";
import locales from "app/animals/locales/locales";
import {Form} from "react-bootstrap";
import useTranslations from "system/Translations/UseTranslations";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import Loading from "layout/modules/Loading/Loading";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import Collapse from "layout/modules/Collapse/Collapse";
import AnimalBoxOptionsButtons from "app/animals/components/form/AnimalBoxOptionsButtons";
import PreRegisterAlertComponent from "app/control/modules/preRegister/components/AlertComponent";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import useAnimalErrors from "app/animals/utils/useAnimalErrors";
import {getEmptyAnimal} from "app/animals/utils/AnimalResolver";
import AnimalFieldIsAllowed from "app/animals/utils/AnimalFieldsAllowed";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import "app/animals/assets/css/form.scss";
import {useLocation} from "react-router";
import BreedDisclaimerPopupComponent from "app/animals/components/form/BreedDisclaimerPopupComponent";

const FormComponent = (props) => {

    const {
        loading, create, errors: responseErrors = {}, viewMode,
        compareMode, changes: currentChanges, photoUploadRef,
        fullChanged, onSubmit: onAnimalSubmit, onLock, animal: remoteAnimal,
        onEditModeChange, showLink, showOwnerLink, showObservations, validationStatus
    } = props;

    const {animal, setAnimal} = useAnimalState();

    const {t} = useTranslations('animal', locales);

    const [readOnly, setReadOnly] = useState(viewMode || compareMode);
    const [changes, setChanges] = useState({});
    const [errors, setSubmitted] = useAnimalErrors(animal, responseErrors, readOnly, compareMode);

    const location = useLocation();

    const isTransponderAllowed = validationStatus?.canRegister || !create;

    useEffect(() => {
        setChanges(currentChanges ? currentChanges : {});
    }, [currentChanges]);


    //Set read Only on Edit Link
    useEffect(() => {
        if (location?.state?.edit === true) {
            setReadOnly(false);
            location.state.edit = false;
        }
    }, [location?.state, location?.state?.edit, setReadOnly]);

    useEffect(() => {
        if (onEditModeChange) {
            onEditModeChange(!readOnly);
        }

        // eslint-disable-next-line
    }, [readOnly]);

    //Set readonly on unload
    useEffect(() => {
        return () => {
            if (onEditModeChange)
                onEditModeChange(false);
        }
    }, [onEditModeChange]);


    //Discard initial errors after submit
    const onSubmit = useCallback(
        () => {
            setSubmitted(true);
            onAnimalSubmit(animal);
        },
        [animal, onAnimalSubmit, setSubmitted]
    );

    return <div className={classnames("animal-form", {
        "view-mode": (viewMode && readOnly),
        "show-errors": (viewMode && readOnly),
        "compare-mode": compareMode,
    })}>

        {!compareMode &&
        <>
            <GenericFormError t={t} errors={responseErrors}/>
            
            <FormHandleNotSaved
                remote={remoteAnimal ? remoteAnimal : getEmptyAnimal()}
                local={animal}
                loading={loading}
                readOnly={readOnly}
                setLocal={setAnimal}
                setReadOnly={setReadOnly}
                onSubmit={onSubmit}
                disabled={validationStatus?.canPreRegister}
            />
        </>
        }

        <Loading visible={loading}/>

        {validationStatus?.canPreRegister &&
        <PreRegisterAlertComponent
            animal={animal}
            create={create}
            readOnly={readOnly}
        />
        }


        {validationStatus?.notBillable &&
        <AdvanceInfoMessage
            icon={faInfo}
            type={"success"}
            iconType={"success"}
            title={t("transponder_free")}
            message={t("transponder_free_msg")}
        />
        }

        <BreedDisclaimerPopupComponent animal={animal} create={create}/>

        <Form className={classnames({"full-changed": fullChanged})}>
            <Box>
                <BoxTitle>{t('animal_id')}</BoxTitle>

                <BoxOptions>
                    <AnimalBoxOptionsButtons
                        create={create}
                        compareMode={compareMode}
                        animal={animal}
                        remoteAnimal={remoteAnimal}
                        onLock={onLock}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                        setAnimal={setAnimal}
                        viewMode={viewMode}
                        showLink={showLink}
                        showObservations={showObservations}
                    />

                </BoxOptions>

                <IDComponent
                    {...props}
                    changes={changes}
                    errors={errors}
                    readOnly={readOnly}/>

            </Box>


            {isTransponderAllowed &&
            <>
                <Collapse title={t('animal_data')}>

                    <AnimalDataComponent
                        create={create}
                        changes={changes}
                        errors={errors}
                        compareMode={compareMode}
                        readOnly={readOnly}/>

                    <AnimalFieldIsAllowed field={"lopNumber"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                        <PedigreeComponent
                            create={create}
                            changes={changes}
                            errors={errors}
                            compareMode={compareMode}
                            readOnly={readOnly}/>
                    </AnimalFieldIsAllowed>


                    <AnimalFieldIsAllowed field={"useOwnerAddress"} isReadOnly={readOnly} errors={errors}
                                          isCreation={create}>
                        <AnimalAddressComponent
                            compareMode={compareMode}
                            animal={animal}
                            setAnimal={setAnimal}
                            create={create}
                            changes={changes}
                            errors={errors}
                            readOnly={readOnly}/>
                    </AnimalFieldIsAllowed>

                    {compareMode &&
                    <DeathDateComponent
                        animal={animal}
                        changes={changes}
                        errors={errors}
                        readOnly={readOnly}
                    />
                    }
                </Collapse>


                <Collapse title={t('owner')} className={classnames({"view-mode": readOnly || animal?.owner?.isLocked})}>
                    <OwnerComponent
                        showLink={showOwnerLink}
                        compareMode={compareMode}
                        create={create}
                        changes={changes}
                        errors={errors}
                        readOnly={readOnly || animal?.owner?.isLocked}/>
                </Collapse>

                <AnimalFieldIsAllowed field={"entityId"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                    <Collapse title={t('veterinary_and_entity')}>
                        <VeterinaryAndEntityComponent
                            create={create}
                            animal={animal}
                            setAnimal={setAnimal}
                            changes={changes}
                            errors={errors}
                            readOnly={readOnly}
                        />
                    </Collapse>
                </AnimalFieldIsAllowed>


                {(!readOnly || animal?.observations) &&
                <AnimalFieldIsAllowed field={"observations"} isReadOnly={readOnly} errors={errors} isCreation={create}
                                      animal={animal}>
                    <Collapse title={t('observations')}>
                        <ObservationsComponent
                            create={create}
                            animal={animal}
                            setAnimal={setAnimal}
                            changes={changes}
                            errors={errors}
                            readOnly={readOnly}
                        />
                    </Collapse>
                </AnimalFieldIsAllowed>
                }
            </>
            }

            <PhotoComponent
                ref={photoUploadRef}
                animal={animal}
                setAnimal={setAnimal}
                changes={changes}
                errors={errors}
                setReadOnly={setReadOnly}
                readOnly={readOnly}/>


            {(!readOnly && isTransponderAllowed) &&
            <AnimalOperationAllowed animal={animal} operation={AnimalOperations.EDIT}>
                <div>
                    <Row>
                        <Col className={"text-end"}>
                            {!create &&
                            <Button variant="secondary" className={"cancel"} onClick={() => setReadOnly(true)}>
                                {t("cancel")}
                            </Button>
                            }
                            <Button variant="primary" className={"submit"} onClick={onSubmit}>
                                {create ? t("create_form") : t("save_form")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </AnimalOperationAllowed>
            }
        </Form>

    </div>


}

export default FormComponent;
