import React, {useCallback, useRef, useState} from "react";
import {useClickedOutsideEffect} from "system/Window/HandleClickOutside";
import DateTextComponent from "layout/modules/Forms/DatePicker/components/DateTextComponent";
import CalendarComponent from "layout/modules/Forms/DatePicker/components/CalendarComponent";

const DatePickerComponent = (props) => {

    const ref = useRef(null);
    const [open, setOpen] = useState(false);

    //Close calendar on click outisde
    useClickedOutsideEffect(ref, useCallback(() => {
            if (open) {
                setOpen(false)
            }
        }, [setOpen, open])
    );


    return (
        <div className="date-picker" ref={ref}>
            <DateTextComponent
                {...props}
                open={open}
                setOpen={setOpen}
            />
            <CalendarComponent
                {...props}
                open={open}
                setOpen={setOpen}
            />
        </div>
    );

}


export default DatePickerComponent;
