import React, {useCallback} from "react";
import AsyncSelect from 'react-select/async';
import {getValuesArrayFromObjectsArray} from "layout/modules/Forms/Dropdowns/utils/utils";
import useSelectHandlers from "modules/Forms/Dropdowns/ClassDropdown/utils/useSelectHandlers";

const GenericRemoteDropdownComponent = React.forwardRef((props, ref) => {

    const {
        classValue, onChange, isMulti, isControlled, setClickedOptionsCache,
        defaultValue, currentSelectedOptions, isClearable = true,
    } = props;

    const {handleOptionLabel, handleOptionValue} = useSelectHandlers(props);

    const handleChange = useCallback(
        (options) => {

            if (isControlled) {
                setClickedOptionsCache(options);
            }

            if (onChange)
                onChange(getValuesArrayFromObjectsArray(options, isMulti, classValue), options);
        },
        [classValue, isMulti, onChange, isControlled, setClickedOptionsCache]
    );

    //Prevent mount if option is not loaded in defaultValue mode
    if (defaultValue && currentSelectedOptions === undefined) {
        return <div ref={ref}></div>;
    }

    return <div ref={ref}>
        <AsyncSelect
            {...props}
            classNamePrefix="rs"
            className="rs"
            getOptionValue={handleOptionValue}
            getOptionLabel={handleOptionLabel}
            onChange={handleChange}
            value={isControlled ? currentSelectedOptions : undefined}
            defaultValue={isControlled ? undefined : currentSelectedOptions }
            cacheOptions={true}
            isClearable={isClearable}
        />
    </div>;
});

export default GenericRemoteDropdownComponent;
