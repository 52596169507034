import React, {useState} from "react";
import {useTranslation} from "react-i18next"
import Container from "react-bootstrap/Container"
import ListFilterComponent from "app/control/modules/transfers/components/list/ListFilterComponent";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {getOrDefault} from "system/Objects/ObjectParameters";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import DateTime from "system/DateTime/DateTime";
import getUserTypeLabel from "app/control/modules/transfers/utils/getUserTypeLabel";
import BulkOptionsComponent from "app/control/modules/transfers/components/list/BulkOptionsComponent";
import "app/control/modules/transfers/assets/css/list.scss";
import ShowAdminLabels from "app/adminLabels/ShowAdminLabels";
import ObjectType from "app/adminLabels/utils/ObjectType";
import {isBoolean} from "system/Utils/isBoolean";
import TransferType from "app/control/modules/transfers/utils/TransferType";

const ListComponent = (props) => {

    const {t} = useTranslation('transfer');

    const {
        data,
        searchFilter,
        onSearch,
        onPageChange,
        onPageSort,
        onFilterSubmit,
        loading,
        onAccept,
        accepting,
        onReject,
        reload,
        transponder,
        isClosed
    } = props;

    const [selectedItems, setSelectedItems] = useState([]);

    return <Container fluid className={"animal-transfer-control-list"}>

        <Box>
            <TableHeader>
                <SearchArea value={searchFilter.universalSearch} onSearch={onSearch}>
                    <SearchFilter onSubmit={(data) => onFilterSubmit(data)}>
                        <ListFilterComponent t={t} searchFilter={searchFilter} isClosed={isClosed}/>
                    </SearchFilter>
                </SearchArea>
            </TableHeader>

            <SearchDataPagination data={data} onPageChange={onPageChange}>
                <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER"]}>
                    <BulkOptionsComponent
                        t={t}
                        data={data}
                        onAccept={onAccept}
                        onReject={onReject}
                        reload={reload}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        reasonsURL={"/animal-transfers/reasons"}
                    />
                </UserHasPermission>

            </SearchDataPagination>
            <TableAdvanced
                hover
                sort={data?.sortBy}
                order={data?.sortOrder?.toLowerCase()}
                onSortClick={onPageSort}
                loading={(loading || !data || accepting)}>
                <thead>
                <tr>

                    <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER"]}>
                        <th className={"table-checkbox"}/>
                    </UserHasPermission>

                    {!transponder &&
                        <th>
                            {t('transponder')}
                        </th>
                    }

                    <th className={"d-none d-lg-table-cell"}>
                        {t('created_by')}
                    </th>

                    <th id={"creationTimestamp"} sorted className={"d-none d-lg-table-cell"}>
                        {t('origin_and_date')}
                    </th>

                    <th id={"lastUpdateTimestamp"}>
                        {t('last_update_date')}
                    </th>

                    <th className={"d-none d-md-table-cell"}>
                        {t('status')}
                    </th>

                    <th/>

                </tr>
                </thead>
                <tbody>

                {data?.content?.map((transfer, index) => {
                    return <tr key={index}>

                        <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER"]}>
                            <td className={"table-checkbox"}>
                                <CheckBox
                                    value={getOrDefault(selectedItems.find(e => e.id === transfer.id), false)}
                                    disabled={transfer.closed || transfer.origin === TransferType.OWNERS}
                                    onChange={(value) => setSelectedItems((items) => {
                                        if (value) {
                                            return [...selectedItems, transfer];
                                        } else {
                                            return [...items].filter(item => item.id !== transfer.id)
                                        }
                                    })}
                                />
                            </td>
                        </UserHasPermission>

                        {!transponder &&
                            <td>
                                {transfer.transponder}
                            </td>
                        }

                        <td className={"d-none d-lg-table-cell"}>
                            {transfer.creationUser?.name ?? transfer?.publicRequest?.requesterName}
                            <div className={"entity-name"}>
                                {transfer?.creationEntity?.name}
                            </div>

                            <div className={"user-type"}>
                                {t(getUserTypeLabel(transfer))}
                            </div>

                        </td>

                        <td className={"d-none d-lg-table-cell fill-empty td-sorted"}>
                            {t(transfer.origin)}

                            <div>
                                <DateTime format="DD-MM-YYYY | HH:mm:ss">
                                    {transfer.creationTimestamp}
                                </DateTime>
                            </div>

                        </td>

                        <td>
                            <DateTime format="DD-MM-YYYY | HH:mm:ss">
                                {transfer.lastUpdateTimestamp ?? transfer.creationTimestamp}
                            </DateTime>
                        </td>


                        <td className={"d-none d-md-table-cell"}>
                            {transfer.closed &&
                                <div className={"validation-status"}>
                                    {isBoolean(transfer.accepted)
                                        ? <>{t(transfer.accepted)}</>
                                        : <>{t('isCanceled')}</>
                                    }
                                </div>
                            }

                            {!transfer.closed &&
                                <div className={"validation-status"}>
                                    {t(transfer.validationStatus ? transfer.validationStatus : "WAITING_VALIDATION")}
                                </div>
                            }

                            {(transfer?.labels && transfer.labels.length > 0) &&
                                <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER", "MANAGE_LABELS"]}>
                                    <div className={"labels-list"}>
                                        <ShowAdminLabels objectType={ObjectType.ANIMAL_TRANSFER} objectId={transfer?.id}
                                                         labels={transfer?.labels}/>
                                    </div>
                                </UserHasPermission>
                            }

                        </td>


                        <td className="table-buttons">
                            <div className="buttons">
                                <TableIconButton
                                    path={"/transfers/" + transfer.id}
                                    icon={faSearch}
                                    className={"table-btn btn-rounded-primary small"}
                                />

                            </div>
                        </td>


                    </tr>
                })}
                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
                <div className={"no-table-results"}>
                    {t('no_results')}
                </div>
            }
        </Box>
    </Container>

}

export default ListComponent;
