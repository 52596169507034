import React, {useCallback, useEffect, useState} from "react";
import useTranslations from "system/Translations/UseTranslations";
import Form from "react-bootstrap/Form";
import locale from "layout/modules/Forms/HTMLEditor/locales/locales";
import classnames from "classnames";
import 'react-quill/dist/quill.snow.css';
import "layout/modules/Forms/HTMLEditor/assets/htmlEditor.scss";
import lazyWithRetry from "system/System/lazyWithRetry";

const ReactQuill = lazyWithRetry(() => import("react-quill"));

const HTMLEditor = (props) => {

    const {id, defaultValue, value, onChange, disabled, ...otherProps} = props;

    const isControlled = (value !== undefined)
    const [html, setHTML] = useState(isControlled ? value : defaultValue);
    const [inSourceMode, setInSourceMode] = useState(false);

    const {t} = useTranslations("htmlEditor", locale);

    //Adjust value on value change
    useEffect(() => {
        if (isControlled)
            setHTML(value);
    }, [isControlled, value, setHTML])


    //Prevent source mode in disabled mode
    useEffect(() => {
        if (disabled && inSourceMode)
            setInSourceMode(false);
    }, [disabled, inSourceMode]);

    const handleChange = useCallback(
        (e) => {

            const value = e?.target?.value ?? e;

            setHTML(value);
            if (onChange && !disabled)
                onChange(value)
        },
        [setHTML, onChange, disabled]
    );

    return <div className={classnames("html-editor-area", {"read-only": disabled})}>

        {(!inSourceMode || disabled) &&
        <React.Suspense fallback={<div></div>}>
            <ReactQuill
                {...otherProps}
                theme={"snow"}
                readOnly={disabled}
                value={html}
                onChange={handleChange}
            />
        </React.Suspense>
        }

        <Form.Control
            id={id}
            name={id}
            as="textarea"
            className={"source-ta"}
            value={html}
            height={"300px"}
            style={{display: inSourceMode ? 'block' : 'none'}}
            onChange={handleChange}/>

        {!disabled &&
        <div className={"switch-to-html"} onClick={() => setInSourceMode(!inSourceMode)}>
            {inSourceMode ? t('switch_back') : t('switch_to_code')}
        </div>
        }

    </div>

}

export default HTMLEditor;
