export const en = {

    cancel: "Não",
    continue: "Sim",

    writeConfirmationLabel: "Por favor escreva \"{{label}}\" ",

};

export default en;
