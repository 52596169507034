export const pt = {

    name: "Nome",
    email: "Email",
    entity: "Entidade",


    include_sub_entities: "Incluir sub-entidades",
    yes: "Sim",
    no: "Não",

    //FORM
    name_ph: "Indique o nome completo do utilizador",
    save_changes: "Guardar alterações",
    cancel: "Cancelar",
    language: "Idioma",
    language_ph: "Selecione o idioma",
    language_no: "Idioma não encontrado",

    timezone: "Fuso horário",
    timezone_ph: "Selecione o fuso horário",
    timezone_no: "Fuso horário não encontrado!",

    receive_notifications: "Receber notificações",
    files: "Documentos",

    //ERRORS
    MISSING_NAME: "Por favor preencha com o nome completo",
    INVALID_NAME: "Nome inválido. Por favor preencha com o nome completo",

    INVALID_LANGUAGE: "Por favor escolha um idioma",
    MISSING_LANGUAGE: "Por favor escolha um idioma",

    MISSING_TIMEZONE: "Por favor escolha um fuso horário",
    INVALID_TIMEZONE: "Fuso horário inválido. Por favor escolha um fuso horário",

    MISSING_RECEIVE_NOTIFICATIONS: "Por favor escolha uma opção",

    //List Success Creation and Update
    UPDATE_SUCCESS: "Utilizador atualizado",
    UPDATE_SUCCESS_MSG: "Os seus dados foram atualizados com sucesso!",

    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    //Own User
    personal_title: "Informação pessoal",

    settings_form: "Definições",
    password_form: "Alterar password",
    entities: "Entidades",
    audit: "Audit Log",

    new_password_confirm: "Confirmação da nova password",
    new_password_confirm_tip: "Repita a password inserida acima.",

    password: "Password atual",
    password_tip: "Insira a password atual",

    new_password: "Nova password",
    new_password_tip: "Insira a nova password",


    set_new_password: "Definir password",

    invalid_password: "A password deverá ter pelo menos:",
    //Password Recovery tips
    uppercase: "1 letra maiúscula",
    lowercase: "1 letra minúscula",
    digits: "1 número",
    symbols: "1 caracter não alfanumérico",
    min: "6 caracteres",
    max: "25 caracteres",

    invalid_confirm_password: "A password de confirmação deverá ser igual à nova password",


    USER_PASSWORD_MISMATCH: "A password atual não está válida. Por favor verifique se a password introduzida é a password do utilizador.",
    MISSING_OLD_PASSWORD: "A password atual está em branco. Por favor insira a password atual",

    FORM_PASSWORD_ERROR: "Erro a mudar a password",
    FORM_PASSWORD_ERROR_MSG: "Por favor verifique os campos abaixo e tente novamente",

    SUCCESS_PASSWORD: "Password alterada",
    SUCCESS_PASSWORD_MSG: "A sua password foi alterada com sucesso. No próximo login deverá utilizar a nova password!",

    access_request: "Pedir acesso a entidade",

    select_impersonate: "Escolha um utilizador para entrar",

    SUCCESS_IMPERSONATE: "Utilizador alterado",
    SUCCESS_IMPERSONATE_MSG: "Está neste momento como utilizador {{name}}. Caso deseje voltar ao seu utilizador deverá realizar logout e login novamente ou utilizar a opção \"Sair do modo impersonate\" no menu pessoal.",

    SUCCESS_EXIT_IMPERSONATE_MSG: "Saiu do modo impersonate e está novamente com o seu utilizador original",

    ERROR_IMPERSONATE: "Erro",
    ERROR_IMPERSONATE_MSG: "Não foi possível alterar o utilizador. Por favor tente mais tarde.",

};

export default pt;
