import FormComponent from "app/control/modules/transfers/components/create/FormComponent";
import {Col, Row} from "react-bootstrap";
import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import FilePreviewComponent from "app/control/components/files/FilePreviewComponent";
import localeOwners from "app/owners/locales/locales";
import localeAnimals from "app/animals/locales/locales";
import RemoteOwnerComponent from "app/control/modules/transfers/components/show/request/RemoteOwnerComponent";
import RequestComponent from "app/control/modules/transfers/components/show/request/RequestComponent";
import FormContainer from "app/animals/containers/form/FormContainer";
import useTranslations from "system/Translations/UseTranslations";
import Loading from "layout/modules/Loading/Loading";
import {AnimalStateProvider} from "app/animals/utils/AnimalContext";
import Alerts from "app/animals/components/view/Alerts";
import ButtonsComponent from "app/control/components/show/ButtonsComponent";
import SetStateModalComponent from "app/control/components/show/SetStateModalComponent";
import ClosedMessageInfoComponent from "app/control/components/show/ClosedMessageInfoComponent";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import AddNewDocumentComponent from "app/control/components/show/AddNewDocumentComponent";
import MoreInformationInfoMessageComponent from "app/control/components/show/MoreInformationInfoMessageComponent";
import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import NextAndPreviousComponent from "app/control/components/show/NextAndPreviousComponent";
import Audit from "app/audit/Audit";
import {TransferActions} from "app/audit/utils/AuditActions";
import {AnimalObjectTypes} from "app/audit/utils/AuditObjectTypes";
import ManagementInfoMessages
    from "app/control/modules/transfers/components/show/manage/messages/ManagementInfoMessages";
import ValidationStatus from "types/ValidationStatus";

import "app/control/modules/transfers/assets/css/show-transfer.scss";
import Observations from "app/observations/Observations";
import FilePreviewMobileComponent from "app/control/components/files/FilePreviewMobileComponent";
import {useIsMobile} from "system/Window/Mobile";
import TransferType from "app/control/modules/transfers/utils/TransferType";
import LostCloseByTransferAlertComponent from "app/animals/components/lost/other/LostCloseByTransferAlertComponent";

const ShowComponent = (props) => {

    const {
        request,
        loading,
        onChangeState,
        animal,
        remoteNewOwner,
        remoteOldOwner,
        uploading,
        errorsUploading,
        showOwners,
        onAddFiles,
        embedded,
        reload,
    } = props;
    const {t} = useTranslation('transfer');

    const [isSetStateVisible, setShowSetState] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const isMobile = useIsMobile();
    const hasFiles = useMemo(() => Array.isArray(request?.documents), [request?.documents]);

    const isAnOwnerRequest = request.origin === TransferType.OWNERS;

    useTranslations('owner', localeOwners);
    useTranslations('animal', localeAnimals);

    const Buttons = () => <UserHasPermission permission="MANAGE_ANIMAL_TRANSFER">
        <ButtonsComponent
            t={t}
            request={request}
            onChangeState={onChangeState}
            setShowSetState={setShowSetState}
            hideAccept={isAnOwnerRequest}
            hideMoreInformation={isAnOwnerRequest}
        />
    </UserHasPermission>;


    return <div className={"show-transfer view-mode"}>
        <Loading visible={loading}/>

        {!embedded &&
            <>
                <NextAndPreviousComponent t={t} path={"/transfers/"} document={request}/>

                {showUpload &&
                    <AddNewDocumentComponent
                        t={t}
                        onClose={() => setShowUpload(false)}
                        loading={uploading}
                        errors={errorsUploading}
                        onSubmit={onAddFiles}
                    />
                }

                <MoreInformationInfoMessageComponent t={t} request={request}/>
                <ClosedMessageInfoComponent t={t} request={request}/>

                <LostCloseByTransferAlertComponent
                    lostAnimalId={request.lostAnimalId}
                    lostAnimalDeliveryStatus={request.lostAnimalDeliveryStatus}/>

                <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER"]}>
                    <ManagementInfoMessages
                        t={t}
                        request={request}
                        remoteNewOwner={remoteNewOwner}
                    />

                    {isSetStateVisible &&
                        <SetStateModalComponent
                            t={t}
                            setShow={setShowSetState}
                            onHide={() => setShowSetState(false)}
                            state={isSetStateVisible}
                            onChangeState={onChangeState}
                            reasonsUrl={isSetStateVisible === ValidationStatus.MORE_INFORMATION_NEEDED
                                ? "/animal-transfers/more-info-reasons"
                                : "/animal-transfers/reasons"
                            }
                        />
                    }

                    <Buttons/>

                </UserHasPermission>
            </>
        }

        <Row>

            <Col lg={(isAnOwnerRequest || !hasFiles) ? 12 : 6} md={12} sm={12}>

                {!embedded &&
                    <div className={classnames("buttons", {"hidden": isAnOwnerRequest})}>
                        {!request.closed &&
                            <Button variant="primary" onClick={() => setShowUpload(true)}>
                                {t('add_new_document')}
                            </Button>
                        }

                        <div className={"round-buttons"}>
                            <UserHasPermission permission={["READ_OBSERVATION"]}>
                                <Observations btnRegular={true}/>
                            </UserHasPermission>


                            <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                                <Audit
                                    lg={true}
                                    objectId={request?.id}
                                    filterActions={TransferActions}
                                    objectType={[AnimalObjectTypes.AnimalTransfer]}
                                />
                            </UserHasPermission>
                        </div>
                    </div>
                }

                <Tabs defaultActiveKey="data" id={"tab-transfer-" + request.id + "-" + request.closed}
                      unmountOnExit={false} mountOnEnter={true}>

                    <Tab eventKey="data" title={t('request_data')} className={'no-cols'}>
                        <RequestComponent request={request}/>
                    </Tab>

                    <Tab eventKey="animal" title={t('animal_data')} className={'no-cols'}>
                        <AnimalStateProvider animal={animal}>
                            <Alerts {...props} hideTransfer={true} fullWidth={true}/>
                            <FormContainer {...props} viewMode={true} compareMode={true} showLink={true}
                                           showObservations={true}/>
                        </AnimalStateProvider>
                    </Tab>

                    {(showOwners && remoteOldOwner) &&
                        <Tab eventKey="remoteOldOwner" title={t('remote_old_owner')} className={'no-cols'}>
                            <div>
                                <RemoteOwnerComponent owner={remoteOldOwner}/>
                            </div>
                        </Tab>
                    }

                    {(showOwners && remoteNewOwner) &&
                        <Tab eventKey="remoteNewOwner" title={t('remote_new_owner')} className={'no-cols'}>
                            <div>
                                <RemoteOwnerComponent owner={remoteNewOwner}/>
                            </div>
                        </Tab>
                    }

                    {!isAnOwnerRequest &&
                        <Tab eventKey="newOwner" title={t('new_owner')} className={'no-cols'}>
                            <div>
                                <FormComponent animalOwner={request} readOnly={true}/>
                            </div>
                        </Tab>
                    }

                </Tabs>
            </Col>

            {(!isAnOwnerRequest && hasFiles) &&
                <>
                    {!isMobile
                        ? <FilePreviewComponent request={request} t={t} reload={reload}/>
                        : <FilePreviewMobileComponent request={request} t={t}  reload={reload}/>
                    }
                </>
            }

        </Row>

        {!embedded &&
            <>
                <UserHasPermission permission={["MANAGE_ANIMAL_TRANSFER"]}>
                    <Buttons/>
                </UserHasPermission>

                <NextAndPreviousComponent t={t} path={"/transfers/"} document={request}/>
            </>
        }

    </div>


}


export default ShowComponent;
