import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import React, {useCallback, useState} from "react";
import {readFileContent} from "system/Files/ReadFileContent";
import PhotoCrop from "layout/modules/Forms/PhotoCrop/PhotoCrop";
import {acceptTypeImages} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";

const PhotoComponent = props => {

    const {t} = useTranslation("animal");
    const {readOnly, lostInfo, setLostInfo} = props;

    const [originalPhoto, setOriginalPhoto] = useState(lostInfo?.photo);

    const handleFileUpload = useCallback(
        (nf) => {
            nf.forEach((file) => {
                readFileContent(file, (content) => {
                    setLostInfo(lostInfo => ({
                        ...lostInfo,
                        photo: btoa(content)
                    }));

                    setOriginalPhoto(btoa(content));
                });
            });
        },
        [setLostInfo]
    );

    const handlePhotoRemove = useCallback(
        () => {
            setLostInfo(lostInfo => ({
                ...lostInfo,
                photo: null,
                additionalPhotos: null,
            }));
            setOriginalPhoto(null);
        },
        [setLostInfo, setOriginalPhoto]
    );

    const handlePhotoChange = useCallback(
        (photo) => {
            setLostInfo(lostInfo => ({
                ...lostInfo,
                photo: photo,
            }));
        },
        [setLostInfo]
    )

    return <>
        {lostInfo.photo &&
        <Box className={"lost-animal-photo"}>
            <BoxTitle>{t('lost_main_animal_photo')}</BoxTitle>
            <BoxOptions>
                {!readOnly &&
                <div className={classnames("btn-rounded-danger small")}
                     onClick={handlePhotoRemove}>
                    <FontAwesomeIcon icon={faTrash}/>
                </div>
                }

            </BoxOptions>

            <div className={"image"}>
                {readOnly
                    ? <img src={`data:image/jpeg;base64,${lostInfo.photo}`} alt={"animal"}/>
                    : <PhotoCrop photo={originalPhoto} onChange={handlePhotoChange}/>
                }
            </div>
        </Box>
        }

        {!readOnly && !lostInfo.photo &&
        <Box className={"upload-box"}>
            <DragDropFile
                acceptTypes={{
                    ...acceptTypeImages,
                }}
                text={t('upload_lost_file_label')}
                maxFiles={1}
                onFileUploaded={handleFileUpload}
            />
        </Box>
        }
    </>

}

export default PhotoComponent;
