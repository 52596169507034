import {useCallback} from "react";
import {useAnimalState} from "./AnimalContext";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {useApiState} from "system/API/APIStateContext";
import {UserType} from "types/Users";
import {OwnerType} from "types/Onwer";

const useAnimalFieldsAllowed = (isReadOnly, isCreation, errors = {}) => {

    //SET_LOST //SET_FOUND //SET_DELIVERED //EDIT
    const userHasPermission = useUserHasPermission();
    const {authentication} = useApiState();
    const {animal: {isPotentiallyDanger = false, owner = {}} = {}} = useAnimalState();

    return useCallback(
        (field) => {

            if (isReadOnly)
                return true;

            if (isCreation) {
                return checkCreationField(field, userHasPermission, authentication.type, owner);
            } else {
                return checkEditionField(field, userHasPermission, errors, isPotentiallyDanger, owner);
            }
        },
        [isReadOnly, isCreation, errors, authentication.type, userHasPermission, isPotentiallyDanger, owner]
    )
}

const checkCreationField = (field, userHasPermission, userType, owner) => {

    switch (field) {

        case 'owner.name':
        case 'owner.vatNumber':
        case 'owner.idCard':
        case 'owner.type':
        case 'owner.nationality':
        case 'owner.country':
        case 'owner.passport':
            return !(owner?.verifiedData && !userHasPermission({permission: "WRITE_ANIMAL_PROTECTED_FIELDS"}));

        case 'owner.email':
            return !(owner.type === OwnerType.COLLECTIVE &&
                owner?.verifiedData && !userHasPermission({permission: "WRITE_ANIMAL_PROTECTED_FIELDS"}));

        case 'orderNumber':
        case 'registrationDate':
            return userHasPermission({permission: "WRITE_ANIMAL_PROTECTED_FIELDS"});
        case 'veterinaryId':
        case 'entityId':
            return (UserType.NORMAL === userType);
        default:
            return true;

    }
}


const checkEditionField = (field, userHasPermission, errors, isPotentiallyDanger, owner) => {

    switch (field) {

        case 'orderNumber':
        case 'registrationDate':
        case 'transponder':
        case 'veterinaryId':
        case 'entityId':
            return userHasPermission({
                permission: "WRITE_ANIMAL_PROTECTED_FIELDS"
            });
        case 'birthdate':
            return (userHasPermission({permission: "WRITE_ANIMAL_BIRTHDATE"}) ||
                ((errors[field] !== undefined) && userHasPermission({
                    permission: "REGISTER_ANIMAL",
                    orUserTypes: UserType.VETERINARY
                })));
        case 'owner':
        case 'owner.type':
        case 'owner.vatNumber':
        case 'owner.idCard':
        case 'owner.passport':
        case 'owner.nationality':
        case 'owner.country':
            if (owner?.verifiedData && !userHasPermission({permission: "WRITE_OWNER_VALIDATED_ID_FIELDS"})) {
                return false;
            }

            return (userHasPermission({permission: "WRITE_ANIMAL_PROTECTED_FIELDS"}) ||
                ((errors[field] !== undefined || errors['owner'] !== undefined) && userHasPermission({
                    any: true,
                    permission: ["WRITE_OWNER_ID_FIELDS_ON_ERROR", "REGISTER_ANIMAL"],
                    orUserTypes: UserType.VETERINARY
                })));

        case 'name': //Animal name
            return isAllowedOrIncompleteOrVeterinary('WRITE_ANIMAL_NAME', errors[field], userHasPermission);
        //Animal Addreess
        case 'owner.email':
            if (owner.type === OwnerType.COLLECTIVE &&
                owner?.verifiedData && !userHasPermission({permission: "WRITE_OWNER_VALIDATED_ID_FIELDS"})) {
                return false;
            }

            return userHasPermission({
                permission: "WRITE_ANIMAL_CONTACTS",
                orUserTypes: UserType.VETERINARY
            });

        case 'parishId':
        case 'address':
        case 'locality':
        case 'postalCode':
        case 'useOwnerAddress':
        case 'owner.phone2':
        case 'owner.phone3':
            return userHasPermission({
                permission: "WRITE_ANIMAL_CONTACTS",
                orUserTypes: UserType.VETERINARY
            });

        case 'owner.address':
        case 'owner.postalCode':
        case 'owner.parishId':
        case 'owner.locality':
        case 'owner.phone':
        case 'owner.responsible':
            return isAllowedOrIncompleteOrVeterinary('WRITE_ANIMAL_CONTACTS', errors[field], userHasPermission);

        case 'owner.name':
            if (owner?.verifiedData && !userHasPermission({permission: "WRITE_ANIMAL_PROTECTED_FIELDS"}))
                return false;

            return userHasPermission({
                permission: "WRITE_ANIMAL_CHARS_GROUP_1",
                orUserTypes: UserType.VETERINARY
            });

        case 'gender':
        case 'color':
            return isAllowedOrIncompleteOrVeterinary('WRITE_ANIMAL_CHARS_GROUP_1', errors[field], userHasPermission);
        case 'observations':
        case 'lopNumber':
        case 'lopName':
        case 'healthReport':
            return userHasPermission({
                permission: "WRITE_ANIMAL_CHARS_GROUP_1",
                orUserTypes: UserType.VETERINARY
            });

        case 'crossBreedId':
        case 'isCrossBreed':
        case 'breedId':
            return allowOnlyWithPermissionAndCondition(isPotentiallyDanger, 'WRITE_ANIMAL_CHARS_GROUP_2', errors[field], userHasPermission);
        case 'breedGroup':
            return allowOnlyWithPermissionAndCondition(!errors['breedId'], 'WRITE_ANIMAL_CHARS_GROUP_2', errors['breedId'], userHasPermission);
        case 'passport':
            return userHasPermission({
                permission: "WRITE_ANIMAL_PASSPORT"
            });
        default:
            return false;

    }
}

const allowOnlyWithPermissionAndCondition = (condition, permission, error, userHasPermission) => {
    if (condition) {
        return (userHasPermission({permission: "WRITE_ANIMAL_PROTECTED_FIELDS"}));
    } else {
        return isAllowedOrIncompleteOrVeterinary(permission, error, userHasPermission);
    }
}

const isAllowedOrIncompleteOrVeterinary = (permission, error, userHasPermission) => {

    if (userHasPermission({permission: permission, orUserTypes: UserType.VETERINARY}))
        return true;
    else
        return (error !== undefined && userHasPermission({permission: "REGISTER_ANIMAL"}))

}

const AnimalFieldIsAllowed = (props) => {

    const {field, isReadOnly, isCreation, errors} = props;
    const isFieldAllowed = useAnimalFieldsAllowed(isReadOnly, isCreation, errors);

    if (isFieldAllowed(field)) {
        return props.children;
    }

    return <></>;

}

export default AnimalFieldIsAllowed;
export {useAnimalFieldsAllowed};
