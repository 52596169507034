import FormComponent from "app/control/modules/preRegister/components/form/FormComponent";
import useGenericFormSubmit from "modules/Forms/Utils/useGenericFormSubmit";
import {useBillingBalanceState} from "modules/Billing/BillingStateContext";
import useErrorParser from "system/API/Error/ErrorParser";
import {useCallback} from "react";

const CreateContainer = props => {

    const billingState = useBillingBalanceState();

    const {onSubmit, loading: submitting, error: errorSubmitting} = useGenericFormSubmit({
        apiBasePath: "/animals/pre-register/",
        redirectBasePath: useCallback((d) => "/pre-registers/" + d?.id, []),
        translation: 'preRegister',
        onSuccess: billingState.reload
    });

    const errors = useErrorParser(errorSubmitting);

    return <FormComponent
        {...props}
        create={true}
        loading={submitting}
        errors={errors}
        onSubmit={onSubmit}
    />
}

export default CreateContainer;
