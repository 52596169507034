import locale from "../locales/locales";
import {useMutate} from "system/Rest/Rest";
import {useCallback} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import PopupFormComponent from "../components/PopupFormComponent";
import interventionResolver from "../utils/InterventionResolver";
import getInterventionPath from "../utils/getInterventionPath";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useTranslations from "system/Translations/UseTranslations";
import useErrorParser from "system/API/Error/ErrorParser";
import useGenericFormValidator, {VALIDATE_NOT_NULL} from "modules/Forms/Utils/useGenericFormValidator";
import {VALIDATE_REASON} from "app/interventions/utils/reasons";

const FormContainer = (props) => {

    const {transponder, reload, reloadBase, onClose, create, intervention} = props;

    const {t} = useTranslations("health-intervention", locale);
    const showToast = useShowToast();

    const {mutate: submitGenericIntervention, loading, error: errorSubmitting} = useMutate({
        verb: create ? "POST" : "PUT",
        path: getInterventionPath
    });

    const errors = useErrorParser(errorSubmitting);

    const {errors: preErrors, validate} = useGenericFormValidator({
        description: VALIDATE_REASON,
        typeId: VALIDATE_NOT_NULL,
        executionDate: VALIDATE_NOT_NULL,
    });

    return <PopupFormComponent
        {...props}
        intervention={create ? {} : interventionResolver(intervention)}
        errors={preErrors ? preErrors : errors}
        loading={loading}
        onSubmit={useCallback(
            intervention => {
                if (!validate(intervention)) {
                    showToast({
                        title: t('ERROR_ADD_INTERVENTION'),
                        message: t('ERROR_ADD_INTERVENTION_MSG'),
                        icon: faTimes,
                        className: 'error',
                    });

                    return;
                }

                submitGenericIntervention({
                    ...intervention,
                    transponder: transponder,
                }, {
                    pathParams: {
                        intervention: intervention,
                        isCreate: create,
                    }
                }).then(() => {
                    reload();

                    if (reloadBase)
                        reloadBase();

                    onClose()
                    if (create)
                        showToast({
                            title: t('SUCCESS_ADD_INTERVENTION'),
                            message: t('SUCCESS_ADD_INTERVENTION_MSG', {transponder: transponder}),
                            icon: faCheck,
                        });
                    else
                        showToast({
                            title: t('SUCCESS_EDIT_INTERVENTION'),
                            message: t('SUCCESS_EDIT_INTERVENTION_MSG', {transponder: transponder}),
                            icon: faCheck,
                        });

                }).catch((e) => {
                    showToast({
                        title: t('ERROR_ADD_INTERVENTION'),
                        message: t(e.data?.message ? e.data?.message : 'ERROR_ADD_INTERVENTION_MSG'),
                        icon: faTimes,
                        className: 'error',
                    });
                });

            },
            [t, reload, reloadBase, onClose, showToast, transponder, submitGenericIntervention, create, validate]
        )}

    />

}

export default FormContainer;
