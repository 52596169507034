export const pt = {

    audit: "Audit log",
    title: "Audit log",

    date: "Data",
    action: "Operação",
    object_type: "Tipo",
    affected_id: "Documento",
    impersonator_user: "Utilizador real",
    user: "Utilizador",
    user_type: "Tipo de utilizador",
    cancel: "Fechar",
    to_date: "Data final",
    from_date: "Data inicial",
    date_ph: "Data no formato (dd-mm-yyyy)",
    ip: "Origem (IP)",

    //filter
    select_ph: "Selecione uma opção",
    no_results: "Sem resultados para apresentar",

    SIAC: "App SIAC",
    OWNERS: "App titulares",
    placeholder: "Selecione uma opção",
    owner: "Titular do animal",

    //export
    download_menu: "Opções para exportação",
    CSV: "Ficheiro CSV",
    XLSX: "Documento Excel (xlsx)",
    PDF: "Documento PDF",
    export_to: "Descarregar {{type}}",

    //View
    view_operation: "Detalhe operação",
    operation_detail: "Dados da operação",
    operation_details: "Detalhes",
    document_information: "Detalhe do documento",
    raw_objects: "Dados em bruto",
    operations_related: "Operações relacionadas",
    changes: "Alterações",
    object: "Documento",

    //Certificate
    certificate: "Dados do documento emitido",
    certificate_template: "Template do documento",
    send_by_email: "Envio por email",
    email_destination: "Email de destino",
    validation_code: "Código de validação",

    //Veterinary Requests
    request_status: "Estado após pedido",
    PENDING_ENTITY_RESPONSE: "Pendente aceitação da entidade",
    PENDING_VETERINARY_RESPONSE: "Pendente aceitação do veterinário",
    REJECTED_BY_VET: "Rejeitado pelo veterinário",
    ACCEPTED_BY_VET: "Aceite pelo veterinário",
    REJECTED_BY_ENTITY: "Rejeitado pela entidade",
    ACCEPTED_BY_ENTITY: "Aceite pela entidade",

    //transaction
    operation_item: "Item",
    operation: "Operação",
    operation_type: "Tipo",

    DEBIT: "Débito",
    CREDIT: "Crédito",
    value: "Valor",
    entity: "Entidade",
    veterinary: "M. Veterinário",
    veterinary_ballot: "Cédula M. Veterinário",

    //Object Types
    Aggression: "Agressão",
    Animal: "Animal",
    HerdingDog: "Cão de proteção de gado",
    AnimalLicense: "Licenciamento",
    AnimalDeathRequest: "Pedido falecimento",
    AnimalTransfer: "Trans. titularidade",
    AnimalMerge: "Fusão de animal",
    AnimalPreRegister: "Pré-registo",
    LostAnimal: "Animal perdido",
    HealthIntervention: "Interv. sanitária",
    RabiesProphylaxis: "Vac. antirrábica",
    Sterilization: "Esterilização",
    User: "Utilizador",
    UserRole: "Perf. utilizador",
    Entity: "Entidade",
    EntityProfile: "Perf. entidade",
    EntityVeterinaryAssociation: "Assoc. vet entidade",
    BillingTransaction: "Transação",
    PriceTable: "Tab. preços",
    Breed: "Raça",
    Certificate: "Documentos emitidos",
    Template: "Modelo doc. emitido",
    Configuration: "Configuração",
    HelpTopic: "Ajuda",
    Observation: "Observação",
    Owner: "Titular",
    OwnerMergeReport: "Fusão de titular",
    OwnerMerge: "Fusão de titular",
    OwnerDataUpdate: "Pedido dados titular",
    OwnerUser: "Titular (Utilizador)",
    Veterinary: "M. Veterinário",
    RabiesVaccine: "Gestão vacinas",
    Report: "Relatório",
    ReportSchedule: "Relatório agendado",
    ReportTemplate: "Modelo de relatório",
    Operation: "Operação",
    OperationSchedule: "Opração agendada",
    OperationTemplate: "Modelo de operação",
    PendingLostAnimal: "Pedido de perdido",
    LabelValue: "Etiqueta",
    Document: "Documento",

    //Actions
    AGGRESSION_CREATED: "Criar agressão",
    AGGRESSION_EDITED: "Editar agressão",
    AGGRESSION_CLEAR_CREATED: "Limpar agressões",
    AGGRESSION_CLEAR_EDITED: "Apagar limpar agressões",
    AGGRESSION_DELETED: "Apagar agressão",
    ANIMAL_READ: "Ler animal",
    ANIMAL_CREATED: "Registar animal",
    ANIMAL_EDITED: "Editar animal",
    ANIMAL_DEAD: "Registar Morte",
    ANIMAL_UNDEAD: "Reverter Morte",
    ANIMAL_REGISTRY: "Registo de Animal",
    ANIMAL_LOCKED: "Bloquear animal",
    ANIMAL_LICENSE_CREATED: "Criar licença",
    ANIMAL_LICENSE_EDITED: "Editar licença",
    ANIMAL_LICENSE_DELETED: "Apagar licença",
    ANIMAL_SET_PHOTO: "Alterar Foto",
    ANIMAL_TRANSFERED: "Trans. titular",
    ANIMAL_TRANSFER_RESPOND: "Resp. a pedido de trans.",
    ANIMAL_TRANSFER_CANCELED: "Cancelar pedido de trans.",
    ANIMAL_MERGED: "Fusão de animal",
    ANIMAL_TRANSFER_VALIDATED: "Validar trans. titular",
    ANIMAL_TRANSFER_VALIDATED_BULK: "Validar trans. titular (Bulk)",
    ANIMAL_LOST_CREATED: "Dar perdido",
    ANIMAL_LOST_EDITED: "Editar perdido",
    ANIMAL_LOST_CLOSED: "Fechar perdido",
    ANIMAL_PRE_REGISTER_VALIDATED: "Validação pré-registo",
    ANIMAL_PRE_REGISTER_EDITED: "Edição pré-registo",
    ANIMAL_PRE_REGISTERED: "Criação de pré-registo",
    ANIMAL_LOST_VALIDATED: "Validação pedido de perdido",
    ANIMAL_LOST_STATUS_UPDATED: "Atualização estado de perdido",
    ANIMAL_DEATH_REQUEST_VALIDATED: "Validação falecimento",
    LOGIN: "Login",
    LOGIN_2FA: "Login 2FA",
    LOGOUT: "Logout",
    IMPERSONATE_USER: "Impersonate",
    SELF_USER_UPDATE: "Editar dados pessoais",
    SELF_PASSWORD_CHANGE: "Alterar password",
    ACTIVATION_CODE_VALIDATED: "Ativar conta ",
    USER_SELECT_ENTITY: "Entrar com entidade",
    SYSTEM_ROLE_CREATED: "Criar permissão",
    SYSTEM_ROLE_EDITED: "Editar permissão",
    SYSTEM_ROLE_DELETED: "Apagar permissão",
    USER_READ: "Ler utilizador",
    USER_CREATED: "Criar utilizador",
    USER_EDITED: "Editar utilizador",
    USER_DELETED: "Apagar utilizador",
    ENTITY_CREATED: "Criar entidade",
    ENTITY_EDITED: "Editar entidade",
    ENTITY_DELETED: "Apagar entidade",
    OWN_ENTITY_EDITED: "Editar a propria entidade",
    VETERINARY_ASSOCIATION: "Associar m. veterinário",
    TRANSACTION_CREATED: "Criar transação",
    PRICE_TABLE_CREATED: "Criar tabela de preços",
    PRICE_TABLE_EDITED: "Editar tabela de preços",
    PRICE_TABLE_DELETED: "Apagar tabela de preços",
    BREED_CREATED: "Criar raça",
    BREED_EDITED: "Editar raça",
    BREED_DELETED: "Apagar raça",
    CERTIFICATE_TEMPLATE_CREATED: "Criar modelo de documento",
    CERTIFICATION_TEMPLATE_EDITED: "Editar modelo de documento",
    CERTIFICATION_TEMPLATE_DELETED: "Apagar modelo de documento",
    CERTIFICATION_GENERATED: "Emitir documento",
    CERTIFICATION_VALIDATED: "Validar documento",
    BULK_CERTIFICATION_REQUEST: "Emitir vários documentos",
    CONFIGURATION_EDITED: "Editar configuração",
    HEALTH_INTERVENTION_CREATED: "Registar interv. sanitária",
    RABIES_INTERVENTION_CREATED: "Registar vacinação antirrábica",
    STERILIZATION_CREATED: "Registar esterilização",
    HEALTH_INTERVENTION_EDITED: "Editar interv. sanitária",
    RABIES_INTERVENTION_EDITED: "Editar vacinação antirrábica",
    STERILIZATION_EDITED: "Editar esterilização",
    HEALTH_INTERVENTION_DELETED: "Apagar interv. sanitária",
    RABIES_VACCINE_CREATED: "Criar vacina",
    RABIES_VACCINE_EDITED: "Editar vacina",
    RABIES_VACCINE_DELETED: "Apagar vacina",
    HELP_TOPIC_DELETED: "Apagar ajuda",
    HELP_TOPIC_EDITED: "Editar ajuda",
    OBSERVATION_CREATED: "Criar observação",
    OBSERVATION_DELETED: "Apagar observação",
    OWNER_READ: "Ler titular",
    OWNER_CREATED: "Criar titular",
    OWNER_EDITED: "Editar titular",
    OWNER_LOCKED: "Bloquear titular",
    OWNER_DELETED: "Apagar titular",
    OWNER_READ_BY_VAT: "Consulta por NIF",
    OWNER_TRAINING_EDITED: "Editar treino titular",
    OWNER_TRAINING_DELETED: "Apagar treino titular",
    OWNER_MERGE_REVERTED: "Reverter fusão titular",
    OWNER_MERGE_VALIDATED: "Validar fusão titular",
    OWNER_DATA_VALIDATED: "Validar atual. titular",
    OWNER_USER_VERIFIED: "Validar dados (titular)",
    OWNER_USER_DATA_UPDATED: "Atualizar dados (titular)",
    OWNER_USER_REGISTERED: "Registo de titular (titular)",
    VETERINARY_EDITED: "Editar m. veterinário",
    VETERINARY_REQUESTED_ENTITY_ASSOCIATION: "Pedido associação",
    VETERINARY_RESPONDED_ENTITY_ASSOCIATION: "Resposta pedido de associação",
    VETERINARY_REMOVED_ENTITY_ASSOCIATION: "Remoção de associação",
    HERDING_DOG_VALIDATED: "Validar cão de proteção de gado",
    ANIMAL_DIAC_REQUEST_VALIDATED: "Validação pedido DIAC ",

    ENTITY_PROFILE_CREATED: "Criar perf. entidade",
    ENTITY_PROFILE_EDITED: "Editar perf. entidade",
    ENTITY_PROFILE_DELETED: "Apagar perf. entidade",

    REPORT_GENERATED: "Gerar relatório",
    REPORT_TEMPLATE_CREATED: "Criar template de relatório",
    REPORT_TEMPLATE_EDITED: "Editar template de relatório",
    REPORT_SCHEDULE_CREATED: "Criar agendamento de relatório",
    REPORT_SCHEDULE_EDITED: "Editar agendamento de relatório",
    REPORT_TEMPLATE_DELETED: "Apagar template de relatório",
    REPORT_SCHEDULE_DELETED: "Apagar agendamento de relatório",

    OPERATION_TEMPLATE_CREATED: "Criar template de operação",
    OPERATION_TEMPLATE_EDITED: "Editar template de operação",
    OPERATION_TEMPLATE_DELETED: "Apagar template de operação",
    OPERATION_SCHEDULE_CREATED: "Criar agendamento de operação",
    OPERATION_SCHEDULE_EDITED: "Editar agendamento de operação",
    OPERATION_SCHEDULE_DELETED: "Apagar agendamento de operação",
    OPERATION_RUN: "Executar operação",

    LABEL_ADDED: "Adicionou Etiqueta",

    //documents
    DOCUMENT_DELETED: "Apagar documento",



    error_platform: "Neste momento não é possível obter os registos de operações."

};

export default pt;
