import React, {useCallback, useState} from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import {canChooseLocalVat} from "app/owners/utils/form/useOwnerIdModel";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrDefault, getOrEmpty} from "system/Objects/ObjectParameters";
import appConfig from "configs/config";
import useClearUnusedFields from "app/owners/utils/form/clearUnusedFields";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Help from "app/help/Help";
import {cleanVatNumber} from "app/owners/utils/form/vatCountries";


const IDNumberComponent = (props) => {

    const {t} = useTranslation('owner');
    const {owner, setOwner, errors, readOnly, changes, lockFields = [], model} = props;

    const [isHelpVisible, setHelpVisible] = useState(false);

    //Clear unused fields
    useClearUnusedFields(readOnly, owner, model, setOwner);

    const handleHelpClick = useCallback(
        () => setHelpVisible(true),
        [setHelpVisible]
    );

    const handleVatChange = useCallback(e => setOwner(owner => ({
        ...owner,
        vatNumber: cleanVatNumber(owner, e.target.value),
        verifiedData: false,
        editLocked: false,
        isLocked: false,
    })), [setOwner])

    const handlePassportChange = useCallback(e => setOwner(owner => ({
        ...owner,
        passport: e.target.value,
    })), [setOwner]);

    const handleIdCardChange = useCallback(e => setOwner(owner => ({
        ...owner,
        idCard: e.target.value,
    })), [setOwner]);


    return <>
        <Help
            isVisible={isHelpVisible}
            onClose={() => setHelpVisible(false)}
            forceArticle={model.vatHelpId}
        />


        {canChooseLocalVat(owner) &&
            <Col sm={12} lg={6}>
                <FormGroup error={errors['hasLocalVatNumber']} changes={changes['hasLocalVatNumber']}>
                    <Form.Label>{t('has_local_vat', {country: t(appConfig.defaultCountry)})}</Form.Label>
                    <BooleanDropdown
                        id={"hasLocalVatNumber"}
                        isDisabled={readOnly || lockFields['vatNumber']}
                        value={parseBoolean(owner.hasLocalVatNumber, false)}
                        onChange={(val => setOwner(owner => ({
                            ...owner,
                            hasLocalVatNumber: val,
                        })))}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['hasLocalVatNumber'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        }

        {model.vat &&
            <Col sm={12} lg={6}>
                <FormGroup error={errors['vatNumber']} changes={changes['vatNumber']}>
                    <Form.Label>
                        {model.vatLabel}

                        {!readOnly &&
                            <span className={"help-block"} onClick={handleHelpClick}>
                                <span className={"help-label"}>
                                <span className={"help-label-text"}>{t('vat_examples_help')}</span>
                                <FontAwesomeIcon
                                    className={"owner-id-help-icon"}
                                    icon={faQuestionCircle}

                                />
                                    </span>
                            </span>
                        }

                    </Form.Label>
                    <FormControl
                        type="text"
                        placeholder={getOrDefault(model.vatPlaceHolder, t('vat_ph'))}
                        disabled={readOnly || lockFields['vatNumber']}
                        value={getOrEmpty(owner?.vatNumber)}
                        onChange={handleVatChange}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['vatNumber'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        }

        {model.passport &&
            <Col sm={12} lg={6}>
                <FormGroup error={errors['passport']} changes={changes['passport']}>
                    <Form.Label>{t('passport')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('passport_ph')}
                        disabled={readOnly || lockFields['passport']}
                        value={getOrEmpty(owner?.passport)}
                        onChange={handlePassportChange}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['passport'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        }


        {model.cc &&
            <Col sm={12} lg={6}>
                <FormGroup error={errors['idCard']} changes={changes['idCard']}>
                    <Form.Label>{t('document_number_complete')}</Form.Label>
                    <FormControl
                        type="text"
                        disabled={readOnly || lockFields['idCard']}
                        placeholder={t('id_card_ph')}
                        value={getOrEmpty(owner?.idCard)}
                        onChange={handleIdCardChange}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['idCard'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        }
    </>
}

export default IDNumberComponent;
