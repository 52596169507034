import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import VeterinaryLinkComponent from "app/veterinaries/components/link/VeterinaryLinkComponent";
import VeterinaryDropdown, {
    getVeterinaryValueOrEmpty
} from "modules/Forms/Dropdowns/ClassDropdown/components/VeterinaryDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import React from "react";
import {UserType} from "types/Users";
import {useApiState} from "system/API/APIStateContext";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";

const VeterinaryFormComponent = props => {

    const {authentication} = useApiState();
    const {errors, intervention, setIntervention, readOnly, compare, t} = props;

    if (readOnly && !compare) {
        return <LabelWithValue label={t('veterinary')}>
            <VeterinaryLinkComponent
                veterinary={intervention?.veterinary}
                label={getVeterinaryValueOrEmpty(intervention?.veterinary)}
            />
        </LabelWithValue>
    }

    if (UserType.NORMAL !== authentication.type)
        return <></>;

    return <Col sm={12} lg={12}>
        <FormGroup error={errors['veterinaryId']} className={"mandatory"}>
            <Form.Label>{t('veterinary')}</Form.Label>
            <VeterinaryDropdown
                value={getOrNull(intervention?.veterinary?.id)}
                defaultSelectedOption={compare ? undefined : intervention?.veterinary}
                isDisabled={readOnly}
                onChange={(veterinaryId) => setIntervention(intervention => ({
                    ...intervention,
                    veterinaryId: veterinaryId,
                }))}
            />
            <Form.Text className="sub-error">
                {t(errors['veterinaryId'])}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default VeterinaryFormComponent;
