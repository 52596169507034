import {Modal} from "react-bootstrap";
import {Trans} from "react-i18next";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import Button from "react-bootstrap/Button";
import React from "react";

const GenericPopupMessageComponent = props => {

    const {popupContent, setPopupContent, title, confirm} = props;

    return <Modal
        show={!!popupContent}
        size={"lg"}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
    >

        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Trans>
                <div dangerouslySetInnerHTML={{__html: popupContent}}/>
            </Trans>
        </Modal.Body>

        <ModalButtons>
            <Button variant="primary" onClick={() => setPopupContent(false)}>
                {confirm}
            </Button>

        </ModalButtons>

    </Modal>

}

export default GenericPopupMessageComponent;
