import {useCallback, useState} from "react"


const EmailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const pv = require('password-validator');
const passwordValidator = new pv();

passwordValidator
    .is().min(6)                                    // Minimum length 8
    .is().max(25)                                  // Maximum length 100
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits()                                // Must have at least 2 digits
    .has().symbols();                               // Special Character


const areEqualAndValid = (password, repetition) => (password === repetition && passwordValidator.validate(password));

const usePasswordWithRepetitionValidator = () => {

    //Set passwords
    const [state, setState] = useState({
        errors: {
            password: false,
            repetition: false,
        },
        areValid: false,
        password: "",
        repetition: "",
    });

    const setPassword = useCallback((password, repetition) => {
        setState(state => {
            return ({
                errors: {
                    ...state.errors,
                    list: passwordValidator.validate(password, {list: true}),
                    password: password ? !passwordValidator.validate(password) : false,
                    repetition: repetition ? !areEqualAndValid(repetition,password) : false
                },
                password: password,
                repetition: repetition,
                areValid: areEqualAndValid(password,repetition)
            });
        });
    }, [setState]);

    return [state, setPassword];
}

export {EmailValidator};

export default usePasswordWithRepetitionValidator;
