import {useCallback} from "react";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {OwnerType} from "types/Onwer";

const OwnerOperations = {
    EDIT: "EDIT",
};

const useOwnerOperationAllowed = (owner) => {

    const userHasPermission = useUserHasPermission();

    return useCallback(
        (operation) => {

            switch (operation) {
                case OwnerOperations.EDIT:
                    if (owner.isLocked)
                        return false;
                    else if (owner?.type === OwnerType.TRACES && !userHasPermission({permission: "REGISTER_OWNER_TRACES"}))
                        return false;
                    return userHasPermission({permission: "WRITE_OWNER"});
                default:
                    return false;
            }
        },
        [userHasPermission, owner?.isLocked, owner?.type]
    )
}


const OwnerOperationAllowed = (props) => {

    const {owner, operation} = props;
    const isOperationAllowed = useOwnerOperationAllowed(owner);

    if (isOperationAllowed(operation)) {
        return props.children;
    }

    return <></>;

}

export {
    OwnerOperationAllowed,
    OwnerOperations
}
