import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import FormGroup from "layout/modules/Forms/Group/FormGroup";

const InternationalFormComponent = (props) => {

    const {t, value, errors, readOnly, onChange, changes} = props;

    return <Row>
        <Col md={12}>
            <FormGroup error={errors['address']} changes={changes['address']} mandatory={true}>
                <Form.Label>{t('address')}</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    name={"address"}
                    value={getOrEmpty(value?.address)}
                    disabled={readOnly}
                    onChange={useCallback(
                        (e) => onChange({
                            address: e.target.value,
                        }),
                        [onChange]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['address'])}
                </Form.Text>
            </FormGroup>
        </Col>
    </Row>
}

export default InternationalFormComponent;
