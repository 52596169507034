const fileDownload = (base64data, filename, fileType = 'application/octet-stream') => {

    try {
        const byteCharacters = atob(base64data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const finalFileType = navigator.userAgent.match('CriOS') ? fileType : 'application/octet-stream';
        const blob = new Blob([byteArray], {type: finalFileType });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const blobURL = (window.URL && window.URL.createObjectURL)
                ? window.URL.createObjectURL(blob)
                : window.webkitURL.createObjectURL(blob);

            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filename);

            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();

            // Fixes "webkit blob resource error 1"
            setTimeout(function () {
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(blobURL);
            }, 200)
        }
    }
    catch (e) {
        throw Error("Error downloading file " + filename + " of type " + fileType + "with content " + base64data);
    }
}

export default fileDownload;
