import React, {useMemo} from "react";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";

const ReportTemplateDropdown = props => {

    const {async, category, isOperation} = props;

    return <UserHasPermission permission={["GENERATE_REPORT","RUN_OPERATION"]} any={true}>
        <GenericRemoteClassDropdown
            {...props}
            remotePath={isOperation ? "/operations/template" : "/reports/template"}
            searchFilter={useMemo(() => ({
                sortProperty: 'name',
                sortDirection: 'ASC',
                currentPage: 0,
                itemsPerPage: 50,
                category: category,
                async: parseBoolean(async, undefined)
            }), [async, category])}
        />
    </UserHasPermission>

}

export default ReportTemplateDropdown;
