import useHistoryState from "system/Routing/useHistoryState";
import {useMemo, useState} from "react";

const useFilterState = (id, queryParameters, disableFilterCache) => {

    const [historySearchFilter, setHistorySearchFilter] = useHistoryState(id, queryParameters);
    const [searchFilter, setSearchFilter] = useState(queryParameters);


    return useMemo(
        () => [
            disableFilterCache ? searchFilter : historySearchFilter,
            disableFilterCache ? setSearchFilter : setHistorySearchFilter,
        ],
        [disableFilterCache, historySearchFilter, setHistorySearchFilter, searchFilter, setSearchFilter]
    );


}

export default useFilterState;
