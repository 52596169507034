import React from "react";
import GeneralDataComponent from "app/animals/components/lost/create/form/GeneralDataComponent";
import ParticipantDataComponent from "app/animals/components/lost/create/form/ParticipantDataComponent";
import ObservationComponent from "app/animals/components/lost/create/form/ObservationComponent";
import AllowLegalPermissions from "app/animals/components/lost/create/form/AllowLegalPermissions";
import PhotoComponent from "app/animals/components/lost/create/form/PhotoComponent";
import OriginComponent from "app/animals/components/lost/create/form/OriginComponent";
import ClosedComponent from "app/animals/components/lost/create/form/ClosedComponent";
import AdditionalAnimalPhotosComponent from "app/animals/components/lost/create/form/AdditionalAnimalPhotosComponent";

const FormComponent = (props) => {

    const {lostInfo, setLostInfo, errors, readOnly, create, setReadOnly, notRegistered} = props;

    return <>

        <GeneralDataComponent
            create={create}
            readOnly={readOnly}
            setReadOnly={setReadOnly}
            errors={errors}
            lostInfo={lostInfo}
            setLostInfo={setLostInfo}
            notRegistered={notRegistered}/>

        <PhotoComponent
            errors={errors}
            readOnly={readOnly}
            lostInfo={lostInfo}
            setLostInfo={setLostInfo}
        />

        {lostInfo?.photo &&
        <AdditionalAnimalPhotosComponent
            errors={errors}
            readOnly={readOnly}
            lostInfo={lostInfo}
            setLostInfo={setLostInfo}
        />
        }

        <ParticipantDataComponent
            errors={errors}
            readOnly={readOnly}
            lostInfo={lostInfo}
            setLostInfo={setLostInfo}
        />

        {(lostInfo?.observations || !readOnly) &&
        <ObservationComponent
            errors={errors}
            readOnly={readOnly}
            lostInfo={lostInfo}
            setLostInfo={setLostInfo}
        />
        }

        <OriginComponent
            lostInfo={lostInfo}
            readOnly={readOnly}
        />


        {lostInfo?.isClosed &&
        <ClosedComponent
            lostInfo={lostInfo}
        />
        }


        <AllowLegalPermissions
            errors={errors}
            lostInfo={lostInfo}
            readOnly={readOnly}
            setLostInfo={setLostInfo}
        />

    </>
}

export default FormComponent;
