export const pt = {

    title: "Utilizadores",
    list: "Utilizadores",
    view: "Visualizar / Editar",
    create: "Novo",
    general: "Dados gerais",

    ballotNumber: "Cédula",
    name: "Nome",
    email: "Email",
    entity: "Entidade",

    lastLogin: "Últ. login",

    status : {
        label: "Estado",
        ACTIVE: "Ativo",
        INACTIVE: "Inativo",
    },

    VETERINARY: "M. veterinário",
    NORMAL: "Utilizador entidade",
    ANY: "Todos",

    type: "Tipo",
    type_ph: "Selecione um tipo",
    include_sub_entities: "Incluir sub-entidades",
    yes: "Sim",
    no: "Não",

    no_users_found: "Não foi encontrado nenhum utilizador",

    //FORM
    name_ph: "Indique o nome completo do utilizador",
    save_changes: "Guardar alterações",
    cancel: "Cancelar",
    language: "Idioma",
    language_ph: "Selecione o idioma",
    language_no: "Idioma não encontrado",

    email_ph: "Indique o email do utilizador",

    role: "Perfil",

    timezone: "Fuso horário",
    timezone_ph: "Selecione o fuso horário",
    timezone_no: "Fuso horário não encontrado!",

    receive_notifications: "Receber notificações",

    //Create
    create_title: "Criar utilizador",
    create_user: "Criar utilizador",

    //ERRORS
    MISSING_NAME: "Por favor preencha com o nome completo",
    INVALID_NAME: "Nome inválido. Por favor preencha com o nome completo",
    MISSING_ENTITY: "Por favor selecione uma entidade",
    MISSING_ROLE: "Por favor selecione um perfil",
    MISSING_EMAIL: "Por favor preencha o email",
    INVALID_EMAIL: "Por favor preencha com um email válido",
    DUPLICATE_EMAIL: "Já existe uma conta registada com este email.",

    INVALID_LANGUAGE: "Por favor escolha um idioma",
    MISSING_LANGUAGE: "Por favor escolha um idioma",

    INVALID_STATUS: "Por favor defina um estado válido",
    MISSING_STATUS: "Por favor escolha um estado",

    MISSING_TIMEZONE: "Por favor escolha um fuso horário",
    INVALID_TIMEZONE: "Fuso horário inválido. Por favor escolha um fuso horário",

    MISSING_RECEIVE_NOTIFICATIONS: "Por favor escolha uma opção",

    //List Success Creation and Update
    UPDATE_SUCCESS: "Utilizador atualizado",
    UPDATE_SUCCESS_MSG: "O utilizador {{name}} foi atualizado com sucesso!",

    //List Success Creation and Update
    CREATE_SUCCESS: "Novo utilizador criado",
    CREATE_SUCCESS_MSG: "O utilizador {{name}} foi criado com sucesso!",

    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    DELETE_SUCCESS: "Utlizador apagado",
    DELETE_SUCCESS_MSG: "O utilizador \"{{name}}\" foi apagado com sucesso!",

    DELETE_FAILED: "Erro ao apagar o utilizador",

    DELETE_USER_CONFIRM: "Confirmação",
    DELETE_USER_CONFIRM_MSG: "Deseja mesmo apagar o utilizador \"{{name}}\"?",

    //Own User
    personal_title: "Informação pessoal",

    settings_form: "Definições",
    password_form: "Alterar password",

    new_password_confirm: "Confirmação da nova password",
    new_password_confirm_tip: "Repita a password inserida acima.",

    password: "Password atual",
    password_tip: "Insira a password atual",

    new_password: "Nova password",
    new_password_tip: "Insira a nova password",


    set_new_password: "Definir password",

    invalid_password: "A password deverá ter pelo menos:",
    //Password Recovery tips
    uppercase: "1 letra maiúscula",
    lowercase: "1 letra minúscula",
    digits: "1 número",
    symbols: "1 caracter não alfanumérico",
    min: "6 caracteres",
    max: "25 caracteres",

    invalid_confirm_password: "A password de confirmação deverá ser igual à nova password",


    USER_PASSWORD_MISMATCH: "A password atual não está válida. Por favor verifique se a password introduzida é a password do utilizador.",
    MISSING_OLD_PASSWORD: "A password atual está em branco. Por favor insira a password atual",

    FORM_PASSWORD_ERROR: "Erro a mudar a password",
    FORM_PASSWORD_ERROR_MSG: "Por favor verifique os campos abaixo e tente novamente",

    SUCCESS_PASSWORD: "Password alterada",
    SUCCESS_PASSWORD_MSG: "A sua password foi alterada com sucesso. No próximo login deverá utilizar a nova password!",

    files: "Documentos"

};

export default pt;
