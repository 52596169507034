import {useGet} from "system/Rest/Rest";
import VeterinaryEntitiesComponent from "../components/VeterinaryEntitiesComponent";
import {useMemo} from "react";

const VeterinaryEntitiesContainer = (props) => {

    const {data: entities, loading: loadingEntities, refetch: refetchEntities} = useGet({
        path: "/auth/search-entities",
        queryParams: useMemo(() => ({
            sortProperty: 'status',
            sortDirection: 'DESC',
            itemsPerPage: 100, //To Fix in future
        }),[])
    });

    return <VeterinaryEntitiesComponent
        entities={entities}
        loadingEntities={loadingEntities}
        onReFetchEntities={refetchEntities} />

}

export default VeterinaryEntitiesContainer;
