import appConfig from "configs/config";

const animalLostResolver = (transponder, data) => {
    if (!data) {
        return {
            transponder: transponder,
            eventCountry: appConfig.defaultCountry,
        }
    }
    else {
        return {
            ...data,
            breedId: data?.breed?.id,
            crossBreedId: data.crossBreed?.id,
            eventDistrict: data.eventDistrict?.id,
            eventDistrictName: data.eventDistrict?.name,
            eventCounty: data.eventCounty?.id,
            eventCountyName: data.eventCounty?.name,
            eventParish: data.eventParish?.id,
            eventParishName: data.eventParish?.name,
            isCrossBreed: !!data.crossBreed?.id,
        }
    }
}


export default animalLostResolver;
