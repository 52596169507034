import React, {useCallback, useEffect, useState} from "react";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import SetPhotoComponent from "app/animals/components/generic/SetPhotoComponent";

const PhotoComponent = React.forwardRef((props, ref) => {

    const {setAnimal, animal: {photo}} = useAnimalState();
    const {setReadOnly} = props;
    const [isPopupVisible, setPopupVisible] = useState(false);

    const setAnimalPhoto = useCallback(
        (content) => {
            setAnimal(animal => ({
                ...animal,
                photo: content,
            }));
            setPopupVisible(false);
        },
        [setAnimal, setPopupVisible]
    );

    useEffect(() => {
        if (ref) {
            ref.current = {
                showPhotoManagement: () => {
                    if (setReadOnly)
                        setReadOnly(false);

                    setPopupVisible(true);
                },
            }
        }
    }, [ref, setReadOnly, setPopupVisible]);


    return <>

        {isPopupVisible &&
        <SetPhotoComponent
            photo={photo}
            onChange={setAnimalPhoto}
            onClose={() => setPopupVisible(false)}
        />
        }
    </>
});

export default PhotoComponent;
