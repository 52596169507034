import {useGet} from "system/Rest/Rest";
import {useCallback, useEffect} from "react";
import fileDownload from "app/files/utils/download";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useTranslation} from "react-i18next";

const useAuditExporter = (searchFilter) => {

    const showToast = useShowToast();
    const {t} = useTranslation('audit');

    const {data, loading, refetch} = useGet({
        verb: "GET",
        path: "/audit/search",
        lazy: true,
        debounce: 300,
    });

    useEffect(
        () => {
            if (data && data.exportBytes) {
                fileDownload(data.exportBytes, "audit-export." + data.exportFormat.toLowerCase());
            }

        },
        [data]
    )

    const handleDownload = useCallback(
        (format) => {
            refetch({
                queryParams: {
                    ...searchFilter,
                    exportFormat: format,
                }
            }).catch(error => handleUnknownFormError(error, showToast, t))

        },
        [refetch, searchFilter, showToast, t]
    );


    return {
        downloading: loading,
        onDownload: handleDownload
    }

}

export default useAuditExporter;
