export const en = {

    //CONFIRM
    CHANGES_NOT_SUBMITTED: "Save Changes",
    CHANGES_NOT_SUBMITTED_CANCEL: "No. Discard Changes",
    CHANGES_NOT_SUBMITTED_CONFIRM: "Yes. Save Changes",
    CHANGES_NOT_SUBMITTED_MSG: "Do you really want to save the changes performed in the owner document?",
    CHANGES_NOT_SUBMITTED_POPUP: 'You have unsaved changes, are you sure you want to leave?',


    CHANGES_NOT_SAVED: "Save changes",
    CHANGES_NOT_SAVED_MSG: "Some changes are not saved, do you want to exit this page?",
    NO: "No",
    YES: "Yes",

};

export default en;
