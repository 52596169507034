import VeterinaryEntitiesComponent from "./components/veterinaries/VeterinaryEntitiesComponent";
import React, {useEffect, useMemo} from "react";
import {useGet, useMutate} from "system/Rest/Rest";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import locale from "./locales/locales";
import LegalWarningContainer from "./containers/LegalWarningContainer";
import {useApiState} from "system/API/APIStateContext";
import useTranslations from "system/Translations/UseTranslations";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {removeVarFromState, useVarFromState} from "system/Routing/useHistoryState";
import createPersistedState from "system/System/PersistedState/PersistenState";
import {Navigate, useNavigate} from "react-router";
import {isAuthenticationExpired} from "app/login/utils/sessionUtils";

const useEntityState = createPersistedState('veterinaryEntity');

const VeterinaryEntities = (props) => {

    const {authentication, setAuthentication} = useApiState();
    const [selectedEntity, setSelectedEntity] = useEntityState(null);

    const {t} = useTranslations('login', locale);
    const showToast = useShowToast();

    const forceEntitySelection = useVarFromState('forceEntity');
    const navigate = useNavigate();

    const {mutate: login, loading: submitting} = useMutate({
        verb: "PUT",
        path: "/auth/select-entity"
    });

    const {data, loading, refetch, error: errorSearchEntities} = useGet({
        path: "/auth/search-entities",
        queryParams: useMemo(() => ({
            sortProperty: 'status',
            sortDirection: 'DESC',
            itemsPerPage: 100, //To Fix in future
        }),[])
    });

    //Filter if last selected entity is not on list
    useEffect(
        () => {
            if (Array.isArray(data?.content) && selectedEntity) {
                if (!data.content.some(assoc => assoc.entity?.id === selectedEntity && assoc.status === "ACCEPTED"))
                    setSelectedEntity(null);
            }
        },
        [setSelectedEntity, selectedEntity, data]
    );


    //Logout entity if entity exists
    useEffect(
        () => {
            if (authentication?.entity?.id && forceEntitySelection) {
                setAuthentication(a => ({
                    ...a,
                    entity: undefined
                }))
            }
        },
        [authentication?.entity?.id, forceEntitySelection, setAuthentication]
    );

    //Prevent errors on loading entites
    useEffect(
        () => {
            if (errorSearchEntities?.status === 401 || isAuthenticationExpired(authentication?.session?.validity)) {
                setAuthentication({});
                navigate("/");
            }
        },
        [errorSearchEntities?.status,setAuthentication, authentication?.session?.validity,navigate]
    );


    if (!authentication?.legalWarningReadTimestamp) {
        return <LegalWarningContainer {...props}/>
    }

    if (authentication?.isAuthenticated && !forceEntitySelection) {
        return <Navigate to='/'/>
    }

    return <VeterinaryEntitiesComponent
        {...props}
        selectedEntity={selectedEntity}
        onSelectEntity={setSelectedEntity}
        loading={submitting}
        loadingEntities={loading}
        onReFetchEntities={refetch}
        entities={data}
        onLogin={(entityId) => {
            login({
                entityId: entityId
            }).then((response) => {
                removeVarFromState('forceEntity');
                setAuthentication({
                    ...authentication,
                    isAuthenticated: true,
                    isPreAuthenticated: undefined,
                    entity: response.entity,
                    entityProfile: response.entity?.profile,
                    entitiesAccessList: response.entitiesAccessList,
                    role: response.role,
                    session: {
                        token: response.sessionToken,
                        validity: response.sessionValidity,
                    },
                });
            }).catch(() => {
                showToast({
                    title: t('ERROR_LOGIN_ENTITY'),
                    message: t('ERROR_LOGIN_ENTITY_MSG'),
                    icon: faTimes,
                    className: 'error',
                });
            });
        }}

        onLoginWithoutEntity={() => {
            removeVarFromState('forceEntity');
            setAuthentication(authentication => ({
                ...authentication,
                isAuthenticated: true,
                isPreAuthenticated: undefined,
                entity: null,
                entitiesAccessList: [],
                session: {
                    ...authentication.session,
                    ...authentication.veterinarySession
                },
                role: {
                    permissions: [],
                }
            }))
        }}

    />

}

export default VeterinaryEntities;
