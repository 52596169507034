import {WithContext as ReactTags} from 'react-tag-input';
import React, {useCallback, useMemo} from "react";
import getAsArray from "system/Arrays/getArray";
import "./TagInput.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

const TagInput = props => {

    const {value, onChange, disabled, placeholder = "", type} = props;

    const tags = useMemo(
        () => !value ? [] : getAsArray(value).map(e => ({id: String(e), text: String(e)})),
        [value]
    );

    return <div className={classnames("tag-input form-control", {"tag-read-only": disabled})}>
        <ReactTags
            readOnly={disabled}
            allowDragDrop={false}
            placeholder={placeholder}
            allowUnique={false}
            autofocus={false}
            classNames={{
                tags: 'tag-component-block',
                tagInput: 'tag-input-control-area',
                tagInputField: 'tag-input-control',
                selected: 'tag-area',
                tag: 'tag-item',
                remove: 'remove-tag',
                editTagInput: 'editTagInputClass',
                editTagInputField: 'editTagInputField',
                clearAll: 'clearAllClass',
            }}
            tags={tags}
            handleDelete={useCallback(
                (i) => {
                    onChange(value.filter((tag, index) => index !== i))
                },
                [value, onChange]
            )}
            handleAddition={useCallback(
                (tag) => {
                    const valueArray = getAsArray(value);
                    if (type === "number") {
                        if (Number.isInteger(parseInt(tag?.id))) {
                            onChange([...valueArray, parseInt(tag?.id)]);
                        }
                    }
                    else {
                        onChange([...valueArray, tag?.id]);
                    }
                },
                [value, onChange, type]
            )}
        />
        <i>
            <FontAwesomeIcon
                icon={faXmark}
                onClick={useCallback(
                    (i) => {
                        onChange([]);
                    },
                    [onChange]
                )}
                style={{visibility: tags?.length > 0 ? 'visible' : 'hidden'}}
            />
        </i>
    </div>

}

export default TagInput;
