import {AuditObjectTypes} from "./AuditObjectTypes";
import {useEffect, useState} from "react";
import {useGetRemoteEntities} from "modules/GenericRemoteClassFetcher/GenericSubClassFetcher";

const useLogDocument = (data) => {

    const [finalData, setFinalData] = useState([]);
    const [objectIds, setObjectIds] = useState({});

    const [owners, loadingOwners] = useGetRemoteEntities("/owners/search", objectIds.owners, "READ_OWNER");
    const [users, loadingUsers] = useGetRemoteEntities("/users/search", objectIds.users, "READ_USER");
    const [roles, loadingRoles] = useGetRemoteEntities("/roles/search", objectIds.roles, "READ_ROLE");
    const [vaccines, loadingVaccines] = useGetRemoteEntities("/rabies-vaccines/search", objectIds.vaccines, "MANAGE_VACCINES");
    const [entityProfiles, loadingEntityProfiles] = useGetRemoteEntities("/entity-profiles/search", objectIds.entityProfiles);
    const [breeds, loadingBreeds] = useGetRemoteEntities("/breeds/search-breeds", objectIds.breeds);
    const [entities, loadingEntities] = useGetRemoteEntities("/entities/search", objectIds.entities, "READ_ENTITY");
    const [veterinaries, loadingVeterinaries] = useGetRemoteEntities("/veterinaries/search", objectIds.veterinaries, "READ_VETERINARY");
    const [reports, loadingReports] = useGetRemoteEntities("/reports/template", objectIds.reports, "GENERATE_REPORT");
    const [operations, loadingOperations] = useGetRemoteEntities("/operations/template", objectIds.operations, "RUN_OPERATION");

    //Iterate data and set remote objects
    useEffect(
        () => setFinalData(
            data?.content?.map((value) => getRemoteObject(value, {
                owners: owners,
                users: users,
                roles: roles,
                vaccines: vaccines,
                entityProfiles: entityProfiles,
                entities: entities,
                breeds: breeds,
                veterinaries: veterinaries,
                reports: reports,
                operations: operations,
            }))
        ),
        [owners, users, roles, vaccines, entityProfiles, breeds, entities, veterinaries, reports, operations, data?.content]
    )

    //Get Objects IDs and fetch objects from server
    useEffect(
        () => {
            const objectIds = {
                owners: [],
                roles: [],
                users: [],
                vaccines: [],
                entityProfiles: [],
                breeds: [],
                entities: [],
                veterinaries: [],

                animals: [],
                veterinaryAssociation: [],
                billingTransactions: [],
                priceTables: [],

                certificates: [],
                templates: [],
                configurations: [],
                helpTopics: [],
                observations: [],

                reports: [],
                operations: [],

                unknown: []
            };

            if (!data)
                return;

            // eslint-disable-next-line
            data?.content?.forEach((value) => { getEntryId(value, objectIds) });
            setObjectIds(objectIds);
        },
        [data, setObjectIds]
    );


    return [finalData,
        loadingUsers || loadingOwners || loadingRoles || loadingVaccines ||
        loadingEntityProfiles || loadingBreeds || loadingEntities ||
        loadingVeterinaries || loadingReports || loadingOperations

    ];

}

// Filter remote objects from log entry
const getRemoteObject = (value, objects) => {

    const objectType = value.affectedObjectType ? value.affectedObjectType : value.objectType;
    const objectId = value.affectedObjectType ? value.affectedObjectId : value.objectId;

    switch (objectType) {
        case AuditObjectTypes.Owner:
            return addKeyFromRemoteObject(value, objects.owners, 'owner', objectId);
        case AuditObjectTypes.User:
            return addKeyFromRemoteObject(value, objects.users, 'user', objectId);
        case AuditObjectTypes.UserRole:
            return addKeyFromRemoteObject(value, objects.roles, 'role', objectId);
        case AuditObjectTypes.RabiesVaccine:
            return addKeyFromRemoteObject(value, objects.vaccines, 'vaccine', objectId);
        case AuditObjectTypes.EntityProfile:
            return addKeyFromRemoteObject(value, objects.entityProfiles, 'entityProfile', objectId);
        case AuditObjectTypes.Breed:
            return addKeyFromRemoteObject(value, objects.breeds, 'breed', objectId);
        case AuditObjectTypes.Entity:
            return addKeyFromRemoteObject(value, objects.entities, 'entity', objectId);
        case AuditObjectTypes.Veterinary:
            return addKeyFromRemoteObject(value, objects.veterinaries, 'veterinary', objectId);
        case AuditObjectTypes.Report:
        case AuditObjectTypes.ReportSchedule:
            return addKeyFromRemoteObject(value, objects.reports, 'report', objectId);
        case AuditObjectTypes.Operation:
        case AuditObjectTypes.OperationSchedule:
            return addKeyFromRemoteObject(value, objects.operations, 'operation', objectId);
        case AuditObjectTypes.Animal:
        case AuditObjectTypes.EntityVeterinaryAssociation:
        case AuditObjectTypes.BillingTransaction:
        case AuditObjectTypes.PriceTable:
        case AuditObjectTypes.Certificate:
        case AuditObjectTypes.Template:
        case AuditObjectTypes.Configuration:
        case AuditObjectTypes.HelpTopic:
        case AuditObjectTypes.Observation:
        default:
            return {
                ...value,
            }
    }

}

const getEntryId = (value, objectIds) => {

    const objectType = value.affectedObjectType ? value.affectedObjectType : value.objectType;
    const objectId = value.affectedObjectType ? value.affectedObjectId : value.objectId;

    switch (objectType) {
        case AuditObjectTypes.Owner:
            objectIds.owners.push(objectId)
            break;
        case AuditObjectTypes.Animal:
            objectIds.animals.push(objectId)
            break;
        case AuditObjectTypes.User:
            objectIds.users.push(objectId)
            break;
        case AuditObjectTypes.Entity:
            objectIds.entities.push(objectId)
            break;
        case AuditObjectTypes.RabiesVaccine:
            objectIds.vaccines.push(objectId)
            break;
        case AuditObjectTypes.EntityProfile:
            objectIds.entityProfiles.push(objectId)
            break;
        case AuditObjectTypes.UserRole:
            objectIds.roles.push(objectId)
            break;
        case AuditObjectTypes.EntityVeterinaryAssociation:
            objectIds.veterinaryAssociation.push(objectId)
            break;
        case AuditObjectTypes.BillingTransaction:
            objectIds.billingTransactions.push(objectId)
            break;
        case AuditObjectTypes.PriceTable:
            objectIds.priceTables.push(objectId)
            break;
        case AuditObjectTypes.Breed:
            objectIds.breeds.push(objectId)
            break;
        case AuditObjectTypes.Certificate:
            objectIds.certificates.push(objectId)
            break;
        case AuditObjectTypes.Template:
            objectIds.templates.push(objectId)
            break;
        case AuditObjectTypes.Configuration:
            objectIds.configurations.push(objectId)
            break;
        case AuditObjectTypes.HelpTopic:
            objectIds.helpTopics.push(objectId)
            break;
        case AuditObjectTypes.Observation:
            objectIds.observations.push(objectId)
            break;
        case AuditObjectTypes.Veterinary:
            objectIds.veterinaries.push(objectId)
            break;
        case AuditObjectTypes.Report:
        case AuditObjectTypes.ReportSchedule:
            objectIds.reports.push(objectId)
            break;
        case AuditObjectTypes.Operation:
        case AuditObjectTypes.OperationSchedule:
            objectIds.operations.push(objectId)
            break;
        default:
            objectIds.unknown.push(objectId)
    }
}

const addKeyFromRemoteObject = (value, list, key, id) => {
    return {
        ...value,
        [key]: {
            ...list?.find(o => o.id === parseInt(id))
        }
    }
}


export default useLogDocument;
