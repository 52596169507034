const STERILIZATION_ID = 9;
const CANNOT_STERILIZATION_ID = 10;
const PROF_OF_LIFE_ID = 12;

const isSterilization = (typeId) => typeId === STERILIZATION_ID;
const isCannotSterilization = (typeId) => typeId === CANNOT_STERILIZATION_ID;
const isProofOfLife = (typeId) => typeId === PROF_OF_LIFE_ID;

export {STERILIZATION_ID, isCannotSterilization, isProofOfLife};
export default isSterilization;
