import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {useCallback, useMemo} from "react";

const ToolTip = (props) => {

    const {children, position = "bottom", show = 100, hide = 50, content} = props;

    const delayConfig = useMemo(
        () => ({show: show, hide: hide}),
        [show, hide]
    );

    const renderTooltip = useCallback((props) => {
        return <Tooltip {...props}>
            {content}
        </Tooltip>
    }, [content]);

    return <OverlayTrigger placement={position} delay={delayConfig} overlay={renderTooltip}>
        {children}
    </OverlayTrigger>

}

export default ToolTip;
