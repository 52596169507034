import HelpComponent from "app/login/components/help/HelpComponent";
import {useState} from "react";
import {useGet} from "system/Rest/Rest";

const HelpContainer = (props) => {

    const [isVisible, setVisible] = useState(false);

    const {data, loading} = useGet({
        path: "/auth/help",
        lazy: !isVisible
    });

    return <HelpComponent
        isVisible={isVisible}
        setVisible={setVisible}
        loading={loading}
        content={data?.help}
    />

}

export default HelpContainer;
