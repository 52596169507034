import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {ReportRestrictionType} from "../../../utils/ReportRestrictionType";

const RestrictionComponent = React.forwardRef((props, ref) => {

    const {t, entry: param, errors = {}, readOnly = false, onChange, onDelete, globalError} = props;

    return <Row>
        <Col xs={12} sm={6} lg={4}>
            <FormGroup error={errors['type'] || globalError}>
                <Form.Label>{t('param_type')}</Form.Label>
                <EnumDropdown
                    t={t}
                    value={getOrNull(param?.type)}
                    options={ReportRestrictionType}
                    isClearable={false}
                    placeholder={t('generic_select_ph')}
                    isSearchable={true}
                    isDisabled={readOnly}
                    onChange={useCallback(
                        (value) => onChange({
                            ...param,
                            type: value,
                        }),
                        [onChange, param]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['type'])}
                </Form.Text>
            </FormGroup>
        </Col>


        <Col xs={true} sm={true} lg={true}>
            <FormGroup error={errors['condition'] || globalError}>
                <Form.Label>{t('query_condition')}</Form.Label>
                <FormControl
                    type="text"
                    ref={ref}
                    autoComplete="chrome-off"
                    placeholder={t('condition_ph')}
                    value={getOrEmpty(param?.condition)}
                    disabled={readOnly || !param?.type}
                    onChange={useCallback(
                        (e) => onChange({
                            ...param,
                            condition: e.target.value,
                        }),
                        [onChange, param]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['condition'])}
                    {t(globalError)}
                </Form.Text>
            </FormGroup>
        </Col>

        {(onDelete && !readOnly) &&
        <Col xs={2} sm={1} lg={1} className="align-self-center">
            <div className={"btn-rounded-danger float-end"} onClick={onDelete}>
                <FontAwesomeIcon icon={faTrash}/>
            </div>
        </Col>
        }

    </Row>

});

export default RestrictionComponent;
