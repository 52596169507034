import React from "react";
import SearchBadge from "./SearchBadge";
import getAsArray from "../../../../system/Arrays/getArray";

const SearchFilterBadges = (props) => {

    const {visible = false, filters, onRemove} = props;


    if (!visible || (!filters || Object.keys(filters).length === 0))
        return <></>;


    return <div className="search-area-filters-badges">
        {Object.keys(filters).map((key) => {
            return filters[key].valueLabels.map((label, idx) => {

                    if (!label) {
                        return <span key={key}/>; //Prevent loading badges without transalation loaded
                    }

                    return <SearchBadge
                        key={key + "_" + label + "_" + filters[key].value}
                        id={key}
                        subId={idx}
                        value={getAsArray(filters[key].value)[idx]}
                        param={filters[key]}
                        label={label}
                        onRemove={onRemove}/>;
                }
            );
        })}
    </div>

}

export default SearchFilterBadges;
