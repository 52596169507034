import ListComponent from "../components/list/ListComponent";
import locale from "../locales/locales";
import React, {useMemo} from "react";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";

const ListContainer = (props) => {

    const {ownerId, ownerAnimalTransponder} = props;

    return <GenericListContainer
        {...props}
        hideTitle={true}
        module="animal"
        locale={locale}
        queryParameters={useMemo(() => ({
            currentPage: 0,
            itemsPerPage: 9,
            ownerId: ownerId,
            ownerAnimalTransponder: ownerAnimalTransponder
        }),[ownerId, ownerAnimalTransponder])}>
        <ListComponent {...props} />
    </GenericListContainer>


}

export default ListContainer;
