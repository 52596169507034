import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import {useTranslation} from "react-i18next";
import ShowComponent from "./ShowComponent";
import OperationDetailComponent from "./OperationDetailComponent";
import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import "app/audit/assets/css/modal.scss";
import ListContainer from "app/audit/containers/ListContainer";

const ModalComponent = (props) => {

    const {onClose, operation} = props;
    const {t} = useTranslation('audit');

    return <Modal
        show={true}
        size={"xl"}
        centered
        onHide={onClose}
        className={"audit-modal dark"}
    >

        <Modal.Header closeButton>
            <Modal.Title>{t('view_operation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <OperationDetailComponent operation={operation}/>

            <div className={"detail-tabs"}>
                <Tabs id={"tab-log-entry-" + operation?.id} unmountOnExit={false} mountOnEnter={false}>
                    <Tab eventKey="detail" title={t('operation_details')}>
                        <ShowComponent {...props} />
                    </Tab>

                    <Tab eventKey="related" title={t('operations_related')}>
                        <ListContainer
                            id={"operation-related-with" + operation?.id}
                            hideTitle={true}
                            hideFilter={true}
                            userIds={operation.userId}
                            fromTimestamp={operation.timestamp}
                            toTimestamp={operation.timestamp}
                            actions={operation.action}
                        />
                    </Tab>
                </Tabs>
            </div>


        </Modal.Body>

        <ModalButtons>
            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>
        </ModalButtons>
    </Modal>


}

export default ModalComponent;
