import {useMutate} from "system/Rest/Rest";
import React, {useCallback} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import PopupFormComponent from "app/certificates/components/generate/PopupFormComponent";
import fileDownload from "app/files/utils/download";
import fileNameGenerator from "app/certificates/utils/fileNameGenerator";

const PopupFormContainer = (props) => {

    const {animal} = props;
    const {t} = useTranslation('certificate');
    const showToast = useShowToast();

    const {mutate: downloadFile, loading: downloading, error: errorDownloading} = useMutate({
        verb: "POST",
        path: "/certificates/download",
    });

    const errors = useErrorParser(errorDownloading);

    return <PopupFormComponent
        {...props}
        loading={downloading}
        errors={errors}
        onSubmit={useCallback(
            (request) => new Promise(resolve => {
                downloadFile({
                    transponder: animal?.transponder,
                    ...request
                }).then(result => {
                    if (request.sendEmail) {
                        showToast({
                            title: t('SUCCESS_SENT_CERTIFICATE'),
                            message: t('SUCCESS_SENT_CERTIFICATE_MSG', {email: request.emailAddress}),
                            icon: faCheck,
                        });
                    } else {

                        fileDownload(result.contents, fileNameGenerator(result), "application/pdf");

                        showToast({
                            title: t('SUCCESS_DOWNLOAD_CERTIFICATE'),
                            message: t('SUCCESS_DOWNLOAD_CERTIFICATE_MSG'),
                            icon: faCheck,
                        })
                    }
                    resolve();

                }).catch((e) => {
                    showToast({
                        title: t('FORM_ERROR'),
                        message: t('FORM_ERROR_MSG'),
                        icon: faTimes,
                        className: 'error',
                    });
                });

            }),
            [animal?.transponder, showToast, downloadFile,t]
        )}
    />


}

export default PopupFormContainer;
