export const pt = {

    add_intervention: "Adicionar intervenção sanitária",
    view_intervention: "Visualizar intervenção sanitária",

    intervention_tip: "Para adicionar uma intervenção sanitária deve preencher os campos abaixo",
    general_data: "Dados gerais",

    type: "Tipo",
    generic_select_ph: "Selecione um tipo ",
    no_results: "Sem resultados",

    execution_date: "Data de execução",
    execution_date_ph: "Data no formato (dd-mm-yyyy)",
    register_date: "Data de registo",
    observations: "Observações opcionais",
    reason: "Motivo",

    add: "Registar intervenção",
    edit: "Guardar alterações",
    cancel: "Cancelar",


    //Proof of life
    proof_of_life: "Prova de vida e aptidão",
    accept_proof_of_life: "Aceitar e continuar",
    cancel_proof_of_life: "Cancelar",

    //antirabie
    antirabie: "Profilaxia antirrábica",
    vaccine: "Vacina",
    select_vaccine_ph: "Selecione uma vacina",
    batch: "Lote",
    batch_ph: "Escreva o nº do lote",
    veterinary: "Médico veterinário",
    immunization_date_end: "Data de validade de imunização",
    immunization_date_end_ph: "Indique a validade de imunização",


    //sterilization
    sterilization: "Esterilização",
    sterilization_type: "Tipo de esterilização",
    select_sterilization_type_ph: "Escolha um tipo",
    PRIVATE: "Privada",
    FINANCED_BY_COUNTY: "Financiada pelo município",
    FINANCED_BY_MINISTRY_OF_AGRICULTURE: "Financiada pelo ministério da agricultura despacho nº 6615/2020, de 25 de junho",
    financing_entity: "Entidade municipal / Intermunicipal",
    MISSING_FINANCING_ENTITY: "Deve indicar uma entidade",

    no_intervention_results: "Sem intervenções sanitárias",

    SUCCESS_ADD_INTERVENTION: "Intervenção registada",
    SUCCESS_ADD_INTERVENTION_MSG: "Foi registado nova intervenção sanitária para o transponder {{transponder}}",

    SUCCESS_EDIT_INTERVENTION: "Intervenção editada",
    SUCCESS_EDIT_INTERVENTION_MSG: "Foi editada a intervenção sanitária para o transponder {{transponder}}",


    ERROR_ADD_INTERVENTION: "Erro na intervenção",
    ERROR_ADD_INTERVENTION_MSG: "Por favor verifique os campos assinalados",
    ANIMAL_ALREADY_STERILIZED: "O animal ja está esterilizado!",

    DELETE_INTERVENTION_CONFIRM: "Confirmação",
    DELETE_INTERVENTION_CONFIRM_MSG: "Deseja realmente apagar a intervenção sanitária do tipo {{name}}?",

    SUCCESS_DELETE_INTERVENTION: "Sucesso",
    SUCCESS_DELETE_INTERVENTION_MSG: "Intervenção sanitária removida com sucesso",

    FAIL_DELETE_INTERVENTION: "Erro ao apagar",
    FAIL_DELETE_INTERVENTION_MSG: "Ocorreu um erro ao apagar a intervenção sanitária. Por favor tente mais tarde",

    INVALID_TYPE: "Por favor indique um tipo de intervenção",
    INVALID_TYPE_ID: "Por favor indique um tipo de intervenção",
    MISSING_TYPE: "Por favor indique um tipo de intervenção",
    INVALID_EXECUTION_DATE: "Deve indicar a data em que foi executada a intervenção",
    MISSING_EXECUTION_DATE: "Deve indicar a data em que foi executada a intervenção",
    INVALID_DATE: "A data não é válida",
    EXECUTION_DATE_CANNOT_BE_AFTER_REGISTRATION_DATE: "A data de execução não pode ser superior à data em que este registo foi criado pelo que deverá criar/registar uma nova intervenção",
    INVALID_REASON: "Deve indicar um motivo válido",

    MISSING_VETERINARY: "Deve indicar o veterinário que executou a intervenção",

    MISSING_VACCINE: "Por favor escolha uma vacina",
    MISSING_BATCH: "Por favor indique o lote",
    INVALID_IMMUNIZATION_DATE: "A data de imunização nao é válida",

    animal_is_not_valid: "Dados inválidos",
    animal_is_not_valid_msg: "Devido ao registo deste animal estar incompleto não será possível registar intervenções de esterilização.<br/>Para avançar complete a ficha com os dados em falta.",


    rabies_date_info: "A data de validade de imunização é indicativa, existindo diferenças na duração em relação à espécie ou situações de primovacinação. Deve ser confirmada pelo Médico Veterinário responsável pela aplicação, consultando a informação técnica de cada vacina (por exemplo o RCM), editando a data para a correta, sempre que tal seja necessário.",

};

export default pt;
