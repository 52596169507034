import React, {useCallback, useState} from "react";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import {Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

const MenuDropDownGroup = props => {

    const {title, children} = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(
        (e) => {
            setIsOpen(isOpen => !isOpen);
            e.stopPropagation();
        },
        [setIsOpen]
    )

    return <div className={"menu-drop-down-group"}>
        <MenuDropdownOption>
            <div onClick={handleClick} className={classnames("menu-drop-down-group-title", {"open": isOpen})}>
                {title}

                <div className={"icon"}>
                    {isOpen
                        ? <FontAwesomeIcon icon={faAngleUp}/>
                        : <FontAwesomeIcon icon={faAngleDown}/>
                    }
                </div>
                
            </div>
        </MenuDropdownOption>
        <Collapse in={isOpen}>
            <div className={"menu-drop-down-group-sub"}>
            {children}
            </div>
        </Collapse>
    </div>
}

export default MenuDropDownGroup;
