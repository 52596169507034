import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import MenuDropdownHeader from "layout/modules/MenuDropdown/components/MenuDropdownHeader";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import TableMenuEntryOptions from "layout/modules/TableAdvanced/components/TableEntryMenuOptions";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import LostComponent from "app/animals/components/lost/show/LostComponent";
import ClosedLostContainer from "app/animals/containers/lost/ClosedLostContainer";
import LostCloseMenuOptionsComponent from "app/animals/components/lost/other/LostCloseMenuOptionsComponent";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import HasBalance from "modules/Billing/HasBalance";


const ListMenuOptionsComponent = (props) => {

    const {animal, placement, children, ...otherProps} = props;
    const {t} = useTranslation("animal");

    const setLostRef = useRef(null);
    const setDeliveredRef = useRef(null);

    return <>

        {animal?.isExternal &&
        <AnimalOperationAllowed animal={animal} operation={AnimalOperations.SET_DELIVERED}>
            <ClosedLostContainer {...otherProps} transponder={animal.transponder} ref={setDeliveredRef}/>
        </AnimalOperationAllowed>
        }

        <LostComponent
            {...otherProps}
            lostInfo={animal?.isExternal ? animal : undefined}
            transponder={animal?.transponder}
            notRegistered={animal?.isExternal || animal?.isPreRegister}
            ref={setLostRef}
        />


        <TableMenuEntryOptions placement={placement} label={children}>
            <MenuDropdownContent>
                <MenuDropdownHeader>
                    {t('table_menu_options')}
                </MenuDropdownHeader>

                {animal?.isExternal
                    ? <MenuDropdownOption>
                        <div onClick={() => setLostRef.current?.setVisible()}>
                            {t('view_lost_info')}
                        </div>
                    </MenuDropdownOption>
                    : <>
                        {animal?.isPreRegister
                            ? <>
                                <MenuDropdownOption>
                                    <Link to={"/pre-registers/" + animal.id}>
                                        {t('view_pre_register')}
                                    </Link>
                                </MenuDropdownOption>
                                <AnimalOperationAllowed animal={animal} operation={AnimalOperations.SET_LOST}>
                                    <MenuDropdownOption>
                                        <div onClick={() => setLostRef.current?.setVisible()}>
                                            {t('as_lost')}
                                        </div>
                                    </MenuDropdownOption>
                                </AnimalOperationAllowed>
                            </>
                            : <MenuDropdownOption>
                                <Link to={"/animal/view/" + animal.transponder}>
                                    {t('view_animal')}
                                </Link>
                            </MenuDropdownOption>
                        }
                    </>
                }

                {animal?.isExternal &&
                <>
                    <UserHasPermission permission={['REGISTER_ANIMAL']}>
                        <HasBalance>
                            <MenuDropdownOption>
                                <Link to={"/animal/create"} state={{transponder_auto_fill: animal.transponder}}>
                                    {t('create_title')}
                                </Link>
                            </MenuDropdownOption>
                        </HasBalance>
                    </UserHasPermission>
                    <LostCloseMenuOptionsComponent {...props} animal={animal} setDeliveredRef={setDeliveredRef}/>
                </>
                }
            </MenuDropdownContent>
        </TableMenuEntryOptions>
    </>

}

export default ListMenuOptionsComponent;
