import Modal from "react-bootstrap/Modal";
import React, {useMemo} from "react";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import GenericTableContainer from "modules/GenericTableContainer/GenericTableContainer";
import ListTableComponent from "app/users/components/ListTableComponent";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import Loading from "layout/modules/Loading/Loading";

const ImpersonateComponent = (props) => {

    const {loading, isVisible, onClose, onImpersonate} = props;
    const {t} = useTranslation('personalArea');

    const queryParams = useMemo(() => ({
        sortProperty: 'name',
        sortDirection: 'ASC',
        currentPage: 0,
        excludeSelf: true,
        itemsPerPage: 5,
        status: 'ACTIVE',
        type: "ANY"
    }), []);

    if (!isVisible) {
        return <></>
    }

    return <Modal show={isVisible} onHide={() => onClose()} size={"xl"}>
        <Modal.Header closeButton>
            <Modal.Title>{t('select_impersonate')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Loading visible={loading} localOnly={true}/>

            <GenericTableContainer
                {...props}
                id={"impersonate"}
                path={"/users/search"}
                queryParameters={queryParams}>

                <ListTableComponent hideAdd={true} showVeterinaries={true}>
                    <TableButtons onImpersonate={onImpersonate}/>
                </ListTableComponent>

            </GenericTableContainer>
        </Modal.Body>
    </Modal>
}

const TableButtons = ({value: user, onImpersonate}) => {

    return <div className="buttons">
        <TableIconButton
            icon={faCheck}
            onClick={() => onImpersonate(user)}
            className={"table-btn btn-rounded-primary small"}
        />
    </div>
}

export default ImpersonateComponent;
