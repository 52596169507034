import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import React, {useCallback, useState} from "react";
import {readFileContent} from "system/Files/ReadFileContent";
import Loading from "layout/modules/Loading/Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Box from "layout/modules/Box/Box";
import {useTranslation} from "react-i18next";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import RemoteListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RemoteListDropdown";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import {acceptTypeImages, acceptTypesPdf} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";

const PreRegisterFormComponent = (props) => {

    const {t} = useTranslation('preRegister');
    const {errors = {}, readOnly} = props;
    const {animal, setAnimal} = useAnimalState();

    const [uploadingCounter, setUploadingCounter] = useState(0);
    const documentCounter = animal?.documents?.length;

    return <>
        <Box>
            <BoxTitle>
                {t('pre_register_reason')}
            </BoxTitle>

            <Row>
                <Col sm={12} lg={12}>
                    <FormGroup error={errors['reason']} mandatory={true}>
                        <Form.Label>{t('pre_register_reason')}</Form.Label>
                        <RemoteListDropdown
                            url={"/animals/pre-register/reasons"}
                            value={getOrEmpty(animal?.reason)}
                            isCreatable={false}
                            isDisabled={readOnly}
                            onChange={useCallback(
                                (reason) => setAnimal(animal => ({
                                    ...animal,
                                    reason: reason,
                                })),
                                [setAnimal]
                            )}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['reason'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </Row>
        </Box>

        <FormGroup error={errors['documents']} mandatory={true}>

            <Box className={"pre-register-upload-box"} visible={!readOnly}>
                <Row>
                    <Col lg={(documentCounter > 0) ? 6 : 12} sm={12} className={"col-upload"}>
                        <DragDropFile
                            maxFiles={10}
                            acceptTypes={{
                                ...acceptTypesPdf,
                                ...acceptTypeImages,
                            }}
                            text={t('pre_register_file_upload')}
                            onFileUploaded={useCallback((nf) => {
                                nf.forEach((file) => {
                                    setUploadingCounter((uc) => uc + 1);
                                    readFileContent(file, (content) => {
                                        setAnimal(ao => ({
                                            ...ao,
                                            documents: [
                                                ...ao.documents,
                                                {
                                                    filename: file.name,
                                                    type: file.type,
                                                    contents: btoa(content)
                                                }
                                            ]
                                        }));
                                        setUploadingCounter((uc) => uc - 1);
                                    });
                                })
                            }, [setUploadingCounter, setAnimal])}
                        />
                    </Col>

                    <Col lg={6} sm={12} className={"file-list"}>
                        <Loading visible={uploadingCounter > 0}/>
                        {animal.documents?.map((file, id) =>
                            <div className={"file"} key={id}>
                                <div className={"name"}>
                                    {file.filename}
                                </div>
                                <div className={"close"}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className={"delete"}
                                        onClick={() => {
                                            setAnimal(ao => {
                                                const documents = [...ao.documents];
                                                documents.splice(id, 1);
                                                return {
                                                    ...ao,
                                                    documents: documents
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Box>
            <Form.Text className="sub-error documents-errors">
                {t('error_in_documents')}
            </Form.Text>
        </FormGroup>

    </>
}

export default PreRegisterFormComponent;
