import Form from "react-bootstrap/Form";
import React, {useCallback, useMemo, useState} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import {
    filterZipCode,
    getZipCode,
    validateZipCode
} from "modules/Forms/Address/components/FormComponents/pt/utils/zipCode";
import Help from "app/help/Help";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

const ZipCodeComponent = (props) => {

    const {t, changes = {}, errors = {}, data, onChange, readOnly} = props;

    const [isHelpVisible, setHelpVisible] = useState(false);
    const validateZip = useCallback((value) => {
        onChange(data => ({
            ...data,
            zipCodeValid: validateZipCode(value),
        }));
    }, [onChange]);

    const handleZipCode = useCallback((value) => {
        if (filterZipCode(value) !== false) {
            onChange(address => ({
                ...getZipCode(value),
                zipCode: filterZipCode(value),
                country: address.country,
            }));
        }
    }, [onChange]);

    const isZipCodeInError = useMemo(
        () => (data.zipCodeValid === false || data.zipCodeConfirmed === false) ? "1" : "",
        [data.zipCodeValid, data.zipCodeConfirmed]
    );

    const handleHelpClick = useCallback(
        () => setHelpVisible(true),
        [setHelpVisible]
    );

    return <>

        <Help
            isVisible={isHelpVisible}
            onClose={() => setHelpVisible(false)}
            forceArticle={"pt-zip-code"}
        />

        <FormGroup error={isZipCodeInError || errors['postalCode']} changes={changes['postalCode']}>
            <Form.Label>
                {t('zip_code')}
                {!readOnly && <FontAwesomeIcon
                    className={"zip-help-icon"}
                    icon={faQuestionCircle}
                    onClick={handleHelpClick}
                />
                }
            </Form.Label>
            <FormControl
                autoComplete="chrome-off"
                type="text"
                placeholder={t('zip_code_ph')}
                className={"zip-code"}
                value={getOrEmpty(data.zipCode)}
                onChange={useCallback(e => handleZipCode(e.target.value), [handleZipCode])}
                disabled={readOnly}
                onBlur={useCallback(e => validateZip(e.target.value), [validateZip])}
                onKeyDown={useCallback((e) => {
                        if (e.keyCode === 9) {
                            e.preventDefault();
                            validateZip(e.target.value)
                        }
                    },
                    [validateZip]
                )}
            />

            <Form.Text className="sub-error">
                {isZipCodeInError ? t('INVALID_POSTAL_CODE') : t(errors['postalCode'])}
            </Form.Text>

        </FormGroup>
    </>
}

export default ZipCodeComponent;
