import {useMutate} from "system/Rest/Rest";
import {useCallback} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import ValidationStatus from "types/ValidationStatus";

const useBulkUpdate = props => {

    const showToast = useShowToast();
    const {path, t, reloadBase, prop} = props;

    const {mutate: bulk, loading} = useMutate({
        verb: "PUT",
        path: path,
    });

    const handleBulkOperation = useCallback(
        (ids, reload, validation, reason) => {

            const isAccept = (validation === ValidationStatus.ACCEPTED || validation === true);

            bulk({
                [prop]: ids,
                validation: validation,
                comments: reason,
            }).then(() => {
                showToast({
                    title: isAccept ? t('SUCCESS_BULK_ACCEPT') : t('SUCCESS_BULK_REJECT'),
                    message: t(isAccept ? 'SUCCESS_BULK_ACCEPT_MSG' : 'SUCCESS_BULK_REJECT_MSG', {number: ids.length}),
                    icon: faCheck,
                });

                reload();

                if (reloadBase)
                    reloadBase();

            }).catch(() => {
                showToast({
                    title: t(isAccept ? 'ERROR_BULK_ACCEPT' : 'ERROR_BULK_REJECT'),
                    message: t(isAccept ?  'ERROR_BULK_ACCEPT_MSG' : 'ERROR_BULK_REJECT_MSG'),
                    className: 'error',
                    icon: faTimes,

                });

                reload()
            });

        },
        [reloadBase, showToast,t, bulk,prop]
    );

    return {
        loading: loading,
        handleBulkOperation: handleBulkOperation,
    }

}

export default useBulkUpdate;
