import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/control/modules/preRegister/locales/locales";
import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";
import ShowContainer from "app/control/modules/preRegister/containers/ShowContainer";
import {AnimalStateProvider} from "app/animals/utils/AnimalContext";
import {useParams} from "react-router-dom";
import animalResolver from "app/animals/utils/AnimalResolver";

const Show = props => {

    const {id} = useParams();
    const {t} = useTranslations('preRegister', locale);

    const {data: preRegister, refetch, loading} = useGet({
        path: "/animals/pre-register/" + id,
        resolve: animalResolver
    });


    return <Title title={t('show_pre_register', {transponder: preRegister?.transponder})}
                  breadcrumbs={[{href: "/pre-registers", label: t('list')}, {
                      href: "/pre-registers/" + id,
                      label: id
                  }]}
                  {...props}>

        {preRegister
            ? <AnimalStateProvider animal={preRegister}>
                <ShowContainer
                    {...props}
                    preRegister={preRegister}
                    loading={loading}
                    reload={refetch}
                />
            </AnimalStateProvider>
            : <Loading visible={true}/>
        }
    </Title>

}

export default Show;
