import React, {useEffect, useState} from 'react'
import Alert from "react-bootstrap/Alert"
import * as PropTypes from "prop-types"

const InfoMessage = (props) => {

    const {title = "", type = "success", visible, children, dismissible} = props;
    const [messageVisible, setMessageVisible] = useState(visible); //To Control Info message

    useEffect(() => {
        setMessageVisible(visible);
    }, [visible, setMessageVisible]);

    return <Alert
        variant={type}
        show={messageVisible}
        onClose={() => setMessageVisible(false)}
        dismissible={dismissible}
    >
        <Alert.Heading>{title}</Alert.Heading>
        {children}
    </Alert>
}

export default InfoMessage;

InfoMessage.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string
};
