import PreviewComponent from "../components/PreviewComponent";
import {useGet} from "system/Rest/Rest";

const PreviewContainer = (props) => {

    const {file} = props;

    const {data, loading} = useGet({
        path: "/documents/" + file.id
    });

    return <PreviewComponent
        {...props}
        file={data ? data: undefined}
        loading={loading}
    />


}

export default PreviewContainer;
