import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import createPersistedState from "system/System/PersistedState/PersistenState";
import generateHashCode from "system/Utils/generateHashCode";

const useInformationPersistedState = createPersistedState('discardedInformation');

const AlertsComponent = props => {

    const {t} = useTranslation('dashboard');
    const {information} = props;

    const [discardedHash, setDiscardedHash] = useInformationPersistedState(null);
    const currentHash = useMemo(() => generateHashCode(information),[information]);
    const [isVisible, setVisible] = useState(discardedHash !== currentHash);

    const handleClose = useCallback(
        () => {
            setVisible(false);
        },
        [setVisible]
    );

    const handleDiscard = useCallback(
        () => {
            setVisible(false);
            setDiscardedHash(currentHash);
        },
        [setVisible, currentHash, setDiscardedHash]
    );

    return <Modal show={isVisible} onHide={handleClose} centered backdrop={true} size={"lg"}>
        <Modal.Header closeButton={true}>
            <Modal.Title>{t('alerts')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div dangerouslySetInnerHTML={{__html: information}}/>

        </Modal.Body>
        <Modal.Footer>

            <Button variant={'danger'} onClick={handleDiscard}>
                {t('close_and_discard')}
            </Button>

            <Button variant={'success'} onClick={handleClose}>
                {t('close')}
            </Button>
        </Modal.Footer>
    </Modal>


}
export default AlertsComponent;
