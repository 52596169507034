import ListFilterComponent from "./ListFilterComponent";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import RunningListComponent from "app/animals/components/list/implementations/runningList/RunningListComponent";
import CardListComponent from "app/animals/components/list/implementations/cardList/CardListComponent";
import TableTypeButton, {ListType} from "layout/modules/TableAdvanced/components/buttons/TableTypeButton";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import TransponderControl from "modules/Forms/Transponder/TransponderControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import TableHeaderButtonGroup from "layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import HasBalance from "modules/Billing/HasBalance";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import "app/animals/assets/css/generic.scss"

const ListComponent = (props) => {

    const {t} = useTranslation("animal");

    const {
        data,
        onSearch,
        searchFilter,
        onFilterSubmit,
        onPageChange,
        showAdd = true,
        paginationContent,
        type,
    } = props;

    const [listType, setListType] = useState(type ? type : ListType.GRID);

    return <div className={classnames({
        "running-animal-list": listType === ListType.LIST,
        "card-animal-list": listType === ListType.GRID
    })}>

        <TableHeader>
            <SearchArea
                onSearch={onSearch}
                value={searchFilter.universalSearch}
                searchControl={() => {
                    return <TransponderControl
                        placeholder={t('transponder_ph')}
                        defaultValue={getOrEmpty(searchFilter.universalSearch)}
                        onValid={() => {}}
                        onBlur={(e) => { onSearch(e.target.value)}}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearch(e.target.value)
                            }
                        }}
                        onInvalid={(value) => {
                            if (value === "")
                                onSearch("");
                        }}
                    />
                }}>
                <SearchFilter onSubmit={(data) => onFilterSubmit(data)}>
                    <ListFilterComponent searchFilter={searchFilter} t={t}/>
                </SearchFilter>
            </SearchArea>

            <TableHeaderButtonGroup>
                {!type &&
                <TableTypeButton onChange={setListType}/>
                }
                <HasBalance>
                    <TableIconAdd path="/animal/create" permission={["REGISTER_ANIMAL"]} visible={showAdd}/>
                </HasBalance>
            </TableHeaderButtonGroup>
        </TableHeader>


        <SearchDataPagination data={data} onPageChange={onPageChange}>
            {!paginationContent ? undefined : React.cloneElement(paginationContent, {
                data: data,
            })}
        </SearchDataPagination>

        {(listType === ListType.LIST)
            ? <RunningListComponent {...props}/>
            : <CardListComponent {...props}/>
        }


    </div>
}

export default ListComponent;
