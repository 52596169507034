import React, {useEffect} from "react"
import {useRouteError} from "react-router";
import InternalError from "layout/modules/ErrorPages/InternalError";
import tracker from "modules/OpenReplay/tracker";
import OpenReplayTracker from "modules/OpenReplay/tracker";

const ErrorInterceptor = props => {

    const error = useRouteError();

    useEffect(
        () => {

            if (error) {
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        log: JSON.stringify({
                            error: error.toString(),
                            logSessionId: OpenReplayTracker?.getSessionID(),
                            location: window.location,
                            stack: error.stack,
                        })
                    })
                };

                console.error(error);
                tracker.event('ErrorPage', error?.toString());
                tracker.event('AppErrorPage', error?.toString());

                fetch(window.location.origin + '/api/fe-debug/', requestOptions)
                    .then(response => {
                    })
            }
        },
        [error]
    )

    return <InternalError error={error} showGoDashboard={true}/>

}


export default ErrorInterceptor;
