import React, {useEffect} from "react";
import useScrollToError from "./utils/scrollToError";
import {ToastError} from "layout/modules/NotificationArea/components/ToastComponent";

const GenericFormError = ({errors, t, id = 'formError'}) => {

    const scrollToError = useScrollToError();

    useEffect(() => {
        if (Object.keys(errors).length > 0 && scrollToError) {
            setTimeout(function () {
                scrollToError();
            }, 400);

        }
    }, [errors, scrollToError]);

    return <ToastError
        id={id}
        title={t('FORM_ERROR')}
        message={t('FORM_ERROR_MSG')}
        visible={Object.keys(errors).length > 0}/>

}

export default GenericFormError;
