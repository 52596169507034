import {useGet} from "system/Rest/Rest";
import React, {useCallback, useMemo, useState} from "react";
import DocumentType from "types/Documents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import SeparatorComponent from "app/animals/components/view/panel/SeparatorComponent";
import {useTranslation} from "react-i18next";
import DownloadContainer from "app/files/containers/DownloadContainer";
import "app/animals/assets/css/breed-files.scss";

const BreedFilesList = props => {

    const {animal} = props;
    const {t} = useTranslation("animal");

    const [download, setDownload] = useState(null);
    const handleDownloadComplete = useCallback(
        () => setDownload(null),
        [setDownload]
    );


    const {data: documents} = useGet({
        path: "/documents/search",
        lazy: !animal?.breed?.parent?.id,
        queryParams: useMemo(() => ({
            documentType: DocumentType.BREED,
            documentTypeId: animal?.breed?.parent?.id,
            itemsPerPage: 20,
        }), [animal?.breed?.parent?.id]),
    });


    if (documents?.content?.length > 0) {

        return <>
            {download &&
            <DownloadContainer file={download} onDownloadComplete={handleDownloadComplete}/>
            }
            <div className={"breed-documents"}>
                <div className={"title"}>
                    {t('other_documents')}
                </div>

                {documents?.content?.map((file, index) =>
                    <div className={"breed-document"} key={index} onClick={() => setDownload(file)}>
                        {file.title ?? file.filename}
                        <FontAwesomeIcon icon={faDownload}/>
                    </div>
                )}
            </div>

            <SeparatorComponent/>
        </>

    }

    return <></>
}

export default BreedFilesList;
