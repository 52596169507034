import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import SterilizationType, {SterilizationAllowedType} from "app/interventions/utils/sterilization/SterilizationType";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Loading from "layout/modules/Loading/Loading";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import FinancingEntitiesDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/FinancingEntitiesDropdown";

const SterilizationComponent = (props) => {

    const {t} = useTranslation("health-intervention");

    const {errors, intervention, setIntervention, readOnly, loading} = props;

    return <Box>
        <BoxTitle>{t('sterilization')}</BoxTitle>

        <Loading visible={loading}/>

        <Row>
            <Col sm={12} xl={true}>

                <FormGroup error={errors['sterilizationType']} mandatory={true}>
                    <Form.Label>{t('sterilization_type')}</Form.Label>
                    <EnumDropdown
                        t={t}
                        value={getOrNull(intervention?.sterilizationType)}
                        options={readOnly ? SterilizationType : SterilizationAllowedType}
                        isClearable={false}
                        isSearchable={false}
                        isDisabled={readOnly}
                        placeholder={t('select_sterilization_type_ph')}
                        onChange={useCallback(
                            type => setIntervention(intervention => ({
                                ...intervention,
                                sterilizationType: type,
                            })), [setIntervention]
                        )}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['sterilizationType'])}
                    </Form.Text>
                </FormGroup>

            </Col>

            {(intervention?.sterilizationType && intervention?.sterilizationType === SterilizationType.FINANCED_BY_COUNTY) &&
            <Col sm={12} xl={6}>
                <FormGroup error={errors['financingEntityId']} mandatory={true}>
                    <Form.Label>{t('financing_entity')}</Form.Label>
                    <FinancingEntitiesDropdown
                        id="financingEntityId"
                        defaultSelectedOption={intervention?.financingEntity}
                        value={getOrNull(intervention?.financingEntityId)}
                        onChange={(val) => setIntervention((intervention) => ({
                            ...intervention,
                            financingEntityId: val,
                        }))}
                        isDisabled={readOnly}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['financingEntityId'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            }
        </Row>

    </Box>


}

export default SterilizationComponent;
