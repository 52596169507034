import UserHasPermission from "system/API/Authentication/UserHasPermission";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import MenuDropdownHeader from "layout/modules/MenuDropdown/components/MenuDropdownHeader";
import MenuDropdown from "layout/modules/MenuDropdown/MenuDropdown";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import LockTemporaryPopupComponent from "app/owners/components/view/toolbar/LockTemporaryPopupComponent";

const LockOwnerComponent = props => {

    const {owner, onLock} = props;
    const {t} = useTranslation('owner');

    const [lockPopupVisible, setLockPopupVisible] = useState(null);

    return <UserHasPermission permission="LOCK_OWNER">

        {lockPopupVisible &&
            <PopupConfirm
                title={t('LOCK_OWNER_CONFIRM')}
                classConfirm={"danger"}
                visible={lockPopupVisible}
                message={lockPopupVisible.allowTemporary ? undefined : t('LOCK_OWNER_CONFIRM_MSG', {desc: lockPopupVisible?.message?.toLowerCase()})}
                onCancel={() => {
                    setLockPopupVisible(false);

                }}
                onConfirm={() => {
                    onLock(lockPopupVisible);
                    setLockPopupVisible(false);
                }}
            >
                {lockPopupVisible.allowTemporary &&
                    <LockTemporaryPopupComponent t={t} status={lockPopupVisible} setStatus={setLockPopupVisible}/>
                }

            </PopupConfirm>
        }

        <MenuDropdown>
            <ToolTip content={t('lock_options')}>
                <div className={classnames("btn-rounded-danger small", {"active": owner.isLocked || owner.editLocked})}>
                    <FontAwesomeIcon icon={faLock}/>
                </div>
            </ToolTip>
            <MenuDropdownContent>
                <MenuDropdownHeader>
                    {t('lock_options')}
                </MenuDropdownHeader>
                <MenuDropdownOption>
                    <div
                        onClick={() => setLockPopupVisible({
                            isLocked: !owner.isLocked,
                            allowTemporary: !owner.isLocked,
                            message: owner.isLocked ? t('unlock_owner') : t('lock_owner')
                        })}>
                        {owner.isLocked ? t('unlock_owner') : t('lock_owner')}
                    </div>
                </MenuDropdownOption>

                <MenuDropdownOption>
                    <div
                        onClick={() => setLockPopupVisible({
                            editLocked: !owner.editLocked,
                            message: owner.editLocked ? t('unlock_edit_owner') : t('lock_edit_owner')
                        })}>
                        {owner.editLocked ? t('unlock_edit_owner') : t('lock_edit_owner')}
                    </div>
                </MenuDropdownOption>

            </MenuDropdownContent>
        </MenuDropdown>
    </UserHasPermission>


}

export default LockOwnerComponent;
