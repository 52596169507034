import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import MultiOptionComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionComponent";
import React, {useCallback} from "react";
import RestrictionComponent from "./RestrictionComponent";

const RestrictionsComponent = props => {
    const {errors, t, template, setTemplate, readOnly} = props;

    return <Box>
        <BoxTitle>{t('query_restrictions')}</BoxTitle>


        <MultiOptionComponent
            data={template?.restrictions}
            errors={errors?.restrictions}
            readOnly={readOnly}
            onChange={useCallback((fnc) => setTemplate((data) => ({
                ...data,
                restrictions: fnc(data.restrictions),
            })), [setTemplate])}>
            <RestrictionComponent t={t}/>
        </MultiOptionComponent>
    </Box>
}

export default RestrictionsComponent;
