import ViewComponent from "./components/ViewComponent";
import useTranslations from "../../system/Translations/UseTranslations";
import locale from "./locales/locales";
import Title from "../../layout/modules/BaseLayout/ContentArea/components/Title";
import React from "react";
import {useGet} from "system/Rest/Rest";
import Loading from "../../layout/modules/Loading/Loading";
import animalResolver from "./utils/AnimalResolver";
import {AnimalStateProvider} from "./utils/AnimalContext";
import {useParams} from "react-router-dom";


const Animal = (props) => {

    const {t} = useTranslations('animal', locale);
    const {id} = useParams();

    const {data: animal, loading, refetch: reload} = useGet({
        path: "/animals/" + id,
        resolve: animalResolver
    });

    return <Title title={t('view', {id: id})}
                  breadcrumbs={[{href: "/animal", label: t('list')}, {href: "/animal/view/" + id, label: id}]}
                  {...props}>

        {animal
            ? <AnimalStateProvider animal={animal}>
                <ViewComponent {...props} id={id} animal={animal} loading={loading} reload={reload}/>
            </AnimalStateProvider>
            : <Loading visible={true}/>
        }
    </Title>

}

export default Animal;
