import ViewContainer from "app/support/containers/ViewContainer";
import locale from "app/support/locales/locales";
import {useParams} from "react-router-dom";
import useTranslations from "system/Translations/UseTranslations";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";

const Ticket = props => {

    const {id} = useParams();
    const {t} = useTranslations('support', locale);

    return <Title
        title={t('view_title', {id: id})}
        breadcrumbs={[
            {href: "/support", label: t('list')}
        ]} {...props}>
        <ViewContainer {...props} id={id}/>
    </Title>
}

export default Ticket;
