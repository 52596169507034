import React, {useMemo} from "react";
import NoResultsComponent from "app/animals/components/list/implementations/NoResultsComponent";
import RunningListEntryComponent from "app/animals/components/list/implementations/runningList/RunningListEntryComponent";
import {useTranslation} from "react-i18next";
import {AnimalStatus} from "types/Animal";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import "app/animals/assets/css/running-list.scss";
import "app/animals/assets/css/generic.scss";

const RunningListComponent = (props) => {

    const {t} = useTranslation("animal");

    const {
        data,
        onPageSort,
        loading,
        hiddenFields = {},
        onCheckBoxChange,
        checkboxSelectedItems,
        searchFilter
    } = props;

    const isNonNormalAnimalPresent = useMemo(
        () => data?.content?.find(v =>
            AnimalStatus.NORMAL !== v.status || !v.isDataValid || v.hasPendingOwnerTransfer || v.isLocked
        ),
        [data?.content]
    );

    return <>
        <TableAdvanced
            hover
            sort={data?.sortBy}
            order={data?.sortOrder?.toLowerCase()}
            onSortClick={onPageSort}
            loading={(loading || !data)}>
            <thead>
            <tr>
                {onCheckBoxChange &&
                <th className={"table-checkbox"}/>
                }

                <th className={"d-none d-sm-table-cell"} width={"40px"}/>

                <th className={"d-none d-sm-table-cell"}>
                    {t('id')}
                </th>

                <th>
                    {t('transponder')}
                </th>

                <th className={"d-none d-lg-table-cell"}>
                    {t('breed_and_dates')}
                </th>

                <th className={"d-none d-lg-table-cell"}>
                    {t('location')}
                </th>
                
                {isNonNormalAnimalPresent &&
                <th className={"d-none d-lg-table-cell"}>
                    {t('status')}
                </th>
                }

                <th/>

            </tr>
            </thead>
            <tbody>

            {data?.content?.map((animal, index) =>
                <RunningListEntryComponent
                    {...props}
                    key={index}
                    animal={animal}
                    onCheckBoxChange={onCheckBoxChange}
                    checkboxSelectedItems={checkboxSelectedItems}
                    hiddenFields={hiddenFields}
                    isNonNormalAnimalPresent={isNonNormalAnimalPresent}
                />
            )}
            </tbody>
        </TableAdvanced>
        <NoResultsComponent {...props} data={data?.content} searchQuery={searchFilter?.universalSearch}
                            loading={loading}/>
    </>;

}

export default RunningListComponent;
