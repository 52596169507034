import {OwnerType} from "types/Onwer";


const vatCountries = [
    'AL', 'AD', 'BE', 'BR', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE',
    'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU',
    'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'RU', 'RS', 'SK', 'SI',
    'ES', 'SE', 'CH', 'GB', 'AO', 'ID',
];

const isVatValidated = (country) => vatCountries.includes(country);

const cleanVatNumber = (owner, vatNumber) => owner.type !== OwnerType.TRACES
    ? vatNumber?.trim()
    : vatNumber


export {isVatValidated, vatCountries, cleanVatNumber};
