import AuditLogComponent from "app/personalArea/components/AuditLogComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import locale from "app/audit/locales/locales";
import React, {useMemo} from "react";

const AuditLogContainer = props => {

    return <GenericListContainer
        module="audit"
        searchPath={"/audit/search-own"}
        hideTitle={true}
        locale={locale}
        queryParameters={useMemo(() => ({
            sortProperty: 'timestamp',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 15,
        }), [])}>

        <AuditLogComponent {...props}/>

    </GenericListContainer>

}

export default AuditLogContainer;
