import {useGet, useMutate} from "system/Rest/Rest";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useMemo, useState} from "react";
import useErrorParser from "system/API/Error/ErrorParser";
import TrainingComponent from "app/owners/components/view/TrainingComponent";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";
import {useLocation} from "react-router";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";

const TrainingContainer = (props) => {

    const {id} = props;
    const showToast = useShowToast();
    const {state} = useLocation();

    const {t} = useTranslation('owner');
    const [training, setTraining] = useState(null);

    const {data: remoteTraining, loading} = useGet({
        path: "/owners/" + id + "/training",
        queryParams: useMemo(
            () => ({transponder: state?.animal}),
            [state?.animal]
        ),
        resolve: (data) => {
            if (!data) {
                return {}
            }
            return data;
        }
    });

    const {mutate: submit, loading: submitting, error, refetch} = useMutate({
        verb: "PUT",
        path: "/owners/" + id + "/training",
    });

    const {mutate: del, loading: deleting} = useMutate({
        verb: "DELETE",
        path: "/owners/" + id + "/training",
    });

    useEffect(() => {
        setTraining(remoteTraining);
    }, [remoteTraining]);

    const errors = useErrorParser(error);

    return <TrainingComponent
        {...props}
        errors={errors}
        training={training}
        reload={refetch}
        loading={loading || submitting || deleting}
        onDelete={useCallback(() => {
            del().then(() => {
                showToast({
                    title: t('SUCCESS_DEL_TRAINING'),
                    message: t('SUCCESS_DEL_TRAINING_MSG'),
                    icon: faCheck,
                });
                setTraining({});
            })
        }, [setTraining, showToast, t,del])}

        onSubmit={useCallback((training) => {
            submit(training)
                .then((training) => {
                    setTraining(training);
                    refetch();
                    showToast({
                        title: t('SUCCESS_TRAINING'),
                        message: t('SUCCESS_TRAINING_MSG'),
                        icon: faCheck,
                    });
                })
                .catch(e => {
                    handleUnknownFormError(e, showToast, t)
                });
        }, [showToast, setTraining, t,submit, refetch])}

    />


}

export default TrainingContainer;
