import {AggressionMap, AggressionRegisterType} from "../../../types/Aggression";

const aggressionAuditResolver = (aggression) => ({
    ...aggression,
    registerType: aggression.clearRegistry
        ? AggressionRegisterType.REGISTER_CLEAR
        : AggressionRegisterType.REGISTER_AGGRESSION,
    aggressionSubType: aggression?.aggressionType ? getAggressionSubType(aggression) : undefined


});

const getAggressionSubType = (aggression) => {
    const map =  Object.keys(AggressionMap[aggression?.aggressionType]);
    return map[aggression.aggressionSubType];
}

export {aggressionAuditResolver};
