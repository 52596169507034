import {useLocation, useNavigate} from "react-router";
import {useMutate} from "system/Rest/Rest";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import isFunction from "system/Utils/isFunction";

const useGenericFormSubmit = (props) => {

    const {
        id, apiBasePath, apiFixedPath, redirectBasePath, redirectEditPath,
        translation, onSuccess, resolve, reload
    } = props;

    const apiPath = apiFixedPath ? apiFixedPath : apiBasePath + (id ? id : '');

    const [loading, setLoading] = useState(false);

    const {mutate: submit, loading: isLoading, error} = useMutate({
        verb: id ? "PUT" : "POST",
        path: apiPath,
        resolve: resolve,
    });

    const onSubmit = useGenericHandleSubmit({
        id: id,
        submit: submit,
        redirectBasePath: redirectBasePath,
        redirectEditPath: redirectEditPath,
        translation: translation,
        setLoading: setLoading,
        onSuccess: onSuccess,
        reload: reload,
    });

    //Update only loading on true
    useEffect(() => {
        if (isLoading)
            setLoading(true);
    }, [isLoading])

    return {onSubmit, loading, error}
}

const useGenericHandleSubmit = (props) => {

    const {
        submit,
        id,
        redirectBasePath,
        redirectEditPath,
        translation = 'global',
        setLoading,
        onSuccess,
        reload
    } = props

    const {t} = useTranslation(translation);
    const navigate = useNavigate();
    const showToast = useShowToast();
    const location = useLocation();

    return useCallback((document) => {
        submit(document)
            .then((response) => {

                if (onSuccess) {
                    onSuccess();
                }

                if (!id) {

                    setLoading(false);

                    showToast({
                        title: t('CREATE_SUCCESS'),
                        message: t('CREATE_SUCCESS_MSG', {name: response?.name}),
                        icon: faCheck,
                    });

                    navigate(isFunction(redirectBasePath)
                            ? redirectBasePath(response)
                            : redirectBasePath, {
                            replace: true,
                            state: {
                                create: true
                            }
                        }
                    );

                } else {

                    let ePath = isFunction(redirectEditPath) ? redirectEditPath(response) : redirectEditPath;
                    if (!ePath) {
                        ePath = isFunction(redirectBasePath) ? redirectBasePath(response) : redirectBasePath + id
                    }

                    setLoading(false);

                    showToast({
                        title: t('UPDATE_SUCCESS'),
                        message: t('UPDATE_SUCCESS_MSG', {name: response?.name}),
                        icon: faCheck,
                    });

                    if (ePath !== location?.pathname) {
                        navigate(ePath, {replace: true});
                    } else if (isFunction(reload)) {
                        reload();
                    }


                }
            })
            .catch(e => {
                setLoading(false);
                handleUnknownFormError(e, showToast, t);
            });

    }, [t, navigate, showToast, id, redirectBasePath, redirectEditPath, setLoading, onSuccess, submit, location, reload]);

}


const handleUnknownFormError = (e, showToast, t) => {

    if (e.data?.message) {
        showToast({
            title: t('FORM_ERROR'),
            message: t(e.data.message),
            icon: faTimes,
            className: 'error',
        })
    }
}

export {handleUnknownFormError}
export default useGenericFormSubmit;
