import BoxTitle from "../../../../layout/modules/Box/components/BoxTitle";
import React from "react";
import Box from "../../../../layout/modules/Box/Box";
import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "../../../../layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrEmpty} from "../../../../system/Objects/ObjectParameters";
import DatePicker from "../../../../layout/modules/Forms/DatePicker/DatePicker";

const DeathDateComponent = (props) => {

    const {t} = useTranslation('animal');
    const {animal, errors, changes} = props;

    return <Box>
        <BoxTitle>{t('date_of_death')}</BoxTitle>
        <Row>
            <Col sm={12} xl={6}>
                <FormGroup error={errors['deathDate']} changes={changes['deathDate']}>
                    <Form.Label>{t('date_of_death')}</Form.Label>
                    <DatePicker
                        timezone={"UTC"}
                        placeholder={t("date_ph")}
                        onlyPastDates={true}
                        disabled={true}
                        value={getOrEmpty(animal?.deathDate)}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['deathDate'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Row>
    </Box>
}

export default DeathDateComponent;
