import Box from "layout/modules/Box/Box";
import ListContainer from "app/animals/containers/ListContainer";

const MainListComponent = (props) => {

    return <Box className={"animal-list"}>
        <ListContainer/>
    </Box>


}

export default MainListComponent;
