import React, {useCallback, useState} from "react";
import MergeFormContainer from "../../../containers/MergeFormContainer";

const MergeComponent = props => {

    const [isVisible, setLostVisible] = useState(false);
    const handleClose = useCallback(() => setLostVisible(false), [setLostVisible]);
    const handleVisible = useCallback(() => setLostVisible(true), [setLostVisible]);

    return <>
        {props.children && props.children({
            setVisible: handleVisible,
            active: isVisible,
        })}
        {isVisible &&
        <MergeFormContainer {...props} onClose={handleClose}/>
        }
    </>

}

export default MergeComponent;
