import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import SetStateModalComponent from "app/control/components/show/SetStateModalComponent";
import ValidationStatus from "types/ValidationStatus";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import Loading from "layout/modules/Loading/Loading";
import "app/owners/assets/css/review.scss";
import ControlSupportContainer from "app/support/containers/ControlSupportContainer";
import {TicketProcessType} from "types/TicketProcessType";

const ReviewComponent = props => {

    const {id, t, onAccept, onTicket, loading} = props;

    const [isMoreInformationVisible, setMoreInformationVisible] = useState(false);


    return <UserHasPermission permission={["OWNER_REVIEW"]}>

        <div className={"owner-owner-review"}>
            <Loading visible={loading}/>
            {isMoreInformationVisible &&
            <SetStateModalComponent
                t={t}
                setShow={setMoreInformationVisible}
                state={ValidationStatus.MORE_INFORMATION_NEEDED}
                onHide={() => setMoreInformationVisible(false)}
                onChangeState={(type, message) => onTicket(message)}
                reasonsUrl={"/owner-review/reasons"}
            />
            }

            <AdvanceInfoMessage
                icon={faCheckDouble}
                type={"warning"}
                iconType={"warning"}
                title={t("owner_in_review")}
            >
                <div className={"review-alert"}>
                    <div className={"message"}>
                        {t('owner_in_review_msg')}
                    </div>
                    <div className={"buttons"}>
                        <Button variant="primary" onClick={onAccept}>
                            {t("accept")}
                        </Button>

                        {id &&
                        <Button variant="warning" onClick={() => setMoreInformationVisible(true)}>
                            {t("more_information")}
                        </Button>
                        }

                    </div>

                </div>
            </AdvanceInfoMessage>

            <ControlSupportContainer
                processType={TicketProcessType.OWNER_REVIEW}
                id={id}/>

        </div>

    </UserHasPermission>
}

export default ReviewComponent
