import React, {useMemo} from "react";
import {useBillingBalanceState} from "modules/Billing/BillingStateContext";
import Unauthorized from "layout/modules/ErrorPages/Unauthorized";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/control/modules/preRegister/locales/locales";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";
import {AnimalStateProvider} from "app/animals/utils/AnimalContext";
import CreateContainer from "app/control/modules/preRegister/containers/CreateContainer";
import {getEmptyAnimal} from "app/animals/utils/AnimalResolver";
import {useLocation} from "react-router";


const Create = (props) => {

    const {t} = useTranslations('preRegister', locale);
    const billingState = useBillingBalanceState();

    const location = useLocation();

    const animal = useMemo(
        () => ({
            ...getEmptyAnimal(location?.state?.transponder),
            documents: [],
        }),
        [location?.state?.transponder]
    );

    if (!billingState.canRegisterAnimal && !billingState.canRegisterNotBillableAnimal) {
        return <Unauthorized {...props}/>
    }

    return <Title
        title={t('pre_register_title')}
        breadcrumbs={[
            {href: "/animal", label: t('list')},
            {href: "/animal/pre-register", label: t('pre_register')},
        ]} {...props}>

        <AnimalStateProvider animal={animal}>
            <CreateContainer/>
        </AnimalStateProvider>
    </Title>
}

export default Create;
