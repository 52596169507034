import CompareComponent from "./components/CompareComponent";
import {useGet} from "system/Rest/Rest";

const VersionsContainer = (props) => {

    const {visible, path, children} = props;

    const {data: versions, loading} = useGet({
        path: path,
        lazy: !visible,
    });

    return <CompareComponent
        {...props}
        loading={loading}
        versions={versions}
        children={children}
    />
}

export default VersionsContainer;
