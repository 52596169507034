import React, {useCallback, useState} from 'react'
import './assets/css/search-area.scss'
import './assets/css/search-area-responsive.scss'

import {faFilter} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import SearchControl from "./components/SearchControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";

const SearchArea = (props) => {

    const {hideInput, value, onSearch, children, liveSearch,  ...otherProps} = props;

    const isSearchFilterPresent = (!!children?.type);
    const [isOpen, setOpen] = useState(false);

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                onSearch(e.target.value)
            }
        },
        [onSearch]
    );

    const handleBlur = useCallback(
        (e) => { onSearch(e.target.value)},
        [onSearch]
    );

    const handleSearch = useCallback(
        (e) => {
            if (liveSearch) {
                onSearch(e.target.value);
            }
            else if (e.target.value === "") {
                onSearch("");
            }
        },
        [onSearch, liveSearch]
    );

    return <div className="search-area">
        <div className="search-area-bar">

            {!hideInput &&
            <SearchControl
                {...otherProps}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                onChange={handleSearch}
                defaultValue={getOrEmpty(value)}
            />
            }

            {isSearchFilterPresent &&
            <div
                className={isOpen ? "btn-rounded-primary active" : "btn-rounded-primary"}
                onClick={() => setOpen(!isOpen)}>
                <FontAwesomeIcon icon={faFilter}/>
            </div>
            }

        </div>

        {isSearchFilterPresent && React.cloneElement(children, {
                isOpen: isOpen,
                onClose: () => setOpen(false),
            }
        )}

    </div>

}

export default SearchArea;
