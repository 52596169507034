import React from "react";

const MenuCounterComponent = props => {

    const {counter} = props;

    if (counter && counter > 0) {
        return <div className={"counter"}>
            {counter > 100 ? "100+" : counter}
        </div>
    }

    return <></>

}

export default MenuCounterComponent;
