import {useGet} from "system/Rest/Rest";
import React from "react";
import animalResolver from "app/animals/utils/AnimalResolver";
import TransferAnimalContainer from "app/control/modules/transfers/containers/TransferAnimalContainer";
import locale from "app/control/modules/transfers/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";
import Loading from "layout/modules/Loading/Loading";
import {useParams} from "react-router-dom";

const TransferAnimal = (props) => {

    const {t} = useTranslations('transfer', locale);
    const {id} = useParams();

    const {data: animal, loading} = useGet({
        path: "/animals/" + id,
        resolve: animalResolver,
    });

    return <Title title={t('change_owner', {id: id})}
                  breadcrumbs={[{href: "/animal", label: t('list')}, {href: "/animal/view/" + id, label: id}]}
                  {...props}>

        {animal
            ? <TransferAnimalContainer {...props} id={id} animal={animal} loading={loading}/>
            : <Loading visible={true}/>
        }
    </Title>

}
export default TransferAnimal;
