import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrEmpty} from "system/Objects/ObjectParameters";

const DateComponent = props => {

    const {value, setValue, parameter, error, readOnly, t} = props;

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <DatePicker
                disabled={readOnly}
                onlyPastDates={true}
                timezone={"UTC"}
                placeholder={t("date_ph")}
                value={getOrEmpty(value)}
                onChange={setValue}
            />
            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default DateComponent;
