const EntitySituation = {
    OPEN: "OPEN",
    CLOSED: "CLOSED",
    TRANSFERRED: "TRANSFERRED"
};


const EntityType = {
    A1: "A1",
    A2: "A2",
    B: "B",
    C: "C",
    D: "D",
    E: "E",
    F: "F",
}

export {EntitySituation, EntityType};
