import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/animals/locales/locales";

const LockedAlert = props => {

    const {animal} = useAnimalState();
    const {t} = useTranslations("animal", locale);

    if (!animal?.isLocked)
        return <></>


    return <AdvanceInfoMessage
        icon={faLock}
        type={"danger"}
        iconType={"danger"}
        title={t("animal_is_locked")}
        message={t('animal_is_locked_msg')}
    />
}

export default LockedAlert;
