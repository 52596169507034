import React, {useCallback, useMemo} from 'react'
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import getAsArray from "system/Arrays/getArray";

const BreedDropdown = (props) => {

    const {groupType, excludeId, parentId, onlyRootBreeds, includeParentOnLabel = false} = props

    return <GenericRemoteClassDropdown
        {...props}
        remotePath={"/breeds/search-breeds"}
        universalSearch={"name"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: 50,
            groupTypes: getAsArray(groupType),
            parentIds: getAsArray(parentId),
            onlyRootBreeds: onlyRootBreeds,
        }), [groupType, parentId, onlyRootBreeds])}

        searchResponseSolver={useCallback(
            (data) => (excludeId)
                ? ({
                    ...data,
                    content: data?.content?.filter(e => e.id !== excludeId)
                })
                : data,
            [excludeId]
        )}
        labelHandler={useCallback(
            o => includeParentOnLabel ?
                <>
                  {o.name}
                    &nbsp;
                    (<i>{o.parent?.name}</i>)
                </>
                : o.name,
            [includeParentOnLabel]
        )}
    />

}

export default BreedDropdown;
