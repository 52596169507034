import {useCallback, useEffect, useState} from "react";

const useCurrentURL = () => {

    const [, setPath] = useState(window.location.href);

    const listenToPopstate = useCallback(
        () => {
            setPath(window.location.href);
        },
        [setPath]
    );


    useEffect(
        () => {
            window.addEventListener("popstate", listenToPopstate);
            return () => {
                window.removeEventListener("popstate", listenToPopstate);
            };
        },
        // eslint-disable-next-line
        []
    );

    return window.location.href;
};

export default useCurrentURL;
