import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmptySafe} from "system/Objects/ObjectParameters";
import TagInput from "layout/modules/Forms/Tags/TagInput";


const TextComponent = props => {

    const {value, setValue, parameter, error, readOnly, onlyNumeric} = props;

    const type = onlyNumeric ? "number" : "text";
    const handleChangeTag = useCallback((v) => setValue(v), [setValue]);
    const handleChangeInput = useCallback((e) => {
        if (onlyNumeric && Number.isInteger(parseInt(e.target.value))) {
            setValue(parseInt(e.target.value))
        } else {
            setValue(e.target.value);
        }
    }, [setValue, onlyNumeric])

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>

            {parameter?.multipleValues
                ? <TagInput
                    type={type}
                    disabled={readOnly}
                    value={getOrEmptySafe(value)}
                    onChange={handleChangeTag}
                />
                : <FormControl
                    type={type}
                    disabled={readOnly}
                    value={getOrEmptySafe(value)}
                    onChange={handleChangeInput}
                />
            }
            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default TextComponent;
