import Box from "layout/modules/Box/Box";
import Show from "app/control/modules/transfers/Show";


const AnimalTransferComponent = (props) => {

    const {operation} = props;

    return <Box>
        <Show id={operation.objectId} embedded={true}/>
    </Box>
}

export default AnimalTransferComponent;
