const BoxScrollComponent = (props) => {

    const {children, height='300px', disabled=false} = props;

    const style = {
        height: height,
    }

    if (disabled) {
        return children;
    }

    return <div className="box-scrolled-content" style={style}>
        {children}
    </div>


}

export default BoxScrollComponent;
