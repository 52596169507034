import {AnimalStatus} from "types/Animal";
import ValueLabelComponent from "app/animals/components/view/panel/ValueLabelComponent";
import classnames from "classnames";
import SeparatorComponent from "app/animals/components/view/panel/SeparatorComponent";
import canLicenseAnimal from "app/licensing/utils/canLicenseAnimal";
import {useDateTime} from "system/DateTime/DateTime";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

const StatusInfoComponent = props => {

    const {animal} = props;
    const {t} = useTranslation("animal");

    const getDateTime = useDateTime("DD-MM-YYYY");
    const timestamp = useMemo(() => (new Date()).getTime(), []);

    if (animal?.status === AnimalStatus.DEAD)
        return <></>

    return <>
        <ValueLabelComponent label={t('anti_rabies')}>
            {animal?.rabiesImmunizationDateEnd
                ? <div className={classnames({
                    "text-success": timestamp < animal.rabiesImmunizationDateEnd,
                    "text-danger": timestamp >= animal.rabiesImmunizationDateEnd
                })}>
                    {t('rabies_until', {date: getDateTime(animal.rabiesImmunizationDateEnd, "UTC")})}
                </div>
                : <div className={"text-danger"}>
                    {t('no_anti_rabies')}
                </div>
            }
        </ValueLabelComponent>

        <SeparatorComponent/>

        <ValueLabelComponent label={t('sterilize')}>
            {animal?.isSterilized
                ? <div className={"text-success"}>
                    {t('is_sterilized')}
                </div>
                : <div className={"text-danger"}>
                    {t('not_sterilized')}
                </div>
            }
        </ValueLabelComponent>
        <SeparatorComponent/>

        {canLicenseAnimal(animal) &&
            <>
                <ValueLabelComponent label={t('licensing')}>
                    {animal?.isLicenseValid
                        ? <div className={"text-success"}>
                            {t('valid_license')}
                        </div>
                        : <div className={"text-danger"}>
                            {t('invalid_license')}
                        </div>
                    }

                </ValueLabelComponent>
                <SeparatorComponent/>
            </>

        }

    </>
}


export default StatusInfoComponent;
