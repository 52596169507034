import {Trans, useTranslation} from "react-i18next";
import classnames from "classnames";
import React, {useState} from "react";
import {useApiState} from "system/API/APIStateContext";
import Loading from "layout/modules/Loading/Loading";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";

const AssociatedEntitiesComponent = (props) => {

    const {
        data = [],
        loading,
        onDisassociate,
        onAccept,
        selectedEntity,
        login = true,
        onSelectEntity
    } = props;

    const {t} = useTranslation("login");
    const {authentication} = useApiState();

    const [deletePopup, setDeletePopup] = useState({});

    if (loading || !data) {
        return <Loading visible={true}/>
    }

    return <div className={classnames({"login-select-entity": login, "personal-area-entities": !login})}>

        <PopupConfirm
            title={t(deletePopup.msg)}
            message={t(deletePopup.msg + '_MSG', {name: deletePopup.entityName})}
            classConfirm={"danger"}
            visible={!!deletePopup.entityId}
            onCancel={() => {
                setDeletePopup({});
            }}
            onConfirm={() => {
                setDeletePopup({})
                if (onSelectEntity)
                    onSelectEntity(null);

                onDisassociate(deletePopup.entityId, deletePopup.value.status, {name: deletePopup.entityName});

            }}
        />


        {(data?.length === 0 || !data)
            ? <div className={"no-associated-entities text-danger"}>
                <Trans>{t('no_associated_entities')}</Trans>
            </div>
            : <>

                {login &&
                    <div className={"text-help"}>
                        {t('entity_choose_help')}
                    </div>
                }

                <TableAdvanced hover loading={loading}>
                    <thead>
                    <tr>
                        <th style={{width: "100%"}}>
                            {t('entity_name')}
                        </th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>

                    {data?.content?.map((value, index) =>
                        <tr key={index}>
                            {login
                                ? <td>
                                    <CheckBox
                                        radio={true}
                                        disabled={value.status !== "ACCEPTED"}
                                        value={selectedEntity === value.entity.id}
                                        onChange={(val) => val ? onSelectEntity(value.entity.id) : onSelectEntity(null)}
                                        label={<>

                                            {value.entity?.brand &&
                                                <div className={"entity-brand"}>{value.entity?.brand}</div>
                                            }

                                            {value.entity.name}
                                            {!value.entity.vatNumber &&
                                                <span className={"sub-entity-label"}>
                                                {t('sub_entity')}
                                            </span>
                                            }
                                        </>}
                                    />
                                </td>
                                : <td>
                                    {value.entity?.brand &&
                                        <div className={"entity-brand"}>{value.entity?.brand}</div>
                                    }
                                    {value.entity.name}
                                    {!value.entity.vatNumber &&
                                        <span className={"sub-entity-label"}>
                                        {t('sub_entity')}
                                    </span>
                                    }
                                </td>
                            }

                            <td className={classnames("table-text-option",
                                {
                                    "accepted": value.status === "ACCEPTED",
                                    "pending-veterinary": (value.status === "PENDING_VETERINARY_RESPONSE"),
                                    "pending-entity": (value.status === "PENDING_ENTITY_RESPONSE"),
                                    "clinical-director": value.isClinicalDirector,
                                })}>

                                {(value.status === "PENDING_ENTITY_RESPONSE") &&
                                    <>
                                        {t('pending')}
                                        <div onClick={() => setDeletePopup({
                                            msg: "CONFIRM_CANCEL",
                                            entityId: value.entity.id,
                                            entityName: value.entity.name,
                                            value: value
                                        })}>
                                            {t('cancel')}
                                        </div>
                                    </>
                                }

                                {(value.status === "ACCEPTED") &&
                                    <>
                                        {value.isClinicalDirector
                                            ? <div>{t('clinical_director')}</div>
                                            : <>
                                                {(authentication?.entity?.id !== value.entity.id)
                                                    ? <div onClick={() => setDeletePopup({
                                                        msg: "CONFIRM_REMOVE",
                                                        entityId: value.entity.id,
                                                        entityName: value.entity.name,
                                                        value: value
                                                    })}>
                                                        {t('remove_association')}
                                                    </div>
                                                    : <></>
                                                }
                                            </>
                                        }
                                    </>
                                }

                                {(value.status === "PENDING_VETERINARY_RESPONSE") &&
                                    <>
                                        <div className={"accept"}
                                             onClick={() => onAccept(value.entity.id, {name: value.entity.name})}>
                                            {t('accept')}
                                        </div>
                                        <div className={"reject"}
                                             onClick={() => setDeletePopup({
                                                 msg: "CONFIRM_REJECT",
                                                 entityId: value.entity.id,
                                                 entityName: value.entity.name,
                                                 value: value
                                             })}>
                                            {t('reject')}
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    )}
                    </tbody>
                </TableAdvanced>
            </>
        }

    </div>
}

export default AssociatedEntitiesComponent;
