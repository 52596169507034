import Form from "react-bootstrap/Form";
import React from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import Col from "react-bootstrap/Col";
import {parseBoolean} from "system/Utils/parseBoolean";

const OptionalComponent = props => {

    const {value, setValue, parameter, error, readOnly} = props;

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <BooleanDropdown
                isDisabled={readOnly}
                isClearable={true}
                isMulti={false}
                value={parseBoolean(value, false)}
                onChange={(value) => setValue(value ? true : undefined)}
            />
            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default OptionalComponent;
