import React, {useMemo} from "react";
import locale from "./locales/locales"
import ListComponent from "./components/ListComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import {useParams} from "react-router";

const FileList = (props) => {

    const {type, id, ownerAnimalTransponder} = props;
    const {fileUniversalSearch} = useParams();

    return <GenericListContainer
        {...props}
        id={"files-" + type + "-" + id}
        module="document"
        locale={locale}
        hideTitle={true}
        queryParameters={useMemo(() => ({
            documentType: type,
            documentTypeId: id,
            ownerAnimalTransponder: ownerAnimalTransponder,
            sortProperty: 'creationTimestamp',
            sortDirection: 'DESC',
            currentPage: 0,
            universalSearch: fileUniversalSearch,
            itemsPerPage: 10,
        }), [type, id, fileUniversalSearch, ownerAnimalTransponder])}>

        <ListComponent {...props} />

    </GenericListContainer>

}

export default FileList;
