import {useEffect} from "react";

const useFirstElementIfUnique = (array, fnc) => {
    useEffect(() => {
        if (array && array.length === 1) {
            fnc(array[0]);
        }
        // eslint-disable-next-line
    }, [array])
}

export default useFirstElementIfUnique;
