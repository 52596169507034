export const pt = {

    title: "Notificações",
    dashboard_title: "Notificações recentes",
    notifications: "Notificações",
    view_all: "Ver todas",

    ACCOUNT_CHARGE: "Carregamento da conta",
    ACCOUNT_CHARGE_MSG: "Recebeu um carregamento de créditos na sua conta.",

    ANIMAL_TRANSFER_ACCEPT: "Transmissão de titularidade",
    ANIMAL_TRANSFER_ACCEPT_MSG: "O pedido de transmissão de titularidade do animal {{additionalInfo}} foi <strong>aceite!</strong>",

    ANIMAL_TRANSFER_REJECT: "Transmissão de titularidade",
    ANIMAL_TRANSFER_REJECT_MSG: "A transmissão de titularidade do animal {{additionalInfo}} foi <strong>rejeitada!</strong>",

    ANIMAL_TRANSFER_MORE_INFO: "Pedido de informação",
    ANIMAL_TRANSFER_MORE_INFO_MSG: "Recebeu um pedido de mais informação para o processo relativo a transmissão de titularidade do animal <strong>{{additionalInfo}}</strong>",

    NEW_TICKET: "Resposta a pedido de suporte",
    NEW_TICKET_MSG: "Foi recebida uma nova mensagem de resposta ao pedido de suporte <strong>{{documentId}}</strong>",

    NEW_TRACEABILITY_TRANSACTION: "Rastreabilidade",
    NEW_TRACEABILITY_TRANSACTION_MSG: "Foi recebida uma transação de rastreabilidade com o id <strong>{{documentId}}</strong>.",

    ENTITY_ASSOCIATION_REQUEST: "Pedido de associação a veterinario",
    ENTITY_ASSOCIATION_REQUEST_MSG: "Foi recebido um pedido de associação à entidade por parte do M. Veterinário <strong>{{additionalInfo}}</strong>.",

    ENTITY_ASSOCIATION_ACCEPT: "Pedido de associação aceite",
    ENTITY_ASSOCIATION_ACCEPT_MSG: "O pedido de associação enviado ao M. Veterinário <strong>{{additionalInfo}}</strong> foi aceite!",

    VETERINARY_ASSOCIATION_REQUEST: "Pedido de associação a entidade",
    VETERINARY_ASSOCIATION_REQUEST_MSG: "Foi recebido um pedido de associação à entidade  <strong>{{additionalInfo}}</strong>. Para aceitar ou rejeitar carregue <strong>ver</strong> e escolha o <strong>separador entidades</strong>",

    VETERINARY_ASSOCIATION_ACCEPT: "Pedido de associação aceite",
    VETERINARY_ASSOCIATION_ACCEPT_MSG: "O pedido de associação enviado à entidade <strong>{{additionalInfo}}</strong> foi aceite.",

    REPORT: "Resultado de execução de relatório",
    REPORT_MSG: "Foi disponibilizado o resultado da execução do relatório <strong>{{documentId}}</strong> no seu arquivo pessoal com o nome <strong>{{additionalInfo}}</strong>.",

    REPORT_FAILED: "Relatório falhou",
    REPORT_FAILED_MSG: "Não foi possível executar o relatório agendado <strong>{{documentId}}</strong>. Por favor tente mais tarde ou contacte o serviço de suporte.",


    OPERATION_RUN: "Operação executada",
    OPERATION_RUN_MESSAGE: "Foi disponibilizado o resultado da operação <strong>{{documentId}}</strong> no seu arquivo pessoal com o nome <strong>{{additionalInfo}}</strong>.",

    OPERATION_FAILED: "Erro em operação",
    OPERATION_FAILED_MSG: "Ocorreu um erro ao executar a operação  <strong>{{documentId}}</strong>",


    ANIMAL_PRE_REGISTER_REJECT: "Pré-registo rejeitado",
    ANIMAL_PRE_REGISTER_REJECT_MSG: "O pré registo do animal <strong>{{additionalInfo}}</strong> foi <strong>rejeitado</strong> pelos serviços SIAC.",

    ANIMAL_PRE_REGISTER_ACCEPT: "Pré-registo aceite",
    ANIMAL_PRE_REGISTER_ACCEPT_MSG: "O pré registo do animal <strong>{{additionalInfo}}</strong> foi <strong>aceite</strong> e o animal já se encontra registado no SIAC",

    ANIMAL_PRE_REGISTER_MORE_INFO: "Pedido de informação",
    ANIMAL_PRE_REGISTER_MORE_INFO_MSG: "Recebeu um pedido de mais informação para o pré registo do animal com o transponder <strong>{{additionalInfo}}</strong>",



    no_results: "Não existem notificações para apresentar"
};

export default pt;
