const AnimalFoundStatus = {
    SHELTER: "SHELTER",
    STREET: "STREET",
    KENNEL: "KENNEL",
    CAMV: "CAMV",
    WITH_PARTICIPANT: "WITH_PARTICIPANT",
    OTHER: "OTHER",
};

export default AnimalFoundStatus;
