import React, {useState} from "react";
import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import {useTranslation} from "react-i18next";
import FormContainer from "app/owners/containers/form/FormContainer";
import TrainingContainer from "app/owners/containers/training/TrainingContainer";
import AnimalListComponent from "./AnimalListComponent";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {OwnerType} from "types/Onwer";
import ManageOwnerAccessContainer from "app/owners/containers/view/ManageOwnerAccessContainer";
import {UserType} from "types/Users";
import {useLocation} from "react-router";
import FileList from "app/files/List";
import DocumentType from "types/Documents";
import OwnerTicketsContainer from "app/owners/containers/view/OwnerTicketsContainer";
import OwnerAlertsComponent from "app/owners/components/view/OwnerAlertsComponent";

const ViewComponent = (props) => {

    const {t} = useTranslation('owner');
    const {state} = useLocation();

    const {owner} = props;

    const userHasPermission = useUserHasPermission();
    const [isInEditMode, setEditMode] = useState(false);


    return <div className={"view-entity limited-width"}>

        <OwnerAlertsComponent {...props} owner={owner} t={t}/>

        <Tabs defaultActiveKey="main" id="tab-owner" unmountOnExit={true} mountOnEnter={true}>
            <Tab eventKey="main" title={t('general')}>
                <FormContainer {...props} viewMode={true} onEditMode={setEditMode}/>
            </Tab>

            <Tab eventKey="training" title={t('training')} disabled={isInEditMode}>
                <TrainingContainer {...props} />
            </Tab>

            {userHasPermission({permission: 'READ_ANIMAL', orUserTypes: UserType.VETERINARY}) &&
            <Tab eventKey="animals" title={t('animals')} disabled={isInEditMode}>
                <AnimalListComponent {...props}/>
            </Tab>
            }

            {userHasPermission({permission: ['OWNER_REVIEW', 'READ_OWNER_MERGE']}) &&
            <Tab eventKey="tickets" title={t('owners_tickets')} disabled={isInEditMode}>
                <OwnerTicketsContainer {...props}/>
            </Tab>
            }

            {((owner.type === OwnerType.COLLECTIVE) && userHasPermission({permission: 'MANAGE_OWNER_USERS'})) &&
            <Tab eventKey="account" title={t('owners_account')} disabled={isInEditMode}>
                <ManageOwnerAccessContainer {...props}/>
            </Tab>
            }


            <Tab eventKey="files" title={t('files')} disabled={isInEditMode}>
                <FileList id={owner?.id} type={DocumentType.OWNER} ownerAnimalTransponder={state?.animal}/>
            </Tab>


        </Tabs>
    </div>
}

export default ViewComponent;
