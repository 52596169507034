import Modal from "react-bootstrap/Modal";
import React from "react";
import {useTranslation} from "react-i18next";
import TransferAnimalContainer from "app/control/modules/transfers/containers/TransferAnimalContainer";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/control/modules/transfers/locales/locales";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faInfo} from "@fortawesome/free-solid-svg-icons";

const ClosedAsTransferredComponent = props => {

    const {t} = useTranslation("animal");
    useTranslations('transfer', locale);

    const {type, handleClose} = props;

    return <Modal show={true} onHide={handleClose} centered backdrop={true} className={"dark-background"} size={"xl"}>
        <Modal.Header closeButton={true}>
            <Modal.Title>{t('LOST_ANIMAL_CLOSE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <AdvanceInfoMessage
                icon={faInfo}
                type={"info"}
                iconType={"info"}
                title={t(type.toLowerCase())}
                message={t('close_lost_with_transfer_msg', {
                    type: t(type.toLowerCase())
                })}
            />
            <TransferAnimalContainer
                {...props}
                transferProcessExtraData={{
                    lostAnimalStatus: type,
                }}

                /** id={id} animal={animal} loading={loading} *//>

        </Modal.Body>

    </Modal>
}

export default ClosedAsTransferredComponent;
