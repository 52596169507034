import {Link} from "react-router-dom";
import {AuditObjectTypes} from "app/audit/utils/AuditObjectTypes";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React from "react";

const DescriptionComponent = (props) => {

    const {value} = props;

    const objectType = value.affectedObjectType ? value.affectedObjectType : value.objectType;
    const objectId = value.affectedObjectType ? value.affectedObjectId : value.objectId;

    switch (objectType) {
        case AuditObjectTypes.Owner:
            return <Link to={"/owner/" + value.owner?.id} target={"_blank"}>
                {getOrEmpty(value.owner?.name)}
            </Link>
        case AuditObjectTypes.Animal:
            return <Link to={"/animal/view/" + objectId} target={"_blank"}>
                {getOrEmpty(objectId)}
            </Link>
        case AuditObjectTypes.User:

            if (value.user?.ballotNumber) {
                return <Link to={"/veterinary/byBallot/" + value.user?.ballotNumber} target={"_blank"}>
                    {getOrEmpty(value.user?.name)}
                </Link>
            } else {
                return <Link to={"/user/" + objectId} target={"_blank"}>
                    {getOrEmpty(value.user?.name)}
                </Link>
            }
        case AuditObjectTypes.Veterinary:
            return <Link to={"/veterinary/" + objectId} target={"_blank"}>
                {getOrEmpty(value.veterinary?.name)}
            </Link>
        case AuditObjectTypes.UserRole:
            return getOrEmpty(value.role?.name);
        case AuditObjectTypes.RabiesVaccine:
            return getOrEmpty(value.vaccine?.name);
        case AuditObjectTypes.EntityProfile:
            return getOrEmpty(value.entityProfile?.name);
        case AuditObjectTypes.Breed:
            return getOrEmpty(value.breed?.name);
        case AuditObjectTypes.Entity:
            return value.entity?.name
                ? <Link to={"/entity/" + objectId} target={"_blank"}>
                    {getOrEmpty(value.entity?.name)}
                </Link>
                : "";
        case AuditObjectTypes.Operation:
        case AuditObjectTypes.OperationSchedule:
            return getOrEmpty(value.operation?.name);
        case AuditObjectTypes.Report:
        case AuditObjectTypes.ReportSchedule:
            return getOrEmpty(value.report?.name);
        case AuditObjectTypes.OperationTemplate:
        case AuditObjectTypes.ReportTemplate:
            return getOrEmpty(value.objectRaw?.name);
        case AuditObjectTypes.AnimalDeathRequest:
            return <Link to={"/request-death/" + objectId} target={"_blank"}>
                {getOrEmpty(value.objectRaw?.transponder)}
            </Link>
        case AuditObjectTypes.OwnerDataUpdate:
            return <Link to={"/owner-data/" + objectId} target={"_blank"}>
                {getOrEmpty(value.objectRaw?.name)}
            </Link>
        case AuditObjectTypes.OwnerMergeReport:
            return <Link to={"/owner-merges/" + objectId} target={"_blank"}>
                {getOrEmpty(value?.objectRaw?.newName)}
            </Link>
        case AuditObjectTypes.HerdingDog:
            return <Link to={"/herding-dog/" + objectId} target={"_blank"}>
                {getOrEmpty(value?.objectRaw?.transponder)}
            </Link>
        default:
            return objectId ? objectId : "";

    }


}

export default DescriptionComponent;
