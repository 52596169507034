export const pt = {


    response_model: "Modelo do pedido",
    response: "Mensagem do pedido",

    CONFIRM_DELETE_FILE: "Apagar documento do pedido",
    CONFIRM_DELETE_FILE_MSG: "Deseja realmente apagar o documento <strong>\"{{title}}\"</strong>?",

    DELETE_SUCCESS: "Sucesso",
    DELETE_SUCCESS_MSG: "O ficheiro <strong>\"{{title}}\"</strong> foi apagado com sucesso",

};

export default pt;
