import {useAnimalState} from "app/animals/utils/AnimalContext";
import useTranslations from "system/Translations/UseTranslations";
import React, {useRef} from "react";
import Box from "layout/modules/Box/Box";
import BallComponent from "app/animals/components/generic/BallComponent";
import AnimalFormDataComponent from "app/control/modules/preRegister/components/form/AnimalFormDataComponent";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import locale from "app/control/modules/preRegister/locales/locales";

const AnimalFormComponent = props => {

    const {t} = useTranslations('preRegister', locale);

    const photoUploadRef = useRef(null);
    const {animal} = useAnimalState();

    const {onSubmit, readOnly} = props;


    return <div className={"create-animal"}>

        <div className={"main-panel"}>

            <AnimalFormDataComponent
                {...props}
                photoUploadRef={photoUploadRef}
            />

            {!readOnly &&
            <Row>
                <Col className={"text-end"}>
                    <Button variant="primary" className={"submit"} onClick={() => onSubmit(animal)}>
                        {t("submit_pre_request")}
                    </Button>
                </Col>
            </Row>
            }

        </div>

        <div className={"right-panel"}>
            <BallComponent
                editMode={!readOnly}
                disableChange={true}
                animal={animal}
                hideStateBorder={!readOnly}
                photoUploadRef={photoUploadRef}
            />

            {!readOnly &&
            <Box>
                <div className={"upload-label"} onClick={() => photoUploadRef.current.showPhotoManagement()}>
                    {t('upload_photo')}
                </div>

            </Box>
            }
        </div>
    </div>


}

export default AnimalFormComponent;
