import {useMemo} from "react";
import useTranslations from "../../../../../system/Translations/UseTranslations";
import locale from "../locales/locales";

const useDefaultProps = (props) => {

    const {t, ready} = useTranslations('classDropdown', locale);

    const labels = useMemo(
        () => ({
            placeholder: t('generic_ph'),
            noOptionsMessage: () => t('generic_no'),
            loadingMessage: () => t('generic_loading'),
        }),
        // eslint-disable-next-line
        [t, ready]
    );

    const {
        isDisabled = false,
        id,
        onChange,
        placeholder = labels.placeholder,
        noOptionsMessage = labels.noOptionsMessage,
        loadingMessage = labels.loadingMessage,
        remotePath,
        remoteClassPath = remotePath,
        searchFilter = {},
        classValue = "id",
        classLabel = "name",
        labelHandler,
        valueHandler,
        value,
        defaultValue,
        universalSearch = "universalSearch",
        isMulti = false,
        defaultSelectedOption,
        searchResponseSolver
    } = props;

    return {
        loadingMessage, remotePath, remoteClassPath, searchFilter,
        id, isDisabled, onChange, placeholder, noOptionsMessage,
        defaultValue, universalSearch, isMulti, defaultSelectedOption,
        classValue, classLabel, labelHandler, valueHandler, value,
        searchResponseSolver
    }

}
export default useDefaultProps
