import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Loading from "layout/modules/Loading/Loading";
import {ToastError, ToastSuccess} from "layout/modules/NotificationArea/components/ToastComponent";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import useHandleFormSubmit from "system/Forms/FormHandling";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/login/locales/locales";
import HelpContainer from "app/login/containers/HelpContainer";


const SIACLoginComponent = (props) => {

    const {t, ready} = useTranslations('login', locale);
    const {loading, error, onLogin, state, sessionExpired} = props;
    const {passwordResetSuccess, forgotSuccess, invalidToken} = state;

    const handleLoginSubmit = useHandleFormSubmit(onLogin);
    const errorMessage = error?.data?.message;

    return <div className={"login-content-area siac-login-content-area"}>

        <Loading visible={loading}/>

        <Row className={["justify-content-center"]}>
            <Col className={["login-form-area"]}>

                {ready &&
                    <>
                        <ToastSuccess message={t('password_reset_success')}
                                      visible={passwordResetSuccess ? true : false}/>
                        <ToastSuccess message={t('password_forgot_success')}
                                      visible={forgotSuccess ? true : false}/>

                        <ToastError message={t('error_recovery_token')} visible={invalidToken ? true : false}/>

                        <ToastError title={t('error_authenticating')} message={t(errorMessage)}
                                    visible={error ? true : false}/>

                        <ToastError title={t('session_expired')} message={t('session_expired_msg')}
                                    visible={sessionExpired ? true : false}/>
                    </>
                }

                <Form onSubmit={(data) => handleLoginSubmit(data)}>
                    <FormGroup error={error}>
                        <Form.Label>{t('email')}</Form.Label>
                        <FormControl type="email" id="email" placeholder={t('email_tip')} autoComplete={"username"}/>
                    </FormGroup>

                    <FormGroup error={error}>
                        <Form.Label>{t('password')}</Form.Label>
                        <FormControl type="password" id="password" placeholder={t('password_tip')}
                                     data-openreplay-obscured autoComplete={"current-password"} />
                        <Form.Text className="sub-error">{t(errorMessage)}</Form.Text>
                    </FormGroup>

                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button variant="primary" type="submit">
                                {t('login')}
                            </Button>

                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
        <div className={"login-siac-bottom"}>

            <div>
                <Link to="/auth/recovery">
                    {t('password_recovery_title')}
                </Link>
            </div>
            <HelpContainer/>
        </div>
    </div>

}

export default SIACLoginComponent;
