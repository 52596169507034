import React, {useEffect, useState} from "react"
import {addToDate, generateInitialDate, subtractFromDate, convertTimezone, convertSecondsToTime} from "./functions"

/**
 * Library forked from https://github.com/devshawn/react-dayjs in order to support timezones
 * Documentation at https://github.com/devshawn/react-dayjs/blob/master/DOCUMENTATION.md
 */

const DayJS = (props) => {

    const {date, format, children, add, subtract, daysInMonth, toJSON, toISOString, asString, unixSeconds, unixMilliseconds, displayIsValid, timeZone, secondsToTime} = props
    const Element = props.element ?? "time"
    const [state, setState] = useState({});

    useEffect(() => {

        let dayjsDate = generateInitialDate(date, children);

        if (timeZone) {
            dayjsDate = convertTimezone(date, children, timeZone);
        }

        if (add) {
            dayjsDate = addToDate(dayjsDate, add)
        }

        if (subtract) {
            dayjsDate = subtractFromDate(dayjsDate, subtract)
        }

        if (displayIsValid) {
            setState({value: `${dayjsDate.isValid()}`})
        }

        if (daysInMonth) {
            setState({value: dayjsDate.daysInMonth()})
        }

        if (toJSON) {
            setState({value: dayjsDate.toJSON()})
        }

        if (toISOString) {
            setState({value: dayjsDate.toISOString()})
        }

        if (asString) {
            setState({value: dayjsDate.toString()})
        }

        if (unixMilliseconds) {
            setState({value: dayjsDate.valueOf()})
        }

        if (unixSeconds) {
            setState({value: dayjsDate.unix()})
        }

        if (format) {
            setState({value: dayjsDate.format(format)})
        }

        if (secondsToTime) {
            setState({value: convertSecondsToTime(children?children:date)})
        }

    }, [children, date, timeZone, format, unixSeconds, unixMilliseconds, asString, toISOString, toJSON, daysInMonth, displayIsValid, subtract, add, secondsToTime]);


    if (!date && !children) {
        return <Element>-</Element>;
    }

    return <Element>{state.value}</Element>;
}

/**
DayJS.defaultProps = {
    element: "time",
    date: null,
    format: null,
    toJSON: false,
    toISOString: false,
    asString: false,
    unixSeconds: false,
    unixMilliseconds: false,
    secondsToTime: false,
    daysInMonth: false,
    displayIsValid: false,
    add: null,
    subtract: null,
    children: null,
    timeZone: null
}
*/

export default DayJS;
