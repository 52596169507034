import {useTranslation} from "react-i18next";
import React, {useCallback, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Loading from "../../../layout/modules/Loading/Loading";
import ModalButtons from "../../../layout/modules/Modal/Buttons/ModalButtons";
import Button from "react-bootstrap/Button";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import AdvanceInfoMessage from "../../../layout/modules/InfoMessages/AdvanceInfoMessage";
import FormComponent from "app/interventions/components/form/FormComponent";
import useInterventionTypes from "app/interventions/utils/useInterventionTypes";

const PopupFormComponent = (props) => {

    const {animal, onSubmit, onClose, loading, errors, create, intervention: remoteIntervention, type: defaultType} = props;

    const {t} = useTranslation("health-intervention");

    const [intervention, setIntervention] = useState(create ? {typeId: defaultType} : remoteIntervention);
    const [readOnly, setReadOnly] = useState(!create);
    const {interventionTypes, loadingTypes} = useInterventionTypes(animal, remoteIntervention, !create);

    const handleEditClick = useCallback(() => setReadOnly(readOnly => !readOnly), [setReadOnly]);


    return <Modal
        show={true}
        onHide={loading ? () => void 0 : onClose}
        size={"xl dark"}
        backdrop="static"
        className={"dark-background"}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{create ? t('add_intervention') : t('view_intervention')}</Modal.Title>
        </Modal.Header>
        <Loading visible={loading || loadingTypes}/>
        <Modal.Body>
            <div>
                {(create && !animal?.isDataValid) &&
                <AdvanceInfoMessage
                    icon={faExclamation}
                    type={"warning"}
                    iconType={"danger"}
                    title={t("animal_is_not_valid")}
                    message={t('animal_is_not_valid_msg')}
                />
                }

                {create && t('intervention_tip')}

                <FormComponent
                    create={create}
                    animal={animal}
                    onEditClick={handleEditClick}
                    readOnly={readOnly}
                    errors={errors}
                    types={interventionTypes}
                    intervention={intervention}
                    setIntervention={setIntervention}
                />

            </div>

        </Modal.Body>

        <ModalButtons>

            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>

            {!readOnly &&
            <Button variant="primary" onClick={() => onSubmit(intervention)} disabled={intervention?.lockSubmit || !intervention?.typeId}>
                {create ? t("add") : t("edit")}
            </Button>
            }

        </ModalButtons>

    </Modal>
}

export default PopupFormComponent;
