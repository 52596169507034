import React from "react";
import classnames from "classnames";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import {useDateTime} from "system/DateTime/DateTime";
import {AnimalStatus} from "types/Animal";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import LostAlertContainer from "app/animals/containers/lost/LostAlertContainer";
import locale from "app/animals/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import PotentiallyDangerousAlert from "app/animals/components/view/alerts/PotentiallyDangerousAlert";
import ShowFixedObservations from "app/observations/components/ShowFixedObservations";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import PendingLostAlert from "app/animals/components/view/alerts/PendingLostAlert";
import InvalidDataAlert from "app/animals/components/view/alerts/InvalidDataAlert";
import LockedAlert from "app/animals/components/view/alerts/LockedAlert";
import TransferAlert from "app/animals/components/view/alerts/TransferAlert";
import MergedTranspondersAlert from "app/animals/components/view/alerts/MergedTranspondersAlert";

const Alerts = (props) => {

    const {fullWidth, reload} = props;

    const {animal} = useAnimalState();
    const {t} = useTranslations("animal", locale);
    const getDateTime = useDateTime("DD-MM-YYYY");


    return <div className={classnames("animal-alerts", {"full-width": fullWidth})}>

        <ShowFixedObservations/>

        <UserHasPermission permission={["ANIMAL_LOST_PENDING"]}>
            <PendingLostAlert animal={animal}/>
        </UserHasPermission>

        {AnimalStatus.DEAD !== animal?.status &&
            <>

                <LockedAlert {...props}/>
                <InvalidDataAlert {...props}/>
                <TransferAlert {...props}/>
                <MergedTranspondersAlert {...props}/>

                {[AnimalStatus.LOST, AnimalStatus.FOUND].includes(animal?.status) &&
                    <LostAlertContainer {...props} animal={animal} transponder={animal.transponder} reload={reload}/>
                }

                <PotentiallyDangerousAlert animal={animal}/>

            </>
        }

        {AnimalStatus.DEAD === animal?.status &&
            <AdvanceInfoMessage
                type={"secondary"}
                iconType={"dark"}
                title={t("animal_is_dead")}
                message={t(animal?.deathDate ? 'animal_is_dead_msg' : 'animal_is_dead_msg_no_date',
                    {deathDate: getDateTime(animal?.deathDate, "UTC")})}
            />
        }


    </div>
}

export default Alerts;
