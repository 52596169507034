import React from "react";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";

const ExternalValidationMessages = props => {

    const {t, request} = props;
    const status = request?.externalValidation?.status

    return <>

        {status === "PENDING" &&
        <AdvanceInfoMessage
            icon={faSpinner}
            type={"info"}
            iconType={"info"}
            title={t("external_transponder_validation")}
            message={t('external_validation_pending')}
        />
        }

        {status === "FOUND" &&
        <AdvanceInfoMessage
            icon={faCheck}
            type={"success"}
            iconType={"success"}
            title={t("external_transponder_validation")}
            message={t('external_validation_found', request?.externalValidation)}
        />
        }

        {status === "NOT_FOUND" &&
        <AdvanceInfoMessage
            icon={faTimes}
            type={"warning"}
            iconType={"warning"}
            title={t("external_transponder_validation")}
            message={t('external_validation_not_found')}
        />
        }
    </>


}


export default ExternalValidationMessages;
