import useTranslations from "../../../../../system/Translations/UseTranslations";
import locale from "../../../../aggressions/locale/locales";
import {getDiffsInErrorFormat, getOriginalRawObject} from "../../../utils/AuditDiff";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormComponent from "../../../../aggressions/components/FormComponent";
import React from "react";
import {aggressionAuditResolver} from "../../../../aggressions/utils/AggressionResolver";

const AggressionComponent = (props) => {

    const {operation} = props;
    const {t} = useTranslations("aggression", locale);
    const diffs = getDiffsInErrorFormat(operation.changes);

    return <div className={"versions"}>
        <Row>
            <Col lg={true}>
                <FormComponent
                    t={t}
                    readOnly={true}
                    compare={true}
                    loading={false}
                    create={false}
                    aggression={aggressionAuditResolver(operation.objectRaw)}
                    setAggression={false}
                    errors={diffs}
                />
            </Col>

            {operation.changes &&
            <Col lg={6}>
                <FormComponent
                    t={t}
                    readOnly={true}
                    compare={true}
                    loading={false}
                    create={false}
                    aggression={aggressionAuditResolver(getOriginalRawObject(operation.objectRaw, operation.changes))}
                    setAggression={false}
                    errors={diffs}
                />
            </Col>
            }

        </Row>
    </div>
}

export default AggressionComponent;
