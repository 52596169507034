import VersionComponent from "../components/VersionComponent";
import {useGet} from "system/Rest/Rest";
import {useCallback, useEffect, useMemo, useState} from "react";

const VersionContainer = (props) => {

    const {path, onChange, diffs, onResolve, children, defaultVersion} = props;
    const [version, setVersion] = useState(defaultVersion)

    const {data: rev, loading, error} = useGet({
        path: path + "/" + version?.id + "/" + version?.revisionTimestamp,
        lazy: !version?.id,
        resolve: onResolve,
        localErrorOnly: true,
    });

    const changes = useMemo(
        () => {
            if (rev?.revisionChangedFields) {
                return rev.revisionChangedFields.reduce(
                    (acc, param) => ({
                        ...acc,
                        [param]: true
                    }),
                    {}
                );
            } else return {};
        },
        [rev?.revisionChangedFields]
    );

    useEffect(
        () => {
            if (rev?.revisionTimestamp && onChange) {
                onChange(rev);
            }
        }, [rev, onChange])


    return <VersionComponent
        {...props}
        loading={loading}
        version={rev}
        diffs={diffs}
        changes={changes}
        children={children}
        error={error}
        onChange={useCallback(
            (version, object) => setVersion({
                id: object?.revisionId,
                revisionTimestamp: object?.revisionTimestamp,
            }),
            [setVersion]
        )}

    />

}
export default VersionContainer;
