import React, {useCallback, useMemo} from "react";
import locale from "app/control/modules/transfers/locales/locales";
import ListComponent from "app/control/modules/transfers/components/list/ListComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import useTranslations from "system/Translations/UseTranslations";
import useBulkUpdate from "app/control/modules/transfers/utils/useBulkUpdate";
import ValidationStatus from "types/ValidationStatus";
import TransferType from "app/control/modules/transfers/utils/TransferType";

const List = (props) => {

    const {transponder, reloadBase, ids, isClosed} = props;
    const {t} = useTranslations('transfer', locale);

    const {loading: accepting, handleBulkOperation} = useBulkUpdate({
        path: "/animal-transfers/bulk-update",
        reloadBase: reloadBase,
        prop: "animalTransferIds",
        t: t,
    });

    return <GenericListContainer
        id={"transfer-table"}
        {...props}
        hideTitle={transponder || ids}
        module="transfer"
        locale={locale}
        usePost={true}
        searchPath={"/animal-transfers/search"}
        queryParameters={useMemo(() => ({
            currentPage: 0,
            itemsPerPage: 10,
            transponders: transponder ? [transponder] : undefined,
            isClosed: isClosed,
            origin: !transponder ? [TransferType.SIAC, TransferType.PUBLIC] : undefined,
            sortProperty: "creationTimestamp",
            sortDirection: isClosed ? "DESC" : "ASC",
            ids: ids,
        }), [transponder, isClosed, ids])}>
        <ListComponent
            transponder={transponder}
            accepting={accepting}
            isClosed={isClosed}
            onAccept={useCallback((ids, reload) => handleBulkOperation(ids, reload, ValidationStatus.ACCEPTED), [handleBulkOperation])}
            onReject={useCallback((ids, reload, reason) => handleBulkOperation(ids, reload, ValidationStatus.REJECTED, reason), [handleBulkOperation])}

        />
    </GenericListContainer>

}

export default List;
