import {AnimalStatus} from "types/Animal";

const getAnimalStatusLabel = (t, animal ) => {

    if (animal.isPreRegister) {
        return t('animal_pre_register');
    }
    if (animal.status !== AnimalStatus.NORMAL) {
        return t(animal.status)
    }
    else if (animal.hasPendingOwnerTransfer) {
        return t('pending');
    }
    else if (!animal.isDataValid) {
        return t("incomplete_data");
    }
    else if (animal.isLocked) {
        return t("animal_is_locked")
    }

    return t(animal.status);

}

export default getAnimalStatusLabel;
