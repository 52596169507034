import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "modules/VersionsContainer/assets/css/versions.scss";
import VersionContainer from "modules/VersionsContainer/containers/VersionContainer";
import Loading from "layout/modules/Loading/Loading";
import {useGetDiffs} from "modules/VersionsContainer/utils/compare";

const CompareComponent = (props) => {

    const {title, onClose, visible, loading, versions, children} = props;

    const [leftVersion, setLeftVersion] = useState(null);
    const [rightVersion, setRightVersion] = useState(null);

    const diffs = useGetDiffs(leftVersion, rightVersion);

    //Forget versions;
    useEffect(() => {
        setLeftVersion(null);
        setRightVersion(null);
    }, [visible]);


    if (!visible) {
        return <></>;
    }

    return <Modal show={visible} onHide={onClose}
                  size={"xl"} className={"dark-background versions"} backdrop="static"
                  keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Loading visible={loading}/>

            {(!loading && versions) &&
            <Row>
                <Col xl={6} md={6}>
                    <VersionContainer
                        {...props}
                        diffs={diffs}
                        onChange={setLeftVersion}
                        excludeVersion={rightVersion}
                        children={children}
                    />
                </Col>

                <Col xl={6} md={6} className={"d-none d-md-block"}>
                    <VersionContainer
                        {...props}
                        diffs={diffs}
                        onChange={setRightVersion}
                        excludeVersion={leftVersion}
                        children={children}
                    />
                </Col>
            </Row>
            }

        </Modal.Body>
    </Modal>
}

export default CompareComponent;
