import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import React from "react";

const RadioComponent = props => {

    const {id, value, disabled, label, icon, onChange} = props;

    return <div className={classnames("checkbox radio", {"with-label": label !== undefined})} onClick={onChange}>

        <input id={id} name={id} type="checkbox" checked={value} onChange={onChange} disabled={disabled}/>
        <div className={classnames("checkbox-box", {"checked": value})}>
            <FontAwesomeIcon className={"checkbox-box-icon"} icon={icon ? icon : faCircle}/>
        </div>
        <div className="checkbox-label">{label}</div>
    </div>

}

export default RadioComponent;
