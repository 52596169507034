import {useMutate} from "system/Rest/Rest";
import {useCallback, useState} from "react";
import {useBillingBalanceState} from "modules/Billing/BillingStateContext";

const defaultValidationStatus = {
    canRegister: false,
    canPreRegister: false,
    errorMessage: undefined,
    notBillable: false,
    validated: false,
};

const useValidateTransponder = () => {

    const billingState = useBillingBalanceState();

    const [validationStatus, setValidationStatus] = useState(defaultValidationStatus);

    const {mutate: validateTransponder, loading} = useMutate({
        verb: "POST",
        path: useCallback(({id}) => `/animals/transponder/validate/${id}`,[]),
    });

    const onInvalidateTransponder = useCallback(
        () => setValidationStatus(defaultValidationStatus),
        [setValidationStatus]
    );

    const onValidateTransponder =  useCallback(
        (transponderId) => {

            setValidationStatus(defaultValidationStatus);

            validateTransponder({}, {pathParams: {id: transponderId}})
                .then(response => {

                    //Inject not credits
                    if (!billingState.canRegisterAnimal && !response.notBillable) {
                        setValidationStatus({
                            canRegister: false,
                            canPreRegister: false,
                            errorMessage: "NO_CREDIT",
                            validated: true,
                        })
                    }
                    else {
                        setValidationStatus({
                            ...response,
                            validated: true,
                        })
                    }
                });
        },
        [validateTransponder, setValidationStatus, billingState]
    );

    return {
        onValidateTransponder,
        onInvalidateTransponder,
        validationStatus,
        loading,
    }
}





export default useValidateTransponder;
