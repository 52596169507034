import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import VeterinaryDropdown, {getVeterinaryValueOrEmpty} from "modules/Forms/Dropdowns/ClassDropdown/components/VeterinaryDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import EntityLinkComponent from "app/entities/components/other/EntityLinkComponent";
import VeterinaryLinkComponent from "app/veterinaries/components/link/VeterinaryLinkComponent";

const VeterinaryAndEntityComponent = (props) => {

    const {t} = useTranslation('animal');
    const {errors, readOnly, changes} = props;

    const {
        animal: {
            veterinary,
            veterinaryId,
            entityId,
            entity,
        },
        setAnimal
    } = useAnimalState();

    const handleVeterinaryChange = useCallback(
        (veterinaryId) => setAnimal(animal => ({
            ...animal,
            veterinaryId: veterinaryId,
        })),
        [setAnimal]
    );

    const handleEntityChange = useCallback(
        (entityId) => setAnimal(animal => ({
            ...animal,
            entityId: entityId,
        })),
        [setAnimal]
    );

    return <Box>
        <BoxTitle>{t('veterinary_and_entity')}</BoxTitle>
        <Row>
            <Col sm={12} lg={12}>
                <FormGroup error={errors['veterinaryId']} changes={changes['veterinaryId']} className={"mandatory"}>
                    <Form.Label>{t('veterinary')}</Form.Label>
                    {readOnly
                        ? <LabelWithValue>
                            <VeterinaryLinkComponent
                                veterinary={veterinary}
                                label={getVeterinaryValueOrEmpty(veterinary)}
                            />
                        </LabelWithValue>
                        : <VeterinaryDropdown
                            value={getOrNull(veterinaryId)}
                            isDisabled={readOnly}
                            onChange={handleVeterinaryChange}

                        />}
                    <Form.Text className="sub-error">
                        {t(errors['veterinaryId'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            <Col sm={12} lg={12}>
                {readOnly
                    ? <LabelWithValue label={t('entity')}>
                        <EntityLinkComponent name={entity?.name} id={entity?.id}/>
                    </LabelWithValue>
                    : <FormGroup error={errors['entityId']} changes={changes['entityId']} className={"mandatory"}>
                        <Form.Label>{t('entity')}</Form.Label>
                        <EntityDropdown
                            value={getOrNull(entityId)}
                            isDisabled={readOnly}
                            onChange={handleEntityChange}

                        />
                        <Form.Text className="sub-error">
                            {t(errors['entityId'])}
                        </Form.Text>
                    </FormGroup>
                }

            </Col>
        </Row>
    </Box>


}

export default VeterinaryAndEntityComponent;
