import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Button from "react-bootstrap/Button";
import LostComponent from "app/animals/components/lost/show/LostComponent";
import {Trans} from "react-i18next";
import classnames from "classnames";
import {useDateTime} from "system/DateTime/DateTime";
import useTranslations from "system/Translations/UseTranslations";
import locales from "app/animals/locales/locales";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";

import "app/animals/assets/css/lost-alert.scss";
import LostCloseButtonComponent from "app/animals/components/lost/other/LostCloseButtonComponent";

const LostAlertComponent = (props) => {

    const {animal, lostInfo, loading} = props;

    const convertDateTime = useDateTime("DD-MM-YYYY");
    const {t} = useTranslations("animal", locales);

    if (animal && !loading && lostInfo) {

        return <AdvanceInfoMessage
            icon={faExclamation}
            type={"danger"}
            iconType={"danger"}
            title={t("animal_is_" + animal.status.toLowerCase())}>

            <div className={"lost-alert"}>
                <div className={"message"}>
                    <Trans>
                        {t("animal_is_" + animal.status.toLowerCase() + "_msg", {
                            contact: lostInfo.participantContact,
                            name: lostInfo.participantName,
                            date: convertDateTime(lostInfo.eventDate),
                            local: lostInfo?.eventParish?.name ? lostInfo?.eventParish?.name : lostInfo?.eventCounty?.name,
                            observations: lostInfo?.observations,

                        })}
                    </Trans>
                </div>
                <div className={"buttons"}>
                    <LostComponent {...props} lostInfo={lostInfo} transponder={animal?.transponder}>
                        {({setVisible, active}) =>
                            <Button variant="danger" onClick={setVisible} className={classnames({'active': active})}>
                                {t("view_lost_info")}
                            </Button>
                        }
                    </LostComponent>

                    {!lostInfo?.pendingAnimalTransferId &&
                    <LostCloseButtonComponent {...props} animal={animal}/>
                    }

                </div>
            </div>

        </AdvanceInfoMessage>


    } else
        return <></>

}

export default LostAlertComponent;
