import {Link} from "react-router-dom";
import {useMemo} from "react";
import {useApiState} from "system/API/APIStateContext";
import {UserType} from "types/Users";

const VeterinaryLinkComponent = props => {

    const {label, veterinary} = props;
    const {authentication} = useApiState();

    const canAccessToAllVeterinaries = useMemo(
        () => authentication?.entitiesAccessList.length === 0 && UserType.NORMAL === authentication.type,
        [authentication?.entitiesAccessList, authentication.type]
    );

    if (!veterinary?.id || !canAccessToAllVeterinaries)
        return <>{label}</>
    else
        return <Link to={"/veterinary/" + veterinary.id}>
            {label}
        </Link>
}

export default VeterinaryLinkComponent;
