export const en = {

    help: "Help",
    go_back: "Help Index",

    title: "Title",
    title_ph: "Please type the help title",

    category: "Category",
    category_ph: "Please type the help category",

    contents: "Contents",
    save_changes: "Save Changes",

    SUCCESS_HELP: "Success",
    SUCCESS_HELP_MSG: "Help content has been successfully updated!",

    CONFIRM_DELETE_PAGE: "Confirm",
    CONFIRM_DELETE_PAGE_MSG: "This help page will be deleted. Are you sure?",

    ERROR_HELP_DELETE: "Error",
    ERROR_HELP_DELETE_MSG: "Something went wrong. Please try again latter",

    SUCCESS_HELP_DELETE: "Success",
    SUCCESS_HELP_DELETE_MSG: "Help article has been successfully deleted!",

    MISSING_TITLE: "Please type the article title",
    MISSING_CATEGORY: "Please type a category",
    MISSING_CONTENTS: "Please type the help article content",

    FORM_ERROR: "Some Errors found",
    FORM_ERROR_MSG: "Please check the fields marked with errors",

};

export default en;
