import "./modal-no-margin.scss"
import classnames from "classnames";
const ModalWithNoMargin = (props) => {

    const {children, className} = props;

    return <div className={classnames("modal-no-margin", className)}>
        {children}
    </div>


}

export default ModalWithNoMargin;
