import useTranslations from "../../../../../system/Translations/UseTranslations";
import locale from "../../../../licensing/locales/locales";
import FormComponent from "../../../../licensing/components/FormComponent";

const AnimalLicenseComponent = (props) => {

    const {operation} = props;
    const {t} = useTranslations("animal-license", locale);

    return <div className={"view-mode"}>
        <FormComponent
            t={t}
            readOnly={true}
            errors={{}}
            license={operation.objectRaw}

        />

    </div>
}

export default AnimalLicenseComponent;
