import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faStop} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const CheckComponent = props => {

    const {id, value, disabled, label, icon, square, onChange} = props;

    const defaultIcon = square ? faStop : faCheck;

    return <div className={classnames("checkbox", {"with-label": label !== undefined})} onClick={onChange}>

        <input id={id} name={id} type="checkbox" checked={value} onChange={onChange} disabled={disabled}/>
        <div className={classnames("checkbox-box", {"checked": value})}>
            <FontAwesomeIcon className={classnames("checkbox-box-icon", {"square": square})}
                             icon={icon ? icon : defaultIcon}/>
        </div>
        <div className="checkbox-label">{label}</div>
    </div>


}

export default CheckComponent;
