import appConfig from "configs/config";

export const pt = {
    title_dashboard: appConfig.name,

    //Search Transponders
    transponder_ph: "Pesquisar transponder",
    transponder_no_search: "Para aceder a um animal introduza o número de transponder completo na caixa de pesquisa acima e prima ENTER ou carregue na lupa.",


    animals: "Animais",
    credits: "Créditos",

    view_more: "Ver mais",
    changelog: "Histórico de alterações",

    search_label: "Pesquisar por transponder ou registar animal",

    NORMAL: "Utilizador de entidade",
    VETERINARY: "Médico Veterinário",
    entity: "Entidade",
    role: "Perfil",
    total_animals: "Animais da entidade",
    sign_out: "Terminar sessão",

    alerts: "Informações",
    close: "Fechar",
    close_and_discard: "Fechar e não voltar a mostrar",

};

export default pt;
