import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {faCheckDouble, faTimes} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import classnames from "classnames";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import SetStateModalComponent from "app/control/components/show/SetStateModalComponent";

const BulkOptionsComponent = props => {

    const {t, data, onAccept, onReject, reload, selectedItems, setSelectedItems, reasonsURL, labelVar} = props;

    const [isAcceptConfirmVisible, setAcceptConfirmVisible] = useState(false);
    const [isRejectConfirmVisible, setRejectConfirmVisible] = useState(false);

    return <>

        {isAcceptConfirmVisible &&
        <PopupConfirm
            title={t('BULK_ACCEPT_CONFIRM')}
            message={t('BULK_ACCEPT_CONFIRM_MSG', labelVar ? labelVar : {transponders: selectedItems.map(i => i.transponder).join("\r\n")})}
            classConfirm={"primary"}
            visible={true}
            onCancel={() => {
                setAcceptConfirmVisible(false);

            }}
            onConfirm={() => {
                onAccept(selectedItems.map(i => i.id), reload);
                setAcceptConfirmVisible(false);
                setSelectedItems([]);
            }}
        />
        }

        {isRejectConfirmVisible &&
        <SetStateModalComponent
            t={t}
            setShow={setRejectConfirmVisible}
            show={true}
            moreInformation={false}
            message={t('BULK_REJECT_CONFIRM_MSG', labelVar ? labelVar : {transponders: selectedItems.map(i => i.transponder).join("\r\n")})}
            reasonsUrl={reasonsURL}
            onChangeState={(_, message) => {
                onReject(selectedItems.map(i => i.id), reload, message);
                setRejectConfirmVisible(false);
                setSelectedItems([]);
            }}
        />

        }

        <CheckBox
            label={t('bulk_item_selected', {count: selectedItems.length})}
            value={selectedItems.length > 0}
            disabled={selectedItems.length === 0}
            square={data?.totalItems > selectedItems.length}
            onChange={() => setSelectedItems([])}
        />
        <div className={"search-buttons"}>
            <ToolTip content={t('accept_in_bulk')}>
                <TableIconButton
                    icon={faCheckDouble}
                    className={classnames("btn-rounded-primary small", {"disabled": selectedItems.length === 0})}
                    onClick={() => {
                        if (selectedItems.length > 0)
                            setAcceptConfirmVisible(true)
                    }}
                />
            </ToolTip>

            {onReject &&
            <ToolTip content={t('reject_in_bulk')}>
                <TableIconButton
                    icon={faTimes}
                    className={classnames("btn-rounded-danger small", {"disabled": selectedItems.length === 0})}
                    onClick={() => {
                        if (selectedItems.length > 0)
                            setRejectConfirmVisible(true)
                    }}
                />
            </ToolTip>
            }

        </div>
    </>
}

export default BulkOptionsComponent;
