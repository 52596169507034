import React, {useCallback, useEffect, useState} from "react";
import LostContainer from "app/animals/containers/lost/LostContainer";

const LostComponent = React.forwardRef((props, ref) => {

    const [isVisible, setLostVisible] = useState(false);
    const handleClose = useCallback(() => setLostVisible(false), [setLostVisible]);
    const handleVisible = useCallback(() => setLostVisible(true), [setLostVisible]);

    //Add setVisible to the ref
    useEffect(() => {
        if (ref) {
            ref.current = {
                setVisible: handleVisible
            }
        }
    }, [ref, handleVisible]);

    return <>
        {props.children && props.children({
            setVisible: handleVisible,
            active: isVisible,
        })}
        {isVisible &&
            <LostContainer {...props} onClose={handleClose}/>
        }
    </>
});

export default LostComponent;
