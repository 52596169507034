import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import React, {useCallback, useState} from "react";
import Box from "layout/modules/Box/Box";
import Row from "react-bootstrap/Row";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import HTMLEditor from "layout/modules/Forms/HTMLEditor/HTMLEditor";
import {getOrDefault, getOrEmpty} from "system/Objects/ObjectParameters";
import Col from "react-bootstrap/Col";

const AgreementComponent = props => {

    const {errors, t, template, setTemplate, readOnly} = props;
    const [collapsed, setCollapsed] = useState(template?.agreement?.length <= 10 || !template?.agreement);

    const handleChange = useCallback(
        (value) => {
            setTemplate(template => ({
                ...template,
                agreement: value,
            }))
        },
        [setTemplate]
    );

    const handleCollapse = useCallback(
        (val) => {
            setCollapsed(!val)
            if (!val) {
                handleChange("");
            }
        },
        [handleChange, setCollapsed]
    );

    if (readOnly && !(template?.agreement?.length > 10))
        return <></>

    return <Box collapsed={collapsed}>
        <BoxTitle>{t('agreement')}</BoxTitle>
        <BoxOptions>
            <CheckBox
                value={getOrDefault(!collapsed, false)}
                label={t('has_agreement')}
                disabled={readOnly}
                onChange={handleCollapse}
            />
        </BoxOptions>

        {!collapsed &&
        <Row>
            <Col sm={12} lg={12} xl={12}>
                <FormGroup error={errors['agreement']}>
                    <HTMLEditor
                        value={getOrEmpty(template?.agreement)}
                        id={"legalWarning"}
                        disabled={readOnly}
                        onChange={handleChange}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['agreement'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Row>
        }

    </Box>

}

export default AgreementComponent;
