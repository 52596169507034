import {Form} from "react-bootstrap";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Loading from "layout/modules/Loading/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import TransponderControl from "modules/Forms/Transponder/TransponderControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Collapse from "layout/modules/Collapse/Collapse";
import AnimalDataComponent from "app/animals/components/form/AnimalDataComponent";
import PedigreeComponent from "app/animals/components/form/PedigreeComponent";
import AnimalAddressComponent from "app/animals/components/form/AnimalAddressComponent";
import OwnerComponent from "app/animals/components/form/OwnerComponent";
import AnimalFieldIsAllowed from "app/animals/utils/AnimalFieldsAllowed";
import VeterinaryAndEntityComponent from "app/animals/components/form/VeterinaryAndEntityComponent";
import ObservationsComponent from "app/animals/components/form/ObservationsComponent";
import React, {useCallback} from "react";
import useTranslations from "system/Translations/UseTranslations";
import locales from "app/animals/locales/locales";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import PhotoComponent from "app/animals/components/form/PhotoComponent";

const AnimalFormDataComponent = props => {

    const {t} = useTranslations('animal', locales);

    const {loading, errors = {}, create, readOnly, disableTransponder, photoUploadRef} = props;
    const {animal, setAnimal} = useAnimalState();

    const handleTransponderChange = useCallback(
        (t) => setAnimal(animal => ({
            ...animal,
            transponder: t,
        })),
        [setAnimal]
    );


    return <div className={"animal-form"}>

        <Form>
            <Box>
                <BoxTitle>{t('animal_id')}</BoxTitle>
                <Loading visible={loading}/>

                <PhotoComponent
                    ref={photoUploadRef}
                    animal={animal}
                    setAnimal={setAnimal}
                />

                <Row>
                    <Col sm={12} xl={true}>
                        <FormGroup error={errors['transponder']} mandatory={false}>
                            <Form.Label>{t('transponder')}</Form.Label>
                            <TransponderControl
                                placeholder={t('transponder_ph')}
                                disabled={readOnly || disableTransponder}
                                onlyNumeric={true}
                                value={getOrEmpty(animal?.transponder)}
                                onValid={handleTransponderChange}
                                onInvalid={handleTransponderChange}

                            />

                            <Form.Text className="sub-error">
                                {t(errors['transponder'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                </Row>

            </Box>


            <Collapse title={t('animal_data')}>

                <AnimalDataComponent
                    create={create}
                    changes={{}}
                    errors={errors}
                    readOnly={readOnly}
                />

                <PedigreeComponent
                    create={create}
                    changes={{}}
                    errors={errors}
                    readOnly={readOnly}/>

                <AnimalAddressComponent
                    animal={animal}
                    setAnimal={setAnimal}
                    create={create}
                    changes={{}}
                    errors={errors}
                    readOnly={readOnly}/>

            </Collapse>


            {!readOnly &&
            <Collapse title={t('owner')}>
                <OwnerComponent
                    create={create}
                    changes={{}}
                    errors={errors}
                    readOnly={readOnly}/>
            </Collapse>
            }

            <AnimalFieldIsAllowed field={"entityId"} isReadOnly={readOnly} errors={errors} isCreation={create}>
                <Collapse title={t('veterinary_and_entity')}>
                    <VeterinaryAndEntityComponent
                        create={create}
                        animal={animal}
                        setAnimal={setAnimal}
                        changes={{}}
                        errors={errors}
                        readOnly={readOnly}
                    />
                </Collapse>
            </AnimalFieldIsAllowed>


            <AnimalFieldIsAllowed
                field={"observations"}
                isReadOnly={readOnly} errors={errors} isCreation={create}
                animal={animal}>

                <Collapse title={t('observations')}>
                    <ObservationsComponent
                        create={create}
                        animal={animal}
                        setAnimal={setAnimal}
                        changes={{}}
                        errors={errors}
                        readOnly={readOnly}
                    />
                </Collapse>
            </AnimalFieldIsAllowed>


        </Form>

    </div>
}

export default AnimalFormDataComponent;
