import {useEffect, useRef} from "react";
import useElementSize from "system/Window/useElementSize";

const useUpdateIframeSize = () => {

    const ref = useRef();
    const {height} = useElementSize(ref);

    useEffect(
        () => {
            if (window.parent) {
                window.parent.postMessage(height, "*");
            }
        },
        [height]
    );

    return ref;

}

export default useUpdateIframeSize;
