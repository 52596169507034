import BreedAdvancedDropdownComponent from "app/breeds/components/dropdown/BreedAdvancedDropdownComponent";
import {useEffect, useState} from "react";
import {useGet} from "system/Rest/Rest";
import breedResolver from "app/breeds/utils/breedResolver";
import getAsArray from "system/Arrays/getArray";

const BreedAdvancedDropdown = props => {

    const {value} = props;

    const [currentChoices, setCurrentChoices] = useState([]);

    const {data: breed, refetch} = useGet({
        path: "/breeds/" + value,
        resolve: breedResolver,
        lazy: true,
    });

    //load choices
    useEffect(
        () => {
            if (breed) {
                setCurrentChoices([...getAsArray(breed.parentList), breed]);
            }
        },
        // eslint-disable-next-line
        [breed]
    )

    //Request load on external id change
    useEffect(
        () => {
            if (!value)
                return;

            if (currentChoices.length === 0 || currentChoices[currentChoices.length - 1]?.id !== value) {
                refetch();
            }
        },
        // eslint-disable-next-line
        [value, refetch]
    );

    return <BreedAdvancedDropdownComponent
        {...props}
        currentChoices={currentChoices}
        setCurrentChoices={setCurrentChoices}
    />
}

export default BreedAdvancedDropdown;
