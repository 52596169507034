import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../../../layout/modules/Loading/Loading";
import React from "react";

const IndexComponent = (props) => {

    const {topics = {}, loading, onChangeArticle} = props;

    return <div className={"help-index"}>
        <Loading visible={loading}/>
        <Row>
            {
                Object.keys(topics).map(topic =>
                    <Col xs={12} lg={4} key={topic} className={"category-group"}>
                        <div className={"title"}>
                            {topic}
                        </div>

                        {topics[topic].map((item,index) =>
                            <div className={"topic"}
                                 key={index}
                                 onClick={() => onChangeArticle(item.url)}>
                                {item.title}
                            </div>
                        )}
                    </Col>
                )}


        </Row>


    </div>
}

export default IndexComponent;
