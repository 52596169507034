export const pt = {

    title: "Documentos",
    view: "Visualizar",
    create_title: "Criar novo documento",
    name: "Nome do documento",
    custom_filename: "Prefixo no ficheiro",
    custom_filename_ph: "Insira um prefixo para o ficheiro. (ex. DIAC)",
    add_transponder_to_filename: "Adicionar transponder ao nome do ficheiro",
    general: "Dados gerais",

    no_certificate_results: "Não foram encontrados documentos.",

    //form
    animal_status: "Estado do animal",
    status_normal: "Normal",
    status_dead: "Morto",
    status_found: "Encontrado",
    status_lost: "Desaparecido",
    save_form: "Guardar",
    create_form: "Criar documento",
    press_download_area: "Para apagar (em modo de edição) ou realizar o download do template utilize os botões laterais",
    veterinary_restriction: "M. veterinários autorizados",

    //Place Holders
    name_ph: "Escreva o nome ex. (DIAC)",
    status_ph: "Escolha uma opção",
    placeholder: "Escolha uma opção",

    animal_data: "Estado do registo do animal",
    certificate_issue: "Emissão",
    only_veterinaries: "Apenas pode ser emitido por m. veterinário",
    file_upload: "Por favor escolha ou arraste o ficheiro com o template no formato doc",
    available_to_owners: "Disponível no SIAC titulares",
    available_to_siac: "Disponível no portal.siac.vet",


    is_valid: "Com ficha válida",
    pending_owner_transfer: "Transmissão de titularidade pendente",
    sterilization: "Esterilização realizada",
    rabies: "Prof. antirrábica realizada",
    has_no_sterilization: "Não esterilização",
    has_contraindication_rabies: "Contra indicação para antirrábica",
    is_danger: "Animal perigoso",
    is_potentially_dangerous: "Animal potencialmente perigoso",
    allowed_entities: "Limitado a perfis de entidades",
    has_license: "Licenciamento",
    breed_type: "Tipo de animal / raça",
    animal_type: "Animal",
    proof_of_life: "Prova de vida e aptidão",

    parameters: "Parâmetros extra para emissão",
    type: "Tipo de parâmetro",
    param_code: "Variável no documento",
    param_code_ph: "A variável a utilizar. (ex. deathDate)",
    param_name: "Nome no formulário",
    param_name_ph: "O nome que irá aparecer no formulário",
    not_null_or_empty: "Obriga preenchimento",
    description: "Texto descritivo",
    description_ph: "Um texto de apoio que aparece junto ao campo",
    prevent_past_dates: "Datas futuras bloqueadas",
    prevent_future_dates: "Datas passadas bloqueadas",
    Text: "Texto",
    Date: "Data",


    YES: "Obrigatório",
    NO: "Apenas quando não estiver",
    ANY: "Não Aplicável / Indiferente",
    ALL: "Todos",
    ONLY_REGISTERED_ANIMAL: "Apenas quem registou o animal",
    ONLY_REGISTERED_STERILIZATION: "Apenas quem registou esterilização",

    UPDATE_SUCCESS: "Documento atualizado",
    UPDATE_SUCCESS_MSG: "O documento  \"{{name}}\" foi atualizado com sucesso!",

    CREATE_SUCCESS: "Documento criado",
    CREATE_SUCCESS_MSG: "O documento  \"{{name}}\" foi criado com sucesso!",

    //Left Bar on Animals
    issue_documents: "Emitir documentos",
    get_document: "Emitir {{name}}",
    issue_option_text: "Por favor indique de que modo quer obter o documento. Pode optar pelo download para impressão ou pelo envio por email",
    send_by_email: "Enviar documento por Email",
    download: "Download / Impressão",
    confirm: "Confirmar",
    cancel: "Cancelar",
    email_box_title: "Email para envio da documentação",
    email: "Endereço de email",
    email_ph: "Introduza o endereço de email que irá receber o documento",
    date_ph: "Data no formato (dd-mm-yyyy)",


    //Bulk
    get_bulk_diac: "Obter documentos de animais",
    get_bulk_diac_msg: "Deve indicar o email para onde os documentos devem ser enviados. Caso deseje realizar o download dos documentos deve obter os ficheiros individualmente na respetiva página do animal.",


    //Document validation
    validate_box_title: "Código de validação",
    validate_title: "Verificar validade do documento",
    code: "Código de verificação",
    code_ph: "Código presente no documento (ex. a329ba121)",
    validate: "Validar",
    view_animal: "Visualizar registo",

    document_is_valid: "Documento válido",
    document_is_valid_msg: "O documento com o código de verificação <strong>{{code}}</strong> emitido a <strong>{{date}}</strong> está válido para o transponder <strong>{{transponder}}</strong>.",

    document_is_valid_and_outdated: "Documento válido mas desatualizado ",
    document_is_valid_and_outdated_msg: "O documento com o código de verificação <strong>{{code}}</strong> emitido a <strong>{{date}}</strong> é válido para o transponder <strong>{{transponder}}</strong>, no entanto a ficha já foi entretanto atualizada.",

    document_is_invalid: "Documento inválido",
    document_is_invalid_msg: "O documento com o código de verificação <strong>{{code}}</strong> não está válido!",


    //Success sent
    SUCCESS_SENT_CERTIFICATE: "Documento enviado",
    SUCCESS_SENT_CERTIFICATE_MSG: "O documento foi enviado com sucesso para o email {{email}}",

    SUCCESS_DOWNLOAD_CERTIFICATE: "Download realizado",
    SUCCESS_DOWNLOAD_CERTIFICATE_MSG: "O download do ficheiro foi realizado com sucesso. Por favor verifique a sua pasta de downloads.",

    SUCCESS_SENT_TRANSPONDERS: "Documentos enviados",
    SUCCESS_SENT_TRANSPONDERS_MSG: "Foram enviados {{total}} documentos DIAC para o email {{email}}",

    //Generate form erros
    EMPTY_VALUE: "Deve preencher o campo com um valor válido",
    INVALID_VALUE: "Por favor introduza um valor válido",
    INVALID_EMAIL: "Por favor introduza um endereço de email válido",
    INVALID_EMAIL_ADDRESS: "Por favor introduza um endereço de email válido",
    INVALID_DATE_NO_FUTURE_DATES: "Deve indicar uma data passada",
    INVALID_DATE_NO_PAST_DATES: "Deve indicar uma data futura",

    //Errors
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    INVALID_CERTIFICATION_PERMISSION: "Por favor escolha uma opção",
    MISSING_NAME: "Por favor indique o nome do documento",
    MISSING_PROPERTY_NAME: "Por favor indique um nome",
    MISSING_TEMPLATE: "Por favor escolha um ficheiro",
    INVALID_CODE: "Por favor indique nome sem espaços ou caracteres especiais",


    DELETE_CERTIFICATE_CONFIRM: "Confirmação",
    DELETE_CERTIFICATE_CONFIRM_MSG: "Deseja realmente apagar esto template {{name}}?",
    SUCCESS_DELETE_CERTIFICATE: "Template de documento removido",
    SUCCESS_DELETE_CERTIFICATE_MSG: "O template com o nome {{name}} foi removido do sistema com sucesso!",
    FAIL_DELETE_CERTIFICATE: "Erro",
    FAIL_DELETE_CERTIFICATE_MSG: "Ocorreu um erro ao apagar o template. Por favor tente mais tarde",

};

export default pt;
