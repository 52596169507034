import React from "react";
import lazyWithRetry from "system/System/lazyWithRetry";

const CodeEditorComponent = lazyWithRetry(() => import("layout/modules/Forms/CodeEditor/CodeEditorComponent"));

const CodeEditor = props => {
    return <React.Suspense fallback={<div></div>}>
        <CodeEditorComponent {...props}/>
    </React.Suspense>
}

export default CodeEditor;
