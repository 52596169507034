import {useMutate} from "system/Rest/Rest";
import React, {useCallback, useState} from "react";
import GeneralSettingsComponent from "../components/GeneralSettingsComponent";
import {useTranslation} from "react-i18next";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useApiState} from "system/API/APIStateContext";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";
import Loading from "layout/modules/Loading/Loading";

const GeneralSettingsContainer = (props) => {

    const {user, reload} = props;
    const {setAuthentication} = useApiState();

    const {t} = useTranslation('personalArea');
    const showToast = useShowToast();

    const [isLoading, setLoading] = useState(false);

    const {mutate: submitUser, error: errorSubmittingPersonalData} = useMutate({
        verb: "PUT",
        path: "/auth/personal",
    });

    const errors = useErrorParser(errorSubmittingPersonalData);

    const handleSubmit = useCallback(
        (user) => {
            setLoading(true);
            submitUser(user)
                .then((response) => {

                    showToast({
                        title: t('UPDATE_SUCCESS'),
                        message: t('UPDATE_SUCCESS_MSG'),
                        icon: faCheck,
                    });

                    //Adjust local settings
                    setAuthentication((authentication) => ({
                        ...authentication,
                        language: response?.language,
                        timeZone: response?.timeZone,
                        name: response.name,
                    }));

                    reload();
                    setLoading(false);


                }).catch(e => {
                setLoading(false);
                handleUnknownFormError(e, showToast, t);
            });
        },
        [showToast, setAuthentication, t, submitUser, reload]
    );

    if (!user) {
        return <Loading visible={true}/>;
    }

    return <GeneralSettingsComponent
        {...props}
        user={user}
        errors={errors}
        loading={isLoading}
        onSubmit={handleSubmit}
    />

}

export default GeneralSettingsContainer;
