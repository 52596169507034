import React, {useCallback, useRef} from "react";
import "app/adminLabels/assets/css/admin-label.scss";
import {useClickedOutsideEffect} from "system/Window/HandleClickOutside";
import LabelsEditModeComponent from "app/adminLabels/components/LabelsEditModeComponent";
import LabelsReadOnlyComponent from "app/adminLabels/components/LabelsReadOnlyComponent";

const AdminLabelComponent = props => {

    const {readOnly, setReadOnly} = props

    const target = useRef(null);


    const handleClick = useCallback(
        () => {
            if (readOnly) {
                setReadOnly(false)
            }
        },
        [readOnly, setReadOnly]
    )


    useClickedOutsideEffect(target, useCallback((e) => {
            if (!readOnly) {
                if (!target.current.contains(e.target)) {
                    setReadOnly(true);
                }

            }
        }, [readOnly, setReadOnly])
    );


    return <div className={"tag-area"} onClick={handleClick} ref={target}>

        {readOnly
            ? <LabelsReadOnlyComponent {...props} />
            : <LabelsEditModeComponent {...props}/>
        }
    </div>
}

export default AdminLabelComponent;
