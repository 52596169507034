import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Box from "layout/modules/Box/Box";
import React, {useCallback} from "react";
import MultiOptionComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionComponent";
import ParamComponent from "./ParamComponent";

const ParamsComponent = props => {

    const {errors, t, template, setTemplate, readOnly, isOperation} = props;

    return <Box>
        <BoxTitle>{t('template_params_config')}</BoxTitle>

        <MultiOptionComponent
            data={template?.parameters}
            errors={errors?.parameters}
            readOnly={readOnly}
            isOperation={isOperation}
            onChange={useCallback((fnc) => setTemplate((data) => ({
                ...data,
                parameters: fnc(data.parameters),
            })), [setTemplate])}>
            <ParamComponent t={t} isOperation={isOperation} />
        </MultiOptionComponent>
    </Box>

}

export default ParamsComponent;
