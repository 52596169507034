import React from "react";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";

const options = {
    TRUE: true,
    FALSE: false,
};

const BooleanDropdown = (props) => {

    const {isClearable = false} = props;

    return <EnumDropdown
        {...props}
        options={options}
        isSearchable={false}
        isClearable={isClearable}
    />
}


export default BooleanDropdown;
