import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";
import {isBoolean} from "system/Utils/isBoolean";

const ClosedMessageInfoComponent = props => {

    const {t, request, showTransponder: st} = props;

    const isAccepted = request.accepted;
    const style = isAccepted ? "primary" : "danger";
    const showTransponder = st && isAccepted;

    if (!request.closed)
        return <></>;


    if (isBoolean(isAccepted)) {

        return <AdvanceInfoMessage
            icon={faLock}
            type={style}
            iconType={style}
            title={t("request_is_closed")}>
            <Trans>
                {isAccepted
                    ? t('request_is_closed_msg', {status: t(isAccepted)})
                    : t('request_is_rejected_msg', request)
                }
            </Trans>

            {showTransponder &&
                <>&nbsp;
                    <Trans>
                        <Link to={"/animal/view/" + request?.transponder}>
                            {t('request_is_closed_msg_registered', request)}

                        </Link>
                    </Trans>
                </>
            }

        </AdvanceInfoMessage>
    }
    else  {
        return <AdvanceInfoMessage
            icon={faLock}
            type={style}
            iconType={style}
            title={t("request_is_canceled")}>
            <Trans>
                {t('request_is_canceled_msg')}
            </Trans>
        </AdvanceInfoMessage>
    }


}

export default ClosedMessageInfoComponent;
