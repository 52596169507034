import {useMemo} from "react";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {cloneWithoutKey} from "system/Objects/Objects";
import {OwnerType} from "types/Onwer";

const useOwnerAllowedTypes = () => {

    const userHasPermission = useUserHasPermission();
    const canRegisterTraces = useMemo(
        () => userHasPermission({permission: "REGISTER_OWNER_TRACES"}),
        [userHasPermission]
    );

    return useMemo(
        () => canRegisterTraces ? OwnerType : cloneWithoutKey(OwnerType, "TRACES"),
        [canRegisterTraces]
    );
}

export default useOwnerAllowedTypes;
