import SearchArea from "layout/modules/SearchArea/SearchArea";
import TransponderControl from "modules/Forms/Transponder/TransponderControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import React from "react";
import {useTranslation} from "react-i18next";
import LostListFilterComponent from "app/animals/components/lost/list/LostListFilterComponent";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import Box from "layout/modules/Box/Box";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import BallComponent from "app/animals/components/generic/BallComponent";
import classnames from "classnames";
import DateTime from "system/DateTime/DateTime";
import "app/animals/assets/css/lost-list.scss";
import ListEntryMenuOptionsComponent from "app/animals/components/lost/list/ListEntryMenuOptionsComponent";
import LostStatusComponent from "app/animals/components/lost/list/LostStatusComponent";
import {AnimalStatus} from "types/Animal";
import {useCountryTranslation} from "layout/modules/Forms/Dropdowns/CountryDropdown/useCountryList";

const LostListComponent = props => {

    const {
        data,
        loading,
        onSearch,
        onPageSort,
        searchFilter,
        onFilterSubmit,
        onPageChange,
    } = props;

    const {t} = useTranslation("animal");
    const getCountry = useCountryTranslation();

    return <Box className={"lost-list"}>

        <TableHeader>

            <SearchArea
                onSearch={onSearch}
                value={searchFilter.universalSearch}
                searchControl={() => {
                    return <TransponderControl
                        placeholder={t('transponder_ph')}
                        defaultValue={getOrEmpty(searchFilter.universalSearch)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearch(e.target.value)
                            }
                        }}
                        onValid={() => {
                        }}
                        onInvalid={(value) => {
                            if (value === "")
                                onSearch("");
                        }}
                    />
                }}>
                <SearchFilter onSubmit={(data) => onFilterSubmit(data)}>
                    <LostListFilterComponent searchFilter={searchFilter} t={t}/>
                </SearchFilter>
            </SearchArea>
        </TableHeader>

        <SearchDataPagination data={data} onPageChange={onPageChange}/>

        <TableAdvanced
            hover
            sort={data?.sortBy}
            order={data?.sortOrder?.toLowerCase()}
            onSortClick={onPageSort}
            loading={(loading)}>
            <thead>
            <tr>

                <th className={"d-none d-sm-table-cell"}>

                </th>

                <th>
                    {t('id')}
                </th>

                <th className={"d-none d-md-table-cell"}>
                    {t('breed')}
                </th>

                <th className={"d-none d-lg-table-cell"}>
                    {t('lost_local')}
                </th>

                <th id={"creationTimestamp"} className={"d-none d-lg-table-cell"} sorted>
                    {t('registration_date')}
                </th>

                <th id={"eventDate"} sorted className={"d-none d-sm-table-cell"}>
                    {t('occurrence_date')}
                </th>

                <th>
                    {t('status')}
                </th>

                <th/>
            </tr>
            </thead>
            <tbody>

            {data?.content?.map((lostInfo, index) => {
                return <tr key={index}>
                    <td className={"d-none d-sm-table-cell"}>
                        <div>
                            <BallComponent disableChange={true} animal={{
                                ...lostInfo,
                                status: lostInfo.isClosed
                                    ? AnimalStatus.NORMAL
                                    : lostInfo.status
                            }}/>
                        </div>
                    </td>

                    <td>
                        <div
                            className={classnames("line-transponder", {"merged-transponder": lostInfo?.hasMergedTransponders})}>
                            {lostInfo?.mergedTransponder
                                ? lostInfo?.mergedTransponder
                                : lostInfo.transponder
                            }
                        </div>

                        <div className={"line-regular fill-empty"}>
                            {!lostInfo.isExternal
                                ? lostInfo.name
                                : <div className={"external-animal"}>
                                    {t('external_animal')}
                                </div>
                            }
                        </div>

                    </td>

                    <td className={"col-breed d-none d-md-table-cell"}>

                        <div className={"line-title"}>
                            {lostInfo.breed?.parent?.name ? lostInfo.breed?.parent?.name + " | " : ""} {t(lostInfo.gender)}
                        </div>

                        <div className={"line-regular"}>
                            {lostInfo.breed?.name}
                            {lostInfo.crossBreed &&
                            <>&nbsp;X&nbsp;{lostInfo.crossBreed.name}</>
                            }
                        </div>

                    </td>

                    <td className={"d-none d-lg-table-cell col-location"}>
                        <div className={"line-title"}>
                            <>{lostInfo?.eventParish?.name}</>
                        </div>
                        <div className={"line-regular"}>
                            {lostInfo?.eventParish?.name
                                ? <>{lostInfo?.eventDistrict?.name} | {lostInfo?.eventCounty?.name}</>
                                : <>{getCountry(lostInfo?.eventCountry)}</>
                            }
                        </div>
                    </td>

                    <td className={"td-sorted d-none d-lg-table-cell"}>
                        <div className={"line-regular"}>
                            <DateTime format="DD-MM-YYYY">
                                {lostInfo?.creationTimestamp}
                            </DateTime>
                        </div>
                    </td>

                    <td className={"d-none d-sm-table-cell td-sorted"}>
                        <div className={"line-regular"}>
                            <DateTime format="DD-MM-YYYY" timezone={'UTC'}>
                                {lostInfo?.eventDate}
                            </DateTime>
                        </div>
                    </td>

                    <td>
                        <LostStatusComponent lostInfo={lostInfo}/>
                    </td>

                    <td className={"animal-menu-dropdown"}>
                        <ListEntryMenuOptionsComponent
                            {...props}
                            lostInfo={lostInfo}
                        />
                    </td>

                </tr>
            })}

            </tbody>
        </TableAdvanced>

    </Box>
}

export default LostListComponent;
