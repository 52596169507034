import {useApiState} from "system/API/APIStateContext";
import {useTranslation} from "react-i18next";
import {getInitials} from "app/support/utils/utils";
import React from "react";
import {useBillingBalanceState} from "modules/Billing/BillingStateContext";
import UserComponent from "layout/modules/BaseLayout/Menu/components/fixed/UserComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

const UserPropertiesComponent = props => {

    const {totalAnimals} = props;
    const {authentication} = useApiState();
    const billingState = useBillingBalanceState();

    const {t} = useTranslation("dashboard");


    return <div className={"user-properties"}>

        <UserComponent placement={"bottom"}>
            <div className={"user-card"}>
                <div className={"user-ball"}>
                    <div className={"letters"}>
                        {getInitials(authentication?.name)}
                    </div>
                </div>
                <div className={"user-id"}>
                    <div className={"name"}>
                        {authentication?.name}
                    </div>
                    <div className={"type"}>
                        {t(authentication?.type)}
                    </div>
                </div>

                <div className={"icon-dropdown"}>
                    <FontAwesomeIcon icon={faChevronDown}/>
                </div>

            </div>
        </UserComponent>

        {authentication?.entity?.name &&
            <div className={"user-info"}>
                <div className={"u-label"}>
                    {t('entity')}
                </div>

                <div className={"u-value"}>
                    {authentication?.entity?.name}
                </div>
            </div>
        }

        {authentication?.role?.name &&
            <div className={"user-info"}>
                <div className={"u-label"}>
                    {t('role')}
                </div>

                <div className={"u-value"}>
                    {authentication?.role?.name}
                </div>
            </div>
        }


        {billingState.isAvailable &&
            <div className={"user-info"}>
                <div className={"u-label"}>
                    {t('credits')}
                </div>

                <div className={"u-value"}>
                    {billingState.balance}
                </div>
            </div>
        }

        {totalAnimals > 0 &&
            <div className={"user-info"}>
                <div className={"u-label"}>
                    {t('total_animals')}
                </div>

                <div className={"u-value"}>
                    {totalAnimals}
                </div>
            </div>
        }

    </div>
}

export default UserPropertiesComponent;
