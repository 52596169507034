const MenuDropdownHeader = (props) => {

    const {visible = true} = props;

    if (!visible)
        return null;

    return <div className={"menu-drop-down-header"}>
        {props.children}
    </div>

}

export default MenuDropdownHeader;
