import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import IDNumberComponent from "./IDNumberComponent";
import {OwnerType} from "types/Onwer";
import CountryDropdown from "layout/modules/Forms/Dropdowns/CountryDropdown/CountryDropdown";
import {getOrDefault, getOrEmpty} from "system/Objects/ObjectParameters";
import Box from "layout/modules/Box/Box";
import FormControl from "layout/modules/Forms/Control/FormControl";
import appConfig from "configs/config";
import useOwnerIdModel from "app/owners/utils/form/useOwnerIdModel";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import useLockFields from "app/owners/utils/useLockFields";

const PersonalDataComponent = (props) => {

    const {errors = {}, changes = {}, owner, setOwner, readOnly, lockFields: remoteLockFields = {}} = props;
    const {t} = useTranslation('owner');

    const modelIdCard = useOwnerIdModel(owner);

    const handleNationalityChange = useCallback(value => setOwner(owner => ({
        ...owner,
        nationality: value,
        hasLocalVatNumber: (value === appConfig.defaultCountry || owner?.country === appConfig.defaultCountry) ? true : undefined
    })), [setOwner]);


    const lockFields = useLockFields({owner, remoteLockFields, create: !owner?.id});

    return <Box>
        <BoxTitle>{props.title ?? t('owner_data')}</BoxTitle>

        <Row className={"mandatory"}>

            {(owner.type === OwnerType.SINGULAR) &&
            <Col sm={12} lg={6}>
                <FormGroup error={errors['nationality']} changes={changes['nationality']}>
                    <Form.Label>{t('nationality')}</Form.Label>
                    <CountryDropdown
                        id={"nationality"}
                        isDisabled={readOnly || lockFields['nationality']}
                        value={getOrDefault(owner?.nationality, appConfig.defaultCountry)}
                        onChange={handleNationalityChange}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['nationality'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            }

            <IDNumberComponent
                owner={owner}
                readOnly={readOnly}
                setOwner={setOwner}
                errors={errors}
                changes={changes}
                lockFields={lockFields}
                model={modelIdCard}
            />

            <Col sm={12} lg={6}>
                <FormGroup error={errors['name']} changes={changes['name']}>
                    <Form.Label>{t('name')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('name_ph')}
                        disabled={readOnly || lockFields['name']}
                        value={getOrEmpty(owner?.name)}
                        onChange={useCallback(e => setOwner(owner => ({
                            ...owner,
                            name: e.target.value,
                        })), [setOwner])}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['name'])}
                    </Form.Text>
                </FormGroup>
            </Col>

        </Row>
    </Box>

}

export default PersonalDataComponent;
