import React, {useEffect} from 'react';
import locale from "./locales/locales"
import {useMutate} from "system/Rest/Rest"
import RecoveryFormComponent from "./components/RecoveryFormComponent"
import {useApiState} from "system/API/APIStateContext";
import useErrorParser from "system/API/Error/ErrorParser";
import {setLangConfig} from "configs/i18n/i18n";
import {Navigate, useNavigate} from "react-router";


const Recovery = (props) => {

    const {authentication} = useApiState();
    const navigate = useNavigate();

    const {mutate: forgot, loading: loadingForgot, error: forgotError} = useMutate({
        verb: "POST",
        path: "/auth/forgot/",
    });

    const errors = useErrorParser(forgotError);

    useEffect(() => setLangConfig('login', locale), []);

    if (authentication?.isAuthenticated) {
        return <Navigate to='/'/>
    }

    return <RecoveryFormComponent
        loading={loadingForgot}
        errors={errors}
        onSubmit={(email) => {
            forgot({email: email,})
                .then((result) => {
                    navigate('/auth',{
                        state: {
                            forgotSuccess: true
                        }
                    });
                })
                .catch(e => {
                }); //only to prevent warning

        }}
    />;
}

export default Recovery;
