import React from "react";
import locale from "./locales/locales";
import CreateComponent from "./components/CreateComponent";
import {AnimalStateProvider} from "./utils/AnimalContext";
import {useBillingBalanceState} from "modules/Billing/BillingStateContext";
import Unauthorized from "layout/modules/ErrorPages/Unauthorized";
import useTranslations from "system/Translations/UseTranslations";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";


const AnimalCreate = (props) => {

    const {t} = useTranslations('animal', locale);

     const billingState = useBillingBalanceState();

     if (!billingState.canRegisterAnimal && !billingState.canRegisterNotBillableAnimal) {
        return <Unauthorized {...props}/>
    }

    return <Title
        title={t('create_title')}
        breadcrumbs={[
            {href: "/animal", label: t('list')},
            {href: "/animal/create", label: t('create')},
        ]} {...props}>

        <AnimalStateProvider {...props}>
            <CreateComponent {...props}/>
        </AnimalStateProvider>
    </Title>
}

export default AnimalCreate;
