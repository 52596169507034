import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {handleIsLocalResident, handleResidenceCountryChange} from "app/owners/utils/form/formUtils";
import useOwnerAllowedTypes from "app/owners/utils/form/OwnerAllowedTypes";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrDefault, getOrNull} from "system/Objects/ObjectParameters";
import {OwnerType} from "types/Onwer";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import CountryDropdown from "layout/modules/Forms/Dropdowns/CountryDropdown/CountryDropdown";
import appConfig from "configs/config";
import useLockFields from "app/owners/utils/useLockFields";

const TypeComponent = (props) => {

    const {errors = {}, changes = {}, owner, setOwner, readOnly, lockFields: remoteLockFields = {}, create} = props;

    const {t} = useTranslation('owner');
    const ownerTypes = useOwnerAllowedTypes();

    const lockFields = useLockFields({owner, remoteLockFields, create: !owner?.id});

    return <Row className={"mandatory"}>
        <Col sm={12} lg={6}>
            <FormGroup error={errors['type']} changes={changes['type']}>
                <Form.Label>{t('type')}</Form.Label>
                <EnumDropdown
                    id={"type"}
                    t={t}
                    isSearchable={false}
                    value={getOrDefault(owner.type,OwnerType.SINGULAR)}
                    options={readOnly ? OwnerType : ownerTypes}
                    isClearable={false}
                    isDisabled={readOnly || lockFields['type']}
                    onChange={useCallback(val => setOwner(owner => ({
                        ...owner,
                        type: val,
                        //resets country
                        nationality: owner?.country,
                        hasLocalVatNumber: undefined,
                    })), [setOwner])}

                />

                <Form.Text className="sub-error">
                    {t(errors['type'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6}>
            <FormGroup error={errors['isLocalResident']} changes={changes['isLocalResident']}>
                <Form.Label>{t('local_residence', {country: t(appConfig.defaultCountry)})}</Form.Label>
                <BooleanDropdown
                    id={"isLocalResident"}
                    isDisabled={readOnly || lockFields['country']}
                    value={parseBoolean(owner.isLocalResident)}
                    onChange={useCallback(val => setOwner(owner => ({
                        ...owner,
                        ...handleIsLocalResident(val, owner, create)
                    })), [setOwner, create])}

                />

                <Form.Text className="sub-error">
                    {t(errors['isLocalResident'])}
                </Form.Text>
            </FormGroup>
        </Col>


        {(owner?.isLocalResident === false) &&
        <Col sm={12} lg={true}>
            <FormGroup error={errors['country']} changes={changes['country']}>
                <Form.Label>{t('residence_country')}</Form.Label>
                <CountryDropdown
                    id={"country"}
                    isDisabled={readOnly || lockFields['country']}
                    excludeList={[appConfig.defaultCountry]}
                    value={getOrNull(owner?.country)}
                    onChange={value => setOwner(owner => ({
                        ...owner,
                        ...handleResidenceCountryChange(value, owner, create),
                    }))}
                />

                <Form.Text className="sub-error">
                    {t(errors['country'])}
                </Form.Text>
            </FormGroup>
        </Col>
        }

    </Row>
}

export default TypeComponent;
