import React, {useEffect, useRef, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import useOnScreen from "system/Components/useOnScreen";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import {AnimalStatus} from "types/Animal";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import BreedDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/BreedDropdown";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import FormGeofence from "modules/Forms/Geofence/GeofenceForm";
import appConfig from "configs/config";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import FormControl from "layout/modules/Forms/Control/FormControl";
import BreedGroupType from "types/BreedGroupType";

const ListFilterComponent = (props) => {

    const {searchFilter, t} = props;
    const [filterState, setFilterState] = useState();

    const filterRef = useRef(null);
    const isVisible = useOnScreen(filterRef);

    useEffect(() => {
        setFilterState({
            ...searchFilter
        });
    }, [searchFilter]);

    return <div ref={filterRef}>
        {filterState &&
        <>
            <Row>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('name')}</Form.Label>
                        <FormControl
                            type="text"
                            id={"name"}
                            placeholder={t('name_ph')}
                            value={getOrEmpty(filterState?.name)}
                            onChange={(e) => {
                                setFilterState({
                                    ...filterState,
                                    name: e.target.value,
                                })
                            }}

                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('status')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            placeholder={t('status_ph')}
                            id={"status"}
                            isMulti={true}
                            isDisabled={parseBoolean(filterState.externalLostAnimals, false)}
                            options={AnimalStatus}
                            value={getOrNull(filterState.status)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    status: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup filter-ignore-values={[false]}>
                        <Form.Label>{t('limit_external_lost_animals')}</Form.Label>
                        <BooleanDropdown
                            id="externalLostAnimals"
                            isClearable={true}
                            value={parseBoolean(filterState.externalLostAnimals, null)}
                            onChange={(val) => {
                                setFilterState({
                                    externalLostAnimals: val
                                })
                            }}
                        />
                    </FormGroup>
                </Col>


                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('breed')}</Form.Label>
                        <BreedDropdown
                            id={"breedIds"}
                            groupType={BreedGroupType.BREED}
                            includeParentOnLabel={true}
                            isMulti={true}
                            value={getOrNull(filterState?.breedIds)}
                            onChange={(breedId) => setFilterState(filterState => ({
                                ...filterState,
                                breedIds: breedId,
                            }))}

                        />
                    </FormGroup>
                </Col>

            <UserHasPermission permission={"READ_ENTITY"}>
                    <Col sm={12} lg={9}>
                        <FormGroup>
                            <Form.Label>{t('entity')}</Form.Label>
                            <EntityDropdown
                                id={"entityIds"}
                                value={getOrNull(filterState?.entityIds)}
                                isMulti={true}
                                onChange={(value) => {
                                    setFilterState({
                                        ...filterState,
                                        entityIds: value,
                                    })
                                }}
                            />
                        </FormGroup>
                    </Col>


                    <Col sm={12} lg={3}>
                        <FormGroup filter-ignore-values={[true]}>
                            <Form.Label>{t('include_sub_entities')}</Form.Label>
                            <BooleanDropdown
                                id="includeChildrenEntities"
                                value={parseBoolean(filterState.includeChildrenEntities, true)}
                                onChange={(val) => {
                                    setFilterState({
                                        ...filterState,
                                        includeChildrenEntities: val
                                    })
                                }}
                                isDisabled={!filterState.entityIds}
                            />
                        </FormGroup>
                    </Col>
            </UserHasPermission>

            {appConfig.defaultCountry === "PT" &&
                <Col sm={12} lg={12}>
                    <FormGeofence
                        t={t}
                        visible={isVisible}
                        ignoreSubmit={false}
                        readOnly={parseBoolean(filterState.externalLostAnimals, false)}
                        entry={{
                            districts: getOrNull(filterState.districts),
                            counties: getOrNull(filterState.counties),
                            parishes: getOrNull(filterState.parishes),
                        }}
                        onChange={(val) => setFilterState(filterState => ({
                            ...filterState,
                            ...val,
                        }))}
                    />
                </Col>
            }

            </Row>
        </>
        }

    </div>

}

export default ListFilterComponent;
