import {faCheck} from "@fortawesome/free-solid-svg-icons";
import locale from "app/personalArea/locales/locales";
import {useCallback} from "react";
import {useApiState} from "system/API/APIStateContext";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useTranslations from "system/Translations/UseTranslations";

const useExitImpersonate = () => {

    const {setAuthentication, authentication} = useApiState();
    const showToast = useShowToast();
    const {t} = useTranslations('personalArea', locale);

    return useCallback(
        () => {
            if (authentication.impersonate) {
                showToast({
                    title: t('SUCCESS_IMPERSONATE'),
                    message: t('SUCCESS_EXIT_IMPERSONATE_MSG'),
                    icon: faCheck,
                });
                setAuthentication({...authentication.impersonate})
            }
        },
        [authentication, showToast, setAuthentication, t]
    );
}

export default useExitImpersonate;
