import {useGet, useMutate} from "system/Rest/Rest";
import {useCallback, useEffect, useState} from "react";
import AdminLabelComponent from "app/adminLabels/components/AdminLabelComponent";
import {getOrDefault} from "system/Objects/ObjectParameters";

const ShowAdminLabels = props => {

    const {labels: defaultLabels, objectType, objectId} = props;
    const [readOnly, setReadOnly] = useState(true);

    const [labels, setLabels] = useState(getOrDefault(defaultLabels, []));

    const {mutate: addLabel, loading: addingLabel} = useMutate({
        verb: "POST",
        path: "/labels/",
    });

    const {mutate: delLabel, loading: deletingLabel} = useMutate({
        verb: "DELETE",
        path: "/labels/"
    });

    const {data: remoteLabels, refetch: reloadLabels, loading: loadingLabels} = useGet({
        path: "/labels/" + objectType + "/" + objectId,
        lazy: defaultLabels
    });

    const {data: list, refetch: reloadList, loading: loadingList} = useGet({
        path: "/labels/",
        lazy: readOnly,
    });

    useEffect(
        () => {
            if (remoteLabels)
                setLabels(remoteLabels)
        },
        [remoteLabels, setLabels]
    );

    useEffect(
        () => {
            if (defaultLabels)
                setLabels(defaultLabels)
        },
        [defaultLabels, setLabels]
    );

    return <AdminLabelComponent
        labels={labels}
        reloadLabels={reloadLabels}
        list={list}
        reloadList={reloadList}
        loading={addingLabel || loadingList || loadingLabels || deletingLabel}
        setReadOnly={setReadOnly}
        readOnly={readOnly}
        onAddLabel={useCallback(
            (label) => {
                setLabels(cl => cl.concat([label]));
                addLabel({
                    objectType: objectType,
                    objectId: objectId,
                    value: label,
                });
            },
            [addLabel, setLabels,objectType, objectId]
        )}
        onDelLabel={useCallback(
            (label) => {
                setLabels(cl => cl.filter(x => x !== label));
                delLabel({
                    objectType: objectType,
                    objectId: objectId,
                    value: label,
                });
            },
            [setLabels, delLabel,objectType, objectId]
        )}

    />


}

export default ShowAdminLabels;
