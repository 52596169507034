import {getOrDefault} from "system/Objects/ObjectParameters";

const getVersionLabel = (index,version, getDate,t ) => {

    if (version?.isOwnerCreationUser)
        return (index+1) + " - " + version?.revisionId + " - " + getDate(version.revisionTimestamp) + " - " + t('owner_version')
    else
        return (index+1) + " - " + version?.revisionId + " - " + getDate(version.revisionTimestamp) + " - " + version.creationUser?.name + " - " + getOrDefault(version.creationUser?.entity, t('no_entity'))
}

export default getVersionLabel;
