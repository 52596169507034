import locale from "app/vaccines/locales/locales";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormComponent from "app/vaccines/components/FormComponent";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import {getDiffsInErrorFormat, getOriginalRawObject} from "app/audit/utils/AuditDiff";

const RabiesVaccine = (props) => {

    const {operation} = props;
    useTranslations("vaccine", locale);

    const diffs = getDiffsInErrorFormat(operation.changes);

    return <div className={"versions"}>
        <Row>
            <Col lg={true}>
                <FormComponent
                    vaccine={operation.objectRaw}
                    errors={diffs}
                    create={false}
                    compare={true}
                    readOnly={true}
                    loading={false}
                />
            </Col>

            {operation.changes &&
            <Col lg={6}>
                <FormComponent
                    vaccine={getOriginalRawObject(operation.objectRaw, operation.changes)}
                    errors={diffs}
                    create={false}
                    compare={true}
                    readOnly={true}
                    loading={false}
                />

            </Col>
            }
        </Row>

    </div>

}

export default RabiesVaccine;
