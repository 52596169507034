import React, {useCallback, useEffect, useMemo, useState} from "react";
import DatePickerComponent from "./components/DatePickerComponent";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import {useConvertedDate, useTimezone} from "system/DateTime/DateTime";
import {getDateString, getUnixTimestamp, validateDateString} from "layout/modules/Forms/DatePicker/utils/Utils";
import "react-day-picker/dist/style.css";
import './assets/DatePicker.scss';

const DatePicker = (props) => {

    const {
        defaultValue, value, name = "date", onChange,
        onlyPastDates, onlyFutureDates, fromDate, toDate,
        outputEndOfDayTimestamp = false, timezone, ...otherProps
    } = props;

    const userTimezone = useTimezone();
    const [timestamp, setTimestamp] = useState(defaultValue);
    const convertTimestampToDate = useConvertedDate();

    useEffect(
        () => {
            if (value !== undefined && value !== timestamp) {
                setTimestamp(value);
            }
        }, [value, timestamp]
    );

    const fd = useMemo(
        () => {
            if (onlyFutureDates) {
                return getDateString(new Date());
            } else if (fromDate) {
                return getDateString(convertTimestampToDate(fromDate, timezone))
            }
            return undefined;
        },
        [onlyFutureDates, fromDate, timezone, convertTimestampToDate]
    );

    const td = useMemo(
        () => {
            if (onlyPastDates) {
                return getDateString(new Date());
            } else if (toDate) {
                return getDateString(convertTimestampToDate(toDate, timezone))
            }
            return undefined;
        },
        [onlyPastDates, toDate, timezone, convertTimestampToDate]
    );

    return <>
        <input type="hidden" name={name} value={getOrEmpty(timestamp)}/>
        <DatePickerComponent
            {...otherProps}
            name={name + "-picker"}
            value={useMemo(
                () => timestamp ? getDateString(convertTimestampToDate(timestamp, timezone)) : "",
                [convertTimestampToDate, timestamp, timezone]
            )}
            fromDate={fd}
            toDate={td}
            onChange={useCallback(date => {
                if (validateDateString(date)) {
                    let timestamp = getUnixTimestamp(date, timezone ? timezone : userTimezone);
                    if (outputEndOfDayTimestamp) {
                        timestamp += ((1000 * 60 * 60 * 24) - 1);
                    }
                    setTimestamp(timestamp);

                    if (onChange) {
                        onChange(timestamp)
                    }
                } else {
                    if (onChange) {
                        onChange(null);
                    }

                    setTimestamp(null);
                }

            }, [onChange, setTimestamp, userTimezone, timezone, outputEndOfDayTimestamp])}
        />

    </>

}

export default DatePicker;
