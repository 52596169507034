const MenuDropdownOption = (props) => {

    const {visible = true} = props;

    if (visible) {
        return <div className={"menu-drop-down-option"}>
            {props.children}
        </div>
    }
    else {
        return <></>
    }

}

export default MenuDropdownOption;
