import {AuditObjectTypes} from "../../utils/AuditObjectTypes";
import HealthInterventionComponent from "./types/HealthInterventionComponent";
import AnimalLicenseComponent from "./types/AnimalLicenseComponent";
import AggressionComponent from "./types/AggressionComponent";
import GenericComponent from "./types/GenericComponent";
import UserComponent from "./types/UserComponent";
import RabiesVaccine from "./types/RabiesVaccine";
import AnimalTransferComponent from "./types/AnimalTransferComponent";
import CertificateComponent from "./types/CertificateComponent";
import EntityVeterinaryAssociationComponent from "./types/EntityVeterinaryAssociationComponent";
import ReportTemplateComponent from "app/audit/components/Operation/types/ReportTemplateComponent";
import ReportScheduleComponent from "app/audit/components/Operation/types/ReportScheduleComponent";
import ReportGenerateComponent from "app/audit/components/Operation/types/ReportGenerateComponent";
import BillingTransactionComponent from "app/audit/components/Operation/types/BillingTransactionComponent";
import OperationTemplateComponent from "app/audit/components/Operation/types/OperationTemplateComponent";

const ShowComponent = (props) => {

    const {operation} = props;

    switch (operation.objectType) {
        case AuditObjectTypes.RabiesProphylaxis:
        case AuditObjectTypes.HealthIntervention:
        case AuditObjectTypes.Sterilization:
            return <HealthInterventionComponent {...props}/>
        case AuditObjectTypes.AnimalLicense:
            return <AnimalLicenseComponent {...props} />
        case AuditObjectTypes.Aggression:
            return <AggressionComponent {...props} />
        case AuditObjectTypes.User:
            return <UserComponent {...props} />
        case AuditObjectTypes.RabiesVaccine:
            return <RabiesVaccine {...props} />
        case AuditObjectTypes.AnimalTransfer:
            return <AnimalTransferComponent {...props}/>
        case AuditObjectTypes.Certificate:
            return <CertificateComponent {...props}/>
        case AuditObjectTypes.EntityVeterinaryAssociation:
            return <EntityVeterinaryAssociationComponent {...props}/>
        case AuditObjectTypes.ReportTemplate:
            return <ReportTemplateComponent {...props}/>
        case AuditObjectTypes.OperationTemplate:
            return <OperationTemplateComponent {...props}/>
        case AuditObjectTypes.ReportSchedule:
            return <ReportScheduleComponent {...props}/>
        case AuditObjectTypes.OperationSchedule:
            return <ReportScheduleComponent {...props} isOperation={true}/>
        case AuditObjectTypes.Operation:
            return <ReportGenerateComponent {...props} isOperation={true}/>
        case AuditObjectTypes.Report:
            return <ReportGenerateComponent {...props}/>
        case AuditObjectTypes.BillingTransaction:
            return <BillingTransactionComponent {...props}/>
        default:
            return <GenericComponent {...props}/>

    }

}

export default ShowComponent;
