import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "../../assets/css/attachments.scss";
import classnames from "classnames";

const AttachmentComponent = props => {

    const {attachment, iconClassName} = props;

    return <div className={"attachment"}>
        <a href={attachment.url} target="_blank" rel="noopener noreferrer" onClick={attachment.onClick}>
            <div className={"file-name"}>
                {attachment.name}
            </div>

            <div className={classnames("icon", iconClassName)}>
                <FontAwesomeIcon icon={attachment.icon ? attachment.icon : faFileDownload}/>
            </div>
        </a>
    </div>;

}

export default AttachmentComponent;
