import {useGet} from "system/Rest/Rest";
import React, {useMemo} from "react";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";

const DuplicatedEntryMessage = props => {

    const {t, request} = props;

    const {data: contents} = useGet({
        path: "/animals/pre-register/search",
        queryParams: useMemo(() => ({
            universalSearch: request.transponder,
            isClosed: false,
        }), [request])
    });

    if (!contents?.totalItems || contents?.totalItems === 1)
        return <></>

    return <AdvanceInfoMessage
        icon={faExclamation}
        type={"danger"}
        iconType={"danger"}
        title={t("duplicated_entry")}
        message={t('duplicated_entry_msg', {total: (contents?.totalItems - 1)})}
    />
}

export default DuplicatedEntryMessage;
