import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import FormGroup from "../../../../layout/modules/Forms/Group/FormGroup";
import {getOrNull} from "../../../../system/Objects/ObjectParameters";
import EnumDropdown from "../../../../layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import TicketStatus from "../../utils/TicketStatus";
import DatePicker from "../../../../layout/modules/Forms/DatePicker/DatePicker";
import {parseNumber} from "../../../../system/Utils/parseNumber";


const ListFilterComponent = (props) => {

    const {searchFilter, t} = props;
    const [filterState, setFilterState] = useState();

    useEffect(() => {
        setFilterState({
            ...searchFilter
        });
    }, [searchFilter]);


    if (!filterState) {
        return <></>;
    }

    return <>
        <Row>
            <Col sm={12} lg={4}>
                <FormGroup>
                    <Form.Label>{t('status')}</Form.Label>
                    <EnumDropdown
                        t={t}
                        placeholder={t('status_ph')}
                        id={"status"}
                        isMulti={true}
                        options={TicketStatus}
                        value={getOrNull(filterState.status)}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState,
                                status: val,
                            })
                        }}
                    />

                </FormGroup>
            </Col>

            <Col sm={12} lg={4}>
                <FormGroup>
                    <Form.Label>{t('from_date')}</Form.Label>
                    <DatePicker
                        name={"fromTimestamp"}
                        placeholder={t("date_ph")}
                        value={parseNumber(filterState.fromTimestamp, undefined)}
                        toDate={parseNumber(filterState.toTimestamp, undefined)}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState,
                                fromTimestamp: val,
                            })
                        }}
                    />
                </FormGroup>
            </Col>

            <Col sm={12} lg={4}>
                <FormGroup>
                    <Form.Label>{t('to_date')}</Form.Label>
                    <DatePicker
                        name={"toTimestamp"}
                        placeholder={t("date_ph")}
                        value={parseNumber(filterState.toTimestamp, undefined)}
                        fromDate={parseNumber(filterState.fromTimestamp, undefined)}
                        outputEndOfDayTimestamp={true}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState,
                                toTimestamp: val,
                            })
                        }}
                    />
                </FormGroup>
            </Col>


        </Row>
    </>
}

export default ListFilterComponent;
