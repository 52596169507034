const GenderType = {
    MALE: "MALE",
    FEMALE: "FEMALE",
};
const GenderTypeWithUndefined = {
    ...GenderType,
    UNDEFINED: "UNDEFINED",
};

const AnimalStatus = {

    DEAD: "DEAD",
    FOUND: "FOUND",
    LOST: "LOST",
    NORMAL: "NORMAL",

};

const AnimalLostStatus = {
    FOUND: "FOUND",
    LOST: "LOST",
};

export {GenderType, GenderTypeWithUndefined, AnimalStatus, AnimalLostStatus};
