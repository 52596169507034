import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import EmailComponent from "./form/EmailComponent";
import "app/certificates/assets/certificate-modal.scss";
import Loading from "layout/modules/Loading/Loading";
import ModalWithNoMargin from "layout/modules/Modal/ModalWithNoMargin/ModalWithNoMargin";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";

const BulkGenerateComponent = (props) => {

    const {t} = useTranslation('certificate');
    const {request, setRequest, onClose, loading, errors, onSubmit} = props;

    return <Modal
        show={true}
        onHide={loading ? () => void 0 : onClose}
        size={"xl"}
        className={"certificate-modal"}
        backdrop="static"
        keyboard={false}
    >

        <Loading visible={loading}/>
        <Modal.Header closeButton>
            <Modal.Title>{t('get_bulk_diac')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {t('get_bulk_diac_msg')}
            <ModalWithNoMargin>
                <div className={"form-body"}>
                    <EmailComponent
                        request={request}
                        setRequest={setRequest}
                        errors={errors}
                    />
                </div>
            </ModalWithNoMargin>
        </Modal.Body>

        <ModalButtons>
            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>

            <Button
                variant="primary"
                onClick={useCallback(() => onSubmit().then(onClose),[onSubmit, onClose])}>
                {t("confirm")}
            </Button>
        </ModalButtons>

    </Modal>

}

export default BulkGenerateComponent;
