const ObjectType = {
    ANIMAL: "ANIMAL",
    ANIMAL_AGGRESSION: "ANIMAL_AGGRESSION",
    ANIMAL_PRE_REGISTER: "ANIMAL_PRE_REGISTER",
    ANIMAL_TRANSFER: "ANIMAL_TRANSFER",
    ANIMAL_TRANSFER_REQUEST: "ANIMAL_TRANSFER_REQUEST",
    ANIMAL_LICENSE: "ANIMAL_LICENSE",
    ENTITY: "ENTITY",
    ENTITY_PROFILE: "ENTITY_PROFILE",
    HEALTH_INTERVENTION: "HEALTH_INTERVENTION",
    LOST_ANIMAL: "LOST_ANIMAL",
    PENDING_LOST_ANIMAL: "PENDING_LOST_ANIMAL",
    OWNER_REVIEW: "OWNER_REVIEW",
    OWNER_MERGE: "OWNER_MERGE",
    TICKET: "TICKET",
    USER: "USER",
    VETERINARY: "VETERINARY",
    OWNER_DATA_UPDATE: "OWNER_DATA_UPDATE",
    ANIMAL_DEATH_REQUEST: "ANIMAL_DEATH_REQUEST",
    ANIMAL_DIAC_REQUEST: "ANIMAL_DIAC_REQUEST"

};

export default ObjectType;
