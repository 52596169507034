import SearchComponent from "../components/SearchComponent";
import {useGet} from "system/Rest/Rest";
import {useCallback, useEffect, useMemo, useState} from "react";

const SearchContainer = (props) => {

    const [transponder, setTransponder] = useState("");
    const [results, setResult] = useState();

    const {data: animals, loading, refetch: reload} = useGet({
        path: "/animals/search",
        queryParams: useMemo(() => ({
            universalSearch: transponder,
            itemsPerPage: 2,
        }), [transponder]),
        lazy: !transponder,
    });

    const isValidSearchResult = useMemo(
        () => {
            //TO REMOVE THIS WORKAROUND!
            if (results?.totalItems > 0) {
                return results?.content?.every(a => (
                        a.transponder === transponder ||
                        a.transponder === transponder + "ERRO" ||
                        a.mergedTransponder?.includes(transponder)))
            }
            return false;
        },
        [results,transponder]
    );

    useEffect(() => setResult(animals), [animals, setResult]);

    return <SearchComponent
        {...props}
        transponder={transponder}
        searching={loading}
        results={results}
        reload={reload}
        isValidSearchResult={isValidSearchResult}
        setTransponder={useCallback(
            (transponder) => {
                setResult(null);
                setTransponder(transponder);
            },
            [setResult, setTransponder]
        )}
    />

}

export default SearchContainer;
