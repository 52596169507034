import {useCallback, useMemo} from "react";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {OwnerType} from "types/Onwer";

const useOwnerEditAllowedFields = props => {

    const {owner, remoteLockFields = {}} = props;
    const userHasPermission = useUserHasPermission();

    return useCallback(
        (field) => {

            //Uses a server var to check if is existent
            const isCreate = !(owner?.hasOwnProperty('needsReview') || owner?.hasOwnProperty('verifiedByOwner')
                || owner?.hasOwnProperty('id'))

            if (owner?.editLocked || owner.isLocked)
                return false;
            else if (isCreate)
                return true;
            else if (remoteLockFields[field] !== undefined) {
                return !remoteLockFields[field];
            }

            switch (field) {
                case 'name':
                    return !(owner?.verifiedData && !userHasPermission({permission: "WRITE_OWNER_VALIDATED_ID_FIELDS"}));
                case 'vatNumber':
                case 'idCard':
                case 'type':
                case 'nationality':
                case 'country':
                case 'passport':
                    if (owner?.verifiedData && !userHasPermission({permission: "WRITE_OWNER_VALIDATED_ID_FIELDS"})) {
                        return false;
                    }
                    return userHasPermission({permission: "WRITE_ANIMAL_PROTECTED_FIELDS"});
                case 'email':
                    return !(owner.type === OwnerType.COLLECTIVE &&
                        owner?.verifiedData && !userHasPermission({permission: "WRITE_OWNER_VALIDATED_ID_FIELDS"}));
                default:
                    return true;

            }
        },
        [owner, userHasPermission,remoteLockFields]
    )

}


const useLockFields = props => {

    const {owner, remoteLockFields = {}} = props;
    const isFieldAllowed = useOwnerEditAllowedFields({owner, remoteLockFields})

    return useMemo(
        () => {
            const vatAllowed = isFieldAllowed('vatNumber');
            const idCardAllowed = isFieldAllowed('idCard');

            return {
                type: !isFieldAllowed('type'),
                name: !isFieldAllowed('name'),
                passport: !isFieldAllowed('passport'),
                idCard: !idCardAllowed,
                nationality: !isFieldAllowed('nationality'),
                country: !isFieldAllowed('country'),
                vatNumber: remoteLockFields['allowVatChange'] ? false : !vatAllowed,
                email: !isFieldAllowed('email'),
                phone: !isFieldAllowed('phone'),
                phone2: !isFieldAllowed('phone2'),
                phone3: !isFieldAllowed('phone3'),
                address: !isFieldAllowed('address'),
            }
        },
        [remoteLockFields, isFieldAllowed]
    )
}

export default useLockFields;
