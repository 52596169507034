import {getOrDefault} from "system/Objects/ObjectParameters";
import React, {useCallback, useMemo} from "react";
import {SelectEvents} from "modules/Forms/Dropdowns/ClassDropdown/utils/SelectEvents";
import CreatableSelect from "react-select/creatable";
import processListToObjectList from "app/adminLabels/utils/ProcessListToObjectList";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/adminLabels/locale/locales";

const LabelsEditModeComponent = props => {

    const {t} = useTranslations('adminLabel', locale);
    const {list, labels, loading, readOnly, onAddLabel, onDelLabel} = props;

    const objectList = useMemo(
        () => processListToObjectList(list),
        [list]
    );

    const labelList = useMemo(
        () => processListToObjectList(labels),
        [labels]
    );

    const handlePlaceHolder = usePlaceHolder(props.placeholder, t('placeholder'));
    const noOptionsMessage = useLabelCallback(props.noOptionsMessage, t('no_results'));
    const loadingMessage = useLabelCallback(props.loadingMessage, t('loading_message'));
    const createMessage = useCallback((x) => x, []);

    return <CreatableSelect
        classNamePrefix={"rs"}
        className={"rs"}
        isCreatable={true}
        isMulti={true}
        isClearable={false}
        isLoading={loading}
        isDisabled={readOnly}
        placeholder={handlePlaceHolder}
        noOptionsMessage={noOptionsMessage}
        formatCreateLabel={createMessage}
        loadingMessage={loadingMessage}
        value={getOrDefault(labelList, [])}
        options={getOrDefault(objectList, [])}
        onChange={useCallback((val, event) => {
                switch (event.action) {
                    case SelectEvents.ADD:
                    case SelectEvents.ADD_EXISTENT:
                        onAddLabel(event?.option?.value);
                        break;
                    default:
                        onDelLabel(event?.removedValue?.value);
                        break;
                }
            },
            [onAddLabel, onDelLabel]
        )}
    />
}

export default LabelsEditModeComponent;
