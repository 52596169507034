
const validateZipCode = (value) => value && value?.match( "^[0-9]{4}(-)([0-9]{3})$") !== null;

const filterZipCode = (iVal) => {

    const filterZipCodePattern = "^[0-9]{1,4}(-)?([0-9]{1,3})?$";
    const value = iVal.trim(iVal);

    if (!value.match(filterZipCodePattern) && value.length > 0) {
        return false;
    }
    // add the bar
    if (value.length > 4 && value.indexOf('-') === -1) {
        return [value.slice(0, 4), "-", value.slice(4)].join('');
    }

    return value;
}

const getZipCode = (value) => {

    if (validateZipCode(value)) {
        const zipCode = value.split("-");
        return {
            cp4: zipCode[0],
            cp3: zipCode[1]
        }
    }
    return {
        cp4: undefined,
        cp3: undefined,
    }

}



export {filterZipCode, validateZipCode,getZipCode}
