const BreedGroupType = {
    INTERMEDIATE_GROUP: "INTERMEDIATE_GROUP",
    FINAL_GROUP: "FINAL_GROUP",
    BREED: "BREED",
}

const isBreed = (breed) => breed?.groupType === BreedGroupType.BREED || breed === BreedGroupType.BREED ;
const isFinalGroup = (breed) => breed?.groupType === BreedGroupType.FINAL_GROUP || breed === BreedGroupType.FINAL_GROUP;

export {isBreed, isFinalGroup};
export default BreedGroupType;
