import useTranslations from "system/Translations/UseTranslations";
import locale from "app/animals/locales/locales";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import React, {useMemo} from "react";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";
import LostListComponent from "app/animals/components/lost/list/LostListComponent";
import AnimalLostListComponent from "app/animals/components/lost/list/AnimalLostListComponent";

const LostList = props => {

    const {t} = useTranslations('animal', locale);
    const {transponder} = props;

    return <Title title={t('lost_list')} breadcrumbs={[{href: "/animal/lost", label: t('lost_list')}]} disable={!!transponder} {...props}>
        <GenericListContainer
            {...props}
            id={"lost-list"}
            hideTitle={true}
            module="animal"
            searchPath={transponder ? "/lost-animals/history/" + transponder : "/lost-animals/history"}
            locale={locale}
            queryParameters={useMemo(() => ({
                isClosed: transponder ? undefined : false,
                currentPage: 0,
                itemsPerPage: 10,
            }), [transponder])}>
            {transponder
                ? <AnimalLostListComponent {...props} />
                : <LostListComponent {...props} />
            }
        </GenericListContainer>
    </Title>

}

export default LostList;
