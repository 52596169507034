import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React from "react";
import Form from "react-bootstrap/Form";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import AnimalLostOrigin from "types/AnimalLostOrigin";
import {useDateTime} from "system/DateTime/DateTime";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import FormControl from "layout/modules/Forms/Control/FormControl";
import Row from "react-bootstrap/Row";
import EntityLinkComponent from "app/entities/components/other/EntityLinkComponent";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import VeterinaryLinkComponent from "app/veterinaries/components/link/VeterinaryLinkComponent";

const OriginComponent = props => {

    const {t} = useTranslation("animal");
    const {lostInfo, readOnly} = props;

    const getDateAndTime = useDateTime("DD-MM-YYYY HH:mm:ss");

    if (!readOnly)
        return <></>;

    return <Box>
        <BoxTitle>{t('origin_title')}</BoxTitle>
        <Row>
            <Col sm={6} xl={6}>
                <FormGroup>
                    <Form.Label>{t('origin')}</Form.Label>
                    <EnumDropdown
                        t={t}
                        value={lostInfo?.creationOrigin}
                        options={AnimalLostOrigin}
                        isDisabled={true}
                    />
                </FormGroup>
            </Col>

            <Col sm={6} xl={6}>
                <FormGroup>
                    <Form.Label>{t('origin_date')}</Form.Label>
                    <FormControl
                        type="text"
                        disabled={true}
                        value={getDateAndTime(lostInfo?.creationTimestamp)}
                    />
                </FormGroup>
            </Col>

            {(lostInfo?.creationOrigin === AnimalLostOrigin.SIAC) &&
            <Col sm={6} xl={6}>
                {lostInfo?.creationUser?.veterinaryId
                    ? <LabelWithValue label={t('author')}>
                        <VeterinaryLinkComponent
                            veterinary={{
                                id: lostInfo?.creationUser?.veterinaryId,
                            }}
                            label={lostInfo?.creationUser?.name}
                        />
                    </LabelWithValue>
                    : <LabelWithValue label={t('author')} value={lostInfo?.creationUser?.name}/>
                }
            </Col>
            }

            {(lostInfo?.creationOrigin === AnimalLostOrigin.PUBLIC) &&
            <Col sm={6} xl={6}>
                <FormGroup>
                    <Form.Label>{t('approved_by')}</Form.Label>
                    <FormControl
                        type="text"
                        disabled={true}
                        value={getOrEmpty(lostInfo?.creationUser?.name ?? lostInfo?.lastUpdatedBy?.name)}
                    />
                </FormGroup>
            </Col>
            }

            {lostInfo?.creationEntity?.id &&
            <Col sm={6} xl={6}>
                <LabelWithValue label={t('entity')}>
                    <EntityLinkComponent name={lostInfo?.creationEntity?.name}
                                         id={lostInfo?.creationEntity?.id}/>
                </LabelWithValue>

            </Col>
            }

        </Row>

    </Box>
}

export default OriginComponent;
