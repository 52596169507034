import useTranslations from "../../system/Translations/UseTranslations";
import locale from "./locales/locales";
import Title from "../../layout/modules/BaseLayout/ContentArea/components/Title";
import React from "react";
import ValidateContainer from "./containers/validate/ValidateContainer";

const Validate = (props) => {

    const {t} = useTranslations('certificate', locale);

    return <Title
        title={t('validate_title')}
        breadcrumbs={[
            {href: "/certificate/validate", label: t('validate')}
        ]} {...props}>

        <ValidateContainer {...props}/>

    </Title>

}

export default Validate;
