import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import NewMessageComponent from "app/support/components/form/NewMessageComponent";
import PanelComponent from "./PanelComponent";
import Box from "layout/modules/Box/Box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import MessageComponent from "./MessageComponent";
import {isMailFromSIAC} from "app/support/utils/utils";
import {useApiState} from "system/API/APIStateContext";
import Loading from "layout/modules/Loading/Loading";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import "app/support/assets/css/messages.scss";
import "app/support/assets/css/status.scss";

const ViewComponent = props => {

    const {ticket, errors = {}, onSubmit, loading} = props;
    const {t} = useTranslation("support");
    const ref = useRef(null);
    const {authentication} = useApiState();

    const [reply, setReply] = useState({
        attachments: [],
    });

    return <div className={"view-ticket"}>
        <Loading visible={loading}/>
        <div className={"ticket"}>
            <Box>
                <BoxTitle>
                    {t('box_title', {id: ticket?.id, subject: ticket.subject})}
                </BoxTitle>

                <div className={"ticket-chat"}>
                    <MessageComponent
                        leftSide={isMailFromSIAC(ticket)}
                        attachments={ticket.attachments}
                        description={ticket.description}
                        user={ticket.creationUser?.name ? ticket.creationUser?.name : ticket.creationUser?.email}
                        timestamp={ticket.creationTimestamp}
                    />


                    {ticket.conversations.map((c, id) =>
                        <MessageComponent
                            key={id}
                            user={c.creationUser?.name ? c.creationUser?.name : c.creationUser?.email}
                            leftSide={isMailFromSIAC(c)}
                            attachments={c.attachments}
                            description={c.body}
                            timestamp={c.creationTimestamp}
                        />
                    )}

                </div>

                {(authentication?.entityProfile?.name !== "SIAC") &&
                <div className={"new-message"}>
                    <NewMessageComponent
                        ref={ref}
                        request={reply}
                        rows={3}
                        setRequest={setReply}
                        id={"message"}
                        errors={errors}
                        showAttachmentLink={true}/>

                    <div className={"attachment-link"} onClick={() => ref?.current?.click()}>
                        <FontAwesomeIcon icon={faPaperclip}/>
                    </div>

                    <div className={"button-submit"}>
                        <Button variant="primary" onClick={() => onSubmit(reply)}>
                            {t('send_message')}
                        </Button>
                    </div>

                </div>
                }
            </Box>


        </div>


        <PanelComponent ticket={ticket}/>

    </div>

}

export default ViewComponent;
