import React, {useMemo, useState} from "react"
import locale from "../locales/locales"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartLine,
    faCogs,
    faEllipsisH,
    faEnvelope,
    faFolderOpen,
    faHeadset,
    faHome,
    faInbox,
    faPaw,
    faShuffle,
    faSitemap
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import RequestSupportContainer from "./support/containers/RequestSupportContainer";
import {useApiState} from "system/API/APIStateContext";
import useTranslations from "system/Translations/UseTranslations";
import SingleMenuItem from "layout/modules/BaseLayout/Menu/components/SingleMenuItem";
import MultipleMenuItem from "layout/modules/BaseLayout/Menu/containers/MultipleMenuItem";
import SubMenu from "layout/modules/BaseLayout/Menu/containers/SubMenu";
import SubMenuItem from "layout/modules/BaseLayout/Menu/components/SubMenuItem";
import {UserType} from "types/Users";

const MainMenu = (props) => {

    const {t} = useTranslations('global', locale);
    const {authentication} = useApiState();
    const isBillable = useMemo(() => authentication?.entityProfile?.isBillable, [authentication]);
    //const exitImpersonate = useExitImpersonate();
    //const [showImpersonate, setShowImpersonate] = useState(false);

    return <>

        {/**
         <UserHasPermission permission={['IMPERSONATE_USER']}>
         <Impersonate {...props} isVisible={showImpersonate} onClose={() => setShowImpersonate(false)}/>
         </UserHasPermission>
         */}

        <SingleMenuItem label={t('home')} icon={faHome} path="/" className={"hidden-mobile"}/>

        <MultipleMenuItem {...props} icon={faPaw} label={t('animals')}>

            <SubMenu label={t('animals')}>

                <SubMenuItem label={t("list_animals")} path={"/animal"}
                             permissions={["READ_ANIMAL"]} orUserTypes={UserType.VETERINARY}/>

                <SubMenuItem
                    label={t("register_animal")}
                    path={"/animal/create"}
                    permissions={["REGISTER_ANIMAL"]}
                    checkBalance={true}
                />

                <SubMenuItem
                    label={t("lost_animals")}
                    path={"/animal/lost"}
                    permissions={["READ_LOST_ANIMAL_HISTORY"]}
                />

                <SubMenuItem label={t("validate_document")} path={"/validate"}
                             permissions={["READ_ANIMAL"]} orUserTypes={UserType.VETERINARY}/>

            </SubMenu>

            <SubMenu label={t('owners')}>


                <SubMenuItem label={t("list_owners")} path={"/owner"}
                             permissions={["READ_OWNER"]}/>

                <SubMenuItem label={t("create_owner")} path={"/owner/create"}
                             permissions={["WRITE_OWNER"]}/>


            </SubMenu>


        </MultipleMenuItem>

        <MultipleMenuItem {...props} icon={faInbox} label={t('controls')}>

            <SubMenu label={t('animals')}>

                <SubMenuItem
                    label={t("pre_registers")}
                    path={"/pre-registers"}
                    permissions={["REGISTER_ANIMAL"]}
                />

                <SubMenuItem label={t("transfers")} path={"/transfers"}
                             permissions={["REQUEST_ANIMAL_TRANSFER"]}
                             orUserTypes={UserType.VETERINARY}/>

                <SubMenuItem label={t("deaths")} path={"/request-death"}
                             permissions={["MANAGE_ANIMAL_DEATH_REQUEST"]}/>


                <SubMenuItem label={t("diac_request")} path={"/request-diac"}
                             permissions={["MANAGE_ANIMAL_DIAC_REQUEST"]}/>

            </SubMenu>

            <SubMenu label={t('owners')}>
                <SubMenuItem label={t("owner_review")} path={"/owner-review"}
                             permissions={["OWNER_REVIEW"]}/>

                <SubMenuItem label={t("owner_merges")} path={"/owner-merges"}
                             permissions={["READ_OWNER_MERGE"]}/>

                <SubMenuItem label={t("owner_data")} path={"/owner-data"}
                             permissions={["MANAGE_OWNER_UPDATE_DATA"]}/>

            </SubMenu>


            <SubMenu label={t('pending_lost_animals')}>
                <SubMenuItem label={t("pending_lost_animals")} path={"/pending-lost"}
                             permissions={["ANIMAL_LOST_PENDING"]}/>

            </SubMenu>


            <SubMenu label={t('ICNF')}>
                <SubMenuItem label={t("herding_dog")} path={"/herding-dog"}
                             permissions={["READ_HERDING_DOG"]}/>

            </SubMenu>

            <SubMenu label={t('controls_by_labels')}>
                <SubMenuItem label={t("controls_by_labels")} path={"/labels/list-by-label"}
                             permissions={["MANAGE_LABELS"]}/>

            </SubMenu>


        </MultipleMenuItem>

        <MultipleMenuItem {...props} icon={faSitemap} label={t('my_entity')}>

            {isBillable &&
                <SubMenu label={t('billing')}>
                    <SubMenuItem label={t("my_transaction_list")}
                                 path={"/billing/transactions"}
                                 permissions={["READ_BILLING"]}
                    />

                    <SubMenuItem label={t("mb_references_list")}
                                 path={"/billing/charging"}
                                 permissions={["READ_BILLING"]}
                    />

                </SubMenu>
            }

            <SubMenu label={t('files')}>
                <SubMenuItem label={t("entity_files")}
                             path={"/entity/files"}
                             permissions={["READ_ENTITY"]}/>
            </SubMenu>


            <SubMenu label={t('users')}>
                <SubMenuItem label={t("list_users")} path={"/user"}
                             permissions={["READ_USER"]}/>

                <SubMenuItem label={t("create_users")} path={"/user/create"}
                             permissions={["WRITE_USER"]}/>
            </SubMenu>

            <SubMenu label={t('veterinaries')}>
                <SubMenuItem label={t("list_my_veterinaries")} path={"/veterinary-management"}
                             permissions={["MANAGE_VETERINARIES"]}/>

                <SubMenuItem label={t("list_veterinaries")} path={"/veterinary"}
                             permissions={["READ_VETERINARY"]}/>
            </SubMenu>


            <SubMenu label={t('entities')}>

                <SubMenuItem label={t("my_entity")} path={"/myEntity"}
                             permissions={["READ_OWN_ENTITY"]}/>

                <SubMenuItem label={t("list_entities")} path={"/entity"}
                             permissions={["READ_ENTITY"]}/>

                <SubMenuItem label={t("create_entity")} path={"/entity/create"}
                             permissions={["WRITE_ENTITY"]}/>
            </SubMenu>


        </MultipleMenuItem>

        <MultipleMenuItem {...props} icon={faShuffle} label={t('traceability')}>
            <SubMenu label={t('traceability')}>
                <SubMenuItem label={t("transactions")}
                             path={"/traceability/"}
                             permissions={["READ_TRANSACTIONS"]}/>

                <SubMenuItem label={t("transponders")}
                             path={"/traceability/transponders"}
                             permissions={["READ_TRANSACTIONS"]}/>
            </SubMenu>
        </MultipleMenuItem>

        <MultipleMenuItem {...props} icon={faChartLine} label={t('reports')}>
            <SubMenu label={t('reports')}>
                <SubMenuItem label={t('reports')} permissions={["GENERATE_REPORT"]} path="/reports"/>
                <SubMenuItem label={t('schedule_report')} permissions={["GENERATE_REPORT"]} path="/reports/schedule"/>
            </SubMenu>

            <SubMenu label={t('operations')}>
                <SubMenuItem label={t('operations')} permissions={["RUN_OPERATION"]} path="/operations"/>
                <SubMenuItem label={t('schedule_operation')} permissions={["RUN_OPERATION"]}
                             path="/operations/schedule"/>
            </SubMenu>

        </MultipleMenuItem>

        <SingleMenuItem label={t('general_files')} icon={faFolderOpen} path="/general-files"/>

        <MultipleMenuItem {...props} icon={faCogs} label={t('admin')}>

            <SubMenu label={t('roles')}>
                <SubMenuItem label={t("list_roles")} path={"/role"}
                             permissions={["READ_ROLE"]}/>

                <SubMenuItem label={t("create_roles")} path={"/role/create"}
                             permissions={["WRITE_ROLE"]}/>
            </SubMenu>

            <SubMenu label={t('entityProfiles')}>
                <SubMenuItem label={t("list_entityProfiles")} path={"/entity-profile"}
                             permissions={["WRITE_ENTITY_PROFILE"]}/>
                <SubMenuItem label={t("create_entityProfiles")} path={"/entity-profile/create"}
                             permissions={["WRITE_ENTITY_PROFILE"]}/>
            </SubMenu>

            <SubMenu label={t('breeds')}>
                <SubMenuItem label={t("list_breeds")} path={"/breed"} permissions={["WRITE_BREED"]}/>
            </SubMenu>

            <SubMenu label={t('pricing')}>
                <SubMenuItem label={t("list_pricing_tables")} path={"/pricing-tables"}
                             permissions={["READ_PRICE_TABLES"]}/>
                <SubMenuItem label={t("transaction_list")} path={"/pricing-tables/transactions"}
                             permissions={["MANAGE_BILLING"]}/>
            </SubMenu>

            <SubMenu label={t('vaccines')}>
                <SubMenuItem label={t("manage_vaccines")} path={"/vaccine"} permissions={["MANAGE_VACCINES"]}/>
            </SubMenu>

            <SubMenu label={t('files')}>
                <SubMenuItem label={t("manage_files")} path={"/file-management"} permissions={["UPLOAD_DOCUMENTS"]}/>
            </SubMenu>

            <SubMenu label={t('reports_and_operations')}>
                <SubMenuItem label={t("list_report_templates")} path={"/report-template"}
                             permissions={["MANAGE_REPORT_TEMPLATES"]}/>
                <SubMenuItem label={t("list_operation_templates")} path={"/operation-template"}
                             permissions={["MANAGE_OPERATION_TEMPLATES"]}/>

            </SubMenu>

            <SubMenu label={t('certificates')}>
                <SubMenuItem label={t("manage_certificates")} path={"/certificate"}
                             permissions={["MANAGE_CERTIFICATES"]}/>
            </SubMenu>

            <SubMenu label={t('configs')}>
                <SubMenuItem label={t("configuration")} path={"/configuration"}
                             permissions={["WRITE_CONFIGURATION"]}/>

                <SubMenuItem label={t("read_audit")} path={"/audit"}
                             permissions={["READ_AUDIT_LOG"]}/>
            </SubMenu>

        </MultipleMenuItem>

        {/**
         {authentication.impersonate
         ? <SingleMenuItem onClick={exitImpersonate} icon={faUserXmark} className={"hidden-no-mobile"}>
         {t('exit_impersonate')}
         </SingleMenuItem>
         : <UserHasPermission permission={['IMPERSONATE_USER']}>
         <SingleMenuItem onClick={() => setShowImpersonate(true)} icon={faUserAstronaut}
         className={"hidden-no-mobile"}>
         {t('impersonate')}
         </SingleMenuItem>
         </UserHasPermission>
         }

         <SingleMenuItem icon={faUserCog} label={t('personal')} path="/personal" className={"hidden-no-mobile"}/>

         {authentication.veterinarySession &&
         <SingleMenuItem icon={faArrowsSpin} label={t('change_entity')} path={{
         pathname: "/auth/entities",
         state: {
         forceEntity: true
         }
         }} className={"hidden-no-mobile"}/>
         }

         <SingleMenuItem icon={faUserSlash} label={t('sign_out')} path="/logout" className={"hidden-no-mobile"}/>*/}

    </>;

}

const MenuBottomMobile = (props) => {

    const [isSupportOpen, setSupportOpen] = useState(false);


    return <Row className={"menu-bottom-buttons"}>
        <Col>
            <SingleMenuItem icon={faHome} path="/"/>
        </Col>

        <Col>
            <SingleMenuItem icon={faPaw} path="/animal/" permissions={["READ_ANIMAL"]}
                            orUserTypes={UserType.VETERINARY}/>
        </Col>

        <Col>
            <div
                className={classnames("main-menu-item", {"selected": isSupportOpen})}
                onClick={() => setSupportOpen(!isSupportOpen)}
            >
                <FontAwesomeIcon icon={faHeadset}/>
            </div>

            {isSupportOpen &&
                <RequestSupportContainer {...props} isOpen={true} onClose={() => setSupportOpen(false)}/>
            }

        </Col>

        <Col>
            <SingleMenuItem icon={faEnvelope} path="/support/"/>
        </Col>

        <Col onMouseDown={props.onOpenMenu}>
            <div className={"icon-more"}>
                <FontAwesomeIcon icon={faEllipsisH}/>
            </div>
        </Col>
    </Row>;

}

export {MainMenu, MenuBottomMobile};
