import React from "react";
import {useGet} from "system/Rest/Rest";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrDefault} from "system/Objects/ObjectParameters";

const RemoteListDropdown = props => {

    const {isDisabled, url, value} = props;

    const {data: categories, loading} = useGet({
        path: url,
        lazy: isDisabled,
        resolve: (data) => data?.reduce((obj, val) => ({
            ...obj,
            [val]: val,
        }), {})
    });

    return <EnumDropdown
        {...props}
        isLoading={loading}
        options={getOrDefault(categories, value ? {[value]: value } : [])}
    />
}

export default RemoteListDropdown;
