import RemoteListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RemoteListDropdown";
import React from "react";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const AdminLabelDropdown = props => {

    return <UserHasPermission permission="LIST_LABELS">
        <RemoteListDropdown
            {...props}
            url={"/labels/"}
            isCreatable={false}
        />
    </UserHasPermission>

}

export default AdminLabelDropdown;
