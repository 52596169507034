import React, {Suspense} from "react";
import locales from "./locales/locales";
import PortugueseFormComponent from "./components/PortugueseFormComponent";
import InternationalFormComponent from "./components/InternationalFormComponent";
import appConfig from "configs/config";
import Loading from "layout/modules/Loading/Loading";
import useTranslations from "system/Translations/UseTranslations";
import lazyWithRetry from "system/System/lazyWithRetry";

const AngolaFormComponent = lazyWithRetry(() => import("modules/Forms/Address/components/AngolaFormComponent"));

const AddressComponent = (props) => {

    const {t} = useTranslations("address", locales)
    const {country = appConfig.defaultCountry} = props;

    switch (country) {
        case "PT":
            return <PortugueseFormComponent {...props} t={t}/>
        case "AO":
            return <Suspense fallback={<Loading visible={true}/>}>
                <AngolaFormComponent {...props} t={t}/>
            </Suspense>
        default:
            return <InternationalFormComponent {...props} t={t}/>
    }
}

export default AddressComponent;
