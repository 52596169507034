import {useBillingBalanceState} from "./BillingStateContext";

const HasBalance = (props) => {

    const billingState = useBillingBalanceState();

    if (billingState.canRegisterAnimal || billingState.canRegisterNotBillableAnimal) {
        return props.children;
    }
    else return <></>

}

export default HasBalance;
