export const pt = {

    title: "Transmissão de titular",
    owner_transfer: "Pedido de transmissão de titularidade",
    change_owner: "Pedido de transmissão de titularidade",

    transponder: "Animal",
    old_owner: "Titular atual",
    new_owner: "Novo titular",
    created_by: "Autor",
    review_by: "Revisão",
    created_by_entity: "Entidade",
    creation_date: "Data",
    status: "Estado",
    open: "Aberto",
    approved_by: "Aprovado por",
    origin: "Origem",
    origin_and_date: "Origem e data",
    last_update_date: "Ult. atualização",
    labels: "Etiquetas",
    exclude_labels: "Excluir etiquetas",
    no_labels: "Apresentar apenas sem labels",

    upload_photo: "Adicionar foto",
    documents: "Documentos ({{count}})",
    preview_documents: "Visualizar documentos",

    //Table
    no_results: "Não existem pedidos para apresentar.",
    bulk_item_selected_one: "{{count}} pedido selecionado",
    bulk_item_selected_other: "{{count}} pedidos selecionados",
    accept_in_bulk: "Aprovar os pedidos selecionados",
    reject_in_bulk: "Rejeitar os pedidos selecionados",

    open_transfers: "Pedidos pendentes",
    closed_transfers: "Histórico",

    MISSING_VALUE: "Deve indicar uma opção",
    BULK_ACCEPT_CONFIRM: "Confirmação",
    BULK_ACCEPT_CONFIRM_MSG: "Os pedidos para transmissão de titular apresentados na lista abaixo vão ser APROVADOS.\r\n\r\n{{transponders}}\r\n\r\nDeseja realmente APROVAR estes pedidos?",
    SUCCESS_BULK_ACCEPT: "Pedidos aprovados",
    SUCCESS_BULK_ACCEPT_MSG: "{{number}} pedidos de transmissão de titular foram aprovados",
    ERROR_BULK_ACCEPT: "Erro a aprovar",
    ERROR_BULK_ACCEPT_MSG: "Ocorreu um erro. Por favor tente mais tarde.",

    BULK_REJECT_CONFIRM_MSG: "Os pedidos para transmissão de titular apresentados na lista abaixo vão ser REJEITADOS.\r\n\r\n{{transponders}}\r\n\r\n",
    SUCCESS_BULK_REJECT: "Pedidos rejeitados",
    SUCCESS_BULK_REJECT_MSG: "{{number}} pedidos de transmissão de titular foram rejeitados",
    ERROR_BULK_REJECT: "Erro a rejeitar",
    ERROR_BULK_REJECT_MSG: "Ocorreu um erro. Por favor tente mais tarde.",

    SUCCESS_TICKET: "Ticket Aberto",
    SUCCESS_TICKET_MSG: "Foi feito um pedido de mais informação",

    ERROR_TICKET: "Erro",
    ERROR_TICKET_MSG: "Ocorreu um erro ao abrir o ticket",

    //review
    register_animal: "Identificação do animal",

    //Change Owner
    bulk_change_owner: "Trans. titularidade em bulk",
    request_data: "Pedido",
    animal_data: "Animal",
    owner_type: "Novo titular",
    remote_new_owner: "Novo titular (Existente)",
    remote_old_owner: "Titular anterior",
    transponders: "Transponders",

    change_owner_s1_desc: "Anexar a declaração",
    change_owner_s1_link_download: "(Caso não disponha de um documento deve fazer o download aqui e efetuar o seu preenchimento)",
    change_owner_s2_desc: "Preencher os dados do novo titular",
    change_owner_s3_desc: "Aguardar pela aprovação dos serviços do SIAC",

    change_owner_file_upload: "Por favor selecione ou arraste a declaração de transmissão de titularidade para esta caixa. Pode ser anexado mais do que um ficheiro.",

    animal_name_ph: "O novo nome do animal",
    animal_name: "Nome do animal",
    submit_co_request: "Submeter pedido",

    notification: "Notificações",
    send_notification: "Enviar notificação",

    no_file_found: "Ficheiro não encontrado",
    select_file_one: "Documento - ({{count}})",
    select_file_other: "Escolha o ficheiro - ({{count}} Ficheiros)",

    accept: "Aprovar pedido",
    reject: "Rejeitar pedido",
    reject_reason: "Motivo da rejeição",
    reject_message_helper: "Por favor indique a razão da rejeição",
    cancel: "Cancelar",
    request_more_information: "Pedir informação",

    //Filters
    closed_process: "Pedido fechado",
    closed: "Fechado",
    accepted_process: "Pedido aprovado",

    SUCCESS_CHANGED: "Pedido de transmissão de titular",
    SUCCESS_CHANGED_MSG: "O pedido de transmissão de titularidade para o transponder {{transponder}} foi {{status}}.",
    SUCCESS_REQUEST_MORE_INFORMATION: "Foi realizado um pedido de mais informação para o pedido de transmissão de titularidade do animal com o transponder {{transponder}}.",

    ERROR_CHANGE_STATE: "Pedido de transmissão de titular",
    ERROR_CHANGE_STATE_MSG: "Não foi possível alterar o estado do pedido. Por favor tente mais tarde",
    TRANSFER_OWNER_SAME_AS_CURRENT: "Neste momento o animal já está registado com o titular indicado no formulário.",


    ACCEPTED: "Aprovado",
    REJECTED: "Rejeitado",
    PENDING: "Pendente",
    MORE_INFORMATION_NEEDED: "A aguardar informação",
    true: "Aceite",
    false: "Rejeitado",
    isCanceled: "Cancelada",

    request_is_closed: "Pedido fechado",
    request_is_closed_msg: "Este pedido já se encontra no estado {{status}} e não pode ser alterado.",
    request_is_rejected_msg: "Este pedido foi fechado e rejeitado com o seguinte motivo: <strong>{{rejectReason}}</strong>.",

    request_is_canceled: "Pedido cancelado",
    request_is_canceled_msg: "O pedido foi cancelado pelo titular do animal através da plataforma SIAC Titulares",

    owner_initiated_request: "Pedido entre titulares",
    owner_initiated_request_msg: "Este pedido foi feito realizado através da plataforma SIAC titulares.",

    site_initiated_request: "Pedido realizado via website",
    site_initiated_request_msg: "Este pedido foi realizado através do formulário público da página do SIAC.",

    SIAC: "Aplicação SIAC",
    PUBLIC: "Formulário website",
    OWNERS: "Aplicação titulares",

    new_owner_already_registered: "O novo titular já está registado",
    new_owner_already_registered_msg: "O novo titular já está registado e este processo de validação (no caso de ser aceite) irá criar uma nova versão do mesmo. Pode validar os campos no separador \"Novo titular (Existente)\"",

    //Errors
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",
    OWNER_IS_LOCKED: "O Titular com o NIF indicado está bloqueado e não pode ser utilizado em nenhuma operação de registo. <br/>" +
        "Para mais informações contacte o suporte",

    SUCCESS_OWNER_CHANGE: "Pedido de transmissão de titular",
    SUCCESS_OWNER_CHANGE_MSG: "O pedido de transmissão de titular foi submetido com sucesso. Deve aguardar agora pela validação dos serviços.",

    SUCCESS_OWNER_BULK_CHANGE: "Pedidos de transmissão de titular",
    SUCCESS_OWNER_BULK_CHANGE_MSG: "Os pedidos de transmissão de titular foram submetidos com sucesso.",

    //File Upload
    add_new_document: "Carregar novo documento",
    add_new_document_description: "Por favor arraste para esta área ou utilize o botão escolher para anexar novos documentos",
    add_files: "Adicionar ficheiros",
    close: "Fechar",
    SUCCESS_UPLOAD: "Documentos Carregados",
    SUCCESS_UPLOAD_MSG: "Documentos carregados com sucesso na ficha de registo temporário",

    ERROR_UPLOADING: "Erro a carregar",
    ERROR_UPLOADING_MSG: "Não foi possível carregar os documentos anexados. Por favor tente mais tarde ou entre em contacto com os serviços SIAC",

    //more information
    more_information_request: "Pedido de mais informação:",
    more_information_message_helper: "Por favor indique que informação se encontra em falta",
    more_information_provided: "A aguardar validação",
    more_information_msg: "Foram carregados novos documentos em resposta ao pedido \"<i>{{message}}</i>\"",

    //validation status
    validation_status: "Estado da validação",
    WAITING_VALIDATION: "Validação pendente",
    WAITING_DOCUMENTATION: "Falta documentação",
    select_ph: "Selecione uma opção",
    DONE: "Fechado",

    //Owner request info
    owner_request: "Informação do pedido app titulares",
    new_owner_nif: "Nif do novo titular",
    new_owner_name: "Nome do novo titular",
    next_request: "Pedido seguinte >",
    previous_request: "< Pedido anterior",

    VETERINARY_USER: "M. Veterinário",
    NORMAL_USER: "Utilizador Normal",
    OWNER_USER: "Titular",
    PUBLIC_USER: "Utilizador do site",

    error_loading_preview: "Erro a visualizar os ficheiros",
    error_loading_preview_msg: "Ocorreu um erro a abrir o visualizador de ficheiros. Por favor faça refresh ao browser para resolver o problema",
    error_in_documents: "Foram detetados ficheiros inválidos. Por favor verifique novamente ou tente com um documento diferente",
};

export default pt;
