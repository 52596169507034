import Box from "../../../layout/modules/Box/Box";
import Loading from "../../../layout/modules/Loading/Loading";
import React from "react";

const ChangeLogComponent = (props) => {

    const {information, loading} = props;

    return <Box>
        <Loading visible={loading}/>
        <div className={"message"} dangerouslySetInnerHTML={{__html: information}}/>
    </Box>
}

export default ChangeLogComponent;
