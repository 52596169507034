import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons'
import UserHasPermission from "../../../../../system/API/Authentication/UserHasPermission";
import classnames from "classnames";

const SubMenuComponent = (props) => {

    const {label, children, setOpen, isOpen, isSelected, permissions, orUserTypes} = props;

    const link = (
        <div className="sub-menu-block">
            <div
                className={isSelected ? "sub-menu-main-item selected" : "sub-menu-main-item"}
                onClick={() => setOpen(!isOpen)}>
                <div className={"sub-menu-group-label"}>{label}</div>
                <div className={"sub-menu-item-arrow"}>
                    {isOpen ?
                        <FontAwesomeIcon icon={faAngleUp}/> :
                        <FontAwesomeIcon icon={faAngleDown}/>
                    }
                </div>
            </div>

            <div className={classnames("sub-menu-items", {"is-visible": isOpen})}>
                {children}
            </div>

        </div>
    );

    if (permissions || orUserTypes) {
        return <UserHasPermission permission={permissions} orUserTypes={orUserTypes} any={true}>
            {link}
        </UserHasPermission>
    }

    return link;
}
export default SubMenuComponent;
