import Col from "react-bootstrap/Col";
import FormGroup from "../../../../../layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import EnumDropdown from "../../../../../layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrNull} from "../../../../../system/Objects/ObjectParameters";
import React from "react";
import SterilizationType from "../../../../interventions/utils/sterilization/SterilizationType";
import useTranslations from "../../../../../system/Translations/UseTranslations";
import locale from "../../../../interventions/locales/locales";

const SterilizationTypeComponent = props => {

    const {value, setValue, parameter, error, readOnly} = props;

    const {t: ts} = useTranslations("health-intervention", locale);

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <EnumDropdown
                t={ts}
                isDisabled={readOnly}
                isClearable={!parameter?.mandatory}
                options={SterilizationType}
                isMulti={parameter?.multipleValues}
                value={getOrNull(value)}
                onChange={setValue}
            />
            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default SterilizationTypeComponent;
