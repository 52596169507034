import React, {useMemo} from "react";
import locale from "./locale/locales";
import ListComponent from "./components/ListComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";

const List = (props) => {

    const {transponder} = props;

    return <GenericListContainer
        {...props}
        id={"aggression"}
        module="aggression"
        deletePath={"/aggressions/" + transponder + "/"}
        locale={locale}
        hideTitle={transponder ? true : false}
        queryParameters={useMemo(() => ({
            transponder: transponder,
            sortProperty: 'aggressionDate',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 10,
        }),[transponder])}>

        <ListComponent {...props} />

    </GenericListContainer>


}

export default List;
