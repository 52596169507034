const AggressionRegisterType = {
    REGISTER_AGGRESSION: "REGISTER_AGGRESSION",
    REGISTER_CLEAR: "REGISTER_CLEAR",
};


const AggressionType = {
    PERSON: "PERSON",
    ANIMAL: "ANIMAL",
    OTHER: "OTHER",
};

const AggressionAnimalFate = {
    EUTHANASIA: "EUTHANASIA",
    DEVOLUTION: "DEVOLUTION",
    TRAINING: "TRAINING",
    OTHER: "OTHER",
}

const PersonAggression = {
    PUBLIC_PLACE_INJURY: "PUBLIC_PLACE_INJURY",
    PUBLIC_PLACE_DEATH: "PUBLIC_PLACE_DEATH",
    RESIDENCE_INJURY: "RESIDENCE_INJURY",
    RESIDENCE_DEATH: "RESIDENCE_DEATH",
}

const OtherAggression = {
    OTHER: "OTHER",
}

const AnimalAggression = {
    SERIOUS_INJURY: "SERIOUS_INJURY",
    DEATH: "DEATH",
}


const AggressionMap = {
    PERSON: PersonAggression,
    ANIMAL:  AnimalAggression,
    OTHER: OtherAggression,
}


export {
    AggressionRegisterType,
    AggressionType,
    AggressionAnimalFate,
    PersonAggression,
    AnimalAggression,
    AggressionMap
}
