import {useTranslation} from "react-i18next";
import React, {useMemo, useState} from "react";
import {Col, Row} from "react-bootstrap";
import Loading from "layout/modules/Loading/Loading";
import PreRegisterFormComponent from "app/control/modules/preRegister/components/form/PreRegisterFormComponent";
import AnimalFormComponent from "app/control/modules/preRegister/components/form/AnimalFormComponent";
import FilePreviewComponent from "app/control/components/files/FilePreviewComponent";
import RequestAuthorAndReviewerComponent from "app/control/components/show/RequestAuthorAndReviewerComponent";
import ButtonsComponent from "app/control/components/show/ButtonsComponent";
import SetStateModalComponent from "app/control/components/show/SetStateModalComponent";
import ClosedMessageInfoComponent from "app/control/components/show/ClosedMessageInfoComponent";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import ValidationHelperMessages from "app/control/modules/preRegister/components/show/ValidationHelperMessages";
import Button from "react-bootstrap/Button";
import AddNewDocumentComponent from "app/control/components/show/AddNewDocumentComponent";
import MoreInformationInfoMessageComponent from "app/control/components/show/MoreInformationInfoMessageComponent";
import NextAndPreviousComponent from "app/control/components/show/NextAndPreviousComponent";
import Audit from "app/audit/Audit";
import {PreRegisterActions} from "app/audit/utils/AuditActions";
import {AnimalObjectTypes} from "app/audit/utils/AuditObjectTypes";
import "app/control/modules/preRegister/assets/css/show.scss";
import ValidationStatus from "types/ValidationStatus";
import ObjectType from "app/adminLabels/utils/ObjectType";
import Box from "layout/modules/Box/Box";
import ShowAdminLabels from "app/adminLabels/ShowAdminLabels";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import ControlSupportContainer from "app/support/containers/ControlSupportContainer";
import {TicketProcessType} from "types/TicketProcessType";
import Observations from "app/observations/Observations";
import {useIsMobile} from "system/Window/Mobile";
import FilePreviewMobileComponent from "app/control/components/files/FilePreviewMobileComponent";
import PotentiallyDangerousAlert from "app/animals/components/view/alerts/PotentiallyDangerousAlert";
import {useAnimalState} from "app/animals/utils/AnimalContext";
import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import RemoteOwnerComponent from "app/control/modules/transfers/components/show/request/RemoteOwnerComponent";
import OwnerComponent from "app/animals/components/form/OwnerComponent";

const ShowComponent = props => {

    const {
        preRegister,
        loading,
        onChangeState,
        onAddFiles,
        uploading,
        errorsUploading,
        registeredOwner,
        reload
    } = props;
    const {t} = useTranslation('preRegister');

    const [showUpload, setShowUpload] = useState(false);
    const [isSetStateVisible, setShowSetState] = useState(false);

    const {animal} = useAnimalState();
    const isMobile = useIsMobile();
    const hasFiles = useMemo(() => Array.isArray(preRegister?.documents), [preRegister?.documents]);

    const Buttons = () => <UserHasPermission permission="MANAGE_ANIMAL_PRE_REGISTER">
        <ButtonsComponent
            t={t}
            request={preRegister}
            hideAccept={preRegister?.transponderValidation?.cannotBeAccepted}
            onChangeState={onChangeState}
            setShowSetState={setShowSetState}
        />
    </UserHasPermission>;

    if (loading)
        return <Loading visible={true}/>

    return <div className={"show-pre-register view-mode"}>

        <NextAndPreviousComponent t={t} path={"/pre-registers/"} document={preRegister}/>

        {showUpload &&
            <AddNewDocumentComponent
                onClose={() => setShowUpload(false)}
                loading={uploading}
                errors={errorsUploading}
                onSubmit={onAddFiles}
                t={t}
            />
        }

        <PotentiallyDangerousAlert animal={animal}/>

        <MoreInformationInfoMessageComponent t={t} request={preRegister}/>
        <ClosedMessageInfoComponent t={t} request={preRegister} showTransponder={true}/>

        <UserHasPermission permission="MANAGE_ANIMAL_PRE_REGISTER">

            <ValidationHelperMessages t={t} request={preRegister}/>

            {isSetStateVisible &&
                <SetStateModalComponent
                    t={t}
                    setShow={setShowSetState}
                    state={isSetStateVisible}
                    onHide={() => setShowSetState(false)}
                    onChangeState={onChangeState}
                    reasonsUrl={isSetStateVisible === ValidationStatus.MORE_INFORMATION_NEEDED
                        ? "/animals/pre-register/more-info-reasons"
                        : "/animals/pre-register/rejection-reasons"
                    }
                />
            }

            <Buttons/>

        </UserHasPermission>

        <Row>
            <Col lg={hasFiles ? 6 : 12} md={12} sm={12} className={"left-side no-cols"}>

                <div className={"button-area"}>
                    {!preRegister.closed &&
                        <Button variant="primary" onClick={() => setShowUpload(true)}>
                            {t('add_new_document')}
                        </Button>
                    }

                    <div className={"round-buttons"}>
                        <UserHasPermission permission={["READ_OBSERVATION"]}>
                            <Observations btnRegular={true}/>
                        </UserHasPermission>

                        <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                            <Audit
                                lg={true}
                                objectId={preRegister?.id}
                                filterActions={PreRegisterActions}
                                objectType={[AnimalObjectTypes.AnimalPreRegister]}
                            />
                        </UserHasPermission>

                    </div>

                </div>

                <Tabs defaultActiveKey="request" id={"tab-owner-" + preRegister.id + "-" + preRegister.closed}
                      unmountOnExit={false} mountOnEnter={true}>

                    <Tab eventKey="request" title={t('request')}>
                        <ControlSupportContainer
                            processType={TicketProcessType.ANIMAL_PRE_REGISTER}
                            id={preRegister.id}/>

                        <RequestAuthorAndReviewerComponent t={t} request={preRegister}/>

                        <PreRegisterFormComponent readOnly={true}/>

                        <UserHasPermission permission={["MANAGE_ANIMAL_PRE_REGISTER", "MANAGE_LABELS"]}>
                            <Box>
                                <BoxTitle>{t('labels')}</BoxTitle>
                                <ShowAdminLabels objectType={ObjectType.ANIMAL_PRE_REGISTER} objectId={preRegister?.id}
                                                 labels={preRegister?.labels}/>
                            </Box>
                        </UserHasPermission>

                        <AnimalFormComponent
                            {...props}
                            readOnly={true}
                        />
                    </Tab>

                    <Tab eventKey="owner" title={t('owner')}>
                        <OwnerComponent
                            create={false}
                            changes={{}}
                            errors={{}}
                            readOnly={true}/>
                    </Tab>

                    {registeredOwner &&
                        <Tab eventKey="registered_owner" title={t('registered_owner')}>
                            <RemoteOwnerComponent
                                owner={registeredOwner}
                            />
                        </Tab>
                    }
                </Tabs>

            </Col>

            {hasFiles &&
                <>
                    {!isMobile
                        ? <FilePreviewComponent request={preRegister} t={t} reload={reload}/>
                        : <FilePreviewMobileComponent request={preRegister} t={t} reload={reload}/>
                    }
                </>
            }

        </Row>

        <Buttons/>

        <NextAndPreviousComponent t={t} path={"/pre-registers/"} document={preRegister}/>

    </div>
}

export default ShowComponent;
