import React, {Suspense} from 'react';
import {ApiStateProvider} from "system/API/APIStateContext";
import appConfig from "configs/config";
import ApiErrorInterceptor from "system/API/Error/ApiErrorInterceptor";
import {NotificationAreaProvider} from "layout/modules/NotificationArea/components/NotificationAreaProvider";
import {routes} from "app/routes";
import {RestfulProvider} from "system/Rest/RestfulProvider";
import {LoadingStateProvider} from "layout/modules/Loading/LoadingStateContext";
import {createRoutesFromElements, RouterProvider} from "react-router";
import Loading from "layout/modules/Loading/Loading";
import {createBrowserRouter} from "react-router-dom";
import OpenReplay from "modules/OpenReplay/OpenReplay";


const App = () => {

    console.log("######  ####    ###     ######  \r\n##    ##  ##    ## ##   ##    ## \r\n##        ##   ##   ##  ##       \r\n ######   ##  ##     ## ##       \r\n      ##  ##  ######### ##       \r\n##    ##  ##  ##     ## ##    ## \r\n ######  #### ##     ##  ######  \r\n");

    return <ApiStateProvider>
        <RestfulProvider base={appConfig.api.base}>
            <OpenReplay>
                <ApiErrorInterceptor>
                    <NotificationAreaProvider>
                        <LoadingStateProvider>
                            <Suspense fallback={<Loading visible={true}/>}>
                                <RouterProvider router={router}/>
                            </Suspense>
                        </LoadingStateProvider>
                    </NotificationAreaProvider>
                </ApiErrorInterceptor>
            </OpenReplay>
        </RestfulProvider>
    </ApiStateProvider>
}

const router = createBrowserRouter(createRoutesFromElements(routes));

export default App;
