import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import PhoneControl from "layout/modules/Forms/PhoneControl/PhoneControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import FormControl from "layout/modules/Forms/Control/FormControl";
import useLockFields from "app/owners/utils/useLockFields";

const ContactsComponent = (props) => {

    const {
        errors = {},
        changes = {},
        owner,
        setOwner,
        readOnly,
        compareMode,
        mandatory = {phone: true},
        lockFields: remoteLockFields = {}
    } = props;
    const {t} = useTranslation('owner');

    const handlePhone2 = useCallback((phone) => setOwner(owner => ({
        ...owner,
        phone2: phone,
    })), [setOwner]);

    const handlePhone3 = useCallback((phone) => setOwner(owner => ({
        ...owner,
        phone3: phone,
    })), [setOwner]);

    const handleEmail = useCallback(e => setOwner(owner => ({
        ...owner,
        email: e.target.value?.toLowerCase()?.trim(),
    })), [setOwner])

    const showEmptyFields = !readOnly || compareMode;

    const lockFields = useLockFields({owner, remoteLockFields, create: !owner?.id});

    return <Box>
        <BoxTitle>{props.title ??  t('contacts')}</BoxTitle>

        <Row>
            <Col sm={12} lg={6}>
                <FormGroup error={errors['phone']} changes={changes['phone']} mandatory={mandatory['phone']}>
                    <Form.Label>{t('phone')}</Form.Label>
                    <PhoneControl
                        value={getOrEmpty(owner?.phone)}
                        disabled={readOnly || lockFields['phone']}
                        country={owner?.country?.toLowerCase()}
                        onChange={useCallback(phone => setOwner(owner => ({
                            ...owner,
                            phone: phone,
                        })), [setOwner])}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['phone'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            {(showEmptyFields || owner.phone2) &&
            <Col sm={12} lg={6}>
                <FormGroup error={errors['phone2']} changes={changes['phone2']} mandatory={mandatory['phone2']}>
                    <Form.Label>{t('phone2')}</Form.Label>
                    <PhoneControl
                        value={getOrEmpty(owner?.phone2)}
                        disabled={readOnly || lockFields['phone2']}
                        onChange={handlePhone2}
                        country={owner?.country?.toLowerCase()}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['phone2'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            }

            {(showEmptyFields || owner.phone3) &&
            <Col sm={12} lg={6}>
                <FormGroup error={errors['phone3']} changes={changes['phone3']} mandatory={mandatory['phone3']}>
                    <Form.Label>{t('phone3')}</Form.Label>
                    <PhoneControl
                        country={owner?.country?.toLowerCase()}
                        value={getOrEmpty(owner?.phone3)}
                        disabled={readOnly || lockFields['phone3']}
                        onChange={handlePhone3}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['phone3'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            }

            {(showEmptyFields || owner.email) &&
            <Col sm={12} lg={6}>
                <FormGroup error={errors['email']} changes={changes['email']} mandatory={mandatory['email']}>
                    <Form.Label>{t('email')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('email_ph')}
                        disabled={readOnly || lockFields['email']}
                        value={getOrEmpty(owner?.email)}
                        onChange={handleEmail}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['email'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            }
        </Row>
    </Box>;

}

export default ContactsComponent;
