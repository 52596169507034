import React, {useState} from "react";
import ModalTicketComponent from "app/support/components/view/ModalTicketComponent";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/support/locales/locales";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import classnames from "classnames";
import DateTime from "system/DateTime/DateTime";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {useApiState} from "system/API/APIStateContext";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ControlSupportComponent = props => {

    const {summary} = props;
    const {authentication} = useApiState();
    const [isOpen, setOpen] = useState(false);

    const {t} = useTranslations('support', locale);

    return <div className={"control-ticket view-mode"}>
        <Box>
            <BoxTitle>
                {t('control_title')}
            </BoxTitle>

            <BoxOptions>
                <Button
                    variant={"primary"}
                    className={classnames("small", {"blink": summary.hasNewMessages})}
                    onClick={() => setOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faEnvelope}/>&nbsp;&nbsp;
                    {t('view_btn')}
                </Button>
            </BoxOptions>

            <Row>
                <Col md={6} sm={12}>
                    <LabelWithValue label={t('status')}>
                        <div className={classnames("ticket-status", summary.status?.toLowerCase().replace(/_/g, "-"))}>
                            {t(summary.status)}
                        </div>
                    </LabelWithValue>
                </Col>

                <Col md={6} sm={12}>
                    <LabelWithValue label={t('last_update')}>
                        <DateTime format={"DD-MM-YYYY HH:mm:ss"}>
                            {summary.lastUpdateTimestamp}
                        </DateTime>
                    </LabelWithValue>
                </Col>


                {(authentication?.entityProfile?.name === "SIAC") &&
                <Col md={12} sm={12}>
                    <LabelWithValue label={t('link')}>
                        <a href={"https://suportesiac.freshdesk.com/a/tickets/" + summary.id} target="_blank"
                           rel="noopener noreferrer">
                            {"https://suportesiac.freshdesk.com/a/tickets/" + summary.id}
                        </a>
                    </LabelWithValue>
                </Col>
                }

            </Row>

        </Box>

        {isOpen &&
        <ModalTicketComponent id={summary.id} isVisible={true} onClose={() => setOpen(false)}/>
        }

    </div>
}

export default ControlSupportComponent;
