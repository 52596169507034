import React from "react";
import MainVersionsContainer from "modules/VersionsContainer/VersionsContainer";
import TrainingComponent from "app/owners/components/view/TrainingComponent";

const TrainingVersionsContainer = (props) => {

    return <MainVersionsContainer
        {...props}
        path={"/owners/" + props.id + "/training/versions"} >
        <CompareComponent/>
    </MainVersionsContainer>
}


const CompareComponent = (props) => {

    const {version, changes, diffs, fullChanged} = props;

    if (!version)
        return <></>;

    return <TrainingComponent
        training={version.entity}
        compareMode={true}
        changes={changes}
        fullChanged={fullChanged}
        errors={diffs}
    />
}


export default TrainingVersionsContainer;
