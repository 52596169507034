import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import classnames from "classnames";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "../../../assets/css/merge.scss";
import {Table} from "react-bootstrap";
import Loading from "layout/modules/Loading/Loading";
import ModalWithNoMargin from "layout/modules/Modal/ModalWithNoMargin/ModalWithNoMargin";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import RunningListEntryComponent from "app/animals/components/list/implementations/runningList/RunningListEntryComponent";
import TransponderControl from "modules/Forms/Transponder/TransponderControl";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import Button from "react-bootstrap/Button";
import RemoteListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RemoteListDropdown";
import {getOrEmpty} from "system/Objects/ObjectParameters";

const MergeFormComponent = props => {

    const {loading, onClose, onSubmit, errors, animal, setTransponder, searchResult, searching, transponder} = props;

    const [reason, setReason] = useState("");

    const {t} = useTranslation("animal");

    return <Modal
        show={true}
        onHide={loading ? () => void 0 : onClose}
        size={"xl"}
        className={"merge-modal"}
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{t('merge_animal')}</Modal.Title>
        </Modal.Header>
        <Loading visible={loading || searching}/>
        <Modal.Body>

            <div className={"title"}>
                {t('animal_data')}
            </div>

            <ModalWithNoMargin>
                <div className={classnames("current-data")}>
                    <GenericFormError t={t} errors={errors}/>
                    <Table className={"running-animal-list"}>
                        <tbody>
                        <RunningListEntryComponent animal={animal} hideOptions={true}/>
                        </tbody>
                    </Table>
                </div>
            </ModalWithNoMargin>


            {(searchResult?.totalItems === 1 && transponder) &&
            <>
                <div className={"title"}>
                    {t('animal_to_merge')}
                </div>

                <ModalWithNoMargin>
                    <div className={classnames("current-data")}>
                        <GenericFormError t={t} errors={errors}/>
                        <Table className={"running-animal-list"}>
                            <tbody>
                            <RunningListEntryComponent animal={searchResult.content[0]} hideOptions={true}/>
                            </tbody>
                        </Table>
                    </div>
                </ModalWithNoMargin>
            </>
            }

            <div className={"title"}>
                {t('merge_data')}
            </div>

            <div className={"description"}>
                {t('merge_description')}
            </div>


            <Row>
                <Col md={12} lg={6}>
                    <FormGroup error={errors['oldTransponder']} mandatory={true}>
                        <Form.Label>{t('old_transponder')}</Form.Label>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputGroup.Text>
                            <TransponderControl
                                placeholder={t('transponder_ph')}
                                onlyNumeric={false}
                                allowInvalid={true}
                                onKeyDown={useCallback(
                                    (e) => {
                                        if (e.key === 'Enter') {
                                            setTransponder(e.target.value);
                                        }
                                    },
                                    [setTransponder]
                                )}
                                onBlur={useCallback(
                                    (e) => {
                                        setTransponder(e.target.value);
                                    },
                                    [setTransponder]
                                )}
                                onInvalid={useCallback((transponder) => {
                                        if (!transponder)
                                            setTransponder("")
                                    },
                                    [setTransponder]
                                )}
                            />
                        </InputGroup>

                        <Form.Text className="sub-error">
                            {t(errors['oldTransponder'])}
                        </Form.Text>
                    </FormGroup>

                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup error={errors['reason']} mandatory={true}>
                        <Form.Label>{t('merge_reason')}</Form.Label>
                        <RemoteListDropdown
                            url={"/animal-merges/reasons"}
                            value={getOrEmpty(reason)}
                            isCreatable={true}
                            onChange={setReason}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['reason'])}
                        </Form.Text>
                    </FormGroup>
                </Col>

            </Row>


        </Modal.Body>

        <ModalButtons>

            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>

            <Button
                variant="primary"
                onClick={() => onSubmit(transponder, reason)}
                disabled={!(transponder && searchResult?.totalItems === 1) || (transponder === animal?.transponder)}
            >
                {t("merge")}
            </Button>

        </ModalButtons>


    </Modal>
}

export default MergeFormComponent;
