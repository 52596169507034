import React, {useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import locales from "../../animals/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import TransponderControl from "modules/Forms/Transponder/TransponderControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Loading from "layout/modules/Loading/Loading";
import CardListComponent from "app/animals/components/list/implementations/cardList/CardListComponent";
import RunningListComponent from "app/animals/components/list/implementations/runningList/RunningListComponent";
import NoResultsComponent from "app/animals/components/list/implementations/NoResultsComponent";
import classnames from "classnames";
import "app/home/assets/css/search.scss"
import useElementSize from "system/Window/useElementSize";

const SearchComponent = props => {

    const {
        setTransponder: searchTransponder,
        transponder: searchedTransponder,
        searching,
        results,
        isValidSearchResult
    } = props;


    const {t} = useTranslation("dashboard");
    useTranslations("animal", locales);


    const ref = useRef();
    const {width} = useElementSize(ref);

    const [transponder, setTransponder] = useState("");
    const useCard = width < 576;

    return <div className={"dashboard-search-area"}>
        <div className={"search-area"}>

            <div className={"search-label"}>
                {t('search_label')}
            </div>

            <div className={"search-form"}>

                <div className={"dashboard-search-control"}>
                    <InputGroup>
                        <TransponderControl
                            onKeyDown={useCallback(
                                (e) => {
                                    if (e.key === 'Enter' && transponder !== searchedTransponder) {
                                        searchTransponder(transponder);
                                    }
                                },
                                [searchTransponder, transponder, searchedTransponder]
                            )}
                            onBlur={useCallback(
                                () => {
                                    if (transponder !== searchedTransponder) {
                                        searchTransponder(transponder);
                                    }
                                },
                                [searchTransponder, transponder, searchedTransponder]
                            )}
                            placeholder={t('transponder_ph')}
                            value={getOrEmpty(transponder)}
                            onValid={setTransponder}
                            onInvalid={setTransponder}

                        />
                        <div className={"button-search-container"}>
                            <div

                                className={classnames("button-search", {
                                    "active": transponder?.length > 0
                                })}
                                onClick={() => {
                                    if (transponder !== searchedTransponder)
                                        searchTransponder(transponder)
                                }}
                            >
                                <FontAwesomeIcon icon={faSearch}/>
                            </div>
                        </div>
                    </InputGroup>
                </div>

            </div>

        </div>


        <div className={"search-result running-animal-list"} ref={ref}>
            <Loading visible={searching && transponder} localOnly={true}/>
            {(!transponder || transponder !== searchedTransponder) &&
                <div className={"no-result"}>
                    {t('transponder_no_search')}
                </div>
            }


            {(isValidSearchResult && transponder === searchedTransponder) && <>
                {useCard
                    ? <CardListComponent {...props} data={results}/>
                    : <RunningListComponent {...props} data={results}/>
                }
            </>
            }

            {(!isValidSearchResult && transponder && transponder === searchedTransponder) &&
                <NoResultsComponent {...props} data={results} searchQuery={transponder} loading={searching}/>}

        </div>


    </div>

}

export default SearchComponent;
