import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {UserType} from "types/Users";
import {isTransponderValid} from "modules/Forms/Transponder/utils/ValidateTransponder";
import HasBalance from "modules/Billing/HasBalance";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import LostComponent from "app/animals/components/lost/show/LostComponent";
import "app/animals/assets/css/no-results.scss";

const NoResultsComponent = (props) => {

    const {t} = useTranslation("animal");

    const {searchQuery, data, loading} = props;

    if (data?.length > 0 || loading) {
        return <></>
    } else if (isTransponderValid(searchQuery)) {
        return <div className={"no-table-results register-no-result"}>

            <Trans>
                {t('no_results_with_operations', {transponder: searchQuery})}
            </Trans>

            <div className={"no-results-buttons"}>

                <HasBalance>
                    <UserHasPermission permission={['REGISTER_ANIMAL']}>
                        <HasBalance>
                            <Link to={"/animal/create"} state={{transponder_auto_fill: searchQuery}}>
                                <Button variant="primary">
                                    {t("register_transponder")}
                                </Button>
                            </Link>
                        </HasBalance>
                    </UserHasPermission>
                </HasBalance>

                <UserHasPermission permission={['REGISTER_LOST_ANIMAL']} orUserTypes={UserType.VETERINARY}>
                    <LostComponent {...props} transponder={searchQuery} notRegistered={true}>
                        {({setVisible}) =>
                            <Button variant="primary" onClick={setVisible}>
                                {t("register_as_lost")}
                            </Button>
                        }
                    </LostComponent>
                </UserHasPermission>
            </div>

        </div>
    } else
        return <div className={"no-table-results"}>{t('no_results')}</div>

}

export default NoResultsComponent;
