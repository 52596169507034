import Form from "react-bootstrap/Form";
import Select from "react-select";
import FormGroup from "../../../../../../layout/modules/Forms/Group/FormGroup";
import React, {useCallback, useMemo} from "react";
import useFirstElementIfUnique from "../../../../../../system/Arrays/useFirstElementIfUnique";

const ParishComponent = (props) => {

    const {t, errors = {},changes = {}, data, addresses, onChange, readOnly, isLoading, defaultParish} = props;

    const parishList = useMemo(
        () => [...new Set(addresses?.map(address => JSON.stringify({
            label: address.parish.name,
            value: address.parish.id,
        })))].map(string => JSON.parse(string)),
        [addresses]
    );

    //Adjust data if only one address was recived from server
    useFirstElementIfUnique(
        parishList,
        parish => {
            onChange(address => ({
                ...address,
                parish: address.parish?.id ? address.parish.id : parish.value,
            }));
        },
    );

    const value = useMemo(() => {
        let value = null;
        parishList.forEach((item) => {
            if (item.value === data.parish) {
                value = item;
            }
        });

        if (!value && data?.parish) {
            return {
                value: defaultParish?.id,
                label: defaultParish?.name,
            };
        }


        return value;

    },[data.parish,parishList, defaultParish]);

    return <FormGroup error={errors['parishId']} changes={changes['parish']}>
        <Form.Label>{t('parish')}</Form.Label>
        <Select
            isClearable={true}
            classNamePrefix="rs"
            className="rs"
            placeholder={useMemo(() => t('parish_ph'), [t])}
            noOptionsMessage={useCallback(() => t('no_parish_found'),[t])}
            isLoading={isLoading}
            isDisabled={readOnly || !data.zipCodeConfirmed || !data.locality}
            options={parishList}
            value={value}
            onChange={useCallback(value => onChange(address => ({
                ...address,
                parish: value?.value,
                street: "",
                accommodation: "",
                door: "",
            })),[onChange])}
        />
        <Form.Text className="sub-error">
            {t(errors['parishId'])}
        </Form.Text>
    </FormGroup>
}

export default ParishComponent;
