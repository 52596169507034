import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import FormComponent from "app/animals/components/lost/create/FormComponent";
import Modal from "react-bootstrap/Modal";
import Loading from "layout/modules/Loading/Loading";
import ModalWithNoMargin from "layout/modules/Modal/ModalWithNoMargin/ModalWithNoMargin";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import TypeSelectorComponent from "app/animals/components/lost/create/form/TypeSelectorComponent";
import "app/animals/assets/css/lost.scss";
import ProcessObservationsContainer from "app/animals/containers/lost/ProcessObservationsContainer";
import LostCloseByTransferAlertComponent from "app/animals/components/lost/other/LostCloseByTransferAlertComponent";


const ModalComponent = (props) => {

    const {loading, onClose, lostInfo, remoteLostInfo, setLostInfo, onSubmit, errors, create} = props;
    const [readOnly, setReadOnly] = useState(!create);

    const {t} = useTranslation("animal");

    return <Modal
        show={true}
        onHide={loading ? () => void 0 : onClose}
        size={"xl"}
        className={"lost-modal light-background"}
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{t('as_lost')}</Modal.Title>
        </Modal.Header>
        <Loading visible={loading}/>
        <Modal.Body>
            <ModalWithNoMargin>
                <div className={classnames("lost-register-form", {"view-mode": readOnly})}>

                    <GenericFormError t={t} errors={errors}/>

                    {readOnly &&
                    <FormHandleNotSaved
                        remote={remoteLostInfo}
                        local={lostInfo}
                        loading={loading}
                        readOnly={readOnly}
                        setLocal={setLostInfo}
                        setReadOnly={setReadOnly}
                        onSubmit={onSubmit}/>
                    }


                    <TypeSelectorComponent
                        t={t}
                        setLostInfo={setLostInfo}
                        lostInfo={lostInfo}
                        readOnly={readOnly}
                    />


                    <div className={"form-body"}>

                        <LostCloseByTransferAlertComponent pendingAnimalTransferId={remoteLostInfo?.pendingAnimalTransferId}/>

                        {lostInfo.status
                            ? <FormComponent {...props} readOnly={readOnly} setReadOnly={setReadOnly}/>
                            : <div className={"no-status-set"}>
                                {t("select_status_msg")}
                            </div>
                        }

                        {(readOnly && !create) &&
                            <ProcessObservationsContainer {...props} />
                        }

                    </div>

                    <div className={"buttons"}>
                        <Button variant="secondary" onClick={onClose}>
                            {t("close")}
                        </Button>

                        {!readOnly &&
                        <Button
                            variant="primary"
                            disabled={!lostInfo.status}
                            onClick={onSubmit}>
                            {t("confirm")}
                        </Button>
                        }

                    </div>

                </div>
            </ModalWithNoMargin>
        </Modal.Body>
    </Modal>


}

export default ModalComponent;
