import React, {useCallback, useState} from "react";
import {Col, Modal} from "react-bootstrap";
import Loading from "layout/modules/Loading/Loading";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import {readFileContent} from "system/Files/ReadFileContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import Button from "react-bootstrap/Button";
import {acceptTypeImages, acceptTypesPdf} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";
import "app/control/assets/css/add-files.scss"

const AddNewDocumentComponent = props => {

    const {t, onClose, onSubmit, loading} = props;

    const [uploadingCounter, setUploadingCounter] = useState(0);
    const [files, setFiles] = useState([]);

    return <Modal show={true} className={"dark"} backdrop="static" keyboard={false} onHide={onClose}>

        <Loading visible={loading}/>
        <Modal.Header closeButton>
            <Modal.Title>{t('add_new_document')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className={"add-files-modal-content"}>
                <div className={"image"}>
                    <DragDropFile
                        maxFiles={10}
                        acceptTypes={{
                            ...acceptTypeImages,
                            ...acceptTypesPdf,
                        }}
                        text={t('add_new_document_description')}
                        onFileUploaded={useCallback((nf) => {
                            nf.forEach((file) => {
                                setUploadingCounter((uc) => uc + 1);
                                readFileContent(file, (content) => {
                                    setFiles(files => ([
                                        ...files,
                                        {
                                            filename: file.name,
                                            type: file.type,
                                            contents: btoa(content)
                                        }
                                    ]))
                                });
                                setUploadingCounter((uc) => uc - 1);
                            });
                        }, [setUploadingCounter, setFiles])}
                    />
                </div>

                <Col lg={6} sm={12} className={"file-list"}>
                    <Loading visible={uploadingCounter > 0}/>
                    {files.map((file, id) =>
                        <div className={"file"} key={id}>
                            <div className={"name"}>
                                {file.filename}
                            </div>
                            <div className={"close"}>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={"delete"}
                                    onClick={() => {
                                        setFiles(files => {
                                            const documents = [...files];
                                            documents.splice(id, 1);
                                            return documents;
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </Col>

            </div>


        </Modal.Body>
        <ModalButtons>

            <Button variant="secondary" onClick={onClose}>
                {t("close")}
            </Button>

            <Button
                variant="primary"
                disabled={files.length === 0}
                onClick={() => onSubmit(files, onClose)}
            >
                {t("add_files")}
            </Button>

        </ModalButtons>

    </Modal>
}

export default AddNewDocumentComponent;
