import {useTranslation} from "react-i18next";
import React, {useRef} from "react";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import ClosedLostContainer from "app/animals/containers/lost/ClosedLostContainer";
import LostComponent from "app/animals/components/lost/show/LostComponent";
import TableMenuEntryOptions from "layout/modules/TableAdvanced/components/TableEntryMenuOptions";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import MenuDropdownHeader from "layout/modules/MenuDropdown/components/MenuDropdownHeader";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import {Link} from "react-router-dom";
import LostCloseMenuOptionsComponent from "app/animals/components/lost/other/LostCloseMenuOptionsComponent";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import HasBalance from "modules/Billing/HasBalance";

const ListEntryMenuOptionsComponent = props => {

    const {lostInfo, placement, children, ...otherProps} = props;
    const {t} = useTranslation("animal");

    const setLostRef = useRef(null);
    const setDeliveredRef = useRef(null);

    return <>

        {!lostInfo?.isClosed &&
        <AnimalOperationAllowed animal={lostInfo} operation={AnimalOperations.SET_DELIVERED}>
            <ClosedLostContainer {...otherProps} transponder={lostInfo.transponder} ref={setDeliveredRef}/>
        </AnimalOperationAllowed>
        }

        <LostComponent
            {...otherProps}
            lostInfo={lostInfo}
            transponder={lostInfo?.transponder}
            ref={setLostRef}
        />


        <TableMenuEntryOptions placement={placement} label={children}>
            <MenuDropdownContent>
                <MenuDropdownHeader>
                    {t('table_menu_options')}
                </MenuDropdownHeader>

                <MenuDropdownOption>
                    <div onClick={() => setLostRef.current?.setVisible()}>
                        {t('view_lost_info')}
                    </div>
                </MenuDropdownOption>

                {!lostInfo?.isExternal &&
                <MenuDropdownOption>
                    <Link to={"/animal/view/" + lostInfo.transponder}>
                        {t('view_animal')}
                    </Link>
                </MenuDropdownOption>
                }

                {lostInfo?.isExternal &&
                <UserHasPermission permission={['REGISTER_ANIMAL']}>
                    <HasBalance>
                        <MenuDropdownOption>
                            <Link to={"/animal/create"} state={{transponder_auto_fill: lostInfo.transponder}}>
                                {t('create_title')}
                            </Link>
                        </MenuDropdownOption>
                    </HasBalance>
                </UserHasPermission>
                }

                {(!lostInfo?.isClosed && !lostInfo?.pendingAnimalTransferId) &&
                <LostCloseMenuOptionsComponent {...props} animal={lostInfo} setDeliveredRef={setDeliveredRef}
                                               open={true}/>
                }

            </MenuDropdownContent>
        </TableMenuEntryOptions>
    </>


}

export default ListEntryMenuOptionsComponent;
