export const pt = {


    role_ph: "Selecione um perfil",
    role_no: "Nenhum perfil encontrado",
    role_loading: "A procurar Perfis..",

    entity_ph: "Selecione uma entidade",
    entity_loading: "A procurar entidades..",
    entity_no: "Nenhuma entidade encontrada",

    entity_profile_ph: "Selecione um perfil de entidade",
    entity_profile_loading: "A procurar perfis de entidade...",
    entity_profile_no: "Nenhum perfil encontrado",

    entity_vat_no: "Sem resultados. Por favor introduza o NIF completo",
    entity_vat_loading: "A procurar entidade pelo NIF...",
    entity_vat_ph: "Por favor introduza o NIF da entidade",

    generic_no: "Nenhum resultado encontrado",
    generic_loading: "A Procurar..",
    generic_ph: "Selecione uma opção",

    veterinary_ph: "Selecione um médico veterinário",
    veterinary_loading: "A Procurar médico veterinário...",
    veterinary_no: "Nenhum resultado encontrado",

    animal_no: "Sem resultados. Por favor introduza o transponder completo",
    animal_loading: "A procurar animais ...",
    animal_ph: "Por favor introduza o transponder do animal",

    owner_no: "Sem resultados. Por favor introduza o Nome ou NIF completo",
    owner_loading: "A procurar titulares ...",
    owner_ph: "Por favor pesquise um titular",

    versions_no: "Versão não encontrada",
    version_ph: "Selecione uma versão",
    versions_loading: "A procurar versão...",

};

export default pt;
