import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import React, {useCallback, useRef} from "react";
import {Link} from "react-router-dom";
import classnames from "classnames";
import MergeComponent from "../merge/MergeComponent";
import RegisterInterventionComponent from "app/interventions/components/RegisterInterventionComponent";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import {ANTI_RABIES_ID} from "app/interventions/utils/antiRabies/isAntiRabies";
import {STERILIZATION_ID} from "app/interventions/utils/sterilization/isSterilization";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {useNavigate} from "react-router";
import {UserType} from "types/Users";
import AddLicenseButtonComponent from "app/licensing/components/AddLicenseButtonComponent";
import canLicenseAnimal from "app/licensing/utils/canLicenseAnimal";

const OperationsComponent = (props) => {

    const {t} = useTranslation("animal");
    const {animal} = props;

    const navigate = useNavigate();
    const registerInterventionRef = useRef(null);

    const handleOwnerClick = useCallback(
        () => navigate("/owner/" + animal.owner?.id, {
            state: {
                animal: animal?.transponder,
            }
        }),
        [animal?.owner.id, animal?.transponder, navigate]
    );

    return <>

        <AnimalOperationAllowed animal={animal} operation={AnimalOperations.REGISTER_INTERVENTION}>
            <RegisterInterventionComponent
                {...props}
                ref={registerInterventionRef}
                animal={animal}
            />
        </AnimalOperationAllowed>


        <div className={"operation-buttons"}>
            <div className={"title"}>
                {t('shortcuts')}
            </div>

            <div className={"buttons"}>
                <AnimalOperationAllowed animal={animal} operation={AnimalOperations.REGISTER_INTERVENTION}>
                    <Button variant="primary"
                            onClick={() => registerInterventionRef.current?.openWithType(ANTI_RABIES_ID)}>
                        {t('anti_rabies')}
                    </Button>

                    <AnimalOperationAllowed animal={animal} operation={AnimalOperations.SET_STERILIZATION}>
                        <Button variant="primary"
                                onClick={() => registerInterventionRef.current?.openWithType(STERILIZATION_ID)}>
                            {t('sterilize')}
                        </Button>
                    </AnimalOperationAllowed>
                </AnimalOperationAllowed>

                {canLicenseAnimal(animal) &&
                    <AddLicenseButtonComponent {...props} animal={animal}/>
                }

            </div>
        </div>

        <div className={"operation-buttons with-space"}>
            <div className={"title"}>
                {t('operations')}
            </div>


            <div className={"buttons"}>

                <AnimalOperationAllowed animal={animal} operation={AnimalOperations.CHANGE_OWNER}>
                    <Link to={"/animal/owner/change/" + animal.transponder}>
                        <Button variant="primary">
                            {t("change_owner_btn")}
                        </Button>
                    </Link>
                </AnimalOperationAllowed>

                <AnimalOperationAllowed animal={animal} operation={AnimalOperations.EDIT}>
                    <Link to={"/animal/view/" + animal.transponder} state={{edit: true}}>
                        <Button variant="primary">
                            {t("edit_animal_btn")}
                        </Button>
                    </Link>
                </AnimalOperationAllowed>

                <UserHasPermission permission={"MERGE_ANIMALS"}>
                    <MergeComponent {...props} transponder={animal.transponder}>
                        {({setVisible, active}) =>
                            <Button variant="primary" onClick={setVisible} className={classnames({'active': active})}>
                                {t("merge_animal")}
                            </Button>
                        }
                    </MergeComponent>
                </UserHasPermission>


                {animal.owner?.id &&
                    <UserHasPermission permission={"READ_OWNER"} orUserTypes={UserType.VETERINARY}>
                        <Button variant="primary" onClick={handleOwnerClick}>
                            {t("open_owner")}
                        </Button>
                    </UserHasPermission>
                }


                <AnimalOperationAllowed animal={animal} operation={AnimalOperations.DELETE}>
                    <Button variant="danger">
                        {t("delete")}
                    </Button>
                </AnimalOperationAllowed>


            </div>

        </div>
    </>
}

export default OperationsComponent;
