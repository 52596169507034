import {useNavigate, useLocation} from "react-router";
import {useEffect, useMemo} from "react";
import {useMutate} from "system/Rest/Rest";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import locale from "../locales/locales";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useApiState} from "system/API/APIStateContext";
import useTranslations from "system/Translations/UseTranslations";
import {UserType} from "types/Users";
import Loading from "layout/modules/Loading/Loading";
import generateSession, {generateTwoFactorSession} from "app/login/utils/generateSession";

const OMVLoginRedirectContainer = props => {

    const location = useLocation();
    const showToast = useShowToast();
    const {authentication, setAuthentication} = useApiState();
    const navigate = useNavigate();

    const {t} = useTranslations('login', locale);

    const urlParameters = useMemo(() => new URLSearchParams(location.search), [location]);

    const state = urlParameters?.get('state');
    const code = urlParameters?.get('code');
    const error = urlParameters?.get('error');

    const {mutate: validateToken, loading} = useMutate({
        verb: "POST",
        path: "/auth-omv"
    });

    useEffect(
        () => {

            if (authentication?.isAuthenticated) {
                navigate('/');
            }
            else if (code && state && navigate && showToast && setAuthentication) {
                validateToken({
                    code: code,
                    state: state,
                }).then((response => {

                    if (response?.twoFaToken) {
                        setAuthentication(generateTwoFactorSession(response, UserType.VETERINARY));
                        navigate('/');
                    }
                    else {
                        setAuthentication(generateSession(response, UserType.VETERINARY));
                        navigate('/auth/entities');
                    }
                })).catch((error) => {

                    if (error?.data?.message === "USER_INACTIVE")
                        showToast({
                            title: t('ERROR_OMV_LOGIN'),
                            message: t('ERROR_OMV_INACTIVE_MSG'),
                            icon: faTimes,
                            className: 'error',
                        })
                    else
                        showToast({
                            title: t('ERROR_OMV_LOGIN'),
                            message: t('ERROR_OMV_LOGIN_MSG'),
                            icon: faTimes,
                            className: 'error',
                        })

                    navigate('/');
                })
            } else if (error && navigate) {
                navigate('/');
            }
        },
        // eslint-disable-next-line
        []
    )


    return <Loading visible={loading}/>
}

export default OMVLoginRedirectContainer;
