import {useMemo} from "react";
import {isVatValidated} from "app/owners/utils/form/vatCountries";
import {useTranslation} from "react-i18next";
import appConfig from "configs/config";
import {OwnerType} from "types/Onwer";

const useOwnerIdModel = (owner) => {

    const {t} = useTranslation('owner');

    return useMemo(() => {
        const isVat = isVatValidated(owner.country) || (owner.type !== OwnerType.SINGULAR);
        const model = {
            cc: (owner.nationality === appConfig.defaultCountry && owner.type === OwnerType.SINGULAR),
        }

        //Customizations
        model.vatLabel = isVatValidated(owner.country)
            ? t('VAT_' + owner.country + "_" + owner.type)
            : t('VAT_' + owner.type);

        model.vatHelpId = isVatValidated(owner.country)
            ? 'VAT/' + owner.country + "/" + owner.type
            : 'VAT/' + owner.type;

        model.passport = (!isVat && owner.nationality !== appConfig.defaultCountry);
        model.vat = isVat;

        if (owner.type === OwnerType.TRACES) {
            model.vatLabel = t('traces_number');
            model.vatPlaceHolder = t('traces_ph')
            model.vatHelpId = 'VAT/traces_number';
        }


        if (owner?.hasLocalVatNumber && owner.type === OwnerType.SINGULAR && owner.nationality === appConfig.defaultCountry) {
            model.vat = true;
            model.passport = false;
            model.vatLabel = t('VAT_' + owner.nationality + "_" + owner.type)
            model.vatHelpId = 'VAT/' + owner.nationality + "/" + owner.type;
        }

        return model;

    }, [owner.nationality, owner.country, owner.type, owner?.hasLocalVatNumber, t]);

}

const canChooseLocalVat = (owner) => (owner.nationality === appConfig.defaultCountry && owner.country !== appConfig.defaultCountry)

export default useOwnerIdModel;
export {canChooseLocalVat};
