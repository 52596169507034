const isMailFromSIAC = (message) => {

    if (message?.creationUser?.email?.toLowerCase().includes("siac")
        || message?.creationUser?.entity?.toLowerCase().includes("siac"))
        return true;

    return false;
}

const getInitials = (nameRaw) => {

    if (!nameRaw)
        return "UE";


    let name = nameRaw;

    if (name.trim().split(' ')?.length > 1) {
        if (name.startsWith("Dr") || name.startsWith("SIAC")) {
            name = name.substr(name.indexOf(" ") + 1);
            name = name.replaceAll(/-/gi, "");

        }
    }

    const namesArray = name.trim().split(' ');
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
}


const shouldRefresh = (location) => (location.pathname === "/support/" || location.pathname === "/");

export {isMailFromSIAC, getInitials, shouldRefresh};
