import BaseToastComponent from "./BaseToastComponent";

import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import * as PropTypes from "prop-types";

const ToastSuccess = (props) => {
    const {visible = true, icon = faCheck, duration = 5000} = props;
    if (!visible) return null;
    return <BaseToastComponent  {...props} icon={icon} duration={duration}/>
}

const ToastError = (props) => {
    const {duration = 5000} = props;
    return <ToastSuccess {...props} icon={faTimes} className={'error'} duration={duration}/>
}


export {
    ToastSuccess,
    ToastError,
}


ToastError.propTypes = ToastSuccess.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    duration: PropTypes.number,
    visible: PropTypes.bool
};

