import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const ButtonComponent = React.forwardRef((props,ref) => {

    const {icon, notification = false, blink = false,  className, ...otherProps} = props;

    return <div
        {...otherProps}
        ref={ref}
        className={classnames("btn-rounded-primary", className, {
            "with-notification": notification,
            "blink": blink
        })}>
        {notification &&
        <div className={"new-notification"}/>
        }
        <FontAwesomeIcon icon={icon}/>
    </div>
});

export default ButtonComponent;
