import {useCallback, useEffect, useState} from "react";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import locale from "../locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import AssociateNewEntityComponent from "app/login/components/veterinaries/AssociateNewEntityComponent";
import {useGet, useMutate} from "system/Rest/Rest";

const AssociateNewEntityContainer = (props) => {

    const {loadingEntities, entities: associatedEntities, onReFetchEntities, onAssociate} = props;

    const [vatNumber, setVatNumber] = useState(null);
    const [entities, setEntities] = useState(null);

    const {t} = useTranslations('login', locale);
    const showToast = useShowToast();

    //558117384
    const {data, loading} = useGet({
        path: "/entities/vat/" + vatNumber,
        lazy: !(vatNumber?.length === 9),
        debounce: 300,
    });

    const {mutate: associate, loading: associating} = useMutate({
        verb: "POST",
        path: "/veterinaries/associate-entities"
    });

    useEffect(() => {
        if (data) {
            setEntities(data.map(entity => {
                const ae = associatedEntities?.content?.find(e => e.entity.id === entity.id);
                return {
                    id: entity.id,
                    name: entity.name,
                    status: ae ? ae.status : null,
                    vatNumber: entity.vatNumber,
                }
            }));
        }
    }, [data, setEntities, associatedEntities])

    return <AssociateNewEntityComponent
        {...props}
        vatNumber={vatNumber}
        entities={entities}
        loading={loading || associating || loadingEntities}
        onSearch={useCallback(
            (q) => {
                const query = q?.trim();
                if (vatNumber !== query) {
                    setEntities(null)
                    setVatNumber(query);
                }
            },
            [setEntities, setVatNumber, vatNumber]
        )}
        onAssociate={useCallback((entityId, data) => {
                associate({
                    entityId: entityId,
                }).then(() => {
                    onReFetchEntities()
                    showToast({
                        id: "R" + entityId,
                        title: t('SUCCESS_REQUEST_ASSOCIATE'),
                        message: t('SUCCESS_REQUEST_ASSOCIATE_MSG', {name: data.name}),
                        icon: faCheck,
                    });

                    if (onAssociate)
                        onAssociate(entityId, data);

                });
            },
            [associate, onAssociate, onReFetchEntities, showToast, t]
        )}
    />

}

export default AssociateNewEntityContainer;
