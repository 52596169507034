import React from "react";
import RemoteListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RemoteListDropdown";

const ReportCategoryDropdown = props => {

    const {isOperation} = props;

    return <RemoteListDropdown
        {...props}
        url={isOperation ? "/operations/template/categories" : "/reports/template/categories"}
    />

}

export default ReportCategoryDropdown;
