import Modal from "react-bootstrap/Modal";
import React from "react";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import ListContainer from "app/audit/containers/ListContainer";

const PopupComponent = (props) => {

    const {onClose} = props;

    const {t} = useTranslation("audit")

    return <Modal
        show={true}
        size={"xl"}
        className={"dark-background"}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
    >

        <Modal.Header closeButton>
            <Modal.Title>{t('audit')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ListContainer {...props}/>
        </Modal.Body>

        <ModalButtons>
            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>
        </ModalButtons>
    </Modal>


}

export default PopupComponent;
