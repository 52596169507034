import React from "react";
import Loading from "../../../../layout/modules/Loading/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import appConfig from "../../../../configs/config"

const OMVLoginComponent = (props) => {

    const {loading} = props
    const {t} = useTranslation('login', true);

    return <div className={"login-content-area"}>

        <Loading visible={loading}/>

        <Row className={["justify-content-center"]}>
            <Col className={"login-form-area omv-login"}>
                {t('omv_login_text')}

                <a href={appConfig.omv.url} className={"omv-link"}>
                    <Button variant="success" type="submit">
                        {t('omv_login_btn')}
                    </Button>
                </a>

            </Col>
        </Row>

    </div>

}

export default OMVLoginComponent;
