import React, {useCallback, useEffect, useRef, useState} from "react";
import Loading from "layout/modules/Loading/Loading";
import ReactCrop from "react-image-crop";
import canvasPreview from "layout/modules/Forms/PhotoCrop/utils/CanvasPreview";
import {imageIsNotCropped} from "layout/modules/Forms/PhotoCrop/utils/utils";
import 'react-image-crop/src/ReactCrop.scss'

const PhotoCrop = props => {

    const {photo, onChange} = props;

    const imageRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [crop, setCrop] = useState({});

    const onCropComplete = useCallback(
        (crop) => {
            canvasPreview(imageRef.current, previewCanvasRef.current, crop);
            onChange(previewCanvasRef.current.toDataURL("image/png", 1).split(';base64,')[1]);
        },
        [onChange]
    );

    //Center crop on photo change
    useEffect(
        () => {
            if (!photo) {
                setCrop(null);
                return;
            }

            setLoading(true);
            setTimeout(() => {
                const {width, height} = imageRef?.current;
                const crop = {
                    unit: 'px',
                    y: width > height ? 0 : (height - width) / 2,
                    x: width > height ? (width - height) / 2 : 0,
                    width: width > height ? height : width,
                    height: width > height ? height : width,
                };

                setCrop(crop);
                if (imageIsNotCropped(width, height, crop)) {
                    onCropComplete(crop)
                }
                setLoading(false);
            }, 500);

        },
        // eslint-disable-next-line
        [setCrop, photo]
    );

    return <div className={"crop-tool"}>
        <Loading visible={loading}/>
        <ReactCrop
            className={"react-crop"}
            crop={crop}
            onChange={c => setCrop(c)}
            onComplete={onCropComplete}
            aspect={1}
            keepSelection={true}
        >
            <img src={`data:image/jpeg;base64,${photo}`} alt={"crop"} ref={imageRef}/>
        </ReactCrop>
        <canvas
            ref={previewCanvasRef}
            style={{
                display: "none",
                border: '1px solid black',
                objectFit: 'contain',
                width: crop?.width,
                height: crop?.height,
            }}
        />
    </div>

}

export default PhotoCrop;
