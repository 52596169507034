import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import FormAnimalComponent from "app/control/modules/transfers/components/create/AnimalComponent";
import localeOwners from "app/owners/locales/locales";
import localeAnimals from "app/animals/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import TypeComponent from "app/owners/components/form/TypeComponent";
import OwnerCheckContainer from "app/owners/containers/form/OwnerCheckContainer";
import PersonalDataComponent from "app/owners/components/form/PersonalDataComponent";
import EntityResponsibleComponent from "app/owners/components/form/EntityResponsibleComponent";
import {OwnerType} from "types/Onwer";
import AddressComponent from "app/owners/components/form/AddressComponent";
import ContactsComponent from "app/owners/components/form/ContactsComponent";
import NotificationComponent from "app/control/modules/transfers/components/create/NotificationComponent";

const FormComponent = (props) => {

    const {t} = useTranslation('transfer');

    const {errors = {}, animalOwner, setAnimalOwner, readOnly, loading, animals, isBulk} = props;

    //Load Owner translations
    useTranslations('owner', localeOwners);
    useTranslations('animal', localeAnimals);

    return <>

        {!readOnly &&
            <>
                <FormHandleNotSaved
                    remote={{}}
                    local={animalOwner}
                    loading={loading}
                    readOnly={false}
                />
                <FormAnimalComponent
                    errors={errors}
                    animals={animals}
                    isBulk={isBulk}
                    animalOwner={animalOwner}
                    setAnimalOwner={setAnimalOwner}
                    readOnly={readOnly}
                />
            </>
        }

        <Box>
            <BoxTitle>{t('owner_type')}</BoxTitle>
            <TypeComponent
                errors={errors}
                owner={animalOwner}
                setOwner={setAnimalOwner}
                readOnly={readOnly}
                create={true}
            />
        </Box>

        <OwnerCheckContainer
            owner={animalOwner}
            setOwner={useCallback((owner) => {
                    return setAnimalOwner(animalOwner => ({
                        ...animalOwner,
                        ...owner()
                    }))
                },
                [setAnimalOwner]
            )}
            readOnly={readOnly}
            create={true}
        />

        {animalOwner.country &&
            <>
                <PersonalDataComponent
                    errors={errors}
                    owner={animalOwner}
                    setOwner={setAnimalOwner}
                    readOnly={readOnly}
                    create={true}
                />

                {(animalOwner.type === OwnerType.COLLECTIVE) &&
                    <EntityResponsibleComponent
                        errors={errors}
                        owner={animalOwner}
                        setOwner={setAnimalOwner}
                        readOnly={readOnly}
                        create={true}
                    />
                }

                <AddressComponent
                    errors={errors}
                    owner={animalOwner}
                    setOwner={setAnimalOwner}
                    readOnly={readOnly}
                />

                <ContactsComponent
                    errors={errors}
                    owner={animalOwner}
                    setOwner={setAnimalOwner}
                    readOnly={readOnly}
                />

                <NotificationComponent
                    errors={errors}
                    owner={animalOwner}
                    setOwner={setAnimalOwner}
                    readOnly={readOnly}
                />

            </>
        }
    </>
}

export default FormComponent;
