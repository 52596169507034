export const pt = {

    placeholder: "Adicione uma etiqueta",
    no_results: "Não encontrou resultados",
    loading_message: "A procurar",
    add_label: "Adicionar etiqueta",

    list_by_label: "Controlos por label",
    labels_filter: "Filtrar por labels",

    ANIMAL_PRE_REGISTER: "Pré registos",
    ANIMAL_TRANSFER: "Transmissões de titularidade",
    OWNER_MERGE: "Fusão de titulares",
    OWNER_REVIEW: "Revisão de titulares suspeitos",
    OWNER_DATA_UPDATE: "Atualização titular",
    PENDING_LOST_ANIMAL: "Animais perdidos",
    ANIMAL_DIAC_REQUEST: "Pedidos de DIAC",
    ANIMAL_DEATH_REQUEST: "Validar falecimentos",

    no_active_labels: "Não existem entradas para apresentar",
    filter_by_tag: "Filtrar",




};

export default pt;
