import React, {useMemo} from "react";
import locale from "./locales/locales"
import ListComponent from "./components/ListComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";

const List = (props) => {

    const {dashboard} = props;

    return <GenericListContainer
        {...props}
        module="notification"
        locale={locale}
        hideTitle={dashboard}
        queryParameters={useMemo(() => ({
            sortProperty: 'date',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 10
        }),[])}>
        <ListComponent dashboard={dashboard}/>
    </GenericListContainer>

}

export default List;
