export const pt = {

    title: "Entidades",
    list: "Entidades",
    view: "Entidade",
    create_title: "Criar nova entidade sub-entidade",
    create: "Criar Nova Entidade",
    entity_files: "Documentos da entidade (faturação e outros)",
    generic_files: "Outros documentos",
    billing_transactions: "Pagamentos",
    mb_references: "Ref. multibanco",
    users: "Utilizadores",
    sub_entities: "Sub-entidades",

    table_menu_options: "Opções",

    no_results: "Não foi encontrada nenhuma entidade",

    name: "Nome",
    brand: "Designação comercial",
    brand_ph: "Marca ou designação comercial opcional",
    entity_profile: "Perfil de entidade",
    parent_entity: "Entidade mãe",
    description: "Descrição",
    type: "Tipo",
    type_ph: "Selecione um tipo de entidade",

    status : {
        label: "Estado",
        ACTIVE: "Ativa",
        INACTIVE: "Inativa",
    },

    situation: "Situação",
    situation_ph: "Selecione uma situação",
    OPEN: "Aberta",
    CLOSED: "Fechada",
    TRANSFERRED: "Transferida",
    transferred_to: "Nova entidade",


    //Alerts
    entity_is_disabled: "Entidade inativa",
    entity_is_disabled_msg: "Esta entidade não está ativa pelo que não serão autorizados acessos nem registos através da mesma.",

    entity_is_transferred: "Entidade transferida",
    entity_is_transferred_msg: "Esta entidade foi transferida para uma nova entidade.",
    entity_transferred_link: "Para aceder à nova entidade carregue aqui",

    entity_is_closed: "Entidade fechada",
    entity_is_closed_msg: "Esta entidade foi fechada definitivamente",

    filter: "Pesquisar",
    include_sub_entities: "Incluir sub-entidades",
    only_root_entities: "Esconder sub-entidades",
    yes: "Sim",
    no: "Não",

    no_entities_found: "Não foram encontradas entidades",

    //List Success Creation and Update
    UPDATE_SUCCESS: "Entidade atualizada",
    UPDATE_SUCCESS_MSG: "A entidade \"{{name}}\" foi atualizada com sucesso!",

    //List Success Creation and Update
    CREATE_SUCCESS: "Nova entidade criada",
    CREATE_SUCCESS_MSG: "A entidade \"{{name}}\" foi criada com sucesso!",

    DELETE_SUCCESS: "Entidade Apagada",
    DELETE_SUCCESS_MSG: "A entidade \"{{name}}\" foi apagada com sucesso!",

    DELETE_FAILED: "Impossível apagar entidade ",

    DELETE_ENTITY_CONFIRM: "Confirmação",
    DELETE_ENTITY_CONFIRM_MSG: "Ao apagar a entidade \"{{name}}\" TODOS os utilizadores, roles e restante informação tambem serão apagados!\n\n Se deseja continuar escreva \"{{name}}\" na caixa de texto e pressione o botão de confirmação .",

    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    //Show Entity
    general: "Geral",
    veterinaries: "Médicos veterinários",

    //Form
    entity_data: "Dados da entidade",
    description_ph: "Uma descrição opcional da entidade",
    name_ph: "Introduza o nome da entidade",

    vat: "NIF",
    vat_ph: "Introduza um NIF válido de empresa",

    website: "Página Web",
    website_ph: "Introduza o endereço da página web da entidade",

    save_changes: "Guardar alterações",
    create_entity: "Criar entidade",

    contacts: "Contactos",

    contact_type: "Tipo",
    generic_select_ph: "Selecione..",

    EMAIL: "Email",
    PHONE: "Telefone",

    contact_name: "Nome/Descrição",
    contact_name_ph: "Escreva um nome",

    contact_category: "Categoria",
    PRINCIPAL: "Principal",
    GENERAL: "Alternativo",
    BILLING: "Faturação",

    contact: "Contacto",
    contact_ph: "Escreva o contacto",

    address: "Morada",

    //Billable
    billable: "Faturação / Pagamentos",
    price_table: "Tabela de preços",
    price_table_ph: "Por favor escolha uma tabela de preços",


    //GeoFence Areas
    geofence_form: "Limitação geográfica",
    district: "Distrito",
    county: "Concelho",
    parish: "Freguesia",


    //Errors
    MISSING_NAME: "Por favor introduza um nome válido",
    MISSING_PARENT_ENTITY: "Por favor selecione uma entidade",

    MISSING_CATEGORY: "Por favor selecione uma categoria",
    INVALID_CATEGORY: "Por favor selecione uma categoria",

    MISSING_TYPE: "Por favor selecione um tipo",
    INVALID_TYPE: "Por favor selecione um tipo",


    INVALID_STATUS: "Por favor selecione um estado",
    MISSING_STATUS: "Por favor selecione um estado",

    MISSING_EMAIL: "Por favor introduza um email",
    INVALID_EMAIL: "O email não é válido. Por favor introduza um email",

    MISSING_ENTITY_PROFILE: "Por favor selecione um perfil de entidade",
    INVALID_ENTITY_PROFILE: "Por favor selecione um perfil de entidade válido",
    ENTITY_INVALID_CHILD_TYPE: "Entidade inválida. Uma importador não pode pertencer a uma distribuidor",

    MISSING_VAT_NUMBER: "Por favor preencha o NIF.",
    VAT_NUMBER_ALREADY_EXISTS: "NIF já existente. Por favor verifique no sistema a entidade já existente",
    INVALID_VAT_NUMBER: "O NIF introduzido não é válido. O NIF deve pertencer a uma entidade colectiva",

    MISSING_CONTACT_NAME: "Por favor introduza um nome/descrição",
    MISSING_CONTACT: "Por favor introduza um contacto",
    INVALID_PHONE: "Por favor introduza um número de telefone válido.",
    CONTACT_EXCEEDS_MAX_SIZE: "Contacto Inválido. O campo não pode ter mais de 100 caracteres.",

    MISSING_GEOFENCE: "Por favor defina uma area geográfica valida",
    INVALID_GEOFENCE: "Por favor verifique a area geográfica escolhida",

    INVALID_PRICE_TABLE: "Por favor selecione uma tabela de preços",
    MISSING_PRICING_TABLE: "Por favor selecione uma tabela de preços",

    SUB_ENTITY_CANNOT_HAVE_VAT_NUMBER: "Uma sub-entidade não pode ter NIF",

    //Veterinaries
    no_associated_veterinaries: "Neste momento ainda não existem médicos veterinários nesta entidade",
    no_veterinaries_results: "Para procurar um médico veterinário por favor pesquise pelo num. de cédula ou pelo nome ",
    ballot_number: "Cédula",
    clinical_director: "Diretor clínico",

    pending_entity: "Pedidos de acesso recebidos",
    pending_veterinary: "Pedidos enviados com aceitação pendente",

    accept: "Aceitar",
    pending: "Pedido pendente",
    cancel: "Cancelar",
    reject: "Rejeitar",
    request: "Adicionar",

    role: "Nível de Acesso",

    set_as_clinical_director: "Definir como diretor clinico",
    set_as_clinical_director_msg: "Deseja definir o médico veterinário {{name}} como diretor clínico?",
    set_as_clinical_director_success: "O médico veterinário {{name}} é agora o diretor clinico.",

    set_permission: "Alteração de nível de acesso",
    set_permission_msg: "Deseja alterar o nível de acesso do médico veterinário {{name}} para {{role}}?",
    set_permission_success: "Médico veterinário {{name}} está agora com o nível de acesso {{role}}",

    remove_from_entity: "Remover da Entidade",
    remove_from_entity_msg: "Deseja remover o médico veterinário {{name}} da entidade {{entity}}?",
    remove_from_entity_success: "O médico veterinário {{name}} deixou de estar associado à entidade {{entity}}.",

    accept_veterinary_with: "Aceitar como {{role}}",
    request_veterinary_with: "Adicionar como {{role}}",
    no_roles: "Sem permissões para m. veterinário na entidade",

    accept_veterinary: "Aceitar associação",
    accept_veterinary_msg: "Ao aceitar esta operação está a autorizar o médico veterinário {{name}} a aceder à entidade com o nível de acesso {{role}}\nDeseja continuar?",
    accept_veterinary_success: "O médico veterinário {{name}} pode agora aceder com o nível de acesso {{role}}.",

    reject_veterinary: "Rejeitar acesso",
    reject_veterinary_msg: "O pedido de acesso do médico veterinário {{name}} vai ser rejeitado.\nDeseja continuar?",
    reject_veterinary_success: "O pedido de acesso do médico veterinário {{name}} foi rejeitado.",

    cancel_veterinary_request: "Cancelar pedido",
    cancel_veterinary_request_msg: "O pedido enviado para o médico veterinário {{name}} vai ser cancelado.\nDeseja continuar?",
    cancel_veterinary_request_success: "O pedido enviado para o médico veterinário {{name}} foi cancelado.",

    request_veterinary: "Adicionar médico veterinário",
    request_veterinary_msg: "Irá ser enviado um pedido ao médico veterinário {{name}} que permitirá que o mesmo passe a aceder à entidade como {{role}}.\nDeseja Continuar?",
    request_veterinary_success: "O Pedido foi realizado ao médico veterinário {{name}} com sucesso!",

    set_permission_to: "Definir como {{role}}",

    associate_new_veterinary: "Autorizar médico veterinário",

    ERROR_VETERINARIES: "Erro a gerir m. veterinário",
    ERROR_VETERINARIES_MSG: "Ocorreu um erro a gerir o m. veterinário. Por favor tente mais tarde",
    ERROR_ADD_VETERINARIES_MSG: "Ocorreu um erro ao adicionar o m. veterinário. Por favor tente mais tarde",

    //Files
    files: "Documentos",

    //Entity Types
    A1: "A1 - Médicos veterinários individual",
    A2: "A2 - Médicos veterinários coletivo / sociedade",
    B: "B - Instituições autárquicas",
    C: "C - Instituições universitárias",
    D: "D - Associações / Fundações",
    E: "E - Outros",
    F: "F - Junta de freguesia",


};

export default pt;
