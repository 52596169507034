import {useGet} from "system/Rest/Rest";
import {useEffect} from "react";
import Loading from "layout/modules/Loading/Loading";
import fileDownload from "app/files/utils/download";
import {useRestConfig} from "system/Rest/RestfulProvider";
import appConfig from "configs/config";

const DownloadContainer = (props) => {

    const {file, onDownloadComplete} = props;
    const {requestOptions} = useRestConfig();

    const {data, loading} = useGet({
        path: "/documents/" + file.id
    });

    useEffect(() => {
        if (!loading && data) {

            if (!data.directDownloadUrl) {
                fileDownload(data.contents, data.filename, data.type);
            } else {

                fetch(appConfig.api.base.replace("/api/", "") + data.directDownloadUrl, {
                    ...requestOptions
                })
                    .then((response) => response.blob())
                    .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                        const _url = window.URL.createObjectURL(blob);
                        window.open(_url, "_blank").focus(); // window.open + focus
                    }).catch((err) => {
                    console.log(err);
                });
            }

            onDownloadComplete();
        }
    }, [loading, data, onDownloadComplete, requestOptions])


    if (loading) {
        return <Loading visible={true}/>
    }

    return null;


}

export default DownloadContainer;
