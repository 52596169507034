import Collapse from "layout/modules/Collapse/Collapse";
import Box from "layout/modules/Box/Box";
import Button from "react-bootstrap/Button";
import "app/control/assets/css/file-preview.scss";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useState} from "react";
import DownloadContainer from "app/files/containers/DownloadContainer";
import {Modal} from "react-bootstrap";
import FilePreviewComponent from "app/control/components/files/FilePreviewComponent";
import BoxTitle from "layout/modules/Box/components/BoxTitle";

const FilePreviewMobileComponent = props => {

    const {request, t} = props;

    const totalDocuments = request?.documents?.length;

    const [download, setDownload] = useState(null);
    const handleDownloadComplete = useCallback(() => setDownload(null), [setDownload]);

    const [isPreviewVisible, setPreviewVisible] = useState(false);
    const switchModalVisibility = useCallback(() => setPreviewVisible(v => !v), [setPreviewVisible]);

    if (totalDocuments === 0)
        return <></>

    return <Collapse title={t('documents', {count: request?.documents?.length})}>

        <Modal show={isPreviewVisible} className={"dark-background"} fullscreen backdrop="static" keyboard={false}
               onHide={switchModalVisibility}>

            <Modal.Header closeButton>
                <Modal.Title>{t('preview_documents')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FilePreviewComponent {...props} request={request} t={t}/>
            </Modal.Body>
        </Modal>

        {download &&
        <DownloadContainer file={download} onDownloadComplete={handleDownloadComplete}/>
        }
        <Box className={"mobile-files-preview"}>

            <BoxTitle>
                {t('documents', {count: request?.documents?.length})}
            </BoxTitle>

            <div className={"file-list-mobile"}>
                {request?.documents?.map((document, id) => (
                    <div className={"file-entry"} key={id}>
                        <div className={"name"}>
                            {document?.title ? document.title : document.filename}
                        </div>

                        <div className={"download"}>
                            <div className={classnames("btn-rounded-primary")} onClick={() => setDownload(document)}>
                                <FontAwesomeIcon icon={faDownload}/>
                            </div>
                        </div>

                    </div>
                ))}
            </div>

            <div className={"preview-files-btn"}>
                <Button onClick={switchModalVisibility}>{t('preview_documents')}</Button>
            </div>

        </Box>


    </Collapse>

}

export default FilePreviewMobileComponent;
