import {useTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import React from "react";
import {Table} from "react-bootstrap";
import BoxScrollComponent from "layout/modules/Box/components/BoxScrollContent";
import ListEntryComponent from "./ListEntryComponent";
import Box from "layout/modules/Box/Box";
import Loading from "layout/modules/Loading/Loading";
import TableReloadWatcher from "modules/GenericTableContainer/utils/TableReloadWatcher";
import "app/support/assets/css/list.scss";
import "app/support/assets/css/status.scss";

const ListDashboardComponent = (props) => {

    const {t} = useTranslation('support');

    const {data, loading} = props;

    return <Container fluid>

        <TableReloadWatcher {...props} />
        <Box className={"ticket-list dashboard-item"}>

            <Loading visible={loading}/>
            <div className={"ticket-list-header"}>
                {t('dashboard_title')}

                <div className={"ticket-buttons"}>
                    <div className={"view-all "}>
                        <Link to={"/support/"}>
                            {t('view_all')}
                        </Link>
                    </div>

                </div>
            </div>

            <BoxScrollComponent>

                <Table>
                    <tbody>
                    <ListEntryComponent isDashboard={true} t={t} data={data}/>
                    </tbody>
                </Table>
                {data?.content?.length === 0 &&
                    <div className={"no-table-results"}>
                        {t('no_tickets_found')}
                    </div>
                }
            </BoxScrollComponent>

        </Box>
    </Container>


}

export default ListDashboardComponent;
