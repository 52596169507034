import UserDetailsComponent from "app/home/components/UserDetailsComponent";
import {useGet} from "system/Rest/Rest";

const UserDetailsContainer = props => {

    const {data: totalAnimals} = useGet({
        path: "/informations/animal-count",
    });


    return <UserDetailsComponent {...props} totalAnimals={totalAnimals}/>
}

export default UserDetailsContainer;
