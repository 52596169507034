import LegalWarningComponent from "layout/modules/LegalWarning/LegalWarningComponent";
import {useEffect, useState} from "react";

const TemplateAgreementComponent = props => {

    const {t, template, setRequest, onTemplateChange} = props;

    const [show, setShow] = useState(false);

    useEffect(
        () => {
            if (template?.agreement?.length > 10)
                setShow(true)
            else
                setShow(false);
        }, [template, setShow]
    )

    if (show) {
        return <LegalWarningComponent
            {...props}
            t={t}
            legalWarning={template?.agreement}
            loading={false}
            onAccept={() => setShow(false)}
            onReject={() => {
                onTemplateChange(null);
                setRequest(request => ({
                    ...request,
                    templateId: null,
                }));
            }}
        />
    }

    return <></>
}

export default TemplateAgreementComponent;
