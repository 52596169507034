import appConfig from "configs/config";

export const pt = {

    title: "Suporte / Tickets",
    view_title: "Pedido de suporte {{id}}",
    view: "Visualizar mensagens",
    control_title: "Pedido de suporte associado",
    box_title: "{{id}} | {{subject}}",
    dashboard_title: "Pedidos de suporte recentes",
    request_support: "Novo pedido de suporte",
    view_btn: "Ver e responder",

    subject: "Assunto",
    last_update: "Ult. atualização",
    creation_date: "Data criação",
    status: "Estado",
    user_and_entity: "Utilizador e entidade",
    id: "Pedido",
    url: "Endereço do pedido",
    no_tickets_found: "Não existem pedidos de suporte para apresentar",
    view_all: "Ver todos",
    new_request: "Novo pedido",

    OPEN: "Aberto",
    PENDING: "Pendente",
    RESOLVED : "Resolvido",
    CLOSED: "Fechado",
    PENDING_USER: "Espera a sua resposta",
    PENDING_OTHERS: "Pendente outros",
    SIAC_RESPONDED: "Respondido pelo " + appConfig.name,
    INTERNAL_CONFIRM: "Resolvido",

    //Filter
    status_ph: "Escolha um estado",
    from_date: "Data inicial",
    to_date: "Data final",
    date_ph: "Data no formato (dd-mm-yyyy)",

    //ERRORS & MEssages
    MISSING_MESSAGE: "Deve preencher com a mensagem que deseja enviar como resposta",

    ERROR_COMMENT: "Erro no formulário",
    ERROR_COMMENT_MSG: "Por favor verifique os campos assinalados",

    SUCCESS_COMMENT: "Sucesso",
    SUCCESS_COMMENT_MSG: "O seu comentário foi registado com sucesso",

    //Form Create
    category: "Categoria",
    select_category: "Escolha uma categoria",
    subject_ph: "Indique o motivo do pedido de suporte",
    description: "Descrição do pedido de suporte",
    create_ticket: "Registar pedido",
    message: "Mensagem",
    cancel: "Fechar",
    send_message: "Enviar",
    no_results: "Não foram encontrados resultados",


    //Error ticket
    MISSING_CATEGORY: "Indique a categoria do pedido",
    MISSING_SUBJECT: "Indique o assunto principal do pedido",
    MISSING_DESCRIPTION: "Indique uma descrição para o pedido de suporte",
    USER_HAS_NO_EMAIL: "Não é possível realizar o pedido de suporte uma vez que não tem email definido na sua conta da Ordem dos Médicos Veterinários",

    SUCCESS_REQUEST: "Pedido registado",
    SUCCESS_REQUEST_MSG: "O seu pedido ficou registado e será respondido com a maior brevidade possível",

    n_responses_one: "{{count}} Resposta",
    n_responses_other: "{{count}} Respostas",
    upload_ticket_files: "Para adicionar um documento largue o ficheiro nesta área ou clique no botão escolher",
    user: "Utilizador",
    entity: "Entidade",

    //Category
    STERILIZATION: "Esterilização",
    RABIES_PROPHYLAXIS: "Profilaxia antirrábica",
    BILLING: "Faturação/Créditos",
    TRANSPONDER_ERROR: "Erro num. transponder/microchip",
    CHANGE_OWNER: "Transmissão titularidade",
    ANIMAL_OWNER_ERRORS: "Dados animal/titular",
    OTHER: "Outro",
    TRACEABILITY: "Rastreabilidade",

    //right menu
    details: "Detalhes",
    link: "Link freshdesk",

};

export default pt;
