import {useTranslation} from "react-i18next";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import Form from "react-bootstrap/Form";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";

const BillingTransactionComponent = props => {

    const {operation} = props;
    const {t} = useTranslation('audit');

    return <Box className={"view-mode"}>
        <BoxTitle>
            {t(operation.objectType)}
        </BoxTitle>

        <Row>

            <Col md={6}>
                <LabelWithValue label={t("operation")} value={t(operation?.objectRaw?.operation)}/>
            </Col>

            <Col md={6}>
                <LabelWithValue label={t("operation_item")} value={operation?.objectRaw?.itemId}/>
            </Col>


            <Col md={6}>
                <LabelWithValue label={t("operation_type")} value={t(operation?.objectRaw?.type)}/>
            </Col>

            <Col md={6}>
                <LabelWithValue label={t("value")} value={operation?.objectRaw?.value}/>
            </Col>


            <Col md={6}>
                <Form.Label>{t('entity')}</Form.Label>
                <EntityDropdown
                    isDisabled={true}
                    value={getOrNull(operation?.objectRaw?.entityId)}
                />
            </Col>

        </Row>


    </Box>

}

export default BillingTransactionComponent;
