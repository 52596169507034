import useTranslations from "../../system/Translations/UseTranslations";
import Title from "../../layout/modules/BaseLayout/ContentArea/components/Title";
import locale from "./locales/locales";
import {useGet} from "system/Rest/Rest";
import React, {useMemo} from "react";
import ViewComponent from "./components/view/ViewComponent";
import Loading from "../../layout/modules/Loading/Loading";
import ownerResolver from "./utils/OwnerResolver";
import {useLocation} from "react-router";
import {useParams} from "react-router-dom";

const Owner = (props) => {

    const {t} = useTranslations('owner', locale);
    const {state} = useLocation();
    const {id} = useParams();

    const {data: owner, loading, error, refetch: reload} = useGet({
        path: "/owners/" + id,
        resolve: ownerResolver,
        queryParams: useMemo(
            () => ({transponder: state?.animal,}),
            [state?.animal]
        )
    });

    return <Title
        title={owner?.name ? t('view') + " " + owner.name : "-"}
        breadcrumbs={[
            {href: "/owner", label: t('list')},
            {href: "/owner/" + id, label: owner?.name},
        ]} {...props}>

        {owner
            ? <ViewComponent
                {...props}
                id={id}
                owner={owner}
                loading={loading}
                reload={reload}
                error={error}/>
            : <Loading visible={true}/>
        }
    </Title>


}

export default Owner;
