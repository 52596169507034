import CountryComponent from "./types/CountryComponent";
import BreedComponent from "./types/BreedComponent";
import EntityComponent from "./types/EntityComponent";
import VeterinaryComponent from "./types/VeterinaryComponent";
import EntityProfileComponent from "./types/EntityProfileComponent";
import UserComponent from "./types/UserComponent";
import BooleanComponent from "./types/BooleanComponent";
import TextComponent from "./types/TextComponent";
import DateComponent from "./types/DateComponent";
import GenderComponent from "./types/GenderComponent";
import AnimalStatusComponent from "./types/AnimalStatusComponent";
import DistrictComponent from "./types/DistrictComponent";
import CountyOrParishComponent from "./types/CountyOrParishComponent";
import {ReportParamType} from "../../utils/ReportParamType";
import AnimalComponent from "./types/AnimalComponent";
import SterilizationTypeComponent from "./types/SterilizationTypeComponent";
import OwnerComponent from "./types/OwnerComponent";
import OptionalComponent from "app/reporting/components/params/types/OptionalComponent";
import VersionComponent from "app/reporting/components/params/types/VersionComponent";
import BreedTypeComponent from "app/reporting/components/params/types/BreedTypeComponent";
import EntityTypeComponent from "app/reporting/components/params/types/EntityTypeComponent";
import VaccineComponent from "app/reporting/components/params/types/VaccineComponent";

const ReportParameter = props => {

    const {parameter} = props;

    switch (parameter?.type) {
        case ReportParamType.COUNTRY:
            return <CountryComponent {...props} />
        case ReportParamType.BREED_TYPE:
            return <BreedTypeComponent {...props} />
        case ReportParamType.BREED:
            return <BreedComponent {...props} />
        case ReportParamType.BREED_GROUP:
            return <BreedComponent {...props} onlyGroups={true}/>
        case ReportParamType.ENTITY:
            return <EntityComponent {...props}/>
        case ReportParamType.VETERINARY:
            return <VeterinaryComponent {...props}/>
        case ReportParamType.ENTITY_PROFILE:
            return <EntityProfileComponent {...props}/>
        case ReportParamType.ENTITY_TYPE:
            return <EntityTypeComponent {...props}/>
        case ReportParamType.USER:
            return <UserComponent {...props}/>
        case ReportParamType.BOOLEAN:
            return <BooleanComponent {...props}/>
        case ReportParamType.NUMERIC:
            return <TextComponent {...props} onlyNumeric={true}/>
        case ReportParamType.ANIMAL_VERSION:
            return <VersionComponent {...props}/>
        case ReportParamType.OWNER_VERSION:
            return <VersionComponent {...props} ownerVersions={true}/>
        case ReportParamType.TEXT:
        case ReportParamType.LOG_PARAMETER:
            return <TextComponent {...props}/>
        case ReportParamType.DATE:
            return <DateComponent {...props}/>
        case ReportParamType.GENDER:
            return <GenderComponent {...props}/>
        case ReportParamType.ANIMAL_STATUS:
            return <AnimalStatusComponent {...props}/>
        case ReportParamType.DISTRICT:
            return <DistrictComponent {...props}/>
        case ReportParamType.COUNTY:
            return <CountyOrParishComponent {...props} isParish={false}/>
        case ReportParamType.PARISH:
            return <CountyOrParishComponent {...props} isParish={true}/>
        case ReportParamType.ANIMAL:
            return <AnimalComponent {...props}/>
        case ReportParamType.OWNER:
            return <OwnerComponent {...props}/>
        case ReportParamType.STERILIZATION_TYPE:
            return <SterilizationTypeComponent {...props}/>
        case ReportParamType.OPTIONAL:
            return <OptionalComponent {...props}/>
        case ReportParamType.RABIES_VACCINE:
            return <VaccineComponent {...props}/>
        default:
            return <></>
    }
}

export default ReportParameter;
