import FormContainer from "../containers/form/FormContainer";
import React, {useRef} from "react";
import BallComponent from "./generic/BallComponent";
import {useTranslation} from "react-i18next";
import {useAnimalState} from "../utils/AnimalContext";
import {useBillingBalanceState} from "modules/Billing/BillingStateContext";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import "app/animals/assets/css/animal.scss"
import Box from "layout/modules/Box/Box";

const CreateComponent = (props) => {

    const {t} = useTranslation('animal');
    const photoUploadRef = useRef(null);

    const {animal} = useAnimalState();
    const billingState = useBillingBalanceState();

    return <>

        {!billingState.canRegisterAnimal &&
        <AdvanceInfoMessage
            icon={faWarning}
            type={"warning"}
            iconType={"warning"}
            title={t("no_credits")}
            message={t("no_credits_msg")}
        />
        }

        <div className={"create-animal"}>

            <div className={"main-panel"}>
                <FormContainer
                    {...props}
                    photoUploadRef={photoUploadRef}
                />
            </div>

            <div className={"right-panel"}>
                <BallComponent
                    editMode={true}
                    animal={animal}
                    hideStateBorder={true}
                    photoUploadRef={photoUploadRef}
                />

                <Box>
                    <div className={"upload-label"} onClick={() => photoUploadRef.current.showPhotoManagement()}>
                        {t('upload_photo')}
                    </div>

                </Box>
            </div>
        </div>
    </>
}

export default CreateComponent;
