export const pt = {

    title: "Vacinas",
    list: "Lista de vacinas",
    create: "Criar",
    create_title: "Criar nova vacina",
    view: "Visualizar/Editar",
    form_title: "Dados da vacina",

    name: "Nome da vacina",
    active: "Ativa",
    requires_permission: "Obriga permissão especial",
    immunization_time_unit: "Unidade de tempo de imunidade",
    immunization_time_unit_ph: "Seleccione uma opção",
    YEAR: "Ano",
    MONTH: "Mês",
    immunization_time_period: "Tempo de imunidade",
    immunization_time_period_ph: "Tempo de imunidade em dias ou anos",

    name_ph: "Escreva o nome da vacina",

    save_form: "Guardar vacina",
    create_form: "Criar vacina",

    no_results: "Não foi encontrada nenhuma vacina",

    true: "Sim",
    false: "Não",


    DELETE_VACCINE_CONFIRM: "Confirmação",
    DELETE_VACCINE_CONFIRM_MSG: "Deseja mesmo apagar a vacina \"{{name}}\"?",
    DELETE_FAILED: "Erro ao apagar",
    RABIES_VACCINE_ALREADY_IN_USE: "Esta vacina já foi utilizada e não pode ser apagada!",
    DELETE_SUCCESS: "Vacina apagada",
    DELETE_SUCCESS_MSG: "A vacina {{name}}\" foi apagada com sucesso",

    //Errors
    MISSING_NAME: "Preencha o nome da vacina",
    MISSING_TIME_PERIOD: "Indique o tempo de imunidade da vacina",
    MISSING_IS_ACTIVE: "Indique se a vacina está ativa",
    MISSING_PERIOD_UNIT: "Indique a unidade de imunidade",

    UPDATE_SUCCESS: "Vacina atualizada",
    UPDATE_SUCCESS_MSG: "A vacina \"{{name}}\" foi atualizada com sucesso!",

    CREATE_SUCCESS: "Vacina criada",
    CREATE_SUCCESS_MSG: "A vacina \"{{name}}\" foi criada com sucesso",



    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",


};

export default pt;
