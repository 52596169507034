import FormComponent from "app/reporting/components/generate/FormComponent";
import {useCallback, useEffect, useState} from "react";
import {useGet, useMutate} from "system/Rest/Rest";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";

const GenerateContainer = props => {

    const showToast = useShowToast();
    const {defaultTemplateId, defaultRequest = {}, isOperation, t} = props;

    const [templateId, setTemplateId] = useState(defaultTemplateId);
    const [request, setRequest] = useState(defaultRequest);

    const [result, setResult] = useState(null);

    const {data, loading: loadingTemplate} = useGet({
        path: isOperation ? "/operations/template/" + templateId : "/reports/template/" + templateId,
        lazy: !templateId
    });

    const {mutate: submit, loading: submitting, error} = useMutate({
        verb: "POST",
        path: isOperation ? "/operations/run" : "/reports/generate-report",
    });

    const errors = useErrorParser(error);

    //Release result on template change
    useEffect(() => setResult(null), [templateId]);

    return <FormComponent
        {...props}
        request={request}
        setRequest={setRequest}
        errors={errors}
        loading={loadingTemplate || submitting}
        onTemplateChange={setTemplateId}
        template={templateId ? data : undefined}
        result={result}
        onSubmit={useCallback(
            (request) => {
                setResult(null);
                submit(request).then((result) => {

                    const async = Object.keys(result).length === 0;

                    if (!async) {
                        setResult(result);
                    } else {
                        setRequest({});
                        setTemplateId(null);
                    }

                    if (request?.isPreview) {
                        showToast({
                            title: t("SUCCESS_PREVIEW"),
                            message: t("SUCCESS_PREVIEW_MSG"),
                            icon: faCheck,
                        });
                    }
                    else {
                        showToast({
                            title: t(async ? 'SUCCESS_SUBMIT_ASYNC_REPORT' : 'SUCCESS_SUBMIT_REPORT'),
                            message: t(async ? 'SUCCESS_SUBMIT_ASYNC_REPORT_MSG' : 'SUCCESS_SUBMIT_REPORT_MSG'),
                            icon: faCheck,
                        });
                    }

                })
                    .catch(error => handleUnknownFormError(error, showToast, t))

            },
            [submit, showToast, t, setResult]
        )}

    />
}

export default GenerateContainer;
