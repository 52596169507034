import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import {useTranslation} from "react-i18next";
import DescriptionComponent from "../DescriptionComponent";
import {useDateTime} from "system/DateTime/DateTime";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import {Link} from "react-router-dom";

const OperationDetailComponent = (props) => {

    const {operation} = props;
    const {t} = useTranslation('audit');

    const getDateAndTime = useDateTime("DD-MM-YYYY HH:mm");

    return <Box className={"view-mode"}>
        <BoxTitle>
            {t('operation_detail')}
        </BoxTitle>
        <Row>
            <Col sm={12} xl={6}>
                <LabelWithValue label={t('date')} value={getDateAndTime(operation?.timestamp)}/>
            </Col>

            <Col sm={12} xl={6}>
                <LabelWithValue label={t('user')}>


                    {operation?.author?.ballotNumber &&
                    <Link to={"/veterinary/byBallot/" + operation?.author?.ballotNumber}>
                        {getOrEmpty(operation?.author?.name)}
                    </Link>
                    }

                    {(operation?.author?.id && !operation?.author?.ballotNumber) &&
                    <Link to={"/user/" + operation?.author?.id}>
                        {getOrEmpty(operation?.author?.name)}
                    </Link>
                    }

                </LabelWithValue>
            </Col>

            <Col sm={12} xl={6}>
                <LabelWithValue label={t('object_type')} value={getOrEmpty(t(operation.objectType))}/>
            </Col>

            <Col sm={12} xl={6}>
                <LabelWithValue label={t('action')} value={getOrEmpty(t(operation.action))}/>
            </Col>

            <Col sm={12} xl={6}>
                <LabelWithValue label={t('affected_id')}>
                    <DescriptionComponent value={operation}/>
                </LabelWithValue>
            </Col>

        </Row>
    </Box>
}

export default OperationDetailComponent;
