const DocumentType = {
    ANIMAL: "ANIMAL",
    ENTITY: "ENTITY",
    OWNER: "OWNER",
    VETERINARY: "VETERINARY",
    USER: "USER",
    ENTITY_PROFILE: "ENTITY_PROFILE",
    BREED: "BREED",
};

export default DocumentType;
