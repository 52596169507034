import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import ListFilterComponent from "app/control/modules/preRegister/components/list/ListFilterComponent";
import {useTranslation} from "react-i18next";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import DateTime from "system/DateTime/DateTime";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import "app/control/modules/preRegister/assets/css/list.scss";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import BulkOptionsComponent from "app/control/modules/transfers/components/list/BulkOptionsComponent";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import {getOrDefault} from "system/Objects/ObjectParameters";
import ObjectType from "app/adminLabels/utils/ObjectType";
import ShowAdminLabels from "app/adminLabels/ShowAdminLabels";

const ListComponent = (props) => {

    const {t} = useTranslation('preRegister');

    const {
        data,
        searchFilter,
        onSearch,
        onPageChange,
        onPageSort,
        onFilterSubmit,
        loading,
        onAccept,
        onReject,
        reload,
        accepting,
        isClosed
    } = props;


    const [selectedItems, setSelectedItems] = useState([]);

    return <Container fluid className={"animal-pre-register-control-list"}>

        <Box>
            <TableHeader>
                <SearchArea value={searchFilter.universalSearch} onSearch={onSearch}>
                    <SearchFilter onSubmit={onFilterSubmit}>
                        <ListFilterComponent t={t} searchFilter={searchFilter} isClosed={isClosed}/>
                    </SearchFilter>
                </SearchArea>
            </TableHeader>

            <SearchDataPagination data={data} onPageChange={onPageChange}>
                <UserHasPermission permission={["MANAGE_ANIMAL_PRE_REGISTER"]}>
                    <BulkOptionsComponent
                        t={t}
                        data={data}
                        onAccept={onAccept}
                        onReject={onReject}
                        reload={reload}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        reasonsURL={"/animals/pre-register/rejection-reasons"}
                    />
                </UserHasPermission>
            </SearchDataPagination>

            <TableAdvanced hover
                           sort={data?.sortBy}
                           order={data?.sortOrder?.toLowerCase()}
                           onSortClick={onPageSort}
                           loading={(loading || !data || accepting)}>
                <thead>
                <tr>

                    <UserHasPermission permission={["MANAGE_ANIMAL_PRE_REGISTER"]}>
                        <th className={"table-checkbox"}></th>
                    </UserHasPermission>

                    <th>
                        {t('transponder')}
                    </th>

                    <th className={"d-none d-lg-table-cell"}>
                        {t('name')}
                    </th>

                    <th className={"d-none d-lg-table-cell"}>
                        {t('veterinary')}
                    </th>

                    <th id={"creationTimestamp"} sorted>
                        {t('date')}
                    </th>

                    <th className={"d-none d-md-table-cell"}>
                        {t('status')}
                    </th>

                    <th/>

                </tr>
                </thead>
                <tbody>

                {data?.content?.map((register, index) => {
                    return <tr key={index}>

                        <UserHasPermission permission={["MANAGE_ANIMAL_PRE_REGISTER"]}>
                            <td className={"table-checkbox"}>
                                <CheckBox
                                    value={getOrDefault(selectedItems.find(e => e.id === register.id), false)}
                                    disabled={register.closed}
                                    onChange={(value) => setSelectedItems((items) => {
                                        if (value) {
                                            return [...selectedItems, register];
                                        } else {
                                            return [...items].filter(item => item.id !== register.id)
                                        }
                                    })}
                                />
                            </td>
                        </UserHasPermission>

                        <td>
                            {register.transponder}
                        </td>


                        <td className={"d-none d-lg-table-cell"}>
                            {register.name}
                        </td>

                        <td className={"d-none d-lg-table-cell"}>
                            {register.veterinary?.name}
                            <div className={"entity-name"}>
                                {register?.creationEntity?.name}
                            </div>

                        </td>

                        <td className="td-sorted">
                            <DateTime format="DD-MM-YYYY | HH:mm:ss">
                                {register.registrationDate}
                            </DateTime>
                        </td>

                        <td className={"d-none d-md-table-cell"}>
                            {register.closed &&
                                <div className={"validation-status"}>
                                    {t(register.accepted)}
                                </div>
                            }

                            {!register.closed &&
                                <div className={"validation-status"}>
                                    {t(register.validationStatus ?? "WAITING_VALIDATION")}
                                </div>
                            }

                            {(register?.labels && register.labels.length > 0) &&
                                <UserHasPermission permission={["MANAGE_ANIMAL_PRE_REGISTER", "MANAGE_LABELS"]}>
                                    <div className={"labels-list"}>
                                        <ShowAdminLabels objectType={ObjectType.ANIMAL_PRE_REGISTER}
                                                         objectId={register?.id}
                                                         labels={register?.labels}/>
                                    </div>
                                </UserHasPermission>
                            }

                        </td>

                        <td className="table-buttons">
                            <div className="buttons">
                                <TableIconButton
                                    path={"/pre-registers/" + register.id}
                                    icon={faSearch}
                                    className={"table-btn btn-rounded-primary small"}
                                />

                            </div>

                        </td>


                    </tr>
                })}
                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
                <div className={"no-table-results"}>
                    {t('no_results')}
                </div>
            }
        </Box>
    </Container>

}

export default ListComponent;
