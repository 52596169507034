//Set translations array for the form
import {useMemo} from "react"
import locale from "../locales/locales"

const useTranslationList = () => useMemo(() => locale.map((lang) => {
    return {
        value: lang.lang.replace("-","_"),
        label: lang.name
    }
}), []);

export default useTranslationList;
