import {useMutate} from "system/Rest/Rest";
import SetDeadComponent from "../components/view/dead/SetDeadComponent";
import {useCallback, useState} from "react";
import useErrorParser from "../../../system/API/Error/ErrorParser";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const SetDeadContainer = (props) => {

    const {animal, reload} = props;
    const showToast = useShowToast();

    const [isVisible, setVisible] = useState(false);

    const {t} = useTranslation("animal");

    const {mutate: setAnimalDead, loading: settingDead, error: errorSubmitting} = useMutate({
        verb: "PUT",
        path: "/animals/" + animal.transponder + "/mark-dead",
    });

    const {mutate: setAnimalAlive, loading: settingAlive} = useMutate({
        verb: "PUT",
        path: "/animals/" + animal.transponder + "/revert-dead",
    });

    const errors = useErrorParser(errorSubmitting);

    return <SetDeadComponent
        {...props}
        loading={settingDead || settingAlive}
        errors={errors}
        isVisible={isVisible}
        setVisible={setVisible}
        setDead={useCallback(
            (date) => {
                setAnimalDead({
                    deathTimestamp: date
                }).then(() => {
                    showToast({
                        title: t('SUCCESS_SET_DEAD'),
                        message: t('SUCCESS_SET_DEAD_MSG', {transponder: animal.transponder}),
                        icon: faCheck,
                    });

                    setVisible(false);
                    reload();
                });



            },
            [t, animal.transponder, showToast, reload, setAnimalDead]
        )}

        setAlive={useCallback(
            () => {
                setAnimalAlive().then(() => {
                    showToast({
                        title: t('SUCCESS_SET_ALIVE'),
                        message: t('SUCCESS_SET_ALIVE_MSG', {transponder: animal.transponder}),
                        icon: faCheck,
                    });

                    reload();

                }).catch(() => {
                    showToast({
                        title: t('ERROR_SUCCESS_SET_ALIVE'),
                        message: t('ERROR_SUCCESS_SET_ALIVE_MSG', {transponder: animal.transponder}),
                        icon: faTimes,
                        className: 'error',
                    });
                })

            },

            [t, animal.transponder, showToast, setAnimalAlive, reload]
        )}
    />

}

export default SetDeadContainer;
