import Box from "layout/modules/Box/Box";
import {Trans, useTranslation} from "react-i18next";
import AccountInfoComponent from "app/owners/components/ownersApp/AccountInfoComponent";
import ActivateAccountComponent from "app/owners/components/ownersApp/ActivateAccountComponent";
import Loading from "layout/modules/Loading/Loading";

import "../../assets/css/owner-app.scss"

const ManageOwnerAccessComponent = props => {

    const {owner, loading} = props;
    const {t} = useTranslation('owner');

    return <Box>


        <Loading visible={loading}/>

        {(!owner?.email)
            ? <div className={"owners-app-no-email"}>
                <div>
                    <Trans>{t('owners_no_email')}</Trans>
                </div>
            </div>
            :
            <>
                {owner?.hasAccount
                    ? <AccountInfoComponent {...props}/>
                    : <ActivateAccountComponent {...props} />
                }
            </>
        }

    </Box>
}

export default ManageOwnerAccessComponent;
