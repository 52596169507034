import React, {useCallback, useMemo} from 'react'
import GenericRemoteClassDropdown from "../GenericRemoteClassDropdown"
import locale from "../locales/locales"
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import useTranslations from "system/Translations/UseTranslations";

const AnimalDropdown = (props) => {

    const {t} = useTranslations('classDropdown', locale);

    const {
        id = "animalId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 5,
    } = props;

    return <UserHasPermission permission={"READ_ANIMAL"}>
        <GenericRemoteClassDropdown
            {...props}
            id={id}
            placeholder={usePlaceHolder(placeholder, t('animal_ph'))}
            noOptionsMessage={useLabelCallback(noOptionsMessage, t('animal_no'))}
            loadingMessage={useLabelCallback(loadingMessage, t('animal_loading'))}
            remotePath="/animals/search"
            labelHandler={useCallback(o => o['transponder'] + ' - ' + o['name'], [])}
            searchFilter={useMemo(() => ({
                currentPage: 0,
                itemsPerPage: maxItems,
                externalLostAnimals: false,
            }), [maxItems])}
        />
    </UserHasPermission>

}

export default AnimalDropdown;
