import TransferAnimalComponent from "app/control/modules/transfers/components/create/TransferAnimalComponent";
import {useCallback, useState} from "react";
import {useMutate} from "system/Rest/Rest";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {defaultOwnerProps} from "app/owners/utils/form/defaultOwnerProps";
import useErrorParser from "system/API/Error/ErrorParser";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";


const TransferAnimalContainer = (props) => {

    const {t} = useTranslation("transfer");

    const {animals, animal, isBulk, transferProcessExtraData} = props;

    const showToast = useShowToast();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [animalOwner, setAnimalOwner] = useState({
        newAnimalName: isBulk ? undefined : animal.name,
        animalIds: isBulk ? animals.map(a => a.id) : undefined,
        useOwnerAddress: true,
        documents: [],
        ...defaultOwnerProps,
        ...transferProcessExtraData,
    });

    const {mutate: submitRequest, error: errorSubmitting} = useMutate({
        verb: "POST",
        path: isBulk ? "/animal-transfers/bulk" : "/animal-transfers/" + animal.transponder,
    });

    const errors = useErrorParser(errorSubmitting);

    return <TransferAnimalComponent
        {...props}
        isBulk={isBulk}
        animals={animals}
        animalOwner={animalOwner}
        loading={isLoading}
        errors={errors}
        setAnimalOwner={setAnimalOwner}
        onSubmit={useCallback(
            (animalOwner) => {
                setLoading(true);
                submitRequest(animalOwner)
                    .then((result) => {
                        if (!isBulk) {
                            showToast({
                                title: t('SUCCESS_OWNER_CHANGE'),
                                message: t('SUCCESS_OWNER_CHANGE_MSG', {result: result.name}),
                                icon: faCheck,
                            });

                            navigate('/transfers/' + result.id);
                        }
                        else {
                            showToast({
                                title: t('SUCCESS_OWNER_BULK_CHANGE'),
                                message: t('SUCCESS_OWNER_BULK_CHANGE_MSG'),
                                icon: faCheck,
                            });

                            navigate('/transfers/');
                        }

                    })
                    .catch(e => {
                        setLoading(false);
                        return handleUnknownFormError(e, showToast, t)
                    });
            },

            [navigate, showToast, t, setLoading, submitRequest, isBulk])}
    />;

}

export default TransferAnimalContainer;
