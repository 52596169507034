import PopupFormComponent from "../components/PopupFormComponent";
import {useMutate} from "system/Rest/Rest";
import {useTranslation} from "react-i18next";
import {useCallback, useState} from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import {AggressionRegisterType} from "types/Aggression";

const FormContainer = (props) => {

    const {animal, aggression, reload, reloadBase, onClose, create} = props;

    const {t} = useTranslation("aggression");
    const showToast = useShowToast();

    const [isSubmit, setSubmit] = useState(true)

    const {mutate: submitAggression, loading, error: errorSubmitting} = useMutate({
        verb: create ? "POST" : "PUT",
        path: "/aggressions/" + (create ? "" : (animal?.transponder + "/" + aggression.id))
    });

    const {mutate: clearRegistry, loading: clearingRegistry, error: errorClearing} = useMutate({
        verb: create ? "POST" : "PUT",
        path: "/aggressions/clear-registry/",
    });


    const errors = useErrorParser(isSubmit ? errorSubmitting : errorClearing);

    return <PopupFormComponent
        {...props}
        errors={errors}
        loading={loading || clearingRegistry}
        onSubmit={useCallback(
            (aggression) => {

                if (aggression.registerType === AggressionRegisterType.REGISTER_CLEAR) {

                    //clear
                    setSubmit(false);
                    clearRegistry({
                        ...aggression,
                        transponder: animal?.transponder,
                    }).then(() => {
                        reload();
                        reloadBase();
                        onClose();

                        showToast({
                            title: t('SUCCESS_CLEAR'),
                            message: t('SUCCESS_CLEAR_MSG', {transponder: animal?.transponder}),
                            icon: faCheck,
                        });

                    }).catch((e) => {
                        showToast({
                            title: t('ERROR_CLEARING_REGISTRY'),
                            message: t(e.data?.message ? e.data?.message : 'ERROR_CLEARING_REGISTRY_MSG'),
                            icon: faTimes,
                            className: 'error',
                        });
                    });
                } else {
                    //Create
                    setSubmit(true);
                    submitAggression({
                        ...aggression,
                        transponder: animal?.transponder,
                    }).then(() => {
                        reload();
                        reloadBase();
                        onClose();

                        if (create)
                            showToast({
                                title: t('SUCCESS_ADD_AGGRESSION'),
                                message: t('SUCCESS_ADD_AGGRESSION_MSG', {transponder: animal?.transponder}),
                                icon: faCheck,
                            });
                        else
                            showToast({
                                title: t('SUCCESS_EDIT_AGGRESSION'),
                                message: t('SUCCESS_EDIT_AGGRESSION_MSG', {transponder: animal?.transponder}),
                                icon: faCheck,
                            });
                    }).catch((e) => {
                        showToast({
                            title: t('ERROR_ADD_AGGRESSION'),
                            message: t(e.data?.message ? e.data?.message : 'ERROR_ADD_AGGRESSION_MSG'),
                            icon: faTimes,
                            className: 'error',
                        });
                    });
                }
            },
            [create, onClose, reload, reloadBase, showToast, t, animal?.transponder, submitAggression, clearRegistry]
        )}
    />
}
export default FormContainer;
