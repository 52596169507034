import FormComponent from "app/interventions/components/form/FormComponent";
import {useAllInterventionTypes} from "app/interventions/utils/useInterventionTypes";
import locale from "app/interventions/locales/locales";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "modules/VersionsContainer/assets/css/versions.scss";
import useTranslations from "system/Translations/UseTranslations";
import Loading from "layout/modules/Loading/Loading";
import {getDiffsInErrorFormat, getOriginalRawObject} from "app/audit/utils/AuditDiff";
import {interventionAuditResolver} from "app/interventions/utils/InterventionResolver";

const RabiesProphylaxis = (props) => {

    const {operation} = props;
    const [interventionTypes, loadingTypes] = useAllInterventionTypes();

    const {t} = useTranslations("health-intervention", locale);

    if (loadingTypes || !interventionTypes) {
        return <Loading visible={true}/>
    }

    const diffs = getDiffsInErrorFormat(operation.changes);

    return <div className={"versions"}>
        <Row>
            <Col lg={true}>
                <FormComponent
                    t={t}
                    create={false}
                    compare={true}
                    types={interventionTypes}
                    readOnly={true}
                    errors={diffs}
                    intervention={interventionAuditResolver(operation.objectRaw)}

                />
            </Col>

            {operation.changes &&
            <Col lg={6}>
                <FormComponent
                    t={t}
                    create={false}
                    compare={true}
                    types={interventionTypes}
                    readOnly={true}
                    errors={diffs}
                    intervention={interventionAuditResolver(getOriginalRawObject(operation.objectRaw, operation.changes))}

                />
            </Col>
            }
        </Row>

    </div>

}

export default RabiesProphylaxis;
