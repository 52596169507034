import locale from "./locales/locales";
import React, {useMemo} from "react";
import ListComponent from "./components/list/ListComponent";
import ListDashboardComponent from "./components/list/ListDashboardComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";

const List = (props) => {

    const {dashboard} = props;

    return <GenericListContainer
        {...props}
        searchPath={"/ticketing/search"}
        module="support"
        hideTitle={dashboard}
        locale={locale}
        queryParameters={useMemo(() => ({
            sortProperty: 'lastUpdate',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 10
        }),[])}>
        {dashboard
            ? <ListDashboardComponent/>
            : <ListComponent dashboard={dashboard}/>
        }
    </GenericListContainer>

}

export default List;
