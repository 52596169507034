import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useGet} from "system/Rest/Rest"
import useDefaultProps from "./utils/useDefaultProps";
import GenericRemoteDropdownComponent from "./components/GenericRemoteDropdownComponent";
import useOnScreen from "system/Components/useOnScreen";
import useDebounce from "system/System/useDebounce";
import {objectIsEqual} from "system/Objects/Objects";
import {getStringValuesArrayFromObjectsArray} from "layout/modules/Forms/Dropdowns/utils/utils";
import arrayEquals from "system/Arrays/equals";

const GenericRemoteClassDropdown = (props) => {

    const {
        id, isDisabled, onChange, placeholder, noOptionsMessage,
        loadingMessage, remotePath, remoteClassPath, searchFilter,
        classValue, classLabel, labelHandler, valueHandler, value,
        defaultValue, universalSearch, isMulti, defaultSelectedOption,
        searchResponseSolver
    } = useDefaultProps(props);

    const resolverRef = useRef(null);
    const dropdownRef = useRef(null);

    const [searchQuery, setSearchQuery] = useState(undefined);
    const [currentSelectedOptions, setCurrentSelectedOptions] = useState(defaultSelectedOption ? defaultSelectedOption : undefined);

    const [clickedOptionsCache, setClickedOptionsCache] = useState(defaultSelectedOption ? defaultSelectedOption : undefined);
    const [loaded, setLoaded] = useState(false);

    const [defaultList, setDefaultList] = useState(null);

    const [sf, setSF] = useState(JSON.stringify(searchFilter)); //Hack to useEffect work in complex objects
    const jsf = JSON.stringify(searchFilter);

    const isVisible = useOnScreen(dropdownRef);
    const isControlled = useMemo(
        () => value !== undefined,
        [value]
    );

    //Download the default class object
    const {data: selectedOptionsResponse, refetch: gso} = useGet({
        path: remoteClassPath,
        lazy: true,
    });

    const getClasses = (ids) => {
        return gso({
            queryParams: {
                ids: ids,
                itemsPerPage: ids.length
            }
        });
    }

    //Search request
    const {data: searchResponse, loading, refetch: sc} = useGet({
        path: remotePath,
        lazy: true,
        resolve: searchResponseSolver,
    });

    const searchWithQueryParameters = useCallback(
        () => sc({
            queryParams: {
                ...searchFilter,
                [universalSearch]: searchQuery?.trim(),
            }
        }),
        [searchFilter, universalSearch, searchQuery, sc]
    );


    const searchClasses = useDebounce(searchWithQueryParameters, 300);

    //Prevent default content loading if not visible
    useEffect(() => {
        if (!isDisabled && isVisible && !loaded) {
            setLoaded(true);
            searchClasses();
        }
    }, [isDisabled, isVisible, loaded, searchClasses]);


    //Update every time searchFilter change
    useEffect(() => {

        //Prevent update if sf is the same or if is not loaded / visible
        if (objectIsEqual(sf, jsf) || isDisabled || !isVisible) {
            return;
        }

        setSF(jsf);
        setSearchQuery(null);

        if (loaded) { //Refresh only if loaded
            sc({queryParams: {...searchFilter}})
                .finally(() => setDefaultList(null)); //force reload default list from server response
        }

    }, [sf, jsf, setSF, loaded, searchFilter, setDefaultList, isDisabled, setSearchQuery, isVisible, sc]);


    useEffect(() => {
        if (!isControlled && defaultValue) {
            getClasses(defaultValue).catch(() => setCurrentSelectedOptions(undefined));
        }
        // eslint-disable-next-line
    }, [isControlled]);

    //When value or default value changes it will get the new objects
    useEffect(() => {

        const isEmptyArray = (Array.isArray(value) && value.length === 0)

        if (value && !isEmptyArray) {
            //Use strings to handle form submits that are allways in string
            const valueIdsArray = Array.isArray(value) ? value.map(String) : [String(value)];
            const optionIdsArray = getStringValuesArrayFromObjectsArray(clickedOptionsCache, true, classValue);

            if (!clickedOptionsCache || !arrayEquals(valueIdsArray, optionIdsArray)) {
                getClasses(valueIdsArray).catch(() => {
                    setCurrentSelectedOptions(undefined);
                });
            } else if (clickedOptionsCache) {
                setCurrentSelectedOptions(clickedOptionsCache);
            }
        } else {
            setCurrentSelectedOptions((option) => {
                return option ? null : undefined;
            });
        }
        // eslint-disable-next-line
    }, [value]);

    //Adjust selected option after receiving it from the server
    useEffect(() => {
        if (selectedOptionsResponse?.totalItems > 0) {
            setCurrentSelectedOptions(isMulti
                ? selectedOptionsResponse.content
                : selectedOptionsResponse.content[0]);
        } else {
            setCurrentSelectedOptions(undefined);
        }
    }, [selectedOptionsResponse, isMulti]);


    //check if default results and set it in first search result
    useEffect(() => {
        if (defaultList === null && searchResponse?.content && !loading) {
            setDefaultList(searchResponse.content);
        }
    }, [searchResponse, defaultList, setDefaultList, loading]);


    //Search after new request
    useEffect(() => {
        if (typeof searchQuery === "string") {
            searchClasses();
        }
        // eslint-disable-next-line
    }, [searchQuery]);

    //Handle server response and store result in the ref
    useEffect(() => {
        if (resolverRef.current && searchResponse?.content) {
            resolverRef.current(searchResponse.content);
            resolverRef.current = null;
        }
    }, [searchResponse]);


    return <GenericRemoteDropdownComponent
        {...props}
        name={id}
        ref={dropdownRef}
        onChange={onChange}
        valueHandler={valueHandler}
        labelHandler={labelHandler}
        classLabel={classLabel}
        classValue={classValue}
        isMulti={isMulti}
        isControlled={isControlled}
        setClickedOptionsCache={setClickedOptionsCache}
        defaultValue={defaultValue}
        defaultOptions={defaultList}
        currentSelectedOptions={currentSelectedOptions}
        isLoading={loading}
        isDisabled={isDisabled}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        loadOptions={useCallback((inputValue) => {
            setSearchQuery(inputValue);
            return new Promise(resolve => {
                resolverRef.current = resolve;
            });
        }, [resolverRef, setSearchQuery])}
    />
}

export default GenericRemoteClassDropdown;
