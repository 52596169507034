import BallComponent from "app/animals/components/generic/BallComponent";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classnames from "classnames";
import CardOptionsComponent from "app/animals/components/list/implementations/cardList/CardOptionsComponent";
import DateTime, {useDateTime} from "system/DateTime/DateTime";
import {AnimalStatus} from "types/Animal";
import getAnimalStatusLabel from "app/animals/utils/getAnimalStatusLabel";
import "app/animals/assets/css/card.scss";
import useAnimalListClick from "app/animals/utils/handleAnimalListClick";

const AnimalCardComponent = (props) => {

    const {animal, reload} = props;

    const {t} = useTranslation("animal");

    const getDateTime = useDateTime("DD-MM-YYYY");
    const timestamp = useMemo(() => (new Date()).getTime(), []);

    const handleHeaderClick = useAnimalListClick(animal);

    return <div className={"animal-card"}>

        <BallComponent
            reload={reload}
            animal={animal}
            hideStateBorder={false}
        />

        <div className={"animal-card-box"}>

            <div
                className={classnames("animal-card-box-header", "with-link", {
                    "lost": animal.status === AnimalStatus.LOST,
                    "found": animal.status === AnimalStatus.FOUND,
                    "dead": animal.status === AnimalStatus.DEAD,
                    "normal": animal.status === AnimalStatus.NORMAL,
                    "locked": animal.isLocked,
                    "pending": animal.hasPendingOwnerTransfer,
                    "invalid": (!animal.isDataValid && !animal.isPreRegister),
                    "pre-register": animal.isPreRegister,
                })}
                onClick={handleHeaderClick}
            >

                <div className={"triangle"}></div>
                <div className={"name fill-empty"}>{animal.name}</div>
                <div className={"sub-name"}>
                    {animal.breed?.name ? animal.breed?.name + " | " : ""} {t(animal.gender)}
                </div>

                <div className={"animal-status"}>
                    {getAnimalStatusLabel(t, animal)}
                </div>
            </div>

            <div className={"animal-card-box-content"}>
                <Row>
                    <Col sm={7} md={7} xl={7}>
                        <div className={"title"}>
                            {t('transponder')}
                        </div>
                        <div className={classnames("value", {
                            "merged-transponder": animal?.hasMergedTransponders
                        })}>
                            {animal?.mergedTransponder
                                ? animal?.mergedTransponder
                                : animal.transponder
                            }
                        </div>

                    </Col>

                    <Col sm={5} md={5} xl={5}>
                        <div className={"title"}>
                            {t('registration_date')}
                        </div>
                        <div className={"value"}>
                            <DateTime format="DD-MM-YYYY" timezone={"UTC"}>
                                {animal.registrationDate}
                            </DateTime>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} md={12} xl={12}>
                        <div className={"title"}>
                            {t('breed')}
                        </div>
                        <div className={"value"}>
                            {animal.breed?.name ? animal.breed?.name : "-"}
                            {animal.crossBreed &&
                            <>&nbsp;X&nbsp;{animal.crossBreed.name}</>
                            }
                        </div>

                    </Col>

                </Row>

                <Row>

                    <Col sm={7} md={7} xl={7}>
                        <div className={"title"}>
                            {t('birthdate')}
                        </div>
                        <div className={"value"}>
                            <DateTime format="DD-MM-YYYY" timezone={"UTC"}>
                                {animal.birthdate}
                            </DateTime>
                        </div>
                    </Col>

                    <Col sm={5} md={5} xl={5}>
                        <div className={"title"}>
                            {t('anti_rabies')}
                        </div>
                        <div className={"value fill-empty"}>
                            {animal?.rabiesImmunizationDateEnd &&
                            <span
                                className={classnames({"text-danger": timestamp >= animal.rabiesImmunizationDateEnd})}>
                                    {t('rabies_until', {date: getDateTime(animal.rabiesImmunizationDateEnd, "UTC")})}

                            </span>
                            }
                        </div>
                    </Col>
                </Row>

            </div>

            <CardOptionsComponent {...props} animal={animal} t={t}/>

        </div>
    </div>


}

export default AnimalCardComponent;
