import React from "react";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faCheckDouble, faInfo} from "@fortawesome/free-solid-svg-icons";
import TransferValidationStatus from "app/control/modules/transfers/utils/TransferValidationStatus";
import {Trans} from "react-i18next";
import {UserHasNoPermission} from "system/API/Authentication/UserHasPermission";

const MoreInformationInfoMessageComponent = props => {

    const {t, request} = props;

    if (request.closed)
        return <></>

    if (!request?.rejectReason && request.validationStatus === TransferValidationStatus.WAITING_VALIDATION) {
        return <UserHasNoPermission permission={["MANAGE_ANIMAL_TRANSFER"]}>
            <AdvanceInfoMessage
                icon={faCheckDouble}
                type={"success"}
                iconType={"success"}
                title={t("SUCCESS_OWNER_CHANGE")}>
                <div style={{whiteSpace: "pre-wrap"}}>
                    <Trans>
                        {t("SUCCESS_OWNER_CHANGE_MSG")}
                    </Trans>

                </div>
            </AdvanceInfoMessage>
        </UserHasNoPermission>
    } else if (request.validationStatus === TransferValidationStatus.WAITING_VALIDATION) {
        return <AdvanceInfoMessage
            icon={faInfo}
            type={"info"}
            iconType={"info"}
            title={t("more_information_provided")}>
            <div style={{whiteSpace: "pre-wrap"}}>
                <Trans>
                    {t("more_information_msg", {message: request?.rejectReason})}
                </Trans>

            </div>
        </AdvanceInfoMessage>
    } else if (request?.rejectReason) {
        return <AdvanceInfoMessage
            icon={faInfo}
            type={"warning"}
            iconType={"warning"}
            title={t("more_information_request")}>
            <div style={{whiteSpace: "pre-wrap"}}>
                {request?.rejectReason}
            </div>
        </AdvanceInfoMessage>
    } else return <></>;


}

export default MoreInformationInfoMessageComponent
