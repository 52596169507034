export const pt = {

    no_results: "Sem licenciamento registado",

    licensing: "Licenciamento",
    add_licensing: "Adicionar para {{transponder}}",

    licensing_tip: "Por favor escolha a data em que foi realizado o licenciamento.",

    //Fields
    license_date: "Data de licenciamento",
    license_ph: "Data no formato (dd-mm-yyyy)",
    entity: "Entidade",
    year: "Ano",

    add: "Adicionar",
    cancel: "Cancelar",

    register: "Licenciamento",

    valid: "Válido",
    true: "Sim",
    false: "Não",

    SUCCESS_ADD_LICENSING: "Licenciamento registado",
    SUCCESS_ADD_LICENSING_MSG: "Foi registado novo licenciamento para o transponder {{transponder}}",

    ERROR_ADD_LICENSING: "Erro no formulário",
    ERROR_ADD_LICENSING_MSG: "Ocorreu um erro ao registar novo licenciamento para o transponder {{transponder}}",

    MISSING_DATE: "Por favor defina uma data de licenciamento",
    INVALID_DATE: "Por favor indique uma data válida",

    DELETE_LICENSE_CONFIRM: "Confirmação",
    DELETE_LICENSE_CONFIRM_MSG: "Deseja realmente apagar esta entrada de licenciamento?",

    SUCCESS_DELETE_LICENSE: "Sucesso",
    SUCCESS_DELETE_LICENSE_MSG: "Licenciamento apagado com sucesso",

    FAIL_DELETE_LICENSE: "Erro ao apagar",
    FAIL_DELETE_LICENSE_MSG: "Ocorreu um erro ao apagar o licenciamento. Por favor tente mais tarde",


};

export default pt;
