import TransferType from "app/control/modules/transfers/utils/TransferType";

const getUserTypeLabel = (transfer) => {

    switch (transfer.origin) {
        case TransferType.SIAC:
            if (transfer.creationUser?.ballotNumber)
                return "VETERINARY_USER";

            return "NORMAL_USER";
        case TransferType.OWNERS:
            return "OWNER_USER";
        default:
            return "PUBLIC_USER";
    }

}

export default getUserTypeLabel;
