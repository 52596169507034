import {useMutate} from "system/Rest/Rest";
import BulkGenerateComponent from "app/certificates/components/generate/BulkGenerateComponent";
import {useCallback, useState} from "react";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import locale from "app/certificates/locales/locales";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useTranslations from "system/Translations/UseTranslations";
import useErrorParser from "system/API/Error/ErrorParser";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";

const BulkGenerateContainer = (props) => {

    const {transponders, owner} = props;
    const showToast = useShowToast();
    const {t} = useTranslations('certificate', locale);

    const [request, setRequest] = useState({
        transponders: transponders,
        emailAddress: owner?.email,
    })

    const {mutate: requestCertificates, loading, error} = useMutate({
        verb: "POST",
        path: "/certificates/bulk"
    });

    const errors = useErrorParser(error);

    return <BulkGenerateComponent
        {...props}
        loading={loading}
        request={request}
        setRequest={setRequest}
        errors={errors}
        onSubmit={useCallback(
            () => new Promise(resolve => {
                    requestCertificates(request).then((result) => {
                        showToast({
                            title: t('SUCCESS_SENT_TRANSPONDERS'),
                            message: t('SUCCESS_SENT_TRANSPONDERS_MSG', {
                                total: result.transponders.length,
                                email: request.emailAddress
                            }),
                            icon: faCheck,
                        });

                        resolve();

                    }).catch((e) => {
                        handleUnknownFormError(e, showToast, t);
                    });

                }
            ),
            [showToast, t, request, requestCertificates]
        )}

    />


}

export default BulkGenerateContainer;
