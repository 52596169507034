import Modal from "react-bootstrap/Modal";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/support/locales/locales";
import ViewContainer from "app/support/containers/ViewContainer";

const ModalTicketComponent = props => {

    const {id, onClose, isVisible} = props;
    const {t} = useTranslations('support', locale);

    return <Modal
        show={isVisible}
        onHide={onClose}
        size={"xl"}
        className={"view-ticket-modal dark-background"}
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{t('view_title',{id: id})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <ViewContainer id={id}/>

        </Modal.Body>
    </Modal>

}

export default ModalTicketComponent
