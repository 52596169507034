import {useEffect, useState} from 'react';
import {useNotifications} from "./NotificationAreaProvider";
import useTranslations from "system/Translations/UseTranslations";
import getRandomString from "system/Randoom/Random";
import ShowToastComponent from "layout/modules/NotificationArea/components/toast/ShowToastComponent";
import locale from "../locale/locales";

const BaseToastComponent = (props) => {

    const {id: bId, message = props.children, duration = 2000, className = "", icon, title} = props;

    const {t} = useTranslations('notifications', locale);

    const [id] = useState(getRandomString(10));
    const {showNotification, closeNotification} = useNotifications();

    const [closeLabel, setCloseLabel] = useState("");

    useEffect(
        () => {
            setCloseLabel(t('close'))
        },
        [t, setCloseLabel]
    );

    useEffect(() => {
            ShowToastComponent({
                id: bId ? bId : id,
                duration: duration,
                title: title,
                message: message,
                close: closeLabel,
                showNotification,
                closeNotification,
                className,
                icon

            })
        },
        [id, message, title, duration, showNotification, closeNotification, className, bId, icon, closeLabel]
    );

    return null;


}


export default BaseToastComponent;
export {ShowToastComponent}

