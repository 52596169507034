import React, {useMemo} from 'react'
import GenericRemoteClassDropdown from "../GenericRemoteClassDropdown"
import locale from "../locales/locales"
import useTranslations from "../../../../../system/Translations/UseTranslations";
import usePlaceHolder from "../utils/usePlaceHolder";
import useLabelCallback from "../utils/useLabelCallback";

const EntityProfileDropdown = (props) => {

    const {id = "entityTypeId", placeholder, noOptionsMessage, loadingMessage, maxItems = 50} = props;

    const {t} = useTranslations('classDropdown', locale);

    return <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder,t('entity_profile_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage,t('entity_profile_no'))}
        loadingMessage={useLabelCallback(loadingMessage,t('entity_profile_loading'))}
        remotePath="/entity-profiles/search"
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: maxItems,
        }),[maxItems])}
    />

}

export default EntityProfileDropdown;
