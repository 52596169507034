import React, {useRef} from 'react'
import Row from "react-bootstrap/Row";
import locale from './locales/locales'
import SearchContainer from "./containers/SearchContainer";
import NotificationComponent from "./components/NotificationComponent";
import SupportComponent from "./components/SupportComponent";
import useTranslations from "system/Translations/UseTranslations";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";
import Col from "react-bootstrap/Col";
import UserDetailsContainer from "app/home/containers/UserDetailsContainer";
import AlertsContainer from "app/home/containers/AlertsContainer";
import useElementSize from "system/Window/useElementSize";
import "./assets/css/dashboard.scss"

const Home = (props) => {

    const {t} = useTranslations('dashboard', locale);
    const ref = useRef();
    const {width} = useElementSize(ref);

    return <>
        <Title title={t('title_dashboard')} breadcrumbs={[{href: "/" + module, label: t('title_dashboard')}]}/>

        <div className={"dashboard limited-width"} ref={ref}>

            <AlertsContainer/>
            {width < 1100
                ? <Row>
                    <Col sm={12} xs={12} md={12} lg={12} xl={12}>
                        <SearchContainer {...props}/>
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={6} xl={6} className={"dashboard-force-grow"}>
                        <UserDetailsContainer {...props} />
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                        <NotificationComponent {...props}/>
                    </Col>
                    <Col sm={12} xs={12} md={12} lg={12} xl={12}>
                        <SupportComponent {...props}/>
                    </Col>

                </Row>

                : <Row>
                    <Col sm={12} xs={12} md={12} lg={12} xl={12} xxl={8} className={"dashboard-grid"}>
                        <SearchContainer {...props}/>
                        <SupportComponent {...props}/>
                    </Col>

                    <Col sm={12} xs={12} md={12} lg={12} xl={4} xxl={4} className={"dashboard-grid"}>
                        <UserDetailsContainer {...props} />
                        <NotificationComponent {...props}/>
                    </Col>
                </Row>
            }
        </div>

    </>
}

export default Home;
